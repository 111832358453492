import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { LogManagerService } from './log-manager.service';

@Component({
  selector: 'sob-log-manager',
  templateUrl: './log-manager.component.html',
  styleUrls: ['./log-manager.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LogManagerComponent implements AfterViewInit {
  private logManagerDiv;
  private messagesCount = 0;

  constructor(private logManager: LogManagerService) {
    this.logManager.logMessageRequest.subscribe((message: any) => {
      this.appendMessage(message.message, message.severity, message.timestamp);
      this.messagesCount++;
      this.displayMessagesCountIfNeeded();
    });

    this.logManager.logSerializedMessagesRequest.subscribe((serializedMessages: Array<any>) => {
      for (const message of serializedMessages) {
        this.appendMessage(message.message, message.severity, message.timestamp);
        this.messagesCount++;
      }

      this.displayMessagesCountIfNeeded();
    });

    this.logManager.clearLogCounterRequest.subscribe(() => {
      this.clearLogCounter();
    });

    this.logManager.clearLogManagerRequest.subscribe(() => {
      this.clearLogManager();
      this.clearLogCounter();
    });
  }

  ngAfterViewInit() {
    this.logManagerDiv = $('#logManager');
  }

  private appendMessage(message: string, severity: string, timestamp: Date) {
    // TODO: If log manager is hidden, add elements to counter
    if (!timestamp) {
      timestamp = new Date();
    }

    this.logManagerDiv.append(
      `<div class="${severity}"> [${severity}] | ${moment(timestamp).format('YYYY/MM/DD HH:mm:ss')} | ${message}</div>`
    );

    this.logManagerDiv.scrollTop(this.logManagerDiv[0].scrollHeight);
  }

  displayMessagesCountIfNeeded() {
    if (!this.logManagerDiv.is(':visible')) {
      this.displayMessagesCount();
    }
  }

  displayMessagesCount() {
    const $logCounter = $('#logCounter');

    if (!$logCounter.is(':visible')) {
      $logCounter.show();
    }

    $logCounter
      .html(`${this.messagesCount}`)
      .effect('bounce')
      .parents('.inverted-tab')
      .css({ paddingRight: `${$logCounter.width() + 10}px` });
  }

  private clearLogCounter() {
    this.messagesCount = 0;
    $('#logCounter').html('').hide().parents('.inverted-tab').css({ paddingRight: 0 });

    this.logManagerDiv.scrollTop(this.logManagerDiv[0].scrollHeight);
  }

  private clearLogManager() {
    this.logManagerDiv.html('');
  }
}
