import { DefaultBaseObjectFormGroupMapper } from './default-base-object-form-group.mapper';
import { BaseObject } from '../_models/base-object';
import { BaseWaterSplitter } from '../_models/_unit-operations/utilties/base-water-splitter';
import { hasNumericValue } from '../_utils/utils';

export abstract class BaseWaterSplitterFormGroupMapper extends DefaultBaseObjectFormGroupMapper {
  override map(source: BaseObject, destination: BaseObject) {
    super.map(source, destination);
    const sourcePlainObject = source as BaseWaterSplitter;
    const destinationInstance = destination as BaseWaterSplitter;

    destinationInstance.isOptimizable = !!sourcePlainObject.isOptimizable;
    this.mapRatioVariables(sourcePlainObject, destinationInstance);
  }

  mapRatioVariables(source: BaseWaterSplitter, destination: BaseWaterSplitter) {
    for (let i = 0; i < source.distributionRatioVariables.length; i++) {
      const sourceValue = source.distributionRatioVariables[i].value;

      // eslint-disable-next-line max-len
      destination.distributionRatioVariables[i].value = hasNumericValue(sourceValue) ? +sourceValue : undefined; // source.distributionRatioVariables[i].value;
    }
  }

  override ignoreForAutoMapping(key: string, source: BaseObject, destination: BaseObject): boolean {
    const sourcePlainObject = source as BaseWaterSplitter;
    return (
      super.ignoreForAutoMapping(key, source, destination) ||
      source[key] === sourcePlainObject.distributionRatioVariables
    );
  }
}
