import { FluidCoker } from '../../../_unit-operations/fluid-coker';
import { SimulationVariable } from '../../../simulation-variable';
import { SimulationVariableName } from '../../../../_config/simulation-variable-name.enum';
import { ConstraintReportVariable } from '../constraint-report-variable';

export class FluidCokerConstraintReportVariableResolver {
  static create() {
    return new FluidCokerConstraintReportVariableResolver();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  addCurrentValueAndUnitToConstraintReportVariable(
    crv: ConstraintReportVariable,
    params: { uo: FluidCoker; simVar: SimulationVariable }
  ): void {
    const { uo, simVar } = params;

    if (simVar.name === SimulationVariableName.MINIMUM_API) {
      crv.currentValue = uo.feedDensity.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.feedDensity.getDefaultUnit();
      return;
    }

    if (simVar.name === SimulationVariableName.MAXIMUM_FEED_CAPACITY) {
      crv.currentValue = uo.totalInletVolume.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.totalInletVolume.getDefaultUnit();
      return;
    }

    if (simVar.name === SimulationVariableName.MINIMUM_FLOW) {
      crv.currentValue = uo.totalInletVolume.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.totalInletVolume.getDefaultUnit();
      return;
    }

    // variables depending on InletStream
    const inletStreamList = uo.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === uo.id;
    });

    if (!inletStreamList.length) {
      return;
    }

    if (simVar.name === SimulationVariableName.MAXIMUM_CCR) {
      crv.currentValue = inletStreamList[0].ccr.convertToDefaultUnit(true, false);
      crv.currentUnit = inletStreamList[0].ccr.getDefaultUnit();
      return;
    }

    throw new Error(`Unknown constraint: ${simVar.name}`);
  }
}
