import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Pipe } from '../../../_models/_unit-operations/pipe';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';

@Component({
  selector: 'sob-pipe',
  templateUrl: './pipe.component.html',
  styleUrls: ['./pipe.component.css'],
})
export class PipeComponent implements OnInit {
  @Input() unitOperation: Pipe;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;

  constructor(private fb: UntypedFormBuilder, private svfb: SimVarFormBuilder) {}

  ngOnInit(): void {
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl('pipeLength', this.svfb.control(this.unitOperation.pipeLength));
    this.propertyWindowFormGroup.addControl('pressureDrop', this.svfb.control(this.unitOperation.pressureDrop));
    this.propertyWindowFormGroup.addControl('ghgIntensity', this.svfb.control(this.unitOperation.ghgIntensity));
    this.propertyWindowFormGroup.addControl('ghgEmissions', this.svfb.control(this.unitOperation.ghgEmissions, true));

    this.propertyWindowFormGroup.addControl('opexFixed', this.svfb.control(this.unitOperation.opexFixed));
    this.propertyWindowFormGroup.addControl('opexVar', this.svfb.control(this.unitOperation.opexVar));
    this.propertyWindowFormGroup.addControl(
      'opexCarbonDioxide',
      this.svfb.control(this.unitOperation.opexCarbonDioxide, true)
    );
    this.propertyWindowFormGroup.addControl('opexTotal', this.svfb.control(this.unitOperation.opexTotal, true));

    this.propertyWindowFormGroup.addControl(
      'capacity',
      this.svfb.control(this.unitOperation.capacity, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'minimumFlow',
      this.svfb.control(this.unitOperation.minimumFlow, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'pipeDensitySpec',
      this.svfb.control(this.unitOperation.pipeDensitySpec, false, false, true)
    );
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }
}
