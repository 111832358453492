import { Completion } from '@codemirror/autocomplete';
import { DefaultCodeProvider } from './default-code-provider';
import { bitumenConversionCompletions } from '../../_config/unit-operations/custom-code-completions/bitumen-conversion-completions';
import { defaultBitumenConversionModel } from '../../_config/unit-operations/default-models/default-bitumen-conversion-model';

export class BitumenConversionCodeProvider extends DefaultCodeProvider {
  constructor() {
    super(defaultBitumenConversionModel);
  }

  override getCodeCompletions(): Completion[] {
    return super.getCodeCompletions().concat(bitumenConversionCompletions);
  }
}
