import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { FuelGasAmineContactor } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-amine-contactor';

@Component({
  selector: 'sob-fuel-gas-amine-contactor',
  templateUrl: './fuel-gas-amine-contactor.component.html',
  styleUrls: ['./fuel-gas-amine-contactor.component.css'],
})
export class FuelGasAmineContactorComponent implements OnInit {
  @Input() unitOperation: FuelGasAmineContactor;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;

  constructor(private fb: UntypedFormBuilder, private svfb: SimVarFormBuilder) {}

  ngOnInit(): void {
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl(
      'h2SRemovalFraction',
      this.svfb.control(this.unitOperation.h2SRemovalFraction)
    );
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }
}
