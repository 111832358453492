import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Case } from '../../case';
import { FuelGasUnitOperation } from './fuel-gas-unit-operation';

export class FuelGasMixer extends FuelGasUnitOperation {
  category = unitOperationsConfig.fuelGasMixer.key;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(unitOperation: any | FuelGasMixer, ownerCase: Case) {
    super(unitOperation, ownerCase);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override initValues(unitOperation: any) {}

  override setSimulationVariableNames() {}

  override setQuantities() {}
}
