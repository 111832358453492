<ng-container [formGroup]="formGroup">
  <ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
    <li role="presentation" class="active">
      <a href="#outlets" aria-controls="outlets" role="tab" data-toggle="tab">Outlets</a>
    </li>
    <li role="presentation">
      <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
    </li>

    <li role="presentation">
      <a href="#economics" aria-controls="economics" role="tab" data-toggle="tab">Economics</a>
    </li>

    <li role="presentation">
      <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
    </li>

    <li role="presentation">
      <a href="#utility" aria-controls="economics" role="tab" data-toggle="tab">Utility</a>
    </li>

    <li role="presentation">
      <a href="#bitumenConversionComments" aria-controls="bitumenConversionComments" role="tab" data-toggle="tab">
        <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
        Comments
      </a>
    </li>
  </ul>

  <div class="tab-content pw-margin tab-content-padding">
    <div role="tabpanel" class="tab-pane fade in active" id="outlets">
      <div class="form-horizontal">
        <div class="form-group">
          <label for="customModelEnabled" class="control-label col-sm-4"> Custom Model </label>
          <div class="col-sm-4">
            <label class="control-label">
              <input
                formControlName="useCustomModel"
                type="checkbox"
                id="customModelEnabled"
                (change)="onCustomModelChanged()" />
            </label>
          </div>
        </div>

        <div class="mb-4">
          <sob-open-reactive-code-editor-button
            [code]="unitOperation.customModelScript"
            [defaultCodeProvider]="defaultCodeProvider"
            [disabled]="!isCustomModelEnabled"
            [instanceId]="'bitumenConversionCodeEditor'"
            (codeSaved)="saveCustomModel($event)">
          </sob-open-reactive-code-editor-button>
        </div>

        <div class="form-group" *ngIf="!isCustomModelEnabled">
          <label for="bcYieldBasis" class="pw-label control-label col-sm-4">Yield Basis</label>
          <div class="col-sm-6">
            <select id="bcYieldBasis" class="form-control" formControlName="yieldBasis">
              <option value="">Select one...</option>
              <option value="{{ BitumenConversionYieldBasis.VOLUME }}">Volume</option>
              <option value="{{ BitumenConversionYieldBasis.MASS }}">Mass</option>
            </select>
          </div>
        </div>
        <ng-container formArrayName="outlets">
          <ng-container *ngFor="let outletFormGroup of outletsFormArray.controls; let i = index">
            <ng-container [formGroupName]="i">
              <h4 class="subtitle" style="padding-top: 20px">{{ findOutletName(i) }}</h4>
              <div class="form-group">
                <label class="pw-label control-label col-sm-4"> Assay Name </label>
                <div class="col-sm-8" [id]="'assaySelector' + i">
                  <sob-assay-selector
                    [fluidAnalyses]="fluidAnalyses"
                    [currentAnalysis]="getCurrentAnalysisByOutletIndex(i)"
                    (updateCurrentAssay)="updateCurrentAnalysis(i, $event)"></sob-assay-selector>
                </div>
              </div>

              <div class="form-group" formGroupName="yield">
                <label for="" class="pw-label control-label col-sm-4">Yield</label>
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-xs-6">
                      <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.totalYield.quantity">
                      </sob-reactive-unit-select>
                    </div>
                    <div class="col-xs-6">
                      <sob-reactive-variable-value-input
                        id=""
                        formControlName="value"
                        [valueMetaData]="unitOperation.totalYield">
                      </sob-reactive-variable-value-input>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" formGroupName="price">
                <label for="" class="pw-label control-label col-sm-4">Product Price</label>
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-xs-6">
                      <sob-reactive-unit-select
                        formControlName="unit"
                        [quantity]="unitOperation.outlets[i].price.quantity">
                      </sob-reactive-unit-select>
                    </div>
                    <div class="col-xs-6">
                      <sob-reactive-variable-value-input
                        formControlName="value"
                        [valueMetaData]="unitOperation.outlets[i].price">
                      </sob-reactive-variable-value-input>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <hr />
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="blockSpecifications">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <div class="form-group" formGroupName="hydrogenUseRate">
            <label for="bcHydrogenUseRate" class="pw-label control-label col-sm-4"> Hydrogen Use Rate </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.hydrogenUseRate.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcHydrogenUseRate"
                    formControlName="value"
                    [valueMetaData]="unitOperation.hydrogenUseRate">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="hydrogenUse">
            <label for="bcHydrogenUse" class="pw-label control-label col-sm-4"> Hydrogen Use </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.hydrogenUse.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcHydrogenUse"
                    formControlName="value"
                    [valueMetaData]="unitOperation.hydrogenUse">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group" formGroupName="hydrogenImbalance">
            <label for="bcHydrogenImbalance" class="pw-label control-label col-sm-4"> Hydrogen Excess</label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.hydrogenImbalance.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcHydrogenImbalance"
                    formControlName="value"
                    [valueMetaData]="unitOperation.hydrogenImbalance">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <h4 class="subtitle">GHG</h4>
          <div class="form-group" formGroupName="ghgIntensity">
            <label for="bcGhgIntensity" class="pw-label control-label col-sm-4"> GHG Intensity </label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.ghgIntensity.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcGhgIntensity"
                    formControlName="value"
                    [valueMetaData]="unitOperation.ghgIntensity">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="absoluteGhgEmissions">
            <label for="bcAbsoluteGhgEmissions" class="pw-label control-label col-sm-4"> Absolute GHG Emissions </label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.absoluteGhgEmissions.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcAbsoluteGhgEmissions"
                    formControlName="value"
                    [valueMetaData]="unitOperation.absoluteGhgEmissions">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="economics">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <div class="form-group" formGroupName="revenue">
            <label for="bcRevenue" class="pw-label control-label col-sm-4">Revenue</label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.revenue.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcRevenue"
                    formControlName="value"
                    [valueMetaData]="unitOperation.revenue">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="unitValue">
            <label for="bcUnitValue" class="pw-label control-label col-sm-4">Before Tax Cash Flow</label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.unitValue.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcUnitValue"
                    formControlName="value"
                    [valueMetaData]="unitOperation.unitValue">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <h4 class="subtitle">OPEX</h4>

          <div class="form-group" formGroupName="opexFixed">
            <label for="bcOpexFixed" class="pw-label control-label col-sm-4">Fixed</label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexFixed.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcOpexFixed"
                    formControlName="value"
                    [valueMetaData]="unitOperation.opexFixed">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexVar">
            <label for="" class="pw-label control-label col-sm-4">Variable</label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexVar.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcOpexVar"
                    formControlName="value"
                    [valueMetaData]="unitOperation.opexVar">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexCarbonDioxide">
            <label for="bcOpexCarbonDioxide" class="pw-label control-label col-sm-4">CO<sub>2</sub></label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.opexCarbonDioxide.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcOpexCarbonDioxide"
                    formControlName="value"
                    [valueMetaData]="unitOperation.opexCarbonDioxide">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexTotal">
            <label for="" class="pw-label control-label col-sm-4">Total</label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexTotal.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcOpexTotal"
                    formControlName="value"
                    [valueMetaData]="unitOperation.opexTotal">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <h4 class="subtitle">CAPEX</h4>

          <div class="form-group" formGroupName="capexAmortized">
            <label for="" class="pw-label control-label col-sm-4">Amortized CAPEX</label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capexAmortized.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="capexAmortized"
                    formControlName="value"
                    [valueMetaData]="unitOperation.capexAmortized">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="capexTotal">
            <label for="bcCapexTotal" class="pw-label control-label col-sm-4">Total</label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capexTotal.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcCapexTotal"
                    formControlName="value"
                    [valueMetaData]="unitOperation.capexTotal">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="constraints">
      <p class="constraints-active-label">
        <span style="">Active</span>
      </p>
      <div class="form-horizontal">
        <div class="form-group" formGroupName="capacity">
          <label for="" class="pw-label control-label col-sm-4">Maximum Feed Capacity</label>
          <div class="col-sm-8">
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capacity.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-5 pl-1 pr-3">
                <sob-reactive-variable-value-input
                  id="bcCapacity"
                  formControlName="value"
                  [valueMetaData]="unitOperation.capacity">
                </sob-reactive-variable-value-input>
              </div>
              <div class="col-xs-1 text-center pl-2_5 pr-1">
                <label class="control-label">
                  <input type="checkbox" formControlName="isActive" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="bitumenConversionComments">
      <div class="tab-content no-padding no-border">
        <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
      </div>
    </div>
    <div role="tabpanel" class="tab-pane fade in" id="utility">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <div class="form-group">
            <div class="btn-group" data-toggle="buttons">
              <label
                for="bcModelTypeSimple"
                class="btn utility-toggle btn-primary btn-sm {{
                  formGroup.controls.modelType.value === BitumenModelTypes.SIMPLE ? 'active' : ''
                }}"
                (click)="updateModelType(BitumenModelTypes.SIMPLE)">
                <input type="radio" id="bcModelTypeSimple" name="modelType" />
                Simple
              </label>
              <label
                for="bcModelTypeUtility"
                class="btn utility-toggle btn-primary btn-sm {{
                  formGroup.controls.modelType.value === BitumenModelTypes.UTILITY ? 'active' : ''
                }}"
                (click)="updateModelType(BitumenModelTypes.UTILITY)">
                <input type="radio" id="bcModelTypeUtility" name="modelType" autocomplete="off" />
                Detailed
              </label>
            </div>
          </div>

          <div class="form-group" formGroupName="powerUseRate">
            <label for="bcPowerUseRate" class="pw-label control-label col-sm-4"> Power Use Rate </label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.powerUseRate.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcPowerUseRate"
                    formControlName="value"
                    [valueMetaData]="unitOperation.powerUseRate">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="powerPrice">
            <label for="" class="pw-label control-label col-sm-4">Power Price</label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.powerPrice.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcPowerPrice"
                    formControlName="value"
                    [valueMetaData]="unitOperation.powerPrice">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="powerEmissionFactor">
            <label for="bcPowerEmissionFactor" class="pw-label control-label col-sm-4">Power Emission Factor</label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.powerEmissionFactor.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcPowerEmissionFactor"
                    formControlName="value"
                    [valueMetaData]="unitOperation.powerEmissionFactor">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="hydrogenPrice">
            <label for="bcHydrogenPrice" class="pw-label control-label col-sm-4">Hydrogen Price</label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.hydrogenPrice.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcHydrogenPrice"
                    formControlName="value"
                    [valueMetaData]="unitOperation.hydrogenPrice">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="hydrogenEmissionFactor">
            <label for="bcHydrogenEmissionFactor" class="pw-label control-label col-sm-4"
              >Hydrogen Emission Factor</label
            >
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.hydrogenEmissionFactor.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcHydrogenEmissionFactor"
                    formControlName="value"
                    [valueMetaData]="unitOperation.hydrogenEmissionFactor">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="naturalGasUseRate">
            <label for="bcNaturalGasUseRate" class="pw-label control-label col-sm-4">Natural Gas Use Rate</label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.naturalGasUseRate.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcNaturalGasUseRate"
                    formControlName="value"
                    [valueMetaData]="unitOperation.naturalGasUseRate">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="naturalGasPrice">
            <label for="bcNaturalGasPrice" class="pw-label control-label col-sm-4">Natural Gas Price</label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.naturalGasPrice.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcNaturalGasPrice"
                    formControlName="value"
                    [valueMetaData]="unitOperation.naturalGasPrice">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="naturalGasEmissionFactor">
            <label for="bcNaturalGasEmissionFactor" class="pw-label control-label col-sm-4"
              >Natural Gas Emission Factor</label
            >
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.naturalGasEmissionFactor.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    id="bcNaturalGasEmissionFactor"
                    formControlName="value"
                    [valueMetaData]="unitOperation.naturalGasEmissionFactor">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <sob-reactive-steam-use-utility [formGroupWrapper]="formGroupWrapper" [unitOperation]="unitOperation">
          </sob-reactive-steam-use-utility>
          <sob-reactive-gas-contributor [formGroupWrapper]="formGroupWrapper" [unitOperation]="unitOperation">
          </sob-reactive-gas-contributor>
        </div>
      </div>
    </div>
  </div>
</ng-container>
