<div class="inline">
  <span *ngIf="currentCase.isSolved" class="label label-success">
    <i class="fa fa-check-circle"></i>
    Solved
  </span>
  <span *ngIf="!currentCase.isSolved" class="label label-not-solved">
    <i class="fa fa-exclamation-circle"></i>
    Not Solved
  </span>
</div>

<div class="inline m-l-xs">
  <span *ngIf="isMultiPeriodEnabled" class="label label-success"
    ><i class="fa fa-clock-o"></i> Multi Period mode enabled</span
  >
</div>
