import { UnitOperation } from '../unit-operation';

export class FlowsheetTreeNode {
  unitOperation: UnitOperation;
  children: FlowsheetTreeNode[];

  constructor(uo: UnitOperation) {
    this.unitOperation = uo;
    this.children = [];
  }

  addChild(child: FlowsheetTreeNode) {
    this.children.push(child);
  }

  get id() {
    return this.unitOperation.id;
  }

  get name() {
    return this.unitOperation.name;
  }

  findChildById(id: string): FlowsheetTreeNode {
    return this.children.find(node => node.id === id);
  }

  public toJSON() {
    return {
      name: this.name,
      children: this.children,
    };
  }
}
