import * as wjcCore from '@grapecity/wijmo';
import { FlexSheetCellConfig } from './flex-sheet-cell-config';

export enum AssayGasesSheetRows {
  NAME = 1,
  TYPE = 2,
  COMP_CO = 5,
  COMP_CO2 = 6,
  COMP_H2 = 7,
  COMP_N2 = 8,
  COMP_H2S = 9,
  COMP_SO2 = 10,
  COMP_METHANE = 11,
  COMP_ETHANE = 12,
  COMP_PROPANE = 13,
  COMP_IC4 = 14,
  COMP_NC4 = 15,
  COMP_IC5 = 16,
  COMP_NC5 = 17,
  COMP_C6 = 18,
}

export const AssayGasesCellConfig: FlexSheetCellConfig[] = [
  {
    row: AssayGasesSheetRows.NAME,
    dataType: wjcCore.DataType.String,
    format: '',
  },
  {
    row: AssayGasesSheetRows.TYPE,
    dataType: wjcCore.DataType.String,
    format: '',
  },
  {
    row: AssayGasesSheetRows.COMP_CO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssayGasesSheetRows.COMP_CO2,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssayGasesSheetRows.COMP_H2,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssayGasesSheetRows.COMP_N2,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssayGasesSheetRows.COMP_H2S,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssayGasesSheetRows.COMP_SO2,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssayGasesSheetRows.COMP_METHANE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssayGasesSheetRows.COMP_ETHANE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssayGasesSheetRows.COMP_PROPANE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssayGasesSheetRows.COMP_IC4,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssayGasesSheetRows.COMP_NC4,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssayGasesSheetRows.COMP_IC5,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssayGasesSheetRows.COMP_NC5,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: AssayGasesSheetRows.COMP_C6,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
];
