<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab"
      >Distribution Ratio</a
    >
  </li>
  <li role="presentation">
    <a href="#general" aria-controls="general" role="tab" data-toggle="tab">General</a>
  </li>
  <li role="presentation">
    <a href="#utilities" aria-controls="utilities" role="tab" data-toggle="tab">Utilities</a>
  </li>
  <li role="presentation">
    <a href="#economics" aria-controls="economics" role="tab" data-toggle="tab">Economics</a>
  </li>
  <li role="presentation">
    <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
  </li>

  <li role="presentation">
    <a href="#vacComments" aria-controls="vacComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label [attr.for]="'sweetProductRatio'" class="pw-label control-label col-sm-4"> Solve Option </label>
          <div class="col-sm-6">
            <select class="form-control" [(ngModel)]="unitOperation.solveOption">
              <option value="Component split">Component Split</option>
              <option value="OutletTBP">OutletTBP</option>
            </select>
          </div>
        </div>
        <div class="form-group" *ngIf="unitOperation.solveOption === 'OutletTBP'">
          <label [attr.for]="'yieldBasis'" class="pw-label control-label col-sm-4"> Yield Basis </label>
          <div class="col-sm-6">
            <select class="form-control" [(ngModel)]="unitOperation.yieldBasis">
              <option value="Weight basis">Weight Basis</option>
            </select>
          </div>
        </div>
      </div>
      <div
        sfeMultipleSimVarInput
        *ngIf="unitOperation.boilingPointCuts.length > 0"
        [hidden]="unitOperation.solveOption !== 'OutletTBP'"
        style="max-width: 100%">
        <div class="form-horizontal">
          <div class="form-group">
            <label [attr.for]="'bpcUnit'" class="pw-label control-label col-sm-4"> Unit </label>

            <div class="col-sm-4">
              <sfe-unit-select
                [selectId]="'bpcUnit'"
                [simVar]="unitOperation.boilingPointCuts[0].values[0]"
                [elClass]="'form-control'"></sfe-unit-select>
            </div>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Yield</th>
              <ng-container *ngFor="let ratioVariable of unitOperation.boilingPointCuts; let i = index">
                <th>{{ findDistributionRatioName(i) }}</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let percent of unitOperation.percentCuts; let i = index">
              <td class="text-capitalize">{{ percent * 100 }}%</td>
              <td *ngFor="let bpc of unitOperation.boilingPointCuts; let j = index">
                <sfe-value-input
                  [simVar]="unitOperation.boilingPointCuts[j].values[i]"
                  [elClass]="'form-control'"
                  [inputId]="unitOperation.boilingPointCuts[j].values[i].id + 'bpc'"
                  [multiple]="true"
                  [convertedBy]="'bpcUnit'">
                </sfe-value-input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="unitOperation.solveOption === 'Component split'" style="max-width: 100%">
        <table *ngIf="unitOperation.distributionRatioVariables">
          <thead>
            <tr>
              <th>Component Name</th>
              <ng-container *ngFor="let ratioVariable of unitOperation.distributionRatioVariables; let i = index">
                <th>{{ findDistributionRatioName(i) }}</th>
              </ng-container>
            </tr>
          </thead>
          <tr *ngFor="let component of components; let componentIndex = index">
            <td class="text-capitalize">{{ component }}</td>
            <td *ngFor="let ratioVariable of unitOperation.distributionRatioVariables; let ratioVariableIndex = index">
              <input
                id="{{ component.replace(' ', '') + ratioVariableIndex }}"
                type="text"
                (change)="
                  onDistributionRatioVariableValueChange({ratioVariableIndex,componentIndex, componentName: component.replace(' ', '')})
                "
                class="form-control"
                [(ngModel)]="ratioVariable[component.replace(' ', '')]"
                [readonly]="ratioVariableIndex === unitOperation.distributionRatioVariables.length - 1" />
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="economics">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">OPEX</h4>

        <div class="form-group">
          <label [attr.for]="'opexVar'" class="pw-label control-label col-sm-4"> Variable </label>
          <sim-var-input [inputId]="'opexVar'" [simVar]="unitOperation.opexVar"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexGasDiesel'" class="pw-label control-label col-sm-4"> Gas Diesel </label>
          <sim-var-input [inputId]="'opexGasDiesel'" [simVar]="unitOperation.opexGasDiesel"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexPower'" class="pw-label control-label col-sm-4"> Power </label>
          <sim-var-input [inputId]="'opexPower'" [simVar]="unitOperation.opexPower"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexCarbonDioxide'" class="pw-label control-label col-sm-4"> CO2 </label>
          <sim-var-input
            [inputId]="'opexCarbonDioxide'"
            [simVar]="unitOperation.opexCarbonDioxide"
            [readOnly]="true"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexFixed'" class="pw-label control-label col-sm-4"> Fixed </label>
          <sim-var-input [inputId]="'opexFixed'" [simVar]="unitOperation.opexFixed"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexTotal'" class="pw-label control-label col-sm-4"> Total </label>
          <sim-var-input [inputId]="'opexTotal'" [simVar]="unitOperation.opexTotal" [readOnly]="true"></sim-var-input>
        </div>

        <hr />
        <h4 class="subtitle">CAPEX</h4>

        <div class="form-group">
          <label [attr.for]="'capexAmortized'" class="pw-label control-label col-sm-4"> Amortized CAPEX </label>
          <sim-var-input [inputId]="'capexAmortized'" [simVar]="unitOperation.capexAmortized"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'capexTotal'" class="pw-label control-label col-sm-4"> Total </label>
          <sim-var-input [inputId]="'capexTotal'" [simVar]="unitOperation.capexTotal" [readOnly]="true"></sim-var-input>
        </div>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="general">
    <div class="tab-content no-padding no-border">
      <h4 class="subtitle">GHG</h4>
      <div class="form-horizontal">
        <div class="form-group">
          <label [attr.for]="'ghgIntensity'" class="pw-label control-label col-sm-4"> GHG Intensity </label>
          <sim-var-input [inputId]="'ghgIntensity'" [simVar]="unitOperation.ghgIntensity"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'ghgEmissions'" class="pw-label control-label col-sm-4"> GHG Emissions </label>
          <sim-var-input
            [inputId]="'ghgEmissions'"
            [readOnly]="true"
            [simVar]="unitOperation.ghgEmissions"></sim-var-input>
        </div>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="utilities">
    <div class="tab-content no-padding no-border">
      <h4 class="subtitle">Amine</h4>
      <div class="form-horizontal">
        <div class="form-group">
          <label [attr.for]="'h2sProductionRate'" class="pw-label control-label col-sm-4"> H2S Production Rate </label>
          <sim-var-input [inputId]="'h2sProductionRate'" [simVar]="unitOperation.h2sProductionRate"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'h2sProduction'" class="pw-label control-label col-sm-4"> H2S Production </label>
          <sim-var-input
            [inputId]="'h2sProduction'"
            [readOnly]="true"
            [simVar]="unitOperation.h2sProduction"></sim-var-input>
        </div>

        <sob-steam-use-utility [unitOperation]="unitOperation"></sob-steam-use-utility>
        <sob-gas-contributor [unitOperation]="unitOperation"></sob-gas-contributor>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="constraints">
    <p style="text-align: right; position: relative">
      <span style="position: absolute; right: -25px; top: -20px; font-weight: bold">Active</span>
    </p>
    <div class="form-horizontal">
      <div class="form-group">
        <label [attr.for]="'capacity'" class="pw-label control-label col-sm-4"> Maximum Feed Capacity </label>
        <sim-var-input [inputId]="'capacity'" [isConstraint]="true" [simVar]="unitOperation.capacity"> </sim-var-input>
      </div>
      <div class="form-group">
        <label [attr.for]="'minimumFlow'" class="pw-label control-label col-sm-4"> Minimum Flow </label>
        <sim-var-input [inputId]="'minimumFlow'" [isConstraint]="true" [simVar]="unitOperation.minimumFlow">
        </sim-var-input>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="vacComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
