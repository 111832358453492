import { Component, Input, OnInit } from '@angular/core';
import { PressureCalculationMode } from '../../../_config/pressure-calculation-mode.enum';
import { WaterValve } from '../../../_models/_unit-operations/utilties/water-valve';

@Component({
  selector: 'sob-water-valve',
  templateUrl: './water-valve.component.html',
  styleUrls: ['./water-valve.component.css'],
})
export class WaterValveComponent implements OnInit {
  @Input() unitOperation: WaterValve;

  ngOnInit() {
    if (this.unitOperation.pressureCalculationMode === PressureCalculationMode.OUTLET_PRESSURE) {
      $('#selectMode').val(PressureCalculationMode.OUTLET_PRESSURE);
      $('#pressure-drop').hide();
      $('#outlet-pressure').show();
    } else if (this.unitOperation.pressureCalculationMode === PressureCalculationMode.PRESSURE_DROP) {
      $('#pressure-drop').show();
      $('#outlet-pressure').hide();
    }
  }

  pressureCalculationModeChanged(calculationValue) {
    if (calculationValue === PressureCalculationMode.OUTLET_PRESSURE) {
      this.unitOperation.pressureCalculationMode = PressureCalculationMode.OUTLET_PRESSURE;
      $('#pressure-drop').hide();
      $('#outlet-pressure').show();
    } else if (calculationValue === PressureCalculationMode.PRESSURE_DROP) {
      this.unitOperation.pressureCalculationMode = PressureCalculationMode.PRESSURE_DROP;
      $('#pressure-drop').show();
      $('#outlet-pressure').hide();
    }
  }
}
