import { Case } from '../case';
import { UnitOperation } from '../unit-operation';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { SuncorUnitOperation } from '../_unit-operations/suncor-unit-operation';
import { GhgReportValue, MixedGhgReportValue, ReportValue } from './report-value';
import { ReportDataSet } from './report-data-set';
import { FuelGasFlare } from '../_unit-operations/fuel-gas/fuel-gas-flare';
import { WaterBoiler } from '../_unit-operations/utilties/water-boiler';
import { WaterCogeneration } from '../_unit-operations/utilties/water-cogeneration';
import { Upgrader } from '../_unit-operations/upgrader';
import { Splitter } from '../_unit-operations/splitter';
import { Mixer } from '../_unit-operations/mixer';

declare let unitConverter: any;

export class GhgEmissionsReport {
  totalGhg: number;
  totalSuncorGhg: number;
  contributionByCategory: ReportValue[] = [];
  contributionByUnit: ReportDataSet[] = [];
  contributionByUpgraderSubFlowsheet: ReportDataSet[] = [];

  unit: string;
  quantity: string;

  constructor(c: Case) {
    this.initReport(c);
  }

  initReport(c: Case) {
    this.totalGhg = 0;

    const diluentGhg = new ReportValue(
      0,
      unitOperationsConfig.diluentSource.displayName,
      unitOperationsConfig.diluentSource.key
    );
    const diluentSet = new ReportDataSet(
      unitOperationsConfig.diluentSource.displayName,
      unitOperationsConfig.diluentSource.key
    );

    const mineGhg = new ReportValue(0, unitOperationsConfig.mine.displayName, unitOperationsConfig.mine.key);
    const mineSet = new ReportDataSet(unitOperationsConfig.mine.displayName, unitOperationsConfig.mine.key);

    const pipeGhg = new ReportValue(0, unitOperationsConfig.pipe.displayName, unitOperationsConfig.pipe.key);
    const pipeSet = new ReportDataSet(unitOperationsConfig.pipe.displayName, unitOperationsConfig.pipe.key);

    const refineryGhg = new ReportValue(
      0,
      unitOperationsConfig.refinery.displayName,
      unitOperationsConfig.refinery.key
    );
    const refinerySet = new ReportDataSet(unitOperationsConfig.refinery.displayName, unitOperationsConfig.refinery.key);

    const sagdGhg = new ReportValue(0, unitOperationsConfig.sagd.displayName, unitOperationsConfig.sagd.key);
    const sagdSet = new ReportDataSet(unitOperationsConfig.sagd.displayName, unitOperationsConfig.sagd.key);

    const upgraderGhg = new ReportValue(
      0,
      unitOperationsConfig.upgrader.displayName,
      unitOperationsConfig.upgrader.key
    );
    const upgraderSet = new ReportDataSet(unitOperationsConfig.upgrader.displayName, unitOperationsConfig.upgrader.key);

    const thirdPartyRefineryGhg = new ReportValue(
      0,
      unitOperationsConfig.thirdPartyRefinery.displayName,
      unitOperationsConfig.thirdPartyRefinery.key
    );

    const thirdPartyRefinerySet = new ReportDataSet(
      unitOperationsConfig.thirdPartyRefinery.displayName,
      unitOperationsConfig.thirdPartyRefinery.key
    );

    const thirdPartySourceGhg = new ReportValue(
      0,
      unitOperationsConfig.thirdPartySource.displayName,
      unitOperationsConfig.thirdPartySource.key
    );

    const thirdPartySourceSet = new ReportDataSet(
      unitOperationsConfig.thirdPartySource.displayName,
      unitOperationsConfig.thirdPartySource.key
    );

    const bitumenConversionGhg = new ReportValue(
      0,
      unitOperationsConfig.bitumenConversion.displayName,
      unitOperationsConfig.bitumenConversion.key
    );

    const bitumenConversionSet = new ReportDataSet(
      unitOperationsConfig.bitumenConversion.displayName,
      unitOperationsConfig.bitumenConversion.key
    );

    const units = c.filterUnitOperations((uo: UnitOperation) => {
      return uo.category !== unitOperationsConfig.mixer.key && uo.category !== unitOperationsConfig.splitter.key;
    }) as Array<SuncorUnitOperation>;

    for (const uo of units) {
      switch (uo.category) {
        case unitOperationsConfig.diluentSource.key:
          diluentGhg.value += uo.ghgEmissions.value;
          diluentSet.addToData(new GhgReportValue(uo.ghgEmissions.value, uo.getAlternativeGhgIntensity(), uo.name));
          break;

        case unitOperationsConfig.mine.key:
          mineGhg.value += uo.ghgEmissions.value;
          mineSet.addToData(new GhgReportValue(uo.ghgEmissions.value, uo.getAlternativeGhgIntensity(), uo.name));
          break;

        case unitOperationsConfig.pipe.key:
          pipeGhg.value += uo.ghgEmissions.value;
          pipeSet.addToData(new GhgReportValue(uo.ghgEmissions.value, uo.getAlternativeGhgIntensity(), uo.name));
          break;

        case unitOperationsConfig.refinery.key:
          refineryGhg.value += uo.ghgEmissions.value;
          refinerySet.addToData(new GhgReportValue(uo.ghgEmissions.value, uo.getAlternativeGhgIntensity(), uo.name));
          break;

        case unitOperationsConfig.sagd.key:
          sagdGhg.value += uo.ghgEmissions.value;
          sagdSet.addToData(new GhgReportValue(uo.ghgEmissions.value, uo.getAlternativeGhgIntensity(), uo.name));
          break;

        case unitOperationsConfig.upgrader.key:
          const upg = uo as Upgrader;
          upgraderGhg.value += uo.ghgEmissions.value;
          const reportValue = new GhgReportValue(
            uo.ghgEmissions.value,
            uo.getAlternativeGhgIntensity(),
            uo.name,
            upg.isFlowsheetOwner ? uo.id : undefined
          );

          reportValue.setIsFlowsheetOwner(upg.isFlowsheetOwner);

          upgraderSet.addToData(reportValue);
          break;

        case unitOperationsConfig.thirdPartyRefinery.key:
          thirdPartyRefineryGhg.value += uo.ghgEmissions.value;
          thirdPartyRefinerySet.addToData(
            new GhgReportValue(uo.ghgEmissions.value, uo.getAlternativeGhgIntensity(), uo.name)
          );
          break;

        case unitOperationsConfig.thirdPartySource.key:
          thirdPartySourceGhg.value += uo.ghgEmissions.value;
          thirdPartySourceSet.addToData(
            new GhgReportValue(uo.ghgEmissions.value, uo.getAlternativeGhgIntensity(), uo.name)
          );
          break;

        case unitOperationsConfig.bitumenConversion.key:
          bitumenConversionGhg.value += uo.ghgEmissions.value;
          bitumenConversionSet.addToData(
            new GhgReportValue(uo.ghgEmissions.value, uo.getAlternativeGhgIntensity(), uo.name)
          );
          break;
      }
      if (uo.ghgEmissions) {
        this.totalGhg += uo.ghgEmissions.value;
      }
    }

    this.totalSuncorGhg = this.totalGhg - thirdPartyRefineryGhg.value;
    this.contributionByCategory.push(diluentGhg);
    this.contributionByCategory.push(mineGhg);
    this.contributionByCategory.push(pipeGhg);
    this.contributionByCategory.push(refineryGhg);
    this.contributionByCategory.push(sagdGhg);
    this.contributionByCategory.push(upgraderGhg);
    this.contributionByCategory.push(bitumenConversionGhg);
    this.contributionByCategory.push(thirdPartyRefineryGhg);
    this.contributionByCategory.push(thirdPartySourceGhg);

    this.contributionByUnit.push(diluentSet);
    this.contributionByUnit.push(mineSet);
    this.contributionByUnit.push(pipeSet);
    this.contributionByUnit.push(refinerySet);
    this.contributionByUnit.push(sagdSet);
    this.contributionByUnit.push(upgraderSet);
    this.contributionByUnit.push(bitumenConversionSet);
    this.contributionByUnit.push(thirdPartyRefinerySet);
    this.contributionByUnit.push(thirdPartySourceSet);

    const sv = units.length > 0 ? units[0].ghgEmissions : null;

    for (const u of upgraderSet.data) {
      this.contributionByUpgraderSubFlowsheet.push(this.getUpgraderDataSet(u.name, u.childDataId, c));
    }

    if (sv) {
      this.unit = unitConverter.units.Massflowrate.MMTPA;
      this.quantity = sv.quantity;
      this.convertReportValues(sv.getInternalUnit());
    }
  }

  convertReportValues(internalUnit: string) {
    for (const contribution of this.contributionByCategory) {
      // alright, .parseFloatString should not be needed :/ but by default, the .convert function returns
      // an empty string when a NaN value is converted... maybe it should return the NaN value as it is
      // and the empty string should be returned by the format() function (?)
      contribution.value = unitConverter.parseFloatString(
        unitConverter(this.quantity).convert(contribution.value, internalUnit, this.unit).round().getValue()
      );
    }

    for (const cbu of this.contributionByUnit) {
      for (const contrib of cbu.data) {
        contrib.value = unitConverter.parseFloatString(
          unitConverter(this.quantity).convert(contrib.value, internalUnit, this.unit).round().getValue()
        );
      }
    }

    for (const cbu of this.contributionByUpgraderSubFlowsheet) {
      for (const contrib of cbu.data) {
        contrib.value = unitConverter.parseFloatString(
          unitConverter(this.quantity).convert(contrib.value, internalUnit, this.unit).round().getValue()
        );
      }

      cbu.total = unitConverter.parseFloatString(
        unitConverter(this.quantity).convert(cbu.total, internalUnit, this.unit).round().getValue()
      );
    }

    this.totalGhg = unitConverter.parseFloatString(
      unitConverter(this.quantity).convert(this.totalGhg, internalUnit, this.unit).round().getValue()
    );

    this.totalSuncorGhg = unitConverter.parseFloatString(
      unitConverter(this.quantity).convert(this.totalSuncorGhg, internalUnit, this.unit).round().getValue()
    );
  }

  getUpgraderDataSet(upgraderName: string, upgraderId: string, c: Case) {
    if (!upgraderName) {
      upgraderName = c.getUnitOperation(upgraderId).name;
    }

    const dataSet = new ReportDataSet(upgraderName, upgraderId);

    const upgraderUnitOperationsEvenNestedOnes = c.filterUnitOperations(uo => {
      return uo.flowsheetId === upgraderId && !(uo instanceof Splitter || uo instanceof Mixer);
    });

    const subFlowsheetOwners = upgraderUnitOperationsEvenNestedOnes.filter(uo => uo.isFlowsheetOwner);

    for (const sfo of subFlowsheetOwners) {
      const subFlowsheetUnitOperations = c.filterUnitOperations(uo => uo.flowsheetId === sfo.id);
      upgraderUnitOperationsEvenNestedOnes.push(...subFlowsheetUnitOperations);
    }

    for (const uo of upgraderUnitOperationsEvenNestedOnes) {
      // ignore flowsheet owners
      if (uo.isFlowsheetOwner) {
        continue;
      }

      if (uo instanceof SuncorUnitOperation) {
        dataSet.addToData(new MixedGhgReportValue(uo.ghgEmissions.value, uo.name, uo.getCategoryDisplayName()));
      } else if (uo instanceof FuelGasFlare) {
        // and what about ghg intensity?
        dataSet.addToData(new MixedGhgReportValue(uo.ghgEmissions.value, uo.name, uo.getCategoryDisplayName()));
      } else if (uo instanceof WaterBoiler) {
        dataSet.addToData(new MixedGhgReportValue(uo.ghgEmissions.value, uo.name, uo.getCategoryDisplayName()));
      } else if (uo instanceof WaterCogeneration) {
        dataSet.addToData(new MixedGhgReportValue(uo.ghgEmissions.value, uo.name, uo.getCategoryDisplayName()));
      }
    }

    return dataSet;
  }
}
