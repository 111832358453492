import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CodeEditorService } from '../../code-editor/code-editor.service';
import { DefaultCodeProvider } from '../../../_services/custom-code-providers/default-code-provider';

@Component({
  selector: 'sob-open-reactive-code-editor-button',
  templateUrl: './open-reactive-code-editor-button.component.html',
  styleUrls: ['./open-reactive-code-editor-button.component.css'],
})
export class OpenReactiveCodeEditorButtonComponent implements OnDestroy {
  private ngUnsubscribe = new Subject();

  @Input() code: string;
  @Input() defaultCodeProvider: DefaultCodeProvider;
  @Input() disabled: boolean;
  @Input() instanceId: string;
  @Output() codeSaved = new EventEmitter<{ code: string; instanceId: string }>();

  constructor(private codeEditorService: CodeEditorService) {
    this.codeEditorService.closeAndSaveCodeRequest.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.codeSaved.emit({ code: res.code, instanceId: res.instanceId });
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openReactiveCodeEditor() {
    $('#codeEditorModal').modal('show');
    $('#codeEditorInstanceId').val(this.instanceId);
    this.codeEditorService.setEditorCode({ code: this.code, defaultCodeProvider: this.defaultCodeProvider });
  }
}
