import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import * as go from 'gojs';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { debounce, detectBody, flowsheetHeight } from '../app.helpers';
import { UnitOperation } from '../_models';
import { CoreService } from '../_services/core.service';
import { FlowsheetService } from '../_services/flowsheet.service';
import { KpiManager } from '../_models/_case-study/kpi-manager';
import { CaseStudyManager } from '../_models/_case-study/case-study-manager';
import { ObjectiveFunction } from '../_models/_optimization/objective-function';
import { ProgressIndicatorService } from './progress-indicator/progress-indicator.service';
import { initStackedModals } from '../ui.helpers';
import { UncertaintyAnalysis } from '../_models/_case-study/uncertainty-analysis';
import { UncertaintyAnalysisService } from '../_services/case-study/uncertainty-analysis.service';
import { AssayManager } from '../_models/_fluid/assay-manager';
import { FlowsheetDiagramService } from '../_services/flowsheet-diagram/flowsheet-diagram.service';
import { FileService } from '../_services/file.service';
import { UserService } from '../_services/user.service';
import { BaseObjectFormGroupWrapper } from '../_form-utils/base-object-form-group-wrapper';
import { environment } from '../../environments/environment';
import { CaseStatsService } from '../_services/case-stats.service';
import { ServerUtcDateService } from '../_services/helpers/server-utc-date.service';
import { CommentsService } from '../_services/helpers/comments.service';

@Component({
  selector: 'sob-flowsheet',
  templateUrl: './flowsheet.component.html',
  styleUrls: ['./flowsheet.component.css'],
})
export class FlowsheetComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  // TODO add all subscriptions to SubSink
  private subSink = new SubSink();

  currentUnitOp: UnitOperation;
  caseStudyManager: CaseStudyManager;
  kpiManager: KpiManager;
  assayManager: AssayManager;
  objectiveFunction: ObjectiveFunction;

  solveUncertaintyAnalysis: (analysis: UncertaintyAnalysis) => Observable<any>;
  private openingPropertyWindow: boolean;

  private calculateHeightFlag = false;

  constructor(
    private ref: ChangeDetectorRef,
    private coreService: CoreService,
    private uncertaintyAnalysisService: UncertaintyAnalysisService,
    private flowsheetDiagramService: FlowsheetDiagramService,
    private flowsheetService: FlowsheetService,
    private fileService: FileService,
    private userService: UserService,
    private progressIndicator: ProgressIndicatorService,
    private caseStatsService: CaseStatsService,
    private utcDateService: ServerUtcDateService,
    private commentsService: CommentsService
  ) {
    this.flowsheetService.closePropertyWindowRequest.subscribe(() => {
      this.closePropertyWindowAndSave();
    });

    this.flowsheetService.saveCustomCodeRequest.subscribe(() => {
      this.saveUnitOperationWithCustomCode();
    });

    this.flowsheetService.openPropertyWindowRequest.subscribe((uo: UnitOperation) => {
      this.loadPropertyView(uo);
    });

    this.flowsheetService.subFlowsheetOpenedRequest.subscribe(() => {
      this.calculateHeightFlag = true;
    });

    this.flowsheetService.subFlowsheetClosedRequest.subscribe(() => {
      this.calculateHeightFlag = false;
    });

    this.setCaseLevelPropertyValues();

    // TODO consider subscribing to currentCaseReplacedRequest
    this.coreService.caseChangedRequest.subscribe(() => {
      this.setCaseLevelPropertyValues();
      this.ref.detectChanges();
      this.progressIndicator.hide();
      this.solveUncertaintyAnalysis = (analysis: UncertaintyAnalysis) => {
        return this.uncertaintyAnalysisService.solveUncertaintyAnalysis(analysis);
      };
    });

    window.addEventListener('beforeunload', event => {
      event.preventDefault();

      // this error can be forgiven, the MDN docs provide an example exactly like this
      // eslint-disable-next-line no-return-assign
      return (event.returnValue = 'Unsaved modifications');
    });

    if (!environment.production) {
      document.onkeydown = evt => {
        // add a key combination for quickly opening a file
        if (evt.ctrlKey && evt.key === '.') {
          this.flowsheetService.closePropertyWindow();
          $('#openCaseModal').modal('show');
        }

        // and for opening the summary report
        if (evt.ctrlKey && evt.key === ',') {
          this.flowsheetService.closePropertyWindow();
          $('#summaryReportModal').modal('show');
        }
      };
    }

    this.subSink.add(
      this.utcDateService.getServerSeedDate().subscribe(serializedDate => {
        this.utcDateService.setServerSeedDate(serializedDate);
      })
    );
  }

  private setCaseLevelPropertyValues() {
    this.caseStudyManager = this.coreService.currentCase.caseStudyManager;
    this.kpiManager = this.caseStudyManager.kpiManager;
    this.assayManager = this.coreService.currentCase.assayManager;
    this.objectiveFunction = this.coreService.currentCase.objectiveFunction;
  }

  ngOnInit() {
    // eslint-disable-next-line max-len
    (go as any).Diagram.licenseKey =
      // eslint-disable-next-line max-len
      '73f147e5ba0537c702d90776423d6af919a17564ce841ba4090345f3ba0d6a06329fee2851d38b90d5ac49fe4a7f93d1d8c169209248573de030d5da13e08faaba3375e5400b4589a00a21cbcaff78f1a87c71e0d2b322f2dc78dea2eabad39f5debf3cb4ed50eb9';
  }

  ngAfterViewInit(): void {
    flowsheetHeight();
    detectBody();
    initStackedModals();

    const debounced = debounce(
      function () {
        flowsheetHeight();
        detectBody();
      },
      500,
      false
    );

    $(window).bind('resize', debounced);

    $('.metismenu a').click(() => {
      setTimeout(() => {
        flowsheetHeight();
      }, 300);
    });

    $(document).on('change', 'input.Default, input.SolverCalculated, input.UnDefined', () => {
      $(this).removeClass('Default');
      $(this).removeClass('UnDefined');
      $(this).removeClass('SolverCalculated');
      $(this).addClass('UserSpecified');
    });

    $('[data-toggle="tooltip"]').tooltip();
  }

  ngAfterViewChecked() {
    if (this.calculateHeightFlag) {
      this.calculateHeightFlag = false;
      flowsheetHeight();
    } else {
      flowsheetHeight();
    }
  }

  private setCurrentUnitOp(uo: UnitOperation): void {
    this.propertyWindowFormGroupWrapper.clear();
    this.currentUnitOp = null;
    this.ref.detectChanges();
    this.currentUnitOp = uo;
    this.ref.detectChanges();
  }

  private loadPropertyView(uo: UnitOperation) {
    if (this.currentUnitOp) {
      this.flowsheetService.closePropertyWindow();
    }
    this.setCurrentUnitOp(uo);
    this.openPropertyWindow();
  }

  private openPropertyWindow(): void {
    const $propertyWindow = $('#propertyWindow');

    this.openingPropertyWindow = true;
    if (!$propertyWindow.is(':visible')) {
      $propertyWindow.toggle('slide', {
        direction: 'right',
        complete: () => {
          this.openingPropertyWindow = false;
        },
      });
    }

    $('#closePropertyWindow').removeAttr('disabled');

    // if any unit operation was previously marked as non-deletable it should be marked as deletable
    this.flowsheetService.makeAllUnitOperationsDeletable();
    this.flowsheetService.preventUnitOperationDeletion(this.currentUnitOp);
  }

  private closePropertyWindowAndSave(): void {
    if ($('#propertyWindow').is(':visible')) {
      this.closePropertyWindow();
      this.saveUnitOperation();

      // this.currentUnitOp = undefined;
      // this.ref.detectChanges();
    }
  }

  private closePropertyWindow(): void {
    this.flowsheetService.makeAllUnitOperationsDeletable();
    $('#closePropertyWindow').attr('disabled', 'disabled');
    $('#propertyWindow').toggle('slide', {
      direction: 'right',
      complete: () => {
        if (!this.openingPropertyWindow) {
          this.currentUnitOp = undefined;
        }
      },
    });
  }

  private saveUnitOperation(): void {
    // call prepare for save here?
    const isReactiveFormUsed = this.flowsheetService.propertyWindowFormGroupWrapper.hasControls();
    if (isReactiveFormUsed) {
      this.coreService.saveUnitOperationFromReactiveForm(this.propertyWindowFormGroupWrapper, this.currentUnitOp);
      return;
    }

    this.coreService.saveUnitOperationFromTemplateDrivenForm(this.currentUnitOp);
  }

  private saveUnitOperationWithCustomCode(): void {
    const isReactiveFormUsed = this.flowsheetService.propertyWindowFormGroupWrapper.hasControls();
    if (isReactiveFormUsed) {
      this.coreService.saveUnitOperationWithReactiveFormWhenCustomCodeChange(
        this.currentUnitOp,
        this.propertyWindowFormGroupWrapper
      );
      return;
    }

    this.coreService.saveUnitOperationWhenCustomCodeChange(this.currentUnitOp);
  }

  get activeFlowsheetOwner(): UnitOperation | undefined {
    if (this.coreService.currentCase.getActiveFlowsheet()) {
      const uoId = this.coreService.currentCase.getActiveFlowsheet().unitOperationId;
      return this.coreService.currentCase.getUnitOperation(uoId);
    }

    return undefined;
  }

  get propertyWindowFormGroupWrapper(): BaseObjectFormGroupWrapper {
    return this.flowsheetService.propertyWindowFormGroupWrapper;
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
