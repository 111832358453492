import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../_services/auth.service';

@Injectable()
export class RoleAdminGuard implements CanActivate, CanLoad {
  private readonly token: string;
  private readonly role: string;

  constructor(private router: Router, private authService: AuthService) {
    this.token = authService.getToken();
    this.role = authService.getRole();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated() && this.role === 'ROLE_ADMIN') {
      return true;
    }
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  canLoad(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.authService.isAuthenticated() && this.role === 'ROLE_ADMIN') {
      return true;
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: '/' } });
    return false;
  }
}
