import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';

@Injectable()
export class AuthService {
  user: string;

  private userNameSubject = new Subject();
  userNameRequest = this.userNameSubject.asObservable();
  // This only authenticates, it concerns itself with nothing else.
  constructor(
    private http: HttpClient,
    private _route: ActivatedRoute,
    private userService: UserService,
    private _router: Router
  ) {}

  login(username: string, password: string) {
    return this.http.post(`${environment.authUrl}/login`, { username, password }, { headers: this.getHeaders() }).pipe(
      map((jwt: any) => {
        if (jwt) {
          this.initLocalStorageItems(jwt);
        }
      })
    );
  }

  initLocalStorageItems(jwt) {
    localStorage.setItem('currentUser', JSON.stringify(jwt));
    localStorage.setItem('token', jwt.auth_token);
    localStorage.setItem('userId', jwt.id);
    this.setUser(jwt.id);
  }

  setUser(id) {
    this.userService.getUser(id).subscribe(
      success => {
        this.user = `${success.firstName} ${success.lastName}`;
        localStorage.setItem('userName', this.user);
        localStorage.setItem('user', JSON.stringify(success));
        this.userNameSubject.next(this.user);
      },
      error => {
        console.error(error.error);
      }
    );
  }

  sendPasswordRecovery(username: string) {
    return this.http.post(`${environment.accountUrl}/passwordRecovery`, { username }, { headers: this.getHeaders() });
  }

  setNewPassword(userId: string, token: string, password: string) {
    return this.http.post(
      `${environment.accountUrl}/newPassword`,
      { userId, token, password },
      { headers: this.getHeaders() }
    );
  }

  // Should do a lot more(?)
  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.clear();
    this._router.navigate(['/login']);
  }

  getIdentity() {
    const identity = JSON.parse(localStorage.getItem('currentUser'));
    if (identity) {
      return identity;
    }
    return null;
  }

  getToken() {
    const token = localStorage.getItem('token');
    if (token) {
      return token;
    }
    return null;
  }

  getRole() {
    const token = localStorage.getItem('token');
    if (token) {
      const jwtData = token.split('.');
      const payload = JSON.parse(atob(jwtData[1]));
      let role = '';
      // noice
      role = payload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      return role;
    }
    return '';
  }

  isAuthenticated() {
    return localStorage.getItem('token') && localStorage.getItem('currentUser') && !this.isTokenExpired(10);
  }

  isTokenExpired(offsetSeconds?: number): boolean {
    const date = this.getTokenExpirationDate();
    offsetSeconds = offsetSeconds || 10;
    if (!date) {
      return false;
    }

    return !(date.valueOf() > new Date().valueOf() + offsetSeconds);
  }

  getTokenExpirationDate(): Date {
    const token = this.getToken();
    if (token) {
      const jwtData = token.split('.');
      const payload = JSON.parse(atob(jwtData[1]));
      let exp = 0;
      exp = payload.exp;
      const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
      date.setUTCSeconds(exp);
      return date;
    }
    return undefined;
  }

  private getHeaders() {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return headers;
  }
}
