<table class="table" [formGroup]="ratioVariablesFormGroup">
  <thead>
    <tr>
      <th class="ratio-variables-first-column">Name</th>
      <th>Min</th>
      <th>Max</th>
      <th>Value</th>
    </tr>
  </thead>

  <tbody formArrayName="ratioVariables">
    <ng-container *ngFor="let group of ratioVariablesFormArray.controls; let i = index">
      <tr [formGroupName]="i">
        <td>{{ findDistributionRatioName(i) }}</td>
        <td>
          <input
            [readOnly]="isRangeControlReadonly()"
            (change)="sanitizeRangeValues(i, 'minimumValue')"
            type="text"
            class="form-control"
            formControlName="minimumValue" />
        </td>
        <td>
          <input
            [readOnly]="isRangeControlReadonly()"
            (change)="sanitizeRangeValues(i, 'maximumValue')"
            type="text"
            class="form-control"
            formControlName="maximumValue" />
        </td>

        <td>
          <input
            (change)="roundValues(i)"
            type="text"
            class="form-control"
            formControlName="value"
            [readOnly]="isValueControlReadonly(i)" />
        </td>
      </tr>

      <tr *ngIf="areFormControlsValid(i)">
        <td colspan="3">
          <span class="text-danger">
            <span *ngIf="formControlsHasErrors(i, 'required')">Please enter a value</span>
            <span *ngIf="formControlsHasErrors(i, 'max')">Distribution ratio cannot be greater than 1</span>
            <span *ngIf="formControlsHasErrors(i, 'min')">Distribution ratio cannot be negative</span>
          </span>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
