import { ChangeDetectorRef, Component } from '@angular/core';
import { ObjectiveFunction } from '../../_models/_optimization/objective-function';
import { CoreService } from '../../_services/core.service';
import { LogManagerService } from '../log-manager/log-manager.service';

@Component({
  selector: 'sob-objective-function-selector',
  templateUrl: './objective-function-selector.component.html',
  styleUrls: ['./objective-function-selector.component.css'],
})
export class ObjectiveFunctionSelectorComponent {
  objectiveFunction: ObjectiveFunction;

  constructor(private coreService: CoreService, private logManager: LogManagerService, private ref: ChangeDetectorRef) {
    this.objectiveFunction = this.coreService.currentCase.objectiveFunction;

    // TODO this is relevant only when the current case is replaced
    this.coreService.caseChangedRequest.subscribe(() => {
      this.objectiveFunction = this.coreService.currentCase.objectiveFunction;
      this.ref.detectChanges();
    });
  }

  objectiveFunctionChanged(functionType) {
    this.objectiveFunction.objectiveFunctionType = functionType;
    this.logManager.info(`Objective function set to ${this.objectiveFunction.objectiveFunctionName}`);
    this.coreService.clearCurrentCaseResults();
  }
}
