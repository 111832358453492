<div role="tabpanel" class="tab-pane fade in" id="srConstraintRanking" *ngIf="constraintRanking.length > 0">
  <ul class="nav nav-pills" role="tablist">
    <li role="presentation">
      <a
        href="#rConstraintRankingChart"
        (click)="buildConstraintRankingGraphics(currentConstraints)"
        role="tab"
        data-toggle="tab"
        aria-controls="rConstraintRankingChart">
        Bar Chart
      </a>
    </li>
    <!--    -->
    <li role="presentation" class="active">
      <a href="#rConstraintRankingTable" role="tab" data-toggle="tab" aria-controls="rConstraintRankingTable">
        Table
      </a>
    </li>
  </ul>

  <div class="tab-content tab-content-padding">
    <div role="tabpanel" class="tab-pane fade in" id="rConstraintRankingChart">
      <div class="col-xs-12 text-right">
        <a
          title="Switch chart type"
          class="btn btn-info"
          style="margin-bottom: 20px"
          (click)="switchConstraintChartType()">
          <i *ngIf="objFunctionUnit === 'MMCAD$/kbpcd'">%</i>
          <i *ngIf="objFunctionUnit === '%'">MMCAD$/kbpcd</i>
        </a>
      </div>
      <div id="rankingChart"></div>
    </div>

    <div role="tabpanel" class="tab-pane fade in active" id="rConstraintRankingTable">
      <div class="row">
        <div class="col-xs-12">
          <table class="table table-bordered" id="srConstraintTable">
            <thead>
              <tr>
                <th>Block Name</th>
                <th>Constraint</th>
                <th>Current Value</th>
                <th>Unit</th>
                <th>
                  Obj. function change rate
                  <button
                    class="btn btn-link"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Improvement in the objective function that can be achieved by relaxing the constraint by one unit. The change rate is only valid in a certain range as other constraints may become bottlenecks">
                    <i class="fa fa-info-circle"></i>
                  </button>
                </th>
                <th>Unit</th>
                <th>Owner Flowsheet</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let mve of constraintRanking">
                <tr>
                  <td>{{ mve.unitOperationName }}</td>
                  <td>{{ mve.variableName }}</td>
                  <td>{{ mve.value | number }}</td>
                  <td>{{ mve.unit }}</td>
                  <td>{{ mve.objFunctionChangeRate | number }}</td>
                  <td>{{ mve.objFunctionChangeRateUnit }}</td>
                  <td>{{ getOwnerFlowsheetName(mve) }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
