import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { EditorState } from '@codemirror/state';
import { CompletionContext, CompletionResult, autocompletion, Completion } from '@codemirror/autocomplete';
import { EditorView } from '@codemirror/view';
import { csharp } from '@codemirror/legacy-modes/mode/clike';
import { StreamLanguage, syntaxTree } from '@codemirror/language';
import { DefaultCodeProvider } from '../../_services/custom-code-providers/default-code-provider';
import { CodeEditorService } from './code-editor.service';
import { codemirrorBasicSetup } from './codemirror-custom-basic-setup';

@Component({
  selector: 'sob-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CodeEditorComponent implements AfterViewInit {
  defaultCodeProvider?: DefaultCodeProvider;
  private editorView?: EditorView;

  constructor(private codeEditorService: CodeEditorService) {
    this.codeEditorService.setCodeRequest.subscribe(codeData => {
      this.defaultCodeProvider = codeData.defaultCodeProvider;
      this.setCode(codeData.code);
    });
  }

  ngAfterViewInit() {
    const editorElement = document.getElementById('code-editor');

    if (!editorElement) {
      throw new Error(`Code editor div was not found!`);
    }

    const autoComplete = autocompletion({
      override: [this.customCompletionFunction()],
    });

    const state = EditorState.create({
      doc: '',
      extensions: [codemirrorBasicSetup, StreamLanguage.define(csharp), autoComplete],
    });

    this.editorView = new EditorView({ state, parent: editorElement });
  }

  saveAndClose() {
    const instanceId = $('#codeEditorInstanceId').val();
    this.codeEditorService.closeAndSaveCode({ code: this.getCode(), instanceId });
    $('#codeEditorModal').modal('hide');
  }

  openRestoreDefaultCodeModal() {
    $('#restoreDefaultCodeModal').modal('show');
  }

  restoreDefaultCode() {
    const code = this.defaultCodeProvider.getDefaultCode();
    this.setCode(code);
    $('#restoreDefaultCodeModal').modal('hide');
  }

  private setCode(code: string) {
    this.editorView?.dispatch({
      changes: { from: 0, to: this.getCode().length, insert: code },
    });
  }

  private getCode() {
    return this.editorView?.state.doc.toString() || '';
  }

  private get activeAutoCompletions(): Completion[] {
    return this.defaultCodeProvider?.getCodeCompletions() || [];
  }

  customCompletionFunction() {
    return (context: CompletionContext): CompletionResult | null => {
      const word = context.matchBefore(/\w*/);

      if (!word || (word.from === word.to && !context.explicit)) {
        return null;
      }

      // include the declared variables in the document for autocompletion
      const declaredVariables: Completion[] = [];
      syntaxTree(context.state).iterate({
        enter(node): boolean | void {
          if (node.type.is('variableName')) {
            const label = context.state.doc.slice(node.from, node.to).toString();
            declaredVariables.push({ type: 'variable', label });
          }
        },
      });

      return {
        from: word.from,
        options: declaredVariables.concat(...this.activeAutoCompletions),
      };
    };
  }
}
