import { BaseObject } from '../_models/base-object';
import { BaseSplitterFormGroupMapper } from './base-splitter-form-group.mapper';
import { Splitter } from '../_models/_unit-operations/splitter';
import { hasNumericValue } from '../_utils/utils';

export class SplitterFormGroupMapper extends BaseSplitterFormGroupMapper {
  override map(source: BaseObject, destination: BaseObject) {
    super.map(source, destination);

    const sourcePlainObject = source as Splitter;
    const destinationInstance = destination as Splitter;

    this.mapDistributionRatioVariables(sourcePlainObject, destinationInstance);
    this.mapConstraintValues(destinationInstance);
  }

  private mapDistributionRatioVariables(sourcePlainObject: Splitter, destinationInstance: Splitter) {
    if (sourcePlainObject.distributionRatioVariables) {
      // TODO not sure about this check
      if (
        sourcePlainObject.distributionRatioVariables.length !== destinationInstance.distributionRatioVariables.length
      ) {
        throw new Error(
          `Source and destination number of Mixing Ratio variables don't match` +
            `${sourcePlainObject.name} - ${destinationInstance.distributionRatioVariables}`
        );
      }

      for (let i = 0; i < sourcePlainObject.distributionRatioVariables.length; i++) {
        if (hasNumericValue(sourcePlainObject.distributionRatioVariables[i].value)) {
          destinationInstance.distributionRatioVariables[i].value =
            +sourcePlainObject.distributionRatioVariables[i].value;
        } else {
          destinationInstance.distributionRatioVariables[i].value = undefined;
        }

        if (hasNumericValue(sourcePlainObject.distributionRatioVariables[i].minimumValue)) {
          destinationInstance.distributionRatioVariables[i].minimumValue =
            +sourcePlainObject.distributionRatioVariables[i].minimumValue;
        } else {
          destinationInstance.distributionRatioVariables[i].minimumValue = undefined;
        }

        if (hasNumericValue(sourcePlainObject.distributionRatioVariables[i].maximumValue)) {
          destinationInstance.distributionRatioVariables[i].maximumValue =
            +sourcePlainObject.distributionRatioVariables[i].maximumValue;
        } else {
          destinationInstance.distributionRatioVariables[i].maximumValue = undefined;
        }
      }
    }
  }

  mapConstraintValues(destinationInstance: Splitter) {
    let constraintsLength = Object.keys(destinationInstance.constraints.constraints).length;
    if (constraintsLength > 0) {
      Object.values(destinationInstance.constraints.constraints).forEach(cstr => {
        const mixingRatioVariable = destinationInstance.findDistributionRatioVariable(cstr.sourceUnitOperationId);

        switch (destinationInstance.isOptimizable) {
          case false:
            if (cstr.constraintName.includes('Minimum') || cstr.constraintName.includes('Maximum')) {
              delete destinationInstance.constraints.constraints[cstr.id];
            } else {
              cstr.constraintValue = mixingRatioVariable.value;
            }
            break;
          case true:
            if (cstr.constraintName.includes('Minimum')) {
              cstr.constraintValue = mixingRatioVariable.minimumValue;
            } else if (cstr.constraintName.includes('Maximum')) {
              cstr.constraintValue = mixingRatioVariable.maximumValue;
            } else {
              delete destinationInstance.constraints.constraints[cstr.id];
            }
            break;
          default:
            throw new Error('Unknown Optimization state');
        }
      });
      constraintsLength = Object.values(destinationInstance.constraints.constraints).length;
    }

    if (constraintsLength === 0) {
      destinationInstance.distributionRatioVariables.forEach(mixRatioVar => {
        destinationInstance.initConstraintsBasedInDistributionRatios(mixRatioVar);
      });
    }
  }

  override ignoreForAutoMapping(key: string, source: BaseObject, destination: BaseObject): boolean {
    const sourcePlainObject = source as Splitter;

    return (
      super.ignoreForAutoMapping(key, source, destination) ||
      source[key] === sourcePlainObject.distributionRatioVariables
    );
  }
}
