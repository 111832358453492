<div class="row">
  <div class="col-xs-12 col-lg-6 side-padding-5x">
    <sob-fuel-gas-summary-report-chart
      *ngIf="fuelGasMakeReportData"
      [data]="fuelGasMakeReportData"
      [unit]="'MMSCFD'"
      [idPrefix]="upgraderId">
    </sob-fuel-gas-summary-report-chart>
  </div>

  <div class="col-xs-12 col-lg-6 side-padding-5x">
    <sob-fuel-gas-summary-report-chart
      *ngIf="fuelGasUseReportData"
      [data]="fuelGasUseReportData"
      [unit]="'GJ/h'"
      [idPrefix]="upgraderId">
    </sob-fuel-gas-summary-report-chart>
  </div>
</div>
