import { Completion } from '@codemirror/autocomplete';

export const steamContributorCodeCompletions: Completion[] = [
  { label: 'HydrogenUse_MMSCFD', type: 'variable', info: '' },
  { label: 'HydrogenProduction_MMSCFD', type: 'variable', info: '' },
  { label: 'H2SProduction_MMSCFD', type: 'variable', info: '' },
  { label: 'AmineCirculationRate_kbpcd', type: 'variable', info: '' },
  { label: 'SulfurFlowrate_MMSCFD', type: 'variable', info: '' },
  { label: 'SteamMake900', type: 'variable', info: '' },
  { label: 'SteamUse900', type: 'variable', info: '' },
  { label: 'SteamMake600', type: 'variable', info: '' },
  { label: 'SteamUse600', type: 'variable', info: '' },
  { label: 'SteamMake150', type: 'variable', info: '' },
  { label: 'SteamUse150', type: 'variable', info: '' },
  { label: 'SteamMake50', type: 'variable', info: '' },
  { label: 'SteamUse50', type: 'variable', info: '' },
];
