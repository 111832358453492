import { FlexSheetMapper } from './flex-sheet-mapper';
import { SagdSource } from '../../../_models/_unit-operations/sagd-source';
import { Mapper } from '../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../_models';
import { NumberToSimulationVariableMapper } from './number-to-simulation-variable-mapper';
import { SagdSourceSheetRows } from '../cell-config/sagd-source-cell-config';

export class SagdSourceFlexSheetMapper implements FlexSheetMapper {
  simulationVariableMapper: Mapper<number, SimulationVariable>;

  constructor(simulationVariableMapper: NumberToSimulationVariableMapper) {
    this.simulationVariableMapper = simulationVariableMapper;
  }

  map(unitOperation: SagdSource, row: number, value: string | number) {
    if (row === SagdSourceSheetRows.NAME && typeof value === 'string') {
      unitOperation.name = value;
    }

    if (typeof value === 'number') {
      switch (row) {
        case SagdSourceSheetRows.VOLUMETRIC_FLOW_RATE:
          this.simulationVariableMapper.map(value, unitOperation.volumetricFlowrate);
          break;

        case SagdSourceSheetRows.GHG_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.ghgIntensity);
          break;

        case SagdSourceSheetRows.COGEN_ELECTRICITY_GENERATION:
          this.simulationVariableMapper.map(value, unitOperation.cogenElectricityGeneration);
          break;

        case SagdSourceSheetRows.NET_ELECTRICITY_EXPORT:
          this.simulationVariableMapper.map(value, unitOperation.netElectricityExport);
          break;

        case SagdSourceSheetRows.COGEN_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.cogenIntensity);
          break;

        case SagdSourceSheetRows.GRID_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.gridIntensity);
          break;

        // case SagdSourceSheetRows.LIGHT_ENDS:
        //   this.simulationVariableMapper.map(value, unitOperation.fluidAnalysis.composition[0]);
        //   break;
        //
        // case SagdSourceSheetRows.LIGHT_NAPHTHA:
        //   this.simulationVariableMapper.map(value, unitOperation.fluidAnalysis.composition[1]);
        //   break;
        //
        // case SagdSourceSheetRows.HEAVY_NAPHTHA:
        //   this.simulationVariableMapper.map(value, unitOperation.fluidAnalysis.composition[2]);
        //   break;
        //
        // case SagdSourceSheetRows.DISTILLATE:
        //   this.simulationVariableMapper.map(value, unitOperation.fluidAnalysis.composition[3]);
        //   break;
        //
        // case SagdSourceSheetRows.LGO:
        //   this.simulationVariableMapper.map(value, unitOperation.fluidAnalysis.composition[4]);
        //   break;
        //
        // case SagdSourceSheetRows.HGO:
        //   this.simulationVariableMapper.map(value, unitOperation.fluidAnalysis.composition[5]);
        //   break;
        //
        // case SagdSourceSheetRows.VGO:
        //   this.simulationVariableMapper.map(value, unitOperation.fluidAnalysis.composition[6]);
        //   break;
        //
        // case SagdSourceSheetRows.HVGO:
        //   this.simulationVariableMapper.map(value, unitOperation.fluidAnalysis.composition[7]);
        //   break;
        //
        // case SagdSourceSheetRows.RESID:
        //   this.simulationVariableMapper.map(value, unitOperation.fluidAnalysis.composition[8]);
        //   break;

        case SagdSourceSheetRows.PRICE:
          this.simulationVariableMapper.map(value, unitOperation.price);
          break;

        case SagdSourceSheetRows.CAPEX_AMORTIZED:
          this.simulationVariableMapper.map(value, unitOperation.capexAmortized);
          break;

        case SagdSourceSheetRows.OPEX_FIXED:
          this.simulationVariableMapper.map(value, unitOperation.opexFixed);
          break;

        case SagdSourceSheetRows.OPEX_VAR:
          this.simulationVariableMapper.map(value, unitOperation.opexVar);
          break;

        case SagdSourceSheetRows.OPEX_GAS_DIESEL:
          this.simulationVariableMapper.map(value, unitOperation.opexGasDiesel);
          break;

        case SagdSourceSheetRows.OPEX_POWER:
          this.simulationVariableMapper.map(value, unitOperation.opexPower);
          break;
      }
    }
  }
}
