import { Component, Input, OnInit } from '@angular/core';
import { MultiPeriodSettings } from '../../../_models/_multi-period/multi-period-settings';
import { BaseObject } from '../../../_models/base-object';
import { SimulationVariable } from '../../../_models';
import { MultiPeriodParameterProvider } from '../../../_models/_multi-period/multi-period-parameter-provider';

@Component({
  selector: 'sob-multi-period-parameter-selector',
  templateUrl: './multi-period-parameter-selector.component.html',
  styleUrls: ['./multi-period-parameter-selector.component.css'],
})
export class MultiPeriodParameterSelectorComponent implements OnInit {
  @Input()
  multiPeriodSettings: MultiPeriodSettings;

  multiPeriodProvidersForCategory: Array<BaseObject> = [];
  availableMultiPeriodParameters: Array<SimulationVariable> = [];
  availableMultiPeriodParameterId: string;

  ngOnInit() {
    $('.nav-pills.flowsheet-view-selector').on('hidden.bs.tab', ' a[href="#multi-period-settings-container"]', () => {
      $('#parameterSelector').find('select').val('');
      this.availableMultiPeriodParameterId = '';
      this.multiPeriodProvidersForCategory = [];
      this.availableMultiPeriodParameters = [];
      $('#selectedMultiPeriodParametersWrapper').find('input[type=checkbox]:checked').prop('checked', false);
    });
  }

  categoryChanged(event: any): void {
    this.multiPeriodProvidersForCategory = this.multiPeriodSettings.getMultiPeriodProvidersByCategoryName(
      event.target.value
    );
    this.availableMultiPeriodParameters = [];
    this.availableMultiPeriodParameterId = '';
  }

  multiPeriodProviderChanged(event: any) {
    const selectedBaseObject = <any>(
      this.multiPeriodProvidersForCategory.find(multiPeriodProvider => multiPeriodProvider.id === event.target.value)
    );

    this.availableMultiPeriodParameters = (<MultiPeriodParameterProvider>(
      selectedBaseObject
    )).getAvailableMultiPeriodParameters();
    this.availableMultiPeriodParameterId = '';
  }

  addMultiPeriodParameter(): void {
    if (this.availableMultiPeriodParameterId) {
      this.multiPeriodSettings.addMultiPeriodParameter(this.availableMultiPeriodParameterId);
    }
  }

  removeMultiPeriodParameter(): void {
    $('#selectedMultiPeriodParametersWrapper')
      .find('input[type=checkbox]:checked')
      .each((index, elem) => {
        this.multiPeriodSettings.removeMultiPeriodParameter($(elem).data('parameter'));
      });
  }
}
