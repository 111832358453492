import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AssayManager } from '../../_models/_fluid/assay-manager';
import { SuncorFluidAnalysis } from '../../_models/_fluid/suncor-fluid-analysis';
import { FlowsheetService } from '../../_services/flowsheet.service';
import { AssayManagerExcelService } from '../../_services/excel-upload/assay-manager/assay-manager-excel.service';
import { CoreService } from '../../_services/core.service';
import { ResourceDataService } from '../../_services/resource-data.service';
import { GasFluidAnalysis } from '../../_models/_fluid/gas-fluid-analysis';
import 'DataTables.net';

@Component({
  selector: 'sob-assay-manager',
  templateUrl: './assay-manager.component.html',
  styleUrls: ['./assay-manager.component.css'],
})
export class AssayManagerComponent implements OnInit, AfterViewInit {
  @Input() assayManager: AssayManager;
  newAnalysisName: string;
  newAnalysisType: string;
  currentAnalysis: SuncorFluidAnalysis | GasFluidAnalysis;

  constructor(
    private ref: ChangeDetectorRef,
    private flowsheetService: FlowsheetService,
    private coreService: CoreService,
    private assayManagerExcelService: AssayManagerExcelService,
    private resourceDataService: ResourceDataService
  ) {}

  ngOnInit() {
    this.newAnalysisType = 'Crude oil - Bitumen';
  }

  ngAfterViewInit(): void {
    const $modal = $('#assayManagerModal');
    $modal.on('hide.bs.modal', () => {
      if (this.currentAnalysis) {
        this.saveCurrentAnalysis();
        this.currentAnalysis = null;
      }
    });
    $modal.on('hidden.bs.modal', () => {
      $('#currentAnalysisWrapper').find('select').val('');
    });
  }

  saveCurrentAnalysis(): void {
    if (this.currentAnalysis) {
      this.setDefaultCompositionValues();
      this.currentAnalysis.convertToInternalUnit();
      this.assayManager.findFluidAnalysisById(this.currentAnalysis.id).overwriteValues(this.currentAnalysis);
      this.coreService.notifyAssayUpdated(this.currentAnalysis, true);
    }
  }

  setDefaultCompositionValues() {
    for (const sv of this.currentAnalysis.composition) {
      if (sv.isUndefined() || sv.value.toString() === '') {
        sv.setDefaultValue(0);
      }
    }
  }

  setCurrentAnalysisById(id: string): void {
    this.newAnalysisName = '';
    if (this.currentAnalysis) {
      delete this.currentAnalysis;
    }
    const originalAnalysis = this.assayManager.findFluidAnalysisById(id);
    if (originalAnalysis) {
      if (originalAnalysis.assayType === 'Gas') {
        this.currentAnalysis = new GasFluidAnalysis(originalAnalysis, originalAnalysis.ownerCase, this.assayManager);
      } else {
        this.currentAnalysis = new SuncorFluidAnalysis(originalAnalysis, originalAnalysis.ownerCase, this.assayManager);
      }
      this.assayManager.currentAnalysisId = id;
      this.ref.detectChanges();
    }
  }

  addFluidAnalysis(): void {
    if (this.newAnalysisName) {
      if (this.validateName(this.newAnalysisName)) {
        const resourceData = this.resourceDataService.getAssayManagerResourceData();

        const newAssay = this.assayManager.addFluidAnalysis(this.newAnalysisName, this.newAnalysisType);

        this.newAnalysisName = '';
        this.coreService.notifyAssayAdded(newAssay);
        this.setCurrentAnalysisById(newAssay.id);
      }
    }
  }

  removeFluidAnalysis(id: string, tableId: string): void {
    const assay = this.assayManager.findFluidAnalysisById(id);
    this.assayManager.removeFluidAnalysis(id);
    this.coreService.notifyAssayDeleted(assay);
    $(`#${tableId}`)
      .DataTable()
      .row($(`#${id}`))
      .remove();
  }

  cloneFluidAnalysis(id: string): void {
    this.newAnalysisName = '';
    const assay = this.assayManager.cloneAssay(id);
    this.coreService.notifyAssayAdded(assay);
    this.setCurrentAnalysisById(assay.id);
  }

  removeCurrentAnalysis() {
    if (this.validateName(this.currentAnalysis.assayName)) {
      this.saveCurrentAnalysis();
      this.currentAnalysis = null;
    }
  }

  uploadAssays() {
    if (!File || !FileReader || !FileList || !Blob) {
      alert('The file APIs are not fully supported in this browser');
    }
    const input = <HTMLInputElement>document.getElementById('excelFileInput');
    if (!input) {
      alert('Could not find the file input element');
    } else if (!input.files[0]) {
      alert('Please select a file before clicking "Load"');
    } else {
      const file = input.files[0];
      const fr = new FileReader();

      fr.onload = e => {
        this.assayManagerExcelService.uploadAssays((<any>e).target.result);
        // this.caseService.loadProjectFile((<any>e).target.result);
        // this.logManager.info('File uploaded successfully.');
      };

      fr.readAsBinaryString(file);
      $('#excelUploadModal').modal('hide');
    }
  }

  totalOutputWarningMessage() {
    const totalOutput = $('#AMcompositionTotal').val();
    const selectedUnit = $('#AMcompositionUnit').val() as string;

    if (selectedUnit === 'wt%') {
      if (totalOutput > 100) {
        return 1;
      }
    } else if (selectedUnit === 'fraction') {
      if (totalOutput > 1) {
        return 0;
      }
    }

    return Number.MAX_SAFE_INTEGER; // none of the above...
  }

  validateName(assayName: string) {
    const assayByName = this.assayManager.getAssayByName(assayName);
    if (assayByName) {
      return this.currentAnalysis ? !(assayByName && assayByName.id !== this.currentAnalysis.id) : !assayByName;
    }
    return true;
  }
}
