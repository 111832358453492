import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { WaterMaterialStream } from '../../../_models/_streams/water-material-stream';
import { CoreService } from '../../../_services/core.service';
import { FlowsheetService } from '../../../_services/flowsheet.service';

@Component({
  selector: 'sob-water-material-stream',
  templateUrl: './water-material-stream.component.html',
  styleUrls: ['./water-material-stream.component.css'],
})
export class WaterMaterialStreamComponent implements OnInit {
  stream: WaterMaterialStream;
  streamGroup: UntypedFormGroup;

  constructor(
    private coreService: CoreService,
    private flowsheetService: FlowsheetService,
    private ref: ChangeDetectorRef,
    private fb: UntypedFormBuilder
  ) {
    this.flowsheetService.openWaterStreamPropertyWindowRequest.subscribe(streamId => {
      this.openStream(streamId);
    });
  }

  initForm() {
    this.streamGroup = this.fb.group({
      name: this.fb.control(this.stream.name || '', [Validators.required, this.uniqueNameValidator()]),
    });
  }

  get nameControl() {
    return this.streamGroup ? (this.streamGroup.get('name') as UntypedFormControl) : null;
  }

  ngOnInit() {
    this.streamGroup = this.fb.group({ name: this.fb.control('') });

    $('#streamWaterModal').on('hide.bs.modal', e => {
      if (this.streamGroup.invalid) {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.nameControl.markAsDirty();
        return false;
      }

      this.save();
      return true;
    });
  }

  openStream(streamId) {
    this.stream = null;
    this.ref.detectChanges();
    this.stream = this.coreService.currentCase.filterWaterMaterialStreams(stream => {
      return stream.id === streamId;
    })[0];
    this.initForm();
    this.ref.detectChanges();

    $('#streamWaterModal').modal('show');
  }

  save() {
    this.stream.name = this.nameControl.value.trim();
    this.coreService.saveStream(this.stream);
  }

  uniqueNameValidator() {
    return (control: AbstractControl): { [key: string]: any } => {
      if (
        this.stream.ownerCase.filterWaterMaterialStreams(
          s => s.name === control.value.trim() && s.id !== this.stream.id
        ).length
      ) {
        return { unique: true };
      }
      return null;
    };
  }
}
