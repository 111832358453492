<div
  class="modal fade"
  id="multiPeriodResultsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="multiPeriodResultsModalLabel">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="">Multiperiod Results</h4>
      </div>

      <div class="modal-body" style="min-height: 400px">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active">
            <a href="#multiPeriodTrends" role="tab" data-toggle="tab" aria-controls="multiPeriodT1"> Trends </a>
          </li>
          <li role="presentation">
            <a href="#multiPeriodTables" role="tab" data-toggle="tab" aria-controls="multiPeriodT2"> Tables </a>
          </li>
        </ul>

        <div class="tab-content tab-content-padding">
          <div role="tabpanel" class="tab-pane fade in active" id="multiPeriodTrends">
            <div class="row">
              <div class="row col-sm-10" *ngIf="selectedKpis.length">
                <div class="col-sm-6">
                  <div class="form-group">
                    <span><strong>KPI</strong></span>
                    <select class="form-control" (change)="kpiChanged()" [(ngModel)]="selectedKpiId">
                      <option *ngFor="let kpi of selectedKpis" value="{{ kpi.id }}">{{ kpi.getFullName() }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-2" style="">
                <a
                  href="javascript:void(0)"
                  class="btn btn-primary btn-md"
                  style="margin-top: 20px; margin-left: 25px"
                  title="Download Excel Report"
                  (click)="excelReport()">
                  Excel Report&nbsp;
                  <i class="main-icon fa fa-download"></i>
                </a>
              </div>
            </div>

            <div role="tabpanel" class="tab-pane fade in">
              <ul class="nav nav-pills" role="tablist">
                <li role="presentation" class="active">
                  <a href="#mpChartTab" role="tab" data-toggle="tab" aria-controls="mpChartTab"> Chart </a>
                </li>

                <li role="presentation">
                  <a href="#mpTableTab" role="tab" data-toggle="tab" aria-controls="mpTableTab"> Table </a>
                </li>
              </ul>

              <div class="tab-content tab-content-padding">
                <div role="tabpanel" class="tab-pane fade in active" id="mpChartTab">
                  <div id="multiPeriodResultsChart"></div>
                </div>

                <div role="tabpanel" class="tab-pane fade in" id="mpTableTab">
                  <div class="row">
                    <div class="col-xs-12 text-center">
                      <table class="table" id="multiPeriodResultsTable" style="width: 100%">
                        <thead></thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div style="text-align: right" *ngIf="selectedKpiOwner">
                <span class="text-danger" *ngIf="findMaxKpiResult(kpiResults) > selectedKpiOwner.maxVolume.value">
                  Maximum Capacity exceeded in tank {{ selectedKpiOwner.name }}
                </span>
              </div>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade in" id="multiPeriodTables">
            <div class="row">
              <div class="col-xs-12 text-center scroll">
                <table class="table" id="multiPeriodReportTable" style="width: 100%">
                  <thead>
                    <th>Day</th>
                    <th *ngFor="let header of headers; let i = index">{{ header }}</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of multiPeriodReportData; let i = index">
                      <td *ngFor="let cell of row">
                        {{ cell }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
