<div class="modal fade" id="caseSettingsModal" tabindex="-1" role="dialog" aria-labelledby="caseSettingsModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="">Case Settings</h4>
      </div>
      <div class="modal-body">
        <div class="form-horizontal" *ngIf="caseSettings">
          <ul class="nav nav-tabs" role="tablist">
            <li role="presentation" class="active">
              <a href="#general" aria-controls="general" role="tab" data-toggle="tab"> General </a>
            </li>
          </ul>

          <div class="tab-content report-tab-content">
            <br />
            <div role="tabpanel" class="tab-pane fade in active" id="general">
              <div class="form-group">
                <sob-objective-function-selector></sob-objective-function-selector>
              </div>

              <div class="form-group">
                <label for="cs.cogenMethodology" class="pw-label control-label col-sm-4"> Cogen Methodology </label>
                <div class="col-sm-6">
                  <select
                    name="cs.cogenMethodology"
                    id="cs.cogenMethodology"
                    [(ngModel)]="caseSettings.cogenMethodology"
                    class="form-control">
                    <option value="NoCogenCredit">No Cogen Credit</option>
                    <option value="OBA">OBA</option>
                    <option value="SGER">SGER</option>
                    <option value="CurrentSUGoal">Current Suncor Goal</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-4" for="cs.operatingDaysPerYear"> Operating Days Per Year </label>

                <div class="col-sm-6">
                  <input
                    class="form-control"
                    type="text"
                    id="cs.operatingDaysPerYear"
                    disabled="disabled"
                    [(ngModel)]="caseSettings.operatingDaysPerYear" />
                </div>
              </div>

              <div class="form-group">
                <label [attr.for]="'cs-carbonPrice'" class="pw-label control-label col-sm-4"> Carbon Price </label>
                <sim-var-input [inputId]="'cs-carbonPrice'" [simVar]="caseSettings.carbonPrice"></sim-var-input>
              </div>

              <div class="form-group">
                <label [attr.for]="'cs-wtiPrice'" class="pw-label control-label col-sm-4"> WTI Price </label>
                <sim-var-input [inputId]="'cs-wtiPrice'" [simVar]="caseSettings.wtiPrice"></sim-var-input>
              </div>
              <div class="form-group">
                <label for="cs-refineryEmissions" class="control-label col-sm-4"> Refinery Emissions Option </label>

                <div class="col-sm-4">
                  <select
                    name="cs-refineryEmissions"
                    id="cs-refineryEmissions"
                    class="form-control"
                    [(ngModel)]="caseSettings.refineryEmissions">
                    <option value="SUonly">Suncor Only</option>
                    <option value="All">All</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
