import { Component, Input, OnInit, AfterViewInit } from '@angular/core';

declare let unitConverter: any;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sfe-unit-select',
  template:
    '<select id="{{selectId}}" [(ngModel)]="dynamicModel.unit" class="{{elClass}}" [disabled]="disabled"></select>',
})
export class UnitSelectComponent implements AfterViewInit, OnInit {
  @Input() selectId: string;
  @Input() simVar: any;
  @Input() unitSubset: string;
  @Input() elClass: string;
  @Input() transientModel: boolean;
  @Input() disabled: boolean;

  dynamicModel: any;

  ngOnInit() {
    if (this.transientModel) {
      this.dynamicModel = { unit: this.simVar.unit };
    } else {
      this.dynamicModel = this.simVar;
    }
  }

  ngAfterViewInit() {
    unitConverter(this.simVar.quantity).createUnitSelect(`#${this.selectId}`, this.simVar.unit, this.unitSubset);
  }
}
