import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { SulfurPlant } from '../../../_models/_unit-operations/sulfur-plant';

@Component({
  selector: 'sob-sulfur-plant',
  templateUrl: './sulfur-plant.component.html',
  styleUrls: ['./sulfur-plant.component.css'],
})
export class SulfurPlantComponent implements OnInit, AfterViewInit {
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;
  @Input() unitOperation: SulfurPlant;

  constructor(private fb: UntypedFormBuilder, private svfb: SimVarFormBuilder) {}

  ngOnInit(): void {
    this.addControls();
  }

  ngAfterViewInit() {
    this.formGroupWrapper.storeOriginalValue();
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl(
      'sulfurFlowrate',
      this.svfb.control(this.unitOperation.sulfurFlowrate, true)
    );
    this.propertyWindowFormGroup.addControl('sulfurCapacity', this.svfb.control(this.unitOperation.sulfurCapacity));
    this.propertyWindowFormGroup.addControl(
      'sulfurGHGIntensity',
      this.svfb.control(this.unitOperation.sulfurGHGIntensity)
    );
    this.propertyWindowFormGroup.addControl('ghgEmissions', this.svfb.control(this.unitOperation.ghgEmissions, true));

    this.propertyWindowFormGroup.addControl('opexVar', this.svfb.control(this.unitOperation.opexVar));
    this.propertyWindowFormGroup.addControl('opexGasDiesel', this.svfb.control(this.unitOperation.opexGasDiesel));
    this.propertyWindowFormGroup.addControl('opexPower', this.svfb.control(this.unitOperation.opexPower));
    this.propertyWindowFormGroup.addControl(
      'opexCarbonDioxide',
      this.svfb.control(this.unitOperation.opexCarbonDioxide, true)
    );
    this.propertyWindowFormGroup.addControl('opexFixed', this.svfb.control(this.unitOperation.opexFixed));
    this.propertyWindowFormGroup.addControl('opexTotal', this.svfb.control(this.unitOperation.opexTotal, true));

    this.propertyWindowFormGroup.addControl('capexAmortized', this.svfb.control(this.unitOperation.capexAmortized));
    this.propertyWindowFormGroup.addControl('capexTotal', this.svfb.control(this.unitOperation.capexTotal, true));
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }
}
