import { DefaultBaseObjectFormChangeDetector } from './default-base-object-form-change-detector';
import { DefaultMappedType } from '../_models/default-mapped-type';
import { Mixer } from '../_models/_unit-operations/mixer';

export class MixerFormChangeDetector extends DefaultBaseObjectFormChangeDetector {
  override detectChangesInValues(
    originalFormValue: DefaultMappedType<Mixer>,
    currentValue: DefaultMappedType<Mixer>
  ): boolean {
    let changes = super.detectChangesInValues(originalFormValue, currentValue);

    if (originalFormValue.mixingRatioVariables.length !== currentValue.mixingRatioVariables.length) {
      return true;
    }

    for (let i = 0; i < originalFormValue.mixingRatioVariables.length; i++) {
      const currentMixingRatioVariable = currentValue.mixingRatioVariables[i];
      const originalMixingRatioVariable = originalFormValue.mixingRatioVariables[i];
      changes =
        changes ||
        currentMixingRatioVariable.value !== originalMixingRatioVariable.value ||
        currentMixingRatioVariable.minimumValue !== originalMixingRatioVariable.minimumValue ||
        currentMixingRatioVariable.maximumValue !== originalMixingRatioVariable.maximumValue;
    }

    return changes;
  }

  override ignoreForDefaultChangeDetection(k: string): boolean {
    return super.ignoreForDefaultChangeDetection(k) || (k as keyof Mixer) === 'mixingRatioVariables';
  }
}
