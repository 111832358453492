import { Case } from '../../_models';
import { ServerUtcDateService } from '../helpers/server-utc-date.service';
import { ParametricStudy } from '../../_models/_case-study/parametric-study';
import { ParametricStudyKpiResult } from '../../_models/_case-study/parametric-study-kpi-result';
import { ParametricStudyParameter } from '../../_models/_case-study/parametric-study-parameter';

declare let unitConverter: any;

export class CaseStudyExcelReport {
  static generateCaseInfoDataArray(c: Case, utc: ServerUtcDateService, caseStudy: ParametricStudy) {
    const dataArray = [['Username', 'Creation Date', 'Name of the Case', 'Name of the Case Study']];
    const currentDate = utc.getCurrentDate();

    dataArray.push([this.getUsername(), this.formatDate(currentDate), c.name, caseStudy.name]);
    return dataArray;
  }

  static generateKpiResultsDataArray(kpiResult: ParametricStudyKpiResult, parameter: ParametricStudyParameter) {
    const dataArray = [
      [
        `${kpiResult.kpi.getFullName()} (${kpiResult.kpi.getDefaultUnit()})`,
        `${parameter.simulationVariable.getFullName()} (${parameter.variableValues.unit})`,
      ],
    ];

    this.addToDataArrayConvertedValues(kpiResult, parameter, dataArray);
    return dataArray;
  }

  private static addToDataArrayConvertedValues(
    kpiResult: ParametricStudyKpiResult,
    parameter: ParametricStudyParameter,
    dataArray: string[][]
  ) {
    const kpiQuantity = kpiResult.kpi.quantity;
    const { kpi } = kpiResult;
    const parameterQuantity = parameter.variableValues.quantity;
    const parameterVariable = parameter.variableValues;

    kpiResult.variableValues.forEach((value, index) => {
      const kpiValueConverted = this.convertValuesToDefaultUnit(
        value,
        kpiQuantity,
        kpi.getInternalUnit(),
        kpi.getDefaultUnit()
      );

      const parameterValueConverted = this.convertValuesToDefaultUnit(
        parameterVariable.values[index],
        parameterQuantity,
        parameterVariable.getInternalUnit(),
        parameterVariable.unit
      );

      dataArray.push([kpiValueConverted, parameterValueConverted]);
    });
  }

  private static getUsername() {
    const serializedUser = localStorage.getItem('user');
    return JSON.parse(serializedUser || '{}').userName;
  }

  private static formatDate(date) {
    const padTo2Digits = num => num.toString().padStart(2, '0');
    return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/');
  }

  private static convertValuesToDefaultUnit(
    value: number,
    quantity: string,
    internalUnit: string,
    defaultUnit: string
  ) {
    const conversion = unitConverter(quantity).convert(value, internalUnit, defaultUnit);
    return conversion.round().getValue().toString();
  }
}
