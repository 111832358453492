<div
  class="modal fade"
  id="flowsheetCommentsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="flowsheetCommentsModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Flowsheet Comments</h4>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <textarea
            [(ngModel)]="currentCase.comments.text"
            name="flowsheetComments"
            style="resize: none"
            id="flowsheetComments"
            class="form-control"
            rows="10"></textarea>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
