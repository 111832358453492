import { Injectable } from '@angular/core';
import { AbstractSubFlowsheetHandler } from './abstract-sub-flowsheet-handler';
import { UnitOperation } from '../../_models';
import { FlowsheetDiagramService } from '../flowsheet-diagram/flowsheet-diagram.service';
import { CoreService } from '../core.service';

@Injectable()
export class FuelGasSubFlowsheetHandler extends AbstractSubFlowsheetHandler {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(flowsheetDiagramService: FlowsheetDiagramService, coreService: CoreService) {
    super(flowsheetDiagramService, coreService);
  }

  getDefaultNodeData(): any {
    return undefined;
  }

  getSubFlowsheetInlets(uoId: string): UnitOperation[] {
    return [];
  }

  getSubFlowsheetOutlets(uoId: string): UnitOperation[] {
    return [];
  }

  syncAllBlocksInletOutletPorts(): void {}

  syncWhileFlowsheetLoading(uoId: string): void {}
}
