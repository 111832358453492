import { Case } from '../case';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { Splitter } from '../_unit-operations/splitter';
import { Upgrader } from '../_unit-operations/upgrader';
import { Refinery } from '../_unit-operations/refinery';
import { isTypeNumber } from '../../_utils/utils';

export class OldCaseValidator {
  static validate(c: Case) {
    const messages = OldCaseValidator.validateSplitters(c)
      .concat(OldCaseValidator.validateSources(c))
      .concat(OldCaseValidator.validateRefineries(c))
      .concat(OldCaseValidator.validatePipes(c))
      .concat(OldCaseValidator.validateUpgraders(c).concat(OldCaseValidator.validateHydrocrackers(c)));
    return { valid: !messages.length, messages };
  }

  static validateSplitters(c: Case) {
    const splitters = c.filterUnitOperations(uo => {
      return uo.category === unitOperationsConfig.splitter.key && !(<Splitter>uo).isOptimizable;
    }) as Splitter[];

    const messages: string[] = [];

    for (const s of splitters) {
      for (let i = 0; i < s.distributionRatioVariables.length; i++) {
        const drv = s.distributionRatioVariables[i];
        if (!isTypeNumber(drv.value)) {
          messages.push(
            `Please enter a valid distribution ratio for ${c.getUnitOperationName(drv.unitOperationId)} on ${s.name}`
          );
        }
      }
    }

    return messages;
  }

  static validateSources(c: Case) {
    const messages = [];
    const unitOperations = c.filterUnitOperations(uo => {
      return (
        uo.category === unitOperationsConfig.diluentSource.key ||
        uo.category === unitOperationsConfig.mine.key ||
        uo.category === unitOperationsConfig.offshore.key ||
        uo.category === unitOperationsConfig.sagd.key ||
        uo.category === unitOperationsConfig.thirdPartySource.key
      );
    });

    for (const uo of unitOperations) {
      if (c.filterSuncorMaterialStreams(s => s.inletUnitOperationId === uo.id).length !== 1) {
        messages.push(`Please define an outlet for ${uo.name}`);
      }
    }

    return messages;
  }

  static validateRefineries(c: Case) {
    const messages = [];
    const unitOperations = c.filterUnitOperations(uo => {
      return (
        uo.category === unitOperationsConfig.refinery.key || uo.category === unitOperationsConfig.thirdPartyRefinery.key
      );
    });
    for (const uo of unitOperations) {
      const refinery = uo as Refinery;
      if (refinery.hasTwoInlets) {
        if (c.filterSuncorMaterialStreams(s => s.outletUnitOperationId === uo.id).length !== 2) {
          messages.push(`Please define an inlet for ${uo.name}`);
        }
      } else if (c.filterSuncorMaterialStreams(s => s.outletUnitOperationId === uo.id).length !== 1) {
        messages.push(`Please define an inlet for ${uo.name}`);
      }
    }

    return messages;
  }

  static validatePipes(c: Case) {
    const messages = [];
    const unitOperations = c.findUnitOpsByCategory(unitOperationsConfig.pipe.key);

    for (const uo of unitOperations) {
      if (c.filterSuncorMaterialStreams(s => s.outletUnitOperationId === uo.id).length !== 1) {
        messages.push(`Please define an inlet for ${uo.name}`);
      }

      if (c.filterSuncorMaterialStreams(s => s.inletUnitOperationId === uo.id).length !== 1) {
        messages.push(`Please define an outlet for ${uo.name}`);
      }
    }

    return messages;
  }

  static validateHydrocrackers(c: Case) {
    const messages = [];
    const unitOperations = c.findUnitOpsByCategory(unitOperationsConfig.hydrocracker.key);

    for (const uo of unitOperations) {
      if (c.filterSuncorMaterialStreams(s => s.outletUnitOperationId === uo.id).length !== 1) {
        messages.push(`Please define an inlet for ${uo.name}`);
      }

      if (c.filterSuncorMaterialStreams(s => s.inletUnitOperationId === uo.id).length !== 5) {
        messages.push(`${uo.name} is not properly connected`);
      }
    }
    return messages;
  }

  static validateUpgraders(c: Case) {
    const messages = [];
    const unitOperations = c.findUnitOpsByCategory(unitOperationsConfig.upgrader.key);

    for (const uo of unitOperations) {
      const upgrader = uo as Upgrader;
      if (!upgrader.useSubFlowsheet) {
        if (c.filterSuncorMaterialStreams(s => s.outletUnitOperationId === uo.id).length === 0) {
          messages.push(`Please define an inlet for ${uo.name}`);
        }
        if (c.filterSuncorMaterialStreams(s => s.inletUnitOperationId === uo.id).length < 2) {
          messages.push(`Please define the outlets for ${uo.name}`);
        }
      } else {
        if (c.filterSuncorMaterialStreams(s => s.outletUnitOperationId === uo.id).length === 0) {
          messages.push(`Please define an inlet for ${uo.name}`);
        }
        if (c.filterSuncorMaterialStreams(s => s.inletUnitOperationId === uo.id).length === 0) {
          messages.push(`Please define the outlets for ${uo.name}`);
        }
      }
    }
    return messages;
  }
}
