import { Component } from '@angular/core';
import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import { CoreService } from '../../../_services/core.service';
import { MultiPeriodSettings } from '../../../_models/_multi-period/multi-period-settings';
import { MultiPeriodParameter } from '../../../_models/_multi-period/multi-period-parameter';
import { NumberToUnitConverter } from '../../../_services/number-to-unit-converter.service';
import {
  MultiPeriodSpreadsheetEditEventHandler,
  MultiPeriodSpreadsheetPasteEventHandler,
} from '../../../_services/multi-period/abstract-multi-period-spreadsheet-event-handler';

@Component({
  selector: 'sob-multi-period-parameter-spreadsheet',
  templateUrl: './multi-period-parameter-spreadsheet.component.html',
  styleUrls: ['./multi-period-parameter-spreadsheet.component.css'],
})
export class MultiPeriodParameterSpreadsheetComponent {
  private spreadsheet: wjcSheet.FlexSheet;
  private editEventHandler: MultiPeriodSpreadsheetEditEventHandler;
  private pasteEventHandler: MultiPeriodSpreadsheetPasteEventHandler;

  constructor(private core: CoreService, private numberToUnitConverter: NumberToUnitConverter) {}

  initializeFlexSheet(flex: wjcSheet.FlexSheet) {
    this.spreadsheet = flex;
    this.spreadsheet.hostElement.style.height = `${93}%`;
    this.initializeEditEventHandler();
    this.initializePasteEventHandler();
  }

  initializeSheet() {
    if (this.spreadsheet.sheets.length) {
      this.spreadsheet.sheets.removeAt(0);
    }

    this.displayPeriodsColumn();

    for (let i = 0; i < this.numberOfMultiPeriodParameters; i++) {
      this.parameterToSpreadsheetColumn(this.multiPeriodSettings.multiPeriodParameters[i], i + 1);
    }

    // it should not need to use setTimeout...
    setTimeout(() => {
      this.spreadsheet.autoSizeColumns(1, this.numberOfMultiPeriodParameters);
    }, 1);
  }

  // region stuff related to displaying
  parameterToSpreadsheetColumn(parameter: MultiPeriodParameter, columnNumber: number) {
    this.spreadsheet.setCellData(0, columnNumber, `${parameter.getFullName()} (${parameter.getDefaultUnit()})`);

    for (let i = 0; i < parameter.multiPeriodValues.length; i++) {
      const simVar = parameter.simulationVariable;
      const convertedValue = this.numberToUnitConverter.convertToDefaultUnit(
        parameter.multiPeriodValues[i],
        simVar.quantity,
        true
      );

      this.spreadsheet.setCellData(i + 1, columnNumber, convertedValue);
    }
  }

  displayPeriodsColumn() {
    const columnNumber = 0;

    this.spreadsheet.setCellData(0, columnNumber, 'Days');

    for (let i = 0; i < this.multiPeriodSettings.numberOfSteps; i++) {
      this.spreadsheet.setCellData(i + 1, columnNumber, i + 1);
    }
  }
  // endregion

  // region event handlers
  private initializeEditEventHandler() {
    this.editEventHandler = new MultiPeriodSpreadsheetEditEventHandler(this.spreadsheet, this.numberToUnitConverter);
    this.spreadsheet.cellEditEnding.addHandler((spreadsheet: wjcSheet.FlexSheet, flexSheetEvent: any) => {
      this.editEventHandler.handle(this.multiPeriodSettings, flexSheetEvent);
    });
  }

  private initializePasteEventHandler() {
    this.pasteEventHandler = new MultiPeriodSpreadsheetPasteEventHandler(this.spreadsheet, this.numberToUnitConverter);
    this.spreadsheet.pastingCell.addHandler((spreadsheet: wjcSheet.FlexSheet, flexSheetEvent: any) => {
      this.pasteEventHandler.handle(this.multiPeriodSettings, flexSheetEvent);
    });
  }
  // endregion

  get multiPeriodSettings(): MultiPeriodSettings {
    return this.core.currentCase.multiPeriodSettings;
  }

  get numberOfRows() {
    const minRows = 35;
    return this.multiPeriodSettings.numberOfSteps >= minRows ? this.multiPeriodSettings.numberOfSteps + 1 : minRows;
  }

  get numberOfColumns() {
    const minCols = 20;
    return this.numberOfMultiPeriodParameters >= minCols ? this.numberOfMultiPeriodParameters + 1 : minCols;
  }

  get numberOfMultiPeriodParameters() {
    return this.multiPeriodSettings.multiPeriodParameters.length;
  }
}
