import { Component, Input, OnInit } from '@angular/core';
import { Cut } from '../../../_models/_fluid/cut';

@Component({
  selector: 'sob-cut',
  templateUrl: './cut.component.html',
  styleUrls: ['./cut.component.css'],
})
export class CutComponent implements OnInit {
  @Input()
  cut: Cut; // isn't this a keyword or sth

  @Input()
  idPrefix: string;

  @Input()
  isStream: boolean;

  @Input()
  readOnly: boolean;

  constructor() {}

  ngOnInit() {
    this.readOnly = this.readOnly ? this.readOnly : false;
  }
}
