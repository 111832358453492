export class BaseConstraintReportVariable {
  name: string;
  isActive: boolean;
  unitOperationName: string;
  unitOperationId: string;
  constraintValue: number;
  currentValue: number;
  constraintUnit: string;
  isConstraintViolated: boolean;

  constructor(name: string, constraintValue: number) {
    this.name = name;
    this.constraintValue = constraintValue;
    this.isConstraintViolated = false;
  }

  static verifyIfConstraintIsViolated(constraintReportVariable: BaseConstraintReportVariable) {
    if (constraintReportVariable.name.includes('Minimum')) {
      if (constraintReportVariable.currentValue < constraintReportVariable.constraintValue) {
        return true;
      }
    } else if (constraintReportVariable.name === 'Flowrate') {
      if (constraintReportVariable.currentValue !== constraintReportVariable.constraintValue) {
        return true;
      }
    } else if (constraintReportVariable.currentValue > constraintReportVariable.constraintValue) {
      return true;
    }

    return false;
  }
}
