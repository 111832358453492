<div class="control-wrapper">
  <ul class="unstyled no-padding no-margins controls">
    <li (click)="toggleDottedLines()" title="Hide / show inactive pipes">
      <a> <i class="fa fa-minus"></i></a>
    </li>

    <li (click)="toggleInactiveItems()" title="Hide / show inactive pipes and process blocks">
      <a> <i class="fa fa-eye"></i></a>
    </li>

    <li (click)="toggleLabels()" title="Hide / show labels">
      <a> <i class="fa fa-info"></i></a>
    </li>

    <li title="Fit to content" class="p-t-5-x" (click)="zoomToFit()">
      <span class="icon-fit-to-content f-s-1-5-em"></span>
    </li>

    <li
      (click)="toggleMultiPeriodMode()"
      [attr.title]="isMultiPeriodEnabled ? 'Disable Multi-Period' : 'Enable Multi-Period'">
      <a> <i class="fa fa-clock-o" [class.text-muted]="!isMultiPeriodEnabled"></i></a>
    </li>
    <li title="Comments" style="padding: 0">
      <sob-diagram-comment-button> </sob-diagram-comment-button>
    </li>
  </ul>
</div>
