import { SimulationVariable } from '../simulation-variable';
import { SuncorUnitOperation } from './suncor-unit-operation';
import { IUnitOperation } from '../i-unit-operation';
import { Case } from '../case';
import { BaseObject } from '../base-object';
import { KpiProvider } from '../_case-study/kpi-provider';
import { ParameterProvider } from '../_case-study/parameter-provider';
import { ConstrainableObject } from '../_interfaces/constrainable-object';
import { UnitOperationConstraints } from './unit-operation-constraints';
import { MultiPeriodParameterProvider } from '../_multi-period/multi-period-parameter-provider';
import { unitOperationsConfig } from '../../_config/unit-operations.config';

declare let unitConverter: any;

export class DiluentSource
  extends SuncorUnitOperation
  implements IUnitOperation, KpiProvider, ParameterProvider, ConstrainableObject, MultiPeriodParameterProvider
{
  category = unitOperationsConfig.diluentSource.key;

  isOptimizable: boolean;
  volumetricFlowrate: SimulationVariable;
  price: SimulationVariable;
  premiumDiscountToWTI: SimulationVariable;
  fluidAnalysisId: string;
  constraints: UnitOperationConstraints;
  /**
   *
   */
  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override addSimVarsToPool() {
    super.addSimVarsToPool();
  }

  override overwriteValues(another: BaseObject) {
    super.overwriteValues(another);
    const ds = another as DiluentSource;
    this.isOptimizable = ds.isOptimizable;
  }

  protected override ignoreForOverWrite(propertyName: string): boolean {
    return super.ignoreForOverWrite(propertyName);
  }

  getAvailableKpis() {
    return [this.volumetricFlowrate, this.price, this.premiumDiscountToWTI, this.ghgIntensity, this.ghgEmissions];
  }

  getAvailableMultiPeriodParameters(): SimulationVariable[] {
    return [this.volumetricFlowrate];
  }

  override clearResults() {
    super.clearResults();
    this.ghgEmissions.value = null;
    this.opexCarbonDioxide.value = null;
    this.opexVar.value = null;
    this.opexTotal.value = null;
    this.price.value = null;
    if (this.isOptimizable) {
      this.volumetricFlowrate.value = null;
    }
  }

  override initValues(unitOperation: any): void {
    super.initValues(unitOperation);

    if (typeof unitOperation.isOptimizable === 'undefined') {
      // I think this is the only place to know if isOptimizable will have an 'undefined' value
      this.isOptimizable = true;
    } else {
      this.isOptimizable = !!unitOperation.isOptimizable;
    }

    this.volumetricFlowrate = new SimulationVariable(unitOperation.volumetricFlowrate, this.ownerCase, this);
    this.price = new SimulationVariable(unitOperation.price, this.ownerCase, this);
    this.premiumDiscountToWTI = new SimulationVariable(unitOperation.premiumDiscountToWTI, this.ownerCase, this);

    this.fluidAnalysisId = unitOperation.fluidAnalysisId || '';
    this.initConstraints();
  }

  override setQuantities() {
    super.setQuantities();
    this.volumetricFlowrate.setQuantity('Volumetricflowrate');
    this.price.setQuantity('CurrencyPerVolume');
    this.premiumDiscountToWTI.setQuantity('Percent');
  }

  override setSimulationVariableNames(): void {
    super.setSimulationVariableNames();
    this.volumetricFlowrate.setName('Volumetric Flowrate');
    this.ghgIntensity.setName('GHG Intensity');
    this.ghgEmissions.setName('GHG Emissions');
    this.price.setName('Diluent Price');
    this.premiumDiscountToWTI.setName('Premium/Discount to WTI');
    this.capacity.setName('Maximum Flow Rate');
  }

  override setSimulationVariablesOwner() {
    super.setSimulationVariablesOwner();
  }

  override setDefaultValues(): void {
    super.setDefaultValues();

    // nope, numbers and booleans can't be initialized like this...
    // this.isOptimizable = true;

    if (this.ghgIntensity.isUndefined()) {
      this.ghgIntensity.setDefaultValue(25, unitConverter.units.MassPerVolume.KG_BBL);
    }
    if (this.premiumDiscountToWTI.isUndefined()) {
      this.premiumDiscountToWTI.setDefaultValue(5.0);
    }
  }

  override convertToInternalUnit() {
    super.convertToInternalUnit();
  }

  override detectChanges(another: DiluentSource) {
    return super.detectChanges(another);
  }

  override getChildrenObjectsForRemoval() {
    return super.getChildrenObjectsForRemoval();
  }

  getAlternativeGhgIntensity() {
    return new SimulationVariable(this.ghgIntensity, this.ownerCase, this);
  }

  // region class-specific methods
  setOptimizable(isOptimizable: boolean) {
    this.isOptimizable = isOptimizable;

    if (this.isOptimizable) {
      this.volumetricFlowrate.value = null;
    }
  }
  // endregion

  getAvailableParameters() {
    return [this.premiumDiscountToWTI, this.ghgIntensity];
  }

  override dePersist(serializedUnitOperation: any) {
    super.dePersist(serializedUnitOperation);

    this.isOptimizable = serializedUnitOperation.isOptimizable;

    this.volumetricFlowrate = this.getSimulationVariableSafe(serializedUnitOperation.volumetricFlowrate);
    this.price = this.getSimulationVariableSafe(serializedUnitOperation.price);
    this.premiumDiscountToWTI = this.getSimulationVariableSafe(serializedUnitOperation.premiumDiscountToWTI);

    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }

  override ignoreForMakeReady(simvar: SimulationVariable): boolean {
    let result = super.ignoreForMakeReady(simvar);
    if (this.isOptimizable) {
      result = result || simvar === this.volumetricFlowrate;
    }
    result = result || simvar === this.capacity || simvar === this.minimumFlow || this.price === simvar;
    return result;
  }

  getAvailableParametricStudyParameters(): Array<SimulationVariable> {
    return [this.premiumDiscountToWTI, this.volumetricFlowrate, this.ghgIntensity];
  }

  constraintValueDefined(): boolean {
    return this.constraints.anyConstraintValueDefined();
  }

  constraintViolated(): boolean {
    return false;
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('Maximum Flow Rate', this.capacity);
  }
}
