import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { ProductTank } from '../../../_models/product-tank';
import { CoreService } from '../../../_services/core.service';
import { DistributionRatioVariable } from '../../../_models/_unit-operations/distribution-ratio-variable';
import { hasNumericValue, roundNumber } from '../../../_utils/utils';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';

declare let unitConverter: any;

@Component({
  selector: 'sob-product-tank',
  templateUrl: './product-tank.component.html',
  styleUrls: ['./product-tank.component.css'],
})
export class ProductTankComponent implements OnInit, OnDestroy {
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;
  @Input() unitOperation: ProductTank;
  multiperiodEnabled: boolean;

  private subSink = new SubSink();

  constructor(private fb: UntypedFormBuilder, private coreService: CoreService, private svfb: SimVarFormBuilder) {
    this.subSink.add(
      this.coreService.streamRemovedRequest.subscribe(s => {
        if (s.inletUnitOperationId === this.unitOperation.id) {
          this.removeDistributionRatioVariable(s.outletUnitOperationId);
        }
      })
    );

    this.subSink.add(
      this.coreService.streamAddedRequest.subscribe(s => {
        if (s.inletUnitOperationId === this.unitOperation.id) {
          this.addDistributionRatioVariable(s.outletUnitOperationId);
        }
      })
    );
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    this.multiperiodEnabled = this.coreService.currentCase.multiPeriodEnabled;
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();
    this.calculateDistributionRatioVaririables();
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl('isOptimizable', this.fb.control(this.unitOperation.isOptimizable));
    this.propertyWindowFormGroup.addControl('productVolume', this.svfb.control(this.unitOperation.productVolume, true));
    this.propertyWindowFormGroup.addControl(
      'inventory',
      this.svfb.control(this.unitOperation.inventory, !this.multiperiodEnabled)
    );
    this.propertyWindowFormGroup.addControl('productType', this.fb.control(this.unitOperation.productType));
    this.propertyWindowFormGroup.addControl(
      'distributionRatioVariables',
      this.fb.array(this.createDistributionRatioVariablesControls())
    );

    if (this.isOptimizable) {
      this.distributionRatioVariablesFormArray.disable();
    } else {
      this.distributionRatioVariablesFormArray.enable();
    }
  }

  private createDistributionRatioVariablesControls(): UntypedFormGroup[] {
    return this.unitOperation.distributionRatioVariables.map(dv => {
      return this.createSingleDistributionRatioVariableControl(dv);
    });
  }

  protected createSingleDistributionRatioVariableControl(dv: DistributionRatioVariable) {
    const roundedValue = hasNumericValue(dv.value) ? roundNumber(+dv.value, 3) : undefined;

    const valueControl = this.fb.control(roundedValue, [Validators.required, Validators.max(1), Validators.min(0)]);

    return this.fb.group({
      value: valueControl,
    });
  }

  calculateDistributionRatioVaririables() {
    this.distributionRatioVariablesFormArray.valueChanges.forEach(() => {
      this.calculateLastDistributionVariable();
    });

    // eslint-disable-next-line guard-for-in
    for (const i in this.distributionRatioVariablesFormArray.controls) {
      this.roundDistributionVariable(+i);
    }
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }

  isOptimizableChanged(checked: boolean) {
    if (checked) {
      this.distributionRatioVariablesFormArray.disable();
    } else {
      this.distributionRatioVariablesFormArray.enable();
    }
  }

  get distributionRatioVariablesFormArray() {
    return this.propertyWindowFormGroup.get('distributionRatioVariables') as UntypedFormArray;
  }

  get isOptimizable() {
    return this.propertyWindowFormGroup.get('isOptimizable').value;
  }

  findDistributionRatioName(index: number): string {
    if (this.unitOperation) {
      return this.unitOperation.findDistributionRatioVariableName(index);
    }
    return '';
  }

  calculateLastDistributionVariable() {
    const { controls } = this.distributionRatioVariablesFormArray;
    if (controls.length <= 1) {
      return;
    }

    let total = 0;

    for (let i = 0; i < controls.length - 1; i++) {
      const dv = controls[i];
      const dvParsed = unitConverter.parseFloatString(dv.get('value').value);

      if (!Number.isNaN(dvParsed)) {
        total += dvParsed;
      } else {
        return;
      }
    }

    controls[controls.length - 1].get('value').setValue(parseFloat((1 - total).toFixed(5)), {
      emitEvent: false,
      emitModelToViewChange: true,
      emitViewToModelChange: true,
    });
    controls[controls.length - 1].get('value').markAsDirty();
  }

  roundDistributionVariable(i: number) {
    const dv = this.distributionRatioVariablesFormArray.controls[i];
    const dvParsed = unitConverter.parseFloatString(dv.value);

    if (!Number.isNaN(dvParsed)) {
      dv.setValue(parseFloat(dvParsed.toFixed(3)));
    }
  }

  private removeDistributionRatioVariable(unitOperationId: string) {
    const variable = this.unitOperation.findDistributionRatioVariable(unitOperationId);
    const index = this.unitOperation.distributionRatioVariables.indexOf(variable);
    if (index === -1) {
      return;
    }

    this.distributionRatioVariablesFormArray.removeAt(index);
    this.unitOperation.removeDistributionRatioVariable(unitOperationId);
  }

  private addDistributionRatioVariable(unitOperationId: string) {
    this.unitOperation.addDistributionVariable(unitOperationId);
    const dv = this.unitOperation.distributionRatioVariables[this.unitOperation.distributionRatioVariables.length - 1];
    const dvControl = this.createSingleDistributionRatioVariableControl(dv);
    if (this.propertyWindowFormGroup.get('isOptimizable').value) {
      dvControl.disable();
    }
    this.distributionRatioVariablesFormArray.push(dvControl);
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}
