import { Completion } from '@codemirror/autocomplete';
import { DefaultCodeProvider } from './default-code-provider';
import { DEFAULT_STEAM_MODEL } from '../../_config/unit-operations/default-models/default-steam-model';
import { steamContributorCodeCompletions } from '../../_config/unit-operations/custom-code-completions/steam-contributor-code-completions';

export class SteamContributorCodeProvider extends DefaultCodeProvider {
  constructor() {
    super(DEFAULT_STEAM_MODEL);
  }

  override getCodeCompletions(): Completion[] {
    return super.getCodeCompletions().concat(...steamContributorCodeCompletions);
  }
}
