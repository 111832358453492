import { AbstractControl, FormGroup } from '@angular/forms';

export class IdentifiableFormGroup<
  TControl extends { [K in keyof TControl]: AbstractControl<any, any> }
> extends FormGroup<TControl> {
  private readonly formGroupId: string;

  constructor(formGroupId: string, controls: TControl) {
    super(controls);
    this.formGroupId = formGroupId;
  }

  getFormGroupId() {
    return this.formGroupId;
  }
}
