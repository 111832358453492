import { SimulationVariable } from '../simulation-variable';
import { BaseObject } from '../base-object';
import { Cut } from '../_fluid/cut';
import { SuncorUnitOperation } from '../_unit-operations/suncor-unit-operation';
import { Case } from '../case';
import { getColor } from '../color.generator';
import { KpiProvider } from '../_case-study/kpi-provider';
import { BaseStream } from './base-stream';
import { Quantity } from '../../_config/quantity.enum';
import { DefaultMappedType } from '../default-mapped-type';
import { isTypeString } from '../../_utils/utils';
import { StreamCompositionNames } from '../../_config/stream-composition-names.enum';
import { enumValuesToArray } from '../../_utils/enum-values-to-array';
import { DistillationCompositionNames } from '../../_config/distillation-composition-names.enum';

declare let unitConverter: any;

export class SuncorMaterialStream extends BaseStream implements KpiProvider {
  category = 'material-stream';
  private readonly COMPOSITION_LENGTH = 9;
  private readonly DISTILLATION_COMP_LENGTH = 5;

  // So-called Bulk properties
  // they are in the FluidAnalysis on sources
  massDensity: SimulationVariable; // SuncorMassDensity
  ccr: SimulationVariable;
  sulfur: SimulationVariable;
  nitrogen: SimulationVariable;

  // general properties
  volumetricFlowrate: SimulationVariable;
  massFlowrate: SimulationVariable;
  ghgEmissions: SimulationVariable;
  ghgIntensity: SimulationVariable;

  // maybe not shown
  price: SimulationVariable;

  // this or each?
  // Also, spec says it should 'expand'
  // therefore the FluidAnalysis seems more apt.
  cuts: Array<Cut> = [];

  lightEndsCut: Cut;
  lightNaphthaCut: Cut;
  heavyNaphthaCut: Cut;
  distillateCut: Cut;
  LGOCut: Cut;
  HGOCut: Cut;
  VGOCut: Cut;
  HVGOCut: Cut;
  residCut: Cut;

  // Should this be an object though?
  // Composition
  composition: Array<SimulationVariable> = [];
  volumeComposition: SimulationVariable[] = [];
  distillationComposition: SimulationVariable[] = [];

  constructor(materialStream: any, ownerCase: Case) {
    super(materialStream.id, ownerCase);

    this.initValues(materialStream);
    this.initStreamCompositions(materialStream);
    this.setSimulationVariableNames();
  }

  getAvailableKpis() {
    if (this.ownerCase.multiPeriodEnabled) {
      return [];
    }

    // wtf is this shit
    this.setSimulationVariableNames();
    return [
      this.ccr,
      this.ghgIntensity,
      this.ghgEmissions,
      this.price,
      this.nitrogen,
      this.massDensity,
      this.sulfur,
      this.volumetricFlowrate,
    ];
  }

  override getChildrenObjectsForRemoval(): BaseObject[] {
    const children = super.getChildrenObjectsForRemoval();
    children.push(this.lightEndsCut);
    children.push(this.lightNaphthaCut);
    children.push(this.heavyNaphthaCut);
    children.push(this.distillateCut);
    children.push(this.LGOCut);
    children.push(this.HGOCut);
    children.push(this.VGOCut);
    children.push(this.HVGOCut);
    children.push(this.residCut);

    return children;
  }

  // isn't this object only useful after solving?

  override initValues(materialStream: any): void {
    super.initValues(materialStream);

    this.price = new SimulationVariable(materialStream.price, this.ownerCase, this);
    this.massDensity = new SimulationVariable(materialStream.massDensity, this.ownerCase, this); // SuncorMassDensity
    this.ccr = new SimulationVariable(materialStream.ccr, this.ownerCase, this);
    this.sulfur = new SimulationVariable(materialStream.sulfur, this.ownerCase, this);
    this.nitrogen = new SimulationVariable(materialStream.nitrogen, this.ownerCase, this);
    // general properties
    this.volumetricFlowrate = new SimulationVariable(materialStream.volumetricFlowrate, this.ownerCase, this);
    this.massFlowrate = new SimulationVariable(materialStream.massFlowrate, this.ownerCase, this);
    this.ghgEmissions = new SimulationVariable(materialStream.ghgEmissions, this.ownerCase, this);
    this.ghgIntensity = new SimulationVariable(materialStream.ghgIntensity, this.ownerCase, this);

    this.lightEndsCut = new Cut(materialStream.lightEndsCut || {}, this.ownerCase, this);
    this.lightNaphthaCut = new Cut(materialStream.lightNaphthaCut || {}, this.ownerCase, this);
    this.heavyNaphthaCut = new Cut(materialStream.heavyNaphthaCut || {}, this.ownerCase, this);
    this.distillateCut = new Cut(materialStream.distillateCut || {}, this.ownerCase, this);
    this.LGOCut = new Cut(materialStream.LGOCut || {}, this.ownerCase, this);
    this.HGOCut = new Cut(materialStream.HGOCut || {}, this.ownerCase, this);
    this.VGOCut = new Cut(materialStream.VGOCut || {}, this.ownerCase, this);
    this.HVGOCut = new Cut(materialStream.HVGOCut || {}, this.ownerCase, this);
    this.residCut = new Cut(materialStream.residCut || {}, this.ownerCase, this);
  }

  override setSimulationVariableNames(): void {
    this.price.setName('Price');
    this.massDensity.setName('Mass Density');
    this.ccr.setName('CCR Content');
    this.sulfur.setName('Sulphur Content');
    this.nitrogen.setName('Nitrogen Content');
    this.volumetricFlowrate.setName('Volumetric Flowrate');
    this.massFlowrate.setName('Mass Flowrate');
    this.ghgEmissions.setName('GHG Emissions');
    this.ghgIntensity.setName('GHG Intensity');
    this.massDensity.setName('Mass Density');

    this.setCompositionNames(this.composition);
    this.setCompositionNames(this.volumeComposition);
    this.setCompositionNames(this.distillationComposition);
  }

  override setDefaultValues(): void {
    if (this.price.isUndefined()) {
      this.price.setDefaultValue(null);
    }
    if (this.massDensity.isUndefined()) {
      this.massDensity.setDefaultValue(null);
    }
    if (this.ccr.isUndefined()) {
      this.ccr.setDefaultValue(null);
    }
    if (this.sulfur.isUndefined()) {
      this.sulfur.setDefaultValue(null);
    }
    if (this.nitrogen.isUndefined()) {
      this.nitrogen.setDefaultValue(null);
    }
    if (this.volumetricFlowrate.isUndefined()) {
      this.volumetricFlowrate.setDefaultValue(null);
    }
    if (this.massFlowrate.isUndefined()) {
      this.massFlowrate.setDefaultValue(null, unitConverter.units.Massflowrate.KPPH);
    }
    if (this.ghgEmissions.isUndefined()) {
      this.ghgEmissions.setDefaultValue(null, unitConverter.units.Massflowrate.MMTPA);
    }
    if (this.ghgIntensity.isUndefined()) {
      this.ghgIntensity.setDefaultValue(null);
    }
  }

  initStreamCompositions(materialStream: DefaultMappedType<SuncorMaterialStream>): void {
    if (!this.composition.length) {
      this.initSingleComposition(materialStream.composition, this.composition, this.COMPOSITION_LENGTH);

      if (this.composition.length !== this.COMPOSITION_LENGTH) {
        throw new Error('Wrong number of Stream composition elements!');
      }
    }

    if (!this.volumeComposition.length) {
      this.initSingleComposition(materialStream.volumeComposition, this.volumeComposition, this.COMPOSITION_LENGTH);
      if (this.volumeComposition.length !== this.COMPOSITION_LENGTH) {
        throw new Error('Wrong number of Stream volume composition elements!');
      }
    }

    if (!this.distillationComposition.length) {
      this.initSingleComposition(
        materialStream.distillationComposition,
        this.distillationComposition,
        this.DISTILLATION_COMP_LENGTH
      );
      if (this.distillationComposition.length !== this.DISTILLATION_COMP_LENGTH) {
        throw new Error('Wrong number of Stream distillation composition elements!');
      }
    }
  }

  initSingleComposition(
    streamComposition: any[],
    destinationComposition: SimulationVariable[],
    compositionLength: number
  ) {
    const sourceComposition = this.getStreamCompositionArray(streamComposition);
    this.addCompoundSimVars(destinationComposition, sourceComposition, compositionLength);
    this.setCompositionNames(destinationComposition);
  }

  addCompoundSimVars(
    destinationCompositionArray: SimulationVariable[],
    sourceCompositionArray: any[],
    compositionLength: number
  ) {
    let compound: SimulationVariable;
    for (let i = 0; i < compositionLength; i++) {
      compound = new SimulationVariable(sourceCompositionArray[i] || {}, this.ownerCase, this);
      destinationCompositionArray.push(compound);
    }
  }

  getStreamCompositionArray(streamComposition: any[]) {
    return streamComposition && streamComposition.every((sv: any) => sv instanceof SimulationVariable)
      ? streamComposition
      : [];
  }

  private setCompositionNames(composition: SimulationVariable[]) {
    // Bruh
    let compositionNames: string[] = [];
    if (composition.length === this.COMPOSITION_LENGTH) {
      compositionNames = enumValuesToArray(StreamCompositionNames);
    } else if (composition.length === this.DISTILLATION_COMP_LENGTH) {
      compositionNames = enumValuesToArray(DistillationCompositionNames);
    }

    for (let i = 0; i < composition.length; i++) {
      const compound = composition[i];
      compound.setName(compositionNames[i]);
    }
  }

  override setQuantities() {
    // rushed set quantities here...
    this.volumetricFlowrate.setQuantity('Volumetricflowrate');
    this.massFlowrate.setQuantity(Quantity.MASSFLOWRATE);
    this.ghgEmissions.setQuantity('Massflowrate');
    this.ghgIntensity.setQuantity('MassPerVolume');
    this.price.setQuantity('CurrencyPerVolume');

    this.massDensity.setQuantity('LiquidDensity');
    this.ccr.setQuantity('MassFraction');
    this.sulfur.setQuantity('MassFraction');
    this.nitrogen.setQuantity('MassFraction');

    this.setCompositionQuantities(this.composition, Quantity.MASS_FRACTION);
    this.setCompositionQuantities(this.volumeComposition, Quantity.VOLUME_FRACTION);
    this.setCompositionQuantities(this.distillationComposition, Quantity.VOLUME_FRACTION);
  }

  setCompositionQuantities(composition: SimulationVariable[], quantity: string) {
    for (const sv of composition) {
      sv.setQuantity(quantity);
    }
  }

  hasFlowrate(): boolean {
    return this.volumetricFlowrate.value > 1e-3;
  }

  protected override isPropertyBlackListed(property: any) {
    return property instanceof Case || typeof property === 'function' || property instanceof SuncorUnitOperation;
  }

  // region results handling
  override clearResults() {
    this.massDensity.value = null;
    this.ccr.value = null;
    this.sulfur.value = null;
    this.nitrogen.value = null;
    this.volumetricFlowrate.value = null;
    this.massFlowrate.value = null;
    this.ghgEmissions.value = null;
    this.ghgIntensity.value = null;
    this.price.value = null;
    this.clearCompositionResults(this.composition);
    this.clearCompositionResults(this.volumeComposition);
    this.clearCompositionResults(this.distillationComposition);

    this.lightEndsCut.clearValues();
    this.lightNaphthaCut.clearValues();
    this.heavyNaphthaCut.clearValues();
    this.distillateCut.clearValues();
    this.LGOCut.clearValues();
    this.HGOCut.clearValues();
    this.VGOCut.clearValues();
    this.HVGOCut.clearValues();
    this.residCut.clearValues();
  }

  clearCompositionResults(composition: SimulationVariable[]) {
    for (const c of composition) {
      c.value = null;
    }
  }

  // endregion

  override dePersist(sms: DefaultMappedType<SuncorMaterialStream>) {
    this.massDensity = this.getSimulationVariableSafe(sms.massDensity); // SuncorMassDensity
    this.ccr = this.getSimulationVariableSafe(sms.ccr);
    this.sulfur = this.getSimulationVariableSafe(sms.sulfur);
    this.nitrogen = this.getSimulationVariableSafe(sms.nitrogen);
    this.volumetricFlowrate = this.getSimulationVariableSafe(sms.volumetricFlowrate);
    this.massFlowrate = this.getSimulationVariableSafe(sms.massFlowrate);
    this.ghgEmissions = this.getSimulationVariableSafe(sms.ghgEmissions);
    this.ghgIntensity = this.getSimulationVariableSafe(sms.ghgIntensity);
    this.price = this.getSimulationVariableSafe(sms.price);
    this.isRecycleStream = sms.isRecycleStream;

    // Cuts
    // this.lightEndsCut.dePersist(sms.lightEndsCut);
    // this.lightNaphthaCut.dePersist(sms.lightNaphthaCut);
    // this.heavyNaphthaCut.dePersist(sms.heavyNaphthaCut);
    // this.distillateCut.dePersist(sms.distillateCut);
    // this.LGOCut.dePersist(sms.LGOCut);
    // this.HGOCut.dePersist(sms.HGOCut);
    // this.VGOCut.dePersist(sms.VGOCut);
    // this.HVGOCut.dePersist(sms.HVGOCut);
    // this.residCut.dePersist(sms.residCut);

    // if there was any variable, it will be discarded
    this.composition = this.dePersistComposition(this.composition, sms.composition, this.COMPOSITION_LENGTH);
    this.volumeComposition = this.dePersistComposition(
      this.volumeComposition,
      sms.volumeComposition,
      this.COMPOSITION_LENGTH
    );
    this.distillationComposition = this.dePersistComposition(
      this.distillationComposition,
      sms.distillationComposition,
      this.DISTILLATION_COMP_LENGTH
    );

    this.streamColor = getColor(sms.streamColor);
  }

  dePersistComposition(
    destinationComposition: SimulationVariable[],
    sourceComposition: any[],
    compositionLength: number
  ) {
    destinationComposition = [];
    if (sourceComposition instanceof Array && sourceComposition.every(isTypeString)) {
      for (const svId of sourceComposition) {
        destinationComposition.push(this.getSimulationVariableSafe(svId));
      }
    } else if (!sourceComposition) {
      for (let i = 0; i < compositionLength; i++) {
        destinationComposition.push(this.getSimulationVariableSafe(''));
      }
    } else {
      throw new Error('Attempted to deserialize a wrong stream composition object!!');
    }

    return destinationComposition;
  }

  override getSimVars() {
    const simVars = super.getSimVars();
    this.getCompositionSimVars(simVars, this.composition);
    this.getCompositionSimVars(simVars, this.volumeComposition);
    this.getCompositionSimVars(simVars, this.distillationComposition);

    return simVars;
  }

  getCompositionSimVars(simVars: SimulationVariable[], composition: SimulationVariable[]) {
    for (const ce of composition) {
      simVars.push(ce);
    }
  }

  public toJSON() {
    return {
      key: this.key,
      streamColor: this.streamColor,
      id: this.id,
      category: this.category,
      name: this.name,
      inletUnitOperationId: this.inletUnitOperationId,
      outletUnitOperationId: this.outletUnitOperationId,
      fromPort: this.fromPort,
      toPort: this.toPort,
      massDensity: this.massDensity.id,
      ccr: this.ccr.id,
      sulfur: this.sulfur.id,
      nitrogen: this.nitrogen.id,
      volumetricFlowrate: this.volumetricFlowrate.id,
      massFlowrate: this.massFlowrate.id,
      ghgEmissions: this.ghgEmissions.id,
      ghgIntensity: this.ghgIntensity.id,
      price: this.price.id,
      isRecycleStream: this.isRecycleStream,
      composition: this.composition.map(sv => sv.id),
      volumeComposition: this.volumeComposition.map(sv => sv.id),
      distillationComposition: this.distillationComposition.map(sv => sv.id),
    };
  }
}
