import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Mixer } from '../../../_models/_unit-operations/mixer';
import { SuncorMaterialStream } from '../../../_models';
import { CoreService } from '../../../_services/core.service';
import { AbstractFormGroupPropertyWindow } from '../abstract-form-group-property-window';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import {
  RangeDistributionRatioVariableForm,
  RangeDistributionRatioVariableFormBuilder,
} from '../../../_form-utils/range-distribution-ratio-variable-form-builder';
import { MixerBlendRecipeOption, MixerConstraintOption } from '../../../_config/unit-operations/mixer-enums';
import { BaseStream } from '../../../_models/_streams/base-stream';

@Component({
  selector: 'sob-mixer',
  templateUrl: './mixer.component.html',
  styleUrls: ['./mixer.component.css'],
})
export class MixerComponent extends AbstractFormGroupPropertyWindow implements OnInit, OnDestroy {
  protected readonly MixerBlendRecipeOption = MixerBlendRecipeOption;
  protected readonly MixerConstraintOption = MixerConstraintOption;

  @Input() override formGroupWrapper: BaseObjectFormGroupWrapper;

  protected inletStreams: SuncorMaterialStream[] = [];
  protected outletStream: SuncorMaterialStream;

  @Input() unitOperation!: Mixer;

  private subSink = new SubSink();

  protected formGroup = new FormGroup({
    name: new FormControl(''),
    constraintOption: new FormControl(''),
    blendRecipeOption: new FormControl(''),
    mixingRatioVariables: new FormArray<RangeDistributionRatioVariableForm>([]),
  });

  protected rangeDistributionVariablesFormGroup = new FormGroup({
    ratioVariables: new FormArray<RangeDistributionRatioVariableForm>([]),
  });

  constructor(
    private coreService: CoreService,
    private rangeRatioVariablesFormBuilder: RangeDistributionRatioVariableFormBuilder
  ) {
    super();
    this.subSink.add(
      this.coreService.streamAddedRequest.subscribe(s => {
        if (s.inletUnitOperationId === this.unitOperation.id) {
          this.initOutletStream();
        }
        if (s.outletUnitOperationId === this.unitOperation.id) {
          this.initInletStreams();
          this.addMixingRatioVariable(s.inletUnitOperationId);
        }
      })
    );

    this.subSink.add(
      this.coreService.streamRemovedRequest.subscribe(s => {
        if (s.inletUnitOperationId === this.unitOperation.id) {
          this.removeOutletStream();
        }
        if (s.outletUnitOperationId === this.unitOperation.id) {
          this.removeInletStream(s);
          this.removeMixingRatioVariable(s.inletUnitOperationId);
        }
      })
    );
  }

  ngOnInit() {
    this.initInletStreams();
    this.initOutletStream();
    this.init();
  }

  addControls() {
    this.formGroup.controls.constraintOption.patchValue(this.unitOperation.constraintOption);
    this.formGroup.controls.blendRecipeOption.patchValue(this.unitOperation.blendRecipeOption);
    this.formGroup.controls.name.patchValue(this.unitOperation.name);

    const rangeDistributionVariablesFormArray = this.rangeRatioVariablesFormBuilder.formArray(
      this.unitOperation.mixingRatioVariables
    );

    this.formGroup.controls.mixingRatioVariables = rangeDistributionVariablesFormArray;
    this.rangeDistributionVariablesFormGroup.controls.ratioVariables = rangeDistributionVariablesFormArray;

    // copy everything to the main form... how good is this?
    for (const key of Object.keys(this.formGroup.controls)) {
      this.propertyWindowFormGroup.addControl(key, this.formGroup.controls[key]);
    }
  }

  get selectedBlendRecipeOption() {
    return this.formGroup.controls.blendRecipeOption.value || '';
  }

  isBlendRatioConstraintSelected() {
    return this.formGroup.controls.constraintOption.value === MixerConstraintOption.BLEND_RATIO;
  }

  private initOutletStream() {
    const { ownerCase } = this.unitOperation;
    const outletStreams = ownerCase.filterSuncorMaterialStreams((s: SuncorMaterialStream) => {
      return s.inletUnitOperationId === this.unitOperation.id;
    });

    if (outletStreams.length) {
      [this.outletStream] = outletStreams;
    }
  }

  private initInletStreams() {
    const { ownerCase } = this.unitOperation;
    this.inletStreams = ownerCase.filterSuncorMaterialStreams((s: SuncorMaterialStream) => {
      return s.outletUnitOperationId === this.unitOperation.id;
    });
  }

  private removeOutletStream() {
    this.outletStream = undefined;
  }

  private removeInletStream(removedStream: BaseStream) {
    const matchingStream = this.inletStreams.find(s => s.id === removedStream.id);

    if (!matchingStream) {
      return;
    }

    this.inletStreams.splice(this.inletStreams.indexOf(matchingStream), 1);
  }

  private addMixingRatioVariable(inletUnitOperationId: string) {
    // TODO remove this when unit operation copies are no longer needed
    this.unitOperation.addInletDistributionRatioVariables(inletUnitOperationId);
    const ratioVariable = this.unitOperation.findMixingRatioVariable(inletUnitOperationId);

    const ratioVariableControl = this.rangeRatioVariablesFormBuilder.control(ratioVariable);
    this.rangeDistributionVariablesFormGroup.controls.ratioVariables.push(ratioVariableControl);
  }

  private removeMixingRatioVariable(unitOperationId: string) {
    const formGroupToRemove = this.rangeDistributionVariablesFormGroup.controls.ratioVariables.controls.find(fg => {
      return fg.getFormGroupId() === unitOperationId;
    });

    if (!formGroupToRemove) {
      return;
    }

    const formGroupIndex =
      this.rangeDistributionVariablesFormGroup.controls.ratioVariables.controls.indexOf(formGroupToRemove);
    this.rangeDistributionVariablesFormGroup.controls.ratioVariables.removeAt(formGroupIndex);

    // TODO remove this when unit operation copies are no longer needed
    this.unitOperation.removeInletDistributionRatioVariables(unitOperationId);
  }

  mixingRatioValueControlsReadonly() {
    return this.selectedBlendRecipeOption === MixerBlendRecipeOption.RANGE;
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}
