import { Pipe, PipeTransform } from '@angular/core';

export const $ = 23;

@Pipe({
  name: 'titleCaseComposition',
})
export class TitleCaseCompositionNamePipe implements PipeTransform {
  transform(compositionName: string): string {
    if (
      compositionName !== 'LGO' &&
      compositionName !== 'HGO' &&
      compositionName !== 'VGO' &&
      compositionName !== 'HVGO'
    ) {
      const compositionNameArray: string[] = compositionName.split(' ');
      const compositionNameTitleCase: string[] = [];
      for (let i = 0; i < compositionNameArray.length; i++) {
        const letterTitleCase = compositionNameArray[i].substr(0, 1).toUpperCase();
        compositionNameTitleCase[i] = letterTitleCase + compositionNameArray[i].substr(1);
      }

      return compositionNameTitleCase.join(' ');
    }

    return compositionName;
  }
}
