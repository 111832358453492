// tree structure to represent the flowsheet owners in a case
import { FlowsheetTreeNode } from './flowsheet-tree-node';
import { FlowsheetTreeRoot } from './flowsheet-tree-root';

export class FlowsheetTree {
  root: FlowsheetTreeNode;

  constructor() {
    this.root = new FlowsheetTreeRoot();
  }
}
