import { SimulationVariable } from '../simulation-variable';
import { SuncorUnitOperation } from './suncor-unit-operation';
import { Case } from '../case';
import { KpiProvider } from '../_case-study/kpi-provider';
import { ConstrainableObject } from '../_interfaces/constrainable-object';
import { UnitOperationConstraints } from './unit-operation-constraints';
import { ParameterProvider } from '../_case-study/parameter-provider';
import { MultiPeriodParameterProvider } from '../_multi-period/multi-period-parameter-provider';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { SimulationVariableName } from '../../_config/simulation-variable-name.enum';

declare let unitConverter: any;
export class Refinery
  extends SuncorUnitOperation
  implements KpiProvider, ConstrainableObject, ParameterProvider, MultiPeriodParameterProvider
{
  category = unitOperationsConfig.refinery.key;

  volumetricFlowrate: SimulationVariable;
  cumulativeGhgEmissions: SimulationVariable;
  feedstockValue: SimulationVariable;
  lightEndsMargin: SimulationVariable;
  naphthaMargin: SimulationVariable;
  distillateMargin: SimulationVariable;
  gasOilMargin: SimulationVariable;
  vacuumResidueMargin: SimulationVariable;

  maximumSulfurContent: SimulationVariable;
  maximumMassDensity: SimulationVariable;
  train2MaximumSulfurContent: SimulationVariable;
  train2MaximumMassDensity: SimulationVariable;
  train1Capacity: SimulationVariable;
  train2Capacity: SimulationVariable;
  train2FeedstockValue: SimulationVariable;
  hasTwoInlets: boolean;
  constraints: UnitOperationConstraints;
  train2MinimumFlow: SimulationVariable;

  /**
   *
   */
  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  getAvailableKpis() {
    return [
      this.feedstockValue,
      this.cumulativeGhgEmissions,
      this.volumetricFlowrate,
      this.lightEndsMargin,
      this.naphthaMargin,
      this.distillateMargin,
      this.gasOilMargin,
      this.vacuumResidueMargin,
      this.ghgIntensity,
      this.ghgEmissions,
    ];
  }

  getAvailableMultiPeriodParameters(): SimulationVariable[] {
    return [this.train1Capacity, this.train2Capacity];
  }

  override clearResults() {
    super.clearResults();
    this.volumetricFlowrate.value = null;
    this.feedstockValue.value = null;
    this.ghgIntensity.value = null;
    this.ghgEmissions.value = null;
    this.cumulativeGhgEmissions.value = null;
    this.revenue.value = null;
    this.unitValue.value = null;
    this.opexCarbonDioxide.value = null;
    this.opexTotal.value = null;
    this.capexTotal.value = null;
  }

  override initValues(unitOperation: any): void {
    super.initValues(unitOperation);
    this.volumetricFlowrate = new SimulationVariable(unitOperation.volumetricFlowrate, this.ownerCase, this);
    this.cumulativeGhgEmissions = new SimulationVariable(unitOperation.cumulativeGhgEmissions, this.ownerCase, this);
    this.feedstockValue = new SimulationVariable(unitOperation.feedstockValue, this.ownerCase, this);
    this.lightEndsMargin = new SimulationVariable(unitOperation.lightEndsMargin, this.ownerCase, this);
    this.naphthaMargin = new SimulationVariable(unitOperation.naphthaMargin, this.ownerCase, this);
    this.distillateMargin = new SimulationVariable(unitOperation.distillateMargin, this.ownerCase, this);
    this.gasOilMargin = new SimulationVariable(unitOperation.gasOilMargin, this.ownerCase, this);
    this.vacuumResidueMargin = new SimulationVariable(unitOperation.vacuumResidueMargin, this.ownerCase, this);
    this.maximumSulfurContent = new SimulationVariable(unitOperation.maximumSulfurContent, this.ownerCase, this);
    this.maximumMassDensity = new SimulationVariable(unitOperation.maximumMassDensity, this.ownerCase, this);
    this.train2MaximumSulfurContent = new SimulationVariable(
      unitOperation.train2MaximumSulfurContent,
      this.ownerCase,
      this
    );
    this.train2MaximumMassDensity = new SimulationVariable(
      unitOperation.train2MaximumMassDensity,
      this.ownerCase,
      this
    );
    this.train1Capacity = new SimulationVariable(unitOperation.train1Capacity, this.ownerCase, this);
    this.train2Capacity = new SimulationVariable(unitOperation.train2Capacity, this.ownerCase, this);
    this.train2FeedstockValue = new SimulationVariable(unitOperation.train2FeedstockValue, this.ownerCase, this);
    this.train2MinimumFlow = new SimulationVariable(unitOperation.train2MinimumFlow, this.ownerCase, this);
    if (typeof unitOperation.hasTwoInlets === 'undefined') {
      this.hasTwoInlets = false;
    } else {
      this.hasTwoInlets = unitOperation.hasTwoInlets;
    }
    this.initConstraints();
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('train1Capacity', this.train1Capacity);
    this.constraints.addConstraint('maximumSulfurContent', this.maximumSulfurContent);
    this.constraints.addConstraint(SimulationVariableName.MAXIMUM_MASS_DENSITY, this.maximumMassDensity);
    this.constraints.addConstraint('minimumFlow', this.minimumFlow);
    this.constraints.addConstraint('train2Capacity', this.train2Capacity);
    this.constraints.addConstraint('train2MinimumFlow', this.train2MinimumFlow);
    this.constraints.addConstraint('train2MaximumSulfurContent', this.train2MaximumSulfurContent);
    this.constraints.addConstraint('train2MaximumMassDensity', this.train2MaximumMassDensity);
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.volumetricFlowrate.setName('Volumetric Flowrate');
    this.cumulativeGhgEmissions.setName('Cumulative GHG Emissions');
    this.feedstockValue.setName('Feedstock Value');
    this.lightEndsMargin.setName('Light Ends Margin');
    this.naphthaMargin.setName('Naphtha Margin');
    this.distillateMargin.setName('Distillate Margin');
    this.gasOilMargin.setName('Gas Oil Margin');
    this.vacuumResidueMargin.setName('Vacuum Residue Margin');
    this.maximumSulfurContent.setName(SimulationVariableName.MAXIMUM_SULFUR_CONTENT);
    this.maximumMassDensity.setName(SimulationVariableName.MAXIMUM_MASS_DENSITY);
    this.train2MaximumSulfurContent.setName('Train 2 Maximum Sulfur Content');
    this.train2MaximumMassDensity.setName('Train 2 Maximum Mass Density');
    this.train1Capacity.setName('Train 1 Maximum feed capacity');
    this.train2Capacity.setName('Train 2 Maximum feed capacity');
    this.train2MinimumFlow.setName('Train 2 Minimum flow');
  }

  override setQuantities() {
    super.setQuantities();
    this.volumetricFlowrate.setQuantity('Volumetricflowrate');
    this.cumulativeGhgEmissions.setQuantity('Massflowrate');
    this.feedstockValue.setQuantity('CurrencyPerVolume');
    this.lightEndsMargin.setQuantity('Percent');
    this.naphthaMargin.setQuantity('Percent');
    this.distillateMargin.setQuantity('Percent');
    this.gasOilMargin.setQuantity('Percent');
    this.vacuumResidueMargin.setQuantity('Percent');

    this.maximumSulfurContent.setQuantity('MassFraction');
    this.maximumMassDensity.setQuantity('LiquidDensity');
    this.train2MaximumSulfurContent.setQuantity('MassFraction');
    this.train2MaximumMassDensity.setQuantity('LiquidDensity');
    this.train1Capacity.setQuantity('Volumetricflowrate');
    this.train2Capacity.setQuantity('Volumetricflowrate');
    this.train2MinimumFlow.setQuantity('Volumetricflowrate');
    this.train2FeedstockValue.setQuantity('CurrencyPerVolume');
  }

  override setDefaultValues() {
    super.setDefaultValues();
    if (this.volumetricFlowrate.isUndefined()) {
      this.volumetricFlowrate.setDefaultValue(0.0);
    }

    if (this.cumulativeGhgEmissions.isUndefined()) {
      this.cumulativeGhgEmissions.setDefaultValue(0.0, unitConverter.units.Massflowrate.MMTPA);
    }

    if (this.feedstockValue.isUndefined()) {
      this.feedstockValue.setDefaultValue(0.0);
    }
    if (this.train2FeedstockValue.isUndefined()) {
      this.train2FeedstockValue.setDefaultValue(0.0);
    }

    if (this.lightEndsMargin.isUndefined()) {
      this.lightEndsMargin.setDefaultValue(-5.0);
    }

    if (this.naphthaMargin.isUndefined()) {
      this.naphthaMargin.setDefaultValue(0.0);
    }

    if (this.distillateMargin.isUndefined()) {
      this.distillateMargin.setDefaultValue(18.0);
    }

    if (this.gasOilMargin.isUndefined()) {
      this.gasOilMargin.setDefaultValue(12.0);
    }

    if (this.vacuumResidueMargin.isUndefined()) {
      this.vacuumResidueMargin.setDefaultValue(8.0);
    }

    if (this.maximumMassDensity.isUndefined()) {
      this.maximumMassDensity.setDefaultValue(null, unitConverter.units.LiquidDensity.KG_M3);
    }

    if (this.train2MaximumMassDensity.isUndefined()) {
      this.train2MaximumMassDensity.setDefaultValue(null, unitConverter.units.LiquidDensity.KG_M3);
    }
  }

  getAlternativeGhgIntensity() {
    return new SimulationVariable(this.ghgIntensity, this.ownerCase, this);
  }

  override dePersist(sup: any) {
    super.dePersist(sup);

    this.volumetricFlowrate = this.getSimulationVariableSafe(sup.volumetricFlowrate);
    this.cumulativeGhgEmissions = this.getSimulationVariableSafe(sup.cumulativeGhgEmissions);
    this.feedstockValue = this.getSimulationVariableSafe(sup.feedstockValue);
    this.lightEndsMargin = this.getSimulationVariableSafe(sup.lightEndsMargin);
    this.naphthaMargin = this.getSimulationVariableSafe(sup.naphthaMargin);
    this.distillateMargin = this.getSimulationVariableSafe(sup.distillateMargin);
    this.gasOilMargin = this.getSimulationVariableSafe(sup.gasOilMargin);
    this.vacuumResidueMargin = this.getSimulationVariableSafe(sup.vacuumResidueMargin);

    this.maximumSulfurContent = this.getSimulationVariableSafe(sup.maximumSulfurContent);
    this.maximumMassDensity = this.getSimulationVariableSafe(sup.maximumMassDensity);
    this.train2MaximumSulfurContent = this.getSimulationVariableSafe(sup.train2MaximumSulfurContent);
    this.train2MaximumMassDensity = this.getSimulationVariableSafe(sup.train2MaximumMassDensity);
    this.train1Capacity = this.getSimulationVariableSafe(sup.train1Capacity);
    this.train2Capacity = this.getSimulationVariableSafe(sup.train2Capacity);
    this.train2MinimumFlow = this.getSimulationVariableSafe(sup.train2MinimumFlow);
    this.train2FeedstockValue = this.getSimulationVariableSafe(sup.train2FeedstockValue);

    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }

  // region constraints
  constraintViolated() {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    })[0];

    return (
      inletStream &&
      (this.constraints.constraintValueViolated('train1Capacity', inletStream.volumetricFlowrate.value) ||
        this.constraints.constraintValueViolated('maximumSulfurContent', inletStream.sulfur.value) ||
        this.constraints.constraintValueViolated(
          SimulationVariableName.MAXIMUM_MASS_DENSITY,
          inletStream.massDensity.value
        ))
    );
  }

  constraintValueDefined() {
    // const v = this.capacity.value;
    // return typeof v !== 'undefined' && v + '' !== '' &&  v !== null && !isNaN(v);
    return this.constraints.anyConstraintValueDefined();
  }
  // endregion

  override isPropertyBlackListed(property) {
    return super.isPropertyBlackListed(property) || property === this.constraints;
  }

  getAvailableParameters(): Array<SimulationVariable> {
    return undefined;
  }

  getAvailableParametricStudyParameters(): Array<SimulationVariable> {
    return [
      this.electricityPrice,
      this.opexVar,
      this.opexGasDiesel,
      this.opexPower,
      this.opexFixed,
      this.capexAmortized,
    ];
  }
}
