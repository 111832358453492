import { BaseStream } from './base-stream';
import { Case } from '../case';
import { SimulationVariable } from '../simulation-variable';
import { Quantity } from '../../_config/quantity.enum';
import { StreamCategory } from '../../_config/stream-category.enum';
import { UnitOperation } from '../unit-operation';
import { SimulationVariableName } from '../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../_case-study/kpi-provider';

declare let unitConverter: any;

export class WaterMaterialStream extends BaseStream implements KpiProvider {
  category = StreamCategory.WATER_MATERIAL_STREAM;

  massFlowrate: SimulationVariable;
  temperature: SimulationVariable;
  pressure: SimulationVariable;

  constructor(waterMaterialStream: WaterMaterialStream | any, ownerCase: Case) {
    super(waterMaterialStream.id, ownerCase);

    this.initValues(waterMaterialStream);
    this.setSimulationVariableNames();
  }

  getAvailableKpis() {
    if (this.ownerCase.multiPeriodEnabled) {
      return [];
    }
    this.setSimulationVariableNames();
    return [this.massFlowrate, this.temperature, this.pressure];
  }

  override initValues(baseStream: any | WaterMaterialStream) {
    super.initValues(baseStream);

    this.massFlowrate = new SimulationVariable(baseStream.massFlowrate, this.ownerCase, this);
    this.temperature = new SimulationVariable(baseStream.temperature, this.ownerCase, this);
    this.pressure = new SimulationVariable(baseStream.pressure, this.ownerCase, this);
  }

  override clearResults() {
    this.massFlowrate.value = undefined;
    this.temperature.value = undefined;
    this.pressure.value = undefined;
  }

  override setQuantities() {
    this.massFlowrate.setQuantity(Quantity.MASSFLOWRATE);
    this.temperature.setQuantity(Quantity.TEMPERATURE);
    this.pressure.setQuantity(Quantity.PRESSURE);
  }

  override setSimulationVariableNames() {
    this.massFlowrate.setName(SimulationVariableName.MASS_FLOWRATE);
    this.temperature.setName(SimulationVariableName.TEMPERATURE);
    this.pressure.setName(SimulationVariableName.PRESSURE);
  }

  override setDefaultValues() {
    if (this.massFlowrate.isUndefined()) {
      this.massFlowrate.setDefaultValue(undefined, unitConverter.units.Massflowrate.KPPH);
    }
  }

  hasFlowrate(): boolean {
    return this.massFlowrate.value > 1e-3;
  }

  protected override isPropertyBlackListed(property: any) {
    return property instanceof Case || typeof property === 'function' || property instanceof UnitOperation;
  }

  override dePersist(persistedObj: any) {
    this.massFlowrate = this.getSimulationVariableSafe(persistedObj.massFlowrate);
    this.temperature = this.getSimulationVariableSafe(persistedObj.temperature);
    this.pressure = this.getSimulationVariableSafe(persistedObj.pressure);
  }

  public toJSON() {
    return {
      key: this.key,
      streamColor: this.streamColor,
      id: this.id,
      category: this.category,
      name: this.name,
      inletUnitOperationId: this.inletUnitOperationId,
      outletUnitOperationId: this.outletUnitOperationId,
      fromPort: this.fromPort,
      toPort: this.toPort,
      massFlowrate: this.massFlowrate.id,
      temperature: this.temperature.id,
      pressure: this.pressure.id,
      isRecycleStream: this.isRecycleStream,
    };
  }
}
