import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { CoreService } from '../../core.service';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { Cut } from '../../../_models/_fluid/cut';
import { Workbook } from '../../excel-report/workbook';
import { XlsxUtils } from '../../excel-report/xlsx.utils';
import { AssayManagerExcelReport } from '../../excel-report/assay-manager-excel-report';

@Injectable({
  providedIn: 'root',
})
export class AssayManagerExcelService {
  constructor(private coreService: CoreService) {}

  generateExcelReport(): any {
    const wb = new Workbook();
    wb.SheetNames.push('Assays');
    wb.Sheets.Assays = XlsxUtils.sheetFromArrayOfArrays(
      AssayManagerExcelReport.generateDataArray(this.coreService.currentCase)
    );
    return XlsxUtils.s2ab(XLSX.write(wb, { bookType: 'xlsx', bookSST: false, type: 'binary' }));
  }

  uploadAssays(excelFile) {
    const { currentCase } = this.coreService;
    const wb = XLSX.read(excelFile, { type: 'binary' });
    const sheet = wb.Sheets[wb.SheetNames[0]];
    const assayFields = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    // Get assay names
    const assayNames = assayFields[3] as string[];
    // first column is property name so it's ignored
    for (let i = 1; i < assayNames.length; i++) {
      // check if type exists and is correct
      if (this.validateType(assayFields[4][i])) {
        const fa: SuncorFluidAnalysis = new SuncorFluidAnalysis({}, currentCase, currentCase.assayManager);
        fa.assayName = assayNames[i];
        fa.assayType = assayFields[4][i];

        // read composition
        for (let c = 0; c < 9; c++) {
          if (assayFields[7 + c][i]) {
            fa.composition[c].setDefaultValue(assayFields[7 + c][i]);
          }
        }

        const cuts = [
          'lightEndsCut',
          'lightNaphthaCut',
          'heavyNaphthaCut',
          'distillateCut',
          'LGOCut',
          'HGOCut',
          'VGOCut',
          'HVGOCut',
          'residCut',
        ];

        cuts.forEach((cut, index) => {
          const row = index * 7 + 18;
          const fCut = fa[cut] as Cut;
          fCut.sulfur.setDefaultValue(assayFields[row][i]);
          fCut.anilinePoint.setDefaultValue(assayFields[row + 1][i]);
          fCut.massDensity.setDefaultValue(assayFields[row + 2][i]);
          fCut.nitrogen.setDefaultValue(assayFields[row + 3][i]);
          fCut.ccr.setDefaultValue(assayFields[row + 4][i]);
        });

        // add assay to manager and pools
        currentCase.assayManager.fluidAnalyses.push(fa);
        currentCase.addToPools(fa);
      }
    }
  }

  validateType(type: string): boolean {
    let isValid = false;
    const types = ['Product', 'Diluent', 'Crude oil - Bitumen'];
    if (type) {
      if (types.indexOf(type) >= 0) {
        isValid = true;
      }
    }
    return isValid;
  }
}
