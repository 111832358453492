import { Completion } from '@codemirror/autocomplete';
import { CodemirrorCompletionType } from '../../codemirror-completion.type';

const xtremaSuncorBaseApiVariables: Completion[] = [
  {
    type: CodemirrorCompletionType.VARIABLE,
    label: 'Inletccrpercent',
  },
];

const xtremaSuncorBaseApiMethods: Completion[] = [
  {
    type: CodemirrorCompletionType.METHOD,
    label: 'GetUnitOpInletVolumetricFlowRate(string UnitOperationName)',
    info: 'Gets the UnitOperationName and returns the Inlet Volumetric Flowrate',
    apply: 'GetUnitOpInletVolumetricFlowRate()',
  },

  {
    type: CodemirrorCompletionType.METHOD,
    label: 'GetStreamVolumetricFlowRate(string MaterialStreamName)',
    info: 'Gets the MaterialStreamName as string and returns the volumetric Flowrate of any stream in the flowsheet',
    apply: 'GetStreamVolumetricFlowRate()',
  },

  {
    type: CodemirrorCompletionType.METHOD,
    label: 'GetStreamFlowRate(string MaterialStreamName, FlowrateBasis basis)',
    info:
      'Gets the MaterialStreamName and "FlowrateBasis" and return the flowrate\n' +
      'Valid Options for FlowrateBasis:\n' +
      'FlowrateBasis.Mass (returns in TPD)| FlowrateBasis.Volume (returns in kbpcd)',
    apply: 'GetStreamFlowRate (,)',
  },

  {
    type: CodemirrorCompletionType.METHOD,
    label: 'GetStreamVolumetricFlowRate(string MaterialStreamName)',
    info: 'Gets the MaterialStreamName as string and returns the volumetric Flowrate of any stream in the flowsheet',
    apply: 'GetStreamVolumetricFlowRate()',
  },

  {
    type: CodemirrorCompletionType.METHOD,
    label: 'GetStreamCutFlowRate(string streamName, SuncorCuts suncorCut, FlowrateBasis basis)',
    info: 'Gets streamName, SuncorCut and Flowrate basis and returns the cut flowrate for the given stream',
    apply: 'GetStreamCutFlowRate(, ,)',
  },
];

const xtremaSuncorBaseApiEnums: Completion[] = [
  {
    type: CodemirrorCompletionType.ENUM,
    label: 'SuncorCuts',
    info:
      'Valid options for SuncorCuts \n ' +
      'SuncorCuts.LightEnd, SuncorCuts.LightNaphta, SuncorCuts.HeavyNaphta, \n' +
      'SuncorCuts.Distillate, SuncorCuts.LightGasOil, SuncorCuts.HeavyGasOil, \n' +
      'SuncorCuts.VacGasOil, SuncorCuts.HeavyVacGasOil, SuncorCuts.VacuumResidue',
  },

  {
    type: CodemirrorCompletionType.ENUM,
    label: 'FlowrateBasis',
    info: 'Valid options: FlowrateBasis.Mass, FlowrateBasis.Volume',
  },
];

export const xtremaSuncorBaseApiCompletions = xtremaSuncorBaseApiVariables
  .concat(xtremaSuncorBaseApiMethods)
  .concat(xtremaSuncorBaseApiEnums);
