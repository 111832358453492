import { DefaultBaseObjectFormGroupMapper } from './default-base-object-form-group.mapper';
import { BaseObject } from '../_models/base-object';
import { ArrayDiff } from '../_utils/array-diff';
import { FuelGasSourceImport } from '../_models/_unit-operations/fuel-gas/fuel-gas-source-import';
import { FuelGasSourceInformationStream } from '../_models/_unit-operations/fuel-gas/fuel-gas-source-information-stream';

export class FuelGasSourceImportFormGroupMapper extends DefaultBaseObjectFormGroupMapper {
  override map(source: BaseObject, destination: BaseObject): void {
    super.map(source, destination);

    const sourcePlainObject = source as FuelGasSourceImport;
    const destinationInstance = destination as FuelGasSourceImport;

    const arrayDiff = new ArrayDiff(sourcePlainObject.inletSourceInformationStreams);

    // diff with the source information streams as its base array
    const diff = arrayDiff.diff(
      destinationInstance.inletSourceInformationStreams,
      (l: FuelGasSourceInformationStream, r: FuelGasSourceInformationStream) => {
        return l.providerUnitOperationId === r.providerUnitOperationId;
      }
    );

    // map matching information streams
    for (const sourceInformationStream of diff.intersection) {
      const destinationStream = destinationInstance.inletSourceInformationStreams.find(
        informationStream =>
          informationStream.providerUnitOperationId === sourceInformationStream.providerUnitOperationId
      );

      super.map(sourceInformationStream, destinationStream);
    }

    // add new information streams
    const informationStreamsToAdd = diff.left;
    for (const informationStream of informationStreamsToAdd) {
      const destinationInformationStream = new FuelGasSourceInformationStream(
        informationStream,
        destinationInstance.ownerCase
      );
      destinationInstance.addInformationStream(destinationInformationStream);
    }

    // delete information streams
    const informationStreamsToDelete = diff.right;
    for (const informationStream of informationStreamsToDelete) {
      destinationInstance.removeInformationStream(informationStream.providerUnitOperationId);
    }
  }

  override ignoreForAutoMapping(key: string, source: BaseObject, destination: BaseObject): boolean {
    const result = super.ignoreForAutoMapping(key, source, destination);
    return key === 'inletSourceInformationStreams' || result;
  }
}
