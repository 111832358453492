<div
  class="modal fade"
  id="upgraderReportModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="upgraderReportModalLabel"
  style="margin-bottom: 20px">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="">Upgrader Report</h4>
      </div>
      <div class="modal-body">
        <div *ngIf="currentFlowsheetCategory === undefined && !selectedUpgrader">
          <ul class="nav nav-tabs" role="tablist">
            <li role="presentation" class="active">
              <a href="#mainReport" class="activeUp" aria-controls="mainReport" role="tab" data-toggle="tab">
                <span><strong> Active Upgraders</strong></span>
              </a>
            </li>
          </ul>

          <div class="activeUpgraders" id="mainReport">
            <table class="table">
              <thead>
                <tr>
                  <th style="padding-left: 30px">Upgrader Name</th>
                  <th style="padding-left: 30px"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let up of topOwners">
                  <td style="padding-left: 30px">
                    <p class="nameU">{{ up.name }}</p>
                  </td>
                  <td style="padding-left: 30px">
                    <a (click)="openUpgrader(up)" class="btn btn-primary btn-md">Inspect</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--(currentFlowsheet === undefined || currentFlowsheet === 'extraction') -->
        <div class="tab-content tab-content-padding" id="activeUpg" *ngIf="selectedUpgrader">
          <div>
            <p><strong>Selected Upgrader:</strong> {{ selectedUpgrader.name }}</p>
          </div>

          <div *ngIf="currentFlowsheetCategory === undefined">
            <div class="col-md-12">
              <div class="form-group">
                <button (click)="removeUpgrader()" class="btn btn-primary back" title="back">
                  <i class="fa fa-arrow-left"></i>
                </button>
              </div>
            </div>
          </div>
          <ul class="nav nav-tabs">
            <li class="active">
              <a href="#urGhgReport" role="tab" data-toggle="tab" aria-controls="urGhgReport">GHG Emissions</a>
            </li>

            <li class="{{ detectReportWithData(utilitiesSummaryReport) === 0 ? 'disabled' : '' }}">
              <a href="#urSteam" role="tab" data-toggle="tab" aria-controls="urSteam">Steam</a>
            </li>

            <li class="{{ detectReportWithData(fuelGasSummaryReport) === 0 ? 'disabled' : '' }}">
              <a href="#urFuelGas" role="tab" data-toggle="tab" aria-controls="urFuelGas">Fuel Gas</a>
            </li>

            <li class="{{ constraintRanking.length === 0 ? 'disabled' : '' }}">
              <a href="#urRanking" role="tab" data-toggle="tab" aria-controls="urRanking"> Constraint Ranking</a>
            </li>
          </ul>

          <div class="tab-content tab-content-padding">
            <div role="tabpanel" class="tab-pane fade in active" id="urGhgReport">
              <sob-ghg-upgrader-report></sob-ghg-upgrader-report>
            </div>

            <div role="tabpanel" class="tab-pane fade in" id="urSteam">
              <sob-utilities-summary-report [upgraderName]="selectedUpgrader.name"></sob-utilities-summary-report>
            </div>

            <div role="tabpanel" class="tab-pane fade in" id="urFuelGas">
              <sob-fuel-gas-summary-report [upgraderName]="selectedUpgrader.name"></sob-fuel-gas-summary-report>
            </div>
            <!-- -->
            <div role="tabpanel" class="tab-pane fade in" id="urRanking">
              <sob-constraint-ranking [currentConstraints]="constraintRanking"></sob-constraint-ranking>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer" style="margin-top: 20px"></div>
    </div>
  </div>
</div>
