import { Injectable } from '@angular/core';
import { CaseDeserializerService } from '../case-deserializer/case-deserializer.service';
import { Project } from '../../_models';
import { AppInfo } from '../../_config/app-info';
import { CompatibilityService } from '../compatibility-service';
import { Flowsheet } from '../../_models/flowsheet-manager/flowsheet';
import { AfterDeserializationCompatibilityService } from '../after-deserialization-compatibility-service';

@Injectable()
export class ProjectDeserializerService {
  constructor(
    private caseDeserializer: CaseDeserializerService,
    private compatibility: CompatibilityService,
    private afterDeserializationCompatibility: AfterDeserializationCompatibilityService
  ) {}

  deserialize(serializedProject: string): Project {
    const plainProject = JSON.parse(serializedProject);
    this.compatibility.makeCompatible(plainProject);

    const project = this.plainObjectToProject(plainProject);

    this.afterDeserializationCompatibility.makeCompatible(project);
    return project;
  }

  plainObjectToProject(plainProject: any): Project {
    const project = new Project();
    project.name = plainProject.name || '';
    project.version = plainProject.version || AppInfo.FILE_VERSION.toString(); // hmm...

    for (const serializedCase of plainProject.cases) {
      const c = this.caseDeserializer.plainObjectToCase(serializedCase);
      project.cases.push(c);

      if (!c.getActiveFlowsheet()) {
        const mainFlowsheet = c.getFlowsheet(c.getMainFlowsheetId());
        if (!mainFlowsheet) {
          c.setActiveFlowsheet(new Flowsheet({}, c));
          c.setMainFlowsheetId(c.getActiveFlowsheet().id);
          c.addToPools(c.getActiveFlowsheet());
        } else {
          c.setActiveFlowsheet(mainFlowsheet);
        }
      }
    }

    return project;
  }
}
