import { Case, UnitOperation } from '../../_models';
import { UtilitiesSummaryReportComponent } from '../../flowsheet/summary-report/utilities-summary-report/utilities-summary-report.component';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { ArrayDiff } from '../../_utils/array-diff';
import { UtilitiesSummaryReportItem } from '../../_models/_reports/utilities-summary-report-data';

export class SteamExcelReport {
  static generateDataArray(steamReport: UtilitiesSummaryReportComponent, c: Case, flowsheetOwnerId: string) {
    const dataArray = [
      [
        'Unit Operation Name',
        '900# Steam Make',
        '900# Steam Use',
        '600# Steam Make',
        '600# Steam Use',
        '150# Steam Make',
        '150# Steam Use',
        '50# Steam Make',
        '50# Steam Use',
      ],
    ];

    this.getSteamValuesByUnitOperation(steamReport, dataArray, flowsheetOwnerId, c);
    return dataArray;
  }

  static getUnitOperationNamesBySubFlowsheet(
    flowsheetOwnerId: string,
    c: Case,
    steamReport: UtilitiesSummaryReportComponent
  ) {
    const upgraderSubFlowsheetUos = c.filterUnitOperations((uo: UnitOperation) => {
      return uo.flowsheetId === flowsheetOwnerId;
    });

    const steamSubFlowsheet = upgraderSubFlowsheetUos.filter(u => {
      return u.category === unitOperationsConfig.waterUtilityUnitOperation.key;
    });

    const steamSubFlowsheetUos = c.filterUnitOperations((uo: UnitOperation) => {
      if (steamSubFlowsheet.length !== 0) {
        return uo.flowsheetId === steamSubFlowsheet[0].id;
      }
      return false;
    });

    const unitOperationNamesBySubFlowsheet = upgraderSubFlowsheetUos.concat(steamSubFlowsheetUos).map(u => {
      return u.name;
    });
    const unitOperationNames = this.getReportUnitOperationNames(steamReport);

    const arrayDiff = new ArrayDiff(unitOperationNamesBySubFlowsheet);
    const diff = arrayDiff.diff(unitOperationNames, (l: string, r: string) => {
      return l === r;
    });

    return diff.intersection.length !== 0 ? diff.intersection : [];
  }

  static getReportUnitOperationNames(steamReport: UtilitiesSummaryReportComponent) {
    const unitOperationNames = [];

    for (const property in steamReport) {
      if (property.includes('ReportData')) {
        steamReport[property].reportItems.forEach(uo => {
          unitOperationNames.push(uo.unitOperationName);
        });
      }
    }

    return [...new Set(unitOperationNames)];
  }

  static getSteamValuesByUnitOperation(
    steamReport: UtilitiesSummaryReportComponent,
    dataArray: any[],
    flowsheetOwnerId: string,
    c: Case
  ) {
    const unitOpNames = this.getUnitOperationNamesBySubFlowsheet(flowsheetOwnerId, c, steamReport);

    if (unitOpNames.length !== 0) {
      unitOpNames.forEach(u => {
        let sM900;
        let sU900;
        let sM600;
        let sU600;
        let sM150;
        let sU150;
        let sM50;
        let sU50;

        sM900 = this.getSteamValue(steamReport.steamMake900ReportData.reportItems, u);
        sU900 = this.getSteamValue(steamReport.steamUse900ReportData.reportItems, u);
        sM600 = this.getSteamValue(steamReport.steamMake600ReportData.reportItems, u);
        sU600 = this.getSteamValue(steamReport.steamUse600ReportData.reportItems, u);
        sM150 = this.getSteamValue(steamReport.steamMake150ReportData.reportItems, u);
        sU150 = this.getSteamValue(steamReport.steamUse150ReportData.reportItems, u);
        sM50 = this.getSteamValue(steamReport.steamMake50ReportData.reportItems, u);
        sU50 = this.getSteamValue(steamReport.steamUse50ReportData.reportItems, u);

        dataArray.push([u, sM900, sU900, sM600, sU600, sM150, sU150, sM50, sU50]);
      });
    }
  }

  static getSteamValue(steamReportData: UtilitiesSummaryReportItem[], unitOperationName: string) {
    let steamValue = 0;
    steamReportData.filter(uo => {
      if (uo.unitOperationName === unitOperationName) {
        steamValue = uo.value;
      }
    });
    return steamValue;
  }
}
