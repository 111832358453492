import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FuelGasMaterialStream } from '../../../_models/_streams/fuel-gas-material-stream';
import { CoreService } from '../../../_services/core.service';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';

@Component({
  selector: 'sob-fuel-gas-material-stream',
  templateUrl: './fuel-gas-material-stream.component.html',
  styleUrls: ['./fuel-gas-material-stream.component.css'],
})
export class FuelGasMaterialStreamComponent implements OnInit {
  stream: FuelGasMaterialStream;
  streamGroup: UntypedFormGroup;

  constructor(
    private coreService: CoreService,
    private flowsheetService: FlowsheetService,
    private ref: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private svfb: SimVarFormBuilder
  ) {
    this.flowsheetService.openFuelGasStreamPropertyWindowRequest.subscribe(streamId => {
      this.openStream(streamId);
    });
  }

  ngOnInit(): void {
    this.streamGroup = this.fb.group({ name: this.fb.control('') });

    $('#fuelGasStreamModal').on('hide.bs.modal', e => {
      if (this.streamGroup.invalid) {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.nameControl.markAsDirty();
        return false;
      }

      this.save();
      this.stream = undefined;
      return true;
    });
  }

  openStream(streamId) {
    this.ref.detectChanges();
    this.stream = this.coreService.currentCase.filterAllMaterialStreams(stream => {
      return stream.id === streamId;
    })[0] as FuelGasMaterialStream;

    this.addControls();

    $('#fuelGasStreamModal').modal('show');
  }

  addControls() {
    this.streamGroup = this.fb.group({
      name: this.fb.control(this.stream.name || '', [Validators.required, this.uniqueNameValidator()]),
    });

    this.streamGroup.addControl('flowrate', this.svfb.control(this.stream.flowrate, true));
    this.streamGroup.addControl('heatFlow', this.svfb.control(this.stream.heatFlow, true));
    this.streamGroup.addControl('lhv', this.svfb.control(this.stream.lhv, true));

    this.streamGroup.addControl('compositionUnit', this.fb.control(this.stream.composition[0].unit));
    const compositionArray = this.fb.array([]);

    for (const sv of this.stream.composition) {
      compositionArray.push(this.fb.control({ value: sv.value, disabled: true }));
    }

    this.streamGroup.addControl('composition', compositionArray);
  }

  uniqueNameValidator() {
    return (control: AbstractControl): { [key: string]: any } => {
      if (
        this.stream.ownerCase.filterAllMaterialStreams(
          s => s instanceof FuelGasMaterialStream && s.name === control.value.trim() && s.id !== this.stream.id
        ).length
      ) {
        return { unique: true };
      }
      return null;
    };
  }

  save() {
    this.stream.name = this.nameControl.value.trim();
    this.coreService.saveStream(this.stream);
  }

  get nameControl() {
    return this.streamGroup ? (this.streamGroup.get('name') as UntypedFormControl) : null;
  }
}
