import { ConstrainableObject } from '../_interfaces/constrainable-object';
import { SimulationVariable } from '../simulation-variable';
import { UnitOperationConstraints } from './unit-operation-constraints';
import { Case } from '../case';
// @ts-ignore
import { DEFAULT_HYDROCRACKER_MODEL } from '../../_config/unit-operations/default-models/default-hydrocracker-model';
import { Quantity } from '../../_config/quantity.enum';
import { SimulationVariableName } from '../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../_case-study/kpi-provider';
import { GasContributorUnitOperation } from './gas-contributor-unit-operation';
import { MultiPeriodParameterProvider } from '../_multi-period/multi-period-parameter-provider';
import { ParameterProvider } from '../_case-study/parameter-provider';
import { unitOperationsConfig } from '../../_config/unit-operations.config';

declare let unitConverter: any;

export class Hydrocracker
  extends GasContributorUnitOperation
  implements ConstrainableObject, MultiPeriodParameterProvider, KpiProvider, ParameterProvider
{
  category = unitOperationsConfig.hydrocracker.key;
  volumetricYield: SimulationVariable;
  useCustomModel: boolean;
  modelScript: string;
  hydrogenUse: SimulationVariable;
  hydrogenUseRate: SimulationVariable;
  h2sProductionRate: SimulationVariable;
  h2sProduction: SimulationVariable;
  minimumAPI: SimulationVariable;
  maximumCCR: SimulationVariable;

  constraints: UnitOperationConstraints;
  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [
        this.ghgIntensity,
        this.ghgEmissions,
        this.hydrogenUse,
        this.h2sProductionRate,
        this.h2sProduction,
        this.opexTotal,
        this.capexTotal,
      ];
    }
    return [
      this.ghgIntensity,
      this.ghgEmissions,
      this.hydrogenUse,
      this.h2sProductionRate,
      this.h2sProduction,
      this.steamUse900,
      this.steamMake900,
      this.steamMake600,
      this.steamUse600,
      this.steamMake150,
      this.steamUse150,
      this.steamMake50,
      this.steamUse50,
      this.opexTotal,
      this.capexTotal,
      this.fuelGasHeatingValue,
      this.fuelGasEmissionFactor,
      this.energyFlow,
      this.flowrate,
    ];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [
      this.hydrogenUseRate,
      this.h2sProductionRate,
      this.opexVar,
      this.opexGasDiesel,
      this.opexPower,
      this.opexFixed,
      this.capexAmortized,
    ];
  }

  getAvailableParameters() {
    return undefined;
  }

  getAlternativeGhgIntensity(): SimulationVariable {
    return undefined;
  }

  override initValues(unitOperation: any): void {
    super.initValues(unitOperation);
    this.volumetricYield = new SimulationVariable(unitOperation.volumetricYield, this.ownerCase, this);
    this.hydrogenUse = new SimulationVariable(unitOperation.hydrogenUse || {}, this.ownerCase, this);
    this.hydrogenUseRate = new SimulationVariable(unitOperation.hydrogenUseRate || {}, this.ownerCase, this);
    this.h2sProductionRate = new SimulationVariable(unitOperation.h2sProductionRate || {}, this.ownerCase, this);
    this.h2sProduction = new SimulationVariable(unitOperation.h2sProduction || {}, this.ownerCase, this);
    this.minimumAPI = new SimulationVariable(unitOperation.minimumAPI || {}, this.ownerCase, this);
    this.maximumCCR = new SimulationVariable(unitOperation.maximumCCR || {}, this.ownerCase, this);
    if (typeof unitOperation.useCustomModel === 'undefined') {
      this.useCustomModel = false;
    } else {
      this.useCustomModel = unitOperation.useCustomModel;
    }
    this.modelScript =
      typeof unitOperation.modelScript === 'undefined' ? DEFAULT_HYDROCRACKER_MODEL : unitOperation.modelScript;
    this.initConstraints();
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('capacity', this.capacity);
    this.constraints.addConstraint('minimumFlow', this.minimumFlow);
    this.constraints.addConstraint('minimumAPI', this.minimumAPI);
    this.constraints.addConstraint('maximumCCR', this.maximumCCR);
  }

  override setQuantities() {
    super.setQuantities();
    this.hydrogenUse.setQuantity(Quantity.MOLEFLOWRATE);
    this.hydrogenUseRate.setQuantity(Quantity.GAS_STD_VOLUME_PER_VOLUME);
    this.h2sProduction.setQuantity(Quantity.MOLEFLOWRATE);
    this.h2sProductionRate.setQuantity(Quantity.GAS_STD_VOLUME_PER_VOLUME);
    this.volumetricYield.setQuantity('Percent');
    this.minimumAPI.setQuantity('LiquidDensity');
    this.maximumCCR.setQuantity('MassFraction');
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.volumetricYield = this.getSimulationVariableSafe(sup.volumetricYield);
    this.hydrogenUse = this.getSimulationVariableSafe(sup.hydrogenUse);
    this.hydrogenUseRate = this.getSimulationVariableSafe(sup.hydrogenUseRate);
    this.h2sProduction = this.getSimulationVariableSafe(sup.h2sProduction);
    this.h2sProductionRate = this.getSimulationVariableSafe(sup.h2sProductionRate);
    this.minimumAPI = this.getSimulationVariableSafe(sup.minimumAPI);
    this.maximumCCR = this.getSimulationVariableSafe(sup.maximumCCR);
    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }

  override setDefaultValues(): void {
    super.setDefaultValues();
    if (this.energyFlow.isUndefined()) {
      this.energyFlow.setDefaultValue(0.0, unitConverter.units.Energyrate.GJ_H);
    }
  }

  constraintViolated() {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    })[0];

    return inletStream && this.constraints.constraintValueViolated('capacity', inletStream.volumetricFlowrate.value);
  }

  override clearResults() {
    super.clearResults();
    this.hydrogenUse.value = null;
    this.h2sProduction.value = null;
  }

  constraintValueDefined() {
    return this.constraints.anyConstraintValueDefined();
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.volumetricYield.setName('Volumetric Yield');
    this.maximumCCR.setName(SimulationVariableName.MAXIMUM_CCR);
    this.minimumAPI.setName(SimulationVariableName.MINIMUM_API);
    this.hydrogenUse.setName('Hydrogen Use');
    this.hydrogenUseRate.setName(SimulationVariableName.HYDROGEN_USE_RATE);
    this.opexTotal.setName('Total OPEX');
    this.capexTotal.setName('Total CAPEX');
    this.h2sProductionRate.setName('H2S Production Rate');
    this.h2sProduction.setName('H2S Production');
    this.steamMake900.setName('#900 Steam Make');
    this.steamUse900.setName('#900 Steam Use');
    this.steamMake600.setName('#600 Steam Make');
    this.steamUse600.setName('#600 Steam Use');
    this.steamMake150.setName('#150 Steam Make');
    this.steamUse150.setName('#150 Steam Use');
    this.steamMake50.setName('#50 Steam Make');
    this.steamUse50.setName('#50 Steam Use');
    this.capacity.setName(SimulationVariableName.CAPACITY);
    this.fuelGasHeatingValue.setName(SimulationVariableName.FUEL_GAS_HEATING_VALUE);
    this.fuelGasEmissionFactor.setName(SimulationVariableName.FUEL_GAS_EMISSION_FACTOR);
    this.energyFlow.setName(SimulationVariableName.ENERGY_FLOW);
    this.flowrate.setName(SimulationVariableName.FUEL_GAS_FLOWRATE);
  }

  getAvailableMultiPeriodParameters(): SimulationVariable[] {
    return [this.capacity];
  }

  override detectChanges(another: any): boolean {
    const changes = super.detectChanges(another);
    return changes || another.modelScript !== this.modelScript;
  }
}
