/* eslint-disable no-console */
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent {
  model: any = {};
  error: string;
  success: string;

  loading = false;

  constructor(private router: Router, private userService: UserService) {}

  signup() {
    // does this actually do anything?
    this.loading = true;
    if (this.model.password === this.model.confirmPassword) {
      this.error = '';
      this.userService.signUp(this.model).subscribe(
        () => {
          // eslint-disable-next-line max-len
          this.success = `Thanks for creating your account, we've sent a confirmation email to  ${this.model.userName}. Please check your email to confirm your account. The confirmation email from Suncor OPTEA might be redirected to the Spam folder`;
          this.loading = false;
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 3000);
        },
        error => {
          this.loading = false;
          this.onSignUpError(error);
        }
      );
    } else {
      this.loading = false;
      this.error = 'Passwords do not match';
    }
  }

  private onSignUpError(error: any) {
    this.error = 'An error has occurred';
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      this.error = `An error occurred: ${error.error.message}`;
      console.error('An error occurred:', error.error.message);
    } else if (error.status === 400) {
      if (error.error instanceof Array) {
        this.error = error.error[0];
      } else if (error.error) {
        if (error.error.Password) {
          this.error = error.error.Password[0];
        }
      }
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // eslint-disable-next-line no-console
      console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
    }
  }

  closeModal() {
    if (this.error) {
      this.error = undefined;
    } else {
      this.success = undefined;
    }
  }
}
