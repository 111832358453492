import { AfterViewInit, Component } from '@angular/core';
import { FlowsheetService } from '../../_services/flowsheet.service';
import { CoreService } from '../../_services/core.service';
import { LogManagerService } from '../log-manager/log-manager.service';
import { ProgressIndicatorService } from '../progress-indicator/progress-indicator.service';
import { smoothlyMenu } from '../../app.helpers';
import { DownloadManualService } from '../../_services/download-manual.service';

@Component({
  selector: 'sob-flowsheet-side-menu',
  templateUrl: './flowsheet-side-menu.component.html',
  styleUrls: ['./flowsheet-side-menu.component.css'],
})
export class FlowsheetSideMenuComponent implements AfterViewInit {
  constructor(
    private flowsheetService: FlowsheetService,
    private coreService: CoreService,
    private logManager: LogManagerService,
    private progressIndicator: ProgressIndicatorService,
    private downloadManualService: DownloadManualService
  ) {}

  ngAfterViewInit() {
    (<any>$)('#side-menu').metisMenu();
  }

  createNewProject() {
    this.coreService.createEmptyProject(true);
    this.logManager.clearLogManager();
    this.logManager.info('New case created successfully');
  }

  openAboutWindow() {
    this.flowsheetService.closePropertyWindow();
    $('#aboutModal').modal('show');
  }

  openCaseSettings(): void {
    this.flowsheetService.closePropertyWindow();
    this.flowsheetService.openCaseSettings();
  }

  openConstraintViolationReport(): void {
    $('#constraintViolationReportModal').modal('show');
  }

  openKpiManagerModal(): void {
    this.flowsheetService.closePropertyWindow();
    $('#kpiManagerModal').modal('show');
  }

  openUncertaintyAnalysisModal(): void {
    this.flowsheetService.closePropertyWindow();
    $('#uncertaintyAnalysisModal').modal('show');
  }

  openCaseStudyModal() {
    this.flowsheetService.closePropertyWindow();
    $('#caseStudyModal').modal('show');
  }

  openSaveFileDialog(): void {
    this.flowsheetService.closePropertyWindow();
    $('#saveCaseModal').modal('show');
  }

  openLoadFileDialog(): void {
    this.flowsheetService.closePropertyWindow();
    $('#openCaseModal').modal('show');
  }

  openSummaryReport() {
    this.flowsheetService.closePropertyWindow();
    $('#summaryReportModal').modal('show');
  }

  openMultiPeriodResults() {
    this.flowsheetService.closePropertyWindow();
    $('#multiPeriodResultsModal').modal('show');
  }

  openUpgraderReport() {
    this.flowsheetService.closePropertyWindow();
    $('#upgraderReportModal').modal('show');
  }

  openAssayManagerModal() {
    this.flowsheetService.closePropertyWindow();
    $('#assayManagerModal').modal('show');
  }

  openOptimizationSettingsTable() {
    this.flowsheetService.closePropertyWindow();
    $('#optimizationSettingsTableModal').modal('show');
  }

  openFlowsheetComments() {
    this.flowsheetService.closePropertyWindow();
    $('#flowsheetCommentsModal').modal('show');
  }

  openCaseCommentsModal() {
    this.flowsheetService.closePropertyWindow();
    $('#caseCommentsModal').modal('show');
  }

  excelReport() {
    this.flowsheetService.closePropertyWindow();
    $('#excelReportModal').modal('show');
  }

  downloadManual() {
    this.downloadManualService.download();
  }

  solveCase(): void {
    this.flowsheetService.closePropertyWindow();
    if (this.currentCase.getActiveFlowsheet().isSubflowsheet()) {
      this.flowsheetService.exitToMainFlowsheet();
    }
    this.coreService.solveCase();
  }

  openCaseList() {
    this.flowsheetService.closePropertyWindow();
    $('#caseListModal').modal('show');
  }

  openGlobalKpiModal() {
    this.flowsheetService.closePropertyWindow();
    $('#globalKpiModal').modal('show');
  }

  toggleNavigation(): void {
    $('body').toggleClass('mini-navbar');
    smoothlyMenu();
  }

  get currentCase() {
    return this.coreService.currentCase;
  }

  get isMultiPeriodEnabled(): boolean {
    return !!this.currentCase.multiPeriodEnabled;
  }
}
