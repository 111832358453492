import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CodeEditorComponent } from './code-editor.component';
import { CodeEditorService } from './code-editor.service';
import { OpenCodeEditorButtonComponent } from './open-code-editor-button/open-code-editor-button.component';

@NgModule({
  declarations: [CodeEditorComponent, OpenCodeEditorButtonComponent],
  imports: [CommonModule, FormsModule],
  exports: [CodeEditorComponent, OpenCodeEditorButtonComponent],
  providers: [CodeEditorService],
})
export class CodeEditorModule {}
