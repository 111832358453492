import { Component } from '@angular/core';
import { CoreService } from '../../_services/core.service';
import { FlowsheetDiagramService } from '../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { FileService } from '../../_services/file.service';
import { FlowsheetService } from '../../_services/flowsheet.service';

@Component({
  selector: 'sob-save-case-file',
  templateUrl: './save-case-file.component.html',
  styleUrls: ['./save-case-file.component.css'],
})
export class SaveCaseFileComponent {
  filename: string;

  constructor(
    private coreService: CoreService,
    private flowsheetDiagramService: FlowsheetDiagramService,
    private flowsheetService: FlowsheetService,
    private fileService: FileService
  ) {}

  downloadCase(): void {
    if (this.coreService.currentCase.getActiveFlowsheet().isSubflowsheet()) {
      this.flowsheetService.exitToMainFlowsheet();
    }

    // wait until l1 diagram is loaded
    setTimeout(() => {
      // TODO dangerous assignment - what if a second level flowsheet is active?
      this.coreService.currentCase.setDiagramString(this.flowsheetDiagramService.gojsDiagram.model.toJson());
      this.fileService.downloadFile(
        this.coreService.project.cases,
        this.coreService.projectToJson(),
        `${this.filename || 'unnamed'}.opt`
      );
      $('#saveCaseModal').modal('hide');
    }, 1000);
  }
}
