<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a href="#waterPumpComments" aria-controls="waterPumpComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Inputs</h4>
        <hr />
        <br />

        <div class="form-group">
          <label for="pressureCalculationMode" class="pw-label control-label col-sm-4">
            Pressure Calculation Mode
          </label>
          <div class="col-sm-8" id="pressureCalculationMode">
            <select id="selectMode" (change)="pressureCalculationModeChanged($event.target.value)" class="form-control">
              <option value="Pressure Gain">Pressure Gain</option>
              <option value="Outlet Pressure">Outlet Pressure</option>
              <option value="Duty">Duty</option>
            </select>
          </div>
        </div>

        <div class="form-group" id="pressure-gain">
          <label [attr.for]="'pressureGain'" class="pw-label control-label col-sm-4"> Pressure Gain </label>
          <sim-var-input [inputId]="'pressureGain'" [simVar]="unitOperation.pressureGain"> </sim-var-input>
        </div>

        <div class="form-group" id="outlet-pressure">
          <label [attr.for]="'outletPressure'" class="pw-label control-label col-sm-4"> Outlet Pressure </label>
          <sim-var-input [inputId]="'outletPressure'" [simVar]="unitOperation.outletPressure"></sim-var-input>
        </div>

        <div class="form-group" id="Duty">
          <label [attr.for]="'duty'" class="pw-label control-label col-sm-4"> Duty </label>
          <sim-var-input [inputId]="'duty'" [simVar]="unitOperation.duty"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'efficiency'" class="pw-label control-label col-sm-4"> Efficiency </label>
          <sim-var-input [inputId]="'efficiency'" [simVar]="unitOperation.efficiency"></sim-var-input>
        </div>
        <br />

        <h4 class="subtitle">Results</h4>
        <hr />
        <br />

        <div class="form-group">
          <label [attr.for]="'dutyResult'" class="pw-label control-label col-sm-4"> Duty </label>
          <sim-var-input [inputId]="'dutyResult'" [simVar]="unitOperation.dutyResult" [readOnly]="true"></sim-var-input>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="waterPumpComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
