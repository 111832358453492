import { Injectable } from '@angular/core';

declare let unitConverter: any;

@Injectable()
export class NumberToUnitConverter {
  convert(value: number, quantity: string, unit: string, round: boolean = false): number {
    const internalUnit = this.getInternalUnit(quantity);
    const conversion = unitConverter(quantity).convert(value, internalUnit, unit);

    if (round) {
      return conversion.round().getValue();
    }

    return conversion.getValue();
  }

  convertToInternalUnit(options: { value: number; quantity: string; fromUnit: string }): number {
    const internalUnit = this.getInternalUnit(options.quantity);
    const conversion = unitConverter(options.quantity).convert(options.value, options.fromUnit, internalUnit);
    return conversion.getValue();
  }

  convertToDefaultUnit(value: number, quantity: string, round: boolean = false): number {
    const internalUnit = this.getInternalUnit(quantity);
    const defaultUnit = this.getDefaultUnit(quantity);

    const conversion = unitConverter(quantity).convert(value, internalUnit, defaultUnit);

    return round ? conversion.round().getValue() : conversion.getValue();
  }

  convertDefaultToInternalUnit(value: number, quantity: string, round: boolean = false): number {
    const internalUnit = this.getInternalUnit(quantity);
    const defaultUnit = this.getDefaultUnit(quantity);

    return this.convertToAnotherUnit(value, quantity, defaultUnit, internalUnit, round);
  }

  convertToAnotherUnit(
    value: number,
    quantity: string,
    fromUnit: string,
    toUnit: string,
    round: boolean = false
  ): number {
    const conversion = unitConverter(quantity).convert(value, fromUnit, toUnit);

    if (round) {
      return conversion.round().getValue();
    }

    return conversion.getValue();
  }

  formatNumber(n: number): string {
    return unitConverter.formatNumber(n);
  }

  private getInternalUnit(quantity: string): string {
    return unitConverter(quantity).getInternalUnit();
  }

  private getDefaultUnit(quantity: string): string {
    return unitConverter(quantity).getDefaultUnit();
  }
}
