import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { WaterSource } from '../../../_models/_unit-operations/utilties/water-source';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { VariableStatus } from '../../../_config/variable-status.enum';
import { SimulationVariable } from '../../../_models';
import { hasNumericValue, isEmptyValue } from '../../../_utils/utils';

@Component({
  selector: 'sob-water-source',
  templateUrl: './water-source.component.html',
  styleUrls: ['./water-source.component.css'],
})
export class WaterSourceComponent implements OnInit {
  @Input() unitOperation: WaterSource;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;

  constructor(private fb: UntypedFormBuilder, private svfb: SimVarFormBuilder) {}

  ngOnInit(): void {
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();
    this.grayOutFields();
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl('massFlowrate', this.svfb.control(this.unitOperation.massFlowrate));
    this.propertyWindowFormGroup.addControl('temperature', this.svfb.control(this.unitOperation.temperature));
    this.propertyWindowFormGroup.addControl('pressure', this.svfb.control(this.unitOperation.pressure));
    this.propertyWindowFormGroup.addControl('vaporFraction', this.svfb.control(this.unitOperation.vaporFraction));
    this.propertyWindowFormGroup.addControl('isOptimizable', this.fb.control(this.unitOperation.isOptimizable));
    this.isOptimizableChanged(this.unitOperation.isOptimizable);
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }

  isOptimizableChanged(checked: boolean) {
    if (checked) {
      this.propertyWindowFormGroup.get('massFlowrate').get('value').disable();
    } else {
      if (this.unitOperation.massFlowrate.variableStatus === VariableStatus.SOLVER_CALCULATED) {
        this.unitOperation.massFlowrate.variableStatus = VariableStatus.USER_SPECIFIED;
      }
      this.propertyWindowFormGroup.get('massFlowrate').get('value').enable();
    }
  }

  grayOutFields(): void {
    const temperature = this.propertyWindowFormGroup.get('temperature').get('value');
    const pressure = this.propertyWindowFormGroup.get('pressure').get('value');
    const vaporFraction = this.propertyWindowFormGroup.get('vaporFraction').get('value');

    const controlsWithSimVar: [SimulationVariable, AbstractControl][] = [
      [this.unitOperation.temperature, temperature],
      [this.unitOperation.pressure, pressure],
      [this.unitOperation.vaporFraction, vaporFraction],
    ];

    const controlsWithValue = controlsWithSimVar.filter(item => {
      return hasNumericValue(item[1].value);
    });

    const controlsWithoutValue = controlsWithSimVar.filter(item => {
      return isEmptyValue(item[1].value) || !hasNumericValue(item[1].value);
    });

    const controlsSolverCalculatedWithValue = controlsWithSimVar.filter(item => {
      return !isEmptyValue(item[1].value) && item[0].variableStatus === VariableStatus.SOLVER_CALCULATED;
    });

    if (controlsWithValue.length / controlsWithValue.length === controlsWithoutValue.length) {
      this.disabledControls(controlsWithoutValue);
      this.enabledControls(controlsSolverCalculatedWithValue);
    } else if (controlsWithoutValue.length === 0) {
      this.disabledControls(controlsSolverCalculatedWithValue);
    } else {
      this.enabledControls(controlsWithSimVar);
    }
  }

  disabledControls(controlsArray: [SimulationVariable, AbstractControl][]) {
    controlsArray.forEach(item => {
      item[0].variableStatus = VariableStatus.SOLVER_CALCULATED;
      item[1].disable();
    });
  }

  enabledControls(controlsArray: [SimulationVariable, AbstractControl][]) {
    controlsArray.forEach(item => {
      item[0].variableStatus = VariableStatus.USER_SPECIFIED;
      item[1].enable();
    });
  }
}
