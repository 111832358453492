import { Case } from './case';
import { BaseObject } from './base-object';
import { SimulationVariable } from './simulation-variable';
import { ParameterProvider } from './_case-study/parameter-provider';

export enum RefineryEmissionsOptions {
  SUOnly = 'SUonly',
  All = 'All',
}

export class CaseSettings extends BaseObject implements ParameterProvider {
  category = 'caseSettings';

  cogenMethodology: string;
  operatingDaysPerYear: number;
  carbonPrice: SimulationVariable;
  wtiPrice: SimulationVariable;
  cadUSDExchange: number;
  refineryEmissions: RefineryEmissionsOptions;

  constructor(caseSettings: any, ownerCase: Case) {
    super(caseSettings.id, ownerCase);
    this.initValues(caseSettings);
    this.name = 'Case settings';
    this.setQuantities();
  }

  override detectChanges(another: BaseObject) {
    let changes = super.detectChanges(another);

    // of course it is... but direct cast is not allowed by the transpiler (?)
    if (another instanceof CaseSettings) {
      changes =
        changes ||
        another.cogenMethodology !== this.cogenMethodology ||
        another.operatingDaysPerYear !== this.operatingDaysPerYear ||
        another.cadUSDExchange !== this.cadUSDExchange ||
        another.refineryEmissions !== this.refineryEmissions;
    }

    return changes;
  }

  initValues(caseSettings: any): void {
    // something
    this.cogenMethodology = caseSettings.cogenMethodology || 'OBA';

    if (typeof caseSettings.operatingDaysPerYear === 'undefined') {
      this.operatingDaysPerYear = 365;
    } else {
      this.operatingDaysPerYear = caseSettings.operatingDaysPerYear;
    }

    this.carbonPrice = new SimulationVariable(caseSettings.carbonPrice, this.ownerCase, this);
    this.wtiPrice = new SimulationVariable(caseSettings.wtiPrice, this.ownerCase, this);

    if (typeof caseSettings.cadUSDExchange === 'undefined') {
      this.cadUSDExchange = 0.77;
    } else {
      this.cadUSDExchange = caseSettings.cadUSDExchange;
    }

    this.refineryEmissions = caseSettings.refineryEmissions || RefineryEmissionsOptions.SUOnly;

    this.setSimulationVariableNames();
  }

  setQuantities(): void {
    this.carbonPrice.setQuantity('CurrencyPerMass');
    this.wtiPrice.setQuantity('CurrencyPerVolume');
  }

  setDefaultValues(): void {
    if (this.carbonPrice.isUndefined()) {
      this.carbonPrice.setDefaultValue(30);
    }
    if (this.wtiPrice.isUndefined()) {
      this.wtiPrice.setDefaultValue(80.0);
    }
  }

  override dePersist(sup: any) {
    this.carbonPrice = this.getSimulationVariableSafe(sup.carbonPrice);
    this.wtiPrice = this.getSimulationVariableSafe(sup.wtiPrice);

    this.setSimulationVariablesOwner();
    this.setQuantities();
    this.setDefaultValues();
    this.setSimulationVariableNames();
  }

  setSimulationVariableNames() {
    this.carbonPrice.name = 'Carbon Price';
    this.wtiPrice.name = 'WTI Price';
  }

  getAvailableParameters(): SimulationVariable[] {
    throw new Error('Method not implemented.');
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.carbonPrice, this.wtiPrice];
  }

  public toJSON() {
    return {
      id: this.id,
      category: this.category,
      cogenMethodology: this.cogenMethodology,
      operatingDaysPerYear: this.operatingDaysPerYear,
      cadUSDExchange: this.cadUSDExchange,
      carbonPrice: this.carbonPrice.id,
      wtiPrice: this.wtiPrice.id,
      refineryEmissions: this.refineryEmissions,
    };
  }
}
