import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Case } from '../../case';
import { BaseObject } from '../../base-object';
import { BaseFuelGasSplitter } from './base-fuel-gas-splitter';

export class FuelGasSplitter extends BaseFuelGasSplitter {
  category = unitOperationsConfig.fuelGasSplitter.key;

  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.setQuantities();
  }

  override clearResults() {
    super.clearResults();
  }

  override overwriteValues(another: BaseObject) {
    const fgs = another as FuelGasSplitter;
    this.isOptimizable = fgs.isOptimizable;

    this.name = another.name;
    for (let i = 0; i < fgs.distributionRatioVariables.length; i++) {
      this.distributionRatioVariables[i].value = fgs.distributionRatioVariables[i].value;
    }
  }

  setOptimizable(isOptimizable: boolean) {
    this.isOptimizable = isOptimizable;
  }

  override setSimulationVariableNames() {}
}
