import { SimulationVariable } from '../simulation-variable';
import { BaseObject } from '../base-object';
import { DefaultOptionalPropertiesMappedType } from '../default-mapped-type';
import { Case } from '../case';

export class BitumenConversionOutlet extends BaseObject {
  unitOperationId: string;
  yield: SimulationVariable;
  price: SimulationVariable;
  fluidAnalysisId: string;
  ownerBaseObject: BaseObject;
  category = 'bitumenConversionOutlet';

  constructor(
    bco: DefaultOptionalPropertiesMappedType<BitumenConversionOutlet>,
    ownerCase: Case,
    ownerBaseObject: BaseObject
  ) {
    super(bco.id, ownerCase);
    this.ownerBaseObject = ownerBaseObject;
    this.initValues(bco);
  }

  initValues(bco: DefaultOptionalPropertiesMappedType<BitumenConversionOutlet>) {
    this.unitOperationId = bco.unitOperationId;
    this.fluidAnalysisId = bco.fluidAnalysisId;
    this.yield = new SimulationVariable(bco.yield, this.ownerCase, this);
    this.price = new SimulationVariable(bco.price, this.ownerCase, this);
    this.setQuantites();
  }

  setQuantites() {
    this.yield.setQuantity('Percent');
    this.price.setQuantity('CurrencyPerVolume');
  }

  override dePersist(persistedObj: any) {
    if (persistedObj) {
      this.yield = this.getSimulationVariableSafe(persistedObj.yield);
      this.price = this.getSimulationVariableSafe(persistedObj.price);
    }
    this.setSimulationVariablesOwner();
  }

  toJSON() {
    return {
      unitOperationId: this.unitOperationId,
      yield: this.yield.id,
      price: this.price.id,
      fluidAnalysisId: this.fluidAnalysisId,
      category: this.category,
      id: this.id,
    };
  }
}
