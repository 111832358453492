<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
  </li>

  <li role="presentation">
    <a href="#renewableComments" aria-controls="renewableComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label for="renewableType" class="pw-label control-label col-sm-4">Type</label>
          <div class="col-sm-4">
            <select name="renewableType" id="renewableType" #renewableType class="form-control">
              <option value="Wind">Wind</option>
              <option value="Solar">Solar</option>
              <option value="Offset">Offset</option>
            </select>
          </div>
          <div class="col-sm-4 add-renewable">
            <button class="btn btn-sm btn-primary" (click)="addRenewable(renewableType.value)">Add Renewable</button>
          </div>
        </div>
        <ng-container *ngFor="let sr of unitOperation.renewablesList; let i = index">
          <hr />

          <div>
            <h4 class="subtitle" style="display: inline-block">{{ sr.name }}</h4>
            <button class="btn btn-danger btn-xs remove-renewable" (click)="removeRenewable(sr.id)">Remove</button>
          </div>
          <sob-suncor-renewable [suncorRenewable]="sr"></sob-suncor-renewable>
        </ng-container>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="renewableComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
