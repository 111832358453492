<div class="layer-info-container" id="sub-flowsheet-info">
  <img src="/assets/img/icons/layers.png" />

  <ng-container *ngFor="let ownerName of subFlowsheetOwnerNames; let i = index">
    <span class="label layer-label">{{ ownerName }}</span>
    <span style="padding-left: 5px" *ngIf="subFlowsheetOwnerNames.length - 1 === i">
      <a class="btn btn-xs btn-danger close-btn" (click)="closeSubFlowsheet()">
        <i class="fa fa-close"></i>
      </a>
    </span>
  </ng-container>
</div>
