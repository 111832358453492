import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../../_services/api.service';
import { SolveCanceller } from '../../_models/solve-canceller';

@Injectable()
export class ProgressIndicatorService {
  private apiService: ApiService;
  solveCanceller: SolveCanceller;

  private showSubject = new Subject();
  showRequest = this.showSubject.asObservable();

  private hideSubject = new Subject();
  hideRequest = this.hideSubject.asObservable();

  private cancelSubject = new Subject();
  cancelRequest = this.cancelSubject.asObservable();

  show(data?: { message?: string; showStopButton?: boolean; solveCanceller?: SolveCanceller }) {
    if (data) {
      this.solveCanceller = data.solveCanceller;
    }
    this.showSubject.next(data || {});
  }

  hide() {
    this.hideSubject.next();
  }

  cancel() {
    // this.apiService.cancelSolveCase();
    this.solveCanceller.cancelSolve();
    this.hide();
    this.solveCanceller = undefined;
  }

  setApiService(apiService: ApiService) {
    this.apiService = apiService;
  }
}
