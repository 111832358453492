export const gasContributorCodeCompletions = [
  { label: 'HydrogenUse_MMSCFD', type: 'variable', info: '' },
  { label: 'HydrogenProduction_MMSCFD', type: 'variable', info: '' },
  { label: 'H2SProduction_MMSCFD', type: 'variable', info: '' },
  { label: 'AmineCirculationRate_kbpcd', type: 'variable', info: '' },
  { label: 'SulfurFlowrate_MMSCFD', type: 'variable', info: '' },
  { label: 'SteamMake900_kbpcd', type: 'variable', info: '' },
  { label: 'SteamMake600_kbpcd', type: 'variable', info: '' },
  { label: 'SteamMake150_kbpcd', type: 'variable', info: '' },
  { label: 'SteamMake50_kbpcd', type: 'variable', info: '' },
  { label: 'GasUse_Energy', type: 'variable', info: '' },
  { label: 'GasMake_Flow', type: 'variable', info: '' },
];
