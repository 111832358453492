import { Component } from '@angular/core';
import { CoreService } from '../../_services/core.service';
import { LogManagerService } from '../log-manager/log-manager.service';
import { FileService } from '../../_services/file.service';
import { FlowsheetDiagramService } from '../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { ProgressIndicatorService } from '../progress-indicator/progress-indicator.service';
import { Upgrader } from '../../_models/_unit-operations/upgrader';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { FlowsheetService } from '../../_services/flowsheet.service';
import { Flowsheet } from '../../_models/flowsheet-manager/flowsheet';
import { Case } from '../../_models';
import { ProjectFileLoaderService } from '../../_services/project-deserializer/project-file-loader.service';

@Component({
  selector: 'sob-open-case-file',
  templateUrl: './open-case-file.component.html',
  styleUrls: ['./open-case-file.component.css'],
})
export class OpenCaseFileComponent {
  constructor(
    private coreService: CoreService,
    private logManager: LogManagerService,
    private fileService: FileService,
    private flowsheetService: FlowsheetService,
    private flowsheetDiagramService: FlowsheetDiagramService,
    private progressIndicator: ProgressIndicatorService,
    private projectFileLoader: ProjectFileLoaderService
  ) {}

  uploadCase(): void {
    // fetch data from uploaded file
    if (!File || !FileReader || !FileList || !Blob) {
      alert('The file APIs are not fully supported in this browser');
    }

    const input = <HTMLInputElement>document.getElementById('caseFileInput');

    if (!input) {
      alert('Could not find the file input element');
    } else if (!input.files[0]) {
      alert('Please select a file before clicking "Load"');
    } else {
      const file = input.files[0];
      const fr = new FileReader();

      fr.onload = e => {
        this.loadLegacyCase((<any>e).target.result, file.name);
      };
      this.fileService
        .getPools(file)
        .then(pools => {
          this.loadPools(pools, file.name);
          this.loadDiagrams(file);
          this.logManager.info('File uploaded successfully.');
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.warn(e);
          fr.readAsText(file);
        });
      $('#openCaseModal').modal('hide');
      this.progressIndicator.show({ message: 'Loading file' });
    }
  }

  loadLegacyCase(pools, filename) {
    this.projectFileLoader.load(pools, filename, false);
    this.logManager.clearLogManager();
    this.logManager.info('File uploaded successfully.');
  }

  loadPools(pools, filename) {
    this.projectFileLoader.load(pools, filename, true);
  }

  loadDiagrams(file: File) {
    this.coreService.project.cases.forEach((c, index) => {
      this.fileService.getFile(file, c.name || 'unnamed').then(diagram => {
        // not a dangerous assignment
        c.setDiagramString(diagram);

        // this is not set when saving a case, apparently
        // this is needed for correct cloning
        c.getFirstLevelFlowsheet().setDiagramJson(diagram);

        if (index === 0) {
          this.flowsheetDiagramService.setDiagramModel(diagram);
          this.flowsheetDiagramService.updateStreamData(c, true);
          const upgraders = c.filterUnitOperations((uo: Upgrader) => {
            return uo.category === unitOperationsConfig.upgrader.key && uo.useSubFlowsheet;
          }) as Upgrader[];

          // TODO is this just for compatibility purposes? or is it useful for something else?
          this.flowsheetDiagramService.updateUpgraders(upgraders);
          this.flowsheetService.updateMaxLinksForAllSubFlowsheets();
        }
      });

      // eslint-disable-next-line guard-for-in
      for (const fId in c.flowsheetPool) {
        const flowsheet = c.flowsheetPool[fId];
        if (flowsheet.unitOperationId) {
          this.loadFlowsheetFile(flowsheet, c, file);
        } else {
          flowsheet.setDiagramJson(c.getDiagramString());
        }
      }
    });
  }

  loadFlowsheetFile(flowsheet: Flowsheet, c: Case, file: File) {
    this.fileService
      .getFile(file, `${flowsheet.unitOperationId}@${c.id}`)
      .then(diagram => flowsheet.setDiagramJson(diagram))
      .catch(() => {
        // to keep compatibility with cases that only use owner unit operation id to store flowsheet diagram
        this.fileService.getFile(file, flowsheet.unitOperationId).then(diagram => flowsheet.setDiagramJson(diagram));
      });
  }
}
