import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { SteamUseUnitOperation } from '../../../_models/_unit-operations/steam-use-unit-operation';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { SteamContributorCodeProvider } from '../../../_services/custom-code-providers/steam-contributor-code-provider';

@Component({
  selector: 'sob-reactive-steam-use-utility',
  templateUrl: './reactive-steam-use-utility.component.html',
  styleUrls: ['./reactive-steam-use-utility.component.css'],
})
export class ReactiveSteamUseUtilityComponent implements OnInit {
  @Input() unitOperation: SteamUseUnitOperation;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;
  defaultCodeProvider: SteamContributorCodeProvider;
  isOpened = false;

  constructor(
    private fb: UntypedFormBuilder,
    private svfb: SimVarFormBuilder,
    private flowsheetService: FlowsheetService
  ) {
    this.defaultCodeProvider = new SteamContributorCodeProvider();
  }

  ngOnInit(): void {
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl(
      'useSteamCustomModel',
      this.fb.control(!!this.unitOperation.useSteamCustomModel)
    );
    this.propertyWindowFormGroup.addControl('steamModelScript', this.fb.control(this.unitOperation.steamModelScript));
    this.propertyWindowFormGroup.addControl('steamMake900', this.svfb.control(this.unitOperation.steamMake900, true));
    this.propertyWindowFormGroup.addControl('steamUse900', this.svfb.control(this.unitOperation.steamUse900, true));
    this.propertyWindowFormGroup.addControl('steamMake600', this.svfb.control(this.unitOperation.steamMake600, true));
    this.propertyWindowFormGroup.addControl('steamUse600', this.svfb.control(this.unitOperation.steamUse600, true));
    this.propertyWindowFormGroup.addControl('steamMake150', this.svfb.control(this.unitOperation.steamMake150, true));
    this.propertyWindowFormGroup.addControl('steamUse150', this.svfb.control(this.unitOperation.steamUse150, true));
    this.propertyWindowFormGroup.addControl('steamMake50', this.svfb.control(this.unitOperation.steamMake50, true));
    this.propertyWindowFormGroup.addControl('steamUse50', this.svfb.control(this.unitOperation.steamUse50, true));
  }

  get useSteam() {
    this.unitOperation.useSteamCustomModel = this.propertyWindowFormGroup.get('useSteamCustomModel').value;
    return this.unitOperation.useSteamCustomModel;
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }

  switchOpened() {
    $('#steamDiv').collapse('toggle');
    this.isOpened = !this.isOpened;
  }

  saveSteamCustomModel(res: any) {
    if (res.instanceId === 'reactiveSteamCodeEditor') {
      this.propertyWindowFormGroup.get('steamModelScript').setValue(res.code);
      this.unitOperation.steamModelScript = this.propertyWindowFormGroup.get('steamModelScript').value;
      this.flowsheetService.saveCustomCodeChanges();
    }
  }
}
