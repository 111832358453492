import { Completion } from '@codemirror/autocomplete';

export const dcuCodeCompletions: Completion[] = [
  { label: 'GasYield', type: 'variable', info: '' },
  { label: 'LightNaphtaYield', type: 'variable', info: '' },
  { label: 'HeavyNaphtaYield', type: 'variable', info: '' },
  { label: 'DistillateYield', type: 'variable', info: '' },
  { label: 'GasOilYield', type: 'variable', info: '' },
  { label: 'CokeYield', type: 'variable', info: '' },
  { label: 'LightNaphtaAPI', type: 'variable', info: '' },
  { label: 'HeavyNaphtaAPI', type: 'variable', info: '' },
  { label: 'DistillateAPI', type: 'variable', info: '' },
  { label: 'GasOilAPI', type: 'variable', info: '' },
  { label: 'LightNaphtaSulfur', type: 'variable', info: '' },
  { label: 'HeavyNaphtaSulfur', type: 'variable', info: '' },
  { label: 'DistillateSulfur', type: 'variable', info: '' },
  { label: 'GasOilSulfur', type: 'variable', info: '' },
  { label: 'LightNaphtaNitrogen', type: 'variable', info: '' },
  { label: 'HeavyNaphtaNitrogen', type: 'variable', info: '' },
  { label: 'DistillateNitrogen', type: 'variable', info: '' },
  { label: 'GasOilNitrogen', type: 'variable', info: '' },
];
