import { Component, Input } from '@angular/core';
import { SuncorRenewable } from '../../../../_models/_unit-operations/suncor-renewable';

@Component({
  selector: 'sob-suncor-renewable',
  templateUrl: './suncor-renewable.component.html',
  styleUrls: ['./suncor-renewable.component.css'],
})
export class SuncorRenewableComponent {
  @Input()
  suncorRenewable: SuncorRenewable;
}
