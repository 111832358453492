<div id="flowsheet-spreadsheet-wrapper" style="width: 100%">
  <wj-flex-sheet #flex (initialized)="initializeFlexSheet(flex)">
    <!--    <wj-sheet [name]="'Inputs-Sources'" [columnCount]="20" [rowCount]="35"> </wj-sheet>-->

    <!--    <wj-sheet [name]="'Inputs-Upgraders'" [columnCount]="20" [rowCount]="35"> </wj-sheet>-->

    <!--    <wj-sheet [name]="'Inputs-Pipes'" [columnCount]="20" [rowCount]="35"> </wj-sheet>-->

    <!--    <wj-sheet [name]="'Inputs-Refineries'" [columnCount]="20" [rowCount]="35"> </wj-sheet>-->

    <wj-sheet [name]="'Assay Manager - Liquids'" [columnCount]="20" [rowCount]="77"> </wj-sheet>

    <wj-sheet [name]="'Assay Manager - Gases'" [columnCount]="20" [rowCount]="20"> </wj-sheet>

    <!--  <wj-sheet [name]="'Utilities'" [columnCount]="20" [rowCount]="77" >-->
    <!--  </wj-sheet>-->

    <!--  <wj-sheet [name]="'H2S Production'" [columnCount]="20" [rowCount]="77" >-->
    <!--  </wj-sheet>-->
  </wj-flex-sheet>
</div>
