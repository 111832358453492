import { Injectable } from '@angular/core';
import * as wjcSheet from '@grapecity/wijmo.grid.sheet';

import { SpreadsheetHandler } from './spreadsheet-handler';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { CoreService } from '../core.service';
import { FlexSheetEventHandler } from './event-handlers/flex-sheet-event-handler';
import { FlexSheetColumnTracker } from './flex-sheet-column-tracker';
import { AbstractSheetHandler } from './sheet-handlers/abstract-sheet-handler';
import { Case, UnitOperation } from '../../_models';
import { AssaySheetHandler } from './sheet-handlers/assay-sheet-handler';
import { SuncorFluidAnalysis } from '../../_models/_fluid/suncor-fluid-analysis';
import { AssayGasesSheetHandler } from './sheet-handlers/assay-gases-sheet-handler';
import { GasFluidAnalysis } from '../../_models/_fluid/gas-fluid-analysis';

@Injectable()
export class FlexSheetSpreadsheetService implements SpreadsheetHandler {
  private readonly ASSAY_SHEET = 0;
  private readonly ASSAY_COLUMN_OFFSET = 1;

  private readonly ASSAY_GASES_SHEET = 1;
  private readonly ASSAY_GASES_COLUMN_OFFSET = 1;

  private spreadsheet: wjcSheet.FlexSheet;

  assaySheetHandler: AbstractSheetHandler;
  assayColumnTracker: FlexSheetColumnTracker;

  assayGasesSheetHandler: AbstractSheetHandler;
  assayGasesColumnTracker: FlexSheetColumnTracker;

  editHandler: FlexSheetEventHandler;

  constructor(private coreService: CoreService) {
    this.coreService.currentCaseReplacedRequest.subscribe(() => {
      this.caseToSpreadsheet(this.coreService.currentCase);
    });
  }

  init(spreadsheet: wjcSheet.FlexSheet) {
    this.spreadsheet = spreadsheet;
    this.spreadsheet.frozenRows = 3;
    this.initializeEditEventHandler();
    this.initializePasteEventHandler();

    this.assayColumnTracker = new FlexSheetColumnTracker(this.ASSAY_COLUMN_OFFSET);
    this.assaySheetHandler = new AssaySheetHandler(this.spreadsheet, this.assayColumnTracker);
    this.assaySheetHandler.init();

    this.assayGasesColumnTracker = new FlexSheetColumnTracker(this.ASSAY_GASES_COLUMN_OFFSET);
    this.assayGasesSheetHandler = new AssayGasesSheetHandler(this.spreadsheet, this.assayGasesColumnTracker);
    this.assayGasesSheetHandler.init();

    // TODO what to do if case is changed?
    this.editHandler = new FlexSheetEventHandler();

    // Hide the context menu for preventing the addition of rows and columns
    // https://www.grapecity.com/forums/wijmo/disable-the-context-menu-o_1
    this.spreadsheet.hostElement.addEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault();
      },
      true
    );

    // TODO this should be tested from an ipad or iphone using safari, the context menu hooks in the
    // touchstart event
    // hide contextmenu in iPad safari/chrome
    this.spreadsheet.hostElement.addEventListener(
      'touchstart',
      () => {
        const { userAgent } = window.navigator;
        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
          setTimeout(() => {
            (this.spreadsheet as any)._contextMenu.hide();
          }, 501);
        }
      },
      true
    );

    this.spreadsheet.showFilterIcons = false;
  }

  dispose() {
    // TODO end any subscriptions here
  }

  caseToSpreadsheet(c: Case) {
    // eslint-disable-next-line guard-for-in
    for (const id in c.unitOperationPool) {
      this.addUnitOperation(c.getUnitOperation(id) as SuncorUnitOperation);
    }
    // eslint-disable-next-line guard-for-in
    for (const index in c.assayManager.fluidAnalyses) {
      if (c.assayManager.fluidAnalyses[index] instanceof SuncorFluidAnalysis) {
        this.addAssay(c.assayManager.fluidAnalyses[index] as SuncorFluidAnalysis);
      } else if (c.assayManager.fluidAnalyses[index] instanceof GasFluidAnalysis) {
        this.addAssay(c.assayManager.fluidAnalyses[index] as GasFluidAnalysis);
      }
    }
  }

  addUnitOperation(unitOperation: SuncorUnitOperation | UnitOperation) {
    const sheetHandlers = this.getSheetHandlers(unitOperation);

    sheetHandlers.forEach(sheetHandler => {
      sheetHandler.addUnitOperation(unitOperation);
    });
  }

  addAssay(assay: SuncorFluidAnalysis | GasFluidAnalysis) {
    const sheetHandlers = this.getSheetHandlers(assay);

    sheetHandlers.forEach(sheetHandler => {
      sheetHandler.addAssay(assay);
    });
  }

  updateUnitOperation(unitOperation: SuncorUnitOperation) {
    const sheetHandlers = this.getSheetHandlers(unitOperation);

    sheetHandlers.forEach(sheetHandler => {
      sheetHandler.updateUnitOperation(unitOperation);
    });
  }

  updateAssay(assay: SuncorFluidAnalysis | GasFluidAnalysis) {
    const sheetHandlers = this.getSheetHandlers(assay);

    sheetHandlers.forEach(sheetHandler => {
      sheetHandler.updateAssay(assay);
    });
  }

  removeUnitOperation(unitOperation: SuncorUnitOperation) {
    const sheetHandlers = this.getSheetHandlers(unitOperation);

    sheetHandlers.forEach(sheetHandler => {
      sheetHandler.removeUnitOperation(unitOperation);
    });
  }

  removeAssay(assay: SuncorFluidAnalysis | GasFluidAnalysis) {
    const sheetHandlers = this.getSheetHandlers(assay);

    sheetHandlers.forEach(sheetHandler => {
      sheetHandler.removeAssay(assay);
    });
  }

  getSheetHandlers(
    unitOperation: SuncorUnitOperation | UnitOperation | SuncorFluidAnalysis | GasFluidAnalysis
  ): AbstractSheetHandler[] {
    const sheetHandlers = [];

    if (this.assaySheetHandler.isUnitOperationAllowed(unitOperation)) {
      sheetHandlers.push(this.assaySheetHandler);
    }
    if (this.assayGasesSheetHandler.isUnitOperationAllowed(unitOperation)) {
      sheetHandlers.push(this.assayGasesSheetHandler);
    }

    return sheetHandlers;
  }

  private initializeEditEventHandler() {
    this.spreadsheet.cellEditEnding.addHandler((spreadsheet: wjcSheet.FlexSheet, flexSheetEvent: any) => {
      let object = null;
      object = this.getUnitOperation(flexSheetEvent);
      if (!object) {
        flexSheetEvent.cancel = true;
        return;
      }
      this.editHandler.handleEditEvent(object, spreadsheet, flexSheetEvent);
      if (object instanceof SuncorFluidAnalysis || object instanceof GasFluidAnalysis) {
        this.coreService.notifyAssayUpdated(object, true);
      } else {
        this.coreService.notifyUnitOperationUpdated(object, true);
      }
    });
  }

  private initializePasteEventHandler() {
    this.spreadsheet.pastingCell.addHandler((spreadsheet: wjcSheet.FlexSheet, flexSheetEvent: any) => {
      const object = this.getUnitOperation(flexSheetEvent);

      if (!object) {
        flexSheetEvent.cancel = true;
        return;
      }

      this.editHandler.handleCellPasteEvent(object, spreadsheet, flexSheetEvent);
      if (object instanceof SuncorFluidAnalysis || object instanceof GasFluidAnalysis) {
        this.coreService.notifyAssayUpdated(object, true);
      } else {
        this.coreService.notifyUnitOperationUpdated(object, true);
      }
    });
  }

  private getUnitOperation(flexsheetEvent: any): SuncorUnitOperation | SuncorFluidAnalysis | GasFluidAnalysis {
    let unitOperationId;
    let assayId;
    const column = flexsheetEvent.col;
    if (this.spreadsheet.selectedSheetIndex === this.ASSAY_SHEET) {
      assayId = this.assayColumnTracker.findAssayId(column);
    } else if (this.spreadsheet.selectedSheetIndex === this.ASSAY_GASES_SHEET) {
      assayId = this.assayGasesColumnTracker.findAssayId(column);
    }

    if (unitOperationId) {
      return this.coreService.currentCase.getUnitOperation(unitOperationId) as SuncorUnitOperation;
    }
    if (assayId) {
      const fluidAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(assayId);

      if (fluidAnalysis instanceof SuncorFluidAnalysis) {
        return this.coreService.currentCase.assayManager.findFluidAnalysisById(assayId) as SuncorFluidAnalysis;
      }
      if (fluidAnalysis instanceof GasFluidAnalysis) {
        return this.coreService.currentCase.assayManager.findFluidAnalysisById(assayId) as GasFluidAnalysis;
      }
    }

    return undefined;
  }

  clearResults() {}

  clear() {}
}
