<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a href="#waterHeaterComments" aria-controls="waterHeaterComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Inputs</h4>
        <hr />
        <br />

        <div class="form-group">
          <label for="temperatureCalculationMode" class="pw-label control-label col-sm-4">
            Temperature Calculation Mode
          </label>
          <div class="col-sm-8" id="temperatureCalculationMode">
            <select
              id="selectMode"
              (change)="temperatureCalculationModeChanged($event.target.value)"
              class="form-control">
              <option value="Temperature Gain">Temperature Gain</option>
              <option value="Outlet Temperature">Outlet Temperature</option>
            </select>
          </div>
        </div>

        <div class="form-group" id="temperature-gain">
          <label [attr.for]="'temperatureGain'" class="pw-label control-label col-sm-4"> Temperature Gain </label>
          <sim-var-input [inputId]="'temperatureGain'" [simVar]="unitOperation.temperatureGain"> </sim-var-input>
        </div>

        <div class="form-group" id="outlet-temperature">
          <label [attr.for]="'outletTemperature'" class="pw-label control-label col-sm-4"> Outlet Temperature </label>
          <sim-var-input [inputId]="'outletTemperature'" [simVar]="unitOperation.outletTemperature"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'pressureDrop'" class="pw-label control-label col-sm-4"> Pressure Drop </label>
          <sim-var-input [inputId]="'pressureDrop'" [simVar]="unitOperation.pressureDrop"></sim-var-input>
        </div>
        <br />

        <h4 class="subtitle">Results</h4>
        <hr />
        <br />

        <div class="form-group">
          <label [attr.for]="'duty'" class="pw-label control-label col-sm-4"> Duty </label>
          <sim-var-input [inputId]="'duty'" [simVar]="unitOperation.duty" [readOnly]="true"></sim-var-input>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="waterHeaterComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
