<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
  </li>

  <li role="presentation">
    <a href="#waterTurbineComments" aria-controls="waterTurbineComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg {{ this.unitOperation.comments ? 'gentle-hover-shake' : '' }}" aria-hidden="true">
      </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding" [formGroup]="propertyWindowFormGroup">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Inputs</h4>
        <hr />
        <br />
        <div class="form-group">
          <label for="pressureCalculationMode" class="pw-label control-label col-sm-4">
            Pressure Calculation Mode
          </label>

          <div class="col-sm-8">
            <select
              class="form-control"
              (change)="pressureMode($event.target.value)"
              formControlName="pressureCalculationMode"
              id="pressureCalculationMode">
              <option value="{{ PressureCalculationMode.OUTLET_PRESSURE }}">Outlet Pressure</option>
              <option value="{{ PressureCalculationMode.PRESSURE_DROP }}">Pressure Drop</option>
              <option value="{{ PressureCalculationMode.DUTY }}">Duty</option>
            </select>
          </div>
        </div>
        <ng-container *ngIf="this.unitOperation.pressureCalculationMode === 'Pressure Drop'">
          <div class="form-group" formGroupName="pressureDrop">
            <label class="control-label col-sm-4"> Pressure Drop </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.pressureDrop.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.pressureDrop">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="this.unitOperation.pressureCalculationMode === 'Outlet Pressure'">
          <div class="form-group" formGroupName="outletPressure">
            <label class="control-label col-sm-4"> Outlet Pressure </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.outletPressure.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.outletPressure">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="this.unitOperation.pressureCalculationMode === 'Duty'">
          <div class="form-group" formGroupName="duty">
            <label class="control-label col-sm-4"> Duty </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.duty.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.duty">
                    >
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="form-group" formGroupName="isentropicEfficiency">
          <label class="control-label col-sm-4"> Isentropic Efficiency </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select
                  formControlName="unit"
                  [quantity]="unitOperation.isentropicEfficiency.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-6">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.isentropicEfficiency">
                </sob-reactive-variable-value-input>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br /><br /><br /><br />
      <div class="form-horizontal">
        <h4 class="subtitle">Results</h4>
        <hr />
        <br />

        <div class="form-group" formGroupName="dutyResult">
          <label class="control-label col-sm-4"> Duty </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.dutyResult.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-6">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.dutyResult"
                  [disabled]="true">
                </sob-reactive-variable-value-input>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" formGroupName="pressureRatio">
          <label class="control-label col-sm-4"> Pressure Ratio </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.pressureRatio.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-6">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.pressureRatio"
                  [disabled]="true">
                </sob-reactive-variable-value-input>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" formGroupName="polytropicEfficiency">
          <label class="control-label col-sm-4"> Polytropic Efficiency </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select
                  formControlName="unit"
                  [quantity]="unitOperation.polytropicEfficiency.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-6">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.polytropicEfficiency"
                  [disabled]="true">
                </sob-reactive-variable-value-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="constraints" (keyup)="grayOutFields()">
    <p style="text-align: right; position: relative">
      <span style="position: absolute; right: -25px; top: -20px; font-weight: bold">Active</span>
    </p>

    <div class="form-horizontal">
      <div class="form-group" formGroupName="minimumMassFlowrate">
        <label class="control-label col-sm-4"> Minimum Mass Flowrate </label>

        <div class="col-sm-8" sobReactiveSimVarInput>
          <div class="row">
            <div class="col-xs-5">
              <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.minimumMassFlowrate.quantity">
              </sob-reactive-unit-select>
            </div>
            <div class="col-xs-5">
              <sob-reactive-variable-value-input
                formControlName="value"
                [valueMetaData]="unitOperation.minimumMassFlowrate">
              </sob-reactive-variable-value-input>
            </div>
            <div class="col-xs-2">
              <sob-reactive-constraint-check-box
                formControlName="isActive"
                [isConstraint]="true"
                [disabledConstraint]="flowrateStatus"
                [valueMetaData]="unitOperation.minimumMassFlowrate">
              </sob-reactive-constraint-check-box>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group" formGroupName="massCapacity">
        <label class="control-label col-sm-4"> Maximum Feed Capacity </label>

        <div class="col-sm-8" sobReactiveSimVarInput>
          <div class="row">
            <div class="col-xs-5">
              <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.massCapacity.quantity">
              </sob-reactive-unit-select>
            </div>
            <div class="col-xs-5">
              <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.massCapacity">
              </sob-reactive-variable-value-input>
            </div>
            <div class="col-xs-2">
              <sob-reactive-constraint-check-box
                formControlName="isActive"
                [isConstraint]="true"
                [disabledConstraint]="capacityStatus"
                [valueMetaData]="unitOperation.massCapacity">
              </sob-reactive-constraint-check-box>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group duty" formGroupName="minimumDuty">
        <label class="control-label col-sm-4"> Minimum Duty </label>

        <div class="col-sm-8" sobReactiveSimVarInput>
          <div class="row">
            <div class="col-xs-5">
              <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.minimumDuty.quantity">
              </sob-reactive-unit-select>
            </div>
            <div class="col-xs-5">
              <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.minimumDuty">
              </sob-reactive-variable-value-input>
            </div>
            <div class="col-xs-2">
              <sob-reactive-constraint-check-box
                formControlName="isActive"
                [isConstraint]="true"
                [disabledConstraint]="minDutyStatus"
                [valueMetaData]="unitOperation.minimumDuty">
              </sob-reactive-constraint-check-box>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group duty" formGroupName="maximumDuty">
        <label class="control-label col-sm-4"> Maximum Duty </label>

        <div class="col-sm-8" sobReactiveSimVarInput>
          <div class="row">
            <div class="col-xs-5">
              <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.maximumDuty.quantity">
              </sob-reactive-unit-select>
            </div>
            <div class="col-xs-5">
              <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.maximumDuty">
              </sob-reactive-variable-value-input>
            </div>
            <div class="col-xs-2">
              <sob-reactive-constraint-check-box
                formControlName="isActive"
                [isConstraint]="true"
                [disabledConstraint]="maxDutyStatus"
                [valueMetaData]="unitOperation.maximumDuty">
              </sob-reactive-constraint-check-box>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="waterTurbineComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
