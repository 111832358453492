import { Completion } from '@codemirror/autocomplete';
import { DefaultCodeProvider } from './default-code-provider';
import { defaultLgoHydrotreaterModel } from '../../_config/unit-operations/default-models/default-lgo-hydrotreater-model';
import { lgoHydrotreaterCodeCompletions } from '../../_config/unit-operations/custom-code-completions/lgo-hydrotreater-code-completions';

export class LgoHydrotreaterCodeProvider extends DefaultCodeProvider {
  constructor() {
    super(defaultLgoHydrotreaterModel);
  }

  override getCodeCompletions(): Completion[] {
    return super.getCodeCompletions().concat(lgoHydrotreaterCodeCompletions);
  }
}
