import { Injectable } from '@angular/core';
import { AbstractSubFlowsheetPaletteHandler } from './abstract-sub-flowsheet-palette-handler';
import { GoJsPaletteUtils } from './gojs-palette-utils';
import { unitOperationsConfig } from '../../_config/unit-operations.config';

@Injectable()
export class FuelGasPaletteHandler extends AbstractSubFlowsheetPaletteHandler {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(paletteUtils: GoJsPaletteUtils) {
    super(paletteUtils);
  }

  getPaletteModel(): any[] {
    return [
      {
        name: unitOperationsConfig.fuelGasSource.displayName,
        icon: unitOperationsConfig.fuelGasSource.paletteIcon,
        diagramIcon: unitOperationsConfig.fuelGasSource.diagramIcon,
        category: unitOperationsConfig.fuelGasSource.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.fuelGasFlare.displayName,
        icon: unitOperationsConfig.fuelGasFlare.paletteIcon,
        diagramIcon: unitOperationsConfig.fuelGasFlare.diagramIcon,
        category: unitOperationsConfig.fuelGasFlare.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.fuelGasSink.displayName,
        icon: unitOperationsConfig.genericSink.paletteIcon,
        diagramIcon: unitOperationsConfig.genericSink.diagramIcon,
        category: unitOperationsConfig.fuelGasSink.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.fuelGasSinkImport.displayName,
        icon: unitOperationsConfig.fuelGasSinkImport.paletteIcon,
        diagramIcon: unitOperationsConfig.fuelGasSinkImport.diagramIcon,
        category: unitOperationsConfig.fuelGasSinkImport.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.fuelGasSourceImport.displayName,
        icon: unitOperationsConfig.fuelGasSourceImport.paletteIcon,
        diagramIcon: unitOperationsConfig.fuelGasSourceImport.diagramIcon,
        category: unitOperationsConfig.fuelGasSourceImport.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.fuelGasMixer.displayName,
        icon: unitOperationsConfig.mixer.paletteIcon,
        diagramIcon: unitOperationsConfig.mixer.diagramIcon,
        category: unitOperationsConfig.fuelGasMixer.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.fuelGasSplitter.displayName,
        icon: unitOperationsConfig.fuelGasSplitter.paletteIcon,
        diagramIcon: unitOperationsConfig.fuelGasSplitter.diagramIcon,
        category: unitOperationsConfig.fuelGasSplitter.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.fuelGasAmineContactor.displayName,
        icon: unitOperationsConfig.fuelGasAmineContactor.paletteIcon,
        diagramIcon: unitOperationsConfig.fuelGasAmineContactor.diagramIcon,
        category: unitOperationsConfig.fuelGasAmineContactor.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
    ];
  }
}
