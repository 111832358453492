import { H2sSpreadsheetModel } from '../../../_models/utilities-spreadsheet/h2s-spreadsheet.model';
import { NumberToUnitConverter } from '../../number-to-unit-converter.service';
import { GasContributorUnitOperation } from '../../../_models/_unit-operations/gas-contributor-unit-operation';
import { VAC } from '../../../_models/_unit-operations/vac';
import { Hydrotreater } from '../../../_models/_unit-operations/hydrotreater';
import { Hydrocracker } from '../../../_models/_unit-operations/hydrocracker';
import { DCU } from '../../../_models/_unit-operations/dcu';
import { HgoHydrotreater } from '../../../_models/_unit-operations/hgo-hydrotreater';
import { FluidCoker } from '../../../_models/_unit-operations/fluid-coker';
import { SulfurPlant } from '../../../_models/_unit-operations/sulfur-plant';
import { LgoHydrotreater } from '../../../_models/_unit-operations/lgo-hydrotreater';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';

declare let unitConverter: any;
export type H2sFirstSectionUnitOperation =
  | VAC
  | Hydrotreater
  | Hydrocracker
  | DCU
  | HgoHydrotreater
  | FluidCoker
  | LgoHydrotreater;

export class H2sSheetUtils {
  protected readonly DEFAULT_NUMBER_OF_ROWS = 40;

  constructor(private items: H2sSpreadsheetModel[], private nuc: NumberToUnitConverter) {}

  addUnitOperationToSpreadsheetItem(uo: GasContributorUnitOperation, item: H2sSpreadsheetModel): void {
    const h2sUo = uo as H2sFirstSectionUnitOperation;
    item.id = uo.id;
    item.h2sProductionRate = h2sUo.h2sProductionRate.convertToDefaultUnit();
    item.unitOperationName = uo.name;
    item.unitCategory = uo.category;
    const unit = uo.ownerCase.getUnitOperation(uo.id) as LgoHydrotreater;

    if (unit.category === unitOperationsConfig.lgoHydrotreater.key) {
      item.useCustomModel = unit.useCustomModel;
    }

    if (uo.ownerCase.isSolved) {
      item.h2sProduction = h2sUo.h2sProduction.convertToDefaultUnit();
    }
  }

  addSulfurPlantToSpreadsheetItem(uo: SulfurPlant, item: H2sSpreadsheetModel): void {
    item.sulfurPlantId = uo.id;
    item.sulfurPlantName = uo.name;

    item.sulfurCapacity = this.nuc.convert(
      uo.sulfurCapacity.value,
      uo.sulfurCapacity.quantity,
      unitConverter.units.Moleflowrate.MMSCFD
    );

    if (uo.ownerCase.isSolved) {
      item.sulfurFlowrate = this.nuc.convert(
        uo.sulfurFlowrate.value,
        uo.sulfurFlowrate.quantity,
        unitConverter.units.Moleflowrate.MMSCFD
      );
    }
  }

  findItemByUnitOperationIdOrSulfurPlantId(id: string) {
    return this.items.find(i => i.id === id || i.sulfurPlantId === id);
  }

  isItemEmpty(item: H2sSpreadsheetModel): boolean {
    return !item.id && !item.sulfurPlantId;
  }

  copyUnitOperationItemData(source: H2sSpreadsheetModel, destination: H2sSpreadsheetModel): void {
    destination.id = source.id;
    destination.unitOperationName = source.unitOperationName;
    destination.h2sProductionRate = source.h2sProductionRate;
    destination.h2sProduction = source.h2sProduction;
    destination.useCustomModel = source.useCustomModel;
    destination.unitCategory = source.unitCategory;
  }

  copySulfurPlantItemData(source: H2sSpreadsheetModel, destination: H2sSpreadsheetModel): void {
    destination.sulfurPlantId = source.sulfurPlantId;
    destination.sulfurPlantName = source.sulfurPlantName;
    destination.sulfurCapacity = source.sulfurCapacity;
    destination.sulfurFlowrate = source.sulfurFlowrate;
  }

  clearSulfurPlantData(item: H2sSpreadsheetModel): void {
    item.sulfurPlantId = '';
    item.sulfurPlantName = '';
    item.sulfurCapacity = undefined;
    item.sulfurFlowrate = undefined;
  }

  clearUnitOperationData(item: H2sSpreadsheetModel): void {
    item.id = '';
    item.unitOperationName = '';
    item.h2sProductionRate = undefined;
    item.h2sProduction = undefined;
    item.useCustomModel = undefined;
    item.unitCategory = undefined;
  }

  removeItem(item: H2sSpreadsheetModel): void {
    const index = this.items.indexOf(item);
    this.items.splice(index, 1);

    // keep the minimum amount of rows
    if (this.items.length < this.DEFAULT_NUMBER_OF_ROWS) {
      this.items.push({});
    }
  }

  clearSpreadsheetItems(): void {
    // delete the items above index covered by the default number of rows
    this.items.splice(this.DEFAULT_NUMBER_OF_ROWS, this.items.length - this.DEFAULT_NUMBER_OF_ROWS);
    for (let i = 0; i < this.DEFAULT_NUMBER_OF_ROWS; i++) {
      this.items[i] = {};
    }
  }
}
