import { SuncorUnitOperation } from './suncor-unit-operation';
import { SimulationVariable } from '../simulation-variable';

export abstract class SuncorUnitOpWithCogen extends SuncorUnitOperation {
  // cogenRevenue: SimulationVariable;
  cogenElectricityGeneration: SimulationVariable;
  netElectricityExport: SimulationVariable;
  gridIntensity: SimulationVariable;
  cogenIntensity: SimulationVariable;

  override initValues(unitOperation: any): void {
    super.initValues(unitOperation);
    // this.cogenRevenue = new SimulationVariable(unitOperation.cogenRevenue, this.ownerCase, this);
    this.cogenElectricityGeneration = new SimulationVariable(
      unitOperation.cogenElectricityGeneration,
      this.ownerCase,
      this
    );
    this.netElectricityExport = new SimulationVariable(unitOperation.netElectricityExport, this.ownerCase, this);
    this.gridIntensity = new SimulationVariable(unitOperation.gridIntensity, this.ownerCase, this);
    this.cogenIntensity = new SimulationVariable(unitOperation.cogenIntensity, this.ownerCase, this);
  }

  override setDefaultValues(): void {
    super.setDefaultValues();
    // if (this.cogenRevenue.isUndefined()) {
    //   this.cogenRevenue.setDefaultValue(0.0);
    // }
    if (this.cogenElectricityGeneration.isUndefined()) {
      this.cogenElectricityGeneration.setDefaultValue(0.0);
    }

    if (this.netElectricityExport.isUndefined()) {
      this.netElectricityExport.setDefaultValue(0.0);
    }

    if (this.gridIntensity.isUndefined()) {
      this.gridIntensity.setDefaultValue(0.0);
    }

    if (this.cogenIntensity.isUndefined()) {
      this.cogenIntensity.setDefaultValue(0.0);
    }
  }

  override setQuantities() {
    super.setQuantities();
    // this.cogenRevenue.setQuantity('CurrencyPerTime');
    this.cogenElectricityGeneration.setQuantity('Power');
    this.netElectricityExport.setQuantity('Power');
    this.gridIntensity.setQuantity('MassPerEnergy');
    this.cogenIntensity.setQuantity('MassPerEnergy');
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    // this.cogenRevenue.setName('Cogen Revenue');
    this.cogenElectricityGeneration.setName('Cogen Electricity Generation');
    this.netElectricityExport.setName('Net Electricity Export');
    this.gridIntensity.setName('Grid Intensity');
    this.cogenIntensity.setName('Cogen Intensity');
  }

  override dePersist(serializedUnitOperation: any) {
    super.dePersist(serializedUnitOperation);
    // this.cogenRevenue = this.getSimulationVariableSafe(serializedUnitOperation.cogenRevenue);
    this.cogenElectricityGeneration = this.getSimulationVariableSafe(
      serializedUnitOperation.cogenElectricityGeneration
    );
    this.netElectricityExport = this.getSimulationVariableSafe(serializedUnitOperation.netElectricityExport);
    this.gridIntensity = this.getSimulationVariableSafe(serializedUnitOperation.gridIntensity);
    this.cogenIntensity = this.getSimulationVariableSafe(serializedUnitOperation.cogenIntensity);
  }

  abstract override getAlternativeGhgIntensity(): SimulationVariable;
}
