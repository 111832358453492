import { ExcelReportUtils } from './excel-report.utils';
import { Case } from '../../_models';

export class KpiManagerExcelReport {
  static generateDataArray(c: Case) {
    const { kpiManager } = c.caseStudyManager;

    const dataArray = [['Variable name', 'Unit', 'Current value']];
    // const dataContainer = [];

    for (const kpi of kpiManager.selectedKpis) {
      dataArray.push([
        kpi.getFullName(),
        kpi.unit,
        ExcelReportUtils.roundFormat(kpi.convertToDefaultUnit(true, false), -2),
      ]);
    }

    return dataArray;
  }
}
