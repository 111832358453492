import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { SimulationVariable } from '../simulation-variable';
import { Case } from '../case';
import { isTypeUndefined } from '../../_utils/utils';
import { Quantity } from '../../_config/quantity.enum';
import { ConstrainableObject } from '../_interfaces/constrainable-object';
import { UnitOperationConstraints } from './unit-operation-constraints';
import { HgoHydroteaterCatalystRun } from '../../_config/unit-operations/hgo-hydroteater-catalyst-run.enum';
import { SimulationVariableName } from '../../_config/simulation-variable-name.enum';
import { defaultHgoHydrotreaterModel } from '../../_config/unit-operations/default-models/default-hgo-hydrotreater-model';
import { GasContributorUnitOperation } from './gas-contributor-unit-operation';
import { KpiProvider } from '../_case-study/kpi-provider';

declare let unitConverter: any;

export class HgoHydrotreater extends GasContributorUnitOperation implements ConstrainableObject, KpiProvider {
  category = unitOperationsConfig.hgoHydrotreater.key;

  fluidAnalysisId: string;
  catalystRun: string;
  naphthaYield: SimulationVariable;
  lgoYield: SimulationVariable;
  hgoYield: SimulationVariable;
  totalYield: SimulationVariable;
  totalInletVolume: SimulationVariable;
  hydrogenUse: SimulationVariable;
  hydrogenUseRate: SimulationVariable;
  h2sProductionRate: SimulationVariable;
  h2sProduction: SimulationVariable;
  maximumSulfurContent: SimulationVariable;
  maximumNitrogen: SimulationVariable;

  constraints: UnitOperationConstraints;

  useCustomModel: boolean;
  customModelScript: string;

  constructor(unitOperation: HgoHydrotreater | any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [
        this.naphthaYield,
        this.lgoYield,
        this.hgoYield,
        this.totalYield,
        this.totalInletVolume,
        this.ghgIntensity,
        this.ghgEmissions,
        this.hydrogenUse,
        this.hydrogenUseRate,
        this.h2sProductionRate,
        this.h2sProduction,
      ];
    }
    return [
      this.naphthaYield,
      this.lgoYield,
      this.hgoYield,
      this.totalYield,
      this.totalInletVolume,
      this.ghgIntensity,
      this.ghgEmissions,
      this.hydrogenUse,
      this.hydrogenUseRate,
      this.h2sProductionRate,
      this.h2sProduction,
      this.steamMake600,
      this.steamUse600,
      this.steamUse900,
      this.steamMake900,
      this.steamMake150,
      this.steamUse150,
      this.steamMake50,
      this.steamUse50,
      this.fuelGasHeatingValue,
      this.fuelGasEmissionFactor,
      this.energyFlow,
      this.flowrate,
    ];
  }

  override initValues(unitOperation: HgoHydrotreater | any) {
    super.initValues(unitOperation);

    this.fluidAnalysisId = unitOperation.fluidAnalysisId || '';
    this.catalystRun = unitOperation.catalystRun || HgoHydroteaterCatalystRun.EOR;
    this.naphthaYield = new SimulationVariable(unitOperation.naphthaYield, this.ownerCase, this);
    this.hgoYield = new SimulationVariable(unitOperation.hgoYield, this.ownerCase, this);
    this.lgoYield = new SimulationVariable(unitOperation.lgoYield, this.ownerCase, this);
    this.totalYield = new SimulationVariable(unitOperation.totalYield, this.ownerCase, this);
    this.totalInletVolume = new SimulationVariable(unitOperation.totalInletVolume, this.ownerCase, this);
    this.hydrogenUse = new SimulationVariable(unitOperation.hydrogenUse || {}, this.ownerCase, this);
    this.hydrogenUseRate = new SimulationVariable(unitOperation.hydrogenUseRate || {}, this.ownerCase, this);
    this.h2sProductionRate = new SimulationVariable(unitOperation.h2sProductionRate || {}, this.ownerCase, this);
    this.h2sProduction = new SimulationVariable(unitOperation.h2sProduction || {}, this.ownerCase, this);
    this.maximumSulfurContent = new SimulationVariable(unitOperation.maximumSulfurContent, this.ownerCase, this);
    this.maximumNitrogen = new SimulationVariable(unitOperation.maximumNitrogen, this.ownerCase, this);

    this.useCustomModel = isTypeUndefined(unitOperation.useCustomModel) ? false : unitOperation.useCustomModel;
    this.customModelScript = isTypeUndefined(unitOperation.customModelScript)
      ? defaultHgoHydrotreaterModel
      : unitOperation.customModelScript;
    this.initConstraints();
  }

  override setQuantities() {
    super.setQuantities();
    this.naphthaYield.setQuantity(Quantity.PERCENT);
    this.lgoYield.setQuantity(Quantity.PERCENT);
    this.hgoYield.setQuantity(Quantity.PERCENT);
    this.totalYield.setQuantity(Quantity.PERCENT);
    this.totalInletVolume.setQuantity(Quantity.VOLUMETRIC_FLOWRATE);
    this.hydrogenUse.setQuantity(Quantity.MOLEFLOWRATE);
    this.hydrogenUseRate.setQuantity(Quantity.GAS_STD_VOLUME_PER_VOLUME);
    this.h2sProduction.setQuantity(Quantity.MOLEFLOWRATE);
    this.h2sProductionRate.setQuantity(Quantity.GAS_STD_VOLUME_PER_VOLUME);
    this.maximumSulfurContent.setQuantity(Quantity.MASS_FRACTION);
    this.maximumNitrogen.setQuantity(Quantity.MASS_FRACTION);
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.maximumSulfurContent.setName(SimulationVariableName.MAXIMUM_SULFUR_CONTENT);
    this.maximumNitrogen.setName(SimulationVariableName.MAXIMUM_NITROGEN);

    this.naphthaYield.setName(SimulationVariableName.NAPHTHA_YIELD);
    this.lgoYield.setName(SimulationVariableName.LGO_YIELD);
    this.hgoYield.setName(SimulationVariableName.HGO_YIELD);
    this.totalYield.setName(SimulationVariableName.TOTAL_YIELD);
    this.totalInletVolume.setName(SimulationVariableName.TOTAL_INLET_VOLUME);
    this.hydrogenUse.setName(SimulationVariableName.HYDROGEN_USE);
    this.hydrogenUseRate.setName(SimulationVariableName.HYDROGEN_USE_RATE);
    this.h2sProduction.setName(SimulationVariableName.H2S_PRODUCTION);
    this.h2sProductionRate.setName(SimulationVariableName.H2S_PRODUCTION_RATE);
    this.fuelGasHeatingValue.setName(SimulationVariableName.FUEL_GAS_HEATING_VALUE);
    this.fuelGasEmissionFactor.setName(SimulationVariableName.FUEL_GAS_EMISSION_FACTOR);
    this.energyFlow.setName(SimulationVariableName.ENERGY_FLOW);
    this.flowrate.setName(SimulationVariableName.FUEL_GAS_FLOWRATE);
  }

  override setDefaultValues() {
    super.setDefaultValues();

    if (this.energyFlow.isUndefined()) {
      this.energyFlow.setDefaultValue(0.0, unitConverter.units.Energyrate.GJ_H);
    }
  }

  getAlternativeGhgIntensity(): SimulationVariable {
    return undefined;
  }

  constraintValueDefined(): boolean {
    return this.constraints.anyConstraintValueDefined();
  }

  constraintViolated(): boolean {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    })[0];
    return (
      inletStream &&
      (this.constraints.constraintValueViolated('capacity', inletStream.volumetricFlowrate.value) ||
        this.constraints.constraintValueViolated('maximumSulfurContent', inletStream.sulfur.value) ||
        this.constraints.constraintValueViolated('maximumNitrogen', inletStream.nitrogen.value))
    );
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('capacity', this.capacity);
    this.constraints.addConstraint('minimumFlow', this.minimumFlow);
    this.constraints.addConstraint('maximumSulfurContent', this.maximumSulfurContent);
    this.constraints.addConstraint('maximumNitrogen', this.maximumNitrogen);
  }

  override clearResults() {
    super.clearResults();
    this.totalInletVolume.value = undefined;
    this.naphthaYield.value = undefined;
    this.lgoYield.value = undefined;
    this.hgoYield.value = undefined;
    this.totalYield.value = undefined;
    this.h2sProduction.value = undefined;
    this.h2sProductionRate.value = undefined;
    this.hydrogenUse.value = undefined;
    this.hydrogenUseRate.value = undefined;
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.naphthaYield = this.getSimulationVariableSafe(sup.naphthaYield);
    this.lgoYield = this.getSimulationVariableSafe(sup.lgoYield);
    this.hgoYield = this.getSimulationVariableSafe(sup.hgoYield);
    this.totalYield = this.getSimulationVariableSafe(sup.totalYield);
    this.totalInletVolume = this.getSimulationVariableSafe(sup.totalInletVolume);
    this.hydrogenUse = this.getSimulationVariableSafe(sup.hydrogenUse);
    this.hydrogenUseRate = this.getSimulationVariableSafe(sup.hydrogenUseRate);
    this.h2sProduction = this.getSimulationVariableSafe(sup.h2sProduction);
    this.h2sProductionRate = this.getSimulationVariableSafe(sup.h2sProductionRate);
    this.maximumSulfurContent = this.getSimulationVariableSafe(sup.maximumSulfurContent);
    this.maximumNitrogen = this.getSimulationVariableSafe(sup.maximumNitrogen);

    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }

  override detectChanges(another: any): boolean {
    const changes = super.detectChanges(another);
    return changes || another.customModelScript !== this.customModelScript;
  }
}
