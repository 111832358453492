import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { hasNumericValue } from '../_utils/utils';

export class CustomValidators {
  /**
   * Positive validator, accepts values with commas
   * @param control the FormControl to be validated
   */
  static positive(control: AbstractControl): ValidationErrors | null {
    const numericValue =
      typeof control.value === 'string' ? parseFloat(control.value.replace(/,/g, '')) : control.value;

    if (!Number.isNaN(numericValue) && numericValue < 0) {
      return {
        positive: true,
      };
    }

    return null;
  }

  /**
   * Validator for checking that the value of a text input is a valid numeric string
   * @param performRequiredValidation If true, empty string will be considered invalid
   */
  static validNumber(performRequiredValidation: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!performRequiredValidation && !hasNumericValue(control.value)) {
        return null;
      }

      const regex = /^[-+]?(?:[0-9]+,)*[0-9]+(?:\.[0-9]+)?$/;

      if (!regex.test(control.value)) {
        return {
          validNumber: true, // indicates that validNumber validation failed
        };
      }

      return null;
    };
  }
}
