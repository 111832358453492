<div [formGroup]="propertyWindowFormGroup">
  <ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
    <li role="presentation" class="active">
      <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
    </li>

    <li role="presentation">
      <a href="#waterSourceComments" aria-controls="waterSourceComments" role="tab" data-toggle="tab">
        <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
        Comments
      </a>
    </li>
  </ul>

  <div class="tab-content pw-margin tab-content-padding">
    <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <h4 class="subtitle">Conditions</h4>
          <hr />
          <br />

          <div class="form-group">
            <label for="isOptimizable" class="control-label col-sm-4"> Optimize </label>

            <div class="col-sm-4">
              <label for="isOptimizable" class="control-label">
                <input
                  type="checkbox"
                  id="isOptimizable"
                  formControlName="isOptimizable"
                  (change)="isOptimizableChanged($event.target.checked)" />
              </label>
            </div>
          </div>

          <div class="form-group" formGroupName="massFlowrate">
            <label class="control-label col-sm-4"> Mass Flowrate </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.massFlowrate.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.massFlowrate">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-horizontal" (keyup)="grayOutFields()">
            <div class="form-group" formGroupName="temperature">
              <label class="control-label col-sm-4"> Temperature </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.temperature.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.temperature">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" formGroupName="pressure">
              <label class="control-label col-sm-4"> Pressure </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.pressure.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.pressure">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" formGroupName="vaporFraction">
              <label class="control-label col-sm-4"> Vapor Fraction </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.vaporFraction.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.vaporFraction">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="waterSourceComments">
      <div class="tab-content no-padding no-border">
        <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
      </div>
    </div>
  </div>
</div>
