import { Component, Input } from '@angular/core';
import { WaterUtilityUnitOperation } from '../../../_models/_unit-operations/utilties/water-utility-unit-operation';
import { FlowsheetService } from '../../../_services/flowsheet.service';

@Component({
  selector: 'sob-water-utility-unit-operation',
  templateUrl: './water-utility-unit-operation.component.html',
  styleUrls: ['./water-utility-unit-operation.component.css'],
})
export class WaterUtilityUnitOperationComponent {
  @Input() unitOperation: WaterUtilityUnitOperation;

  constructor(private flowsheetService: FlowsheetService) {}

  openSubFlowsheet(): void {
    if ($('#closePropertyWindow').attr('disabled')) {
      return;
    }
    this.flowsheetService.prepareUnitOperationForSave();
    this.flowsheetService.closePropertyWindow();

    this.flowsheetService.loadUnitOperationFlowsheet(this.unitOperation.id);
  }
}
