import { AfterViewInit, Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ParametricStudy } from '../../../_models/_case-study/parametric-study';
import { KpiManager } from '../../../_models/_case-study/kpi-manager';
import { ParametricStudyKpiResult } from '../../../_models/_case-study/parametric-study-kpi-result';
import { SimulationVariableArray } from '../../../_models/simulation-variable-array';
import { hasNumericValue } from '../../../_utils/utils';

@Component({
  selector: 'sob-parametric-study-results',
  templateUrl: './parametric-study-results.component.html',
  styleUrls: ['./parametric-study-results.component.css'],
})
export class ParametricStudyResultsComponent implements AfterViewInit {
  @Input()
  parametricStudy: ParametricStudy;

  @Input()
  kpiManager: KpiManager;

  selectedKpi: String;
  unitCase: string;
  ngAfterViewInit() {
    if (this.selectedKpisWithResults()) {
      this.unitCase = this.parametricStudy.parameter.variableValues.unit;
      this.changeResults(this.selectedKpisWithResults()[0].id);
    }
  }

  changeResults(kpiId: string) {
    this.selectedKpi = kpiId;
    const results = this.findKpiResultsByKpiId(kpiId);
    this.buildChart(results);
    this.buildTable(results);
  }

  private buildChart(results: ParametricStudyKpiResult) {
    const resultsSva = new SimulationVariableArray(
      { values: results.variableValues, quantity: results.kpi.quantity },
      null,
      null
    );
    const chartData = resultsSva.convertToAnotherUnitArray(true, true, results.kpi.getDefaultUnit());
    const p = this.parametricStudy.parameter;
    const xLabels = p.variableValues.convertToCaptureUnit(this.unitCase, p.variableValues.getInternalUnit());

    Highcharts.chart({
      chart: {
        renderTo: 'parametricStudyChart',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        title: {
          text: `${p.simulationVariable.getFullName()} (${this.unitCase})`,
        },
        categories: xLabels.sort(function (a, b) {
          return a - b;
        }),
      },
      yAxis: {
        title: {
          text: `${results.kpi.getFullName()} (${results.kpi.getDefaultUnit()})`,
        },
      },
      tooltip: {
        headerFormat: '<table>',
        pointFormatter() {
          return (
            `<tr><td style="color:${this.color};padding:0">${this.series.name}: </td>` +
            `<td style="padding-left:2px"><b>${this.y} ${results.kpi.getDefaultUnit()}</b></td></tr>`
          );
        },
        footerFormat: '<tr></table>',
        shared: true,
        useHTML: true,
        followPointer: true,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
            color: 'white',
          },
        },
      },
      series: [
        {
          name: results.kpi.getFullName(),
          data: chartData.sort(function (a, b) {
            return a - b;
          }),
        },
      ],
    });
  }

  private buildTable(results: ParametricStudyKpiResult) {
    // trying different approach to build the table... avoiding the declaration of a global
    // variable for use in the html template
    const resultsSva = new SimulationVariableArray(
      { values: results.variableValues, quantity: results.kpi.quantity },
      null,
      null
    );
    const kpiData = resultsSva.convertToAnotherUnitArray(false, true, results.kpi.getDefaultUnit());

    const p = this.parametricStudy.parameter;

    const parameterData = p.variableValues.convertToCaptureUnit(
      p.variableValues.unit,
      p.variableValues.getInternalUnit()
    );

    let tableHtml = '';

    for (let i = 0; i < kpiData.length; i++) {
      tableHtml +=
        `<tr> <td>${kpiData[i]} ${results.kpi.getDefaultUnit()}</td>` +
        `<td>${parameterData[i]} ${p.simulationVariable.getDefaultUnit()}</td></tr>`;
    }

    const $table = $('#parametricStudyResultsTable');
    $table
      .find('thead')
      .html(`<th>${results.kpi.getFullName()}</th>` + `<th>${p.simulationVariable.getFullName()}</th>`);

    $table.find('tbody').html(tableHtml);
  }

  selectedKpisWithResults() {
    return this.kpiManager.selectedKpis.filter(
      kpi => this.findKpiResultsByKpiId(kpi.id) && kpi.value !== 0 && hasNumericValue(kpi.value)
    );
  }

  findKpiResultsByKpiId(kpiId: string) {
    return this.parametricStudy.kpiResults.find(result => result.kpi.id === kpiId);
  }

  excelReport() {
    $('#caseStudyModal').modal('hide');
    $('#caseStudyExcelReportModal').modal('show');
  }
}
