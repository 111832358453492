import { WaterUnitOperation } from './water-unit-operation';
import { Case } from '../../case';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { SimulationVariable } from '../../simulation-variable';
import { Quantity } from '../../../_config/quantity.enum';
import { BaseObject } from '../../base-object';
import { VariableStatus } from '../../../_config/variable-status.enum';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';
import { ParameterProvider } from '../../_case-study/parameter-provider';

declare let unitConverter: any;

export class WaterSource extends WaterUnitOperation implements KpiProvider, ParameterProvider {
  category = unitOperationsConfig.waterSource.key;
  massFlowrate: SimulationVariable;
  temperature: SimulationVariable;
  pressure: SimulationVariable;
  vaporFraction: SimulationVariable;
  isOptimizable: boolean;

  constructor(unitOperation: any | WaterSource, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.massFlowrate = new SimulationVariable(unitOperation.massFlowrate, this.ownerCase, this);
    this.temperature = new SimulationVariable(unitOperation.temperature, this.ownerCase, this);
    this.pressure = new SimulationVariable(unitOperation.pressure, this.ownerCase, this);
    this.vaporFraction = new SimulationVariable(unitOperation.vaporFraction, this.ownerCase, this);

    if (typeof unitOperation.isOptimizable === 'undefined') {
      this.isOptimizable = false;
    } else {
      this.isOptimizable = !!unitOperation.isOptimizable;
    }
  }

  override clearResults() {
    super.clearResults();

    if (this.isOptimizable) {
      this.massFlowrate.value = undefined;
    }

    if (this.temperature.variableStatus === VariableStatus.SOLVER_CALCULATED) {
      this.temperature.value = undefined;
    } else if (this.pressure.variableStatus === VariableStatus.SOLVER_CALCULATED) {
      this.pressure.value = undefined;
    } else if (this.vaporFraction.variableStatus === VariableStatus.SOLVER_CALCULATED) {
      this.vaporFraction.value = undefined;
    }
  }

  override overwriteValues(another: BaseObject) {
    super.overwriteValues(another);
    const ws = another as WaterSource;
    this.isOptimizable = ws.isOptimizable;
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.massFlowrate.setName(SimulationVariableName.MASS_FLOWRATE);
    this.temperature.setName(SimulationVariableName.TEMPERATURE);
    this.pressure.setName(SimulationVariableName.PRESSURE);
    this.vaporFraction.setName(SimulationVariableName.VAPOR_FRACTION);
  }

  override setQuantities() {
    super.setQuantities();
    this.massFlowrate.setQuantity(Quantity.MASSFLOWRATE);
    this.temperature.setQuantity(Quantity.TEMPERATURE);
    this.pressure.setQuantity(Quantity.PRESSURE);
    this.vaporFraction.setQuantity(Quantity.FRACTION);
  }

  override setDefaultValues() {
    super.setDefaultValues();

    if (this.massFlowrate.isUndefined()) {
      this.massFlowrate.setDefaultValue(null, unitConverter.units.Massflowrate.KPPH);
    }
  }

  getAvailableKpis(): SimulationVariable[] {
    return [this.massFlowrate, this.temperature, this.pressure, this.vaporFraction];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.massFlowrate, this.temperature, this.pressure, this.vaporFraction];
  }

  getAvailableParameters() {
    return undefined;
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.massFlowrate = this.getSimulationVariableSafe(sup.massFlowrate);
    this.temperature = this.getSimulationVariableSafe(sup.temperature);
    this.pressure = this.getSimulationVariableSafe(sup.pressure);
    this.vaporFraction = this.getSimulationVariableSafe(sup.vaporFraction);
    this.isOptimizable = sup.isOptimizable;

    this.setQuantities();
    this.setDefaultValues();
  }
}
