import * as wjcCore from '@grapecity/wijmo';
import { FlexSheetCellConfig, VerticalFlexSheetCellConfig } from './flex-sheet-cell-config';

export enum UtilitiesSheetColumns {
  NAME,
  HYDROGEN_USE_RATE,
  HYDROGEN_USE,
}

export const UtilitiesCellConfig: FlexSheetCellConfig[] = [
  {
    row: UtilitiesSheetColumns.NAME,
    dataType: wjcCore.DataType.String,
    format: '',
  },
  {
    row: UtilitiesSheetColumns.HYDROGEN_USE_RATE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UtilitiesSheetColumns.HYDROGEN_USE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
];
