import { Component, Input, OnInit } from '@angular/core';
import { Hydrocracker } from '../../../_models/_unit-operations/hydrocracker';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { HydrocrackerCodeProvider } from '../../../_services/custom-code-providers/hydrocracker-code-provider';

@Component({
  selector: 'sob-hydrocracker',
  templateUrl: './hydrocracker.component.html',
  styleUrls: ['./hydrocracker.component.css'],
})
export class HydrocrackerComponent implements OnInit {
  @Input()
  unitOperation: Hydrocracker;

  defaultCodeProvider: HydrocrackerCodeProvider;

  constructor(private flowsheetService: FlowsheetService) {}

  ngOnInit() {
    this.defaultCodeProvider = new HydrocrackerCodeProvider();
  }

  saveCustomModel(res: any) {
    if (res.instanceId === 'hcCodeEditor') {
      this.unitOperation.modelScript = res.code;
      this.flowsheetService.saveCustomCodeChanges();
    }
  }
}
