import { SimulationVariable } from '../simulation-variable';
import { IUnitOperation } from '../i-unit-operation';
import { Case } from '../case';
import { UnitOperationConstraints } from './unit-operation-constraints';
import { ConstrainableObject } from '../_interfaces/constrainable-object';
import { BitumenConversionOutlet } from './bitumen-conversion-outlet';
import { Quantity } from '../../_config/quantity.enum';
import { KpiProvider } from '../_case-study/kpi-provider';
import { MultiPeriodParameterProvider } from '../_multi-period/multi-period-parameter-provider';
import { SimulationVariableName } from '../../_config/simulation-variable-name.enum';
import { ParameterProvider } from '../_case-study/parameter-provider';
import { DefaultMappedType } from '../default-mapped-type';
import { defaultBitumenConversionModel } from '../../_config/unit-operations/default-models/default-bitumen-conversion-model';
import { BitumenConversionYieldBasis, BitumenModelTypes } from '../../_config/unit-operations/bitumen-conversion-enums';
import { GasContributorUnitOperation } from './gas-contributor-unit-operation';
import { unitOperationsConfig } from '../../_config/unit-operations.config';

declare let unitConverter: any;

export class BitumenConversion
  extends GasContributorUnitOperation
  implements IUnitOperation, ConstrainableObject, MultiPeriodParameterProvider, KpiProvider, ParameterProvider
{
  category = unitOperationsConfig.bitumenConversion.key;

  yieldBasis: BitumenConversionYieldBasis;
  volumetricYield: SimulationVariable;
  totalYield: SimulationVariable;
  productPrice: SimulationVariable;
  powerUseRate: SimulationVariable;
  naturalGasUseRate: SimulationVariable;
  hydrogenUseRate: SimulationVariable;
  hydrogenImbalance: SimulationVariable;
  absoluteGhgEmissions: SimulationVariable;
  fluidAnalysisId: string;
  hydrogenUse: SimulationVariable;
  powerEmissionFactor: SimulationVariable;
  powerPrice: SimulationVariable;
  hydrogenEmissionFactor: SimulationVariable;
  hydrogenPrice: SimulationVariable;
  naturalGasEmissionFactor: SimulationVariable;
  naturalGasPrice: SimulationVariable;
  outlets: BitumenConversionOutlet[] = [];
  modelType: BitumenModelTypes;
  constraints: UnitOperationConstraints;

  useCustomModel = false;
  customModelScript = '';

  /**
   *
   */
  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: DefaultMappedType<BitumenConversion>): void {
    super.initValues(unitOperation);
    this.yieldBasis = unitOperation.yieldBasis || BitumenConversionYieldBasis.VOLUME;
    this.totalYield = new SimulationVariable(unitOperation.totalYield, this.ownerCase, this);
    this.productPrice = new SimulationVariable(unitOperation.productPrice, this.ownerCase, this);
    this.powerUseRate = new SimulationVariable(unitOperation.powerUseRate, this.ownerCase, this);
    this.naturalGasUseRate = new SimulationVariable(unitOperation.naturalGasUseRate, this.ownerCase, this);
    this.hydrogenUseRate = new SimulationVariable(unitOperation.hydrogenUseRate, this.ownerCase, this);
    this.hydrogenImbalance = new SimulationVariable(unitOperation.hydrogenImbalance, this.ownerCase, this);
    this.absoluteGhgEmissions = new SimulationVariable(unitOperation.absoluteGhgEmissions, this.ownerCase, this);
    this.fluidAnalysisId = unitOperation.fluidAnalysisId || '';
    this.volumetricYield = new SimulationVariable(unitOperation.volumetricYield, this.ownerCase, this);

    this.powerEmissionFactor = new SimulationVariable(unitOperation.powerEmissionFactor || {}, this.ownerCase, this);
    this.powerPrice = new SimulationVariable(unitOperation.powerPrice || {}, this.ownerCase, this);
    this.hydrogenEmissionFactor = new SimulationVariable(
      unitOperation.hydrogenEmissionFactor || {},
      this.ownerCase,
      this
    );
    this.hydrogenPrice = new SimulationVariable(unitOperation.hydrogenPrice || {}, this.ownerCase, this);
    this.naturalGasEmissionFactor = new SimulationVariable(
      unitOperation.naturalGasEmissionFactor || {},
      this.ownerCase,
      this
    );
    this.naturalGasPrice = new SimulationVariable(unitOperation.naturalGasPrice || {}, this.ownerCase, this);
    this.hydrogenUse = new SimulationVariable(unitOperation.hydrogenUse || {}, this.ownerCase, this);

    this.modelType = unitOperation.modelType || BitumenModelTypes.UTILITY;

    if (unitOperation.outlets instanceof Array) {
      for (let i = 0; i < unitOperation.outlets.length; i++) {
        const o = unitOperation.outlets[i];
        this.outlets.push(new BitumenConversionOutlet(o, this.ownerCase, this));
      }
    }

    this.customModelScript = unitOperation.customModelScript ?? defaultBitumenConversionModel;
    this.useCustomModel = !!unitOperation.useCustomModel;

    this.initConstraints();
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('capacity', this.capacity);
  }

  override addSimVarsToPool() {
    super.addSimVarsToPool();
    this.outlets.forEach((outlet: BitumenConversionOutlet) => {
      outlet.addSimVarsToPool();
    });
  }

  getAvailableKpis(): SimulationVariable[] {
    return [
      this.volumetricYield,
      this.productPrice,
      this.hydrogenUse,
      this.ghgIntensity,
      this.ghgEmissions,
      this.opexTotal,
      this.capexTotal,
      this.powerUseRate,
      this.powerPrice,
      this.powerEmissionFactor,
      this.hydrogenEmissionFactor,
      this.naturalGasUseRate,
      this.naturalGasPrice,
      this.naturalGasEmissionFactor,
    ];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [
      this.hydrogenUseRate,
      this.ghgIntensity,
      this.opexFixed,
      this.opexVar,
      this.capexAmortized,
      this.powerUseRate,
      this.powerPrice,
      this.powerEmissionFactor,
      this.hydrogenPrice,
      this.hydrogenEmissionFactor,
      this.naturalGasUseRate,
      this.naturalGasPrice,
      this.naturalGasEmissionFactor,
    ];
  }

  override clearResults() {}

  protected override ignoreForOverWrite(propertyName: string): boolean {
    return super.ignoreForOverWrite(propertyName) || propertyName === 'outlets';
  }

  override overwriteValues(another: any) {
    super.overwriteValues(another);
    this.outlets.forEach((outlet: BitumenConversionOutlet, index) => {
      outlet.yield.overwriteValues(another.outlets[index].yield);
      outlet.price.overwriteValues(another.outlets[index].price);
      outlet.fluidAnalysisId = another.outlets[index].fluidAnalysisId;
    });
  }

  override setSimulationVariablesOwner() {
    super.setSimulationVariablesOwner();
  }

  override setSimulationVariableNames(): void {
    super.setSimulationVariableNames();
    this.totalYield.setName('Total Yield');
    this.volumetricYield.setName('Yield');
    this.absoluteGhgEmissions.setName('Absolute GHG Emissions');
    this.productPrice.setName('Product price');
    this.powerUseRate.setName('Power use rate');
    this.hydrogenUseRate.setName('Hydrogen use rate');
    this.naturalGasUseRate.setName('Natural gas use rate');
    this.capacity.setName(SimulationVariableName.CAPACITY);
    this.hydrogenImbalance.setName(SimulationVariableName.HYDROGEN_IMBALANCE);
    this.hydrogenUse.setName('Hydrogen Use');
    this.hydrogenPrice.setName(SimulationVariableName.HYDROGEN_PRICE);
    this.ghgIntensity.setName('GHG intensity');
    this.ghgEmissions.setName('GHG Emissions');
    this.opexTotal.setName('Total OPEX');
    this.capexTotal.setName('Total CAPEX');
    this.powerPrice.setName('Power Price');
    this.powerEmissionFactor.setName('Power Emission Factor');
    this.hydrogenEmissionFactor.setName('Hydrogen Emission Factor');
    this.naturalGasPrice.setName('Natural Gas Price');
    this.naturalGasEmissionFactor.setName('Natural Gas Emission Factor');
  }

  override setDefaultValues(): void {
    if (this.totalYield.isUndefined()) {
      this.totalYield.setDefaultValue(90.0);
    }

    if (this.naturalGasUseRate.isUndefined()) {
      this.naturalGasUseRate.setDefaultValue(undefined, unitConverter.units.EnergyPerVolume.MJ_M3);
    }

    if (this.hydrogenEmissionFactor.isUndefined()) {
      this.hydrogenEmissionFactor.setDefaultValue(8500, unitConverter.units.MassPerStdH2Volume.KGCO2E_tonne);
    }

    if (this.hydrogenPrice.isUndefined()) {
      this.hydrogenPrice.setDefaultValue(1600, unitConverter.units.CurrencyPerStdH2Volume.CAD_tonne);
    }

    if (this.powerEmissionFactor.isUndefined()) {
      this.powerEmissionFactor.setDefaultValue(0.5, unitConverter.units.MassPerEnergy.KG_KWH);
    }

    if (this.powerPrice.isUndefined()) {
      this.powerPrice.setDefaultValue(40, unitConverter.units.CurrencyPerElectricity.CAD_MEGAWH);
    }

    if (this.naturalGasEmissionFactor.isUndefined()) {
      this.naturalGasEmissionFactor.setDefaultValue(55.32);
    }

    if (this.naturalGasPrice.isUndefined()) {
      this.naturalGasPrice.setDefaultValue(4.28);
    }

    if (this.absoluteGhgEmissions.isUndefined()) {
      this.absoluteGhgEmissions.setDefaultValue(0.0, unitConverter.units.Massflowrate.MMTPA);
    }

    super.setDefaultValues();
  }

  override setQuantities() {
    super.setQuantities();
    this.totalYield.setQuantity(Quantity.PERCENT);
    this.naturalGasUseRate.setQuantity(Quantity.ENERGY_PER_VOLUME);
    this.hydrogenUseRate.setQuantity(Quantity.GAS_STD_VOLUME_PER_VOLUME);
    this.powerUseRate.setQuantity(Quantity.POWER_PER_VOLUMETRIC_FLOW);
    this.absoluteGhgEmissions.setQuantity(Quantity.MASSFLOWRATE);
    this.productPrice.setQuantity(Quantity.CURRENCY_PER_VOLUME);
    this.volumetricYield.setQuantity('Percent');
    this.hydrogenImbalance.setQuantity(Quantity.MASSFLOWRATE);
    this.powerEmissionFactor.setQuantity(Quantity.MASS_PER_ENERGY);
    this.powerPrice.setQuantity(Quantity.CURRENCY_PER_ELECTRICITY);
    this.hydrogenEmissionFactor.setQuantity(Quantity.MASS_PER_STD_H2_VOLUME);
    this.hydrogenPrice.setQuantity(Quantity.CURRENCY_PER_STD_H2_VOLUME);
    this.hydrogenUse.setQuantity(Quantity.MOLEFLOWRATE);
    this.naturalGasPrice.setQuantity(Quantity.CURRENCY_PER_ENERGY);
    this.naturalGasEmissionFactor.setQuantity(Quantity.MASS_PER_ENERGY);
  }

  override convertToInternalUnit() {
    super.convertToInternalUnit();
    this.outlets.forEach(outlet => {
      outlet.price.value = outlet.price.convertToInternalUnit();
      outlet.yield.value = outlet.yield.convertToInternalUnit();
    });
  }

  override detectChanges(another: BitumenConversion) {
    return super.detectChanges(another);
  }
  // getSimVars(): Array<SimulationVariable> {
  //   const simvars =  super.getSimVars();
  //   this.outlets.forEach((outlet: BitumenConversionOutlet) => {
  //     simvars.push(outlet.price);
  //     simvars.push(outlet.yield);
  //   });
  //   return simvars;
  // }

  override getChildrenObjectsForRemoval() {
    return super.getChildrenObjectsForRemoval();
  }

  override updateStatus(another: BitumenConversion) {
    this.updateSimulationVariablesStatus(another);
  }

  getAlternativeGhgIntensity() {
    return new SimulationVariable(this.ghgIntensity, this.ownerCase, this);
  }

  getAvailableParameters() {
    const params = [this.totalYield, this.ghgIntensity, this.productPrice, this.opexFixed, this.opexVar];
    if (this.modelType === BitumenModelTypes.UTILITY) {
      params.push(this.powerUseRate, this.hydrogenUseRate, this.naturalGasUseRate);
    }
    return params;
  }

  constraintViolated() {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    })[0];

    return inletStream && this.constraints.constraintValueViolated('capacity', inletStream.volumetricFlowrate.value);
  }

  constraintValueDefined() {
    return this.constraints.anyConstraintValueDefined();
  }

  findOutlet(unitOperationId: string): BitumenConversionOutlet | undefined {
    return this.outlets.find(o => o.unitOperationId === unitOperationId);
  }

  addOutlet(unitOperationId: string) {
    const outlet = new BitumenConversionOutlet({ unitOperationId }, this.ownerCase, this);
    this.outlets.push(outlet);
    this.ownerCase.addToPools(outlet.price);
    this.ownerCase.addToPools(outlet.yield);
  }

  removeOutlet(outletId: string) {
    const index = this.outlets.findIndex(outlet => {
      return outlet.unitOperationId === outletId;
    });

    if (index === -1) {
      return;
    }
    const outlet = this.outlets[index];
    this.ownerCase.removeFromPools(outlet.price);
    this.ownerCase.removeFromPools(outlet.yield);
    this.outlets.splice(index, 1);
  }

  getAvailableMultiPeriodParameters(): SimulationVariable[] {
    return [this.capacity];
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.naturalGasUseRate = this.getSimulationVariableSafe(sup.naturalGasUseRate);
    this.hydrogenUseRate = this.getSimulationVariableSafe(sup.hydrogenUseRate);
    this.hydrogenUse = this.getSimulationVariableSafe(sup.hydrogenUse);
    this.powerUseRate = this.getSimulationVariableSafe(sup.powerUseRate);
    this.absoluteGhgEmissions = this.getSimulationVariableSafe(sup.absoluteGhgEmissions);
    this.volumetricYield = this.getSimulationVariableSafe(sup.volumetricYield);
    this.hydrogenImbalance = this.getSimulationVariableSafe(sup.hydrogenImbalance);
    this.powerPrice = this.getSimulationVariableSafe(sup.powerPrice);
    this.powerEmissionFactor = this.getSimulationVariableSafe(sup.powerEmissionFactor);
    this.naturalGasPrice = this.getSimulationVariableSafe(sup.naturalGasPrice);
    this.naturalGasEmissionFactor = this.getSimulationVariableSafe(sup.naturalGasEmissionFactor);
    this.hydrogenPrice = this.getSimulationVariableSafe(sup.hydrogenPrice);
    this.hydrogenEmissionFactor = this.getSimulationVariableSafe(sup.hydrogenEmissionFactor);
    this.outlets.forEach((outlet: BitumenConversionOutlet, index) => {
      outlet.dePersist(sup.outlets[index]);
    });
    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }
}
