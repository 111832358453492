import { UntypedFormGroup } from '@angular/forms';
import { BaseObjectFormChangeDetector } from './base-object-form-change-detector';

export class BaseObjectFormGroupWrapper {
  private originalFormValue: any;
  constructor(private formGroup: UntypedFormGroup) {}

  // TODO nice hack to allow change detection in name... should not be used REMOVE when not needed
  updateBaseObjectNameOnFormGroup(name: string) {
    this.formGroup.controls['name'].setValue(name);
  }

  getFormGroup() {
    return this.formGroup;
  }

  getRawValue(): any {
    return this.formGroup.getRawValue();
  }

  /**
   * Stores the form's current raw value
   * Call this right after adding all the form controls when a property
   * window is initialized
   */
  storeOriginalValue(): void {
    this.originalFormValue = this.formGroup.getRawValue();
  }

  /**
   * Compares the current form group values against the original form group values
   * Returns true if changes are detected (the name property is ignored)
   */
  detectChangesInValues(unitOperationCategory: any): boolean {
    const originalName = this.originalFormValue.name;
    const currentValue = this.formGroup.getRawValue();

    let changes = false;

    // we won't want to detect changes in the name property
    delete currentValue.name;
    delete this.originalFormValue.name;

    const changeDetector = new BaseObjectFormChangeDetector(this.formGroup);
    changes = changeDetector.detectChangesInValues(this.originalFormValue, currentValue, unitOperationCategory);

    this.originalFormValue.name = originalName;

    return changes;
  }

  /**
   * Returns true if there are changes in the name property
   */
  detectChangesInName() {
    return this.formGroup.value.name !== this.originalFormValue.name;
  }

  /**
   * Remove all form controls and clears all status variables
   * WARNING - Do not call this before mapping
   */
  clear(): void {
    const keys = Object.keys(this.formGroup.controls);
    keys.forEach(k => {
      this.formGroup.removeControl(k);
    });

    this.originalFormValue = undefined;
  }

  /**
   * Returns true if the form group has any controls
   */
  hasControls(): boolean {
    return !!Object.keys(this.formGroup.controls).length;
  }
}
