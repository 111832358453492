import { Injectable } from '@angular/core';
import { CoreService } from '../core.service';
import { BaseObjectComment } from '../../_models/base-object-comment';
import { ServerUtcDateService } from './server-utc-date.service';

@Injectable()
export class CommentsService {
  constructor(private core: CoreService, private utcDateService: ServerUtcDateService) {}

  addComment(ownerId: string, text: string) {
    const comment = BaseObjectComment.create(ownerId);
    comment.text = text; // TODO actually sanitize this... do not allow special characters...
    comment.commentDate = this.utcDateService.getCurrentDate();
    comment.username = this.getUserName();
    comment.userId = this.getUserId();
    this.core.currentCase.commentsPool.unshift(comment);

    return comment;
  }

  updateComment(comment: BaseObjectComment, text: string) {
    comment.text = text; // TODO actually sanitize this... do not allow special characters...
    comment.commentDate = this.utcDateService.getCurrentDate();
    comment.username = this.getUserName();
    comment.userId = this.getUserId();
  }

  removeComment(commentId: string) {
    const commentIndex = this.core.currentCase.commentsPool.findIndex(c => c.id === commentId);
    this.core.currentCase.commentsPool.splice(commentIndex, 1);
  }

  getCommentsPool() {
    return this.core.currentCase.commentsPool;
  }

  getCommentById(id: string) {
    return this.core.currentCase.commentsPool.find(com => com.id === id);
  }

  getCommentsByOwnerId(ownerId: string): BaseObjectComment[] {
    return this.core.currentCase.commentsPool.filter(c => c.ownerId === ownerId);
  }

  private getUserName() {
    const serializedUser = localStorage.getItem('user');
    return JSON.parse(serializedUser || '{}').userName;
  }

  private getUserId() {
    const serializedUser = localStorage.getItem('user');
    return JSON.parse(serializedUser || '{}').id;
  }

  private get currentCase() {
    return this.core.currentCase;
  }
}
