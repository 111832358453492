import { WaterUnitOperation } from './water-unit-operation';
import { isTypeUndefined } from '../../../_utils/utils';
import { DistributionRatioVariable } from '../distribution-ratio-variable';
import { WaterMaterialStream } from '../../_streams/water-material-stream';

export abstract class BaseWaterSplitter extends WaterUnitOperation {
  abstract override category: string;

  isOptimizable: boolean;
  distributionRatioVariables: DistributionRatioVariable[] = [];

  override initValues(unitOperation: BaseWaterSplitter | any) {
    super.initValues(unitOperation);

    this.isOptimizable = isTypeUndefined(unitOperation.isOptimizable) ? false : unitOperation.isOptimizable;
    if (unitOperation.distributionRatioVariables instanceof Array) {
      for (const dv of unitOperation.distributionRatioVariables) {
        this.distributionRatioVariables.push(new DistributionRatioVariable(dv.unitOperationId, dv.value));
      }
    }
  }

  addDistributionVariable(unitOperationId: string) {
    this.emptyDistributionValues();
    this.distributionRatioVariables.push(new DistributionRatioVariable(unitOperationId, null));
  }

  private emptyDistributionValues() {
    for (const dv of this.distributionRatioVariables) {
      dv.value = null;
    }
  }

  findDistributionRatioVariable(unitOperationId: string): DistributionRatioVariable {
    return this.distributionRatioVariables.find(drv => {
      return drv.unitOperationId === unitOperationId;
    });
  }

  findDistributionRatioVariableName(i: number): string {
    const dv = this.distributionRatioVariables[i];

    if (dv) {
      const uo = this.ownerCase.getUnitOperation(dv.unitOperationId);

      const waterStreams = this.ownerCase.filterAllMaterialStreams((ws: WaterMaterialStream) => {
        return ws.outletUnitOperationId === uo?.id;
      });

      return waterStreams.length ? waterStreams[0].outletUnitOperation.name : undefined;
    }

    return undefined;
  }

  findDistributionRatioVariableIndex(unitOperationId: string) {
    return this.distributionRatioVariables.indexOf(this.findDistributionRatioVariable(unitOperationId));
  }

  removeDistributionRatioVariable(unitOperationId: string): void {
    const index = this.findDistributionRatioVariableIndex(unitOperationId);

    if (index >= 0) {
      this.distributionRatioVariables.splice(index, 1);
    }
  }
}
