import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import * as Highcharts from 'highcharts';
import * as Drilldown from 'highcharts/modules/drilldown.src';
import * as more from 'highcharts/highcharts-more.src';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JsonPipe } from '@angular/common';
import { HeaderInterceptor } from './shared/header-interceptor';

import { routing } from './app.routing';

import { AppComponent } from './app.component';
import { FlowsheetModule } from './flowsheet/flowsheet.module';

import { AuthGuard } from './_guards/auth.guard';
import { AuthService } from './_services/auth.service';
import { UserService } from './_services/user.service';

import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';

import { EqualValidatorDirective } from './shared/equal-validator.directive';
import { ChangelogComponent } from './changelog/changelog.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LatestAccessComponent } from './latest-access/latest-access.component';
import { RoleAdminGuard } from './_guards/roleAdmin.guard';
import { GlobalErrorHandler } from './_services/global-error-handler';
import { GlobalErrorModalComponent } from './shared/global-error-modal/global-error-modal.component';

export function initHighcharts() {
  return () => {
    Drilldown(Highcharts);
    more(Highcharts);
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    EqualValidatorDirective,
    ChangelogComponent,
    ForgotPasswordComponent,
    LatestAccessComponent,
    GlobalErrorModalComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    routing,
    FlowsheetModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-full-width',
    }),
  ],
  providers: [
    AuthGuard,
    RoleAdminGuard,
    AuthService,
    UserService,
    JsonPipe,
    { provide: APP_INITIALIZER, useFactory: initHighcharts, multi: true, deps: [] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
