import { Injectable } from '@angular/core';
import * as go from 'gojs';

@Injectable()
export class GoJsPaletteUtils {
  $ = go.GraphObject.make;

  // TODO this should be reused
  initPaletteElement(paletteDivId: string): go.Palette {
    return this.$(go.Palette, paletteDivId, {
      autoScale: go.Diagram.None,
      contentAlignment: go.Spot.TopCenter,
      'toolManager.hoverDelay': 60,
      layout: this.$(go.GridLayout, { sorting: go.GridLayout.Forward }),
    });
  }

  // TODO this should be reused
  getPaletteNodeTemplate(): any {
    return this.$(
      go.Node,
      'Auto',
      {
        width: 80,
        height: 60,
        // desiredSize: new go.Size(70, NaN),
        mouseEnter: (e, obj: go.Node) => {
          const icon = obj.findObject('ICON') as go.Picture;
          icon.source = icon.source.replace('.svg', '_w.svg');
          (obj.findObject('BACKGROUND') as any).fill = '#96c1d6';
        },
        mouseLeave: (e, obj: go.Node) => {
          const icon = obj.findObject('ICON') as go.Picture;
          !obj.part.selectable
            ? ((obj.findObject('BACKGROUND') as any).fill = '#96c1d6')
            : ((obj.findObject('BACKGROUND') as any).fill = 'Transparent');
          icon.source = icon.source.replace('_w.svg', '.svg');
        },
      },
      this.$(
        go.Shape,
        {
          margin: 0,
          figure: 'Rectangle',
          fill: 'Transparent',
          stroke: '#5f5f5f',
          strokeWidth: 0,
          name: 'BACKGROUND',
        },
        new go.Binding('fill', 'backgroundColor')
      ),
      this.$(
        go.Panel,
        'Vertical',
        this.$(
          go.Picture,
          { desiredSize: new go.Size(25, 25), margin: 5, name: 'ICON' },
          // Picture.source is data bound to the "source" attribute of the model data
          new go.Binding('source', 'icon')
        ),
        this.$(
          go.TextBlock,
          {
            margin: 0,
            font: '11px sans-serif',
            textAlign: 'center',
            desiredSize: new go.Size(80, NaN),
            name: 'TEXT',
          },
          new go.Binding('text', 'name'),
          new go.Binding('stroke', 'textColor')
        ),
        {}
      ),
      new go.Binding('selectable', 'selectable')
    );
  }
}
