<div class="form-group">
  <label [attr.for]="idPrefix + 'sulfur'" class="pw-label control-label col-sm-4"> Sulfur </label>

  <sim-var-input
    [simVar]="cut.sulfur"
    [inputId]="idPrefix + 'sulfur'"
    [readOnly]="readOnly"
    [transientValue]="readOnly"></sim-var-input>
</div>

<div class="form-group">
  <label [attr.for]="idPrefix + 'anilinePoint'" class="pw-label control-label col-sm-4"> Aniline Point </label>

  <sim-var-input
    [simVar]="cut.anilinePoint"
    [inputId]="idPrefix + 'anilinePoint'"
    [readOnly]="readOnly"
    [transientValue]="readOnly"></sim-var-input>
</div>

<div class="form-group" *ngIf="isStream">
  <label [attr.for]="idPrefix + 'aromaticityFactor'" class="pw-label control-label col-sm-4">
    Aromaticity Factor
  </label>

  <sim-var-input
    [simVar]="cut.aromaticityFactor"
    [inputId]="idPrefix + 'aromaticityFactor'"
    [readOnly]="readOnly"
    [transientValue]="readOnly"></sim-var-input>
</div>

<div class="form-group">
  <label [attr.for]="idPrefix + 'massDensity'" class="pw-label control-label col-sm-4"> Mass Density </label>

  <sim-var-input
    [simVar]="cut.massDensity"
    [inputId]="idPrefix + 'massDensity'"
    [readOnly]="readOnly"
    [transientValue]="readOnly"></sim-var-input>
</div>

<div class="form-group">
  <label [attr.for]="idPrefix + 'nitrogen'" class="pw-label control-label col-sm-4"> Nitrogen </label>

  <sim-var-input
    [simVar]="cut.nitrogen"
    [inputId]="idPrefix + 'nitrogen'"
    [readOnly]="readOnly"
    [transientValue]="readOnly"></sim-var-input>
</div>

<div class="form-group">
  <label [attr.for]="idPrefix + 'ccr'" class="pw-label control-label col-sm-4"> CCR </label>

  <sim-var-input
    [simVar]="cut.ccr"
    [inputId]="idPrefix + 'ccr'"
    [readOnly]="readOnly"
    [transientValue]="readOnly"></sim-var-input>
</div>

<div class="form-group" *ngIf="isStream">
  <label [attr.for]="idPrefix + 'Afc'" class="pw-label control-label col-sm-4"> AFC </label>

  <sim-var-input
    [simVar]="cut.afc"
    [inputId]="idPrefix + 'Afc'"
    [readOnly]="readOnly"
    [transientValue]="readOnly"></sim-var-input>
</div>
