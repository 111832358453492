import { Component, Input, OnInit } from '@angular/core';
import { WaterCooler } from '../../../_models/_unit-operations/utilties/water-cooler';
import { TemperatureCalculationMode } from '../../../_config/temperature-calculation-mode.enum';

@Component({
  selector: 'sob-water-cooler',
  templateUrl: './water-cooler.component.html',
  styleUrls: ['./water-cooler.component.css'],
})
export class WaterCoolerComponent implements OnInit {
  @Input() unitOperation: WaterCooler;
  TemperatureCalculationMode = TemperatureCalculationMode;

  ngOnInit() {
    if (this.unitOperation.temperatureCalculationMode === TemperatureCalculationMode.OUTLET_TEMPERATURE) {
      $('#selectMode').val(TemperatureCalculationMode.OUTLET_TEMPERATURE);
      $('#outlet-temperature').show();
    } else if (this.unitOperation.temperatureCalculationMode === TemperatureCalculationMode.TEMPERATURE_DROP) {
      $('#selectMode').val(TemperatureCalculationMode.TEMPERATURE_DROP);
      $('#outlet-temperature').hide();
    }
  }

  temperatureCalculationModeChanged(calculationValue) {
    if (calculationValue === TemperatureCalculationMode.TEMPERATURE_DROP) {
      this.unitOperation.temperatureCalculationMode = TemperatureCalculationMode.TEMPERATURE_DROP;
      $('#outlet-temperature').hide();
    } else if (calculationValue === TemperatureCalculationMode.OUTLET_TEMPERATURE) {
      this.unitOperation.temperatureCalculationMode = TemperatureCalculationMode.OUTLET_TEMPERATURE;
      $('#outlet-temperature').show();
    }
  }
}
