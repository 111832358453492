export function initStackedModals() {
  // eslint-disable-next-line func-names
  $(document).on('show.bs.modal', function () {
    // eslint-disable-next-line func-names
    setTimeout(function () {
      const $modalVisible = $('.modal:visible');
      const zIndex = 2040 + 10 * $modalVisible.length;

      const $backdrop = $('.modal-backdrop').not('.modal-stack');
      $backdrop.addClass('modal-stack');
    }, 0);
  });

  // eslint-disable-next-line func-names
  $(document).on('hidden.bs.modal', function () {
    if ($('.modal:visible').length) {
      $('body').addClass('modal-open');
    }
  });
}
