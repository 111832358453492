import { Case } from '../../case';
import { OptimizationReportVariable } from './optimization-report-variable';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Splitter } from '../../_unit-operations/splitter';
import { ProductTank } from '../../product-tank';
import { WaterHeader } from '../../_unit-operations/utilties/water-header';
import { FlowsheetTreeService } from '../../../_services/sub-flowsheet/flowsheet-tree/flowsheet-tree.service';
import { UnitOperation } from '../../unit-operation';

export class OptimizationVariablesReport {
  optimizationTableVariables: OptimizationReportVariable[] = [];

  constructor(c: Case, activeFlowsheetId: string, flowsheetTree: FlowsheetTreeService) {
    this.initReport(c, activeFlowsheetId, flowsheetTree.getAllFlowsheetOwners(c));
  }

  initReport(c: Case, activeFlowsheetId: string, flowsheetOwners: UnitOperation[]) {
    const splitters = c.filterUnitOperations(uo => {
      return (
        uo.category === unitOperationsConfig.splitter.key ||
        uo.category === unitOperationsConfig.fuelGasSplitter.key ||
        uo.category === unitOperationsConfig.waterSplitter.key
      );
    });

    const productTanks = c.filterUnitOperations(uo => {
      return uo.category === unitOperationsConfig.productTank.key;
    });

    const headers = c.filterUnitOperations(uo => {
      return uo.category === unitOperationsConfig.waterHeader.key;
    });

    const optimizableUnits = c.filterUnitOperations(uo => {
      return (
        uo.category === unitOperationsConfig.diluentSource.key ||
        uo.category === unitOperationsConfig.thirdPartySource.key ||
        uo.category === unitOperationsConfig.fuelGasSource.key ||
        uo.category === unitOperationsConfig.waterSource.key
      );
    });

    for (const s of splitters) {
      this.detectActiveFlowsheetUnitsOperation(s, activeFlowsheetId);
    }

    for (const pT of productTanks) {
      this.detectActiveFlowsheetUnitsOperation(pT, activeFlowsheetId);
    }

    for (const h of headers) {
      this.detectActiveFlowsheetUnitsOperation(h, activeFlowsheetId);
    }

    for (const u of optimizableUnits) {
      if (activeFlowsheetId !== undefined) {
        if (u.flowsheetId === activeFlowsheetId) {
          switch (this.findActiveFlowsheetCategory(activeFlowsheetId, flowsheetOwners)) {
            default:
              this.optimizationTableVariables.push(OptimizationReportVariable.create((<any>u).volumetricFlowrate));
              break;
            case unitOperationsConfig.waterUtilityUnitOperation.key:
              this.optimizationTableVariables.push(OptimizationReportVariable.create((<any>u).massFlowrate));
              break;
            case unitOperationsConfig.fuelGasUtilityUnitOperation.key:
              this.optimizationTableVariables.push(OptimizationReportVariable.create((<any>u).flowrate));
              break;
          }
        }
      } else if (u.flowsheetId === '') {
        this.optimizationTableVariables.push(OptimizationReportVariable.create((<any>u).volumetricFlowrate));
      }
    }
  }

  detectActiveFlowsheetUnitsOperation(unitOperation: any, activeFlowsheetId: string) {
    if (activeFlowsheetId !== undefined) {
      if (unitOperation.flowsheetId === activeFlowsheetId) {
        this.createOptimizationReportVariableByUnitOpCategory(unitOperation);
      }
    } else if (unitOperation.flowsheetId === '') {
      this.createOptimizationReportVariableByUnitOpCategory(unitOperation);
    }
  }

  createOptimizationReportVariableByUnitOpCategory(unitOperation: any) {
    switch (unitOperation.category) {
      case unitOperationsConfig.splitter.key:
      case unitOperationsConfig.waterSplitter.key:
      case unitOperationsConfig.fuelGasSplitter.key:
        this.optimizationTableVariables = this.optimizationTableVariables.concat(
          OptimizationReportVariable.createFromSplitter(unitOperation as Splitter)
        );
        break;

      case unitOperationsConfig.productTank.key:
        this.optimizationTableVariables = this.optimizationTableVariables.concat(
          OptimizationReportVariable.createFromSplitter(unitOperation as ProductTank)
        );
        break;

      case unitOperationsConfig.waterHeader.key:
        this.optimizationTableVariables = this.optimizationTableVariables.concat(
          OptimizationReportVariable.createFromSplitter(unitOperation as WaterHeader)
        );
        break;
    }
  }

  findActiveFlowsheetCategory(activeFlowsheetId: string, flowsheetOwners: UnitOperation[]) {
    let flowsheetOwnerCategory;

    flowsheetOwners.forEach(uO => {
      if (uO.id === activeFlowsheetId) {
        flowsheetOwnerCategory = uO.category;
      }
    });

    return flowsheetOwnerCategory;
  }
}
