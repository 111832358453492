import { Completion } from '@codemirror/autocomplete';

export const hydrocrackerCodeCompletions: Completion[] = [
  { label: 'DiluentYield', type: 'variable', info: '' },
  { label: 'NaphtaYield', type: 'variable', info: '' },
  { label: 'LGOYield', type: 'variable', info: '' },
  { label: 'HGOYield', type: 'variable', info: '' },
  { label: 'ResidueYield', type: 'variable', info: '' },
  { label: 'NaphtaAPI', type: 'variable', info: '' },
  { label: 'LGOAPI', type: 'variable', info: '' },
  { label: 'HGOAPI', type: 'variable', info: '' },
  { label: 'ResidueAPI', type: 'variable', info: '' },
  { label: 'NaphtaSulfur', type: 'variable', info: '' },
  { label: 'LGOSulfur', type: 'variable', info: '' },
  { label: 'HGOSulfur', type: 'variable', info: '' },
  { label: 'ResidueSulfur', type: 'variable', info: '' },
  { label: 'NaphtaNitrogen', type: 'variable', info: '' },
  { label: 'LGONitrogen', type: 'variable', info: '' },
  { label: 'HGONitrogen', type: 'variable', info: '' },
  { label: 'ResidueNitrogen', type: 'variable', info: '' },
];
