import { FlowsheetTreeNode } from './flowsheet-tree-node';

export class FlowsheetTreeRoot extends FlowsheetTreeNode {
  constructor() {
    super(undefined);
  }

  override get id() {
    return '';
  }

  override get name() {
    return 'Main';
  }
}
