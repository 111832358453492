import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CodeEditorData } from '../../_services/custom-code-providers/default-code-provider';

@Injectable()
export class CodeEditorService {
  code: string;

  private setCodeSubject: Subject<CodeEditorData> = new Subject();
  setCodeRequest = this.setCodeSubject.asObservable();

  closeAndSaveCodeSubject: Subject<string> = new Subject();
  closeAndSaveCodeRequest = this.closeAndSaveCodeSubject.asObservable();

  setEditorCode(codeData: CodeEditorData) {
    this.setCodeSubject.next(codeData);
  }

  closeAndSaveCode(res) {
    this.closeAndSaveCodeSubject.next(res);
  }
}
