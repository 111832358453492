export const defaultBitumenConversionModel =
  '// GetUnitOpInletVolumetricFlowRate(string UnitOperationName)\n' +
  '// Gets the UnitOperationName and returns the Inlet Volumetric Flowrate\n' +
  '// GetStreamVolumetricFlowRate(string MaterialStreamName)\n' +
  '// Gets the MaterialStreamName as string and returns the volumetric Flowrate of any stream in the flowsheet\n' +
  '// GetStreamFlowRate (string MaterialStreamName, FlowrateBasis basis)\n' +
  '// Gets the MaterialStreamName and "FlowrateBasis" and return the flowrate\n' +
  '// Valid Options for FlowrateBasis: \n' +
  '// FlowrateBasis.Mass (returns in TPD)| FlowrateBasis.Volume (returns in kbpcd)\n' +
  '// GetStreamVolumetricFlowRate(string MaterialStreamName) \n' +
  '// Gets the MaterialStreamName as string and returns the volumetric Flowrate of any stream in the flowsheet\n' +
  '// GetStreamCutFlowRate(string streamName, SuncorCuts suncorCut, FlowrateBasis basis)\n' +
  '// Gets streamName, SuncorCut and Flowrate basis and returns the cut flowrate for the given stream\n ' +
  '// Valid options for SuncorCut:\n ' +
  '// SuncorCuts.LightEnd, SuncorCuts.LightNaphta, SuncorCuts.HeavyNaphta, \n' +
  '// SuncorCuts.Distillate, SuncorCuts.LightGasOil, SuncorCuts.HeavyGasOil, \n' +
  '// SuncorCuts.VacGasOil, SuncorCuts.HeavyVacGasOil, SuncorCuts.VacuumResidue \n' +
  '// Accessible block variables:\n' +
  '// YieldBasis \n' +
  '// Valid options: YieldBasis.Mass, YieldBasis.Volume)\n' +
  '// ProductYields (Dictionary of stream Name and Corresponding Yield Percent for the stream)\n' +
  '// Example: ProductYields["Outlet1"] = 80.0 \n';
