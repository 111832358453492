import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SankeyDiagramService } from '../sankey-diagram/sankey-diagram.service';
import { CoreService } from '../../_services/core.service';
import { MultiPeriodService } from '../../_services/multi-period/multi-period.service';

@Component({
  selector: 'sob-flowsheet-view-selector',
  templateUrl: './flowsheet-view-selector.component.html',
  styleUrls: ['./flowsheet-view-selector.component.css'],
})
export class FlowsheetViewSelectorComponent implements AfterViewInit, OnInit {
  firstTime = true;

  constructor(
    private sankeyDiagramService: SankeyDiagramService,
    private coreService: CoreService,
    private ref: ChangeDetectorRef,
    private multiPeriodService: MultiPeriodService
  ) {}

  ngOnInit() {
    this.multiPeriodService.multiPeriodModeEnabledRequest.subscribe(options => {
      if (options.multiPeriodEnabled && options.showSettingsModal) {
        this.openMultiPeriodSettingsTab();
      } else {
        this.closeMultiPeriodSettingsTab();
      }
    });

    this.multiPeriodService.multiPeriodSettingsCompletedRequest.subscribe(() => {
      this.openDiagramTab();
    });
  }

  ngAfterViewInit() {
    $('#pillContainer').on('shown.bs.tab', () => {
      // TODO remove setTimeOut implementation, without it the diagram won't refresh correctly
      // TODO may be worth to call this once after sankey chart chart is loaded
      if ($('#sankey-container').hasClass('active')) {
        setTimeout(() => {
          this.sankeyDiagramService.zoomToFit();
        }, 5);
      }
    });
  }

  refreshSankey() {
    if (this.firstTime) {
      this.sankeyDiagramService.sankeyDiagram.delayInitialization(() => {
        this.sankeyDiagramService.sankeyDiagram.requestUpdate();
      });
      this.firstTime = false;
    }
    this.sankeyDiagramService.sankeyDiagram.requestUpdate();

    this.showSankeyControls();
    this.hidePalette();
  }

  private openMultiPeriodSettingsTab() {
    this.ref.detectChanges();
    this.hideControls();
    $('.nav-pills a[href="#multi-period-settings-container"]').tab('show');
  }

  private closeMultiPeriodSettingsTab() {
    if ($('#multi-period-settings-container').hasClass('active')) {
      $('.nav-pills a[href="#flowsheet-diagram-container"]').tab('show');
    }
  }

  private openDiagramTab() {
    this.ref.detectChanges();
    this.showControls();
    $('.nav-pills a[href="#flowsheet-diagram-container"]').tab('show');
  }

  get isSolved() {
    return this.coreService.currentCase.isSolved;
  }

  get multiPeriodEnabled() {
    return this.coreService.currentCase.multiPeriodEnabled;
  }

  hideControls() {
    $('.control-wrapper').hide();
    $('.sankey-control-wrapper').hide();
    this.hidePalette();
  }

  showControls() {
    $('.control-wrapper').show();
    this.hideSankeyControls();

    // palette hidden in multiPeriod mode
    if (!this.multiPeriodEnabled) {
      this.showPalette();
    }
  }

  showSankeyControls() {
    $('.control-wrapper').hide();
    $('.sankey-control-wrapper').show();
  }

  hideSankeyControls() {
    $('.sankey-control-wrapper').hide();
  }

  hidePalette() {
    $('#paletteWrapper').hide();
    $('#paletteButton').hide();
  }

  showPalette() {
    $('#paletteWrapper').show();
    $('#paletteButton').show();
  }
}
