import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { UnitOperation } from '../../_models';
import { FluidAnalysis } from '../../_models/_fluid/fluid-analysis';

// this is being used to track rows as well...
export class FlexSheetColumnTracker {
  private unitOperationIds = [];
  private assayIds = [];

  constructor(private columnOffset: number) {}

  addUnitOperation(uo: SuncorUnitOperation | UnitOperation) {
    this.unitOperationIds.push(uo.id);
  }

  addAssay(assay: FluidAnalysis) {
    this.assayIds.push(assay.id);
  }

  removeUnitOperation(uo: SuncorUnitOperation | UnitOperation) {
    const unitOperationIndex = this.unitOperationIds.indexOf(uo.id);
    this.unitOperationIds.splice(unitOperationIndex, 1);
  }

  removeAssay(assay: FluidAnalysis) {
    const assayIndex = this.assayIds.indexOf(assay.id);
    this.assayIds.splice(assayIndex, 1);
  }

  columnCount(): number {
    return this.unitOperationIds.length;
  }

  findColumnIndex(unitOperation: SuncorUnitOperation | UnitOperation) {
    const unitOperationIndex = this.unitOperationIds.indexOf(unitOperation.id);
    return unitOperationIndex === -1 ? unitOperationIndex : unitOperationIndex + this.columnOffset;
  }

  findAssayColumnIndex(assay: FluidAnalysis) {
    const assayIndex = this.assayIds.indexOf(assay.id);
    return assayIndex === -1 ? assayIndex : assayIndex + this.columnOffset;
  }

  findAssayId(column: number) {
    return this.assayIds[column - this.columnOffset];
  }

  clear() {
    this.unitOperationIds = [];
    this.assayIds = [];
  }
}
