import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { GlobalErrorModalService } from './global-error-modal.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../../_services/user.service';

interface FrontendError {
  message: string;
  stackTrace: string;
  username: string;
}

@Component({
  selector: 'sob-global-error-modal',
  templateUrl: './global-error-modal.component.html',
  styleUrls: ['./global-error-modal.component.css'],
})
export class GlobalErrorModalComponent implements AfterViewInit, OnDestroy {
  private isModalWindowOpen = false;
  private subSink = new SubSink();

  private readonly errorNotificationDelayTimeMs = 15 * 1000;
  private previousErrorNotifiedAt = 0;
  protected previousError?: FrontendError;
  protected currentError: FrontendError = {
    message: '',
    stackTrace: '',
    username: '',
  };

  protected errorDetailsCollapsed = true;
  protected processEcologyNotified = true;
  private loading = false;

  constructor(
    private globalErrorModalService: GlobalErrorModalService,
    private http: HttpClient,
    private userService: UserService,
    private ref: ChangeDetectorRef
  ) {
    this.globalErrorModalService.errorOccurred$.subscribe({
      next: error => {
        if (error instanceof HttpErrorResponse) {
          return;
        }

        this.previousError = this.currentError;

        if (error instanceof Error) {
          this.currentError = {
            message: error.message,
            stackTrace: this.getStackTrace(error),
            username: '',
          };
        } else {
          try {
            this.currentError = {
              message: 'An unexpected error occurred',
              stackTrace: JSON.stringify(error),
              username: '',
            };
          } catch (e: unknown) {
            // This is very, very unlikely to happen, just in case the error has a circular reference and fails
            // to be serialized...
            this.currentError = {
              message: 'An unexpected error occurred, please check the browser console for more details',
              stackTrace: '',
              username: '',
            };
          }
        }

        if (this.areCurrentAndPreviousErrorsEqual() || this.isModalWindowOpen) {
          return;
        }

        this.showModalWindow();

        const timeNow = new Date().getTime();
        // wait some time before sending another notification another guard against
        // bursting errors...
        if (timeNow - this.previousErrorNotifiedAt < this.errorNotificationDelayTimeMs) {
          return;
        }
        this.previousErrorNotifiedAt = timeNow;

        this.sendErrorToPe();
        this.ref.detectChanges();
      },
    });
  }

  ngAfterViewInit() {
    const $collapsible = $('#errorDetailsCollapsible');
    $collapsible.on('show.bs.collapse', () => {
      this.errorDetailsCollapsed = false;
    });
    $collapsible.on('hide.bs.collapse.bs.collapse', () => {
      this.errorDetailsCollapsed = true;
    });

    const $globalErrorModal = $('#globalErrorModal');
    $globalErrorModal.on('show.bs.modal', () => {
      this.isModalWindowOpen = true;
    });

    $globalErrorModal.on('hide.bs.modal', () => {
      this.isModalWindowOpen = false;
    });
  }

  protected showModalWindow() {
    $('#globalErrorModal').modal('show');
  }

  private getStackTrace(error: unknown) {
    if (error instanceof Error) {
      return error.stack;
    }

    return '';
  }

  private sendErrorToPe() {
    this.startLoading();
    this.processEcologyNotified = false;
    this.currentError.username = this.userService.currentUsername;
    this.subSink.add(
      this.http
        .post(environment.sendFrontendErrorDetailsToPe, this.currentError, { headers: this.getHeaders() })
        .subscribe({
          next: () => {
            this.stopLoading();
            this.processEcologyNotified = true;
            this.ref.detectChanges();
          },
          error: () => {
            this.stopLoading();
            this.processEcologyNotified = false;
            this.ref.detectChanges();
          },
        })
    );
  }

  private getHeaders() {
    return new HttpHeaders().set('Accept', 'application/json');
  }

  protected startLoading() {
    this.loading = true;
  }

  protected stopLoading() {
    this.loading = false;
  }

  protected isLoading() {
    return this.loading;
  }

  protected areCurrentAndPreviousErrorsEqual(): boolean {
    if (!this.previousError) {
      return false;
    }

    return (
      this.currentError.message === this.previousError.message &&
      this.currentError.stackTrace === this.previousError.stackTrace
    );
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}
