import { DefaultBaseObjectFormChangeDetector } from './default-base-object-form-change-detector';
import { BitumenConversion } from '../_models/_unit-operations/bitumen-conversion';
import { DefaultMappedType } from '../_models/default-mapped-type';
import { BitumenConversionOutlet } from '../_models/_unit-operations/bitumen-conversion-outlet';

export class BitumenConversionFormChangeDetector extends DefaultBaseObjectFormChangeDetector {
  override detectChangesInValues(
    originalFormValue: DefaultMappedType<BitumenConversion>,
    currentFormValue: DefaultMappedType<BitumenConversion>
  ): boolean {
    let changes = super.detectChangesInValues(originalFormValue, currentFormValue);

    if (originalFormValue.outlets.length !== currentFormValue.outlets.length) {
      return true;
    }

    originalFormValue.outlets.forEach((originalOutlet: DefaultMappedType<BitumenConversionOutlet>, i: number) => {
      const currentOutlet = currentFormValue.outlets[i] as DefaultMappedType<BitumenConversionOutlet>;

      changes = changes || originalOutlet.fluidAnalysisId !== currentOutlet.fluidAnalysisId;
      changes = changes || originalOutlet.yield.value !== currentOutlet.yield.value;
      changes = changes || originalOutlet.price.value !== currentOutlet.price.value;
    });

    return changes;
  }

  override ignoreForDefaultChangeDetection(k: string): boolean {
    return super.ignoreForDefaultChangeDetection(k) || (k as keyof BitumenConversion) === 'outlets';
  }
}
