<div [formGroup]="propertyWindowFormGroup">
  <div class="form-horizontal">
    <div class="form-group">
      <label class="pw-label control-label col-sm-4"> Assay Name </label>
      <div class="col-sm-6">
        <sob-assay-selector
          [fluidAnalyses]="fluidAnalyses"
          [currentAnalysis]="currentAnalysis"
          (updateCurrentAssay)="updateCurrentAnalysis($event)">
        </sob-assay-selector>
      </div>
    </div>
  </div>
  <hr />

  <ul class="nav nav-tabs m-r m-t-md" role="tablist" style="margin-top: 20px">
    <li role="presentation" class="active">
      <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
    </li>
    <li role="presentation">
      <a href="#utilities" aria-controls="utilities" role="tab" data-toggle="tab">Utilities</a>
    </li>

    <li *ngIf="currentAnalysis" role="presentation">
      <a href="#productspecs" aria-controls="productspecs" role="tab" data-toggle="tab">Product Specifications</a>
    </li>

    <li role="presentation">
      <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
    </li>

    <li role="presentation">
      <a href="#comments" aria-controls="comments" role="tab" data-toggle="tab">
        <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
        Comments
      </a>
    </li>
  </ul>

  <div class="tab-content pw-margin tab-content-padding">
    <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <div class="form-group">
            <label for="catalystRun" class="pw-label control-label col-sm-4"> Catalyst Run </label>
            <div class="col-sm-6">
              <select formControlName="catalystRun" id="catalystRun" class="form-control">
                <option value="{{ LgoHydroTreaterCatalystRun.EOR }}">EOR</option>
                <option value="{{ LgoHydroTreaterCatalystRun.SOR }}">SOR</option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <label for="yieldProfile" class="pw-label control-label col-sm-4"> Yield Profile </label>
            <div class="col-sm-6">
              <select formControlName="yieldProfile" id="yieldProfile" class="form-control">
                <option value="{{ LgoYieldProfile.HIGH }}">High</option>
                <option value="{{ LgoYieldProfile.LOW }}">Low</option>
              </select>
            </div>
          </div>

          <div class="form-group" formGroupName="naphthaYield">
            <label class="pw-label control-label col-sm-4"> Naphtha Yield </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.naphthaYield.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.naphthaYield">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="lgoYield">
            <label class="pw-label control-label col-sm-4"> LGO Yield </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.lgoYield.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.lgoYield">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="hgoYield">
            <label class="pw-label control-label col-sm-4"> HGO Yield </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.hgoYield.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.hgoYield">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="totalYield">
            <label class="pw-label control-label col-sm-4"> Total Yield </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.totalYield.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.totalYield">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="totalInletVolume">
            <label class="pw-label control-label col-sm-4"> Total Inlet Volume </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.totalInletVolume.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.totalInletVolume">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <h4 class="subtitle">GHG</h4>

          <div class="form-group" formGroupName="ghgIntensity">
            <label class="pw-label control-label col-sm-4"> GHG Intensity </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.ghgIntensity.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.ghgIntensity">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="ghgEmissions">
            <label class="pw-label control-label col-sm-4"> GHG Emissions </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.ghgEmissions.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.ghgEmissions">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div class="form-group">
            <label for="customModelEnabled" class="control-label col-sm-4"> Custom Model </label>
            <div class="col-sm-4">
              <label class="control-label">
                <input
                  formControlName="useCustomModel"
                  type="checkbox"
                  id="customModelEnabled"
                  (change)="useCustomModelChanged($event.target.checked)" />
              </label>
            </div>
          </div>

          <div>
            <sob-open-reactive-code-editor-button
              [code]="unitOperation.customModelScript"
              [defaultCodeProvider]="defaultCodeProvider"
              [disabled]="!useCustomModel"
              [instanceId]="'lgoHydrotreaterCodeEditor'"
              (codeSaved)="saveCustomModel($event)">
            </sob-open-reactive-code-editor-button>
          </div>
        </div>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="utilities">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <h4 class="subtitle">Hydrogen</h4>

          <div class="form-group" formGroupName="hydrogenUseRate">
            <label class="pw-label control-label col-sm-4"> Hydrogen Use Rate </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.hydrogenUseRate.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.hydrogenUseRate">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="hydrogenUse">
            <label class="pw-label control-label col-sm-4"> Hydrogen Use </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.hydrogenUse.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.hydrogenUse">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <h4 class="subtitle">Amine</h4>

          <div class="form-group" formGroupName="h2sProductionRate">
            <label class="pw-label control-label col-sm-4"> H2S Production Rate </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.h2sProductionRate.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.h2sProductionRate">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="h2sProduction">
            <label class="pw-label control-label col-sm-4"> H2S Production </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.h2sProduction.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.h2sProduction">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-horizontal">
            <sob-reactive-steam-use-utility [formGroupWrapper]="formGroupWrapper" [unitOperation]="unitOperation">
            </sob-reactive-steam-use-utility>

            <sob-reactive-gas-contributor [formGroupWrapper]="formGroupWrapper" [unitOperation]="unitOperation">
            </sob-reactive-gas-contributor>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentAnalysis" role="tabpanel" class="tab-pane fade in" id="productspecs">
      <ul class="nav nav-pills" role="tablist" style="margin-right: 15px">
        <li role="presentation" class="active">
          <a href="#bulkProperties" aria-controls="bulkProperties" role="tab" data-toggle="tab">Bulk Properties</a>
        </li>

        <li role="presentation">
          <a href="#cutProperties" aria-controls="cutProperties" role="tab" data-toggle="tab">Cut Properties</a>
        </li>

        <li role="presentation">
          <a href="#compositionUO" aria-controls="composition" role="tab" data-toggle="tab">Composition</a>
        </li>
      </ul>

      <div class="tab-content pw-margin tab-content-padding">
        <div role="tabpanel" class="tab-pane fade in active" id="bulkProperties">
          <sob-bulk-properties [currentAnalysis]="currentAnalysis"></sob-bulk-properties>
        </div>

        <div role="tabpanel" class="tab-pane fade in" id="cutProperties">
          <sob-reactive-cut-properties
            [readonly]="true"
            [fluidAnalysis]="currentAnalysis"></sob-reactive-cut-properties>
        </div>

        <div *ngIf="currentAnalysis" role="tabpanel" class="tab-pane fade in" id="compositionUO">
          <sob-composition [currentAnalysis]="currentAnalysis"></sob-composition>
        </div>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="constraints">
      <p style="text-align: right; position: relative">
        <span style="position: absolute; right: -25px; top: -20px; font-weight: bold">Active</span>
      </p>

      <div class="form-horizontal">
        <div class="form-group" formGroupName="capacity">
          <label class="pw-label control-label col-sm-4"> Maximum Feed Capacity </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-5">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capacity.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-5">
                <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.capacity">
                </sob-reactive-variable-value-input>
              </div>
              <div class="col-xs-2">
                <sob-reactive-constraint-check-box
                  formControlName="isActive"
                  [isConstraint]="true"
                  [valueMetaData]="unitOperation.capacity"></sob-reactive-constraint-check-box>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" formGroupName="minimumFlow">
          <label class="pw-label control-label col-sm-4"> Minimum Flow </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-5">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.minimumFlow.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-5">
                <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.minimumFlow">
                </sob-reactive-variable-value-input>
              </div>
              <div class="col-xs-2">
                <sob-reactive-constraint-check-box
                  formControlName="isActive"
                  [isConstraint]="true"
                  [valueMetaData]="unitOperation.minimumFlow"></sob-reactive-constraint-check-box>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" formGroupName="maximumSulfurContent">
          <label class="pw-label control-label col-sm-4"> Maximum Sulfur Content</label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-5">
                <sob-reactive-unit-select
                  formControlName="unit"
                  [quantity]="unitOperation.maximumSulfurContent.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-5">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.maximumSulfurContent">
                </sob-reactive-variable-value-input>
              </div>
              <div class="col-xs-2">
                <sob-reactive-constraint-check-box
                  formControlName="isActive"
                  [isConstraint]="true"
                  [valueMetaData]="unitOperation.maximumSulfurContent"></sob-reactive-constraint-check-box>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" formGroupName="maximumNitrogen">
          <label class="pw-label control-label col-sm-4"> Maximum Nitrogen</label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-5">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.maximumNitrogen.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-5">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.maximumNitrogen">
                </sob-reactive-variable-value-input>
              </div>
              <div class="col-xs-2">
                <sob-reactive-constraint-check-box
                  formControlName="isActive"
                  [isConstraint]="true"
                  [valueMetaData]="unitOperation.maximumNitrogen"></sob-reactive-constraint-check-box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="comments">
      <div class="tab-content no-padding no-border">
        <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
      </div>
    </div>
  </div>
</div>
