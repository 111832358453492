<div class="dropdown flowsheet-dropdown-menu">
  <button
    class="btn btn-default dropdown-toggle form-control flowsheetDropdown"
    type="button"
    id="flowsheetDropdown"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="true">
    {{ selectedFlowsheet ? selectedFlowsheet : 'Select' }}
    <span class="caret" style="float: right; margin-top: 8px"></span>
  </button>
  <ul class="dropdown-menu full-width" aria-labelledby="flowsheetDropdown" (click)="keepDropdownOpen($event)">
    <li>
      <wj-tree-view
        #flowsheetsTree
        [itemsSource]="treeData"
        [displayMemberPath]="'header'"
        [childItemsPath]="'items'"
        [showCheckboxes]="true"
        (checkedItemsChanged)="onCheckedItemsChanged()">
      </wj-tree-view>
    </li>
  </ul>
</div>
