import { WaterSinkImport } from '../../../_unit-operations/utilties/water-sink-import';
import { SimulationVariable } from '../../../simulation-variable';
import { ConstraintReportVariable } from '../constraint-report-variable';
import { SimulationVariableName } from '../../../../_config/simulation-variable-name.enum';

export class WaterSinkImportConstraintReportVariableResolver {
  static create() {
    return new WaterSinkImportConstraintReportVariableResolver();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  addCurrentValueAndUnitToConstraintReportVariable(
    crv: ConstraintReportVariable,
    params: { uo: WaterSinkImport; simVar: SimulationVariable }
  ): void {
    const { uo, simVar } = params;

    const inletStreamsList = uo.ownerCase.filterWaterMaterialStreams(s => {
      return s.outletUnitOperationId === uo.id;
    });

    if (!inletStreamsList.length) {
      return;
    }

    if (simVar.name === SimulationVariableName.TEMPERATURE) {
      crv.currentValue = inletStreamsList[0].temperature.convertToDefaultUnit(true, false);
      crv.currentUnit = inletStreamsList[0].temperature.getDefaultUnit();
      return;
    }
    if (simVar.name === SimulationVariableName.PRESSURE) {
      crv.currentValue = inletStreamsList[0].pressure.convertToDefaultUnit(true, false);
      crv.currentUnit = inletStreamsList[0].pressure.getDefaultUnit();
      return;
    }
    if (simVar.name === SimulationVariableName.FLOWRATE) {
      crv.currentValue = inletStreamsList[0].massFlowrate.convertToDefaultUnit(true, false);
      crv.currentUnit = inletStreamsList[0].massFlowrate.getDefaultUnit();
      return;
    }

    throw new Error(`Unknown constraint: ${simVar.name}`);
  }
}
