<div class="row" style="margin-top: 20px">
  <div class="col-xs-12">
    <div class="navbar" style="margin-bottom: 0px">
      <div class="col-md-12" style="height: 90px">
        <div class="col-md-2 col-md-offset-4 col-xs-6">
          <img
            class="img-responsive"
            src="/assets/img/logo_pe_white.png"
            style="max-height: 70px; min-height: 70px; padding-bottom: 10px" />
        </div>
        <div class="col-md-2 text-center col-xs-6">
          <img
            class="img-responsive"
            src="/assets/img/suncor-w.png"
            style="max-height: 70px; float: right; min-height: 70px; padding-bottom: 10px" />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xs-12" style="background: rgb(243, 243, 244)">
    <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-lg-offset-3 col-lg-6">
      <div class="row" style="margin-top: 20px">
        <div class="col-xs-12">
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        <div
          class="col-xs-12 col-sm-offset-2 col-sm-8 panel panel-default"
          style="padding-top: 20px; padding-bottom: 20px">
          <div *ngIf="error" class="alert alert-danger margin-bottom-30" id="errorModal">
            <button type="button" autofocus class="close" (click)="closeModal()">
              <span aria-hidden="true">×</span>
              <span class="sr-only">Close</span>
            </button>
            <strong>{{ error }}</strong>
          </div>
          <div *ngIf="success" class="alert alert-success margin-bottom-30">
            <button type="button" autofocus class="close" (click)="closeModal()">
              <span aria-hidden="true">×</span>
              <span class="sr-only">Close</span>
            </button>
            <strong>{{ success }}</strong>
          </div>

          <div class="panel-body">
            <form name="signup" (ngSubmit)="f.form.valid && signup()" #f="ngForm" novalidate>
              <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !firstName.valid }">
                <label for="firstName">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="firstName"
                  [(ngModel)]="model.firstName"
                  #firstName="ngModel"
                  required />
                <div *ngIf="f.submitted && !firstName.valid" class="help-block">First Name is required</div>
              </div>
              <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !lastName.valid }">
                <label for="lastName">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="lastName"
                  [(ngModel)]="model.lastName"
                  #lastName="ngModel"
                  required />
                <div *ngIf="f.submitted && !lastName.valid" class="help-block">Last Name is required</div>
              </div>
              <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !userName.valid }">
                <label for="userName">Username</label>
                <input
                  type="email"
                  class="form-control"
                  name="userName"
                  placeholder="E-Mail (Username)"
                  [(ngModel)]="model.userName"
                  #userName="ngModel"
                  required />
                <div *ngIf="f.submitted && !userName.valid" class="help-block">Username is required</div>
              </div>
              <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                <label for="password">Password</label>
                <input
                  type="password"
                  class="form-control"
                  name="password"
                  [(ngModel)]="model.password"
                  #password="ngModel"
                  required />
                <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
              </div>
              <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !confirmPassword.valid }">
                <label for="confirmPassword">Confirm Password</label>
                <input
                  name="confirmPassword"
                  type="password"
                  class="form-control"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  [(ngModel)]="model.confirmPassword"
                  #confirmPassword="ngModel"
                  required />
                <div *ngIf="f.submitted && !confirmPassword.valid" class="help-block">Password is required</div>
              </div>
              <div class="form-group">
                <button [disabled]="loading" class="btn btn-primary">Register</button>
                <img
                  *ngIf="loading"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                  style="margin: 15px" />
                <a [routerLink]="['/login']" class="btn btn-link">Cancel</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row blocky"></div>
