<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li *ngIf="inletStream" role="presentation">
    <a href="#compositionTab" aria-controls="compositionTab" role="tab" data-toggle="tab">Composition</a>
  </li>

  <li role="presentation">
    <a href="#fuelGasFlareComments" aria-controls="fuelGasFlareComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg {{ this.unitOperation.comments ? 'gentle-hover-shake' : '' }}" aria-hidden="true">
      </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding" [formGroup]="propertyWindowFormGroup">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Conditions</h4>
        <hr />
        <br />

        <ng-container>
          <div class="form-group" formGroupName="flowrate">
            <label class="control-label col-sm-4"> Flowrate </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.flowrate.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.flowrate">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <h4 class="subtitle">GHG</h4>
          <hr />
          <br />

          <div class="form-group" formGroupName="fuelGasEmissionFactor">
            <label class="control-label col-sm-4"> Emission Factor </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.fuelGasEmissionFactor.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.fuelGasEmissionFactor">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="ghgEmissions">
            <label class="control-label col-sm-4"> GHG Emissions </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.ghgEmissions.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.ghgEmissions">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="inletStream" role="tabpanel" class="tab-pane fade in" id="compositionTab">
    <div class="tab-content pw-margin tab-content-padding">
      <div *ngIf="inletStream" role="tabpanel" class="tab-pane fade in active" id="compositionUO">
        <div class="form-horizontal">
          <ng-container sobReactiveMultipleVariableInput totalOutputId="compositionTotalUO">
            <div class="form-group">
              <div class="row">
                <label class="control-label col-sm-4"> Unit </label>
                <div class="col-sm-4" (change)="getComposition()">
                  <sob-reactive-unit-select
                    formControlName="compositionUnit"
                    [quantity]="inletStream.composition[0].quantity">
                  </sob-reactive-unit-select>
                </div>
              </div>
            </div>

            <ng-container formArrayName="composition">
              <div class="form-group" *ngFor="let sv of inletStream.composition; let i = index">
                <div class="row">
                  <label class="control-label col-sm-4">
                    {{ inletStream.composition[i].name }}
                  </label>
                  <div class="col-sm-4">
                    <sob-reactive-variable-value-input [formControlName]="i" [valueMetaData]="sv">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="form-group">
              <label class="pw-label control-label col-sm-4">Total</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" formControlName="total" id="compositionTotalUO" />
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="fuelGasFlareComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
