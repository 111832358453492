import { Parameter } from './parameter';
import { SimulationVariable } from '../simulation-variable';
import { Case } from '../case';

export class UncertainParameter extends Parameter {
  override category = 'uncertainParameter';
  mode: SimulationVariable;
  confidenceInterval: number;
  distributionType: string;

  constructor(uncertainParameter: any, ownerCase: Case) {
    super(uncertainParameter, ownerCase);
    if (uncertainParameter.simulationVariable instanceof SimulationVariable) {
      this.initMemberVariables(uncertainParameter);
    }
  }

  override initMemberVariables(uncertainParameter: any) {
    super.initMemberVariables(uncertainParameter);
    if (uncertainParameter.mode == null) {
      this.mode = new SimulationVariable(
        {
          unit: this.simulationVariable.unit,
          quantity: this.simulationVariable.quantity,
        },
        this.ownerCase,
        this
      );
    } else {
      this.mode = new SimulationVariable(
        {
          value: uncertainParameter.mode.value,
          unit: uncertainParameter.mode.unit,
          quantity: this.simulationVariable.quantity,
        },
        this.ownerCase,
        this
      );
    }
    this.distributionType = uncertainParameter.distributionType || 'normal';
    this.confidenceInterval =
      uncertainParameter.confidenceInterval == null ? 0.95 : uncertainParameter.confidenceInterval;
  }

  override convertToInternalUnit(): void {
    super.convertToInternalUnit();
    this.mode.value = this.mode.convertToInternalUnit();
  }

  override setSimulationVariablesOwner() {
    super.setSimulationVariablesOwner();
    this.mode.setOwnerBaseObject(this);
  }

  override overwriteValues(up: UncertainParameter) {
    super.overwriteValues(up);
    this.distributionType = up.distributionType;
    this.mode.overwriteValues(up.mode);
    this.confidenceInterval = up.confidenceInterval;
  }

  override dePersist(persistedObj: any): void {
    super.dePersist(persistedObj);
    this.mode = this.getSimulationVariableSafe(persistedObj.mode);
    this.confidenceInterval = +persistedObj.confidenceInterval;
    this.distributionType = persistedObj.distributionType;

    this.setSimulationVariablesOwner();
  }

  public override toJSON() {
    return {
      id: this.id,
      name: this.name,
      category: this.category,
      simulationVariable: this.simulationVariable.id,
      mode: this.mode.id,
      maximumValue: this.maximumValue.id,
      minimumValue: this.minimumValue.id,
      confidenceInterval: this.confidenceInterval,
      distributionType: this.distributionType,
    };
  }
}
