import { Completion } from '@codemirror/autocomplete';
import { DefaultCodeProvider } from './default-code-provider';
import { DEFAULT_GAS_CONTRIBUTOR_MODEL } from '../../_config/unit-operations/default-models/default-gas-contributor-model';
import { gasContributorCodeCompletions } from '../../_config/unit-operations/custom-code-completions/gas-contributor-code-completions';

export class GasContributorCodeProvider extends DefaultCodeProvider {
  constructor() {
    super(DEFAULT_GAS_CONTRIBUTOR_MODEL);
  }

  override getCodeCompletions(): Completion[] {
    return super.getCodeCompletions().concat(gasContributorCodeCompletions);
  }
}
