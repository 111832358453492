import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { GasFluidAnalysis } from '../../../_models/_fluid/gas-fluid-analysis';

@Component({
  selector: 'sob-assay-selector',
  templateUrl: './assay-selector.component.html',
  styleUrls: ['./assay-selector.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssaySelectorComponent),
      multi: true,
    },
  ],
})
export class AssaySelectorComponent implements ControlValueAccessor {
  @Input()
  currentAnalysis?: SuncorFluidAnalysis | GasFluidAnalysis;

  @Input()
  fluidAnalyses: (SuncorFluidAnalysis | GasFluidAnalysis)[];

  @Output()
  updateCurrentAssay = new EventEmitter<string>();

  updateCurrentAnalysis(id) {
    this.updateCurrentAssay.emit(id);
  }

  openAssayManager() {
    $('#assayManagerModal').modal('show');
  }

  registerOnChange(): void {}

  registerOnTouched(): void {}

  writeValue(): void {}
}
