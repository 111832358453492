import { BaseObject } from './base-object';
import { Case } from './case';

declare let unitConverter: any;
export class SimulationVariableArray extends BaseObject {
  category = 'simulationVariableArray';
  values: Array<number> = [];
  unit: string;
  quantity: string;
  variableStatus: string;
  ownerBaseObject: BaseObject;

  constructor(simulationVariableArray: any, ownerCase: Case, ownerBaseObject: BaseObject) {
    super(simulationVariableArray ? simulationVariableArray.id : '', ownerCase);

    if (simulationVariableArray instanceof Object) {
      if (simulationVariableArray.values instanceof Array) {
        for (const value of simulationVariableArray.values) {
          this.values.push(value);
        }
      }

      this.unit = simulationVariableArray.unit;
      this.quantity = simulationVariableArray.quantity;
      this.variableStatus = simulationVariableArray.variableStatus;
      this.setDefaultUnitIfNeeded();
    } else {
      this.unit = '';
      this.quantity = '';
      this.variableStatus = 'UnDefined';
    }

    this.ownerBaseObject = ownerBaseObject;
  }

  addValue(value: number) {
    this.values.push(value);
  }

  removeValue(index: number) {
    this.values.splice(index, 1);
  }

  setQuantity(quantity: string) {
    this.quantity = quantity;
    this.setDefaultUnitIfNeeded();
  }

  setOwnerBaseObject(ownerBaseObject: BaseObject): void {
    this.ownerBaseObject = ownerBaseObject;
  }

  getDefaultUnit(): string {
    return unitConverter(this.quantity).getDefaultUnit();
  }

  getInternalUnit(): string {
    return unitConverter(this.quantity).getInternalUnit();
  }

  getFullName(): string {
    return `${this.ownerBaseObject.name} - ${this.name}`;
  }

  isUndefined(): boolean {
    return this.variableStatus === 'UnDefined' && (typeof this.values === 'undefined' || this.values.length === 0);
  }

  setDefaultUnitIfNeeded() {
    if (
      (this.unit === '' && this.quantity) ||
      (this.quantity && !unitConverter(this.quantity).unitInQuantity(this.unit))
    ) {
      this.unit = this.getDefaultUnit();
    }
  }

  updateVariableStatus(another: SimulationVariableArray): void {
    if (this.detectValueChange(another)) {
      this.variableStatus = 'UserSpecified';
    }
  }

  detectValueChange(another: SimulationVariableArray): boolean {
    // eslint-disable-next-line guard-for-in
    for (const i in another.values) {
      const anotherValue = unitConverter.parseFloatString(
        another.convertValueToAnotherUnit(another.values[i], this.unit)
      );

      const thisValue = this.parseValue(this.values[i]);

      // NaN === NaN returns false, so it may be detected as a value change, avoid it
      if (thisValue !== anotherValue && !(isNaN(anotherValue) && isNaN(thisValue))) {
        return true;
      }
    }

    return false;
  }

  private convertValueToAnotherUnit(value: number, anotherUnit: string): any {
    if (!this.quantity || !anotherUnit) {
      return unitConverter.parseFloatString(value);
    }

    return unitConverter(this.quantity).convert(value, this.getInternalUnit(), anotherUnit).round().getValue();
  }

  override convertToInternalUnit() {
    const convertedValues = [];

    if (!this.quantity || !this.unit) {
      for (const value of this.values) {
        convertedValues.push(unitConverter.parseFloatString(value));
      }

      return convertedValues;
    }

    for (const value of this.values) {
      convertedValues.push(this.convertValueToInternalUnit(value));
    }

    return convertedValues;
  }

  convertValueToInternalUnit(value: number) {
    return unitConverter(this.quantity).convert(value, this.unit, this.getInternalUnit()).getValue();
  }

  convertToCaptureUnitArray(asNumber: boolean, round: boolean) {
    const convertedValues = [];

    const noUnitOrQuantity = !this.quantity || !this.unit;

    for (const value of this.values) {
      if (noUnitOrQuantity) {
        if (asNumber) {
          convertedValues.push(unitConverter.parseFloatString(value));
        } else {
          convertedValues.push(unitConverter.formatNumber(value));
        }
      } else {
        let conversion = unitConverter(this.quantity).convert(value, this.getInternalUnit(), this.unit);

        if (round) {
          conversion = conversion.round();
        }

        if (asNumber) {
          convertedValues.push(conversion.getValue());
        } else {
          convertedValues.push(conversion.format());
        }
      }
    }

    return convertedValues;
  }

  convertToCaptureUnit(captureUnit: string, internalUnit: string) {
    const convertedValues = [];
    for (const value of this.values) {
      let conversion = unitConverter(this.quantity).convert(value, internalUnit, captureUnit);
      conversion = conversion.round();
      convertedValues.push(conversion.getValue());
    }
    return convertedValues;
  }

  convertToAnotherUnitArray(asNumber: boolean, round: boolean, unit: string) {
    const convertedValues = [];

    for (const value of this.values) {
      if (!this.quantity) {
        if (asNumber) {
          convertedValues.push(unitConverter.parseFloatString(value));
        } else {
          convertedValues.push(unitConverter.formatNumber(value));
        }
      } else {
        let conversion = unitConverter(this.quantity).convert(value, this.getInternalUnit(), unit);
        if (round) {
          conversion = conversion.round();
        }

        if (asNumber) {
          convertedValues.push(conversion.getValue());
        } else {
          convertedValues.push(conversion.format());
        }
      }
    }

    return convertedValues;
  }

  private parseValue(value: number): any {
    return unitConverter.parseFloatString(value);
  }

  override overwriteValues(another: SimulationVariableArray): void {
    // for sim vars, only value, unit and status may be modified
    this.unit = another.unit;

    this.values = [];

    for (const value of another.values) {
      this.values.push(value);
    }

    this.variableStatus = another.variableStatus;
  }

  public override dePersist() {}

  get length() {
    return this.values.length;
  }

  public toJSON() {
    return {
      category: this.category,
      id: this.id,
      values: this.values,
      unit: this.unit,
      quantity: this.quantity,
      variableStatus: this.variableStatus,
    };
  }
}
