import { Component } from '@angular/core';
import { CoreService } from '../../_services/core.service';

@Component({
  selector: 'sob-case-status-indicator',
  templateUrl: './case-status-indicator.component.html',
  styleUrls: ['./case-status-indicator.component.css'],
})
export class CaseStatusIndicatorComponent {
  constructor(private coreService: CoreService) {}

  get currentCase() {
    return this.coreService.currentCase;
  }

  get isMultiPeriodEnabled() {
    return this.currentCase.multiPeriodEnabled;
  }
}
