import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../_services/auth.service';
import { UserService } from '../../_services/user.service';
import { CoreService } from '../../_services/core.service';
import { FlowsheetService } from '../../_services/flowsheet.service';
import { SankeyDiagramService } from '../sankey-diagram/sankey-diagram.service';

@Component({
  selector: 'sob-flowsheet-navbar',
  templateUrl: './flowsheet-navbar.component.html',
  styleUrls: ['./flowsheet-navbar.component.css'],
})
export class FlowsheetNavbarComponent implements OnInit {
  identity: any;
  token: any;
  user: string;
  role: string;
  subscription: Subscription;

  constructor(
    private _accessService: AuthService,
    private _userService: UserService,
    private coreService: CoreService,
    private sankeyService: SankeyDiagramService,
    private flowsheetService: FlowsheetService
  ) {}

  ngOnInit() {
    // identity has the token, userId and the expiration
    this.identity = this._accessService.getIdentity();
    this.token = this._accessService.getToken();
    this.role = this._accessService.getRole();
    if (this.identity != null && this.identity !== undefined && this.token !== undefined && this.token !== null) {
      // TODO bruh
    } else {
      this.user = null;
      this.logout();
    }

    this.subscription = this._accessService.userNameRequest.subscribe((response: string) => {
      this.user = response;
    });

    const user = this._userService.getCurrentUser();
    if (!this.user && user) {
      this.user = `${user.firstName} ${user.lastName}`;
    }
  }

  logout() {
    this._accessService.logout();
  }

  setCurrentCase(id: string) {
    if (id !== this.currentCase.id) {
      if (this.currentCase.getActiveFlowsheet().isSubflowsheet()) {
        this.flowsheetService.exitToMainFlowsheet();
      }
      this.coreService.setCurrentCaseById(id);
      this.sankeyService.caseToSankeyDiagram(this.coreService.currentCase);
    }
  }

  openCaseList() {
    this.flowsheetService.closePropertyWindow();
    $('#caseListModal').modal('show');
  }

  openSearchModal() {
    this.flowsheetService.closePropertyWindow();
    $('#searchModal').modal('show');
    $('#searchModal').data('bs.modal').$backdrop.css('background-color', 'transparent');
    $('#searchModal').on('shown.bs.modal', function () {
      $('#search-bar').focus();
    });
  }

  get currentCase() {
    return this.coreService.currentCase;
  }

  get currentProject() {
    return this.coreService.project;
  }
}
