import { Component } from '@angular/core';
import { saveAs } from 'file-saver/FileSaver';
import { ExcelReportService } from '../../_services/excel-report/excel-report.service';
import { FlowsheetService } from '../../_services/flowsheet.service';
import { CoreService } from '../../_services/core.service';

@Component({
  selector: 'sob-case-study-excel-report',
  templateUrl: './case-study-excel-report.component.html',
  styleUrls: ['./case-study-excel-report.component.css'],
})
export class CaseStudyExcelReportComponent {
  filename: string;

  constructor(
    private xlsxSrv: ExcelReportService,
    private flowsheetService: FlowsheetService,
    private coreService: CoreService
  ) {}

  generateExcel() {
    this.filename = this.filename.replace(/\s/g, '');

    if (this.filename.length > 0) {
      this.downloadExcelReport(this.filename);
      $('#caseStudyExcelReportModal').modal('hide');
      this.filename = '';
    }
  }

  private downloadExcelReport(filename) {
    if (this.currentCase.getActiveFlowsheet().isSubflowsheet()) {
      this.flowsheetService.exitToMainFlowsheet();
    }

    const excelFile = this.xlsxSrv.generateCaseStudyExcelReport();
    saveAs(new Blob([excelFile], { type: 'application/octet-stream' }), `${filename}.xlsx`);
  }

  get currentCase() {
    return this.coreService.currentCase;
  }
}
