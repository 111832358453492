import { Mapper } from '../../../_models/_interfaces/mapper';
import { SuncorUnitOperation } from '../../../_models/_unit-operations/suncor-unit-operation';
import { SagdSource } from '../../../_models/_unit-operations/sagd-source';
import { ArrayToSagdSourceMapper } from './sources/array-to-sagd-source-mapper';
import { DiluentSource } from '../../../_models/_unit-operations/diluent-source';
import { ArrayToDiluentSourceMapper } from './sources/array-to-diluent-source-mapper';
import { MineSource } from '../../../_models/_unit-operations/mine-source';
import { ArrayToMineSourceMapper } from './sources/array-to-mine-source-mapper';
import { ThirdPartySource } from '../../../_models/_unit-operations/third-party-source';
import { ArrayToThirdPartySourceMapper } from './array-to-third-party-source-mapper';
import { OffshoreSource } from '../../../_models/_unit-operations/offshore-source';
import { ArrayToOffshoreSourceMapper } from './sources/array-to-offshore-source-mapper';
import { Upgrader } from '../../../_models/_unit-operations/upgrader';
import { ArrayToUpgraderMapper } from './array-to-upgrader-mapper';
import { Pipe } from '../../../_models/_unit-operations/pipe';
import { ArrayToPipeMapper } from './array-to-pipe-mapper';
import { Refinery } from '../../../_models/_unit-operations/refinery';
import { ArrayToRefineryMapper } from './refineries/array-to-refinery-mapper';
import { ThirdPartyRefinery } from '../../../_models/_unit-operations/third-party-refinery';
import { ArrayToThirdPartyRefineryMapper } from './array-to-third-party-refinery.mapper';

export class ArrayToUnitOperationMapper implements Mapper<string[], SuncorUnitOperation> {
  private NAME_INDEX = 0;

  private readonly arrayToSagdSourceMapper: Mapper<string[], SagdSource>;
  private readonly arrayToDiluentSourceMapper: Mapper<string[], DiluentSource>;
  private readonly arrayToMineSourceMapper: Mapper<string[], MineSource>;
  private readonly arrayToThirdPartySourceMapper: Mapper<string[], ThirdPartySource>;
  private readonly arrayToOffshoreSourceMapper: Mapper<string[], OffshoreSource>;
  private readonly arrayToUpgraderMapper: Mapper<string[], Upgrader>;
  private readonly arrayToPipeMapper: Mapper<string[], Pipe>;
  private readonly arrayToRefineryMapper: Mapper<string[], Refinery>;
  private readonly arrayToThirdPartyRefineryMapper: Mapper<string[], ThirdPartyRefinery>;

  constructor() {
    this.arrayToSagdSourceMapper = new ArrayToSagdSourceMapper();
    this.arrayToDiluentSourceMapper = new ArrayToDiluentSourceMapper();
    this.arrayToMineSourceMapper = new ArrayToMineSourceMapper();
    this.arrayToThirdPartySourceMapper = new ArrayToThirdPartySourceMapper();
    this.arrayToOffshoreSourceMapper = new ArrayToOffshoreSourceMapper();
    this.arrayToUpgraderMapper = new ArrayToUpgraderMapper();
    this.arrayToPipeMapper = new ArrayToPipeMapper();
    this.arrayToRefineryMapper = new ArrayToRefineryMapper();
    this.arrayToThirdPartyRefineryMapper = new ArrayToThirdPartyRefineryMapper();
  }

  map(source: string[], destination: SuncorUnitOperation) {
    if (source[this.NAME_INDEX]) {
      destination.name = source[this.NAME_INDEX];
    }

    if (destination instanceof SagdSource) {
      this.arrayToSagdSourceMapper.map(source, destination);
    } else if (destination instanceof DiluentSource) {
      this.arrayToDiluentSourceMapper.map(source, destination);
    } else if (destination instanceof MineSource) {
      this.arrayToMineSourceMapper.map(source, destination);
    } else if (destination instanceof ThirdPartySource) {
      this.arrayToThirdPartySourceMapper.map(source, destination);
    } else if (destination instanceof OffshoreSource) {
      this.arrayToOffshoreSourceMapper.map(source, destination);
    } else if (destination instanceof Upgrader) {
      this.arrayToUpgraderMapper.map(source, destination);
    } else if (destination instanceof Pipe) {
      this.arrayToPipeMapper.map(source, destination);
    } else if (destination instanceof Refinery) {
      this.arrayToRefineryMapper.map(source, destination);
    } else if (destination instanceof ThirdPartyRefinery) {
      this.arrayToThirdPartyRefineryMapper.map(source, destination);
    }
  }
}
