import { WaterBoiler } from '../../../_unit-operations/utilties/water-boiler';
import { SimulationVariable } from '../../../simulation-variable';
import { SimulationVariableName } from '../../../../_config/simulation-variable-name.enum';
import { ConstraintReportVariable } from '../constraint-report-variable';

export class WaterBoilerConstraintReportVariableResolver {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static create() {
    return new WaterBoilerConstraintReportVariableResolver();
  }

  addCurrentValueAndUnitToConstraintReportVariable(
    crv: ConstraintReportVariable,
    params: { uo: WaterBoiler; simVar: SimulationVariable }
  ): void {
    const { uo, simVar } = params;

    const inletStreams = uo.ownerCase.filterWaterMaterialStreams(s => {
      return s.outletUnitOperationId === uo.id;
    });

    if (!inletStreams.length) {
      return;
    }

    if (simVar.name === SimulationVariableName.MAXIMUM_FEED_CAPACITY) {
      crv.currentValue = inletStreams[0].massFlowrate.convertToDefaultUnit(true, false);
      crv.currentUnit = inletStreams[0].massFlowrate.getDefaultUnit();
      return;
    }
    if (simVar.name === SimulationVariableName.MINIMUM_MASS_FLOWRATE) {
      crv.currentValue = inletStreams[0].massFlowrate.convertToDefaultUnit(true, false);
      crv.currentUnit = inletStreams[0].massFlowrate.getDefaultUnit();
      return;
    }

    throw new Error(`Unknown constraint: ${simVar.name}`);
  }
}
