<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
  </li>
  <li role="presentation">
    <a href="#economics" aria-controls="economics" role="tab" data-toggle="tab">Economics</a>
  </li>

  <li role="presentation">
    <a href="#sulfurPlantComments" aria-controls="sulfurPlantComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg {{ this.unitOperation.comments ? 'gentle-hover-shake' : '' }}" aria-hidden="true">
      </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding" [formGroup]="propertyWindowFormGroup">
  <ng-container>
    <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <div class="form-group" formGroupName="sulfurFlowrate">
            <label class="control-label col-sm-4"> H2S Flowrate </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.sulfurFlowrate.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.sulfurFlowrate">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <h4 class="subtitle">Constraints</h4>

          <div class="form-group" formGroupName="sulfurCapacity">
            <label class="control-label col-sm-4"> Sulfur Capacity </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.sulfurCapacity.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.sulfurCapacity">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <h4 class="subtitle">GHG</h4>

          <div class="form-group" formGroupName="sulfurGHGIntensity">
            <label class="control-label col-sm-4"> Process GHG Intensity (Excluding Fuel Gas) </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.sulfurGHGIntensity.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.sulfurGHGIntensity">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="ghgEmissions">
            <label class="control-label col-sm-4"> GHG Emissions </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.ghgEmissions.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.ghgEmissions">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <h4 class="subtitle">Utilities</h4>
        </div>
        <div class="form-horizontal">
          <sob-reactive-steam-use-utility [formGroupWrapper]="formGroupWrapper" [unitOperation]="unitOperation">
          </sob-reactive-steam-use-utility>
          <sob-reactive-gas-contributor [formGroupWrapper]="formGroupWrapper" [unitOperation]="unitOperation">
          </sob-reactive-gas-contributor>
        </div>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="economics">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <h4 class="subtitle">OPEX</h4>

          <div class="form-group" formGroupName="opexVar">
            <label class="control-label col-sm-4"> Variable </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexVar.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexVar">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexGasDiesel">
            <label class="control-label col-sm-4"> Gas Diesel </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexGasDiesel.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.opexGasDiesel">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexPower">
            <label class="control-label col-sm-4"> Power </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexPower.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexPower">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexCarbonDioxide">
            <label class="control-label col-sm-4"> CO2 </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.opexCarbonDioxide.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.opexCarbonDioxide">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexFixed">
            <label class="control-label col-sm-4"> Fixed </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexFixed.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexFixed">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexTotal">
            <label class="control-label col-sm-4"> Total </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexTotal.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexTotal">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <h4 class="subtitle">CAPEX</h4>

          <div class="form-group" formGroupName="capexAmortized">
            <label class="control-label col-sm-4"> Amortized CAPEX </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capexAmortized.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.capexAmortized">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="capexTotal">
            <label class="control-label col-sm-4"> Total </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capexTotal.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.capexTotal">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div role="tabpanel" class="tab-pane fade in" id="sulfurPlantComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
