import { BaseObject } from '../base-object';
import { Case } from '../case';
import { SimulationVariable } from '../simulation-variable';
import { CutResourceData } from './assay-manager-resource-data';
import { SimulationVariableResourceData } from '../_unit-operations/simulation-variable-resource-data';

declare let unitConverter: any;
export class Cut extends BaseObject {
  category = 'source-cut';
  // TODO: properties should be Simulation Variable!
  ownerBaseObject: BaseObject;
  olefinContent: SimulationVariable;
  weightFraction: SimulationVariable;
  massDensity: SimulationVariable;
  sulfur: SimulationVariable;
  nitrogen: SimulationVariable;
  ccr: SimulationVariable;
  aromaticityFactor: SimulationVariable;
  anilinePoint: SimulationVariable;
  // vabp: SimulationVariable;
  afc: SimulationVariable;
  volumeFraction: SimulationVariable;

  constructor(cut: any, ownerCase: Case, ownerBaseObject: BaseObject) {
    super(cut.id, ownerCase);
    this.ownerBaseObject = ownerBaseObject;
    this.initValues(cut);
  }

  initValues(cut: any) {
    this.olefinContent = new SimulationVariable(cut.olefinContent, this.ownerCase, this);
    this.weightFraction = new SimulationVariable(cut.weightFraction, this.ownerCase, this);
    this.massDensity = new SimulationVariable(cut.massDensity, this.ownerCase, this);
    this.sulfur = new SimulationVariable(cut.sulfur, this.ownerCase, this);
    this.aromaticityFactor = new SimulationVariable(cut.aromaticityFactor, this.ownerCase, this);
    this.anilinePoint = new SimulationVariable(cut.anilinePoint, this.ownerCase, this);
    // this.vabp = new SimulationVariable(cut.vabp, this.ownerCase, this);
    this.afc = new SimulationVariable(cut.afc, this.ownerCase, this);
    this.nitrogen = new SimulationVariable(cut.nitrogen, this.ownerCase, this);
    this.ccr = new SimulationVariable(cut.ccr, this.ownerCase, this);
    this.volumeFraction = new SimulationVariable(cut.volumeFraction, this.ownerCase, this);

    // :v ...
    this.setQuantities();
    if (this.nitrogen.isUndefined()) {
      this.nitrogen.setDefaultValue(null, unitConverter.units.MassFraction.PPMW);
    }
  }

  setQuantities() {
    // TODO add missing quantities
    this.olefinContent.setQuantity('Dimensionless');
    this.weightFraction.setQuantity('Dimensionless'); // <- tbcl
    this.massDensity.setQuantity('LiquidDensity');
    this.sulfur.setQuantity('MassFraction');
    this.aromaticityFactor.setQuantity('Dimensionless');
    this.anilinePoint.setQuantity('Temperature');
    // this.vabp.setQuantity('Temperature');
    this.afc.setQuantity('Dimensionless');
    this.nitrogen.setQuantity('MassFraction');
    this.ccr.setQuantity('MassFraction');
    this.volumeFraction.setQuantity('Dimensionless');
  }

  clearValues() {
    this.olefinContent.value = null;
    this.weightFraction.value = null;
    this.massDensity.value = null;
    this.sulfur.value = null;
    this.aromaticityFactor.value = null;
    this.anilinePoint.value = null;
    // this.vabp.value = null;
    this.afc.value = null;
    this.nitrogen.value = null;
    this.ccr.value = null;
    this.volumeFraction.value = null;
  }

  override dePersist(c: any) {
    if (c) {
      this.olefinContent = this.getSimulationVariableSafe(c.olefinContent);
      this.weightFraction = this.getSimulationVariableSafe(c.weightFraction);
      this.massDensity = this.getSimulationVariableSafe(c.massDensity);
      this.sulfur = this.getSimulationVariableSafe(c.sulfur);
      this.aromaticityFactor = this.getSimulationVariableSafe(c.aromaticityFactor);
      this.anilinePoint = this.getSimulationVariableSafe(c.anilinePoint);
      // this.vabp = this.getSimulationVariableSafe(c.vabp);
      this.afc = this.getSimulationVariableSafe(c.afc);
      this.nitrogen = this.getSimulationVariableSafe(c.nitrogen);
      this.ccr = this.getSimulationVariableSafe(c.ccr);
      this.volumeFraction = this.getSimulationVariableSafe(c.volumeFraction);
      this.setSimulationVariablesOwner();
    }
  }

  clearIds() {
    this.id = (<any>window).uuid();
    const simvars = this.getSimVars();
    simvars.forEach(simvar => {
      simvar.id = (<any>window).uuid();
    });
  }

  loadResourceData(resourceData: CutResourceData) {
    if (resourceData) {
      Object.keys(resourceData).forEach(prop => {
        const simVar = this[prop] as SimulationVariable;
        const data = resourceData[prop] as SimulationVariableResourceData;
        if (simVar && data) {
          simVar.lowerBound = data.lowerBound;
          simVar.upperBound = data.upperBound;
        }
      });
    }
  }

  toJSON() {
    return {
      category: this.category,
      olefinContent: this.olefinContent.id,
      weightFraction: this.weightFraction.id,
      massDensity: this.massDensity.id,
      sulfur: this.sulfur.id,
      aromaticityFactor: this.aromaticityFactor.id,
      anilinePoint: this.anilinePoint.id,
      // vabp: this.vabp.id,
      afc: this.afc.id,
      nitrogen: this.nitrogen.id,
      ccr: this.ccr.id,
      volumeFraction: this.volumeFraction.id,
    };
  }
}
