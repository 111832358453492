import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { Case } from '../_models';
import { BaseObject } from '../_models/base-object';

@Injectable({ providedIn: 'root' })
export class CaseStatsService {
  constructor(private core: CoreService) {}

  findOtherBaseObjectsByCategory(category: string) {
    return this.filterOtherBaseObjects((bo: BaseObject) => bo.category === category);
  }

  filterOtherBaseObjects(callback: (bo: BaseObject) => boolean): BaseObject[] {
    const result: BaseObject[] = [];

    for (const id in this.currentCase.otherBaseObjectPool) {
      if (callback(this.currentCase.otherBaseObjectPool[id])) {
        result.push(this.currentCase.otherBaseObjectPool[id]);
      }
    }

    return result;
  }

  getUnitOperationCount() {
    return Object.keys(this.currentCase.unitOperationPool).length;
  }

  getMaterialStreamCount() {
    return Object.keys(this.currentCase.materialStreamPool).length;
  }

  getOtherBaseObjectCount() {
    return Object.keys(this.currentCase.otherBaseObjectPool).length;
  }

  getSimulationVariableCount() {
    return Object.keys(this.currentCase.simulationVariablePool).length;
  }

  get currentCase(): Case {
    return this.core.currentCase;
  }
}
