import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import { FlexSheetValueConverter } from '../flex-sheet-value-converter';
import { DCU } from '../../../_models/_unit-operations/dcu';
import { DRU } from '../../../_models/_unit-operations/dru';
import { VAC } from '../../../_models/_unit-operations/vac';
import { Hydrocracker } from '../../../_models/_unit-operations/hydrocracker';
import { Hydrotreater } from '../../../_models/_unit-operations/hydrotreater';

export class SteamSheetEventHandler {
  constructor(private valueConverter: FlexSheetValueConverter) {}
  handle(
    unitOperation: DCU | DRU | VAC | Hydrocracker | Hydrotreater,
    spreadsheet: wjcSheet.FlexSheet,
    flexsheetEvent,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    rawValue
  ) {
    flexsheetEvent.cancel = true;
    return false;
  }
}
