<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a href="#waterHeatExchangerComments" aria-controls="waterHeatExchangerComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Inputs</h4>
        <hr />
        <br />

        <div class="form-group">
          <label [attr.for]="'configuration'" class="pw-label control-label col-sm-4"> Configuration </label>

          <div class="col-md-8">
            <select
              id="configurationMode"
              class="form-control"
              (change)="configurationModeChanged($event.target.value)">
              <option value="{{ configuration.COUNTER_CURRENT }}">Counter-current</option>
              <option value="{{ configuration.CO_CURRENT }}">Co-current</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label [attr.for]="'temperatureCalculationMode'" class="pw-label control-label col-sm-4">
            Temperature Calculation Mode
          </label>

          <div class="col-md-8" id="temperatureCalculationMode">
            <select
              id="selectMode"
              (change)="temperatureCalculationModeChanged($event.target.value)"
              class="form-control">
              <option value="{{ temperatureCalculationMode.OUTLET_TEMPERATURE_TUBE }}">
                Outlet Temperature - Tube
              </option>
              <option value="{{ temperatureCalculationMode.OUTLET_TEMPERATURE_SHELL }}">
                Outlet Temperature - Shell
              </option>
              <option value="{{ temperatureCalculationMode.UA }}">UA</option>
            </select>
          </div>
        </div>

        <div class="form-group" id="outlet-temperature">
          <label [attr.for]="'outletTemperature'" class="pw-label control-label col-sm-4"> Outlet Temperature </label>
          <sim-var-input [inputId]="'outletTemperature'" [simVar]="unitOperation.outletTemperature"></sim-var-input>
        </div>

        <div class="form-group" id="ua-temperature">
          <label [attr.for]="'Ua'" class="pw-label control-label col-sm-4"> UA </label>
          <sim-var-input [inputId]="'Ua'" [simVar]="unitOperation.ua"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'pressureDropTube'" class="pw-label control-label col-sm-4"> Pressure Drop - Tube </label>
          <sim-var-input [inputId]="'pressureDropTube'" [simVar]="unitOperation.pressureDropTube"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'pressureDropShell'" class="pw-label control-label col-sm-4">
            Pressure Drop - Shell
          </label>
          <sim-var-input [inputId]="'pressureDropShell'" [simVar]="unitOperation.pressureDropShell"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'geometryFactor'" class="pw-label control-label col-sm-4"> Geometry Factor </label>
          <sim-var-input [inputId]="'geometryFactor'" [simVar]="unitOperation.geometryFactor"></sim-var-input>
        </div>

        <br /><br /><br /><br />
        <div class="form-horizontal">
          <h4 class="subtitle">Results</h4>
          <hr />
          <br />

          <div class="form-group">
            <label [attr.for]="'duty'" class="pw-label control-label col-sm-4"> Duty </label>
            <sim-var-input [inputId]="'duty'" [readOnly]="true" [simVar]="unitOperation.duty"></sim-var-input>
          </div>

          <div class="form-group">
            <label [attr.for]="'uaResult'" class="pw-label control-label col-sm-4"> UA </label>
            <sim-var-input [inputId]="'uaResult'" [readOnly]="true" [simVar]="unitOperation.uaResult"></sim-var-input>
          </div>

          <div class="form-group">
            <label [attr.for]="'lmtd'" class="pw-label control-label col-sm-4"> LMTD </label>
            <sim-var-input [inputId]="'lmtd'" [readOnly]="true" [simVar]="unitOperation.lmtd"></sim-var-input>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="waterHeatExchangerComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
