import * as wjcCore from '@grapecity/wijmo';

import { FlexSheetCellConfig } from './flex-sheet-cell-config';

export enum UpgraderSheetRows {
  NAME = 0,
  CCR_REMOVAL = 3,
  NITROGEN_REMOVAL = 4,
  SULFUR_REMOVAL = 5,
  SWEET_PRODUCT_RATIO = 6,
  SWEET_GHG_INTENSITY = 7,
  SOUR_GHG_INTENSITY = 8,
  CAPACITY = 9,
  COGEN_ELECTRICITY_GENERATION = 11,
  NET_ELECTRICITY_EXPORT = 12,
  COGEN_INTENSITY = 13,
  GRID_INTENSITY = 14,
  SWEET_PRODUCT_PRICE = 17,
  SOUR_PRODUCT_PRICE = 18,
  CAPEX_AMORTIZED = 19,
  OPEX_FIXED = 20,
  OPEX_VAR = 21,
  OPEX_GAS_DIESEL = 22,
  OPEX_POWER = 23,
  TOTAL_H2S_PRODUCTION = 26,
  DEA_USE_RATE = 27,
  AMINE_CIRCULATION_RATE = 28,
  AMINE_RATE_CAPACITY = 29,
}

export const UpgraderCellConfig: FlexSheetCellConfig[] = [
  {
    row: UpgraderSheetRows.NAME,
    dataType: wjcCore.DataType.String,
    format: '',
  },
  {
    row: UpgraderSheetRows.CCR_REMOVAL,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.NITROGEN_REMOVAL,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.SULFUR_REMOVAL,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.SWEET_PRODUCT_RATIO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.SWEET_GHG_INTENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.SOUR_GHG_INTENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.CAPACITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.COGEN_ELECTRICITY_GENERATION,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.NET_ELECTRICITY_EXPORT,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.COGEN_INTENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.GRID_INTENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.SWEET_PRODUCT_PRICE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.SOUR_PRODUCT_PRICE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.CAPEX_AMORTIZED,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.OPEX_FIXED,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.OPEX_VAR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.OPEX_GAS_DIESEL,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.OPEX_POWER,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.TOTAL_H2S_PRODUCTION,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.DEA_USE_RATE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.AMINE_CIRCULATION_RATE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: UpgraderSheetRows.AMINE_RATE_CAPACITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
];
