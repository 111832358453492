import * as wjGrid from '@grapecity/wijmo.grid';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import { H2sSpreadsheetModel } from '../../../_models/utilities-spreadsheet/h2s-spreadsheet.model';
import { NumberToUnitConverter } from '../../number-to-unit-converter.service';
import { H2sFirstSectionUnitOperation, H2sSheetUtils } from './h2s-sheet-utils';
import { H2sSheetItemDataShifter } from './h2s-sheet-item-data-shifter.service';
import { Case, UnitOperation } from '../../../_models';
import { H2sSheetColumnsConfiguration } from './h2s-sheet-columns.configuration';
import { SpreadsheetTitle } from '../../../_config/spreadsheet/spreadsheet-title.enum';
import { SuncorUnitOperation } from '../../../_models/_unit-operations/suncor-unit-operation';
import { VAC } from '../../../_models/_unit-operations/vac';
import { Hydrocracker } from '../../../_models/_unit-operations/hydrocracker';
import { Hydrotreater } from '../../../_models/_unit-operations/hydrotreater';
import { DCU } from '../../../_models/_unit-operations/dcu';
import { HgoHydrotreater } from '../../../_models/_unit-operations/hgo-hydrotreater';
import { FluidCoker } from '../../../_models/_unit-operations/fluid-coker';
import { LgoHydrotreater } from '../../../_models/_unit-operations/lgo-hydrotreater';
import { SulfurPlant } from '../../../_models/_unit-operations/sulfur-plant';
import { GasContributorUnitOperation } from '../../../_models/_unit-operations/gas-contributor-unit-operation';
import { H2sItemType } from '../../../_config/spreadsheet/h2s-item-type.enum';
import { H2sItemTitle } from '../../../_config/spreadsheet/h2s-item-title.enum';
import { hasNumericValue } from '../../../_utils/utils';
import { Quantity } from '../../../_config/quantity.enum';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';

declare let unitConverter: any;

export class UpgraderH2sSheetHandler {
  protected grid: wjGrid.FlexGrid;
  items: H2sSpreadsheetModel[];
  protected sheetIndex: number;
  private sheetName: string;
  private h2sUtils: H2sSheetUtils;
  private h2sItemShifter: H2sSheetItemDataShifter;

  private ownerUnitOperationId: string;

  constructor(private ownerUnitOperation: UnitOperation, private nuc: NumberToUnitConverter) {
    this.items = [];
    this.h2sUtils = new H2sSheetUtils(this.items, this.nuc);
    this.h2sItemShifter = new H2sSheetItemDataShifter(this.items, this.h2sUtils);
    this.ownerUnitOperationId = ownerUnitOperation.id;
  }

  init(spreadsheet: wjcSheet.FlexSheet, c: Case, index: number) {
    this.grid = new wjGrid.FlexGrid(document.createElement('div'), {
      autoGenerateColumns: false,
      columns: H2sSheetColumnsConfiguration.get(),
      frozenRows: 1,
    });

    this.h2sUtils.clearSpreadsheetItems();
    const data = this.createCollectionView();
    this.setSheetIndex(spreadsheet);
    this.sheetName = `${this.ownerUnitOperation.name} - ${SpreadsheetTitle.ACID_GAS_SULFUR}`;

    spreadsheet.addBoundSheet(this.sheetName, data, this.sheetIndex + index, this.grid);
  }

  protected createCollectionView() {
    return new CollectionView(this.items);
  }

  caseToSpreadsheet(c: Case): void {
    for (const id in c.unitOperationPool) {
      const uo = c.unitOperationPool[id] as SuncorUnitOperation;
      if (this.isUnitOperationAllowed(uo)) {
        this.addUnitOperationWithoutRefreshing(uo);
      }
    }

    // add the totals
    if (c.isSolved) {
      const grandTotal = this.calculateTotals(c);
      const firstSectionTotal = this.getFirstSectionFreeItem();
      const secondSectionTotal = this.getSecondSectionFreeItem();

      this.h2sUtils.copyUnitOperationItemData(grandTotal, firstSectionTotal);
      this.h2sUtils.copySulfurPlantItemData(grandTotal, secondSectionTotal);
    }
  }

  addUnitOperation(uo: UnitOperation): void {
    if (this.isUnitOperationAllowed(uo)) {
      this.addUnitOperationWithoutRefreshing(uo);
      this.grid.collectionView.refresh();
    }
  }

  updateUnitOperation(uo: UnitOperation): void {
    if (!this.isUnitOperationAllowed(uo)) {
      return;
    }

    const item = this.h2sUtils.findItemByUnitOperationIdOrSulfurPlantId(uo.id);

    if (item) {
      if (this.unitOperationBelongsToFirstSection(uo)) {
        this.h2sUtils.addUnitOperationToSpreadsheetItem(uo as GasContributorUnitOperation, item);
      } else if (this.unitOperationBelongsToSecondSection(uo)) {
        this.h2sUtils.addSulfurPlantToSpreadsheetItem(uo as SulfurPlant, item);
      }

      this.grid.collectionView.refresh();
    }
  }

  removeUnitOperation(uo: UnitOperation): void {
    if (!this.isUnitOperationAllowed(uo)) {
      return;
    }

    const item = this.h2sUtils.findItemByUnitOperationIdOrSulfurPlantId(uo.id);
    const belongsToFirstSection = this.unitOperationBelongsToFirstSection(uo);

    if (belongsToFirstSection) {
      this.h2sUtils.clearUnitOperationData(item);
    } else {
      this.h2sUtils.clearSulfurPlantData(item);
    }

    // if no unit operation was left in the row...
    if (this.h2sUtils.isItemEmpty(item)) {
      this.h2sUtils.removeItem(item);
    } else {
      this.h2sItemShifter.shiftItemData(item, belongsToFirstSection);
    }

    this.grid.collectionView.refresh();
  }

  isUnitOperationAllowed(uo: UnitOperation): boolean {
    return (
      (this.unitOperationBelongsToFirstSection(uo) || this.unitOperationBelongsToSecondSection(uo)) &&
      uo.flowsheetId === this.ownerUnitOperationId
    );
  }

  unitOperationBelongsToFirstSection(uo: UnitOperation): boolean {
    return (
      uo instanceof VAC ||
      uo instanceof Hydrocracker ||
      uo instanceof Hydrotreater ||
      uo instanceof DCU ||
      uo instanceof HgoHydrotreater ||
      uo instanceof FluidCoker ||
      uo instanceof LgoHydrotreater
    );
  }

  unitOperationBelongsToSecondSection(uo: UnitOperation): uo is SulfurPlant {
    return uo instanceof SulfurPlant;
  }

  addUnitOperationWithoutRefreshing(uo: UnitOperation): void {
    if (this.unitOperationBelongsToFirstSection(uo)) {
      this.addUnitOperationToFirstSection(uo as GasContributorUnitOperation);
    } else if (this.unitOperationBelongsToSecondSection(uo)) {
      this.addSulfurPlantToSecondSection(uo);
    }
  }

  clearResults(): void {
    for (const item of this.items) {
      item.h2sProduction = undefined;
      item.sulfurFlowrate = undefined;

      if (item.unitCategory === unitOperationsConfig.lgoHydrotreater.key && item.useCustomModel) {
        item.h2sProductionRate = undefined;
      }
    }

    const totals = this.items.filter(i => i.id === H2sItemType.TOTAL || i.sulfurPlantId === H2sItemType.TOTAL);

    for (const t of totals) {
      const index = this.items.indexOf(t);
      if (t.id !== H2sItemType.TOTAL) {
        this.items[index].sulfurCapacity = undefined;
        this.items[index].sulfurPlantName = undefined;
        this.items[index].sulfurPlantId = undefined;
      } else {
        this.items.splice(index, 1);
      }
    }
  }

  addUnitOperationToFirstSection(uo: GasContributorUnitOperation): void {
    const item = this.getFirstSectionFreeItem();
    this.h2sUtils.addUnitOperationToSpreadsheetItem(uo, item);
  }

  addSulfurPlantToSecondSection(uo: SulfurPlant): void {
    const item = this.getSecondSectionFreeItem();
    this.h2sUtils.addSulfurPlantToSpreadsheetItem(uo, item);
  }

  getFirstSectionFreeItem(): H2sSpreadsheetModel {
    let freeItem = this.items.find(i => this.firstSectionItemIsFree(i));

    if (!freeItem) {
      const newItem = {};
      this.items.push(newItem);
      freeItem = newItem;
    }
    return freeItem;
  }

  getSecondSectionFreeItem(): H2sSpreadsheetModel {
    let freeItem = this.items.find(i => this.secondSectionItemIsFree(i));

    if (!freeItem) {
      const newItem = {};
      this.items.push(newItem);
      freeItem = newItem;
    }
    return freeItem;
  }

  firstSectionItemIsFree(item: H2sSpreadsheetModel): boolean {
    return !item.id;
  }

  secondSectionItemIsFree(item: H2sSpreadsheetModel): boolean {
    return !item.sulfurPlantId;
  }

  calculateTotals(c: Case): H2sSpreadsheetModel {
    const total: H2sSpreadsheetModel = {
      id: H2sItemType.TOTAL,
      unitOperationName: H2sItemTitle.TOTAL,
      h2sProductionRate: undefined,
      h2sProduction: 0,
      sulfurPlantId: H2sItemType.TOTAL,
      sulfurPlantName: H2sItemTitle.TOTAL,
      sulfurFlowrate: 0,
      sulfurCapacity: 0,
    };

    for (const id in c.unitOperationPool) {
      const uo = c.unitOperationPool[id] as SuncorUnitOperation;
      if (uo.flowsheetId === this.ownerUnitOperationId) {
        if (this.unitOperationBelongsToFirstSection(uo)) {
          const h2sUo = uo as H2sFirstSectionUnitOperation;
          total.h2sProduction += hasNumericValue(h2sUo.h2sProduction.value) ? h2sUo.h2sProduction.value : 0;
        } else if (this.unitOperationBelongsToSecondSection(uo)) {
          total.sulfurFlowrate += hasNumericValue(uo.sulfurFlowrate.value) ? uo.sulfurFlowrate.value : 0;
          total.sulfurCapacity += hasNumericValue(uo.sulfurCapacity.value) ? uo.sulfurCapacity.value : 0;
        }
      }
    }

    total.h2sProduction = this.nuc.convert(
      total.h2sProduction,
      Quantity.MOLEFLOWRATE,
      unitConverter.units.Moleflowrate.MMSCFD
    );

    total.sulfurFlowrate = this.nuc.convert(
      total.sulfurFlowrate,
      Quantity.MOLEFLOWRATE,
      unitConverter.units.Moleflowrate.MMSCFD
    );

    total.sulfurCapacity = this.nuc.convert(
      total.sulfurCapacity,
      Quantity.MOLEFLOWRATE,
      unitConverter.units.Moleflowrate.MMSCFD
    );

    return total;
  }

  /**
   * Returns true if this sheet belongs to the unit operation represented by the id
   * @param id
   */
  belongsToUnitOperation(id: string) {
    return this.ownerUnitOperationId === id;
  }

  setSheetIndex(spreadsheet: wjcSheet.FlexSheet) {
    let lastHydrogenSheetIdx = null;
    spreadsheet.sheets.forEach((sheet, idx) => {
      if (sheet.name.includes(SpreadsheetTitle.HYDROGEN)) {
        lastHydrogenSheetIdx = idx;
      }
    });
    this.sheetIndex = lastHydrogenSheetIdx + 1;
  }
}
