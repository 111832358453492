import { WaterMaterialStream } from '../_streams/water-material-stream';

declare let unitConverter: any;

export class SankeyWaterMaterialStream {
  id: string;
  from: number;
  to: number;
  name: string;
  massFlowrate: number;
  massFlowrateUnit: string;
  width: number;

  constructor(s: WaterMaterialStream) {
    this.id = s.id;
    this.from = s.inletUnitOperation.key;
    this.to = s.outletUnitOperation.key;
    this.name = s.name || s.connectionName;
    this.massFlowrateUnit = unitConverter.units.Massflowrate.KPPH;
    this.massFlowrate = s.massFlowrate.convertToAnotherUnit(this.massFlowrateUnit, false);
    this.width = this.massFlowrate > 700 ? 700 : this.massFlowrate;
  }
}
