<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a href="#waterSplitterComments" aria-controls="waterSplitterComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg {{ this.unitOperation.comments ? 'gentle-hover-shake' : '' }}" aria-hidden="true">
      </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding" [formGroup]="propertyWindowFormGroup">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group m-b-md">
          <label for="isOptimizable" class="control-label col-sm-4"> Optimize </label>

          <div class="col-sm-4">
            <label for="isOptimizable" class="control-label">
              <input
                type="checkbox"
                id="isOptimizable"
                formControlName="isOptimizable"
                (change)="isOptimizableChanged($event.target.checked)" />
            </label>
          </div>
        </div>

        <label class="control-label col-sm-4"> Stream Name </label>

        <label class="control-label col-sm-6"> Distribution Ratio </label>

        <br />
        <br />
        <br />

        <div
          class="form-group"
          formArrayName="distributionRatioVariables"
          *ngFor="let dv of distributionRatioVariablesFormArray.controls; let i = index">
          <label for="distributionRatio{{ i }}" class="pw-label control-label col-sm-4">
            {{ findDistributionRatioName(i) }}
          </label>

          <div class="col-sm-8" formGroupName="{{ i }}">
            <input
              type="text"
              class="form-control"
              id="distributionRatio{{ i }}"
              formControlName="value"
              (change)="roundDistributionVariable(i)"
              [readonly]="i === distributionRatioVariablesFormArray.controls.length - 1" />
            <span class="text-danger" *ngIf="shouldShowValidationError(getDistributionRatioVariableValueControl(i))">
              <span *ngIf="getDistributionRatioVariableValueControl(i).errors?.required">Please enter a value</span>
              <span *ngIf="getDistributionRatioVariableValueControl(i).errors?.max"
                >Distribution ratio cannot be greater than 1</span
              >
              <span *ngIf="getDistributionRatioVariableValueControl(i).errors?.min"
                >Distribution ratio cannot be negative</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="waterSplitterComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
