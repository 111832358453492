import { Component, Input } from '@angular/core';
import { ThirdPartyRefinery } from '../../../_models/_unit-operations/third-party-refinery';

@Component({
  selector: 'sob-third-party-refinery',
  templateUrl: './third-party-refinery.component.html',
  styleUrls: ['./third-party-refinery.component.css'],
})
export class ThirdPartyRefineryComponent {
  @Input()
  unitOperation: ThirdPartyRefinery;
}
