import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import { GhgEmissionsReport } from '../../../_models/_reports/ghg-emissions-report';
import { GhgReportValue, MixedGhgReportValue } from '../../../_models/_reports/report-value';
import { ReportDataSet } from '../../../_models/_reports/report-data-set';

declare let unitConverter: any;

@Component({
  selector: 'sob-ghg-summary-report',
  templateUrl: './ghg-summary-report.component.html',
  styleUrls: ['./ghg-summary-report.component.css'],
})
export class GhgSummaryReportComponent {
  ghgEmissions: GhgEmissionsReport;
  subFlowsheetDataActive: boolean;
  currentSubFlowsheetDataset: ReportDataSet;

  buildReport(ghgEmissions: GhgEmissionsReport): void {
    this.ghgEmissions = ghgEmissions;
    this.subFlowsheetDataActive = false;
    this.currentSubFlowsheetDataset = undefined;

    this.buildTopLevelTable();
    this.buildPieChart();

    $('a[href="#srGhgEmissionsReportChartTab"]').tab('show');
  }

  buildTopLevelTable(): void {
    const tableData = [];

    for (const cu of this.ghgEmissions.contributionByUnit) {
      for (const cv of cu.data) {
        tableData.push({ name: cv.name, category: cu.name, value: cv.value });
      }
    }

    this.buildTable(tableData);
  }

  buildSubFlowsheetTable() {
    const tableData = [];
    for (const cv of this.currentSubFlowsheetDataset.data) {
      const reportValue = cv as MixedGhgReportValue;
      tableData.push({ name: reportValue.name, category: reportValue.unitOperationCategory, value: reportValue.value });
    }

    this.buildTable(tableData);
  }

  buildTable(tableData): void {
    const $table = $('#srGhgEmissionsTable');

    this.destroyDataTable();

    const opts = {
      data: tableData,
      paging: false,
      searching: false,
      info: false,
      order: [[2, 'desc']],
      columns: [
        {
          data: 'name',
        },
        {
          data: 'category',
        },
        {
          data: (data, type) => {
            if (type === 'display') {
              return `${unitConverter.formatNumber(data.value)} ${this.ghgEmissions.unit}`;
            }
            return data.value;
          },
        },
      ],
    };

    (<any>$table).DataTable(<any>opts);
  }

  destroyDataTable(): void {
    const $table = $('#srGhgEmissionsTable');

    if ((<any>$.fn).dataTable.isDataTable('#srGhgEmissionsTable')) {
      (<any>$table).DataTable().destroy();
    }
  }

  buildPieChart(): void {
    const series = {
      name: 'GHG emissions',
      colorByPoint: true,
      data: this.ghgEmissions.contributionByCategory.map(cv => {
        return { y: cv.value, name: cv.name, drilldown: cv.childDataId };
      }),
    };

    const drilldownSeries = this.ghgEmissions.contributionByUnit.map(cu => {
      return {
        name: cu.name,
        id: cu.id,
        data: cu.data.map(cv => {
          const ghgReportValue = cv as GhgReportValue;
          const aGhgUnit = ghgReportValue.alternativeGhgIntensity.getDefaultUnit();
          return {
            name: ghgReportValue.name,
            y: ghgReportValue.value,
            alternativeGhgIntensity: ghgReportValue.alternativeGhgIntensity.convertToDefaultUnit(false, false),
            alternativeGhgIntensityUnit: aGhgUnit === 'kg/bbl' ? 'kgCO2e/bbl' : aGhgUnit,
            drilldown: ghgReportValue.childDataId ? ghgReportValue.childDataId : undefined,
            isFlowsheetOwner: ghgReportValue.isFlowsheetOwner,
          };
        }),
      };
    });

    drilldownSeries.push(
      ...this.ghgEmissions.contributionByUpgraderSubFlowsheet.map(cu => {
        return {
          name: cu.name,
          id: cu.id,
          data: cu.data.map(cv => {
            return {
              name: cv.name,
              y: cv.value,
              alternativeGhgIntensity: undefined,
              alternativeGhgIntensityUnit: undefined,
              drilldown: cv.childDataId ? cv.childDataId : undefined,
              isFlowsheetOwner: false,
            };
          }),
        };
      })
    );

    const chartOptions = this.getGhgDrilldownChartOptions(
      'GHG Emissions',
      this.ghgEmissions.unit,
      series,
      drilldownSeries
    );

    Highcharts.chart('srGhgEmissionsChart', <any>chartOptions);
  }

  getGhgDrilldownChartOptions(title: string, unit: string, series, drilldownSeries) {
    const that = this;
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        events: {
          drillup() {
            that.hideSubFlowsheetData();
          },
        },
      },

      credits: {
        enabled: false,
      },

      title: {
        text: title,
      },

      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: `{point.name}: {point.y:.2f} ${unit}`,
          },
        },
        pie: {
          events: {
            click(e) {
              const { point } = e;
              if (point.isFlowsheetOwner) {
                that.showSubFlowsheetData(point.drilldown);
              }
            },
          },
        },
      },
      tooltip: {
        formatter() {
          let formattedTooltip;
          if (this.point.alternativeGhgIntensityUnit) {
            formattedTooltip =
              `<span style="font-size:11px">${this.point.name}</span><br>` +
              `<span style="color:${this.color}">GHG Emissions</span>: <b>${this.y} ${unit}</b><br/>` +
              `<span style="color:${this.color}">GHG Intensity</span>: <b>${Highcharts.numberFormat(
                this.point.alternativeGhgIntensity,
                2,
                '.',
                ','
              )} ${this.point.alternativeGhgIntensityUnit}</b><br/>`;
          } else {
            formattedTooltip =
              `<span style="font-size:11px">${this.series.name}</span><br>` +
              `<span style="color:${this.color}">${this.point.name}</span>: <b>${this.y} ${unit}</b><br/>`;
          }
          return formattedTooltip;
        },
      },
      series: [series],

      drilldown: {
        series: drilldownSeries,
      },
    };
  }

  showSubFlowsheetData(subFlowsheetId: string) {
    this.currentSubFlowsheetDataset = this.ghgEmissions.contributionByUpgraderSubFlowsheet.find(
      d => d.id === subFlowsheetId
    );
    this.subFlowsheetDataActive = true;

    this.buildSubFlowsheetTable();
    this.buildWaterfallChart(this.ghgEmissions.unit);
  }

  hideSubFlowsheetData() {
    this.subFlowsheetDataActive = false;
    this.currentSubFlowsheetDataset = undefined;
    this.buildTopLevelTable();
  }

  buildWaterfallChart(unit: string) {
    const series = [
      {
        color: Highcharts.getOptions().colors[8],
        name: 'GHG Emissions',
        data: this.currentSubFlowsheetDataset.data
          .filter(cv => cv.value !== 0)
          .sort((a, b) => (a.value > b.value ? -1 : 1))
          .map(cv => {
            return {
              name: cv.name,
              y: cv.value,
            };
          }),
      },
    ];

    series.push(this.getTotalEmissionsBar());

    Highcharts.chart('srGhgEmissionsWaterfallChart', this.getWaterfallChartOptions(series, unit));
  }

  getTotalEmissionsBar() {
    return {
      color: Highcharts.getOptions().colors[0],
      name: 'GHG Emissions',
      data: [
        {
          name: 'Total Emissions',
          y: this.currentSubFlowsheetDataset.total,
          color: Highcharts.getOptions().colors[0],
        },
      ],
    };
  }

  getWaterfallChartOptions(series: any, unit: string) {
    return {
      chart: {
        type: 'waterfall',
      },
      title: {
        text: 'GHG Emissions',
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: unit,
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        waterfall: {
          dataLabels: {
            enabled: true,
            inside: false,
            color: '#000',
          },
          pointPadding: 0,
          borderWidth: 0,
        },
      },
      tooltip: {
        formatter() {
          return `<span style="font-size:11px">${this.series.name}</span><br>
            <span style="color: ${this.color}">${this.point.name}</span>: <b>${this.y} ${unit}</b><br/>`;
        },
      },

      series,
    };
  }
}
