<ul class="nav nav-pills" role="tablist">
  <li role="presentation" class="active">
    <a href="#srGhgEmissionsReportChartTab" role="tab" data-toggle="tab" aria-controls="srGhgEmissionsReportChartTab">
      Pie Chart
    </a>
  </li>

  <li role="presentation">
    <a href="#srGhgEmissionsReportTableTab" role="tab" data-toggle="tab" aria-controls="srGhgEmissionsReportTableTab">
      Table
    </a>
  </li>

  <li role="presentation" *ngIf="subFlowsheetDataActive">
    <a href="#srGhgEmissionsWaterfallTab" role="tab" data-toggle="tab" aria-controls="srGhgEmissionsReportTable">
      Waterfall
    </a>
  </li>
</ul>

<div class="tab-content tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="srGhgEmissionsReportChartTab">
    <div id="srGhgEmissionsChart"></div>

    <div class="row" style="margin-top: 20px">
      <div class="col-xs-12 text-center">
        <ng-container *ngIf="!subFlowsheetDataActive">
          <strong>Total GHG Emissions</strong>
          {{ ghgEmissions?.totalGhg | thousandSeparator }} {{ ghgEmissions?.unit }}
        </ng-container>

        <ng-container *ngIf="subFlowsheetDataActive">
          <strong>Total GHG Emissions</strong>
          {{ currentSubFlowsheetDataset?.total | thousandSeparator }} {{ ghgEmissions?.unit }}
        </ng-container>
      </div>
    </div>

    <div class="row" style="margin-top: 20px" *ngIf="!subFlowsheetDataActive">
      <div class="col-xs-12 text-center">
        <strong>Suncor GHG Emissions</strong> {{ ghgEmissions?.totalSuncorGhg | thousandSeparator }}
        {{ ghgEmissions?.unit }}
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="srGhgEmissionsReportTableTab">
    <div class="row">
      <div class="col-xs-12">
        <table class="table" id="srGhgEmissionsTable" style="width: 100%">
          <thead>
            <tr>
              <th>Unit Operation</th>
              <th>Type</th>
              <th>GHG Emissions</th>
            </tr>
          </thead>

          <tfoot>
            <tr>
              <th colspan="2" class="text-right">Total</th>
              <th>
                <span *ngIf="!subFlowsheetDataActive"
                  >{{ ghgEmissions?.totalGhg | thousandSeparator }} {{ ghgEmissions?.unit }}</span
                >
                <span *ngIf="subFlowsheetDataActive"
                  >{{ this.currentSubFlowsheetDataset?.total | thousandSeparator }} {{ ghgEmissions?.unit }}</span
                >
              </th>
            </tr>
            <tr *ngIf="!subFlowsheetDataActive">
              <th colspan="2" class="text-right">Total Suncor</th>
              <th>{{ ghgEmissions?.totalSuncorGhg | thousandSeparator }} {{ ghgEmissions?.unit }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="srGhgEmissionsWaterfallTab">
    <div id="srGhgEmissionsWaterfallChart"></div>
  </div>
</div>
