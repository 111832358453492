import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';

@Component({
  selector: 'sob-composition',
  templateUrl: './composition.component.html',
  styleUrls: ['./composition.component.css'],
})
export class CompositionComponent implements OnInit {
  @Input() currentAnalysis: SuncorFluidAnalysis;
  compositionFormGroup: UntypedFormGroup;
  total: number;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.compositionFormGroup = this.fb.group({});
    this.addControls();
  }

  addControls() {
    this.compositionFormGroup.addControl('compositionUnit', this.fb.control(this.currentAnalysis.composition[0].unit));

    const compositionArray = this.fb.array([]);
    this.total = 0;
    for (const sv of this.currentAnalysis.composition) {
      this.total += sv.convertToCaptureUnit(true);
      compositionArray.push(this.fb.control({ value: sv.value, disabled: true }));
    }

    this.compositionFormGroup.addControl('composition', compositionArray);
    this.compositionFormGroup.addControl('compositionTotal', this.fb.control(this.total));
    this.compositionFormGroup.controls['compositionTotal'].disable();
    this.compositionFormGroup.controls['compositionUnit'].disable();
  }

  updateControls() {
    this.compositionFormGroup.get('compositionUnit').setValue(this.currentAnalysis.composition[0].unit);
    this.compositionFormGroup.get('compositionTotal').setValue(this.total);
    const compArray = this.compositionFormGroup.get('composition') as UntypedFormArray;
    compArray.controls.forEach((sv, index) => {
      sv.setValue(this.currentAnalysis.composition[index].value);
    });
  }
}
