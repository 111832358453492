<div class="row border-bottom" id="navbar-wrapper">
  <nav class="navbar navbar-static-top" id="flowsheet-navbar" role="navigation" style="margin-bottom: 0">
    <div class="inline logo">
      <img src="/assets/img/suncor-optea-logo.png" alt="suncor logo" />
    </div>

    <div class="inline">
      <span class="page-title">
        <i class="fa fa-chevron-right"></i>
      </span>
      <!--      <span class="dropdown-container">-->
      <!--       <select #caseSelector class="form-control control-xs" (change)="setCurrentCase(caseSelector.value)">-->
      <!--                 <option [value]="c.id" *ngFor="let c of currentProject.cases"-->
      <!--                         selected="{{c.id === currentCase.id ? 'selected' : ''}}"> {{c.name ? c.name : 'Unnamed'}}-->
      <!--                 </option>-->
      <!--       </select>-->
      <!--      </span>-->
      <div class="dropdown inline dropdown-container" style="">
        <h2 class="dropdown-toggle" data-toggle="dropdown">
          {{ currentCase.name }}
          <span class="caret"></span>
        </h2>

        <ul class="dropdown-menu" style="">
          <ng-container *ngFor="let c of currentProject.cases">
            <li class="{{ c.id === currentCase.id ? 'active' : '' }}" (click)="setCurrentCase(c.id)">
              <a href="javascript:void(0);">
                {{ c.name }}
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
      <a
        title="Change case name"
        href="javascript:void(0);"
        (click)="openCaseList()"
        class="btn btn-sm btn-white"
        style="margin-left: 20px"
        ><i class="fa fa-pencil"></i
      ></a>
    </div>
    <div class="pull-right">
      <a id="search-btn" (click)="openSearchModal()" class="btn btn-white btn-rounded btn-sm"
        ><i class="fa fa-search"></i
      ></a>
      <sob-flowsheet-view-selector></sob-flowsheet-view-selector>
      <ul class="nav navbar-top-links" style="display: inline-block">
        <li class="dropdown">
          <a class="dropdown-toggle count-info" data-toggle="dropdown" href="#" aria-expanded="false">
            <i class="fa fa-user"></i> {{ user }} <i class="fa fa-angle-down"></i>
          </a>
          <ul class="dropdown-menu dropdown-alerts">
            <li>
              <a (click)="logout()"> <i class="fa fa-sign-out"></i> Log out </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</div>
