import { SimulationVariable } from '../simulation-variable';
import { BaseObject } from '../base-object';
import { Case } from '../case';

export enum RenewableTypes {
  WIND = 'Wind',
  SOLAR = 'Solar',
  OFFSET = 'Offset',
}

export class SuncorRenewable extends BaseObject {
  category = 'suncorRenewable';
  ownerBaseObject: BaseObject;

  renewableType: RenewableTypes;
  capacity: SimulationVariable;
  capacityFactor: SimulationVariable;
  ghgCredit: SimulationVariable;
  opex: SimulationVariable;
  amortizedCapex: SimulationVariable;
  electricityPrice: SimulationVariable;

  constructor(renewable: any, ownerBaseObject: BaseObject, ownerCase: Case) {
    super(renewable.id, ownerCase);
    this.ownerBaseObject = ownerBaseObject;
    this.initValues(renewable);
  }

  initValues(renewable: any) {
    this.name = renewable.name || '';
    this.renewableType = renewable.renewableType || RenewableTypes.OFFSET;
    this.capacity = new SimulationVariable(renewable.capacity, this.ownerCase, this);
    this.capacityFactor = new SimulationVariable(renewable.capacityFactor, this.ownerCase, this);
    this.ghgCredit = new SimulationVariable(renewable.ghgCredit, this.ownerCase, this);
    this.opex = new SimulationVariable(renewable.opex, this.ownerCase, this);
    this.amortizedCapex = new SimulationVariable(renewable.amortizedCapex, this.ownerCase, this);
    this.electricityPrice = new SimulationVariable(renewable.electricityPrice, this.ownerCase, this);

    this.setQuantities();
  }

  setQuantities() {
    this.capacity.setQuantity('Power');
    this.capacityFactor.setQuantity('Percent');
    this.ghgCredit.setQuantity('MassPerEnergy');
    this.opex.setQuantity('CurrencyPerTime');
    this.amortizedCapex.setQuantity('CurrencyPerTime');
    this.electricityPrice.setQuantity('CurrencyPerElectricity');
  }

  override dePersist(renewable: any) {
    this.capacity = this.getSimulationVariableSafe(renewable.capacity);
    this.capacityFactor = this.getSimulationVariableSafe(renewable.capacityFactor);
    this.ghgCredit = this.getSimulationVariableSafe(renewable.ghgCredit);
    this.opex = this.getSimulationVariableSafe(renewable.opex);
    this.amortizedCapex = this.getSimulationVariableSafe(renewable.amortizedCapex);
    this.electricityPrice = this.getSimulationVariableSafe(renewable.electricityPrice);
  }

  public toJSON() {
    return {
      id: this.id,
      category: this.category,
      name: this.name,
      renewableType: this.renewableType,
      capacity: this.capacity.id,
      capacityFactor: this.capacityFactor.id,
      ghgCredit: this.ghgCredit.id,
      opex: this.opex.id,
      amortizedCapex: this.amortizedCapex.id,
      electricityPrice: this.electricityPrice.id,
    };
  }
}
