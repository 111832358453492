import * as wjcCore from '@grapecity/wijmo';
import { FlexSheetCellConfig } from './flex-sheet-cell-config';

export enum ThirdPartyRefinerySheetRows {
  NAME = 0,
  LIGHT_ENDS_MARGIN = 3,
  NAPHTHA_MARGIN = 4,
  DISTILLATE_MARGIN = 5,
  GAS_OIL_MARGIN = 6,
  VACUUM_RESIDUE_MARGIN = 7,
  CAPACITY = 8,
}

export const ThirdPartyRefineryCellConfig: FlexSheetCellConfig[] = [
  {
    row: ThirdPartyRefinerySheetRows.NAME,
    dataType: wjcCore.DataType.String,
    format: '',
  },
  {
    row: ThirdPartyRefinerySheetRows.LIGHT_ENDS_MARGIN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartyRefinerySheetRows.NAPHTHA_MARGIN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartyRefinerySheetRows.DISTILLATE_MARGIN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartyRefinerySheetRows.GAS_OIL_MARGIN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartyRefinerySheetRows.VACUUM_RESIDUE_MARGIN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: ThirdPartyRefinerySheetRows.CAPACITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
];
