import { Point } from 'gojs';
import { UnitOperation } from '../../_models';
import { FlowsheetDiagramService } from '../flowsheet-diagram/flowsheet-diagram.service';
import { CoreService } from '../core.service';
import { Flowsheet } from '../../_models/flowsheet-manager/flowsheet';
import { ArrayDiff } from '../../_utils/array-diff';

enum DeletedPortType {
  INLET = 'inlet',
  OUTLET = 'outlet',
}

export abstract class AbstractSubFlowsheetHandler {
  protected constructor(
    protected flowsheetDiagramService: FlowsheetDiagramService,
    protected coreService: CoreService
  ) {}

  /**
   * Gets the data of the node that should be created by default when the flowsheet is
   * opened
   */
  abstract getDefaultNodeData(): any;
  abstract getSubFlowsheetInlets(uoId: string): UnitOperation[];
  abstract getSubFlowsheetOutlets(uoId: string): UnitOperation[];

  /**
   * Performs operations needed for opening the flowsheet
   * @param uoId
   */
  abstract syncWhileFlowsheetLoading(uoId: string): void;

  /**
   * Synchronizes the inlet and outlet ports of all the blocks that own a flowsheet of this type.
   * The parent flowsheet of those blocks should be active for this to work.
   */
  abstract syncAllBlocksInletOutletPorts(): void;

  /**
   * Creates the flowsheet, saves it and sets it as current
   * @param uoId
   */
  createFlowsheet(uoId: string): Flowsheet {
    const flowsheet = this.coreService.createFlowsheetAndSave(uoId);
    this.coreService.setCurrentFlowsheet(flowsheet);
    this.flowsheetDiagramService.clearModel();

    const data = this.getDefaultNodeData();

    if (data) {
      data.flowsheetId = uoId;
      this.coreService.createUnitOpFromNodeData(data);
    }

    return flowsheet;
  }

  protected addNewNodeOnDiagram(data, x: number, y: number) {
    const pos = new Point();
    pos.x = x;
    pos.y = y;
    this.flowsheetDiagramService.addNodeData(data);
    const newNode = this.flowsheetDiagramService.gojsDiagram.findNodeForData(data);
    newNode.location = pos;
    return newNode.data;
  }

  /**
   * Adjusts the number of ports of the node in the first level flowsheet based on the number of inlets and outlets
   * added in its second level flowsheet
   * @param uo The unit operation whose links will be updated
   */
  protected syncBlockInletOutletPorts(uo: UnitOperation): void {
    const outletUnitOperations = this.getSubFlowsheetOutlets(uo.id);
    const inletUnitOperations = this.getSubFlowsheetInlets(uo.id);

    const node = this.flowsheetDiagramService.gojsDiagram.findNodesByExample({ id: uo.id }).first();
    if (!node.data.outletPorts) {
      node.data.outletPorts = [];
    }

    if (!node.data.inletPorts) {
      node.data.inletPorts = [];
    }

    this.handleDeletedInletPorts(uo, node, inletUnitOperations);
    this.handleDeletedOutletPorts(uo, node, outletUnitOperations);

    inletUnitOperations.forEach(inlet => {
      this.addOrUpdateInletPort(inlet, node);
    });

    outletUnitOperations.forEach(commodity => {
      this.addOrUpdateOutletPort(commodity, node);
    });

    this.flowsheetDiagramService.gojsDiagram.updateAllRelationshipsFromData();
    this.flowsheetDiagramService.gojsDiagram.updateAllTargetBindings();
    this.flowsheetDiagramService.gojsDiagram.requestUpdate();
  }

  protected handleDeletedInletPorts(
    ownerUnitOperation: UnitOperation,
    node: go.Node,
    inetUnitOperations: UnitOperation[]
  ) {
    const deletedInlets = new ArrayDiff(node.data.inletPorts as any[]).left(
      inetUnitOperations,
      (port, uo) => uo.id === port.portId
    );

    if (deletedInlets.length) {
      this.handleDeletedPorts(ownerUnitOperation, node, deletedInlets, DeletedPortType.INLET);
    }
  }

  protected handleDeletedOutletPorts(
    ownerUnitOperation: UnitOperation,
    node: go.Node,
    outletUnitOperations: UnitOperation[]
  ) {
    const deletedOutlets = new ArrayDiff(node.data.outletPorts as any[]).left(
      outletUnitOperations,
      (port, uo) => uo.id === port.portId
    );

    if (deletedOutlets.length) {
      this.handleDeletedPorts(ownerUnitOperation, node, deletedOutlets, DeletedPortType.OUTLET);
    }
  }

  protected handleDeletedPorts(
    uo: UnitOperation,
    node: go.Node,
    deletedPorts: any[],
    deletedPortType: DeletedPortType
  ) {
    deletedPorts.forEach(c => {
      const stream = this.coreService.currentCase.filterSuncorMaterialStreams(s => {
        return deletedPortType === DeletedPortType.INLET ? s.toPort === c.portId : s.fromPort === c.portId;
      })[0];
      if (stream) {
        this.coreService.currentCase.deleteMaterialStreamById(stream.id);
      }

      const portsArray = deletedPortType === DeletedPortType.INLET ? node.data.inletPorts : node.data.outletPorts;
      const index = portsArray.indexOf(c);

      this.flowsheetDiagramService.gojsDiagram.startTransaction('updatePort');
      // This deletes the attached stream automatically
      this.flowsheetDiagramService.gojsDiagram.model.removeArrayItem(portsArray, index);
      this.flowsheetDiagramService.gojsDiagram.commitTransaction('updatePort');
    });

    // horrible hack to avoid visual glitch
    const upNode = this.flowsheetDiagramService.gojsDiagram.findNodesByExample({ id: uo.id }).first();
    upNode.location.x += 1;
    upNode.location.x -= 1;
  }

  protected addOrUpdateOutletPort(uo: UnitOperation, portNode: go.Node) {
    const newPortData = {
      portId: uo.id,
      tooltip: uo.name,
    };
    const portsArray = portNode.data.outletPorts;

    this.addOrUpdatePort(portNode, uo, portsArray, newPortData);
  }

  protected addOrUpdateInletPort(inlet: UnitOperation, portNode: go.Node) {
    const newPortData = {
      portId: inlet.id,
      tooltip: inlet.name,
    };

    const portsArray = portNode.data.inletPorts;
    this.addOrUpdatePort(portNode, inlet, portsArray, newPortData);
  }

  protected addOrUpdatePort(
    portNode: go.Node,
    uo: UnitOperation,
    portsArray,
    newPortData: { tooltip: string; portId: string }
  ) {
    if (portNode.findPort(uo.id) === portNode) {
      this.flowsheetDiagramService.gojsDiagram.startTransaction('addPort');
      // and add it to the Array of port data
      this.flowsheetDiagramService.gojsDiagram.model.insertArrayItem(portsArray, -1, newPortData);
      this.flowsheetDiagramService.gojsDiagram.commitTransaction('addPort');
    } else {
      this.flowsheetDiagramService.gojsDiagram.startTransaction('changePort');
      const { data } = portNode.findPort(uo.id) as any;
      this.flowsheetDiagramService.gojsDiagram.model.setDataProperty(data, 'tooltip', uo.name);
      this.flowsheetDiagramService.gojsDiagram.commitTransaction('changePort');
    }
  }
}
