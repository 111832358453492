import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { FuelGasMaterialStream } from '../../../_models/_streams/fuel-gas-material-stream';
import { FuelGasMixer } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-mixer';
import { CoreService } from '../../../_services/core.service';
import { BaseStream } from '../../../_models/_streams/base-stream';

@Component({
  selector: 'sob-fuel-gas-mixer',
  templateUrl: './fuel-gas-mixer.component.html',
  styleUrls: ['./fuel-gas-mixer.component.css'],
})
export class FuelGasMixerComponent implements OnInit, OnDestroy {
  inletStreams: FuelGasMaterialStream[] = [];
  outletStream: FuelGasMaterialStream;

  @Input()
  unitOperation: FuelGasMixer;

  private subSink = new SubSink();

  constructor(private coreService: CoreService) {
    this.subSink.add(
      this.coreService.streamAddedRequest.subscribe(s => {
        if (s.inletUnitOperationId === this.unitOperation.id) {
          this.initOutletStream();
        }
        if (s.outletUnitOperationId === this.unitOperation.id) {
          this.initInletStreams();
        }
      })
    );

    this.subSink.add(
      this.coreService.streamRemovedRequest.subscribe(s => {
        if (s.inletUnitOperationId === this.unitOperation.id) {
          this.removeOutletStream();
        }
        if (s.outletUnitOperationId === this.unitOperation.id) {
          this.removeInletStream(s);
        }
      })
    );
  }

  ngOnInit(): void {
    if (this.unitOperation) {
      this.initInletStreams();
      this.initOutletStream();
    }
  }

  private initOutletStream() {
    const { ownerCase } = this.unitOperation;
    const outletStreams = ownerCase.filterFuelGasMaterialStreams((s: FuelGasMaterialStream) => {
      return s.inletUnitOperationId === this.unitOperation.id;
    });

    if (outletStreams.length) {
      [this.outletStream] = outletStreams;
    }
  }

  private initInletStreams() {
    const { ownerCase } = this.unitOperation;
    this.inletStreams = ownerCase.filterFuelGasMaterialStreams((s: FuelGasMaterialStream) => {
      return s.outletUnitOperationId === this.unitOperation.id;
    });
  }

  private removeOutletStream() {
    this.outletStream = undefined;
  }

  private removeInletStream(removedStream: BaseStream) {
    const matchingStream = this.inletStreams.find(s => s.id === removedStream.id);

    if (!matchingStream) {
      return;
    }

    this.inletStreams.splice(this.inletStreams.indexOf(matchingStream), 1);
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}
