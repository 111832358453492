import { SimulationVariable } from '../../simulation-variable';
import { Splitter } from '../../_unit-operations/splitter';
import { ProductTank } from '../../product-tank';
import { FuelGasSplitter } from '../../_unit-operations/fuel-gas/fuel-gas-splitter';
import { WaterHeader } from '../../_unit-operations/utilties/water-header';

export class OptimizationReportVariable {
  name: string;
  ownerName: string;
  ownerId: string;
  isOptimizable: boolean;
  value: number;
  unit: string;

  constructor(name, ownerName, ownerId, isOptimizable, value, unit) {
    this.name = name;
    this.ownerName = ownerName;
    this.ownerId = ownerId;
    this.isOptimizable = isOptimizable;
    this.value = value;
    this.unit = unit;
  }

  static create(sv: SimulationVariable) {
    return new OptimizationReportVariable(
      sv.name,
      sv.ownerBaseObject.name,
      sv.ownerBaseObject.id,
      (<any>sv.ownerBaseObject).isOptimizable, // hmmmmmmmmm
      sv.convertToDefaultUnit(true, false),
      sv.getDefaultUnit()
    );
  }

  static createFromSplitter(
    splitter: Splitter | FuelGasSplitter | ProductTank | WaterHeader
  ): Array<OptimizationReportVariable> {
    const variables = [];

    let i;
    for (i = 0; i < splitter.distributionRatioVariables.length; i++) {
      const dv = splitter.distributionRatioVariables[i];

      variables.push(
        new OptimizationReportVariable(
          `${splitter.findDistributionRatioVariableName(i)} - Distribution Ratio`,
          splitter.name,
          splitter.id,
          splitter.isOptimizable,
          dv.value ? `${parseFloat(dv.value.toFixed(3))}` : null,
          'N/D'
        )
      );
    }

    return variables;
  }
}
