import { getRandomColor } from '../color.generator';

export class SankeyUnitOperation {
  id: string;
  name: string;
  color: string;
  key: number;
  location: number[];
  category: string;

  constructor(uo: any) {
    this.id = uo.id;
    this.name = uo.name;
    this.location = uo.location;
    this.key = uo.key;
    this.category = uo.category;
    this.color = getRandomColor();
  }
}
