import { SuncorUnitOperation } from '../_unit-operations/suncor-unit-operation';
import { ConstrainableObject, isConstrainableObject } from '../_interfaces/constrainable-object';
import { Pipe } from '../_unit-operations/pipe';
import { WaterUtilityUnitOperation } from '../_unit-operations/utilties/water-utility-unit-operation';
import { FuelGasUtilityUnitOperation } from '../_unit-operations/fuel-gas/fuel-gas-utility-unit-operation';
import { UnitOperationReadiness } from '../../_config/unit-operations/unit-operation-readiness';
import { UnitOperation } from '../unit-operation';

class ConstraintTextSolver {
  static solveText(uo: SuncorUnitOperation & ConstrainableObject) {
    const c = uo.ownerCase;
    const inletStream = c.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === uo.id;
    })[0];

    const valuesTextInfo: ConstraintTextInfo[] = [];

    valuesTextInfo[0] = {
      variableName: uo.capacity.name,
      variableValue: uo.capacity.convertToDefaultUnit(true, true),
      unit: uo.capacity.getDefaultUnit(),
      constraintName: 'Vol. flow rate',
      constraintValue: inletStream ? inletStream.volumetricFlowrate.convertToDefaultUnit(true, true) : undefined,
    };

    if (uo instanceof Pipe) {
      valuesTextInfo[1] = {
        variableName: uo.pipeDensitySpec.name,
        variableValue: uo.pipeDensitySpec.convertToDefaultUnit(true, true),
        unit: uo.pipeDensitySpec.getDefaultUnit(),
        constraintName: 'Mass Density',
        constraintValue: inletStream ? inletStream.massDensity.convertToDefaultUnit(true, true) : undefined,
      };
    }

    return valuesTextInfo;
  }
}

export class GojsUnitOperation {
  id: string;
  name: string;

  key: number;
  location: number[];
  category: string;
  isSolved: boolean;
  readiness: UnitOperationReadiness;

  constraintInfo: ConstraintInfo;

  constructor(uo: UnitOperation) {
    this.id = uo.id;
    this.name = uo.name;

    this.location = uo.location;
    this.key = uo.key;
    this.category = uo.category;
    this.readiness = uo.readiness;

    if (uo instanceof WaterUtilityUnitOperation) {
      this.isSolved = uo.isSolved;
    }
    if (uo instanceof FuelGasUtilityUnitOperation) {
      this.isSolved = uo.isSolved;
    }

    if (isConstrainableObject(uo)) {
      const co = uo as SuncorUnitOperation & ConstrainableObject;

      this.constraintInfo = {
        constraintValueDefined: co.constraintValueDefined(),
        statusText: co.constraintViolated() ? 'Constraint violated' : 'Constraint value defined',
        valuesTextInfo: ConstraintTextSolver.solveText(co),
        constraintColor: '',
      };

      if (this.constraintInfo.constraintValueDefined) {
        if (uo.ownerCase.isSolved) {
          if (co.constraintViolated()) {
            this.constraintInfo.constraintColor = 'red';
          } else {
            this.constraintInfo.constraintColor = 'green';
          }
        } else {
          this.constraintInfo.constraintColor = 'orange';
        }
      }
    }
  }
}

interface ConstraintInfo {
  constraintColor: string;
  constraintValueDefined: boolean;
  statusText: string;
  valuesTextInfo: ConstraintTextInfo[];
}

interface ConstraintTextInfo {
  variableName: string;
  variableValue: string;
  constraintName: string;
  constraintValue: string;
  unit: string;
}
