<div class="row" *ngIf="parametricStudy">
  <div class="col-md-12">
    <div class="row"></div>

    <div class="row parametricStudyControls">
      <div class="col-sm-4">
        <div class="form-group">
          <span><strong>Category</strong></span>
          <select class="form-control" size="2" (change)="categoryChanged($event)">
            <option
              *ngFor="let category of caseStudyManager.parametricStudyCategories"
              value="{{ category.categoryName }}">
              {{ category.categoryName }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group">
          <span><strong>Parameter Provider</strong></span>
          <select class="form-control" size="2" (change)="parameterProviderChanged($event)">
            <option *ngFor="let paramProvider of paramProvidersForCategory" value="{{ paramProvider.id }}">
              {{ paramProvider.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group" style="margin-bottom: 2px">
          <span><strong>Available Parameters</strong></span>
          <select class="form-control" [(ngModel)]="availableParamId" size="2">
            <option *ngFor="let param of availableParams" [value]="param.id">
              {{ param.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <button
            class="btn btn-xs btn-success"
            (click)="setParameter()"
            data-toggle="tooltip"
            title="Add selected parameter">
            <i class="fa fa-plus-circle"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <sob-parameter-input
          [parametricStudy]="parametricStudy"
          *ngIf="parametricStudy.parameter"></sob-parameter-input>
        <div class="alert alert-warning" *ngIf="!parametricStudy.parameter" style="margin-top: 19px">
          No parameter selected
        </div>
      </div>
    </div>
  </div>
</div>
