<div class="modal fade" id="assayManagerModal" tabindex="-1" role="dialog" aria-labelledby="reportsModalLabel">
  <div class="modal-lg modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          [disabled]="currentAnalysis ? !validateName(currentAnalysis.assayName) : !validateName(newAnalysisName)">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Assay Manager</h4>
      </div>
      <div class="modal-body" *ngIf="assayManager">
        <div class="row" *ngIf="assayManager.fluidAnalyses.length === 0">
          <div class="col-md-12">
            <div class="form-inline">
              <div class="form-group" style="margin-bottom: 20px">
                <label for="fluidAnalysisType">Type</label>
                <select
                  style="margin-left: 18px"
                  id="fluidAnalysisType"
                  [(ngModel)]="newAnalysisType"
                  class="form-control">
                  <option value="Crude oil - Bitumen">Crude Oil - Bitumen</option>
                  <option value="Diluent">Diluent</option>
                  <option value="Gas">Gas</option>
                  <option value="Product">Product</option>
                </select>
              </div>
            </div>
            <div class="form-inline">
              <div class="form-group">
                <label for="assayManagerAssayName">Name</label>
                <input
                  style="margin-left: 10px"
                  type="text"
                  [(ngModel)]="newAnalysisName"
                  class="form-control"
                  id="assayManagerAssayName" />
                <button
                  style="margin-left: 10px"
                  (click)="addFluidAnalysis()"
                  class="btn btn-primary"
                  title="Add new assay">
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" [style.display]="assayManager.fluidAnalyses.length ? 'block' : 'none'">
          <div class="col-md-12">
            <div class="row">
              <!--              <div class="col-md-6">-->
              <!--                <div class=" alert alert-bordered">-->
              <!--                  <p style="padding-left: 20px; padding-top: 10px">Select / Remove</p>-->
              <!--                  <div class="form">-->
              <!--                    <div class="form-group" style="margin-bottom: 10px">-->
              <!--                      <div class="col-md-10">-->
              <!--                        <select name="" id="currentAnalysisId" [value]="currentAnalysis?.id"-->
              <!--                                #analysisSelect (change)="currentAnalysisChanged(analysisSelect.value)"-->
              <!--                                class="form-control">-->
              <!--                          <option *ngFor="let analysis of assayManager.fluidAnalyses" value="{{analysis.id}}">-->
              <!--                            {{analysis.assayName}}-->
              <!--                          </option>-->
              <!--                        </select>-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                    <div class="form-group">-->
              <!--                      <button class="btn btn-danger" (click)="removeFluidAnalysis()"><i class="fa fa-minus"></i>-->
              <!--                      </button>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="col-md-6">
                <ng-container *ngIf="currentAnalysis"> </ng-container>
              </div>
              <div class="col-md-6">
                <div *ngIf="!currentAnalysis">
                  <div class="col-md-12">
                    <div class="col-md-5">
                      <label class="control-label">Type</label>
                      <select id="fluidAnalysisTypex" [(ngModel)]="newAnalysisType" class="form-control">
                        <option value="Crude oil - Bitumen">Crude Oil - Bitumen</option>
                        <option value="Diluent">Diluent</option>
                        <option value="Gas">Gas</option>
                        <option value="Product">Product</option>
                      </select>
                    </div>
                    <div class="col-md-5">
                      <label class="control-label">Name</label>
                      <input
                        type="text"
                        [(ngModel)]="newAnalysisName"
                        class="form-control"
                        id="newAnalysisName"
                        placeholder="Name" />

                      <div class="row">
                        <div class="col-sm-12">
                          <span class="text-danger" *ngIf="!validateName(newAnalysisName)">
                            <span>This Analysis name already exists</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <button
                          style="margin-top: 23px"
                          (click)="addFluidAnalysis()"
                          class="btn btn-primary"
                          title="Add new assay"
                          [disabled]="!validateName(newAnalysisName)">
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form">
                    <div class="form-group" style="margin-bottom: 10px">
                      <!--<label for="uncertaintyAnalysisName">Uncertainty analysis name</label>-->
                    </div>
                  </div>
                </div>

                <div *ngIf="currentAnalysis">
                  <div class="col-md-12">
                    <div class="col-md-5">
                      <label class="control-label">Type</label>
                      <select id="fluidAnalysisTypexd" [(ngModel)]="currentAnalysis.assayType" class="form-control">
                        <option value="Crude oil - Bitumen">Crude Oil - Bitumen</option>
                        <option value="Diluent">Diluent</option>
                        <option value="Gas">Gas</option>
                        <option value="Product">Product</option>
                      </select>
                    </div>
                    <div class="col-md-5">
                      <label class="control-label">Name</label>
                      <input
                        type="text"
                        [(ngModel)]="currentAnalysis.assayName"
                        class="form-control"
                        id="currentAnalysisName"
                        placeholder="Name" />

                      <div class="row">
                        <div class="col-sm-12">
                          <span class="text-danger" *ngIf="!validateName(currentAnalysis.assayName)">
                            <span>This Assay name already exists</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <button
                          (click)="removeCurrentAnalysis()"
                          style="margin-top: 23px"
                          class="btn btn-primary"
                          title="back"
                          [disabled]="!validateName(currentAnalysis.assayName)">
                          <i class="fa fa-arrow-left"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="currentAnalysisWrapper" *ngIf="assayManager.fluidAnalyses.length > 0">
              <div class="col-md-12">
                <div>
                  <div class="padding-20" style="padding-top: 10px !important">
                    <ul class="nav nav-tabs">
                      <li class="active" *ngIf="!currentAnalysis">
                        <a href="#crudeOilBitumenAnalysisList" data-toggle="tab">Crude Oil - Bitumen</a>
                      </li>
                      <li *ngIf="!currentAnalysis">
                        <a href="#diluentAnalysisList" data-toggle="tab">Diluent</a>
                      </li>
                      <li *ngIf="!currentAnalysis">
                        <a href="#gasAnalysisList" data-toggle="tab">Gas</a>
                      </li>
                      <li *ngIf="!currentAnalysis">
                        <a href="#productAnalysisList" data-toggle="tab">Product</a>
                      </li>
                      <li class="active" *ngIf="currentAnalysis && currentAnalysis.assayType !== 'Gas'">
                        <a href="#faCuts" data-toggle="tab">Cuts</a>
                      </li>
                      <li class="{{ currentAnalysis.assayType === 'Gas' ? 'active' : '' }}" *ngIf="currentAnalysis">
                        <a href="#composition" data-toggle="tab" id="uaResultsTab">Composition</a>
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div
                        *ngIf="!currentAnalysis"
                        class="tab-pane fade in active"
                        id="crudeOilBitumenAnalysisList"
                        style="padding-top: 20px">
                        <div class="row">
                          <div class="col-md-12">
                            <div
                              id="crudeOilBitumenList"
                              *ngIf="assayManager.getAssaysByCategory('Crude oil - Bitumen').length > 0">
                              <table id="crudeOilBitumenTable" class="table">
                                <thead>
                                  <tr>
                                    <!--<th></th>-->
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr
                                    *ngFor="let fa of assayManager.getAssaysByCategory('Crude oil - Bitumen')"
                                    id="{{ fa.id }}">
                                    <!--<td><input type="checkbox" [attr.data-kpi]="kpi.id"></td>-->
                                    <td>{{ fa.assayName | titlecase }}</td>
                                    <td>{{ fa.assayType | titlecase }}</td>
                                    <td>
                                      <a (click)="setCurrentAnalysisById(fa.id)" class="btn btn-primary btn-xs">Edit</a>
                                      <a
                                        (click)="cloneFluidAnalysis(fa.id)"
                                        class="btn btn-primary btn-xs"
                                        style="margin-left: 15px"
                                        >Clone</a
                                      >
                                      <a
                                        (click)="removeFluidAnalysis(fa.id, 'crudeOilBitumenTable')"
                                        class="btn btn-danger btn-xs"
                                        style="margin-left: 15px"
                                        >Delete</a
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="!currentAnalysis"
                        class="tab-pane fade in"
                        id="diluentAnalysisList"
                        style="padding-top: 20px">
                        <div class="row">
                          <div class="col-md-12">
                            <div id="diluentList" *ngIf="assayManager.getAssaysByCategory('Diluent').length > 0">
                              <table id="diluentTable" class="table">
                                <thead>
                                  <tr>
                                    <!--<th></th>-->
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr *ngFor="let fa of assayManager.getAssaysByCategory('Diluent')" id="{{ fa.id }}">
                                    <!--<td><input type="checkbox" [attr.data-kpi]="kpi.id"></td>-->
                                    <td>{{ fa.assayName | titlecase }}</td>
                                    <td>{{ fa.assayType | titlecase }}</td>
                                    <td>
                                      <a (click)="setCurrentAnalysisById(fa.id)" class="btn btn-primary btn-xs">Edit</a>
                                      <a
                                        (click)="cloneFluidAnalysis(fa.id)"
                                        class="btn btn-primary btn-xs"
                                        style="margin-left: 15px"
                                        >Clone</a
                                      >
                                      <a
                                        (click)="removeFluidAnalysis(fa.id, 'diluentTable')"
                                        class="btn btn-danger btn-xs"
                                        style="margin-left: 15px"
                                        >Delete</a
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="!currentAnalysis"
                        class="tab-pane fade in"
                        id="gasAnalysisList"
                        style="padding-top: 20px">
                        <div class="row">
                          <div class="col-md-12">
                            <div id="gasList" *ngIf="assayManager.getAssaysByCategory('Gas').length > 0">
                              <table id="gasTable" class="table">
                                <thead>
                                  <tr>
                                    <!--<th></th>-->
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr *ngFor="let fa of assayManager.getAssaysByCategory('Gas')" id="{{ fa.id }}">
                                    <!--<td><input type="checkbox" [attr.data-kpi]="kpi.id"></td>-->
                                    <td>{{ fa.assayName | titlecase }}</td>
                                    <td>{{ fa.assayType | titlecase }}</td>
                                    <td>
                                      <a
                                        id="editButton{{ fa.id }}"
                                        (click)="setCurrentAnalysisById(fa.id)"
                                        class="btn btn-primary btn-xs"
                                        >Edit</a
                                      >
                                      <a
                                        (click)="cloneFluidAnalysis(fa.id)"
                                        class="btn btn-primary btn-xs"
                                        style="margin-left: 15px"
                                        >Clone</a
                                      >
                                      <a
                                        (click)="removeFluidAnalysis(fa.id, 'gasTable')"
                                        class="btn btn-danger btn-xs"
                                        style="margin-left: 15px"
                                        >Delete</a
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="!currentAnalysis"
                        class="tab-pane fade in"
                        id="productAnalysisList"
                        style="padding-top: 20px">
                        <div class="row">
                          <div class="col-md-12">
                            <div id="productList" *ngIf="assayManager.getAssaysByCategory('Product').length > 0">
                              <table id="productTable" class="table">
                                <thead>
                                  <tr>
                                    <!--<th></th>-->
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr *ngFor="let fa of assayManager.getAssaysByCategory('Product')" id="{{ fa.id }}">
                                    <!--<td><input type="checkbox" [attr.data-kpi]="kpi.id"></td>-->
                                    <td>{{ fa.assayName | titlecase }}</td>
                                    <td>{{ fa.assayType | titlecase }}</td>
                                    <td>
                                      <a (click)="setCurrentAnalysisById(fa.id)" class="btn btn-primary btn-xs">Edit</a>
                                      <a
                                        (click)="cloneFluidAnalysis(fa.id)"
                                        class="btn btn-primary btn-xs"
                                        style="margin-left: 15px"
                                        >Clone</a
                                      >
                                      <a
                                        (click)="removeFluidAnalysis(fa.id, 'productTable')"
                                        class="btn btn-danger btn-xs"
                                        style="margin-left: 15px"
                                        >Delete</a
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="currentAnalysis && currentAnalysis.assayType !== 'Gas'"
                        class="tab-pane fade in active"
                        id="faCuts"
                        style="padding-top: 20px">
                        <div class="row">
                          <div class="col-md-6">
                            <sob-cut-properties
                              [fluidAnalysis]="currentAnalysis.assayType !== 'Gas' ? currentAnalysis : undefined"
                              [idPrefix]="'assayManager'"></sob-cut-properties>
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="currentAnalysis"
                        class="tab-pane fade in {{ currentAnalysis.assayType === 'Gas' ? 'active' : '' }}"
                        id="composition"
                        style="padding-top: 20px">
                        <div class="row">
                          <div class="col-md-6">
                            <div
                              class="form-horizontal"
                              sfeMultipleSimVarInput
                              [objectToValidate]="currentAnalysis"
                              totalOutputId="AMcompositionTotal">
                              <div class="form-group">
                                <label [attr.for]="'compositionUnit'" class="pw-label control-label col-sm-4">
                                  Unit
                                </label>

                                <div class="col-sm-4">
                                  <sfe-unit-select
                                    [selectId]="'AMcompositionUnit'"
                                    [simVar]="currentAnalysis.composition[0]"
                                    [elClass]="'form-control'"></sfe-unit-select>
                                </div>
                              </div>

                              <ng-container *ngFor="let idx of currentAnalysis.composition | keys">
                                <div class="form-group">
                                  <label [attr.for]="'composition' + idx" class="pw-label control-label col-sm-4">
                                    {{ currentAnalysis.composition[idx].name | titleCaseComposition }}
                                  </label>
                                  <div class="col-sm-4">
                                    <sfe-value-input
                                      [simVar]="currentAnalysis.composition[idx]"
                                      [elClass]="'form-control'"
                                      [inputId]="'AMcomposition' + idx"
                                      [multiple]="false"
                                      [convertedBy]="'compositionUnit'">
                                    </sfe-value-input>
                                  </div>
                                </div>
                              </ng-container>

                              <div class="form-group">
                                <label class="pw-label control-label col-sm-4">Total</label>
                                <div class="col-sm-4">
                                  <input type="text" class="form-control" id="AMcompositionTotal" readonly />
                                </div>
                              </div>

                              <div class="col-sm-11" style="text-align: center">
                                <span class="text-danger">
                                  <span *ngIf="this.totalOutputWarningMessage() === 0"
                                    >Total Composition cannot be more than 1
                                  </span>
                                  <span *ngIf="this.totalOutputWarningMessage() === 1"
                                    >Total Composition cannot be more than 100%
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" [style.display]="!assayManager.fluidAnalyses.length ? 'block' : 'none'">
          <div class="col-md-12">
            <h4 style="padding-top: 15px">Please add a fluid analysis</h4>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>

<div class="modal fade" id="excelUploadModal" tabindex="-1" role="dialog" aria-labelledby="excelUploadLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Excel upload</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-info">
          To import assays, please
          <a href="../../../assets/files/Assay%20upload.xlsx" download class="font-bold">download</a>
          the generated Excel template and fill-in information
        </div>

        <div class="form form-horizontal">
          <div class="form-group">
            <div class="col-xs-12">
              <input id="excelFileInput" name="excelFileInput" type="file" accept=".xlsx" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button id="download" (click)="uploadAssays()" type="button" class="btn btn-primary">Upload Excel file</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
