import { Case } from '../case';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { BitumenConversion } from './bitumen-conversion';
import { DiluentSource } from './diluent-source';
import { MineSource } from './mine-source';
import { Mixer } from './mixer';
import { SagdSource } from './sagd-source';
import { Pipe } from './pipe';
import { Refinery } from './refinery';
import { ThirdPartyRefinery } from './third-party-refinery';
import { Splitter } from './splitter';
import { Upgrader } from './upgrader';
import { ThirdPartySource } from './third-party-source';
import { OffshoreSource } from './offshore-source';
import { SuncorRenewableBlock } from './suncor-renewable-block';
import { DRU } from './dru';
import { VAC } from './vac';
import { UpgraderInlet } from './upgrader-inlet';
import { Hydrotreater } from './hydrotreater';
import { DCU } from './dcu';
import { UnitOperation } from '../unit-operation';
import { GenericSink } from './generic-sink';
import { CommodityTank } from './commodity-tank';
import { ProductTank } from '../product-tank';
import { Hydrocracker } from './hydrocracker';
import { Extraction } from './extraction';
import { InletTransferUnit } from './inlet-transfer-unit';
import { OutletTransferUnit } from './outlet-transfer-unit';
import { WaterSource } from './utilties/water-source';
import { WaterHeatExchanger } from './utilties/water-heat-exchanger';
import { WaterTurbine } from './utilties/water-turbine';
import { WaterHeater } from './utilties/water-heater';
import { WaterCooler } from './utilties/water-cooler';
import { WaterTwoPhaseSeparator } from './utilties/water-two-phase-separator';
import { WaterPump } from './utilties/water-pump';
import { WaterUtilityUnitOperation } from './utilties/water-utility-unit-operation';
import { WaterValve } from './utilties/water-valve';
import { WaterSink } from './utilties/water-sink';
import { WaterMixer } from './utilties/water-mixer';
import { WaterSplitter } from './utilties/water-splitter';
import { FluidCoker } from './fluid-coker';
import { WaterPipe } from './utilties/water-pipe';
import { HgoHydrotreater } from './hgo-hydrotreater';
import { WaterSourceImport } from './utilties/water-source-import';
import { WaterSinkImport } from './utilties/water-sink-import';
import { LgoHydrotreater } from './lgo-hydrotreater';
import { WaterBoiler } from './utilties/water-boiler';
import { WaterHeader } from './utilties/water-header';
import { HydrogenPlant } from './hydrogen-plant';
import { SulfurPlant } from './sulfur-plant';
import { FuelGasUtilityUnitOperation } from './fuel-gas/fuel-gas-utility-unit-operation';
import { FuelGasFlare } from './fuel-gas/fuel-gas-flare';
import { FuelGasSink } from './fuel-gas/fuel-gas-sink';
import { FuelGasSinkImport } from './fuel-gas/fuel-gas-sink-import';
import { FuelGasSourceImport } from './fuel-gas/fuel-gas-source-import';
import { FuelGasMixer } from './fuel-gas/fuel-gas-mixer';
import { FuelGasSource } from './fuel-gas/fuel-gas-source';
import { FuelGasSplitter } from './fuel-gas/fuel-gas-splitter';
import { FuelGasAmineContactor } from './fuel-gas/fuel-gas-amine-contactor';
import { WaterCogeneration } from './utilties/water-cogeneration';

export class UnitOperationFactory {
  static createUnitOperationAndSetDefaultValues(
    sourceUnitOperation: any,
    ownerCase: Case,
    resourceData?
  ): UnitOperation {
    const uo = this.createUnitOperation(sourceUnitOperation, ownerCase);

    uo.setQuantities();
    uo.setDefaultValues();
    if (resourceData) {
      uo.loadResourceData(resourceData);
    }
    return uo;
  }

  // eslint-disable-next-line max-len
  static createUnitOperation(sourceUnitOperation: any, ownerCase: Case): UnitOperation {
    switch (sourceUnitOperation.category) {
      case unitOperationsConfig.bitumenConversion.key:
        return new BitumenConversion(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.hydrogenPlant.key:
        return new HydrogenPlant(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.sulfurPlant.key:
        return new SulfurPlant(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.diluentSource.key:
        return new DiluentSource(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.extraction.key:
        return new Extraction(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.mine.key:
        return new MineSource(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.mixer.key:
        return new Mixer(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.offshore.key:
        return new OffshoreSource(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.pipe.key:
        return new Pipe(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.refinery.key:
        return new Refinery(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.suncorRenewableBlock.key:
        return new SuncorRenewableBlock(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.sagd.key:
        return new SagdSource(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.splitter.key:
        return new Splitter(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.thirdPartyRefinery.key:
        return new ThirdPartyRefinery(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.thirdPartySource.key:
        return new ThirdPartySource(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.upgrader.key:
        return new Upgrader(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.dru.key:
        return new DRU(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.vac.key:
        return new VAC(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.upgraderInlet.key:
        return new UpgraderInlet(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.hydrotreater.key:
        return new Hydrotreater(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.hgoHydrotreater.key:
        return new HgoHydrotreater(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.lgoHydrotreater.key:
        return new LgoHydrotreater(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.dcu.key:
        return new DCU(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.fluidCoker.key:
        return new FluidCoker(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.genericSink.key:
        return new GenericSink(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.commodityTank.key:
        return new CommodityTank(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.productTank.key:
        return new ProductTank(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.hydrocracker.key:
        return new Hydrocracker(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.inletTransferUnit.key:
        return new InletTransferUnit(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.outletTransferUnit.key:
        return new OutletTransferUnit(sourceUnitOperation, ownerCase);

      // water-utility-unit-operation
      case unitOperationsConfig.waterUtilityUnitOperation.key:
        return new WaterUtilityUnitOperation(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterSourceImport.key:
        return new WaterSourceImport(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterSinkImport.key:
        return new WaterSinkImport(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterSource.key:
        return new WaterSource(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterHeatExchanger.key:
        return new WaterHeatExchanger(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterTurbine.key:
        return new WaterTurbine(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterHeater.key:
        return new WaterHeater(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterCooler.key:
        return new WaterCooler(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterTwoPhaseSeparator.key:
        return new WaterTwoPhaseSeparator(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterPump.key:
        return new WaterPump(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterValve.key:
        return new WaterValve(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterSink.key:
        return new WaterSink(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterMixer.key:
        return new WaterMixer(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterSplitter.key:
        return new WaterSplitter(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterHeader.key:
        return new WaterHeader(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterPipe.key:
        return new WaterPipe(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterBoiler.key:
        return new WaterBoiler(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.waterCogeneration.key:
        return new WaterCogeneration(sourceUnitOperation, ownerCase);

      // fuel gas
      case unitOperationsConfig.fuelGasUtilityUnitOperation.key:
        return new FuelGasUtilityUnitOperation(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.fuelGasFlare.key:
        return new FuelGasFlare(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.fuelGasSink.key:
        return new FuelGasSink(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.fuelGasSinkImport.key:
        return new FuelGasSinkImport(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.fuelGasSourceImport.key:
        return new FuelGasSourceImport(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.fuelGasMixer.key:
        return new FuelGasMixer(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.fuelGasSource.key:
        return new FuelGasSource(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.fuelGasSplitter.key:
        return new FuelGasSplitter(sourceUnitOperation, ownerCase);
      case unitOperationsConfig.fuelGasAmineContactor.key:
        return new FuelGasAmineContactor(sourceUnitOperation, ownerCase);
      default:
        throw new Error(`Unknown unit operation ${sourceUnitOperation.category}`);
    }
  }
}
