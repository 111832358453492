import { StreamCategory } from '../../_config/stream-category.enum';
import { GojsBaseStream } from './gojs-base-stream';
import { FuelGasMaterialStream } from '../_streams/fuel-gas-material-stream';

export class GojsFuelGasMaterialStream extends GojsBaseStream {
  override category = StreamCategory.FUEL_GAS_MATERIAL_STREAM;
  flowrate: string;
  flowrateUnit: string;
  heatFlow: string;
  heatFlowUnit: string;
  lhv: string;
  lhvUnit: string;

  constructor(s: FuelGasMaterialStream) {
    super(s);

    this.flowrate = s.flowrate.convertToDefaultUnit(true, true);
    this.flowrateUnit = s.flowrate.getDefaultUnit();

    this.heatFlow = s.heatFlow.convertToDefaultUnit(true, true);
    this.heatFlowUnit = s.heatFlow.getDefaultUnit();

    this.lhv = s.lhv.convertToDefaultUnit(true, true);
    this.lhvUnit = s.lhv.getDefaultUnit();

    this.dashedLine = !s.hasFlowrate();
  }
}
