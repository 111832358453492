<div class="modal fade" id="summaryReportModal" tabindex="-1" role="dialog" aria-labelledby="summaryReportModalLabel">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="">Summary Report</h4>
      </div>

      <div class="modal-body">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active">
            <a href="#srInsights" role="tab" data-toggle="tab" aria-controls="srInsights"> Insights </a>
          </li>

          <li role="presentation">
            <a href="#srGhgEmissionsReport" role="tab" data-toggle="tab" aria-controls="srGhgEmissionsReport">
              GHG Emissions
            </a>
          </li>

          <li role="presentation">
            <a href="#srRevenueReport" role="tab" data-toggle="tab" aria-controls="srRevenueReport"> Revenue </a>
          </li>
          <li role="presentation">
            <a href="#srCapexReport" role="tab" data-toggle="tab" aria-controls="srCapexReport"> CAPEX </a>
          </li>

          <li role="presentation">
            <a href="#srOpexReport" role="tab" data-toggle="tab" aria-controls="srOpexReport"> OPEX </a>
          </li>

          <li role="presentation">
            <a href="#srRefineryFlowRates" role="tab" data-toggle="tab" aria-controls="srRefineryFlowRates">
              Suncor Hydrocarbon Production
            </a>
          </li>

          <li role="presentation">
            <a href="#srDiluentFlowrates" role="tab" data-toggle="tab" aria-controls="srDiluentFlowrates">
              Diluent Flowrates
            </a>
          </li>
          <li role="presentation" *ngIf="constraintRanking.length > 0">
            <a
              href="#srConstraintRanking"
              role="tab"
              data-toggle="tab"
              aria-controls="srConstraintRanking"
              (click)="showConstraintRank()">
              Constraint Ranking
            </a>
          </li>
        </ul>

        <div class="tab-content tab-content-padding">
          <div role="tabpanel" class="tab-pane fade in active" id="srInsights">
            <sob-insights></sob-insights>
          </div>

          <div role="tabpanel" class="tab-pane fade in" id="srGhgEmissionsReport" *ngIf="ghgEmissions">
            <sob-ghg-summary-report></sob-ghg-summary-report>
          </div>

          <div role="tabpanel" class="tab-pane fade in" id="srRevenueReport" *ngIf="revenueReport">
            <ul class="nav nav-pills" role="tablist">
              <li role="presentation" class="active">
                <a href="#srRevenueReportChart" role="tab" data-toggle="tab" aria-controls="srRevenueReportChart">
                  Pie Chart
                </a>
              </li>

              <li role="presentation">
                <a href="#srRevenueReportTable" role="tab" data-toggle="tab" aria-controls="srRevenueReportTable">
                  Table
                </a>
              </li>
            </ul>

            <div class="tab-content tab-content-padding">
              <div role="tabpanel" class="tab-pane fade in active" id="srRevenueReportChart">
                <div id="srRevenueChart"></div>

                <div class="row" style="margin-top: 20px">
                  <div class="col-xs-12 text-center">
                    <strong>Total Revenue</strong> {{ revenueReport.total | thousandSeparator }}
                    {{ revenueReport.unit }}
                  </div>
                  <div class="col-xs-12 text-center">
                    <strong>Total Value</strong> {{ revenueReport.totalValue | thousandSeparator }}
                    {{ revenueReport.unit }}
                  </div>
                </div>
              </div>

              <div role="tabpanel" class="tab-pane fade in" id="srRevenueReportTable">
                <div class="row">
                  <div class="col-xs-12">
                    <table class="table" id="srRevenueTable" style="width: 100%">
                      <thead>
                        <tr>
                          <th>Refinery Name</th>
                          <th>Revenue</th>
                        </tr>
                      </thead>

                      <tfoot>
                        <tr>
                          <th class="text-right">Total</th>
                          <th>{{ revenueReport.total | thousandSeparator }} {{ revenueReport.unit }}</th>
                        </tr>
                        <tr>
                          <th class="text-right">Total Value</th>
                          <th>{{ revenueReport.totalValue | thousandSeparator }} {{ revenueReport.unit }}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade in" id="srRefineryFlowRates" *ngIf="refineryFlowRates">
            <ul class="nav nav-pills" role="tablist">
              <li role="presentation" class="active">
                <a
                  href="#srRefineryFlowRatesReportChart"
                  role="tab"
                  data-toggle="tab"
                  aria-controls="srRefineryFlowRatesReportChart">
                  Pie Chart
                </a>
              </li>

              <li role="presentation">
                <a
                  href="#srRefineryFlowRatesReportTable"
                  role="tab"
                  data-toggle="tab"
                  aria-controls="srRefineryFlowRatesReportTable">
                  Table
                </a>
              </li>
            </ul>

            <div class="tab-content tab-content-padding">
              <div role="tabpanel" class="tab-pane fade in active" id="srRefineryFlowRatesReportChart">
                <div id="srRefineryFlowRatesChart"></div>

                <div class="row" style="margin-top: 20px">
                  <div class="col-xs-12 text-center">
                    <strong>Total flowrate</strong> {{ refineryFlowRates.total | thousandSeparator }}
                    {{ refineryFlowRates.unit }}
                  </div>
                </div>
              </div>

              <div role="tabpanel" class="tab-pane fade in" id="srRefineryFlowRatesReportTable">
                <div class="row">
                  <div class="col-xs-12">
                    <table class="table" id="srRefineryFlowRatesTable" style="width: 100%">
                      <thead>
                        <tr>
                          <th>Unit Operation</th>
                          <th>Volumetric Flowrate</th>
                        </tr>
                      </thead>

                      <tfoot>
                        <tr>
                          <th class="text-right">Total</th>
                          <th>{{ refineryFlowRates.total | thousandSeparator }} {{ refineryFlowRates.unit }}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade in" id="srDiluentFlowrates" *ngIf="diluentFlowRates">
            <ul class="nav nav-pills" role="tablist">
              <li role="presentation" class="active">
                <a
                  href="#srDiluentFlowRatesReportChart"
                  role="tab"
                  data-toggle="tab"
                  aria-controls="srDiluentFlowRatesReportChart">
                  Pie Chart
                </a>
              </li>

              <li role="presentation">
                <a
                  href="#srDiluentFlowRatesReportTable"
                  role="tab"
                  data-toggle="tab"
                  aria-controls="srDiluentFlowRatesReportTable">
                  Table
                </a>
              </li>
            </ul>

            <div class="tab-content tab-content-padding">
              <div role="tabpanel" class="tab-pane fade in active" id="srDiluentFlowRatesReportChart">
                <div id="srDiluentFlowRatesChart"></div>

                <div class="row" style="margin-top: 20px">
                  <div class="col-xs-12 text-center">
                    <strong>Total Flowrate</strong> {{ diluentFlowRates.total | thousandSeparator }}
                    {{ diluentFlowRates.unit }}
                  </div>
                </div>
              </div>

              <div role="tabpanel" class="tab-pane fade in" id="srDiluentFlowRatesReportTable">
                <div class="row">
                  <div class="col-xs-12">
                    <table class="table" id="srDiluentFlowRatesTable" style="width: 100%">
                      <thead>
                        <tr>
                          <th>Unit Operation</th>
                          <th>Volumetric Flowrate</th>
                        </tr>
                      </thead>

                      <tfoot>
                        <tr>
                          <th class="text-right">Total</th>
                          <th>{{ diluentFlowRates.total | thousandSeparator }} {{ diluentFlowRates.unit }}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade in" id="srCapexReport" *ngIf="capexReport">
            <ul class="nav nav-pills" role="tablist">
              <li role="presentation" class="active">
                <a href="#srCapexReportChart" role="tab" data-toggle="tab" aria-controls="srCapeReportChart">
                  Pie Chart
                </a>
              </li>

              <li role="presentation">
                <a href="#srCapexReportTable" role="tab" data-toggle="tab" aria-controls="srCapexReportTable">
                  Table
                </a>
              </li>
            </ul>

            <div class="tab-content tab-content-padding">
              <div role="tabpanel" class="tab-pane fade in active" id="srCapexReportChart">
                <div id="srCapexChart"></div>

                <div class="row" style="margin-top: 20px">
                  <div class="col-xs-12 text-center">
                    <strong>Total Capex</strong> {{ capexReport.total | thousandSeparator }}
                    {{ capexReport.unit }}
                  </div>
                </div>
              </div>

              <div role="tabpanel" class="tab-pane fade in" id="srCapexReportTable">
                <div class="row">
                  <div class="col-xs-12">
                    <table class="table" id="srCapexTable" style="width: 100%">
                      <thead>
                        <tr>
                          <th>Unit Operation</th>
                          <th>CAPEX</th>
                        </tr>
                      </thead>

                      <tfoot>
                        <tr>
                          <th class="text-right">Total</th>
                          <th>{{ capexReport.total | thousandSeparator }} {{ capexReport.unit }}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade in" id="srOpexReport" *ngIf="opexReport">
            <ul class="nav nav-pills" role="tablist">
              <li role="presentation" class="active">
                <a href="#srOpexReportChart" role="tab" data-toggle="tab" aria-controls="srCapeReportChart">
                  Pie Chart
                </a>
              </li>

              <li role="presentation">
                <a href="#srOpexReportTable" role="tab" data-toggle="tab" aria-controls="srOpexReportTable"> Table </a>
              </li>
            </ul>

            <div class="tab-content tab-content-padding">
              <div role="tabpanel" class="tab-pane fade in active" id="srOpexReportChart">
                <div id="srOpexChart"></div>

                <div class="row" style="margin-top: 20px">
                  <div class="col-xs-12 text-center">
                    <strong>Total OPEX</strong> {{ opexReport.total | thousandSeparator }}
                    {{ opexReport.unit }}
                  </div>
                </div>
              </div>

              <div role="tabpanel" class="tab-pane fade in" id="srOpexReportTable">
                <div class="row">
                  <div class="col-xs-12">
                    <table class="table" id="srOpexTable" style="width: 100%">
                      <thead>
                        <tr>
                          <th>Unit Operation</th>
                          <th>OPEX</th>
                        </tr>
                      </thead>

                      <tfoot>
                        <tr>
                          <th class="text-right">Total</th>
                          <th>{{ opexReport.total | thousandSeparator }} {{ opexReport.unit }}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade in" id="srConstraintRanking" *ngIf="constraintRanking.length > 0">
            <ul class="nav nav-pills" role="tablist">
              <li role="presentation">
                <a href="#srConstraintRankingChart" role="tab" data-toggle="tab" aria-controls="srCapeReportChart">
                  Bar Chart
                </a>
              </li>

              <li role="presentation" class="active">
                <a href="#srConstraintRankingTable" role="tab" data-toggle="tab" aria-controls="srOpexReportTable">
                  Table
                </a>
              </li>
            </ul>

            <div class="tab-content tab-content-padding">
              <div role="tabpanel" class="tab-pane fade in" id="srConstraintRankingChart">
                <div class="col-xs-12 text-right">
                  <a
                    title="Switch chart type"
                    class="btn btn-info"
                    style="margin-bottom: 20px"
                    (click)="switchConstraintChartType()">
                    <i *ngIf="objFunctionUnit === 'MMCAD$/kbpcd'">%</i>
                    <i *ngIf="objFunctionUnit === '%'">MMCAD$/kbpcd</i>
                  </a>
                </div>
                <div id="srConstraintChart"></div>
              </div>

              <div role="tabpanel" class="tab-pane fade in active" id="srConstraintRankingTable">
                <div class="row">
                  <div class="col-xs-12">
                    <table class="table table-bordered" id="srConstraintTable">
                      <thead>
                        <tr>
                          <th>Block Name</th>
                          <th>Constraint</th>
                          <th>Current Value</th>
                          <th>Unit</th>
                          <th>
                            Obj. function change rate
                            <button
                              class="btn btn-link"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Improvement in the objective function that can be achieved by relaxing the constraint by one unit. The change rate is only valid in a certain range as other constraints may become bottlenecks">
                              <i class="fa fa-info-circle"></i>
                            </button>
                          </th>
                          <th>Unit</th>

                          <th>Owner Flowsheet</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let mve of constraintRanking">
                          <tr>
                            <td>{{ mve.unitOperationName }}</td>
                            <td>{{ mve.variableName }}</td>
                            <td>{{ mve.value | number }}</td>
                            <td>{{ mve.unit }}</td>
                            <td>{{ mve.objFunctionChangeRate | number }}</td>
                            <td>{{ mve.objFunctionChangeRateUnit }}</td>
                            <td>{{ getOwnerFlowsheetName(mve) }}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
