import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import { SagdSource } from '../../../_models/_unit-operations/sagd-source';
import { SagdSourceFlexSheetEventHandler } from './sagd-source-flex-sheet-event-handler';
import { FlexSheetValueConverter } from '../flex-sheet-value-converter';
import { SuncorUnitOperation } from '../../../_models/_unit-operations/suncor-unit-operation';
import { MineSourceFlexSheetEventHandler } from './mine-source-flex-sheet-event-handler';
import { MineSource } from '../../../_models/_unit-operations/mine-source';
import { UnitOperationFlexSheetEventHandler } from './unit-operation-flex-sheet-event-handler';
import { DiluentSourceFlexSheetEventHandler } from './diluent-source-flex-sheet-event-handler';
import { DiluentSource } from '../../../_models/_unit-operations/diluent-source';
import { ThirdPartySourceFlexSheetEventHandler } from './third-party-source-flex-sheet-event-handler';
import { ThirdPartySource } from '../../../_models/_unit-operations/third-party-source';
import { OffshoreSource } from '../../../_models/_unit-operations/offshore-source';
import { OffshoreSourceFlexSheetEventHandler } from './offshore-source-flex-sheet-event-handler';
import { Upgrader } from '../../../_models/_unit-operations/upgrader';
import { UpgraderFlexSheetEventHandler } from './upgrader-flex-sheet-event-handler';
import { PipeFlexSheetEventHandler } from './pipe-flex-sheet-event-handler';
import { Pipe } from '../../../_models/_unit-operations/pipe';
import { Refinery } from '../../../_models/_unit-operations/refinery';
import { RefineryFlexSheetEventHandler } from './refinery-flex-sheet-event-handler';
import { ThirdPartyRefineryFlexSheetEventHandler } from './third-party-refinery-flex-sheet-event-handler';
import { ThirdPartyRefinery } from '../../../_models/_unit-operations/third-party-refinery';
import { AssayFlexsheetEventHandler } from './assay-flexsheet-event-handler';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { UtilitiesFlexSheetEventHandler } from './utilities-flex-sheet-event-handler';
import { H2sProductionSheetEventHandler } from './h2s-production-sheet-event-handler';
import { SteamSheetEventHandler } from './steam-sheet-event-handler';
import { GasFluidAnalysis } from '../../../_models/_fluid/gas-fluid-analysis';
import { AssayGasesFlexsheetEventHandler } from './assay-gases-flexsheet-event-handler';

export class FlexSheetEventHandler {
  private readonly sagdSourceEventHandler: UnitOperationFlexSheetEventHandler;
  private readonly mineSourceEventHandler: UnitOperationFlexSheetEventHandler;
  private readonly diluentSourceEventHandler: UnitOperationFlexSheetEventHandler;
  private readonly thirdPartySourceEventHandler: UnitOperationFlexSheetEventHandler;
  private readonly offshoreSourceEventHandler: UnitOperationFlexSheetEventHandler;
  private readonly upgraderEventHandler: UnitOperationFlexSheetEventHandler;
  private readonly pipeEventHandler: UnitOperationFlexSheetEventHandler;
  private readonly refineryEventHandler: UnitOperationFlexSheetEventHandler;
  private readonly thirdPartyRefineryEventHandler: UnitOperationFlexSheetEventHandler;
  private readonly assayEventHandler: AssayFlexsheetEventHandler;
  private readonly assayGasesEventHandler: AssayGasesFlexsheetEventHandler;
  readonly utilitiesEventHandler: UtilitiesFlexSheetEventHandler;
  readonly h2sProductionEventHandler: H2sProductionSheetEventHandler;
  readonly steamSheetEventHandler: SteamSheetEventHandler;

  constructor() {
    const valueConverter = new FlexSheetValueConverter();

    this.sagdSourceEventHandler = new SagdSourceFlexSheetEventHandler(valueConverter);
    this.mineSourceEventHandler = new MineSourceFlexSheetEventHandler(valueConverter);
    this.diluentSourceEventHandler = new DiluentSourceFlexSheetEventHandler(valueConverter);
    this.thirdPartySourceEventHandler = new ThirdPartySourceFlexSheetEventHandler(valueConverter);
    this.offshoreSourceEventHandler = new OffshoreSourceFlexSheetEventHandler(valueConverter);
    this.upgraderEventHandler = new UpgraderFlexSheetEventHandler(valueConverter);
    this.pipeEventHandler = new PipeFlexSheetEventHandler(valueConverter);
    this.refineryEventHandler = new RefineryFlexSheetEventHandler(valueConverter);
    this.thirdPartyRefineryEventHandler = new ThirdPartyRefineryFlexSheetEventHandler(valueConverter);
    this.assayEventHandler = new AssayFlexsheetEventHandler(valueConverter);
    this.assayGasesEventHandler = new AssayGasesFlexsheetEventHandler(valueConverter);
    this.utilitiesEventHandler = new UtilitiesFlexSheetEventHandler(valueConverter);
    this.h2sProductionEventHandler = new H2sProductionSheetEventHandler(valueConverter);
    this.steamSheetEventHandler = new SteamSheetEventHandler(valueConverter);
  }

  handleEditEvent(
    unitOperation: SuncorUnitOperation | SuncorFluidAnalysis | GasFluidAnalysis,
    spreadsheet: wjcSheet.FlexSheet,
    flexSheetEvent: any
  ): boolean {
    const rawValue = spreadsheet.activeEditor.value;
    return this.handleEvent(unitOperation, spreadsheet, flexSheetEvent, rawValue);
  }

  handleCellPasteEvent(
    unitOperation: SuncorUnitOperation | SuncorFluidAnalysis | GasFluidAnalysis,
    spreadsheet: wjcSheet.FlexSheet,
    flexSheetEvent: any
  ) {
    const rawValue = flexSheetEvent.data;
    this.handleEvent(unitOperation, spreadsheet, flexSheetEvent, rawValue);
  }

  private handleEvent(
    unitOperation: SuncorUnitOperation | SuncorFluidAnalysis | GasFluidAnalysis,
    spreadsheet: wjcSheet.FlexSheet,
    flexSheetEvent: any,
    rawValue
  ) {
    const eventHandler = this.getEventHandler(unitOperation, spreadsheet.selectedSheetIndex);
    if (eventHandler) {
      return eventHandler.handle(unitOperation, spreadsheet, flexSheetEvent, rawValue);
    }
  }

  protected getEventHandler(
    unitOperation: SuncorUnitOperation | SuncorFluidAnalysis | GasFluidAnalysis,
    activeSheet: number
  ): any {
    if (unitOperation instanceof SagdSource) {
      return this.sagdSourceEventHandler;
    }
    if (unitOperation instanceof MineSource) {
      return this.mineSourceEventHandler;
    }
    if (unitOperation instanceof DiluentSource) {
      return this.diluentSourceEventHandler;
    }
    if (unitOperation instanceof ThirdPartySource) {
      return this.thirdPartySourceEventHandler;
    }
    if (unitOperation instanceof OffshoreSource) {
      return this.offshoreSourceEventHandler;
    }
    if (unitOperation instanceof Upgrader) {
      return this.upgraderEventHandler;
    }
    if (unitOperation instanceof Pipe) {
      return this.pipeEventHandler;
    }
    if (unitOperation instanceof Refinery) {
      return this.refineryEventHandler;
    }
    if (unitOperation instanceof ThirdPartyRefinery) {
      return this.thirdPartyRefineryEventHandler;
    }
    if (unitOperation instanceof SuncorFluidAnalysis) {
      return this.assayEventHandler;
      // TODO: we should update this to use sheet index not harcoded
    }
    if (unitOperation instanceof GasFluidAnalysis) {
      return this.assayGasesEventHandler;
    }
    if (activeSheet === 5) {
      return this.utilitiesEventHandler;
    }
    if (activeSheet === 6) {
      return this.h2sProductionEventHandler;
    }
  }
}
