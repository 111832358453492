import { Injectable } from '@angular/core';
import * as go from 'gojs';
import { Diagram, GraphLinksModel } from 'gojs';
import { FlowsheetDiagramService } from '../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { SankeyUnitOperation } from '../../_models/_gojs/sankey-unit-operation';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { SankeySuncorMaterialStream } from '../../_models/_gojs/sankey-suncor-material-stream';
import { Case, SuncorMaterialStream, UnitOperation } from '../../_models';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { WaterMaterialStream } from '../../_models/_streams/water-material-stream';
import { SankeyWaterMaterialStream } from '../../_models/_gojs/sankey-water-material-stream';
import { FuelGasMaterialStream } from '../../_models/_streams/fuel-gas-material-stream';
import { SankeyFuelGasMaterialStream } from '../../_models/_gojs/sankey-fuel-gas-material-stream';
import { isNullOrUndefined } from '../../_utils/utils';

@Injectable({
  providedIn: 'root',
})
export class SankeyDiagramService {
  sankeyDiagram: Diagram;
  constructor(private flowsheetDiagramService: FlowsheetDiagramService) {}

  caseToSankeyDiagram(c: Case, upgId?: string, subDiagram?: boolean) {
    if (c.isSolved) {
      const nodeDataArray = [];
      const linkDataArray = [];
      // eslint-disable-next-line guard-for-in
      for (const uoId in c.unitOperationPool) {
        if (subDiagram) {
          this.restoreKeylessNodes(c);
          if (c.unitOperationPool[uoId].flowsheetId && c.unitOperationPool[uoId].flowsheetId === upgId) {
            const o = new SankeyUnitOperation(c.unitOperationPool[uoId] as UnitOperation);
            // if (o.category !== 'mixer' && o.category !== 'splitter' && o.category !== 'pipe') {
            if (isNullOrUndefined(o.key) || <any>o.key === '') {
              delete o.key;
            } else {
              nodeDataArray.push(o);
            }
          }
        } else if (!c.unitOperationPool[uoId].flowsheetId) {
          const o = new SankeyUnitOperation(c.unitOperationPool[uoId] as SuncorUnitOperation);
          // if (o.category !== 'mixer' && o.category !== 'splitter' && o.category !== 'pipe') {
          if (isNullOrUndefined(o.key) || <any>o.key === '') {
            delete o.key;
          } else {
            nodeDataArray.push(o);
          }
        }
      }
      this.sankeyDiagram.clear(); // this will destroy all the undo actions so far

      const streamIds = c.getAllMaterialStreamIds();

      for (const id of streamIds) {
        let stream;
        stream = c.getSuncorMaterialStream(id);

        if (!(stream instanceof SuncorMaterialStream)) {
          stream = c.getWaterStream(id);
          if (!(stream instanceof WaterMaterialStream)) {
            stream = c.getFuelGasStream(id);
          }
        }

        if (!stream) {
          continue;
        }

        if (subDiagram) {
          const toNode = c.unitOperationPool[stream.outletUnitOperationId];
          const fromNode = c.unitOperationPool[stream.inletUnitOperationId];
          if (toNode.flowsheetId === upgId && fromNode.flowsheetId === upgId) {
            if (stream.hasFlowrate() && stream.inletUnitOperation.key && stream.outletUnitOperation.key) {
              linkDataArray.push(this.getSankeyMaterialStreamByStreamType(stream));
            }
          }
        } else {
          const toNode = c.unitOperationPool[stream.outletUnitOperationId];
          const fromNode = c.unitOperationPool[stream.inletUnitOperationId];
          if (!toNode.flowsheetId && !fromNode.flowsheetId) {
            if (stream.hasFlowrate()) {
              linkDataArray.push(this.getSankeyMaterialStreamByStreamType(stream));
            }
          }
        }
      }

      this.sankeyDiagram.model.startTransaction('addNodeDataArray');
      this.sankeyDiagram.model.addNodeDataCollection(nodeDataArray);
      this.sankeyDiagram.model.commitTransaction('addNodeDataArray');

      this.sankeyDiagram.model.startTransaction('addLinkDataArray');
      (this.sankeyDiagram.model as GraphLinksModel).addLinkDataCollection(linkDataArray);
      this.sankeyDiagram.model.commitTransaction('addLinkDataArray');
      this.removePipes();
      this.removeMixers();
      this.removeSplittersWithOneInlet();
      this.removeSplittersWithOneOutlet();
      this.removeDisconnectedNodes();

      this.zoomToFit();
    }
  }

  getSankeyMaterialStreamByStreamType(stream: any) {
    if (stream instanceof SuncorMaterialStream) {
      return new SankeySuncorMaterialStream(stream);
    }
    if (stream instanceof WaterMaterialStream) {
      return new SankeyWaterMaterialStream(stream);
    }
    if (stream instanceof FuelGasMaterialStream) {
      return new SankeyFuelGasMaterialStream(stream);
    }

    throw new Error(`Unknown material stream ${stream.category}`);
  }

  restoreKeylessNodes(c: Case) {
    const nodes = c.filterUnitOperations(o => {
      return isNullOrUndefined(o.key) || <any>o.key === '';
    });
    nodes.forEach(uo => {
      const node = this.flowsheetDiagramService.gojsDiagram.findNodesByExample({ id: uo.id }).first();
      if (node) {
        uo.key = node.data.key;
      }
    });
  }

  removePipes() {
    const nodesToDelete = [];
    const it = this.sankeyDiagram.links;
    while (it.next()) {
      const link = it.value;
      const toNode = this.sankeyDiagram.findNodeForKey(link.data.to);
      if (toNode && (toNode.data.category === 'pipe' || toNode.data.category === 'water-pipe')) {
        link.toNode = this.findSinkNode(link);
        nodesToDelete.push(toNode);
        nodesToDelete.push(toNode.findLinksOutOf().first());
      }
    }

    nodesToDelete.forEach(node => {
      this.sankeyDiagram.remove(node);
    });
  }

  removeMixers() {
    const nodesToDelete = [];
    const it = this.sankeyDiagram.links;
    while (it.next()) {
      const link = it.value;

      const toNode = this.sankeyDiagram.findNodeForKey(link.data.to);
      if (
        toNode &&
        (toNode.data.category === 'mixer' ||
          toNode.data.category === 'water-mixer' ||
          toNode.data.category === 'fuel-gas-mixer')
      ) {
        link.toNode = this.findSinkNode(link);
        nodesToDelete.push(toNode);
        nodesToDelete.push(toNode.findLinksOutOf().first());
      }
    }

    nodesToDelete.forEach(node => {
      this.sankeyDiagram.remove(node);
    });
  }

  removeSplittersWithOneInlet() {
    const nodesToDelete = [];

    const it = this.sankeyDiagram.links;
    while (it.next()) {
      const link = it.value;

      const toNode = this.sankeyDiagram.findNodeForKey(link.data.to);
      if (
        toNode &&
        (toNode.data.category === 'splitter' ||
          toNode.data.category === 'water-splitter' ||
          toNode.data.category === 'fuel-gas-splitter')
      ) {
        if (toNode.findLinksInto().count === 1) {
          const linksToReasign = [];
          const linksOut = toNode.findLinksOutOf();
          linksOut.each(l => {
            linksToReasign.push(l);
          });
          linksToReasign.forEach(l => {
            l.fromNode = toNode.findLinksInto().first().fromNode;
          });
          nodesToDelete.push(toNode);
        }
      }
    }

    nodesToDelete.forEach(node => {
      this.sankeyDiagram.remove(node);
    });
  }

  removeSplittersWithOneOutlet() {
    const splitters = this.sankeyDiagram.nodes.filter(n => {
      return (
        (n.data.category === 'splitter' ||
          n.data.category === 'water-splitter' ||
          n.data.category === 'fuel-gas-splitter') &&
        n.findLinksOutOf().count === 1
      );
    });

    while (splitters.next()) {
      const n = splitters.value;
      const linksToReasign = [];
      const links = n.findLinksInto();
      const sinkNode = n.findLinksOutOf().first().toNode;
      links.each(l => {
        linksToReasign.push(l);
      });
      linksToReasign.forEach(l => {
        l.toNode = sinkNode;
      });
    }

    this.sankeyDiagram.removeParts(splitters, false);
  }

  removeDisconnectedNodes() {
    const disconnectedNodes = this.sankeyDiagram.nodes.filter(n => {
      return n.linksConnected.count === 0;
    });
    this.sankeyDiagram.removeParts(disconnectedNodes, false);
  }

  findSinkNode(link) {
    const node = link.toNode;
    if (
      node.data.category === 'mixer' ||
      node.data.category === 'pipe' ||
      node.data.category === 'water-mixer' ||
      node.data.category === 'water-pipe' ||
      node.data.category === 'fuel-gas-mixer'
    ) {
      const outLink = node.findLinksOutOf().first();
      return this.findSinkNode(outLink);
    }
    return node;
  }

  getLinksToGenericSink(): go.Link[] {
    const results: go.Link[] = [];
    this.sankeyDiagram.links.each(link => {
      if (
        link.toNode.data.category === unitOperationsConfig.genericSink.key ||
        link.toNode.data.category === unitOperationsConfig.waterSink.key ||
        link.toNode.data.category === unitOperationsConfig.fuelGasSink.key
      ) {
        results.push(link);
      }
    });
    return results;
  }

  hideGenericSinks() {
    // we also need to hide the links
    const links = this.getLinksToGenericSink();
    links.forEach(link => {
      link.visible = false;
      link.toNode.visible = false;
    });
  }

  showGenericSinks() {
    const links = this.getLinksToGenericSink();
    links.forEach(link => {
      link.visible = true;
      link.toNode.visible = true;
    });
  }

  zoomToFit() {
    this.sankeyDiagram.zoomToFit();
  }
}
