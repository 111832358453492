import { SimulationVariable } from '../simulation-variable';

export class ReportValue {
  value: number;
  name: string;
  childDataId: string;
  isFlowsheetOwner: boolean;

  constructor(value: number, name: string, childDataId?: string) {
    this.value = value;
    this.name = name;
    this.childDataId = childDataId;
  }

  setIsFlowsheetOwner(isFlowsheetOwner: boolean) {
    this.isFlowsheetOwner = isFlowsheetOwner;
  }
}

export class GhgReportValue extends ReportValue {
  alternativeGhgIntensity: SimulationVariable;

  constructor(value: number, alternativeGhgIntensity: SimulationVariable, name: string, childDataId?: string) {
    super(value, name, childDataId);
    this.alternativeGhgIntensity = alternativeGhgIntensity;
  }
}

// to be used when different types of unit operation are in the same DataSet
export class MixedGhgReportValue extends GhgReportValue {
  unitOperationCategory: string;

  constructor(value: number, name: string, unitOperationType: string, childDataId?: string) {
    super(value, undefined, name, childDataId);
    this.unitOperationCategory = unitOperationType;
  }
}
