import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { WaterUnitOperation } from './water-unit-operation';
import { PressureCalculationMode } from '../../../_config/pressure-calculation-mode.enum';
import { SimulationVariable } from '../../simulation-variable';
import { Case } from '../../case';
import { Quantity } from '../../../_config/quantity.enum';

export class WaterMixer extends WaterUnitOperation {
  category = unitOperationsConfig.waterMixer.key;

  pressureCalculationMode: PressureCalculationMode | string;
  outletPressure: SimulationVariable;

  constructor(unitOperation: any | WaterMixer, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.pressureCalculationMode = unitOperation.pressureCalculationMode || '';
    this.outletPressure = new SimulationVariable(unitOperation.outletPressure, this.ownerCase, this);
  }

  override setDefaultValues() {
    super.setDefaultValues();
    if (this.pressureCalculationMode === '') {
      this.pressureCalculationMode = PressureCalculationMode.LOWEST;
    }
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.outletPressure.setName('Outlet Pressure');
  }

  override setQuantities() {
    super.setQuantities();
    this.outletPressure.setQuantity(Quantity.PRESSURE);
  }

  override dePersist(unitOperation: any) {
    super.dePersist(unitOperation);
    this.outletPressure = this.getSimulationVariableSafe(unitOperation.outletPressure);
    this.setQuantities();
    this.setDefaultValues();
  }
}
