import { DataType } from '@grapecity/wijmo';

import { UtilitySteamSpreadsheetModel } from '../../_models/utilities-spreadsheet/utility-steam-spreadsheet-model';
import { FlexSheetColumn } from '../../_models/wijmo/flex-sheet-column';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { AbstractUtilitiesHandler } from './abstract-utilities-handler';
import { BaseUtilitySpreadsheetModel } from '../../_models/utilities-spreadsheet/base-utility-spreadsheet-model';
import { Upgrader } from '../../_models/_unit-operations/upgrader';
import { CoreService } from '../core.service';
import { NumberToUnitConverter } from '../number-to-unit-converter.service';
import { SteamUseUnitOperation } from '../../_models/_unit-operations/steam-use-unit-operation';
import { Case, UnitOperation } from '../../_models';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { SteamSheetItemType } from '../../_config/spreadsheet/steam-sheet-item-type.enum';
import { FlowsheetType } from '../../_config/flowsheet-type.enum';
import { SteamSheetHelper } from './steam-sheet-helper';
import { WaterBoiler } from '../../_models/_unit-operations/utilties/water-boiler';
import { WaterTurbine } from '../../_models/_unit-operations/utilties/water-turbine';
import { WaterSourceImport } from '../../_models/_unit-operations/utilties/water-source-import';
import { WaterSinkImport } from '../../_models/_unit-operations/utilties/water-sink-import';
import { WaterSource } from '../../_models/_unit-operations/utilties/water-source';
import { WaterHeader } from '../../_models/_unit-operations/utilties/water-header';
import { WaterUtilityUnitOperation } from '../../_models/_unit-operations/utilties/water-utility-unit-operation';

export class SteamSheetHandler extends AbstractUtilitiesHandler {
  private steamSheetHelper: SteamSheetHelper;

  constructor(coreService: CoreService, private ownerUnitOperation: UnitOperation, private nuc: NumberToUnitConverter) {
    super(coreService);
    this.steamSheetHelper = new SteamSheetHelper(this.nuc);
    this.ownerUnitOperationId = ownerUnitOperation.id;
  }

  override caseToSpreadsheet(c: Case, upgOwner: Upgrader) {
    this.clearSpreadsheetItems();

    const itemsAndTotals = this.getItemsAndTotalsConfig();
    const hydrocarbonSpreadsheetItems: UtilitySteamSpreadsheetModel[] = [];

    const othersTotal: UtilitySteamSpreadsheetModel = this.steamSheetHelper.getEmptySpreadsheetItem(
      'Others',
      SteamSheetItemType.HYDROCARBON,
      undefined
    );

    // eslint-disable-next-line guard-for-in
    for (const id in c.unitOperationPool) {
      const uo = c.unitOperationPool[id];

      if (!this.isUnitOperationAllowed(uo)) {
        continue;
      }

      if (this.steamSheetHelper.isFromHydroCarbonFlowsheet(uo)) {
        const uoSteam = uo as SteamUseUnitOperation;
        othersTotal.steamMake50 += uoSteam.steamMake50.value;
        othersTotal.steamUse50 += uoSteam.steamUse50.value;
        othersTotal.steamUse150 += uoSteam.steamUse150.value;
        othersTotal.steamMake150 += uoSteam.steamMake150.value;
        othersTotal.steamUse600 += uoSteam.steamUse600.value;
        othersTotal.steamMake600 += uoSteam.steamMake600.value;
        othersTotal.steamUse900 += uoSteam.steamUse900.value;
        othersTotal.steamMake900 += uoSteam.steamMake900.value;

        const uoItem = this.toSpreadsheetItem(uo) as UtilitySteamSpreadsheetModel;

        hydrocarbonSpreadsheetItems.push(uoItem);
        continue;
      }

      const item = this.toSpreadsheetItem(uo) as UtilitySteamSpreadsheetModel;
      itemsAndTotals[uo.category].items.push(item);
      const itemInRawValue = itemsAndTotals[uo.category].getItemInRawValue(uo, true);
      this.steamSheetHelper.sumValuesToItem(itemInRawValue, itemsAndTotals[uo.category].total);
    }

    if (c.isSolved) {
      othersTotal.steamMake50 = upgOwner.steamMake50.value - othersTotal.steamMake50;
      othersTotal.steamUse50 = upgOwner.steamUse50.value - othersTotal.steamUse50;
      othersTotal.steamUse150 = upgOwner.steamUse150.value - othersTotal.steamUse150;
      othersTotal.steamMake150 = upgOwner.steamMake150.value - othersTotal.steamMake150;
      othersTotal.steamUse600 = upgOwner.steamUse600.value - othersTotal.steamUse600;
      othersTotal.steamMake600 = upgOwner.steamMake600.value - othersTotal.steamMake600;
      othersTotal.steamUse900 = upgOwner.steamUse900.value - othersTotal.steamUse900;
      othersTotal.steamMake900 = upgOwner.steamMake900.value - othersTotal.steamMake900;

      this.steamSheetHelper.convertItemValuesToDisplayUnit(othersTotal);
      hydrocarbonSpreadsheetItems.push(othersTotal);

      const upgraderTotalItem = this.toSpreadsheetItem(upgOwner, 'Total');
      (upgraderTotalItem as UtilitySteamSpreadsheetModel).subType = SteamSheetItemType.TOTAL;
      hydrocarbonSpreadsheetItems.push(upgraderTotalItem);
    }

    if (hydrocarbonSpreadsheetItems.length) {
      const separator = this.getInformativeItem('');
      separator.type = FlowsheetType.HYDROCARBON;
      hydrocarbonSpreadsheetItems.push(separator);
    }

    const keys = Object.keys(itemsAndTotals);
    for (const key of keys) {
      const iat = itemsAndTotals[key];

      if (iat.items.length) {
        if (c.isSolved) {
          this.steamSheetHelper.convertItemValuesToDisplayUnit(iat.total);
          iat.items.push(iat.total);
        }

        const separator = this.getInformativeItem('');
        separator.type = key;
        iat.items.push(separator);
      }
    }

    const utilitiesItems = itemsAndTotals[unitOperationsConfig.waterBoiler.key].items
      .concat(itemsAndTotals[unitOperationsConfig.waterTurbine.key].items)
      .concat(itemsAndTotals[unitOperationsConfig.waterSourceImport.key].items)
      .concat(itemsAndTotals[unitOperationsConfig.waterSinkImport.key].items)
      .concat(itemsAndTotals[unitOperationsConfig.waterSource.key].items)
      .concat(itemsAndTotals[unitOperationsConfig.waterHeader.key].items);

    if (utilitiesItems.length) {
      const utilitiesTitle = this.getInformativeItem('Utilities Flowsheet');
      utilitiesTitle.type = SteamSheetItemType.UTILITIES_TITLE;
      utilitiesItems.splice(0, 0, utilitiesTitle);
    }

    const allItems = hydrocarbonSpreadsheetItems.concat(utilitiesItems);

    for (let i = 0; i < allItems.length; i++) {
      if (i <= this.DEFAULT_NUMBER_OF_ROWS) {
        this.items[i] = allItems[i];
      } else {
        this.items.push(allItems[i]);
      }
    }

    this.grid.collectionView.refresh();
  }

  /**
   * Returns an object that stores the items and the totals of each of the different unit operations that are displayed
   * in this spreadsheet at least, for utilities
   */
  getItemsAndTotalsConfig(): {
    [unitOperationType: string]: {
      items: UtilitySteamSpreadsheetModel[];
      total: UtilitySteamSpreadsheetModel;
      getItemInRawValue: (uo: UnitOperation, rawValue: boolean) => UtilitySteamSpreadsheetModel;
    };
  } {
    const boilerTotals: UtilitySteamSpreadsheetModel = this.steamSheetHelper.getEmptySpreadsheetItem(
      'Total Boilers',
      unitOperationsConfig.waterBoiler.key,
      SteamSheetItemType.TOTAL
    );

    const turbineTotals: UtilitySteamSpreadsheetModel = this.steamSheetHelper.getEmptySpreadsheetItem(
      'Total Turbines',
      unitOperationsConfig.waterTurbine.key,
      SteamSheetItemType.TOTAL
    );

    const waterSourceImportTotals: UtilitySteamSpreadsheetModel = this.steamSheetHelper.getEmptySpreadsheetItem(
      'Total Upgrader Steam Make',
      unitOperationsConfig.waterSourceImport.key,
      SteamSheetItemType.TOTAL
    );

    const waterSinkImportTotals: UtilitySteamSpreadsheetModel = this.steamSheetHelper.getEmptySpreadsheetItem(
      'Total Upgrader Steam Use',
      unitOperationsConfig.waterSinkImport.key,
      SteamSheetItemType.TOTAL
    );

    const waterSourceTotals: UtilitySteamSpreadsheetModel = this.steamSheetHelper.getEmptySpreadsheetItem(
      'Total Water Sources',
      unitOperationsConfig.waterSource.key,
      SteamSheetItemType.TOTAL
    );

    const waterHeaderTotals: UtilitySteamSpreadsheetModel = this.steamSheetHelper.getEmptySpreadsheetItem(
      'Total Water Headers',
      unitOperationsConfig.waterHeader.key,
      SteamSheetItemType.TOTAL
    );

    const result: {
      [unitOperationType: string]: {
        items: UtilitySteamSpreadsheetModel[];
        total: UtilitySteamSpreadsheetModel;
        getItemInRawValue: (uo: UnitOperation, rawValue: boolean) => UtilitySteamSpreadsheetModel;
      };
    } = {};

    result[unitOperationsConfig.waterBoiler.key] = {
      items: [],
      total: boilerTotals,
      getItemInRawValue: (uo: UnitOperation, rawValue: boolean) => {
        return this.steamSheetHelper.boilerToSpreadsheetItem(uo as WaterBoiler, rawValue);
      },
    };

    result[unitOperationsConfig.waterTurbine.key] = {
      items: [],
      total: turbineTotals,
      getItemInRawValue: (uo: UnitOperation, rawValue: boolean) => {
        return this.steamSheetHelper.turbineToSpreadsheetItem(uo as WaterTurbine, rawValue);
      },
    };

    result[unitOperationsConfig.waterSourceImport.key] = {
      items: [],
      total: waterSourceImportTotals,
      getItemInRawValue: (uo: UnitOperation, rawValue: boolean) => {
        return this.steamSheetHelper.waterSourceImportToSpreadsheetItem(uo as WaterSourceImport, rawValue);
      },
    };

    result[unitOperationsConfig.waterSinkImport.key] = {
      items: [],
      total: waterSinkImportTotals,
      getItemInRawValue: (uo: UnitOperation, rawValue: boolean) => {
        return this.steamSheetHelper.waterSinkImportToSpreadsheetItem(uo as WaterSinkImport, rawValue);
      },
    };

    result[unitOperationsConfig.waterSource.key] = {
      items: [],
      total: waterSourceTotals,
      getItemInRawValue: (uo: UnitOperation, rawValue: boolean) => {
        return this.steamSheetHelper.waterSourceToSpreadsheetItem(uo as WaterSource, rawValue);
      },
    };

    result[unitOperationsConfig.waterHeader.key] = {
      items: [],
      total: waterHeaderTotals,
      getItemInRawValue: (uo: UnitOperation, rawValue: boolean) => {
        return this.steamSheetHelper.waterHeaderToSpreadsheetItem(uo as WaterHeader, rawValue);
      },
    };

    return result;
  }

  protected override addUnitOperationWithoutRefreshing(uo: UnitOperation) {
    const newItem = this.toSpreadsheetItem(uo);

    const indexData = this.steamSheetHelper.calculateNewItemIndex(this.items, newItem);
    let { index } = indexData;

    if (indexData.firstUtilitiesItem) {
      const titleItem = this.getInformativeItem('Utilities Flowsheet');
      titleItem.type = SteamSheetItemType.UTILITIES_TITLE;
      this.addNewItemAt(titleItem, index);
      index++;
    }

    if (indexData.firstItemOfItsType) {
      this.addNewItemAt(newItem, index);

      const separator = this.getInformativeItem('');
      separator.type = (newItem as UtilitySteamSpreadsheetModel).type;
      this.addNewItemAt(separator, index + 1);
    } else {
      this.addNewItemAt(newItem, index - 1); // considering that there will be a separator;
    }
  }

  private addNewItemAt(item: UtilitySteamSpreadsheetModel, index: number) {
    this.items.splice(index, 0, item);
  }

  isUnitOperationAllowed(uo: UnitOperation): boolean {
    return (
      (this.steamSheetHelper.isFromHydroCarbonFlowsheet(uo) || this.steamSheetHelper.isFromUtilitiesFlowsheet(uo)) &&
      this.isUpgraderChild(uo.flowsheetId)
    );
  }

  protected toSpreadsheetItem(unitOperation: UnitOperation, alternateName?: string): BaseUtilitySpreadsheetModel {
    return this.steamSheetHelper.toSpreadsheetItem(unitOperation, alternateName);
  }

  override clearResults(): void {
    for (const i of this.items) {
      const item = i as UtilitySteamSpreadsheetModel;
      item.steamMake900 = undefined;
      item.steamUse900 = undefined;
      item.steamMake600 = undefined;
      item.steamUse600 = undefined;
      item.steamMake150 = undefined;
      item.steamUse150 = undefined;
      item.steamMake50 = undefined;
      item.steamUse50 = undefined;
    }

    // remove the 'Total' row
    const itemsToRemove = (this.items as UtilitySteamSpreadsheetModel[]).filter(
      i => i.subType === SteamSheetItemType.TOTAL
    );
    for (const i of itemsToRemove) {
      const index = this.items.indexOf(i);
      this.items.splice(index, 1);
    }

    this.grid.collectionView.refresh();
  }

  override removeUnitOperation(unitOperation: SuncorUnitOperation) {
    if (this.isUnitOperationAllowed(unitOperation)) {
      const extraItemsToRemove = this.steamSheetHelper.getExtraItemsToRemove(this.items, unitOperation);

      for (const itemToRemove of extraItemsToRemove) {
        const index = this.items.indexOf(itemToRemove);
        this.items.splice(index, 1);
      }
    }

    super.removeUnitOperation(unitOperation);
  }

  override addTotal() {
    // nothing is done here as the total is added when converting a case to a spreadsheet
  }

  override assignValueToUnitOperation(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    valueAsNumber: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    item: BaseUtilitySpreadsheetModel,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    flexSheetEvent: any
  ): SuncorUnitOperation {
    return undefined;
  }

  getInformativeItem(text: string): UtilitySteamSpreadsheetModel {
    return {
      unitOperationName: text,
    };
  }

  isUpgraderChild(flowsheetId: string): boolean {
    const steamAndPowerSubFs = this.coreService.currentCase.filterUnitOperations(uo => {
      return uo instanceof WaterUtilityUnitOperation && uo.flowsheetId === this.ownerUnitOperationId;
    });
    return flowsheetId === this.ownerUnitOperationId || flowsheetId === steamAndPowerSubFs[0].id;
  }

  protected getColumnsConfiguration(): FlexSheetColumn[] {
    return [
      {
        header: 'Unit Operation Name',
        binding: 'unitOperationName',
        dataType: DataType.String,
        width: 200,
        isReadOnly: true,
      },
      {
        header: '900# Steam Make',
        binding: 'steamMake900',
        dataType: DataType.Number,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '900# Steam Use',
        binding: 'steamUse900',
        dataType: DataType.Number,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '600# Steam Make',
        binding: 'steamMake600',
        dataType: DataType.Number,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '600# Steam Use',
        binding: 'steamUse600',
        dataType: DataType.Number,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '150# Steam Make',
        binding: 'steamMake150',
        dataType: DataType.Number,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '150# Steam Use',
        binding: 'steamUse150',
        dataType: DataType.Number,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '50# Steam Make',
        binding: 'steamMake50',
        dataType: DataType.Number,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '50# Steam Use',
        binding: 'steamUse50',
        dataType: DataType.Number,
        width: 120,
        isReadOnly: true,
      },
      // empty columns to fill the screen...
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
    ];
  }
}
