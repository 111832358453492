<div class="row">
  <div class="col-xs-12 col-lg-6 side-padding-5x">
    <ul class="nav nav-pills" role="tablist">
      <li role="presentation" class="active custom-pill">
        <a
          href="#{{ upgraderId }}usrSteamMake50Tab"
          role="tab"
          data-toggle="tab"
          [attr.aria-controls]="'#{{upgraderId}}usrSteamMake50Tab'">
          Make
        </a>
      </li>

      <li role="presentation" class="custom-pill">
        <a
          href="#{{ upgraderId }}usrSteamUse50Tab"
          role="tab"
          data-toggle="tab"
          [attr.aria-controls]="'#{{upgraderId}}usrMakeUse50Tab'">
          Use
        </a>
      </li>
    </ul>

    <div class="tab-content p-t-10-x">
      <div role="tabpanel" class="tab-pane fade in active" id="{{ upgraderId }}usrSteamMake50Tab">
        <sob-utilities-summary-report-chart
          [data]="steamMake50ReportData"
          *ngIf="steamMake50ReportData"
          [idPrefix]="upgraderId"
          [upgraderName]="upgraderName">
        </sob-utilities-summary-report-chart>
      </div>

      <div role="tabpanel" class="tab-pane fade in" id="{{ upgraderId }}usrSteamUse50Tab">
        <sob-utilities-summary-report-chart
          [data]="steamUse50ReportData"
          *ngIf="steamUse50ReportData"
          [idPrefix]="upgraderId"
          [upgraderName]="upgraderName">
        </sob-utilities-summary-report-chart>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-lg-6 side-padding-5x">
    <ul class="nav nav-pills" role="tablist">
      <li role="presentation" class="active custom-pill">
        <a
          href="#{{ upgraderId }}usrSteamMake150Tab"
          role="tab"
          data-toggle="tab"
          [attr.aria-controls]="'#{{upgraderId}}usrSteamMake150Tab'">
          Make
        </a>
      </li>

      <li role="presentation" class="custom-pill">
        <a
          href="#{{ upgraderId }}usrSteamUse150Tab"
          role="tab"
          data-toggle="tab"
          [attr.aria-controls]="'#{{upgraderId}}usrMakeUse150Tab'">
          Use
        </a>
      </li>
    </ul>

    <div class="tab-content p-t-10-x">
      <div role="tabpanel" class="tab-pane fade in active" id="{{ upgraderId }}usrSteamMake150Tab">
        <sob-utilities-summary-report-chart
          [data]="steamMake150ReportData"
          *ngIf="steamMake150ReportData"
          [idPrefix]="upgraderId"
          [upgraderName]="upgraderName">
        </sob-utilities-summary-report-chart>
      </div>

      <div role="tabpanel" class="tab-pane fade in" id="{{ upgraderId }}usrSteamUse150Tab">
        <sob-utilities-summary-report-chart
          [data]="steamUse150ReportData"
          *ngIf="steamUse150ReportData"
          [idPrefix]="upgraderId"
          [upgraderName]="upgraderName">
        </sob-utilities-summary-report-chart>
      </div>
    </div>
  </div>
</div>

<hr class="m-y-10-x" />

<div class="row">
  <div class="col-xs-6 side-padding-5x" style="margin-top: 10px">
    <ul class="nav nav-pills" role="tablist">
      <li role="presentation" class="active custom-pill">
        <a
          href="#{{ upgraderId }}usrSteamMake600Tab"
          role="tab"
          data-toggle="tab"
          [attr.aria-controls]="'#{{upgraderId}}usrSteamMake600Tab'">
          Make
        </a>
      </li>

      <li role="presentation" class="custom-pill">
        <a
          href="#{{ upgraderId }}usrSteamUse600Tab"
          role="tab"
          data-toggle="tab"
          [attr.aria-controls]="'#{{upgraderId}}usrMakeUse600Tab'">
          Use
        </a>
      </li>
    </ul>

    <div class="tab-content p-t-10-x">
      <div role="tabpanel" class="tab-pane fade in active" id="{{ upgraderId }}usrSteamMake600Tab">
        <sob-utilities-summary-report-chart
          [data]="steamMake600ReportData"
          *ngIf="steamMake600ReportData"
          [idPrefix]="upgraderId"
          [upgraderName]="upgraderName">
        </sob-utilities-summary-report-chart>
      </div>

      <div role="tabpanel" class="tab-pane fade in" id="{{ upgraderId }}usrSteamUse600Tab">
        <sob-utilities-summary-report-chart
          [data]="steamUse600ReportData"
          *ngIf="steamUse600ReportData"
          [idPrefix]="upgraderId"
          [upgraderName]="upgraderName">
        </sob-utilities-summary-report-chart>
      </div>
    </div>
  </div>

  <div class="col-xs-6 side-padding-5x" style="margin-top: 10px">
    <ul class="nav nav-pills" role="tablist">
      <li role="presentation" class="active custom-pill">
        <a
          href="#{{ upgraderId }}usrSteamMake900Tab"
          role="tab"
          data-toggle="tab"
          [attr.aria-controls]="'#{{upgraderId}}usrSteamMake900Tab'">
          Make
        </a>
      </li>

      <li role="presentation" class="custom-pill">
        <a
          href="#{{ upgraderId }}usrSteamUse900Tab"
          role="tab"
          data-toggle="tab"
          [attr.aria-controls]="'#{{upgraderId}}usrMakeUse900Tab'">
          Use
        </a>
      </li>
    </ul>

    <div class="tab-content p-t-10-x">
      <div role="tabpanel" class="tab-pane fade in active" id="{{ upgraderId }}usrSteamMake900Tab">
        <sob-utilities-summary-report-chart
          [data]="steamMake900ReportData"
          *ngIf="steamMake900ReportData"
          [idPrefix]="upgraderId"
          [upgraderName]="upgraderName">
        </sob-utilities-summary-report-chart>
      </div>

      <div role="tabpanel" class="tab-pane fade in" id="{{ upgraderId }}usrSteamUse900Tab">
        <sob-utilities-summary-report-chart
          [data]="steamUse900ReportData"
          *ngIf="steamUse900ReportData"
          [idPrefix]="upgraderId"
          [upgraderName]="upgraderName">
        </sob-utilities-summary-report-chart>
      </div>
    </div>
  </div>
</div>
