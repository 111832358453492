import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { WjTreeView } from '@grapecity/wijmo.angular2.nav';
import { FlowsheetTreeService } from '../../_services/sub-flowsheet/flowsheet-tree/flowsheet-tree.service';
import { CoreService } from '../../_services/core.service';

@Component({
  selector: 'sob-flowsheet-tree-selector',
  templateUrl: './flowsheet-tree-selector.component.html',
  styleUrls: ['./flowsheet-tree-selector.component.css'],
})
export class FlowsheetTreeSelectorComponent implements OnInit {
  @ViewChild('flowsheetsTree', { static: true }) flowsheetsTree: WjTreeView;
  @Input() modalName: string;
  @Output() emitFlowsheetOwnerId = new EventEmitter<string>();
  treeData: string[] = [];
  selectedFlowsheet: string;
  flowsheetOwnerId: string;

  constructor(private flowsheetTree: FlowsheetTreeService, private coreService: CoreService) {}

  ngOnInit(): void {
    const $modal = $(`#${this.modalName}`);

    $modal.on('show.bs.modal', () => {
      this.initializeTree();
    });

    $('#pillContainer').on('shown.bs.tab', () => {
      if ($('#multi-period-settings-container').hasClass('active')) {
        this.initializeTree();
      }
    });
  }

  initializeTree() {
    this.selectedFlowsheet = null;
    this.treeData = this.getTreeData();
  }

  getTreeData() {
    const nodes = [];
    nodes.push({ header: 'Main Flowsheet', items: [] });

    for (let i = 0; i < this.flowsheetTree.getFlowsheetOwnersCount(this.currentCase); i++) {
      nodes.push(this.getNode_(i));
    }
    return nodes;
  }

  private getNode_(position: number) {
    let sortedFlowsheets;
    const flowsheetTree = this.flowsheetTree.getFlowsheetTree(this.currentCase).root;
    flowsheetTree.children.forEach((parentNode, index) => {
      if (index === position) {
        sortedFlowsheets = { header: parentNode.name };
        if (parentNode.children.length !== 0) {
          sortedFlowsheets.items = [];
          parentNode.children.forEach(childNode => {
            sortedFlowsheets.items.push({ header: childNode.name });
          });
        }
      }
    });
    return sortedFlowsheets;
  }

  keepDropdownOpen(event: any) {
    event.stopPropagation();
  }

  onCheckedItemsChanged() {
    const items = this.flowsheetsTree.checkedItems;
    let flowsheetOwnerId;

    if (items.length === 1) {
      this.disableCheckBoxes();
      flowsheetOwnerId = this.flowsheetTree.getFlowsheetOwnerIdByName(items[0].header, this.currentCase);
      if (!flowsheetOwnerId) {
        flowsheetOwnerId = '';
      }
      this.selectedFlowsheet = items[0].header;
    } else if (items.length > 1) {
      this.disableCheckBoxes();
      flowsheetOwnerId = this.flowsheetOwnerId;
    } else {
      this.selectedFlowsheet = null;
      this.enableCheckBoxes();
    }

    this.emitFlowsheetOwnerId.emit(flowsheetOwnerId);
  }

  disableCheckBoxes() {
    this.flowsheetsTree.nodes.forEach(parentNode => {
      if (parentNode.nodes) {
        if (parentNode.isChecked) {
          this.flowsheetOwnerId = this.flowsheetTree.getFlowsheetOwnerIdByName(
            parentNode.dataItem.header,
            this.currentCase
          );
          this.selectedFlowsheet = parentNode.dataItem.header;
          parentNode.nodes.forEach(childNode => {
            childNode.checkBox.disabled = true;
          });
        } else {
          parentNode.checkBox.disabled = true;
          parentNode.nodes.forEach(childNode => {
            if (!childNode.isChecked) {
              childNode.checkBox.disabled = true;
            }
          });
        }
      } else if (!parentNode.isChecked && !parentNode.nodes) {
        parentNode.checkBox.disabled = true;
      }
    });
  }

  enableCheckBoxes() {
    for (let node = this.flowsheetsTree.getFirstNode(); node; node = node.next()) {
      node.checkBox.disabled = false;
    }
  }

  get currentCase() {
    return this.coreService.currentCase;
  }
}
