import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { attachSimVarEvent } from '../unit-converter.helper';
import { ReadyStatus } from '../../../_config/ready-status.enum';
import { CoreService } from '../../../_services/core.service';

declare let unitConverter: any;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sim-var-input',
  template: `<div class="sim-var-input">
    <div class="{{ controlWidthClass }}" *ngIf="!hiddenUnit">
      <sfe-unit-select
        [selectId]="inputId + 'Unit'"
        [simVar]="simVar"
        [elClass]="'form-control'"
        [disabled]="disabled"
        [unitSubset]="unitSubset">
      </sfe-unit-select>
    </div>
    <div class="{{ isConstraint ? 'col-xs-3' : controlWidthClass }}" [style.padding]="isConstraint ? 0 : ''">
      <input
        type="text"
        id="{{ inputId }}"
        [(ngModel)]="displayValue"
        (change)="valueChanged($event.target.value)"
        class="form-control {{ simVar.variableStatus }} {{ simVar.readyStatus }}"
        [disabled]="readOnly || disabled || multiPeriodEnabled" />
    </div>
    <div *ngIf="isConstraint">
      <label for="isActive" class="control-label col-sm-4"> </label>
      <div style="position: relative">
        <label class="control-label">
          <input
            type="checkbox"
            id="isActive"
            [disabled]="disabledConstraint"
            style="position: absolute; top: 6px; right: 0"
            [(ngModel)]="simVar.isActive" />
        </label>
      </div>
    </div>
  </div>`,
})
export class SimVarInputComponent implements AfterViewInit, OnInit, AfterContentInit, OnChanges {
  @Input() simVar: any;
  @Input() inputId: any;
  @Input() readOnly: boolean;
  @Input() hiddenUnit: boolean;
  @Input() disabled: boolean;
  @Input() min: number;
  @Input() max: number;
  @Input() isConstraint: boolean;
  @Input() disabledConstraint: boolean;
  @Input() widerControls: boolean;
  @Input() unitSubset: string;

  // use this for owner base objects that don't apply for conventional
  // save/convert to internal unit/update status process
  @Input() transientValue: boolean;

  displayValue: string;
  originalValue: number;

  ref: ChangeDetectorRef;
  controlWidthClass = 'col-xs-4';

  get multiPeriodEnabled(): boolean {
    return this.coreService.currentCase.multiPeriodEnabled;
  }

  constructor(ref: ChangeDetectorRef, private coreService: CoreService) {
    this.ref = ref;
  }

  ngOnInit() {
    this.originalValue = this.simVar.value;
    if (this.widerControls) {
      this.controlWidthClass = 'col-xs-6';
    }
  }

  ngAfterViewInit(): void {
    attachSimVarEvent($(`#${this.inputId}`), $(`#${this.inputId}Unit`), this.simVar.quantity, this);
  }

  ngAfterContentInit(): void {
    const conversion = unitConverter(this.simVar.quantity).convert(
      this.simVar.value,
      unitConverter(this.simVar.quantity).getInternalUnit(),
      this.simVar.unit
    );

    this.setValue(conversion.getValue(), conversion.round().format());
  }

  getConversionToInternalUnit(value) {
    return unitConverter(this.simVar.quantity).convert(
      value,
      this.simVar.unit,
      unitConverter(this.simVar.quantity).getInternalUnit()
    );
  }

  getConversionToUnit(value, unit) {
    return unitConverter(this.simVar.quantity).convert(
      value,
      unitConverter(this.simVar.quantity).getInternalUnit(),
      unit
    );
  }

  ngOnChanges(changes) {
    if (this.simVar.value === null || typeof this.simVar.value === 'undefined') {
      this.setValue(null, null);
    }
  }

  valueChanged(value) {
    const valueInternal = this.getConversionToInternalUnit(value).getValue();

    if (valueInternal < this.simVar.lowerBound || valueInternal > this.simVar.upperBound) {
      this.simVar.readyStatus = ReadyStatus.NOT_VALID;
      this.displayValue = '';
    } else {
      this.simVar.readyStatus = ReadyStatus.READY;
    }
  }

  setValue(rawValue, roundedValue) {
    if (!this.transientValue) {
      this.simVar.value = rawValue;
    }

    this.displayValue = roundedValue;
  }
}
