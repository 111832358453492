import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { commaNumberFormat, hasNumericValue, roundNumber } from '../../_utils/utils';
import { VariableStatus } from '../../_config/variable-status.enum';

declare let unitConverter: any;

@Component({
  selector: 'sob-reactive-variable-value-input',
  template: `<input
    type="text"
    class="form-control {{ isInvalid ? 'is-invalid' : '' }} {{ this.statusClass }}"
    [disabled]="isDisabled || isSolverCalculated"
    (input)="onInput($event.target['value'])" />`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReactiveVariableValueInputComponent),
      multi: true,
    },
  ],
})
export class ReactiveVariableValueInputComponent implements OnInit, ControlValueAccessor {
  @Input() isInvalid = false;
  @Input() format = true;
  @Input() round = true;
  @Input() fractionDigits = -1;

  // Just a data structure so it still can be used with plain numbers...
  // or in array-like forms
  // perfectly fits the data structure of an emat variable
  // property not named ematVariable because it can be used with plain doubles as well
  // e.g. the components of fluid analysis
  @Input() valueMetaData: { unit?: string; quantity?: string; variableStatus?: string } = {};

  @Input() unit: string;
  @Input() quantity: string;
  @Input() status: string | VariableStatus;
  @Input() alwaysDefault: boolean;
  @Input() scientificNotationValue = true;

  value: number;
  displayValue: string;
  internalUnit: string;
  isDisabled: boolean;

  onChange = (_: any) => {};
  onTouch = () => {};

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (this.valueMetaData) {
      this.quantity = this.valueMetaData.quantity ? this.valueMetaData.quantity : this.quantity;
      this.unit = this.valueMetaData.unit ? this.valueMetaData.unit : this.unit;
      this.internalUnit = unitConverter(this.quantity).getInternalUnit();
    }
  }

  onInput(value: any) {
    this.displayValue = value;
    this.value = this.convertToInternalUnit(value);

    this.onTouch();
    this.onChange(this.value);

    // when user enters a value, the status should be updated to userSpecified
    if (!this.alwaysDefault) {
      this.setUserSpecifiedStatus();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // this method is executed when value is changed externally
  writeValue(value: any): void {
    // assume the value should be written in internal unit
    // and is a number
    this.value = value;

    this.displayValueOnInput();
  }

  updateUnit(unit: string) {
    this.unit = unit;
    this.displayValueOnInput();
  }

  // region display value handling
  private displayValueOnInput() {
    this.setDisplayValue();
    this.renderer.setProperty(this.el.nativeElement.firstChild, 'value', this.displayValue);
  }

  private setDisplayValue() {
    this.displayValue = unitConverter(this.quantity).convert(this.value, this.internalUnit, this.unit).getValue();

    if (this.round && hasNumericValue(this.displayValue as any)) {
      let conversionFractionDigits =
        this.fractionDigits > -1 ? this.fractionDigits : unitConverter.conversions[this.quantity][this.unit].precision;

      // set default if no unitConverter precision or no provided fraction digits
      conversionFractionDigits = hasNumericValue(conversionFractionDigits)
        ? conversionFractionDigits
        : unitConverter.DEFAULT_PRECISION;

      this.displayValue = roundNumber(+this.displayValue, conversionFractionDigits).toString();
    }

    const displayValueAsNumber = unitConverter.parseFloatString(this.displayValue);
    if (
      this.scientificNotationValue &&
      (displayValueAsNumber > 9999.9 ||
        displayValueAsNumber < -999999 ||
        (displayValueAsNumber < 0.00001 && displayValueAsNumber > -0.00001))
    ) {
      this.displayValue =
        displayValueAsNumber !== 0 ? this.scientificNotation(displayValueAsNumber) : this.displayValue;
    }

    if (this.format) {
      this.displayValue = commaNumberFormat(this.displayValue);
    }
  }

  private convertToInternalUnit(value) {
    const valueInInternalUnit = unitConverter(this.quantity).convert(value, this.unit, this.internalUnit).getValue();
    return hasNumericValue(valueInInternalUnit) ? valueInInternalUnit : undefined;
  }

  // endregion

  // region variable status handling
  private setUserSpecifiedStatus() {
    this.status = VariableStatus.USER_SPECIFIED;
    this.valueMetaData.variableStatus = VariableStatus.USER_SPECIFIED;
  }

  get isSolverCalculated() {
    // TODO fix this crp
    return (
      this.status === VariableStatus.SOLVER_CALCULATED ||
      this.valueMetaData.variableStatus === VariableStatus.SOLVER_CALCULATED
    );
  }

  // TODO make this better... why having two input parameters?
  get statusClass() {
    if (this.alwaysDefault) {
      return VariableStatus.DEFAULT;
    }

    return this.valueMetaData.variableStatus ? this.valueMetaData.variableStatus : this.status;
  }

  // endregion

  // TODO scientific notation precision
  private scientificNotation(value: number) {
    return value.toExponential(3);
  }

  // endregion
}
