import { BaseObject } from '../base-object';
import { SimulationVariable } from '../simulation-variable';
import { Case } from '../case';
import { UncertainParameter } from './uncertain-parameter';

export class Parameter extends BaseObject {
  category = 'parameter';
  simulationVariable: SimulationVariable;
  minimumValue: SimulationVariable;
  maximumValue: SimulationVariable;
  constructor(parameter: any, ownerCase: Case) {
    super(parameter.id, ownerCase);
    if (parameter.simulationVariable instanceof SimulationVariable) {
      this.simulationVariable = this.ownerCase.getSimulationVariable(parameter.simulationVariable.id);
      this.initMemberVariables(parameter);
    }
  }

  protected initMemberVariables(parameter: any) {
    if (parameter.maximumValue == null) {
      this.maximumValue = new SimulationVariable(
        {
          value: this.simulationVariable.value * 1.05,
          unit: this.simulationVariable.unit,
          quantity: this.simulationVariable.quantity,
        },
        this.ownerCase,
        this
      );
    } else {
      this.maximumValue = new SimulationVariable(
        {
          value: parameter.maximumValue.value,
          unit: parameter.maximumValue.unit,
          quantity: this.simulationVariable.quantity,
        },
        this.ownerCase,
        this
      );
    }

    if (parameter.minimumValue == null) {
      this.minimumValue = new SimulationVariable(
        {
          value: this.simulationVariable.value * 0.95,
          unit: this.simulationVariable.unit,
          quantity: this.simulationVariable.quantity,
        },
        this.ownerCase,
        this
      );
    } else {
      this.minimumValue = new SimulationVariable(
        {
          value: parameter.minimumValue.value,
          unit: parameter.minimumValue.unit,
          quantity: this.simulationVariable.quantity,
        },
        this.ownerCase,
        this
      );
    }
  }

  override convertToInternalUnit(): void {
    this.maximumValue.value = this.maximumValue.convertToInternalUnit();
    this.minimumValue.value = this.minimumValue.convertToInternalUnit();
  }

  override overwriteValues(p: UncertainParameter): void {
    this.minimumValue.overwriteValues(p.minimumValue);
    this.maximumValue.overwriteValues(p.maximumValue);
  }

  override setSimulationVariablesOwner() {
    this.maximumValue.setOwnerBaseObject(this);
    this.minimumValue.setOwnerBaseObject(this);
  }

  override dePersist(persistedObj: any) {
    this.simulationVariable = this.ownerCase.getSimulationVariable(persistedObj.simulationVariable);
    this.maximumValue = this.getSimulationVariableSafe(persistedObj.maximumValue);
    this.minimumValue = this.getSimulationVariableSafe(persistedObj.minimumValue);
  }

  public toJSON() {
    return {
      id: this.id,
      name: this.name,
      category: this.category,
      simulationVariable: this.simulationVariable.id,
      maximumValue: this.maximumValue.id,
      minimumValue: this.minimumValue.id,
    };
  }
}
