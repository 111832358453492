<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
  </li>
  <li role="presentation">
    <a href="#economics" aria-controls="economics" role="tab" data-toggle="tab">Economics</a>
  </li>

  <li role="presentation">
    <a href="#hydrogenPlantComments" aria-controls="hydrogenPlantComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label [attr.for]="'hydrogenProduction'" class="pw-label control-label col-sm-4"> Hydrogen Production </label>
          <sim-var-input [inputId]="'hydrogenProduction'" [simVar]="unitOperation.hydrogenProduction" [readOnly]="true">
          </sim-var-input>
        </div>
        <hr />
        <h4 class="subtitle">Constraints</h4>
        <div class="form-group">
          <label [attr.for]="'maximumHydrogenProduction'" class="pw-label control-label col-sm-4">
            Maximum Hydrogen Production
          </label>
          <sim-var-input [inputId]="'maximumHydrogenProduction'" [simVar]="unitOperation.maximumHydrogenProduction">
          </sim-var-input>
        </div>
        <hr />
        <h4 class="subtitle">GHG</h4>
        <div class="form-group">
          <label [attr.for]="'hydrogenGHGIntensity'" class="pw-label control-label col-sm-4">
            Process GHG Intensity (Reaction)
          </label>
          <sim-var-input [inputId]="'hydrogenGHGIntensity'" [simVar]="unitOperation.hydrogenGHGIntensity">
          </sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'ghgEmissions'" class="pw-label control-label col-sm-4"> GHG Emissions </label>
          <sim-var-input [inputId]="'ghgEmissions'" [simVar]="unitOperation.ghgEmissions" [readOnly]="true">
          </sim-var-input>
        </div>
      </div>
      <hr />
      <h4 class="subtitle">Utilities</h4>
      <div class="form-horizontal">
        <sob-steam-use-utility [unitOperation]="unitOperation"></sob-steam-use-utility>
        <sob-gas-contributor [unitOperation]="unitOperation"></sob-gas-contributor>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="economics">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">OPEX</h4>

        <div class="form-group">
          <label [attr.for]="'opexVar'" class="pw-label control-label col-sm-4"> Variable </label>
          <sim-var-input [inputId]="'opexVar'" [simVar]="unitOperation.opexVar"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexGasDiesel'" class="pw-label control-label col-sm-4"> Gas Diesel </label>
          <sim-var-input [inputId]="'opexGasDiesel'" [simVar]="unitOperation.opexGasDiesel"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexPower'" class="pw-label control-label col-sm-4"> Power </label>
          <sim-var-input [inputId]="'opexPower'" [simVar]="unitOperation.opexPower"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexCarbonDioxide'" class="pw-label control-label col-sm-4"> CO2 </label>
          <sim-var-input [inputId]="'opexCarbonDioxide'" [simVar]="unitOperation.opexCarbonDioxide" [readOnly]="true">
          </sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexFixed'" class="pw-label control-label col-sm-4"> Fixed </label>
          <sim-var-input [inputId]="'opexFixed'" [simVar]="unitOperation.opexFixed"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexTotal'" class="pw-label control-label col-sm-4"> Total </label>
          <sim-var-input [inputId]="'opexTotal'" [simVar]="unitOperation.opexTotal" [readOnly]="true"></sim-var-input>
        </div>

        <hr />
        <h4 class="subtitle">CAPEX</h4>

        <div class="form-group">
          <label [attr.for]="'capexAmortized'" class="pw-label control-label col-sm-4"> Amortized CAPEX </label>
          <sim-var-input [inputId]="'capexAmortized'" [simVar]="unitOperation.capexAmortized"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'capexTotal'" class="pw-label control-label col-sm-4"> Total </label>
          <sim-var-input [inputId]="'capexTotal'" [simVar]="unitOperation.capexTotal" [readOnly]="true"></sim-var-input>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="hydrogenPlantComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
