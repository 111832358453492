<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
  </li>

  <li role="presentation">
    <a href="#waterCogenerationComments" aria-controls="waterCogenerationComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg {{ this.unitOperation.comments ? 'gentle-hover-shake' : '' }}" aria-hidden="true">
      </i>
      Comments
    </a>
  </li>
</ul>
<div class="tab-content pw-margin tab-content-padding" [formGroup]="propertyWindowFormGroup">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Gas Turbine</h4>
        <hr />
        <br />

        <div class="form-group" formGroupName="heatRate">
          <label class="control-label col-sm-4"> Heat Rate </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.heatRate.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-6">
                <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.heatRate">
                </sob-reactive-variable-value-input>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" formGroupName="power">
          <label class="control-label col-sm-4"> Electricity Generation </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.power.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-6">
                <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.power">
                </sob-reactive-variable-value-input>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" formGroupName="quality">
          <label class="control-label col-sm-4"> Quality </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.quality.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-6">
                <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.quality">
                </sob-reactive-variable-value-input>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="customModelEnabled" class="control-label col-sm-4">
            Use Custom Model for OTSG Energy Demand
          </label>
          <div class="col-sm-4">
            <label class="control-label">
              <input type="checkbox" id="customGasModelEnabled" formControlName="useCustomModel" />
            </label>
          </div>

          <div class="clearfix"></div>
        </div>

        <div *ngIf="useCustomModel">
          <sob-open-code-editor-button
            [code]="unitOperation.hrsgScript"
            [defaultCodeProvider]="defaultCodeProvider"
            [instanceId]="'cogenerationCodeEditor'"
            [disabled]="!useCustomModel"
            (codeSaved)="saveCustomModel($event)">
          </sob-open-code-editor-button>
          <br />
          <br />
        </div>

        <div class="form-group" formGroupName="otsgEnergyDemand">
          <label class="control-label col-sm-4"> OTSG Energy Demand </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.otsgEnergyDemand.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-6">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.otsgEnergyDemand">
                </sob-reactive-variable-value-input>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" formGroupName="turbineEnergyDemand">
          <label class="control-label col-sm-4"> Turbine Energy Demand </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select
                  formControlName="unit"
                  [quantity]="unitOperation.turbineEnergyDemand.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-6">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.turbineEnergyDemand">
                </sob-reactive-variable-value-input>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" formGroupName="totalEnergyDemand">
          <label class="control-label col-sm-4"> Total Energy Demand </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.totalEnergyDemand.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-6">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.totalEnergyDemand">
                </sob-reactive-variable-value-input>
              </div>
            </div>
          </div>
        </div>
        <h4 class="subtitle">GHG</h4>
        <hr />
        <br />

        <div class="form-group" formGroupName="fuelGasEmissionFactor">
          <label class="control-label col-sm-4"> Fuel Gas Emission Factor </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select
                  formControlName="unit"
                  [quantity]="unitOperation.fuelGasEmissionFactor.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-6">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.fuelGasEmissionFactor">
                </sob-reactive-variable-value-input>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" formGroupName="fuelGasHeatingValue">
          <label class="control-label col-sm-4"> Fuel Gas Heating Value (LHV) </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select
                  formControlName="unit"
                  [quantity]="unitOperation.fuelGasHeatingValue.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-6">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.fuelGasHeatingValue">
                </sob-reactive-variable-value-input>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" formGroupName="ghgEmissions">
          <label class="control-label col-sm-4"> GHG Emissions </label>
          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.ghgEmissions.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-6">
                <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.ghgEmissions">
                </sob-reactive-variable-value-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="constraints">
    <p style="text-align: right; position: relative">
      <span style="position: absolute; right: -20px; top: -20px; font-weight: bold">Active</span>
    </p>

    <div class="form-horizontal">
      <ng-container>
        <div class="form-group" formGroupName="massCapacity">
          <label class="control-label col-sm-4"> Maximum Feed Capacity </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-5">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.massCapacity.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-5">
                <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.massCapacity">
                </sob-reactive-variable-value-input>
              </div>
              <div class="col-xs-2">
                <sob-reactive-constraint-check-box
                  formControlName="isActive"
                  [isConstraint]="true"
                  [valueMetaData]="unitOperation.massCapacity"></sob-reactive-constraint-check-box>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" formGroupName="minimumMassFlowrate">
          <label class="control-label col-sm-4"> Minimum Mass Flowrate </label>

          <div class="col-sm-8" sobReactiveSimVarInput>
            <div class="row">
              <div class="col-xs-5">
                <sob-reactive-unit-select
                  formControlName="unit"
                  [quantity]="unitOperation.minimumMassFlowrate.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-5">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.minimumMassFlowrate">
                </sob-reactive-variable-value-input>
              </div>
              <div class="col-xs-2">
                <sob-reactive-constraint-check-box
                  formControlName="isActive"
                  [isConstraint]="true"
                  [valueMetaData]="unitOperation.minimumMassFlowrate"></sob-reactive-constraint-check-box>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="waterCogenerationComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
