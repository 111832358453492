<ng-container [formGroup]="energyStreamFormGroup">
  <div class="form-group" formGroupName="energyFlow">
    <label class="control-label col-sm-4"> Energy Demand </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="energyStream.energyFlow.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="energyStream.energyFlow">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>
</ng-container>
