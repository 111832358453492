<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
  </li>

  <li role="presentation">
    <a href="#waterValveComments" aria-controls="waterValveComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Inputs</h4>
        <hr />
        <br />

        <div class="form-group">
          <label for="pressureCalculationMode" class="pw-label control-label col-sm-4">
            Pressure Calculation Mode
          </label>
          <div class="col-sm-8" id="pressureCalculationMode">
            <select id="selectMode" (change)="pressureCalculationModeChanged($event.target.value)" class="form-control">
              <option value="Pressure Drop">Pressure Drop</option>
              <option value="Outlet Pressure">Outlet Pressure</option>
            </select>
          </div>
        </div>

        <div class="form-group" id="pressure-drop">
          <label [attr.for]="'pressureDrop'" class="pw-label control-label col-sm-4"> Pressure Drop </label>
          <sim-var-input [inputId]="'pressureDrop'" [simVar]="unitOperation.pressureDrop"> </sim-var-input>
        </div>

        <div class="form-group" id="outlet-pressure">
          <label [attr.for]="'outletPressure'" class="pw-label control-label col-sm-4"> Outlet Pressure </label>
          <sim-var-input [inputId]="'outletPressure'" [simVar]="unitOperation.outletPressure"></sim-var-input>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="constraints">
    <p style="text-align: right; position: relative">
      <span style="position: absolute; right: -25px; top: -20px; font-weight: bold">Active</span>
    </p>
    <div class="form-horizontal">
      <div class="form-group">
        <label [attr.for]="'capacity'" class="pw-label control-label col-sm-4"> Maximum Feed Capacity </label>
        <sim-var-input [inputId]="'capacity'" [isConstraint]="true" [simVar]="unitOperation.massCapacity">
        </sim-var-input>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="waterValveComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
