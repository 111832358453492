import { CaseSettings } from './case-settings';
import { Case } from './case';
import { CaseStudyManager } from './_case-study/case-study-manager';
import { KpiManager } from './_case-study/kpi-manager';
import { ObjectiveFunction } from './_optimization/objective-function';
import { SuncorCalculator } from './suncor-calculator';
import { Flowsheet } from './flowsheet-manager/flowsheet';
import { AssayManager } from './_fluid/assay-manager';
import { MultiPeriodSettings } from './_multi-period/multi-period-settings';

export class Project {
  cases: Array<Case> = [];
  version: string;
  name: string;

  fileName: string;

  createCase(): Case {
    const c = new Case({});
    this.cases.push(c);
    return c;
  }

  createEmptyCase(): Case {
    const c = this.createCase();

    c.calculator = new SuncorCalculator({}, c);
    c.addToPools(c.calculator);
    c.calculator.addSimVarsToPool();

    c.caseSettings = new CaseSettings({}, c);
    c.caseSettings.setDefaultValues();
    c.addToPools(c.caseSettings);
    c.caseSettings.addSimVarsToPool();

    c.caseStudyManager = new CaseStudyManager({}, c);
    c.addToPools(c.caseStudyManager);

    c.assayManager = new AssayManager({}, c);
    c.addToPools(c.assayManager);

    c.caseStudyManager.kpiManager = new KpiManager({}, c);
    c.initCaseStudyManagerCategories();
    c.addToPools(c.caseStudyManager.kpiManager); // does this make sense?
    c.caseStudyManager.kpiManager.addDefaultKpis(c.calculator);

    c.objectiveFunction = new ObjectiveFunction({}, c);
    c.addToPools(c.objectiveFunction);
    c.name = 'Empty case';
    c.setActiveFlowsheet(new Flowsheet({}, c));
    c.setMainFlowsheetId(c.getActiveFlowsheet().id);
    c.addToPools(c.getActiveFlowsheet());

    c.multiPeriodSettings = new MultiPeriodSettings({}, c);
    c.initMultiPeriodCategories();
    c.addToPools(c.multiPeriodSettings);
    c.multiPeriodSettings.addSimVarsToPool();

    return c;
  }

  addCase(c: Case) {
    this.cases.push(c);
  }

  findCaseById(id: string) {
    return this.cases.find(c => c.id === id);
  }

  findCaseIndexById(id: string) {
    return this.cases.findIndex(c => c.id === id);
  }

  toJSON() {
    return {
      cases: this.cases,
      version: this.version,
      name: this.name,
    };
  }
}
