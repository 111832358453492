import { SimulationVariable } from '..';
import { SuncorUnitOperation } from './suncor-unit-operation';
import { Case } from '../case';
import { KpiProvider } from '../_case-study/kpi-provider';
import { ConstrainableObject } from '../_interfaces/constrainable-object';
import { UnitOperationConstraints } from './unit-operation-constraints';
import { Quantity } from '../../_config/quantity.enum';
import { SimulationVariableName } from '../../_config/simulation-variable-name.enum';
import { ParameterProvider } from '../_case-study/parameter-provider';
import { MultiPeriodParameterProvider } from '../_multi-period/multi-period-parameter-provider';
import { unitOperationsConfig } from '../../_config/unit-operations.config';

declare let unitConverter: any;
export class ThirdPartyRefinery
  extends SuncorUnitOperation
  implements KpiProvider, ConstrainableObject, ParameterProvider, MultiPeriodParameterProvider
{
  category = unitOperationsConfig.thirdPartyRefinery.key;

  volumetricFlowrate: SimulationVariable;
  cumulativeGhgEmissions: SimulationVariable;
  feedstockValue: SimulationVariable;

  lightEndsMargin: SimulationVariable;
  naphthaMargin: SimulationVariable;
  distillateMargin: SimulationVariable;
  gasOilMargin: SimulationVariable;
  vacuumResidueMargin: SimulationVariable;

  suncorProfitPercentage: SimulationVariable;

  maximumSulfurContent: SimulationVariable;
  maximumMassDensity: SimulationVariable;

  constraints: UnitOperationConstraints;

  /**
   *
   */
  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override clearResults() {
    super.clearResults();
    this.volumetricFlowrate.value = null;
    this.feedstockValue.value = null;
    this.ghgIntensity.value = null;
    this.ghgEmissions.value = null;
    this.cumulativeGhgEmissions.value = null;
  }

  override initValues(unitOperation: any): void {
    super.initValues(unitOperation);
    this.volumetricFlowrate = new SimulationVariable(unitOperation.volumetricFlowrate, this.ownerCase, this);
    this.cumulativeGhgEmissions = new SimulationVariable(unitOperation.cumulativeGhgEmissions, this.ownerCase, this);
    this.feedstockValue = new SimulationVariable(unitOperation.feedstockValue, this.ownerCase, this);

    this.lightEndsMargin = new SimulationVariable(unitOperation.lightEndsMargin, this.ownerCase, this);
    this.naphthaMargin = new SimulationVariable(unitOperation.naphthaMargin, this.ownerCase, this);
    this.distillateMargin = new SimulationVariable(unitOperation.distillateMargin, this.ownerCase, this);
    this.gasOilMargin = new SimulationVariable(unitOperation.gasOilMargin, this.ownerCase, this);
    this.vacuumResidueMargin = new SimulationVariable(unitOperation.vacuumResidueMargin, this.ownerCase, this);

    this.suncorProfitPercentage = new SimulationVariable(unitOperation.suncorProfitPercentage, this.ownerCase, this);

    this.maximumSulfurContent = new SimulationVariable(unitOperation.maximumSulfurContent, this.ownerCase, this);
    this.maximumMassDensity = new SimulationVariable(unitOperation.maximumMassDensity, this.ownerCase, this);
    this.initConstraints();
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('capacity', this.capacity);
    this.constraints.addConstraint('maximumSulfurContent', this.maximumSulfurContent);
    this.constraints.addConstraint('maximumMassDensity', this.maximumMassDensity);
  }

  getAvailableKpis() {
    return [
      this.volumetricFlowrate,
      this.feedstockValue,
      this.cumulativeGhgEmissions,
      this.ghgIntensity,
      this.ghgEmissions,
    ];
  }

  getAvailableMultiPeriodParameters(): SimulationVariable[] {
    return [this.capacity];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [
      this.lightEndsMargin,
      this.naphthaMargin,
      this.distillateMargin,
      this.gasOilMargin,
      this.vacuumResidueMargin,
      this.suncorProfitPercentage,
    ];
  }

  getAvailableParameters() {
    return [this.ghgIntensity];
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.volumetricFlowrate.setName('Volumetric Flowrate');
    this.cumulativeGhgEmissions.setName('Cumulative GHG Emissions');
    this.feedstockValue.setName('Feedstock Value');
    this.suncorProfitPercentage.setName(SimulationVariableName.SUNCOR_PROFIT_PERCENTAGE);

    this.lightEndsMargin.setName('Light Ends Margin');
    this.naphthaMargin.setName('Naphtha Margin');
    this.distillateMargin.setName('Distillate Margin');
    this.gasOilMargin.setName('Gas Oil Margin');
    this.vacuumResidueMargin.setName('Vacuum Residue Margin');
    this.maximumMassDensity.setName(SimulationVariableName.MAXIMUM_MASS_DENSITY);
    this.maximumSulfurContent.setName(SimulationVariableName.MAXIMUM_SULFUR_CONTENT);
    this.capacity.setName(SimulationVariableName.CAPACITY);
  }

  override setQuantities() {
    super.setQuantities();
    this.feedstockValue.setQuantity('CurrencyPerVolume');
    this.volumetricFlowrate.setQuantity('Volumetricflowrate');
    this.cumulativeGhgEmissions.setQuantity('Massflowrate');

    this.lightEndsMargin.setQuantity('Percent');
    this.naphthaMargin.setQuantity('Percent');
    this.distillateMargin.setQuantity('Percent');
    this.gasOilMargin.setQuantity('Percent');
    this.vacuumResidueMargin.setQuantity('Percent');

    this.suncorProfitPercentage.setQuantity('Percent');

    this.maximumSulfurContent.setQuantity(Quantity.MASS_FRACTION);
    this.maximumMassDensity.setQuantity('LiquidDensity');
  }

  override setDefaultValues() {
    super.setDefaultValues();

    if (this.volumetricFlowrate.isUndefined()) {
      this.volumetricFlowrate.setDefaultValue(0.0);
    }

    if (this.cumulativeGhgEmissions.isUndefined()) {
      this.cumulativeGhgEmissions.setDefaultValue(0.0, unitConverter.units.Massflowrate.MMTPA);
    }

    if (this.feedstockValue.isUndefined()) {
      this.feedstockValue.setDefaultValue(0.0);
    }

    if (this.lightEndsMargin.isUndefined()) {
      this.lightEndsMargin.setDefaultValue(-5.0);
    }

    if (this.naphthaMargin.isUndefined()) {
      this.naphthaMargin.setDefaultValue(0.0);
    }

    if (this.distillateMargin.isUndefined()) {
      this.distillateMargin.setDefaultValue(18.0);
    }

    if (this.gasOilMargin.isUndefined()) {
      this.gasOilMargin.setDefaultValue(12.0);
    }

    if (this.vacuumResidueMargin.isUndefined()) {
      this.vacuumResidueMargin.setDefaultValue(8.0);
    }
    if (this.maximumMassDensity.isUndefined()) {
      this.maximumMassDensity.setDefaultValue(null, unitConverter.units.LiquidDensity.KG_M3);
    }
  }

  override ignoreForMakeReady(simvar: SimulationVariable): boolean {
    return (
      super.ignoreForMakeReady(simvar) ||
      simvar === this.volumetricFlowrate ||
      simvar === this.capacity ||
      simvar === this.maximumSulfurContent ||
      simvar === this.maximumMassDensity ||
      simvar === this.minimumFlow
    );
  }

  getAlternativeGhgIntensity() {
    return new SimulationVariable(this.ghgIntensity, this.ownerCase, this);
  }

  override dePersist(suo: any) {
    super.dePersist(suo);

    this.feedstockValue = this.getSimulationVariableSafe(suo.feedstockValue);
    this.volumetricFlowrate = this.getSimulationVariableSafe(suo.volumetricFlowrate);
    this.cumulativeGhgEmissions = this.getSimulationVariableSafe(suo.cumulativeGhgEmissions);

    this.lightEndsMargin = this.getSimulationVariableSafe(suo.lightEndsMargin);
    this.naphthaMargin = this.getSimulationVariableSafe(suo.naphthaMargin);
    this.distillateMargin = this.getSimulationVariableSafe(suo.distillateMargin);
    this.gasOilMargin = this.getSimulationVariableSafe(suo.gasOilMargin);
    this.vacuumResidueMargin = this.getSimulationVariableSafe(suo.vacuumResidueMargin);

    this.suncorProfitPercentage = this.getSimulationVariableSafe(suo.suncorProfitPercentage);

    this.maximumSulfurContent = this.getSimulationVariableSafe(suo.maximumSulfurContent);
    this.maximumMassDensity = this.getSimulationVariableSafe(suo.maximumMassDensity);

    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }

  constraintViolated() {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    })[0];

    // return inletStream && this.constraintValueDefined() ? inletStream.volumetricFlowrate.value > (this.capacity.value + 0.01) : false;
    return (
      inletStream &&
      (this.constraints.constraintValueViolated('capacity', inletStream.volumetricFlowrate.value) ||
        this.constraints.constraintValueViolated('maximumSulfurContent', inletStream.sulfur.value) ||
        this.constraints.constraintValueViolated('maximumMassDensity', inletStream.massDensity.value))
    );
    // this.constraints.constraintValueViolated('maximumHydrogenProduction', inletStream.hyd.value));
  }

  constraintValueDefined() {
    return this.constraints.anyConstraintValueDefined();
  }
}
