import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { LatestUserAccessService } from '../_services/latest-user-access.service';

@Component({
  selector: 'sob-latest-access',
  templateUrl: './latest-access.component.html',
  styleUrls: ['./latest-access.component.css'],
})
export class LatestAccessComponent implements OnInit, OnDestroy {
  latestAccessList: LatestUserAccess[] = [];

  private subSink = new SubSink();

  constructor(private latestUserAccessService: LatestUserAccessService) {}

  ngOnInit(): void {
    this.subSink.add(
      this.latestUserAccessService
        .getLatestAccesses()
        .pipe(map(latestAccesses => this.onLatestAccessesReceived(latestAccesses)))
        .subscribe(latestAccessList => {
          this.latestAccessList = latestAccessList;
        })
    );
  }

  private onLatestAccessesReceived(latestAccesses: any) {
    const latestAccessList: LatestUserAccess[] = [];

    for (const key of Object.keys(latestAccesses)) {
      const momentDate = moment(latestAccesses[key]);

      latestAccessList.push({
        lastAccessDate: momentDate.format('YYYY/MM/DD hh:mm:ss'),
        username: key,
        ago: momentDate.fromNow(),
      });
    }

    return latestAccessList;
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}

interface LatestUserAccess {
  username: string;
  lastAccessDate: string;
  ago: string;
}
