<div class="modal fade" id="aboutModal" tabindex="-1" role="dialog" aria-labelledby="flowsheetCommentsModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">
          <i class="fa fa-info-circle"></i>
          About
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xs-12">
            <div class="text-center">
              <img src="/assets/img/suncor-optea-logo.png" alt="suncor logo" />
            </div>
          </div>
        </div>

        <div class="row product-description" style="margin-top: 10px">
          <p class="text-center">
            The Suncor Optimization and Techno-Economic Assessment (Optea) software is a system-wide optimization tool
            designed to efficiently evaluate optimal processing pathways and new technology integration in Suncor's
            value chain
          </p>
        </div>

        <hr />

        <div class="row">
          <div class="col-xs-12">
            <div class="text-center">
              <img src="/assets/img/logo_pe.png" alt="Process Ecology logo" class="pe-logo" />
            </div>
          </div>
        </div>

        <div class="row" class="pe-description">
          <p class="text-center">
            Suncor OPTEA is developed and supported by Process Ecology <br />
            <a href="http://processecology.com/contact" target="_blank"> Contact Process Ecology </a>
          </p>
        </div>
      </div>

      <div class="modal-footer">
        <div>
          <span class="pull-right">
            <a routerLink="/versionControl" target="_blank">v{{ appVersion }}</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
