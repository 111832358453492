import { BaseStream } from '../_streams/base-stream';
import { GojsSuncorMaterialStream } from './gojs-suncor-material-stream';
import { SuncorMaterialStream } from '../_streams/suncor-material-stream';
import { GojsBaseStream } from './gojs-base-stream';
import { WaterMaterialStream } from '../_streams/water-material-stream';
import { GojsWaterMaterialStream } from './gojs-water-material-stream';
import { FuelGasMaterialStream } from '../_streams/fuel-gas-material-stream';
import { GojsFuelGasMaterialStream } from './gojs-fuel-gas-material-stream';

export class GojsStreamFactory {
  static create(stream: BaseStream): GojsBaseStream {
    if (stream instanceof SuncorMaterialStream) {
      return new GojsSuncorMaterialStream(stream);
    }
    if (stream instanceof WaterMaterialStream) {
      return new GojsWaterMaterialStream(stream);
    }
    if (stream instanceof FuelGasMaterialStream) {
      return new GojsFuelGasMaterialStream(stream);
    }

    throw new Error(`Unknown stream type ${stream.category}`);
  }
}
