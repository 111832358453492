import { AssayManager } from '../_models/_fluid/assay-manager';
import { unitOperationsConfig } from '../_config/unit-operations.config';

export class V210 {
  static moveFluidAnalysisToAssayManager(serializedCase: any) {
    if (!serializedCase.assayManager) {
      const unitOpsWithAssays = Object.values(serializedCase.unitOperationPool).filter((uo: any) => {
        return uo.hasOwnProperty('fluidAnalysis');
      });
      const am = new AssayManager({}, null);
      unitOpsWithAssays.forEach((uo: any) => {
        uo.fluidAnalysis.assayName = `${uo.name} assay`;
        if (uo.category === unitOperationsConfig.diluentSource.key) {
          uo.fluidAnalysis.assayType = 'Diluent';
        } else if (
          uo.category === unitOperationsConfig.hydrotreater.key ||
          uo.category === unitOperationsConfig.bitumenConversion.key
        ) {
          uo.fluidAnalysis.assayType = 'Product';
        } else {
          uo.fluidAnalysis.assayType = 'Crude oil - Bitumen';
        }
        serializedCase.otherBaseObjectPool[uo.fluidAnalysis.id] = uo.fluidAnalysis;
        am.fluidAnalyses.push(uo.fluidAnalysis.id);
        uo.fluidAnalysisId = uo.fluidAnalysis.id;
      });
      serializedCase.assayManager = am.id;
      serializedCase.otherBaseObjectPool[am.id] = am;
    }
  }
}
