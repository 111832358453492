import { SimulationVariable } from '../simulation-variable';
import { Case } from '../case';
import { UnitOperation } from '../unit-operation';

export class OutletTransferUnit extends UnitOperation {
  category = 'outlet-transfer-unit';

  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  // empty methods so errors aren't generated
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override initValues(unitOperation: any) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override dePersist(serializedUnitOperation: any) {}

  override setSimulationVariableNames() {}

  getAlternativeGhgIntensity(): SimulationVariable {
    return undefined;
  }

  override setQuantities() {}

  override clearResults() {}

  override setDefaultValues() {}
}
