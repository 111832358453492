import { Case, SuncorMaterialStream, UnitOperation } from '../../_models';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { CommodityTank } from '../../_models/_unit-operations/commodity-tank';
import { ExcelReportUtils } from './excel-report.utils';

export class CommodityTankExcelReport {
  static generateDataArray(c: Case, flowsheetOwnerId: string): any[] {
    const dataArray = [
      [
        'Commodity Tank Name',
        'Product Price (CAD$/bbl)',
        'GHG Intensity (kg/bbl)',
        'Flow (kbpcd)',
        'Revenue (MMCAD/yr)',
      ],
      [''],
    ];
    const commodityTanks = c.filterUnitOperations((uo: UnitOperation) => {
      return uo.category === unitOperationsConfig.commodityTank.key && uo.flowsheetId === flowsheetOwnerId;
    });
    commodityTanks.forEach((ct: CommodityTank) => {
      const revenue =
        ExcelReportUtils.roundFormat(ct.productPrice.convertToDefaultUnit(true, false), -2) *
        ExcelReportUtils.roundFormat(ct.commodityVolume.convertToDefaultUnit(true, false), -2) *
        365 *
        1e-3;
      const stream = c.filterSuncorMaterialStreams((s: SuncorMaterialStream) => {
        return s.fromPort === ct.id;
      })[0];
      const row = [ct.name];
      row.push(ExcelReportUtils.roundFormat(ct.productPrice.convertToDefaultUnit(true, false), -2));
      row.push(ExcelReportUtils.roundFormat(stream.ghgIntensity.convertToDefaultUnit(true, false), -2));
      row.push(ExcelReportUtils.roundFormat(ct.commodityVolume.convertToDefaultUnit(true, false), -2));
      row.push(ExcelReportUtils.roundFormat(revenue, -2));
      dataArray.push(row);
    });

    return dataArray;
  }
}
