import { FlexSheetMapper } from './flex-sheet-mapper';
import { Refinery } from '../../../_models/_unit-operations/refinery';
import { RefinerySheetRows } from '../cell-config/refinery-cell-config';
import { Mapper } from '../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../_models';
import { NumberToSimulationVariableMapper } from './number-to-simulation-variable-mapper';

export class RefineryFlexSheetMapper implements FlexSheetMapper {
  simulationVariableMapper: Mapper<number, SimulationVariable>;

  constructor(simulationVariableMapper: NumberToSimulationVariableMapper) {
    this.simulationVariableMapper = simulationVariableMapper;
  }

  map(unitOperation: Refinery, row: number, value: string | number) {
    if (row === RefinerySheetRows.NAME && typeof value === 'string') {
      unitOperation.name = value;
    }

    if (typeof value === 'number') {
      switch (row) {
        case RefinerySheetRows.LIGHT_ENDS_MARGIN:
          this.simulationVariableMapper.map(value, unitOperation.lightEndsMargin);
          break;

        case RefinerySheetRows.NAPHTHA_MARGIN:
          this.simulationVariableMapper.map(value, unitOperation.naphthaMargin);
          break;

        case RefinerySheetRows.DISTILLATE_MARGIN:
          this.simulationVariableMapper.map(value, unitOperation.distillateMargin);
          break;

        case RefinerySheetRows.GAS_OIL_MARGIN:
          this.simulationVariableMapper.map(value, unitOperation.gasOilMargin);
          break;

        case RefinerySheetRows.VACUUM_RESIDUE_MARGIN:
          this.simulationVariableMapper.map(value, unitOperation.vacuumResidueMargin);
          break;

        case RefinerySheetRows.CAPACITY:
          this.simulationVariableMapper.map(value, unitOperation.capacity);
          break;

        case RefinerySheetRows.CAPEX_AMORTIZED:
          this.simulationVariableMapper.map(value, unitOperation.capexAmortized);
          break;

        case RefinerySheetRows.OPEX_FIXED:
          this.simulationVariableMapper.map(value, unitOperation.opexFixed);
          break;

        case RefinerySheetRows.OPEX_VAR:
          this.simulationVariableMapper.map(value, unitOperation.opexVar);
          break;

        case RefinerySheetRows.OPEX_GAS_DIESEL:
          this.simulationVariableMapper.map(value, unitOperation.opexGasDiesel);
          break;

        case RefinerySheetRows.OPEX_POWER:
          this.simulationVariableMapper.map(value, unitOperation.opexPower);
          break;
      }
    }
  }
}
