import { DefaultBaseObjectFormGroupMapper } from './default-base-object-form-group.mapper';
import { BaseObject } from '../_models/base-object';
import { BitumenConversion } from '../_models/_unit-operations/bitumen-conversion';
import { DefaultMappedType } from '../_models/default-mapped-type';
import { BitumenConversionOutlet } from '../_models/_unit-operations/bitumen-conversion-outlet';

export class BitumenConversionFormGroupMapper extends DefaultBaseObjectFormGroupMapper {
  override map(source: BaseObject, destination: BaseObject) {
    super.map(source, destination);

    const destinationBitumenConversion = destination as BitumenConversion;
    const sourcePlainObject = source as any as DefaultMappedType<BitumenConversion>;

    if (sourcePlainObject.outlets.length !== destinationBitumenConversion.outlets.length) {
      throw new Error('Source and destination should have the same amount of outlets!');
    }

    sourcePlainObject.outlets.forEach((plainOutlet: DefaultMappedType<BitumenConversionOutlet>, i: number) => {
      const destinationOutlet = destinationBitumenConversion.outlets[i];
      this.simVarMapper.map(plainOutlet.yield, destinationOutlet.yield);
      this.simVarMapper.map(plainOutlet.price, destinationOutlet.price);
      destinationOutlet.fluidAnalysisId = plainOutlet.fluidAnalysisId;
    });
  }

  override ignoreForAutoMapping(key: string, source: BaseObject, destination: BaseObject): boolean {
    return super.ignoreForAutoMapping(key, source, destination) || (key as keyof BitumenConversion) === 'outlets';
  }
}
