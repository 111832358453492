import { DataType } from '@grapecity/wijmo';
import { CoreService } from '../core.service';
import { NumberToUnitConverter } from '../number-to-unit-converter.service';
import { AbstractUtilitiesHandler } from './abstract-utilities-handler';
import { Upgrader } from '../../_models/_unit-operations/upgrader';
import { FlexSheetColumn } from '../../_models/wijmo/flex-sheet-column';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { BaseUtilitySpreadsheetModel } from '../../_models/utilities-spreadsheet/base-utility-spreadsheet-model';
import { Hydrotreater } from '../../_models/_unit-operations/hydrotreater';
import { Hydrocracker } from '../../_models/_unit-operations/hydrocracker';
import { UtilityHydrogenSpreadsheetModel } from '../../_models/utilities-spreadsheet/utility-hydrogen-spreadsheet-model';
import { UtilityH2sSpreadsheetModel } from '../../_models/utilities-spreadsheet/utility-h2s-spreadsheet-model';
import { HgoHydrotreater } from '../../_models/_unit-operations/hgo-hydrotreater';
import { LgoHydrotreater } from '../../_models/_unit-operations/lgo-hydrotreater';
import { UnitOperation } from '../../_models';

export class HydrogenSheetHandler extends AbstractUtilitiesHandler {
  constructor(coreService: CoreService, private ownerUnitOperation: UnitOperation, private nuc: NumberToUnitConverter) {
    super(coreService);
    this.ownerUnitOperationId = ownerUnitOperation.id;
  }

  private getUnitOperation(
    item: UtilityH2sSpreadsheetModel
  ): Hydrotreater | Hydrocracker | HgoHydrotreater | LgoHydrotreater {
    const unitOperation = this.coreService.currentCase.getUnitOperation(item.id) as SuncorUnitOperation;

    if (this.isUnitOperationAllowed(unitOperation)) {
      return unitOperation;
    }

    throw new Error(`A non-allowed unit operation was detected ${unitOperation.category} - ${unitOperation.name}`);
  }

  addTotal(upgrader: Upgrader): void {
    const newItem = this.toSpreadsheetItem(upgrader, 'Total');
    const index = this.items.findIndex(u => !u.unitOperationName);

    if (index > -1 && index <= this.DEFAULT_NUMBER_OF_ROWS - 1) {
      this.items[index] = newItem;
    } else {
      this.items.push(newItem);
    }

    this.grid.collectionView.refresh();
  }

  override clearResults(): void {
    for (const i of this.items) {
      const item = i as UtilityHydrogenSpreadsheetModel;
      item.hydrogenUse = undefined;
    }

    const totalIndex = this.items.findIndex(i => i.unitOperationName === 'Total');
    if (totalIndex > -1) {
      this.items.splice(totalIndex, 1);
    }

    this.grid.collectionView.refresh();
  }

  isUnitOperationAllowed(
    unitOperation: SuncorUnitOperation
  ): unitOperation is Hydrotreater | Hydrocracker | HgoHydrotreater | LgoHydrotreater {
    return (
      (unitOperation instanceof Hydrotreater ||
        unitOperation instanceof Hydrocracker ||
        unitOperation instanceof HgoHydrotreater ||
        unitOperation instanceof LgoHydrotreater) &&
      unitOperation.flowsheetId === this.ownerUnitOperationId
    );
  }

  protected toSpreadsheetItem(
    unitOperation: Hydrotreater | Hydrocracker | Upgrader | HgoHydrotreater | LgoHydrotreater,
    alternateName?: string
  ): BaseUtilitySpreadsheetModel {
    const result = {
      id: unitOperation.id,
      unitOperationName: alternateName || unitOperation.name,
    } as UtilityHydrogenSpreadsheetModel;

    if (unitOperation instanceof Hydrotreater && unitOperation.hydrogenUseRateVariables.length) {
      result.hydrogenUseRate = this.nuc.convertToDefaultUnit(
        unitOperation.hydrogenUseRateVariables[0].value,
        'GasStdVolumePerVolume'
      ); // TODO use quantities enum
    } else if (
      unitOperation instanceof Hydrocracker ||
      unitOperation instanceof HgoHydrotreater ||
      unitOperation instanceof LgoHydrotreater
    ) {
      result.hydrogenUseRate = unitOperation.hydrogenUseRate.convertToDefaultUnit();
    }

    if (unitOperation.ownerCase.isSolved) {
      result.hydrogenUse = unitOperation.hydrogenUse.convertToDefaultUnit();
    }

    return result;
  }

  assignValueToUnitOperation(
    valueAsNumber: number,
    item: BaseUtilitySpreadsheetModel,
    flexSheetEvent: any
  ): SuncorUnitOperation | undefined {
    const concreteItem = item as UtilityHydrogenSpreadsheetModel;
    if (valueAsNumber === concreteItem.hydrogenUseRate) {
      flexSheetEvent.cancel = true;
      return undefined;
    }

    const uo = this.getUnitOperation(item);

    // it is assumed that only this value is editable...
    if (uo instanceof Hydrotreater && uo.hydrogenUseRateVariables.length) {
      uo.hydrogenUseRateVariables[0].value = this.nuc.convertDefaultToInternalUnit(
        valueAsNumber,
        uo.h2sProductionRate.quantity
      );
    } else {
      uo.hydrogenUseRate.value = this.nuc.convertDefaultToInternalUnit(valueAsNumber, uo.h2sProductionRate.quantity);
    }

    return uo;
  }

  protected getColumnsConfiguration(): FlexSheetColumn[] {
    return [
      {
        header: 'Unit Operation Name',
        binding: 'unitOperationName',
        dataType: DataType.String,
        width: 200,
        isReadOnly: true,
      },
      {
        header: 'Hydrogen Use Rate (scf/bbl)',
        binding: 'hydrogenUseRate',
        dataType: DataType.Number,
        width: 180,
      },
      {
        header: 'Hydrogen Use (MMSCFD)',
        binding: 'hydrogenUse',
        dataType: DataType.Number,
        width: 180,
        isReadOnly: true,
      },
      // empty columns to fill the screen...
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
    ];
  }
}
