import { Component, Input, ChangeDetectorRef } from '@angular/core';
import * as Highcharts from 'highcharts';
import { MarginalValueEntry } from '../../../_models/marginal-value-entry';
import { isTypeUndefined } from '../../../_utils/utils';
import { CoreService } from '../../../_services/core.service';

@Component({
  selector: 'sob-constraint-ranking',
  templateUrl: './constraint-ranking.component.html',
  styleUrls: ['./constraint-ranking.component.css'],
})
export class ConstraintRankingComponent {
  @Input() currentConstraints: MarginalValueEntry[];
  constraintRanking: MarginalValueEntry[] = [];
  objFunctionUnit = 'MMCAD$/kbpcd';

  constructor(private coreService: CoreService, private ref: ChangeDetectorRef) {}

  buildConstraintRankingGraphics(constraintRanking: MarginalValueEntry[]) {
    this.constraintRanking = constraintRanking;
    this.ref.detectChanges();
    if (this.constraintRanking.length !== 0) {
      if (this.objFunctionUnit === 'MMCAD$/kbpcd') {
        this.buildConstraintRankingChart();
      } else {
        this.buildConstraintRankingByPercentChart();
      }
    }
  }

  switchConstraintChartType() {
    this.objFunctionUnit = this.objFunctionUnit === '%' ? 'MMCAD$/kbpcd' : '%';
    if (this.objFunctionUnit === 'MMCAD$/kbpcd') {
      this.buildConstraintRankingChart();
    } else {
      this.buildConstraintRankingByPercentChart();
    }
  }

  getOwnerFlowsheetName(mve: MarginalValueEntry) {
    const ownerFlowsheet = this.coreService.currentCase.getUnitOperation(mve.ownerOperationId);
    if (isTypeUndefined(ownerFlowsheet)) {
      return 'Main Flowsheet';
    }
    return ownerFlowsheet.name;
  }

  buildConstraintRankingChart() {
    const series = this.constraintRanking
      .map(mve => {
        return { name: `${mve.variableName} - ${mve.unitOperationName}`, y: mve.objFunctionChangeRate };
      })
      .filter(mve => !mve.name.startsWith('Minimum Flow'));

    const chartOptions = this.getBarChartOptions(series);
    Highcharts.chart('rankingChart', <any>chartOptions);
  }

  buildConstraintRankingByPercentChart() {
    const totalObjFunctionChangeRateValue = this.getTotalObjFunctionChangeRate();

    const series = this.constraintRanking
      .filter(mve => !mve.variableName.startsWith('Minimum Flow'))
      .map(mve => {
        const percent = (mve.objFunctionChangeRate * 100) / totalObjFunctionChangeRateValue;
        return { name: `${mve.variableName} - ${mve.unitOperationName}`, y: percent };
      });

    const chartOptions = this.getBarChartOptions(series);
    Highcharts.chart('rankingChart', <any>chartOptions);
  }

  getBarChartOptions(series) {
    return {
      chart: {
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: `Obj. function change rate value (${this.objFunctionUnit})`,
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        // eslint-disable-next-line max-len
        pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.3f}${this.objFunctionUnit}</b><br/>`,
        followPointer: true,
      },
      series: [
        {
          name: 'Constraint',
          data: series,
          colorByPoint: true,
        },
      ],
    };
  }

  getTotalObjFunctionChangeRate() {
    let total = 0;
    this.constraintRanking
      .filter(mve => !mve.variableName.startsWith('Minimum Flow'))
      .forEach(mve => {
        total += mve.objFunctionChangeRate;
      });

    return total;
  }
}
