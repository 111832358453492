import { Mapper } from '../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../_models';

/**
 * Maps a numeric string to a SimulationVariable value in default unit
 */
export class StringToSimulationVariableMapper implements Mapper<string, SimulationVariable> {
  map(source: string, destination: SimulationVariable) {
    const value = parseFloat(source);

    if (!isNaN(value)) {
      destination.value = value;
      destination.unit = destination.getDefaultUnit();

      destination.value = destination.convertToInternalUnit();
    } else {
      destination.value = undefined;
    }
  }
}
