<div class="modal fade" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="summaryReportModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="">Search</h4>
      </div>

      <div class="modal-body">
        <div class="form-horizontal">
          <div class="form-group g-max-width-450--sm">
            <label for="search-bar" class="control-label col-md-4">Unit Operation</label>
            <input
              autocomplete="off"
              id="search-bar"
              class="form-control col-md-4"
              [(ngModel)]="searchTerm"
              type="text" />
            <button type="submit" (click)="zoomToUnitOperation()" class="btn btn-success m-l-sm">
              <i class="fa fa-search"></i>
            </button>
            <ul id="facilities" class="col-md-offset-4">
              <ng-container *ngFor="let uo of unitOperations | unitOperationFilter : searchTerm">
                <li (click)="assignSearchTerm(uo.id)">
                  {{ uo.name }}
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
