import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OpenReactiveCodeEditorButtonComponent } from './open-reactive-code-editor-button/open-reactive-code-editor-button.component';
import { CodeEditorService } from '../code-editor/code-editor.service';

@NgModule({
  declarations: [OpenReactiveCodeEditorButtonComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [OpenReactiveCodeEditorButtonComponent],
  providers: [CodeEditorService],
})
export class ReactiveCodeEditorModule {}
