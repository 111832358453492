import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { FuelGasSink } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-sink';
import { FuelGasMaterialStream } from '../../../_models/_streams/fuel-gas-material-stream';

@Component({
  selector: 'sob-fuel-gas-sink',
  templateUrl: './fuel-gas-sink.component.html',
  styleUrls: ['./fuel-gas-sink.component.css'],
})
export class FuelGasSinkComponent implements OnInit {
  @Input() unitOperation: FuelGasSink;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;
  inletStream: FuelGasMaterialStream;
  compositionArray = this.fb.array([]);

  constructor(private fb: UntypedFormBuilder, private svfb: SimVarFormBuilder) {}

  ngOnInit(): void {
    const { ownerCase } = this.unitOperation;
    const inletStreams = ownerCase.filterFuelGasMaterialStreams((s: FuelGasMaterialStream) => {
      return s.outletUnitOperationId === this.unitOperation.id;
    });

    if (inletStreams.length) {
      this.inletStream = inletStreams[0];
    }
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl('flowrate', this.svfb.control(this.unitOperation.flowrate, true));
    this.getComposition();
  }

  getComposition() {
    if (this.inletStream) {
      this.propertyWindowFormGroup.addControl('compositionUnit', this.fb.control(this.inletStream.composition[0].unit));
      let total = 0;
      for (let i = 0; i < this.inletStream.composition.length; i++) {
        const element = this.inletStream.composition[i];
        total += element.convertToCaptureUnit(true);
        this.compositionArray.push(this.fb.control({ value: element.value, disabled: true }));
      }
      this.propertyWindowFormGroup.addControl('composition', this.compositionArray);
      this.propertyWindowFormGroup.addControl('total', this.fb.control(total));
      this.propertyWindowFormGroup.controls['total'].disable();
    }
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }
}
