<div class="row" style="margin-top: 20px">
  <div class="col-xs-12">
    <div class="navbar" style="margin-bottom: 0px">
      <div class="col-md-12" style="height: 90px">
        <div class="col-md-2 col-md-offset-4 col-xs-6">
          <img
            class="img-responsive"
            src="/assets/img/logo_pe_white.png"
            style="max-height: 70px; min-height: 70px; padding-bottom: 10px" />
        </div>
        <div class="col-md-2 text-center col-xs-6">
          <img
            class="img-responsive"
            src="/assets/img/suncor-w.png"
            style="max-height: 70px; float: right; min-height: 70px; padding-bottom: 10px" />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xs-12" style="background: rgb(243, 243, 244)">
    <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-lg-offset-3 col-lg-6">
      <div class="row" style="margin-top: 20px">
        <div class="col-xs-12">
          <h1 class="text-center">Welcome</h1>
          <br />
        </div>
        <div
          class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8 panel panel-default"
          style="padding-top: 20px; padding-bottom: 20px">
          <div class="panel-body">
            <div *ngIf="error" class="alert alert-danger margin-bottom-30">
              <button type="button" autofocus class="close" (click)="closeModal()">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
              </button>
              <strong>{{ error }}</strong>
            </div>
            <div *ngIf="success" class="alert alert-success margin-bottom-30">
              <button type="button" autofocus class="close" (click)="closeModal()">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
              </button>
              <strong>{{ success }}</strong>
            </div>
            <form name="login" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
              <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                <input
                  name="username"
                  type="email"
                  class="form-control"
                  id="username"
                  placeholder="Username"
                  [(ngModel)]="model.username"
                  #username="ngModel"
                  required />
                <div *ngIf="f.submitted && !username.valid" class="help-block">Username is required</div>
              </div>

              <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                <input
                  name="password"
                  type="password"
                  class="form-control"
                  id="password"
                  placeholder="Password"
                  [(ngModel)]="model.password"
                  #password="ngModel"
                  required />
                <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
              </div>
              <button
                [disabled]="loading"
                class="btn btn-primary block full-width m-b"
                style="width: 300px; margin-top: 30px"
                id="submit">
                Login
              </button>
              <a
                class="btn btn-sm btn-white btn-block"
                [routerLink]="['/signup']"
                id="submit"
                style="margin-bottom: 20px"
                >Sign Up</a
              >
              <a [routerLink]="['/forgot-password']"><small>Forgot your password?</small></a>
            </form>
            <br />
            <br />
            <h1></h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row blocky"></div>
