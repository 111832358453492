import { BaseObject } from '../base-object';
import { SimulationVariable } from '../simulation-variable';
import { Case } from '../case';
import { CaseStudyCategory } from './case-study-category';
import { CaseStudyManager } from './case-study-manager';
import { SuncorCalculator } from '../suncor-calculator';

export class KpiManager extends BaseObject {
  category = 'kpiManager'; // um...
  selectedKpis: SimulationVariable[] = [];
  categories: CaseStudyCategory[] = [];

  constructor(kpiManager: any, ownerCase: Case) {
    super(kpiManager.id, ownerCase);

    if (kpiManager.selectedKpis instanceof Array) {
      for (const kpi of kpiManager.selectedKpis) {
        if (kpi instanceof SimulationVariable) {
          this.selectedKpis.push(this.ownerCase.getSimulationVariable(kpi.id));
        }
      }
    }

    this.ownerCase.simulationVariableDeletedRequest.subscribe((sv: SimulationVariable) => {
      this.removeKpi(sv);
    });
  }

  addToCategories(category: CaseStudyCategory): void {
    const existingCategory = this.categories.find(cat => {
      return cat.ownerBaseObjectId && cat.ownerBaseObjectId === category.ownerBaseObjectId;
    });
    if (existingCategory) {
      return;
    }
    this.categories.push(category);
  }

  /**
   * @param categoryName The name of the category
   * @returns {Array<BaseObject>} An array of all the base objects that belong to categoryName
   */
  getKpiProvidersByCategoryName(categoryName: string): Array<BaseObject> {
    let selectedCategory: CaseStudyCategory = null;
    for (const category of this.categories) {
      if (category.categoryName === categoryName) {
        selectedCategory = category;
      }
    }
    return this.caseStudyManager.getKpiProvidersForCategory(selectedCategory);
  }

  addKpiById(id: string) {
    this.addKpi(this.ownerCase.getSimulationVariable(id));
  }

  addKpi(sv: SimulationVariable) {
    if (this.selectedKpis.indexOf(sv) > -1) {
      return;
    }

    this.selectedKpis.push(sv);
  }

  removeKpi(sv: SimulationVariable) {
    const index = this.selectedKpis.indexOf(sv);

    if (index > -1) {
      this.ownerCase.kpiDeletedSubject.next(this.selectedKpis[index]);
      this.selectedKpis.splice(index, 1);
    }
  }

  removeKpiById(id: string) {
    this.removeKpi(this.ownerCase.getSimulationVariable(id));
  }

  override dePersist(kpiManager: any) {
    for (const kpiId of kpiManager.selectedKpis) {
      this.selectedKpis.push(this.ownerCase.getSimulationVariable(kpiId));
    }
  }

  get caseStudyManager(): CaseStudyManager {
    return this.ownerCase.caseStudyManager;
  }

  public toJSON(): any {
    return {
      category: this.category,
      id: this.id,
      selectedKpis: this.selectedKpis.map((sv: SimulationVariable) => {
        return sv.id;
      }),
    };
  }

  private getDefaultKpis(calculator: SuncorCalculator): SimulationVariable[] {
    return calculator.getAvailableKpis();
  }

  addDefaultKpis(calculator: SuncorCalculator) {
    const defaultKpis = this.getDefaultKpis(calculator);
    for (const kpi of defaultKpis) {
      this.addKpi(kpi);
    }
  }

  removeDefaultKpis(calculator: SuncorCalculator) {
    const defaultKpis = this.getDefaultKpis(calculator);
    for (const kpi of defaultKpis) {
      this.removeKpi(kpi);
    }
  }
}
