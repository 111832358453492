import { Case } from './case';
import { BaseObject } from './base-object';
import { ReadyStatus } from '../_config/ready-status.enum';
import { VariableStatus } from '../_config/variable-status.enum';
import { hasNumericValue } from '../_utils/utils';
import { BasicSimulationVariableInterface } from './simulation-variable-interfaces';
// Wait, what, why
declare let unitConverter: any;

export class SimulationVariable extends BaseObject implements BasicSimulationVariableInterface {
  category = 'simulationVariable';
  value: number;
  unit: string;
  quantity: string;
  variableStatus: string;
  lowerBound: number;
  upperBound: number;
  isActive: boolean;
  readyStatus: ReadyStatus;
  ownerBaseObject: BaseObject;

  constructor(simVar: any, ownerCase: Case, ownerBaseObject: BaseObject) {
    super(simVar ? simVar.id : '', ownerCase);
    if (simVar instanceof Object) {
      this.value = simVar.value;
      this.unit = simVar.unit;
      this.quantity = simVar.quantity;
      this.isActive = simVar.isActive;
      this.variableStatus = simVar.variableStatus || 'UnDefined';
      this.readyStatus = simVar.readyStatus || ReadyStatus.UNDEFINED;
      this.setDefaultUnitIfNeeded();
      this.lowerBound = simVar.lowerBound === null ? undefined : simVar.lowerBound || 0;
      this.upperBound = simVar.upperBound === null ? undefined : simVar.upperBound;
    } else {
      this.value = undefined;
      this.unit = '';
      this.quantity = '';
      this.lowerBound = 0;
      this.readyStatus = ReadyStatus.UNDEFINED;
      this.upperBound = undefined;
      this.variableStatus = 'UnDefined';
    }
    this.ownerBaseObject = ownerBaseObject;
  }

  setQuantity(quantity: string) {
    this.quantity = quantity;
    this.setDefaultUnitIfNeeded();
  }

  setOwnerBaseObject(ownerBaseObject: BaseObject): void {
    if (this.ownerBaseObject && this.ownerBaseObject !== ownerBaseObject) {
      console.error(
        `An attempt to change SimulationVariable owner has occurred - old:`,
        this.ownerBaseObject,
        'new:',
        ownerBaseObject
      );
      throw new Error(
        `An attempt to change SimulationVariable owner has occurred - old ${this.ownerBaseObject?.id}:${this.ownerBaseObject?.name} //// new ${ownerBaseObject?.id}:${ownerBaseObject?.name} `
      );
    }
    this.ownerBaseObject = ownerBaseObject;
  }

  getDefaultUnit(): string {
    return unitConverter(this.quantity).getDefaultUnit();
  }

  getInternalUnit(): string {
    return unitConverter(this.quantity).getInternalUnit();
  }

  getFullName(): string {
    return `${this.ownerBaseObject.getName()} - ${this.name}`;
  }

  override convertToInternalUnit(): number {
    // if no quantity, at least remove the format
    if (!this.quantity || !this.unit) {
      return unitConverter.parseFloatString(this.value);
    }

    return unitConverter(this.quantity).convert(this.value, this.unit, this.getInternalUnit()).getValue();
  }

  convertToCaptureUnit(asNumber: boolean): any {
    if (!this.quantity || !this.unit) {
      if (asNumber) {
        return unitConverter.parseFloatString(this.value);
      }

      return unitConverter.formatNumber(this.value);
    }

    const conversion = unitConverter(this.quantity).convert(this.value, this.getInternalUnit(), this.unit).round();

    if (asNumber) {
      return conversion.getValue();
    }

    return conversion.format();
  }

  convertToAnotherUnit(anotherUnit, format?: boolean): any {
    if (!this.quantity || !anotherUnit) {
      return unitConverter.parseFloatString(this.value);
    }

    const conversion = unitConverter(this.quantity).convert(this.value, this.getInternalUnit(), anotherUnit);

    if (format) {
      return conversion.round().format();
    }

    return conversion.round().getValue();
  }

  /**
   * Converts from internal to default unit
   * @param {boolean} round
   * @param {boolean} format
   * @returns {any}
   */
  convertToDefaultUnit(round?: boolean, format?: boolean) {
    if (!this.quantity || !this.unit) {
      return unitConverter.parseFloatString(this.value);
    }

    let conversion = unitConverter(this.quantity).convert(this.value, this.getInternalUnit(), this.getDefaultUnit());

    if (round) {
      conversion = conversion.round();
    }

    if (format) {
      return conversion.format();
    }

    return conversion.getValue();
  }

  roundCurrentValue() {
    return unitConverter(this.quantity).convert(this.value, this.unit, this.unit).round().getValue();
  }

  setDefaultUnitIfNeeded() {
    if (
      (this.unit === '' && this.quantity) ||
      (this.quantity && !unitConverter(this.quantity).unitInQuantity(this.unit))
    ) {
      this.unit = this.getDefaultUnit();
    }
  }

  setDefaultValue(value: number, unit?: string): void {
    this.value = value;

    if (this.quantity) {
      this.unit = unit || this.getDefaultUnit();
      this.value = this.convertToInternalUnit();
    }

    this.variableStatus = 'Default';
  }

  private parseCurrentValue(): any {
    return unitConverter.parseFloatString(this.value);
  }

  detectValueChange(another: SimulationVariable): boolean {
    const anotherValue = unitConverter.parseFloatString(another.convertToAnotherUnit(this.unit));
    const thisValue = unitConverter.parseFloatString(this.roundCurrentValue()); // this.parseCurrentValue();
    if ((isNaN(anotherValue) && isNaN(thisValue)) || (another.value === this.value && another.unit === this.unit)) {
      return false;
    }

    return thisValue !== anotherValue;
  }

  updateVariableStatus(another: SimulationVariable): void {
    if (this.detectValueChange(another)) {
      this.variableStatus = 'UserSpecified';
    }
  }

  override overwriteValues(another: SimulationVariable): void {
    // for sim vars, only value, unit and status may be modified
    this.unit = another.unit;
    this.value = another.value;
    this.variableStatus = another.variableStatus;
    this.isActive = another.isActive;
    this.readyStatus = another.readyStatus;
  }

  setIsActive(isActive: boolean) {
    this.isActive = isActive;
  }

  isUndefined(): boolean {
    return this.variableStatus === 'UnDefined' && (this.value === null || typeof this.value === 'undefined');
  }

  isSolverCalculated(): boolean {
    return this.variableStatus === VariableStatus.SOLVER_CALCULATED;
  }

  hasNumericValue(): boolean {
    return hasNumericValue(this.value);
  }

  // where is _Override_ ?
  public toJSON() {
    return {
      category: this.category,
      id: this.id,
      value: this.value,
      unit: this.unit,
      quantity: this.quantity,
      isActive: this.isActive === undefined ? true : this.isActive,
      variableStatus: this.variableStatus,
    };
  }

  outOfBounds(): boolean {
    if (this.hasNumericValue()) {
      return +this.value < this.lowerBound || +this.value > this.upperBound;
    }

    return true;
  }
}
