import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Case } from '../../case';
import { WaterUnitOperation } from './water-unit-operation';
import { SimulationVariable } from '../../simulation-variable';
import { PressureCalculationMode } from '../../../_config/pressure-calculation-mode.enum';
import { Quantity } from '../../../_config/quantity.enum';
import { BaseObject } from '../../base-object';
import { UnitOperationConstraints } from '../unit-operation-constraints';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';
import { ParameterProvider } from '../../_case-study/parameter-provider';

declare let unitConverter: any;

export class WaterTurbine extends WaterUnitOperation implements KpiProvider, ParameterProvider {
  category = unitOperationsConfig.waterTurbine.key;

  pressureCalculationMode: PressureCalculationMode | string;
  pressureDrop: SimulationVariable;
  outletPressure: SimulationVariable;
  duty: SimulationVariable;
  isentropicEfficiency: SimulationVariable;
  pressureRatio: SimulationVariable;
  polytropicEfficiency: SimulationVariable;

  dutyResult: SimulationVariable;

  massCapacity: SimulationVariable;
  minimumMassFlowrate: SimulationVariable;

  minimumDuty: SimulationVariable;
  maximumDuty: SimulationVariable;

  constraints: UnitOperationConstraints;

  constructor(unitOperation: any | WaterTurbine, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any): void {
    super.initValues(unitOperation);
    this.pressureCalculationMode = unitOperation.pressureCalculationMode || '';
    this.pressureDrop = new SimulationVariable(unitOperation.pressureDrop, this.ownerCase, this);
    this.outletPressure = new SimulationVariable(unitOperation.outletPressure, this.ownerCase, this);
    this.duty = new SimulationVariable(unitOperation.duty, this.ownerCase, this);
    this.isentropicEfficiency = new SimulationVariable(unitOperation.isentropicEfficiency, this.ownerCase, this);
    this.polytropicEfficiency = new SimulationVariable(unitOperation.polytropicEfficiency, this.ownerCase, this);
    this.pressureRatio = new SimulationVariable(unitOperation.pressureRatio, this.ownerCase, this);

    this.dutyResult = new SimulationVariable(unitOperation.dutyResult, this.ownerCase, this);

    this.massCapacity = new SimulationVariable(unitOperation.massCapacity, this.ownerCase, this);
    this.minimumMassFlowrate = new SimulationVariable(unitOperation.minimumMassFlowrate, this.ownerCase, this);
    this.minimumDuty = new SimulationVariable(unitOperation.minimumDuty, this.ownerCase, this);
    this.maximumDuty = new SimulationVariable(unitOperation.maximumDuty, this.ownerCase, this);

    this.initConstraints();
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('massCapacity', this.massCapacity);
    this.constraints.addConstraint('minimumMassFlowrate', this.minimumMassFlowrate);
    this.constraints.addConstraint('minimumDuty', this.minimumDuty);
    this.constraints.addConstraint('maximumDuty', this.maximumDuty);
  }

  override clearResults() {
    super.clearResults();
    this.dutyResult.value = null;
    this.pressureRatio.value = null;
    this.polytropicEfficiency.value = null;
  }

  override setSimulationVariablesOwner() {
    super.setSimulationVariablesOwner();
  }

  override addSimVarsToPool() {
    super.addSimVarsToPool();
  }

  override overwriteValues(another: BaseObject) {
    super.overwriteValues(another);
  }

  protected override ignoreForOverWrite(propertyName: string): boolean {
    return super.ignoreForOverWrite(propertyName);
  }

  override setSimulationVariableNames(): void {
    super.setSimulationVariableNames();
    this.pressureDrop.setName(SimulationVariableName.PRESSURE_DROP);
    this.outletPressure.setName(SimulationVariableName.OUTLET_PRESSURE);
    this.duty.setName(SimulationVariableName.DUTY);
    this.isentropicEfficiency.setName(SimulationVariableName.ISENTROPIC_EFFICIENCY);
    this.polytropicEfficiency.setName(SimulationVariableName.POLYTROPIC_EFFICIENCY);
    this.pressureRatio.setName(SimulationVariableName.PRESSURE_RATIO);

    this.dutyResult.setName(SimulationVariableName.DUTY);

    this.massCapacity.setName(SimulationVariableName.MAXIMUM_FEED_CAPACITY);
    this.minimumMassFlowrate.setName(SimulationVariableName.MINIMUM_MASS_FLOWRATE);
    this.minimumDuty.setName(SimulationVariableName.MINIMUM_DUTY);
    this.maximumDuty.setName(SimulationVariableName.MAXIMUM_DUTY);
  }

  override setDefaultValues() {
    if (this.pressureCalculationMode === '') {
      this.pressureCalculationMode = PressureCalculationMode.PRESSURE_DROP;
    }
    if (this.pressureDrop.isUndefined()) {
      this.pressureDrop.setDefaultValue(null);
    }
    if (this.outletPressure.isUndefined()) {
      this.outletPressure.setDefaultValue(null);
    }
    if (this.duty.isUndefined()) {
      this.duty.setDefaultValue(null, unitConverter.units.Power.KW);
    }

    if (this.dutyResult.isUndefined()) {
      this.dutyResult.setDefaultValue(null, unitConverter.units.Power.KW);
    }

    if (this.isentropicEfficiency.isUndefined()) {
      this.isentropicEfficiency.setDefaultValue(75, unitConverter.units.Dimensionless.PERCENT);
    }
    if (this.polytropicEfficiency.isUndefined()) {
      this.polytropicEfficiency.setDefaultValue(null, unitConverter.units.Dimensionless.PERCENT);
    }
    if (this.pressureRatio.isUndefined()) {
      this.pressureRatio.setDefaultValue(null);
    }

    if (this.minimumMassFlowrate.isUndefined()) {
      this.minimumMassFlowrate.setDefaultValue(null, unitConverter.units.Massflowrate.KPPH);
    }

    if (this.massCapacity.isUndefined()) {
      this.massCapacity.setDefaultValue(null, unitConverter.units.Massflowrate.KPPH);
    }

    if (this.minimumDuty.isUndefined()) {
      this.minimumDuty.setDefaultValue(null, unitConverter.units.Power.KW);
    }
    if (this.maximumDuty.isUndefined()) {
      this.maximumDuty.setDefaultValue(null, unitConverter.units.Power.KW);
    }

    super.setDefaultValues();
  }

  override setQuantities() {
    super.setQuantities();
    this.pressureDrop.setQuantity(Quantity.DELTA_PRESSURE);
    this.outletPressure.setQuantity(Quantity.PRESSURE);
    this.duty.setQuantity(Quantity.POWER);
    this.isentropicEfficiency.setQuantity(Quantity.DIMENSIONLESS);
    this.pressureRatio.setQuantity(Quantity.DIMENSIONLESS);
    this.polytropicEfficiency.setQuantity(Quantity.DIMENSIONLESS);

    this.dutyResult.setQuantity(Quantity.POWER);

    this.massCapacity.setQuantity(Quantity.MASSFLOWRATE);
    this.minimumMassFlowrate.setQuantity(Quantity.MASSFLOWRATE);

    this.minimumDuty.setQuantity(Quantity.POWER);
    this.maximumDuty.setQuantity(Quantity.POWER);
  }

  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [this.outletPressure, this.dutyResult];
    }
    return [this.outletPressure, this.isentropicEfficiency, this.dutyResult, this.pressureRatio];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.isentropicEfficiency];
  }

  getAvailableParameters() {
    return undefined;
  }

  override dePersist(sup: any) {
    super.dePersist(sup);

    this.pressureDrop = this.getSimulationVariableSafe(sup.pressureDrop);
    this.outletPressure = this.getSimulationVariableSafe(sup.outletPressure);
    this.duty = this.getSimulationVariableSafe(sup.duty);
    this.isentropicEfficiency = this.getSimulationVariableSafe(sup.isentropicEfficiency);
    this.pressureRatio = this.getSimulationVariableSafe(sup.pressureRatio);
    this.polytropicEfficiency = this.getSimulationVariableSafe(sup.polytropicEfficiency);

    this.dutyResult = this.getSimulationVariableSafe(sup.dutyResult);

    this.massCapacity = this.getSimulationVariableSafe(sup.massCapacity);
    this.minimumMassFlowrate = this.getSimulationVariableSafe(sup.minimumMassFlowrate);
    this.minimumDuty = this.getSimulationVariableSafe(sup.minimumDuty);
    this.maximumDuty = this.getSimulationVariableSafe(sup.maximumDuty);

    this.setDefaultValues();
    this.setQuantities();
    this.initConstraints();
  }
}
