import { Injectable } from '@angular/core';
import { GoJsPaletteUtils } from './gojs-palette-utils';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { AbstractSubFlowsheetPaletteHandler } from './abstract-sub-flowsheet-palette-handler';

@Injectable()
export class UtilitiesPaletteHandler extends AbstractSubFlowsheetPaletteHandler {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(paletteUtils: GoJsPaletteUtils) {
    super(paletteUtils);
  }

  getPaletteModel(): any[] {
    return [
      {
        name: unitOperationsConfig.waterBoiler.displayName,
        icon: unitOperationsConfig.waterBoiler.paletteIcon,
        diagramIcon: unitOperationsConfig.waterBoiler.diagramIcon,
        category: unitOperationsConfig.waterBoiler.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterSource.displayName,
        icon: unitOperationsConfig.waterSource.paletteIcon,
        diagramIcon: unitOperationsConfig.waterSource.diagramIcon,
        category: unitOperationsConfig.waterSource.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterSourceImport.displayName,
        icon: unitOperationsConfig.waterSourceImport.paletteIcon,
        diagramIcon: unitOperationsConfig.waterSourceImport.diagramIcon,
        category: unitOperationsConfig.waterSourceImport.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterSinkImport.displayName,
        icon: unitOperationsConfig.waterSinkImport.paletteIcon,
        diagramIcon: unitOperationsConfig.waterSinkImport.diagramIcon,
        category: unitOperationsConfig.waterSinkImport.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterHeatExchanger.displayName,
        icon: unitOperationsConfig.waterHeatExchanger.paletteIcon,
        diagramIcon: unitOperationsConfig.waterHeatExchanger.diagramIcon,
        category: unitOperationsConfig.waterHeatExchanger.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterTurbine.displayName,
        icon: unitOperationsConfig.waterTurbine.paletteIcon,
        diagramIcon: unitOperationsConfig.waterTurbine.diagramIcon,
        category: unitOperationsConfig.waterTurbine.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterHeater.displayName,
        icon: unitOperationsConfig.waterHeater.paletteIcon,
        diagramIcon: unitOperationsConfig.waterHeater.diagramIcon,
        category: unitOperationsConfig.waterHeater.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterCooler.displayName,
        icon: unitOperationsConfig.waterCooler.paletteIcon,
        diagramIcon: unitOperationsConfig.waterCooler.diagramIcon,
        category: unitOperationsConfig.waterCooler.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterTwoPhaseSeparator.displayName,
        icon: unitOperationsConfig.waterTwoPhaseSeparator.paletteIcon,
        diagramIcon: unitOperationsConfig.waterTwoPhaseSeparator.diagramIcon,
        category: unitOperationsConfig.waterTwoPhaseSeparator.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterPump.displayName,
        icon: unitOperationsConfig.waterPump.paletteIcon,
        diagramIcon: unitOperationsConfig.waterPump.diagramIcon,
        category: unitOperationsConfig.waterPump.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterValve.displayName,
        icon: unitOperationsConfig.waterValve.paletteIcon,
        diagramIcon: unitOperationsConfig.waterValve.diagramIcon,
        category: unitOperationsConfig.waterValve.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterSink.displayName,
        icon: unitOperationsConfig.waterSink.paletteIcon,
        diagramIcon: unitOperationsConfig.waterSink.diagramIcon,
        category: unitOperationsConfig.waterSink.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterMixer.displayName,
        icon: unitOperationsConfig.waterMixer.paletteIcon,
        diagramIcon: unitOperationsConfig.waterMixer.diagramIcon,
        category: unitOperationsConfig.waterMixer.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterSplitter.displayName,
        icon: unitOperationsConfig.waterSplitter.paletteIcon,
        diagramIcon: unitOperationsConfig.waterSplitter.diagramIcon,
        category: unitOperationsConfig.waterSplitter.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterHeader.displayName,
        icon: unitOperationsConfig.waterHeader.paletteIcon,
        diagramIcon: unitOperationsConfig.waterHeader.diagramIcon,
        category: unitOperationsConfig.waterHeader.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterPipe.displayName,
        icon: unitOperationsConfig.waterPipe.paletteIcon,
        diagramIcon: unitOperationsConfig.waterPipe.diagramIcon,
        category: unitOperationsConfig.waterPipe.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.waterCogeneration.displayName,
        icon: unitOperationsConfig.waterCogeneration.paletteIcon,
        diagramIcon: unitOperationsConfig.waterCogeneration.diagramIcon,
        category: unitOperationsConfig.waterCogeneration.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
    ];
  }
}
