<div
  id="diagram-item-color-picker-popup-container"
  style="position: relative; top: 0; left: 0; height: 0; width: 0; z-index: -1">
  <button
    #btnClickBlur
    id="diagram-item-color-picker-popup-button"
    type="button"
    style="background-color: transparent; z-index: 1000"></button>

  <div id="diagram-item-color-picker-popup" class="popover popover-xl"></div>

  <wj-popup
    #popup
    [owner]="btnClickBlur"
    [showTrigger]="PopupTrigger.None"
    [hideTrigger]="PopupTrigger.BlurPopup"
    (hiding)="onPopupHiding()"
    (hidden)="onPopupHidden()"
    (showing)="onPopupShowing()"
    (shown)="onPopupShown()"
    class="popover popover-xl">
    <div class="popover-content" *ngIf="state">
      <input
        [(colorPicker)]="selectedColor"
        [cpOutputFormat]="'rgba'"
        [cpDialogDisplay]="'inline'"
        [cpAlphaChannel]="'always'"
        [(ngModel)]="selectedColor"
        (colorPickerChange)="onColorPickerInputChanged($event)"
        class="form-control"
        id="streamColor"
        readonly="readonly" />
    </div>
  </wj-popup>
</div>
