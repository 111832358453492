<ul class="nav nav-pills" role="tablist">
  <li role="presentation" class="active" *ngIf="!upgTotalGhgEmissions">
    <a href="#urGhgEmissionsReportChartTab" role="tab" data-toggle="tab" aria-controls="srGhgEmissionsReportChartTab">
      Pie Chart
    </a>
  </li>

  <li role="presentation" *ngIf="!upgTotalGhgEmissions">
    <a href="#urGhgEmissionsReportTableTab" role="tab" data-toggle="tab" aria-controls="srGhgEmissionsReportTableTab">
      Table
    </a>
  </li>

  <li role="presentation" *ngIf="!upgTotalGhgEmissions">
    <a href="#urGhgEmissionsWaterfallTab" role="tab" data-toggle="tab" aria-controls="srGhgEmissionsReportTable">
      Waterfall
    </a>
  </li>
</ul>

<div class="tab-content tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="urGhgEmissionsReportChartTab">
    <div id="urGhgEmissionsChart"></div>

    <div class="row" style="margin-top: 20px; {{ upgTotalGhgEmissions ? 'font-size: 20px' : '' }}">
      <div class="col-xs-12 text-center">
        <strong>Total GHG Emissions</strong>
        {{ (upgTotalGhgEmissions ? upgTotalGhgEmissions : upgraderData?.total) | thousandSeparator }} {{ this.unit }}
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="urGhgEmissionsReportTableTab">
    <div class="row">
      <div class="col-xs-12">
        <table class="table" id="urGhgEmissionsTable" style="width: 100%">
          <thead>
            <tr>
              <th>Unit Operation</th>
              <th>Type</th>
              <th>GHG Emissions</th>
            </tr>
          </thead>

          <tfoot>
            <tr>
              <th colspan="2" class="text-right">Total</th>
              <th>{{ this.upgraderData?.total | thousandSeparator }} {{ this.unit }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="urGhgEmissionsWaterfallTab">
    <div id="urGhgEmissionsWaterfallChart"></div>
  </div>
</div>
