export enum StreamCompositionNames {
  LIGHT_ENDS = 'Light Ends',
  LIGHT_NAPHTHA = 'Light Naphtha',
  HEAVY_NAPHTHA = 'Heavy Naphtha',
  DISTILLATE = 'Distillate',
  LGO = 'LGO',
  HGO = 'HGO',
  VGO = 'VGO',
  HVGO = 'HVGO',
  RESID = 'Resid',
}
