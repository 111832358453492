import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'sob-number-validation-messages',
  templateUrl: './number-validation-messages.component.html',
  styleUrls: ['./number-validation-messages.component.css'],
})
export class NumberValidationMessagesComponent {
  @Input() control: AbstractControl;
}
