import { AfterViewInit, ContentChild, ContentChildren, Directive, Input, OnInit, QueryList } from '@angular/core';
import { ValueInputComponent } from '../value-input/value-input.component';
import { UnitSelectComponent } from '../unit-select/unit-select.component';
import { attachMultipleSimVarEvent } from '../unit-converter.helper';
import { BoundedObject } from '../../../_models/bounded-object';
import { ReadyStatus } from '../../../_config/ready-status.enum';

declare let unitConverter: any;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[sfeMultipleSimVarInput]',
})
export class MultipleSimVarInputDirective implements OnInit, AfterViewInit {
  @Input() totalOutputId: string; // id of the element where the total value will be shown
  @Input() objectToValidate: BoundedObject;
  @ContentChildren(ValueInputComponent, { descendants: true }) valueInputs: QueryList<ValueInputComponent>;
  @ContentChild(UnitSelectComponent, { static: true }) unitSelect: UnitSelectComponent;

  private internalUnit: string;

  constructor() {}

  ngOnInit() {
    if (this.objectToValidate) {
      this.internalUnit = unitConverter(this.objectToValidate.quantity).getInternalUnit();
    }
  }

  ngAfterViewInit() {
    attachMultipleSimVarEvent(this.valueInputs, this.unitSelect);
    const $unitSelect = $(`#${this.unitSelect.selectId}`);

    if (this.totalOutputId) {
      this.calculateTotal();
      $unitSelect.on('change', () => {
        this.calculateTotal();
      });

      this.valueInputs.forEach(vi => {
        $(`#${vi.inputId}`).on('change', () => {
          this.calculateTotal();
        });
      });
    }
  }

  calculateTotal() {
    let total = 0;

    this.valueInputs.forEach(vi => {
      const val = unitConverter.parseFloatString(vi.displayValue);

      if (!isNaN(val)) {
        total += val;
      }
    });

    total = parseFloat(total.toFixed(5));

    const $totalOutput = $(`#${this.totalOutputId}`);
    $totalOutput.val(total);

    if (this.objectToValidate) {
      const totalInternal = unitConverter(this.objectToValidate.quantity)
        .convert(total, this.getSelectedUnit(), this.internalUnit)
        .getValue();

      if (totalInternal < this.objectToValidate.lowerBound || totalInternal > this.objectToValidate.upperBound) {
        this.objectToValidate.readyStatus = ReadyStatus.NOT_READY;
        $totalOutput.addClass(ReadyStatus.NOT_VALID);
      } else {
        this.objectToValidate.readyStatus = ReadyStatus.READY;
        $totalOutput.removeClass(ReadyStatus.NOT_VALID);
      }
    }
  }

  getSelectedUnit(): string {
    return $(`#${this.unitSelect.selectId}`).val() as string;
  }
}
