import { Component, Input } from '@angular/core';
import { HydrogenPlant } from '../../../_models/_unit-operations/hydrogen-plant';

@Component({
  selector: 'sob-hydrogen-plant',
  templateUrl: './hydrogen-plant.component.html',
  styleUrls: ['./hydrogen-plant.component.css'],
})
export class HydrogenPlantComponent {
  @Input() unitOperation: HydrogenPlant;
}
