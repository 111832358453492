<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a
      href="#waterTwoPhaseSeparatorComments"
      aria-controls="waterTwoPhaseSeparatorComments"
      role="tab"
      data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Inputs</h4>
        <hr />
        <br />

        <h4 class="text-center font-bold">Carryover</h4>
        <br />
        <div class="form-group">
          <label [attr.for]="'gasLiquidCarryover'" class="pw-label control-label col-sm-4"> Gas in Liquid </label>

          <sim-var-input
            [inputId]="'gasLiquidCarryover'"
            [simVar]="unitOperation.gasLiquidCarryover"
            [unitSubset]="UnitSubset.MOLE_FRACTION_NO_PPM"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'liquidGasCarryover'" class="pw-label control-label col-sm-4"> Liquid in Gas </label>

          <sim-var-input
            [inputId]="'liquidGasCarryover'"
            [simVar]="unitOperation.liquidGasCarryover"
            [unitSubset]="UnitSubset.MOLE_FRACTION_NO_PPM"></sim-var-input>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="waterTwoPhaseSeparatorComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
