import { ConstraintsReport } from '../../_models/_reports/_optimization-settings-table/constraints-report';
import { Case } from '../../_models';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { ExcelReportUtils } from './excel-report.utils';

export class MassBalanceExcelReport {
  static generateDataArray(c: Case, flowsheetOwnerId?: string): any[] {
    const dataArray = [
      [
        'Block name',
        'All values in kg/s',
        'Light ends',
        'light naphtha',
        'Heavy naphtha',
        'Distillate',
        'LGO',
        'HGO',
        'VGO',
        'HVGO',
        'Resid',
      ],
      [''],
    ];

    const uos = c.filterUnitOperations((uo: SuncorUnitOperation) => {
      if (flowsheetOwnerId) {
        return uo instanceof SuncorUnitOperation && uo.flowsheetId === flowsheetOwnerId
          ? uo.outletCutMassFlows.length > 0 || uo.inletCutMassFlows.length > 0
          : false;
      }
      return uo instanceof SuncorUnitOperation && uo.flowsheetId === ''
        ? uo.outletCutMassFlows.length > 0 || uo.inletCutMassFlows.length > 0
        : false;
    });

    uos.forEach((uo: SuncorUnitOperation) => {
      const inlets = [uo.name, 'Total inlet'];
      const outlets = ['', 'Total outlet'];
      uo.inletCutMassFlows.forEach(value => {
        inlets.push(ExcelReportUtils.roundFormat(value, -2));
      });
      uo.outletCutMassFlows.forEach(value => {
        outlets.push(ExcelReportUtils.roundFormat(value, -2));
      });
      dataArray.push(inlets);
      dataArray.push(outlets);
      dataArray.push(['']);
    });
    return dataArray;
  }
}
