import { BaseObject } from '../base-object';
import { Case } from '../case';
import { KpiManager } from './kpi-manager';
import { CaseStudyCategory } from './case-study-category';
import { UncertaintyAnalysis } from './uncertainty-analysis';
import { ParametricStudy } from './parametric-study';
import { isParameterProvider, ParameterProvider } from './parameter-provider';
import { DiluentSource } from '../_unit-operations/diluent-source';
import { Refinery } from '../_unit-operations/refinery';
import { CaseSettings } from '../case-settings';
import { isKpiProvider } from './kpi-provider';
import { isMultiPeriodParameter } from '../_multi-period/multi-period-parameter';
import { Subject } from 'rxjs';

export class CaseStudyManager extends BaseObject {
  category = 'caseStudyManager';

  kpiManager: KpiManager;

  uncertaintyAnalyses: Array<UncertaintyAnalysis> = [];

  parametricStudies: ParametricStudy[] = [];

  currentAnalysisId: string;

  currentStudyId: string;

  uncertaintyCategories: Array<CaseStudyCategory> = [];

  parametricStudyCategories: CaseStudyCategory[] = [];

  private analysisResultsAdded = new Subject<UncertaintyAnalysis>();

  analysisResultsAddedRequest = this.analysisResultsAdded.asObservable();

  constructor(caseStudyManager: any, ownerCase: Case) {
    super(caseStudyManager.id, ownerCase);

    if (caseStudyManager.uncertaintyAnalyses instanceof Array) {
      for (const uncertaintyAnalysis of caseStudyManager.uncertaintyAnalyses) {
        if (uncertaintyAnalysis instanceof UncertaintyAnalysis) {
          this.uncertaintyAnalyses.push(uncertaintyAnalysis);
        }
      }
    }
    if (caseStudyManager.currentStudyId) {
      this.currentStudyId = caseStudyManager.currentStudyId;
    }
    if (caseStudyManager.parametricStudies instanceof Array) {
      for (const ps of caseStudyManager.parametricStudies) {
        this.parametricStudies.push(ps);
      }
    }
    this.currentStudyId = caseStudyManager.currentStudyId || '';
    this.currentAnalysisId = caseStudyManager.currentAnalysisId || '';
  }

  private initParameterCategories() {
    this.parametricStudyCategories.push(
      new CaseStudyCategory('Diluent sources', uo => {
        return uo instanceof DiluentSource;
      })
    );

    this.parametricStudyCategories.push(
      new CaseStudyCategory('Refineries', uo => {
        return uo instanceof Refinery;
      })
    );

    this.parametricStudyCategories.push(
      new CaseStudyCategory('Case settings', cs => {
        return cs instanceof CaseSettings;
      })
    );
  }

  addUncertaintyAnalysis(name: string): UncertaintyAnalysis {
    const ua: UncertaintyAnalysis = new UncertaintyAnalysis({}, this.ownerCase);
    ua.setName(name);

    this.uncertaintyAnalyses.push(ua);
    this.ownerCase.addToPools(ua);

    return ua;
  }

  addParametricStudy(name: string) {
    const ps = new ParametricStudy({}, this.ownerCase);
    ps.setName(name);

    this.parametricStudies.push(ps);
    this.ownerCase.addToPools(ps);

    return ps;
  }

  removeUncertaintyAnalysis(id: string) {
    const analyses = this.uncertaintyAnalyses.filter(ua => {
      return ua.id === id;
    });

    if (analyses.length) {
      const index = this.uncertaintyAnalyses.indexOf(analyses[0]);

      if (index > -1) {
        this.uncertaintyAnalyses.splice(index, 1);
        this.ownerCase.removeFromPools(analyses[0]);
        this.ownerCase.caseStudyManager.currentAnalysisId = '';
      }
    }
  }

  removeParametricStudy(id: string) {
    const ps = this.parametricStudies.find(p => p.id === id);

    if (ps) {
      const i = this.parametricStudies.indexOf(ps);
      this.parametricStudies.splice(i, 1);
      this.ownerCase.removeFromPools(ps);
    }
  }

  addToUncertaintyCategories(category: CaseStudyCategory): void {
    this.uncertaintyCategories.push(category);
  }

  addToParametricStudyCategories(category: CaseStudyCategory): void {
    this.parametricStudyCategories.push(category);
  }

  findUncertaintyAnalysisById(id: String): UncertaintyAnalysis {
    return this.uncertaintyAnalyses.find(ua => ua.id === id);
  }

  findParametricStudy(id: string): ParametricStudy {
    return this.parametricStudies.find(ps => ps.id === id);
  }

  addUncertaintyAnalysisResults() {
    const currentAnalysis = this.uncertaintyAnalyses[0];
    this.analysisResultsAdded.next(currentAnalysis);
  }

  // region kpi and parameter providers
  /**
   * Builds a list of BaseObject filled with all the available kpi providers from case's unit operation pool, material
   * stream pool and other base object pool.
   * @returns {Array} All available kpi providers
   */
  getAvailableKpiProviders(): BaseObject[] {
    const providers: BaseObject[] = [];

    const uoKeys = Object.keys(this.ownerCase.unitOperationPool);
    for (const id of uoKeys) {
      const uo = this.ownerCase.unitOperationPool[id];
      if (isKpiProvider(uo)) {
        providers.push(this.ownerCase.unitOperationPool[id]);
      }
    }

    const streamIds = this.ownerCase.getAllMaterialStreamIds();

    for (const id of streamIds) {
      const stream = this.ownerCase.getMaterialStream(id);
      if (isKpiProvider(stream)) {
        providers.push(stream);
      }
    }

    const boKeys = Object.keys(this.ownerCase.otherBaseObjectPool);
    for (const id of boKeys) {
      const bo = this.ownerCase.otherBaseObjectPool[id];
      if (isKpiProvider(bo)) {
        providers.push(bo);
      }
    }

    return providers;
  }

  getAvailableMultiPeriodProviders(): BaseObject[] {
    const multiPeriodProviders = [];

    const uoKeys = Object.keys(this.ownerCase.unitOperationPool);

    for (const id of uoKeys) {
      const uo = this.ownerCase.unitOperationPool[id];
      if (isMultiPeriodParameter(uo)) {
        multiPeriodProviders.push(uo);
      }
    }

    const boKeys = Object.keys(this.ownerCase.otherBaseObjectPool);
    for (const id of boKeys) {
      const bo = this.ownerCase.otherBaseObjectPool[id];
      if (isMultiPeriodParameter(bo)) {
        multiPeriodProviders.push(bo);
      }
    }

    return multiPeriodProviders;
  }

  /**
   *
   * @param category
   * @returns {Array<BaseObject>}
   */
  getKpiProvidersForCategory(category: CaseStudyCategory): BaseObject[] {
    const availableKpiProviders = this.getAvailableKpiProviders();
    const baseObjectsByCategory: BaseObject[] = [];

    for (const kpiProvider of availableKpiProviders) {
      if (category.filterCallback(kpiProvider)) {
        baseObjectsByCategory.push(kpiProvider);
      }
    }

    return baseObjectsByCategory.sort();
  }

  getMultiPeriodProvidersForCategory(category: CaseStudyCategory): BaseObject[] {
    const availableMultiPeriodProviders = this.getAvailableMultiPeriodProviders();
    const baseObjectsByCategory: BaseObject[] = [];

    for (const multiPeriodProvider of availableMultiPeriodProviders) {
      if (category.filterCallback(multiPeriodProvider)) {
        baseObjectsByCategory.push(multiPeriodProvider);
      }
    }

    return baseObjectsByCategory.sort();
  }

  /**
   * Builds a list of BaseObject filled with all the available kpi providers from case's unit operation pool, material
   * stream pool and other base object pool.
   * @returns {Array} All available kpi providers
   */
  getAvailableParameterProviders(): BaseObject[] {
    const providers: BaseObject[] = [];

    const uoKeys = Object.keys(this.ownerCase.unitOperationPool);
    for (const id of uoKeys) {
      const uo = this.ownerCase.unitOperationPool[id];
      if (isParameterProvider(uo)) {
        providers.push(uo);
      }
    }

    const streamIds = this.ownerCase.getAllSuncorMaterialStreamIds();
    for (const id of streamIds) {
      const stream = this.ownerCase.getSuncorMaterialStream(id);
      if (isParameterProvider(stream)) {
        providers.push(stream);
      }
    }

    const boKeys = Object.keys(this.ownerCase.otherBaseObjectPool);
    for (const id of boKeys) {
      const bo = this.ownerCase.otherBaseObjectPool[id];
      if (isParameterProvider(bo)) {
        providers.push(bo);
      }
    }
    return providers;
  }

  getUncertainParameterProvidersByCategory(category: CaseStudyCategory) {
    const availableParameterProviders = this.getAvailableParameterProviders();
    const baseObjectsByCategory: Array<BaseObject> = [];

    for (const paramProvider of availableParameterProviders) {
      if (category.filterCallback(paramProvider)) {
        baseObjectsByCategory.push(paramProvider);
      }
    }

    return baseObjectsByCategory.sort();
  }

  getParameterProvidersByCategory(category: CaseStudyCategory): (BaseObject & ParameterProvider)[] {
    const availableParameterProviders = this.getAvailableParameterProviders();
    const baseObjectsByCategory = [];

    for (const paramProvider of availableParameterProviders) {
      if (category.filterCallback(paramProvider)) {
        baseObjectsByCategory.push(paramProvider);
      }
    }

    return baseObjectsByCategory.sort();
  }

  getUncertainParameterProvidersByCategoryName(categoryName: string): Array<BaseObject> {
    const selectedCategory: CaseStudyCategory = this.uncertaintyCategories.find(c => c.categoryName === categoryName);
    return this.getUncertainParameterProvidersByCategory(selectedCategory);
  }

  getParameterProvidersByCategoryName(categoryName: string): (BaseObject & ParameterProvider)[] {
    const selectedCategory: CaseStudyCategory = this.parametricStudyCategories.find(
      c => c.categoryName === categoryName
    );

    return this.getParameterProvidersByCategory(selectedCategory);
  }
  // endregion

  // region serialization and deserialization
  override dePersist(persistedObj: any) {
    this.kpiManager = <KpiManager>this.ownerCase.getOtherBaseObject(persistedObj.kpiManager);
    if (persistedObj.parametricStudies) {
      for (let i = 0; i < persistedObj.parametricStudies.length; i++) {
        this.parametricStudies[i] = this.ownerCase.getOtherBaseObject(
          persistedObj.parametricStudies[i]
        ) as ParametricStudy;
      }
    }
    if (persistedObj.parametricStudies) {
      for (const analysisId of persistedObj.uncertaintyAnalyses) {
        const ua = <UncertaintyAnalysis>this.ownerCase.getOtherBaseObject(analysisId);
        this.uncertaintyAnalyses.push(ua);
      }
    }
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      category: this.category,
      kpiManager: this.kpiManager.id,
      uncertaintyAnalyses: this.uncertaintyAnalyses.map(ua => ua.id),
      currentAnalysisId: this.currentAnalysisId,
      parametricStudies: this.parametricStudies.map(ps => ps.id),
      currentStudyId: this.currentStudyId,
    };
  }
  // endregion
}
