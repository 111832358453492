import { ChangeDetectorRef, Component } from '@angular/core';
import { CoreService } from '../../_services/core.service';
import { ConstraintViolationEntry } from '../../_models/constraint-violation-entry';

@Component({
  selector: 'sob-constraint-violation-report',
  templateUrl: './constraint-violation-report.component.html',
  styleUrls: ['./constraint-violation-report.component.css'],
})
export class ConstraintViolationReportComponent {
  constraintViolationEntries: ConstraintViolationEntry[] = [];
  constructor(private coreService: CoreService, private ref: ChangeDetectorRef) {}

  showConstraintViolationReport() {
    $('#constraintViolationReportModal').modal('show');
  }
}
