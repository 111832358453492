import { isTypeFunction } from '../../_utils/utils';

export interface InletDistributionRatioVariablesOwner {
  addInletDistributionRatioVariables(unitOperationId: string): void;
  removeInletDistributionRatioVariables(unitOperationId: string): void;
}

export function isInletDistributionRatioVariablesOwner(val: any): val is InletDistributionRatioVariablesOwner {
  return (
    isTypeFunction(val.addInletDistributionRatioVariables) && isTypeFunction(val.removeInletDistributionRatioVariables)
  );
}
