import { SuncorUnitOperation } from './suncor-unit-operation';
import { IUnitOperation } from '../i-unit-operation';
import { SimulationVariable } from '../simulation-variable';
import { Case } from '../case';
import { BaseObject } from '../base-object';
import { isTypeUndefined } from '../../_utils/utils';
import { ParameterProvider } from '../_case-study/parameter-provider';
import { unitOperationsConfig } from '../../_config/unit-operations.config';

export class Extraction extends SuncorUnitOperation implements IUnitOperation, ParameterProvider {
  category = unitOperationsConfig.extraction.key;

  yield: SimulationVariable;
  bitumenLossToTailing: SimulationVariable;
  price: SimulationVariable;
  fluidAnalysisId: string;

  useSubFlowsheet: boolean;

  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override clearResults() {
    super.clearResults();
    this.ghgEmissions.value = null;
    this.unitValue.value = null;
    this.opexCarbonDioxide.value = null;
    this.opexTotal.value = null;
    this.capexTotal.value = null;
  }

  override initValues(unitOperation: any): void {
    super.initValues(unitOperation);
    this.yield = new SimulationVariable(unitOperation.yield, this.ownerCase, this);
    this.bitumenLossToTailing = new SimulationVariable(unitOperation.bitumenLossToTailing, this.ownerCase, this);
    this.price = new SimulationVariable(unitOperation.price, this.ownerCase, this);
    this.fluidAnalysisId = unitOperation.fluidAnalysisId || '';
    this.useSubFlowsheet = isTypeUndefined(unitOperation.useSubFlowsheet) ? false : unitOperation.useSubFlowsheet;
  }

  override addSimVarsToPool() {
    super.addSimVarsToPool();
  }

  override overwriteValues(another: BaseObject) {
    super.overwriteValues(another);
  }

  protected override ignoreForOverWrite(propertyName: string): boolean {
    return super.ignoreForOverWrite(propertyName);
  }

  override setSimulationVariableNames(): void {
    super.setSimulationVariableNames();
    this.yield.setName('Yield');
    this.bitumenLossToTailing.setName('Bitumen Loss to Tailing');
    this.price.setName('Price');
  }

  override setQuantities() {
    super.setQuantities();
    this.yield.setQuantity('Percent');
    this.bitumenLossToTailing.setQuantity('Massflowrate');
    this.price.setQuantity('CurrencyPerVolume');
  }

  override setDefaultValues(): void {
    if (this.yield.isUndefined()) {
      this.yield.setDefaultValue(95.0);
    }
    if (this.price.isUndefined()) {
      this.price.setDefaultValue(50.0);
    }
    super.setDefaultValues();
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.yield, this.ghgIntensity, this.price, this.opexFixed, this.opexVar, this.capexAmortized];
  }

  getAvailableParameters() {
    return [this.ghgIntensity, this.price, this.opexVar, this.opexFixed];
  }

  override ignoreForMakeReady(simvar: SimulationVariable): boolean {
    return super.ignoreForMakeReady(simvar) || simvar === this.capacity || simvar === this.minimumFlow;
  }

  override get isFlowsheetOwner() {
    return true;
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.yield = this.getSimulationVariableSafe(sup.yield);
    this.bitumenLossToTailing = this.getSimulationVariableSafe(sup.bitumenLossToTailing);
    this.price = this.getSimulationVariableSafe(sup.price);

    this.setQuantities();
    this.setDefaultValues();
  }

  getAlternativeGhgIntensity(): SimulationVariable {
    return new SimulationVariable(this.ghgIntensity, this.ownerCase, this);
  }
}
