<div>
  <label class="subtitle-v2 gas-label">Gas</label>

  <a class="btn btn-outline btn-success btn-xs m-l-sm" href="#gasDiv" (click)="switchOpened()" aria-expanded="false">
    <i *ngIf="!isOpened" class="fa fa-plus"></i>
    <i *ngIf="isOpened" class="fa fa-minus"></i>
  </a>
</div>

<div [formGroup]="formGroup" class="collapse" id="gasDiv" aria-expanded="false">
  <div class="form-group">
    <label for="customGasModelEnabled" class="control-label col-sm-4"> Use Custom Model for Gas </label>
    <div class="col-sm-4">
      <label class="control-label">
        <input type="checkbox" id="customGasModelEnabled" formControlName="useCustomGasModel" />
      </label>
    </div>

    <div class="clearfix"></div>
  </div>
  <div *ngIf="useCustomGasModel">
    <sob-open-code-editor-button
      [code]="unitOperation.gasModelScript"
      [defaultCodeProvider]="defaultCodeProvider"
      [instanceId]="'gasCodeEditor'"
      [disabled]="!useCustomGasModel"
      (codeSaved)="saveGasCustomModel($event)">
    </sob-open-code-editor-button>
  </div>
  <hr class="m-t-md m-b-md" />

  <div class="form-group" formGroupName="fuelGasEmissionFactor">
    <label class="control-label col-sm-4"> Fuel Gas Emission Factor </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.fuelGasEmissionFactor.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input
            formControlName="value"
            [valueMetaData]="unitOperation.fuelGasEmissionFactor">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="fuelGasHeatingValue">
    <label class="control-label col-sm-4"> Fuel Gas Heating Value (LHV) </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.fuelGasHeatingValue.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input
            formControlName="value"
            [valueMetaData]="unitOperation.fuelGasHeatingValue">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="energyFlow">
    <label class="control-label col-sm-4"> Energy Flow </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.energyFlow.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.energyFlow">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="flowrate">
    <label class="control-label col-sm-4"> Flowrate </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.flowrate.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.flowrate">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-sm-4"> Gas Analysis </label>

    <div class="col-sm-8">
      <div class="row">
        <div class="col-xs-12">
          <sob-assay-selector
            formControlName="gasAnalysisId"
            [fluidAnalyses]="fluidAnalyses"
            [currentAnalysis]="currentAnalysis"
            (updateCurrentAssay)="updateCurrentAnalysis($event)">
          </sob-assay-selector>
        </div>
      </div>
    </div>
  </div>
</div>
