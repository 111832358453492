import { ConstraintReportVariable } from '../constraint-report-variable';
import { SimulationVariable } from '../../../simulation-variable';
import { CommodityTank } from '../../../_unit-operations/commodity-tank';
import { SimulationVariableName } from '../../../../_config/simulation-variable-name.enum';

export class CommodityTankReportVariableResolver {
  static create() {
    return new CommodityTankReportVariableResolver();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  addCurrentValueAndUnitToConstraintReportVariable(
    crv: ConstraintReportVariable,
    params: { uo: CommodityTank; simVar: SimulationVariable }
  ): void {
    const { uo, simVar } = params;

    // TODO only three constraints??? the other two are actually added in PROD - do they get value from commodityVOlume?
    if (simVar.name === SimulationVariableName.MAXIMUM_SULFUR) {
      crv.currentValue = uo.commoditySulfur.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.commoditySulfur.getDefaultUnit();
      crv.constraintDisabled = uo.isBlendRatioConstraintSelected(); // TODO ask if this is correct
      return;
    }

    if (simVar.name === SimulationVariableName.MAXIMUM_NITROGEN) {
      crv.currentValue = uo.commodityNitrogen.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.commodityNitrogen.getDefaultUnit();
      crv.constraintDisabled = uo.isBlendRatioConstraintSelected(); // TODO ask if this is correct
      return;
    }

    if (simVar.name === SimulationVariableName.MINIMUM_API) {
      crv.currentValue = uo.commodityDensity.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.commodityDensity.getDefaultUnit();
      crv.constraintDisabled = uo.isBlendRatioConstraintSelected(); // TODO ask if this is correct
      return;
    }

    if (
      simVar.name === SimulationVariableName.MINIMUM_DEMAND ||
      simVar.name === SimulationVariableName.MAXIMUM_DEMAND
    ) {
      crv.currentValue = uo.commodityVolume.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.commodityVolume.getDefaultUnit();
      return;
    }

    throw new Error(`Unknown constraint ${simVar.name}`);
  }
}
