import { Pipe, PipeTransform } from '@angular/core';

declare let unitConverter: any;
@Pipe({
  name: 'thousandSeparator',
})
export class ThousandSeparatorPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return unitConverter.formatNumber(value);
  }
}
