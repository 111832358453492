<div
  class="modal fade"
  id="optimizationSettingsTableModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="optimizationSettingsTableLabel">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="">{{ getActiveFlowsheetTitle }}</h4>
      </div>

      <div class="modal-body">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active">
            <a href="#ostConstraints" role="tab" data-toggle="tab" aria-controls="ostConstraints"> Constraints </a>
          </li>

          <li role="presentation">
            <a href="#ostOptimizationVariables" role="tab" data-toggle="tab" aria-controls="ostOptimizationVariables">
              Optimization Variables
            </a>
          </li>

          <li style="float: right">
            <sob-flowsheet-tree-selector
              [modalName]="'optimizationSettingsTableModal'"
              (emitFlowsheetOwnerId)="getFlowsheetOwnerId($event)"></sob-flowsheet-tree-selector>
          </li>
        </ul>

        <div class="tab-content tab-content-padding">
          <div role="tabpanel" class="tab-pane fade in active" id="ostConstraints">
            <div class="row">
              <div class="col-xs-12">
                <table class="table" id="ostConstraintsTable" style="width: 100%">
                  <thead>
                    <tr>
                      <th>Active</th>
                      <th>Block Name</th>
                      <th>Constraint</th>
                      <th>Constraint Value</th>
                      <th>Current Value</th>
                      <th>Unit</th>
                      <th>Violated</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade in" id="ostOptimizationVariables">
            <div class="row">
              <div class="col-xs-12">
                <table class="table" id="ostOptimizationVariablesTable" style="width: 100%">
                  <thead>
                    <tr>
                      <th style="width: 50px">Optimize</th>
                      <th>Variable Name</th>
                      <th>Owner</th>
                      <th>Unit</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
