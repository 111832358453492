<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">Model</a>
  </li>

  <li role="presentation">
    <a href="#waterPipeComments" aria-controls="waterPipeComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Inputs</h4>
        <hr />
        <br />

        <div class="form-group">
          <label for="pipePressureModel" class="pw-label control-label col-sm-4"> Pipe Pressure Model </label>

          <div class="col-sm-8" id="pipePressureModel">
            <select
              id="selectPressureModel"
              (change)="pipePressureModelChanged($event.target.value)"
              class="form-control">
              <option value="Homogeneous">Homogeneous</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label for="thermalCalculationMode" class="pw-label control-label col-sm-4"> Thermal Calculation Mode </label>
          <div class="col-sm-8" id="thermalCalculationMode">
            <select
              id="selectThermalMode"
              (change)="thermalCalculationModeChanged($event.target.value)"
              class="form-control">
              <option value="Adiabatic">Adiabatic</option>
              <option value="Isothermal">Isothermal</option>
              <option value="Thermal">Thermal</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label [attr.for]="'stepLength'" class="pw-label control-label col-sm-4"> Step Length </label>
          <sim-var-input [inputId]="'stepLength'" [simVar]="unitOperation.stepLength"></sim-var-input>
        </div>
        <br />

        <h4 class="subtitle">Segments</h4>
        <hr />
        <br />
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-1 col-md-offset-11">
              <button
                (click)="addWaterPipeSegmentFormGroup()"
                class="btn btn-primary btn-xs addButton"
                data-toggle="tooltip"
                title="Add new water pipe segment">
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
        </div>

        <div [formGroup]="waterPipeSegmentForm">
          <div *ngFor="let segment of this.unitOperation.segments; let i = index" [formGroupName]="segment.id">
            <div class="col-md-12">
              <div class="row">
                <div class="col-xs-10">
                  <h4>
                    <a
                      data-toggle="collapse"
                      sfeCollapsePanelIcon
                      href="#segmentProperties{{ i }}"
                      aria-expanded="false"
                      [attr.aria-controls]="'#segmentProperties' + i"
                      [attr.for]="'segmentName' + i">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                      {{ segment.name }}
                    </a>
                  </h4>
                </div>
                <div class="col-xs-2">
                  <button
                    class="btn btn-xs btn-danger removeButton"
                    (click)="removeWaterPipeSegment(segment)"
                    data-toggle="tooltip"
                    title="Remove water pipe segment">
                    <i class="fa fa-minus-circle"></i>
                  </button>
                </div>
              </div>
            </div>

            <br />

            <div id="segmentProperties{{ i }}" class="collapse">
              <div class="form-group" formGroupName="segmentLength">
                <label [attr.for]="'segmentLength' + i" class="pw-label control-label col-sm-4"> Length </label>
                <div class="col-sm-8" sobReactiveSimVarInput>
                  <div class="row">
                    <div class="col-xs-6">
                      <sob-reactive-unit-select formControlName="unit" [quantity]="segment.segmentLength.quantity">
                      </sob-reactive-unit-select>
                    </div>
                    <div class="col-xs-6">
                      <sob-reactive-variable-value-input
                        formControlName="value"
                        [valueMetaData]="segment.segmentLength">
                      </sob-reactive-variable-value-input>
                      <span class="text-danger" *ngIf="getValueFieldsErrorValidations(segment.id, 'segmentLength')">
                        Please enter a value
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" formGroupName="insideDiameter">
                <label [attr.for]="'insideDiameter' + i" class="pw-label control-label col-sm-4">
                  Inside Diameter
                </label>
                <div class="col-sm-8" sobReactiveSimVarInput>
                  <div class="row">
                    <div class="col-xs-6">
                      <sob-reactive-unit-select formControlName="unit" [quantity]="segment.insideDiameter.quantity">
                      </sob-reactive-unit-select>
                    </div>
                    <div class="col-xs-6">
                      <sob-reactive-variable-value-input
                        formControlName="value"
                        [valueMetaData]="segment.insideDiameter">
                      </sob-reactive-variable-value-input>
                      <span class="text-danger" *ngIf="getValueFieldsErrorValidations(segment.id, 'insideDiameter')">
                        Please enter a value
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" formGroupName="outsideDiameter">
                <label [attr.for]="'outsideDiameter' + i" class="pw-label control-label col-sm-4">
                  Outside Diameter
                </label>
                <div class="col-sm-8" sobReactiveSimVarInput>
                  <div class="row">
                    <div class="col-xs-6">
                      <sob-reactive-unit-select formControlName="unit" [quantity]="segment.outsideDiameter.quantity">
                      </sob-reactive-unit-select>
                    </div>
                    <div class="col-xs-6">
                      <sob-reactive-variable-value-input
                        formControlName="value"
                        [valueMetaData]="segment.outsideDiameter">
                      </sob-reactive-variable-value-input>
                      <span class="text-danger" *ngIf="getValueFieldsErrorValidations(segment.id, 'outsideDiameter')">
                        Please enter a value
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" formGroupName="segmentAngle">
                <label [attr.for]="'segmentAngle' + i" class="pw-label control-label col-sm-4"> Angle </label>
                <div class="col-sm-8" sobReactiveSimVarInput>
                  <div class="row">
                    <div class="col-xs-6">
                      <sob-reactive-unit-select formControlName="unit" [quantity]="segment.segmentAngle.quantity">
                      </sob-reactive-unit-select>
                    </div>
                    <div class="col-xs-6">
                      <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="segment.segmentAngle">
                      </sob-reactive-variable-value-input>
                      <span class="text-danger" *ngIf="getValueFieldsErrorValidations(segment.id, 'segmentAngle')">
                        Please enter a value
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" formGroupName="relativeRoughness">
                <label [attr.for]="'relativeRoughness' + i" class="pw-label control-label col-sm-4">
                  Relative Roughness
                </label>
                <div class="col-sm-8" sobReactiveSimVarInput>
                  <div class="row">
                    <div class="col-xs-6">
                      <sob-reactive-unit-select formControlName="unit" [quantity]="segment.relativeRoughness.quantity">
                      </sob-reactive-unit-select>
                    </div>
                    <div class="col-xs-6">
                      <sob-reactive-variable-value-input
                        formControlName="value"
                        [valueMetaData]="segment.relativeRoughness">
                      </sob-reactive-variable-value-input>
                      <span class="text-danger" *ngIf="getValueFieldsErrorValidations(segment.id, 'relativeRoughness')">
                        Please enter a value
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="unitOperation.thermalCalculationMode === 'Thermal'">
                <div class="form-group" formGroupName="overallU">
                  <label [attr.for]="'overallU' + i" class="pw-label control-label col-sm-4"> Overall U </label>
                  <div class="col-sm-8" sobReactiveSimVarInput>
                    <div class="row">
                      <div class="col-xs-6">
                        <sob-reactive-unit-select formControlName="unit" [quantity]="segment.overallU.quantity">
                        </sob-reactive-unit-select>
                      </div>
                      <div class="col-xs-6">
                        <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="segment.overallU">
                        </sob-reactive-variable-value-input>
                        <span class="text-danger" *ngIf="getValueFieldsErrorValidations(segment.id, 'overallU')">
                          Please enter a value
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group" formGroupName="inletAmbientTemperature">
                  <label [attr.for]="'inletAmbientTemperature' + i" class="pw-label control-label col-sm-4">
                    Inlet Ambient Temperature
                  </label>
                  <div class="col-sm-8" sobReactiveSimVarInput>
                    <div class="row">
                      <div class="col-xs-6">
                        <sob-reactive-unit-select
                          formControlName="unit"
                          [quantity]="segment.inletAmbientTemperature.quantity">
                        </sob-reactive-unit-select>
                      </div>
                      <div class="col-xs-6">
                        <sob-reactive-variable-value-input
                          formControlName="value"
                          [valueMetaData]="segment.inletAmbientTemperature">
                        </sob-reactive-variable-value-input>
                        <span
                          class="text-danger"
                          *ngIf="getValueFieldsErrorValidations(segment.id, 'inletAmbientTemperature')">
                          Please enter a value
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group" formGroupName="outletAmbientTemperature">
                  <label [attr.for]="'outletAmbientTemperature' + i" class="pw-label control-label col-sm-4">
                    Outlet Ambient Temperature
                  </label>
                  <div class="col-sm-8" sobReactiveSimVarInput>
                    <div class="row">
                      <div class="col-xs-6">
                        <sob-reactive-unit-select
                          formControlName="unit"
                          [quantity]="segment.outletAmbientTemperature.quantity">
                        </sob-reactive-unit-select>
                      </div>
                      <div class="col-xs-6">
                        <sob-reactive-variable-value-input
                          formControlName="value"
                          [valueMetaData]="segment.outletAmbientTemperature">
                        </sob-reactive-variable-value-input>
                        <span
                          class="text-danger"
                          *ngIf="getValueFieldsErrorValidations(segment.id, 'outletAmbientTemperature')">
                          Please enter a value
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="waterPipeComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
