import { SimulationVariable } from '../simulation-variable';

export class KpiResult {
  kpi: SimulationVariable;
  cumulativeProbabilities: Array<number> = [];
  values: Array<number> = [];
  p10Value: number;
  p90Value: number;

  constructor(kpiResult: any) {
    if (kpiResult.kpi instanceof SimulationVariable) {
      this.kpi = kpiResult.kpi;
      this.cumulativeProbabilities = Array.from(kpiResult.cumulativeProbabilities);
      this.values = Array.from(kpiResult.values);
      this.p90Value = kpiResult.p90Value;
      this.p10Value = kpiResult.p10Value;
    } else {
      this.kpi = kpiResult.kpi;
      this.cumulativeProbabilities = Array.from(kpiResult.cumulativeProbabilities);
      this.values = Array.from(kpiResult.values);
      this.p90Value = kpiResult.p90Value;
      this.p10Value = kpiResult.p10Value;
    }
  }

  public toJSON() {
    return {
      kpi: this.kpi.id,
      cumulativeProbabilities: this.cumulativeProbabilities,
      values: this.values,
      p90Value: this.p90Value,
      p10Value: this.p10Value,
    };
  }
}
