import { BaseObject } from '../base-object';

export class CaseStudyCategory {
  categoryName: string;
  ownerBaseObjectId: string;

  // boolean callback, used for getting the base objects for this category,
  // if filterCallback(bo) is true then bo will be considered part of this category
  filterCallback: (bo: BaseObject) => boolean;

  constructor(categoryName: string, callback: (bo: BaseObject) => boolean) {
    this.categoryName = categoryName;
    this.filterCallback = callback;
  }
}
