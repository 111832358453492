import * as wjcCore from '@grapecity/wijmo';
import { FlexSheetCellConfig } from './flex-sheet-cell-config';

export enum RefinerySheetRows {
  NAME = 0,
  LIGHT_ENDS_MARGIN = 3,
  NAPHTHA_MARGIN = 4,
  DISTILLATE_MARGIN = 5,
  GAS_OIL_MARGIN = 6,
  VACUUM_RESIDUE_MARGIN = 7,
  CAPACITY = 8,
  CAPEX_AMORTIZED = 11,
  OPEX_FIXED = 12,
  OPEX_VAR = 13,
  OPEX_GAS_DIESEL = 14,
  OPEX_POWER = 15,
}

export const RefineryCellConfig: FlexSheetCellConfig[] = [
  {
    row: RefinerySheetRows.NAME,
    dataType: wjcCore.DataType.String,
    format: '',
  },
  {
    row: RefinerySheetRows.LIGHT_ENDS_MARGIN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: RefinerySheetRows.NAPHTHA_MARGIN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: RefinerySheetRows.DISTILLATE_MARGIN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: RefinerySheetRows.GAS_OIL_MARGIN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: RefinerySheetRows.VACUUM_RESIDUE_MARGIN,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: RefinerySheetRows.CAPACITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: RefinerySheetRows.CAPEX_AMORTIZED,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: RefinerySheetRows.OPEX_FIXED,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: RefinerySheetRows.OPEX_VAR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: RefinerySheetRows.OPEX_GAS_DIESEL,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: RefinerySheetRows.OPEX_POWER,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
];
