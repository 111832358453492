import { BaseObject } from '../base-object';
import { getColor } from '../color.generator';

export abstract class BaseStream extends BaseObject {
  // gojs data, should it be in a separated object?
  key: number;
  streamColor: string;

  inletUnitOperationId: string;
  outletUnitOperationId: string;
  fromPort: string;
  toPort: string;

  isRecycleStream: boolean;

  abstract setQuantities();
  abstract clearResults();
  abstract setSimulationVariableNames();
  abstract setDefaultValues();

  initValues(baseStream: BaseStream | any) {
    this.key = baseStream.key;
    this.name = baseStream.name;
    this.streamColor = getColor(baseStream.color);
    this.fromPort = baseStream.fromPort;
    this.toPort = baseStream.toPort;
    this.inletUnitOperationId = baseStream.inletUnitOperationId;
    this.outletUnitOperationId = baseStream.outletUnitOperationId;
    this.isRecycleStream = !!baseStream.isRecycleStream;
  }

  get inletUnitOperation() {
    return this.ownerCase.getUnitOperation(this.inletUnitOperationId);
  }

  get outletUnitOperation() {
    return this.ownerCase.getUnitOperation(this.outletUnitOperationId);
  }

  get connectionName() {
    return `${this.inletUnitOperation.name} - ${this.outletUnitOperation.name}`;
  }
}
