import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import { CoreService } from '../../../_services/core.service';
import { SimulationVariable } from '../../../_models';
import { KeyValueObject } from '../../../_models/key-value-object';
import { NumberToUnitConverter } from '../../../_services/number-to-unit-converter.service';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { hasNumericValue, isTypeNumber } from '../../../_utils/utils';
import { ProductTank } from '../../../_models/product-tank';
import { MultiPeriodSettings } from '../../../_models/_multi-period/multi-period-settings';

declare let unitConverter: any;

@Component({
  selector: 'sob-multi-period-results',
  templateUrl: './multi-period-results.component.html',
  styleUrls: ['./multi-period-results.component.css'],
})
export class MultiPeriodResultsComponent {
  selectedKpis: SimulationVariable[] = [];
  selectedKpiId: string;
  kpiResults: number[] = [];
  multiPeriodReportData: string[][] = [];
  headers: string[] = [];
  selectedKpiOwner: ProductTank;

  constructor(
    private coreService: CoreService,
    private nuc: NumberToUnitConverter,
    private flowsheetService: FlowsheetService
  ) {
    // TODO Analyze if this should only be fired when case is solved
    // TODO consider subscribing to currentCaseReplacedRequest
    this.coreService.caseChangedRequest.subscribe((data: any) => {
      if (data && data.solveSuccess) {
        if (this.coreService.currentCase.multiPeriodEnabled) {
          this.show();
          this.generateMultiPeriodReportArray();
        }
      }
    });

    this.coreService.currentCaseReplacedRequest.subscribe(() => {
      if (
        this.coreService.currentCase.multiPeriodEnabled &&
        this.coreService.currentCase.multiPeriodSettings.multiPeriodResults
      ) {
        this.selectedKpis = this.noNullSelectedKpis();
        this.selectedKpiId = this.selectedKpis.length ? this.selectedKpis[0].id : '';
        this.kpiChanged();
        this.generateMultiPeriodReportArray();
      }
    });
  }

  noNullSelectedKpis() {
    return this.coreService.currentCase.caseStudyManager.kpiManager.selectedKpis.filter(kpi => {
      return this.findKpiResultsByKpiId(kpi.id);
    });
  }

  private show() {
    $('#multiPeriodResultsModal').modal('show');
    this.selectedKpis = this.noNullSelectedKpis();
    this.selectedKpiId = this.selectedKpis.length ? this.selectedKpis[0].id : '';
    this.kpiChanged();
  }

  kpiChanged() {
    this.kpiResults = this.getKpiResults();
    const selectedKpi = this.selectedKpis.find(kpi => kpi.id === this.selectedKpiId);
    const kpiOwnerBaseObject = selectedKpi.ownerBaseObject;

    if (kpiOwnerBaseObject instanceof ProductTank) {
      this.selectedKpiOwner = kpiOwnerBaseObject;
      this.buildChart(selectedKpi, this.kpiResults, this.selectedKpiOwner.maxVolume.value);
    } else {
      this.buildChart(selectedKpi, this.kpiResults);
    }

    this.buildTable(selectedKpi, this.kpiResults);
  }

  getKpiResults(): number[] {
    const selectedKpi = this.selectedKpis.find(kpi => kpi.id === this.selectedKpiId);
    return Object.entries(this.multiPeriodResults).map(entry => {
      return isNaN(entry[1][this.selectedKpiId])
        ? undefined
        : this.nuc.convertToDefaultUnit(entry[1][this.selectedKpiId], selectedKpi.quantity);
    });
  }

  findKpiResultsByKpiId(kpiId: string) {
    return Object.entries(this.multiPeriodResults).find(
      entry => isTypeNumber(entry[1][kpiId]) && entry[1][kpiId] !== 0
    );
  }

  buildChart(kpi: SimulationVariable, chartData: number[], tankCapacity?: number) {
    Highcharts.chart({
      chart: {
        renderTo: 'multiPeriodResultsChart',
        type: 'column',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        title: {
          text: 'Days',
        },
        categories: this.getPeriods(chartData),
      },
      yAxis: {
        title: {
          text: `${kpi.getFullName()} (${kpi.getDefaultUnit()})`,
        },
        plotLines: [
          {
            color: this.setPlotLineColor(chartData, tankCapacity),
            width: 5,
            value: tankCapacity,
            dashStyle: 'solid',
            zIndex: 10,
            label: {
              text: '<b>Capacity</b>',
              align: 'right',
              style: { color: this.setPlotLineColor(chartData, tankCapacity) },
              x: -10,
              y: -7,
            },
          },
        ],
        max: this.calculateYAxisMaxValue(chartData, tankCapacity),
      },
      tooltip: {
        headerFormat: '<table>',
        pointFormatter() {
          return (
            `<tr><td>Day ${this.index + 1}</td></tr>` +
            `<tr><td style="color:${this.color}; padding:0;">${kpi.getFullName()}: </td>` +
            `<td style="padding-left:2px"><b>${Highcharts.numberFormat(this.y, 2, '.', ',')} ${kpi.getDefaultUnit()}` +
            '</b></td></tr>'
          );
        },
        footerFormat: '<tr></table>',
        shared: true,
        useHTML: true,
        followPointer: true,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
            color: 'white',
          },
        },
      },
      series: [
        {
          data: chartData,
          color: '#1034a6',
        },
      ],
    });
  }

  buildTable(kpi: SimulationVariable, data: number[]) {
    let tableHtml = '';

    for (let i = 0; i < data.length; i++) {
      let dataRounded;
      !data[i] ? (dataRounded = '') : (dataRounded = data[i].toFixed(2));
      tableHtml +=
        `<tr> <td style="padding-right: 4%" >${i + 1}</td>` +
        `<td>${this.format(dataRounded)} ${kpi.getDefaultUnit()}</td></tr>`;
    }

    const $table = $('#multiPeriodResultsTable');
    $table
      .find('thead')
      .html(
        `<th class="text-center" style="padding-right: 3.5%">${'Period'}</th>` +
          `<th class="text-center">${kpi.getFullName()}</th>`
      );

    // TODO this is a no-no
    $table.find('tbody').html(tableHtml);
  }

  generateMultiPeriodReportArray() {
    this.headers = [];
    const multiPerdiod = this.coreService.currentCase.multiPeriodSettings;

    for (let i = 0; i < multiPerdiod.numberOfSteps; i++) {
      this.multiPeriodReportData[i] = [(i + 1).toString()];
    }
    this.generateMultiperiodParametersDataArray(multiPerdiod);
    this.generateMultiperiodResultsDataArray(multiPerdiod);
  }

  generateMultiperiodParametersDataArray(m: MultiPeriodSettings) {
    for (let i = 0; i < m.multiPeriodParameters.length; i++) {
      const parameter = m.multiPeriodParameters[i];
      const simVar = parameter.simulationVariable;
      this.headers.push(`${parameter.getFullName()} (${parameter.getDefaultUnit()})`);

      for (let j = 0; j < parameter.multiPeriodValues.length; j++) {
        const parameterValue = parameter.multiPeriodValues[j];
        const conversion = unitConverter(simVar.quantity).convert(
          parameterValue,
          simVar.getInternalUnit(),
          simVar.getDefaultUnit()
        );
        const roundedValue = conversion.round();
        this.multiPeriodReportData[j][i + 1] = roundedValue.getValue().toLocaleString();
      }
    }
  }

  generateMultiperiodResultsDataArray(m: MultiPeriodSettings) {
    const selectedKpis = this.noNullSelectedKpis();
    const index = this.multiPeriodReportData[0].length;

    for (let i = 0; i < selectedKpis.length; i++) {
      const kpi = selectedKpis[i];
      this.headers.push(`${kpi.getFullName()} (${kpi.getDefaultUnit()})`);
      const kpiResults = m.multiPeriodResults.map(arrayValues => {
        return arrayValues[kpi.id];
      });

      for (let j = 0; j < kpiResults.length; j++) {
        const kpiValue = kpiResults[j];
        const conversion = unitConverter(kpi.quantity).convert(kpiValue, kpi.getInternalUnit(), kpi.getDefaultUnit());
        const roundedValue = conversion.round();
        this.multiPeriodReportData[j][index + i] = roundedValue.getValue().toLocaleString();
      }
    }
  }

  format = number => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    return number.toString().replace(exp, rep);
  };

  getPeriods(charData) {
    return charData.map((quantity, index) => {
      return index + 1;
    });
  }

  get multiPeriodResults(): KeyValueObject<number>[] {
    return this.coreService.currentCase.multiPeriodSettings.multiPeriodResults;
  }

  excelReport() {
    this.flowsheetService.closePropertyWindow();
    $('#multiperiodExcelReportModal').modal('show');
  }

  calculateYAxisMaxValue(kpiResults: number[], tankCapacity: number) {
    const maxKpiResult = this.findMaxKpiResult(kpiResults);
    return maxKpiResult < tankCapacity ? tankCapacity * 0.1 + tankCapacity : maxKpiResult * 0.1 + maxKpiResult;
  }

  findMaxKpiResult(kpiResults: number[]) {
    let maxKpiResult = 0;
    kpiResults.forEach((kpi, index) => {
      if (hasNumericValue(kpiResults[index + 1])) {
        maxKpiResult = Math.max(kpi, kpiResults[index + 1]);
      }
    });

    return maxKpiResult;
  }

  setPlotLineColor(kpiResults: number[], tankCapacity: number) {
    if (this.findMaxKpiResult(kpiResults) > tankCapacity) {
      return '#ad0012';
    }
    return 'green';
  }
}
