/*
 * Inspinia js helpers:
 *
 * correctHeight() - fix the height of main wrapper
 * detectBody() - detect windows size
 * smoothlyMenu() - add smooth fade in/out on navigation show/ide
 *
 */
export function smoothlyMenu() {
  const $body = $('body');
  if (!$body.hasClass('mini-navbar') || $body.hasClass('body-small')) {
    // Hide menu in order to smoothly turn on when maximize menu
    $('#side-menu').hide();
    // For smoothly turn on menu
    setTimeout(function () {
      $('#side-menu').fadeIn(400);
    }, 200);
  } else if ($('body').hasClass('fixed-sidebar')) {
    $('#side-menu').hide();
    setTimeout(function () {
      $('#side-menu').fadeIn(400);
    }, 100);
  } else {
    // Remove all inline style from $ fadeIn function to reset menu state
    $('#side-menu').removeAttr('style');
  }
}

export function flowsheetHeight() {
  const windowHeight = $(window).height();
  const topNavBarHeight = $('nav.navbar-static-top').outerHeight();

  const subFlowsheetInfoBar = $('#sub-flowsheet-info').is(':visible');
  const subFlowsheetInfoHeight = subFlowsheetInfoBar ? $('.layer-info-container').outerHeight() : 0;

  const bottomBarHeight = $('#lower-toolbar').outerHeight();
  const pageWrapper = $('#page-wrapper');
  const flowsheetDiagramWrapper = $('#flowsheet-diagram-wrapper');
  const sankeyDiagramWrapper = $('#sankey-diagram-wrapper');

  const diagramMinHeight = 300;
  const minHeight = diagramMinHeight + topNavBarHeight;
  const pageWrapperHeight =
    windowHeight < minHeight ? minHeight : windowHeight - topNavBarHeight - subFlowsheetInfoHeight;
  pageWrapper.css('min-height', pageWrapperHeight - 1);

  let flowsheetWrapperHeight = pageWrapperHeight - bottomBarHeight;

  if (flowsheetWrapperHeight < diagramMinHeight) {
    flowsheetWrapperHeight = diagramMinHeight;
  }

  flowsheetDiagramWrapper.css('height', flowsheetWrapperHeight - 1);
  $('#flowsheet-spreadsheet-wrapper').css('height', flowsheetWrapperHeight - 1);
  $('#utilities-spreadsheet-wrapper').css('height', flowsheetWrapperHeight - 1);
  $('#constraints-spreadsheet-wrapper').css('height', flowsheetWrapperHeight - 1);
  sankeyDiagramWrapper.css('height', flowsheetWrapperHeight - 1);
  $('#multi-period-settings-wrapper').css('height', flowsheetWrapperHeight - 1);
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

export function detectBody() {
  if ($(document).width() < 769) {
    $('body').addClass('body-small');
  } else {
    $('body').removeClass('body-small');
  }
}
