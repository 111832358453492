import { AfterViewInit, Component } from '@angular/core';

import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import * as wjcCore from '@grapecity/wijmo';

import { CoreService } from '../../_services/core.service';
import { FlexSheetSpreadsheetService } from '../../_services/flowsheet-spreadsheet/flex-sheet-spreadsheet.service';
import { environment } from '../../../environments/environment';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { ConstraintsSpreadsheetService } from '../../_services/constraints-spreadsheet/constraints-spreadsheet.service';
import { UtilitiesSpreadsheetService } from '../../_services/utilities-spreadsheet/utilities-spreadsheet.service';
import { H2sSheetGroupHandler } from '../../_services/utilities-spreadsheet/h2s/h2s-sheet-group-handler.service';
import { FlowsheetTreeService } from '../../_services/sub-flowsheet/flowsheet-tree/flowsheet-tree.service';
import { Upgrader } from '../../_models/_unit-operations/upgrader';
import { WaterUtilityUnitOperation } from '../../_models/_unit-operations/utilties/water-utility-unit-operation';
import { WaterUnitOperation } from '../../_models/_unit-operations/utilties/water-unit-operation';
import { UnitOperation } from '../../_models';
import { SpreadsheetTitle } from '../../_config/spreadsheet/spreadsheet-title.enum';
import { isEmptyValue } from '../../_utils/utils';
import { Mixer } from '../../_models/_unit-operations/mixer';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { Splitter } from '../../_models/_unit-operations/splitter';

@Component({
  selector: 'sob-flowsheet-spreadsheet',
  templateUrl: './flowsheet-spreadsheet.component.html',
  styleUrls: ['./flowsheet-spreadsheet.component.css'],
})
export class FlowsheetSpreadsheetComponent implements AfterViewInit {
  flexSheet: wjcSheet.FlexSheet;
  constructor(
    private coreService: CoreService,
    private flexSheetService: FlexSheetSpreadsheetService,
    private spreadsheetService: ConstraintsSpreadsheetService,
    private utilitiesSpreadsheetV2Service: UtilitiesSpreadsheetService,
    private h2sSheetGroupHandler: H2sSheetGroupHandler,
    private flowsheetTree: FlowsheetTreeService
  ) {
    this.updateSpreadsheetWhenCaseReplaced();
    this.updateSpreadsheetWhenUnitOpAdded();
    this.updateSpreadsheetWhenUnitOpUpdated();
    this.updateSpreadsheetWhenUnitOpRemoved();

    this.coreService.assayAddedRequest.subscribe(assay => {
      this.flexSheetService.addAssay(assay);
    });

    this.coreService.assayUpdatedRequest.subscribe(data => {
      this.flexSheetService.updateAssay(data.assay);
    });

    this.coreService.assayRemovedRequest.subscribe(assay => {
      this.flexSheetService.removeAssay(assay);
    });

    if (environment.production) {
      wjcCore.setLicenseKey(
        // eslint-disable-next-line max-len
        'Process Ecology Inc,test.suncor-optea.com|suncor-optea.com|www.suncor-optea.com,492851145592756#B06dJEDMyIiOiIXZ6JCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBVzU5FDULdkQ8cHe5Vkarc4aaJnR9p6KU36RaxWd0FkcIRkTVp7dVZWeUllMwY7SQJ4YJdzU9ATdZNFOI56LHVWREdTULZzN6Nlath7dLRzMJBTNrZHb8AjYXpVSX3mT5UTdnF5KUN6c5gGNYhFV4RmWqpWb4gkZGJHWjh6KYFEOhtWT7NEbJpkd03SRaVzVodGS6JUOoFjUzNnVr4EMUlVQrQDOwkVTQRGaOhkSVRWYix6RXFVMBVVSxUTMrtENGF7NShWUXdTVKtiYOtUdiJjTkVGMm9kZ4JkNwZVVwxUcJVXVypncVlEUklWWzona9dVQGVGVPVFN5pXTOFlbPdlRxNGbvETe986MuJ6TFJVY7Z4c0NmcHdmN584Ymh6dSh6QwlEd9tmVpFURwNTVKVWSqpGTCtGbvBlbz9UaxJ6SulneRtyZrFUNXJFVQ5GalZEZol4TiojITJCLiM4M7QEMyUTMiojIIJCLzEDNyczNwkTN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiITN4IDOwASNyATM9EDMyIiOiQncDJCLi46bj9SYlRHcv5icvNmb5NnL7d7ds46bj9SYlRHcv5icvNmb5NHLt36YuEWZ4B7btI7bj9Wdz9CdzVGdiojIz5GRiwiIj9WSgk7Zvx6bjVEIzNXZj3mcQJiOiEmTDJCLiYTN7ITO5UDNxETN8ITO4IiOiQWSiwIfsM'
      );
    }
  }

  ngAfterViewInit() {
    // yeah, let's hide the annoying grapecity div
    // only in dev :^)
    if (!environment.production) {
      // nice try, with setTimeout the licensing div is successfully removed
      setTimeout(() => {
        $('a[href="https://www.grapecity.com/licensing/wijmo"]').parent().remove();
      }, 500);
    }
  }

  initializeFlexSheet(flex: wjcSheet.FlexSheet) {
    this.flexSheet = flex;
    this.flexSheetService.init(flex);
    this.spreadsheetService.init(flex);
  }

  initializeH2sSheets() {
    const upgOwners = this.flowsheetTree.getUpgradersWithSubFlowsheet(this.currentCase);
    if (upgOwners.length !== 0) {
      this.h2sSheetGroupHandler.init(this.flexSheet, upgOwners);
    }
  }

  initializeUtilitiesSheets(sheetType: SpreadsheetTitle) {
    const upgsOwners = this.flowsheetTree.getUpgradersWithSubFlowsheet(this.currentCase);
    if (upgsOwners.length !== 0) {
      this.utilitiesSpreadsheetV2Service.init(this.flexSheet, upgsOwners, sheetType);
    }
  }

  updateSpreadsheetWhenCaseReplaced() {
    this.coreService.currentCaseReplacedRequest.subscribe(() => {
      this.initializeUtilitiesSheets(SpreadsheetTitle.HYDROGEN);
      this.initializeH2sSheets();
      this.initializeUtilitiesSheets(SpreadsheetTitle.STEAM_REPORT);
      this.spreadsheetService.caseToSpreadsheet(this.coreService.currentCase);
    });
  }

  updateSpreadsheetWhenUnitOpAdded() {
    this.coreService.unitOperationAddedRequest.subscribe(uo => {
      const upgraderOwner = this.flowsheetTree.getUpgradersWithSubFlowsheet(this.currentCase, uo.flowsheetId)[0];

      this.flexSheetService.addUnitOperation(uo);
      this.spreadsheetService.addUnitOperation(uo as SuncorUnitOperation);
      this.updateUtilitiesSheetsWhenUnitOpAdded(uo);

      if (!isEmptyValue(uo.flowsheetId) && upgraderOwner) {
        this.h2sSheetGroupHandler.addUnitOperation(uo);
        this.initializeH2sSheets();
        this.h2sSheetGroupHandler.clearResults();
      }

      this.flexSheetService.clearResults();
      this.spreadsheetService.clearResults();
    });
  }

  updateSpreadsheetWhenUnitOpUpdated() {
    this.coreService.unitOperationUpdatedRequest.subscribe(data => {
      const upgraderOwner = this.flowsheetTree.getUpgradersWithSubFlowsheet(
        this.currentCase,
        data.unitOperation.flowsheetId
      )[0];

      this.flexSheetService.updateUnitOperation(data.unitOperation as SuncorUnitOperation);
      this.spreadsheetService.updateConstraintValue(data.unitOperation as SuncorUnitOperation);

      if (data.unitOperation instanceof Upgrader) {
        const childUnitOperations = this.coreService.currentCase.filterUnitOperations(
          uo => uo.flowsheetId === data.unitOperation.id
        );
        if (childUnitOperations.length === 0) {
          this.initializeUtilitiesSheets(SpreadsheetTitle.HYDROGEN);
          this.initializeH2sSheets();
          this.initializeUtilitiesSheets(SpreadsheetTitle.STEAM_REPORT);
        }
      }
      this.updateUtilitiesSheetsWhenUnitOpUpdated(data.unitOperation);
      if (!isEmptyValue(data.unitOperation.flowsheetId) && upgraderOwner) {
        this.h2sSheetGroupHandler.updateUnitOperation(data.unitOperation);
        this.h2sSheetGroupHandler.clearResults();
      }
      this.flexSheetService.clearResults();
      this.spreadsheetService.clearResults();
    });
  }

  updateSpreadsheetWhenUnitOpRemoved() {
    this.coreService.unitOperationRemovedRequest.subscribe(uo => {
      const upgraderOwner = this.flowsheetTree.getUpgradersWithSubFlowsheet(this.currentCase, uo.flowsheetId)[0];

      this.flexSheetService.removeUnitOperation(uo as SuncorUnitOperation);
      this.spreadsheetService.removeUnitOperation(uo as SuncorUnitOperation);
      this.updateUtilitiesSheetsWhenUnitOpRemoved(uo);

      if (!isEmptyValue(uo.flowsheetId) && upgraderOwner) {
        this.h2sSheetGroupHandler.removeUnitOperation(uo);
        this.initializeH2sSheets();
        this.h2sSheetGroupHandler.clearResults();
      }
      if (uo instanceof Upgrader && uo.isFlowsheetOwner) {
        this.utilitiesSpreadsheetV2Service.removeUtilitiesSheetsByUpgOwner(uo, true);
        this.h2sSheetGroupHandler.removeH2sSheetsByUpgOwner(uo);
      }

      this.flexSheetService.clearResults();
      this.spreadsheetService.clearResults();
    });
  }

  updateUtilitiesSheetsWhenUnitOpAdded(uo: UnitOperation) {
    const upgraderOwner = this.flowsheetTree.getUpgradersWithSubFlowsheet(this.currentCase, uo.flowsheetId)[0];

    if (uo instanceof WaterUnitOperation) {
      const steamAndPowerOwner = this.getSteamAndPowerSubFlowsheet(uo);
      const steamAndPowerUpgraderOwner = this.flowsheetTree.getUpgradersWithSubFlowsheet(
        this.currentCase,
        steamAndPowerOwner[0].flowsheetId
      )[0];

      this.utilitiesSpreadsheetV2Service.addUnitOperation(
        uo as SuncorUnitOperation,
        steamAndPowerUpgraderOwner,
        SpreadsheetTitle.STEAM_REPORT
      );
      this.utilitiesSpreadsheetV2Service.clearResults();
    } else if (uo instanceof WaterUtilityUnitOperation) {
      this.initializeUtilitiesSheets(SpreadsheetTitle.HYDROGEN);
      this.initializeUtilitiesSheets(SpreadsheetTitle.STEAM_REPORT);
    } else if (!isEmptyValue(uo.flowsheetId) && upgraderOwner) {
      this.utilitiesSpreadsheetV2Service.addUnitOperation(uo as SuncorUnitOperation, upgraderOwner);
      this.utilitiesSpreadsheetV2Service.clearResults();
    }
  }

  updateUtilitiesSheetsWhenUnitOpUpdated(unitOperation: UnitOperation) {
    const upgraderOwner = this.flowsheetTree.getUpgradersWithSubFlowsheet(
      this.currentCase,
      unitOperation.flowsheetId
    )[0];

    if (unitOperation instanceof WaterUnitOperation) {
      const steamAndPowerOwner = this.getSteamAndPowerSubFlowsheet(unitOperation);
      const steamAndPowerUpgraderOwner = this.flowsheetTree.getUpgradersWithSubFlowsheet(
        this.currentCase,
        steamAndPowerOwner[0].flowsheetId
      )[0];

      this.utilitiesSpreadsheetV2Service.updateUnitOperation(
        unitOperation as SuncorUnitOperation,
        steamAndPowerUpgraderOwner,
        SpreadsheetTitle.STEAM_REPORT
      );
      this.utilitiesSpreadsheetV2Service.clearResults();
    } else if (!isEmptyValue(unitOperation.flowsheetId) && upgraderOwner) {
      this.utilitiesSpreadsheetV2Service.updateUnitOperation(unitOperation as SuncorUnitOperation, upgraderOwner);
      this.utilitiesSpreadsheetV2Service.clearResults();
    }
  }

  updateUtilitiesSheetsWhenUnitOpRemoved(uo: UnitOperation) {
    const upgraderOwner = this.flowsheetTree.getUpgradersWithSubFlowsheet(this.currentCase, uo.flowsheetId)[0];

    if (uo instanceof WaterUnitOperation) {
      const steamAndPowerOwner = this.getSteamAndPowerSubFlowsheet(uo);
      const steamAndPowerUpgraderOwner = this.flowsheetTree.getUpgradersWithSubFlowsheet(
        this.currentCase,
        steamAndPowerOwner[0].flowsheetId
      )[0];

      this.utilitiesSpreadsheetV2Service.removeUnitOperation(
        uo as SuncorUnitOperation,
        steamAndPowerUpgraderOwner,
        SpreadsheetTitle.STEAM_REPORT
      );
      this.utilitiesSpreadsheetV2Service.clearResults();
    } else if (uo instanceof WaterUtilityUnitOperation) {
      this.utilitiesSpreadsheetV2Service.removeUtilitiesSheetsByUpgOwner(upgraderOwner, false);
    } else if (!isEmptyValue(uo.flowsheetId) && upgraderOwner) {
      this.utilitiesSpreadsheetV2Service.removeUnitOperation(uo as SuncorUnitOperation, upgraderOwner);
      this.utilitiesSpreadsheetV2Service.clearResults();
    }
  }

  get currentCase() {
    return this.coreService.currentCase;
  }

  getSteamAndPowerSubFlowsheet(uo: UnitOperation) {
    return this.flowsheetTree.getAllFlowsheetOwners(this.currentCase).filter(uOpOwner => {
      return uOpOwner instanceof WaterUtilityUnitOperation && uOpOwner.id === uo.flowsheetId;
    });
  }
}
