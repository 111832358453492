import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import { FlexSheetValueConverter } from '../flex-sheet-value-converter';
import { Hydrotreater } from '../../../_models/_unit-operations/hydrotreater';
import { Hydrocracker } from '../../../_models/_unit-operations/hydrocracker';
import { enumValuesToArray } from '../../../_utils/enum-values-to-array';
import { UtilitiesCellConfig, UtilitiesSheetColumns } from '../cell-config/utilities-cell-config.enum';
import { UtilitiesFlexSheetMapper } from '../mappers/utilities-flex-sheet-mapper';
import { NumberToSimulationVariableMapper } from '../mappers/number-to-simulation-variable-mapper';

export class UtilitiesFlexSheetEventHandler {
  constructor(private valueConverter: FlexSheetValueConverter) {}

  handle(unitOperation: Hydrotreater | Hydrocracker, spreadsheet: wjcSheet.FlexSheet, flexSheetEvent, rawValue) {
    const column = flexSheetEvent.col;
    if (
      enumValuesToArray(UtilitiesSheetColumns).indexOf(column) === -1 ||
      column === UtilitiesSheetColumns.HYDROGEN_USE
    ) {
      flexSheetEvent.cancel = true;
      return;
    }
    const cellConfig = UtilitiesCellConfig.find(rowConfig => rowConfig.row === column);
    const convertedValue = this.valueConverter.convert(spreadsheet, rawValue, cellConfig);
    const simulationVariableMapper = new NumberToSimulationVariableMapper();
    const mapper = new UtilitiesFlexSheetMapper(simulationVariableMapper);
    mapper.map(unitOperation, column, convertedValue);
  }
}
