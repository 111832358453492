<div id="sendCaseModal" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          An internal error happened while solving your case
        </h4>
      </div>

      <div class="modal-body">
        <p id="sendCaseToPEMessage">
          An internal error happened in the calculation engine. Please send the case to Process Ecology
        </p>
        <p class="text-center" id="sendCaseToPELoading" style="display: none">
          <i class="ace-icon fa fa-spinner fa-spin orange" style="font-size: 20px"></i>
          Sending case to Process Ecology...
        </p>
        <div id="sendCaseError" class="alert alert-danger" style="display: none">
          An error occurred while sending the case.
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Ignore</button>
        <button type="button" class="btn btn-primary" (click)="sendCase()">Send</button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
