<ul class="nav nav-tabs" role="tablist">
  <li role="presentation" class="active">
    <a href="#mixerGeneral" aria-controls="mixerGeneral" role="tab" data-toggle="tab">General</a>
  </li>

  <li role="presentation">
    <a href="#mixerConstraints" aria-controls="mixerConstraints" role="tab" data-toggle="tab">Constraints</a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="mixerGeneral">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Inlet Streams</h4>

        <ul *ngFor="let inletStream of inletStreams" class="inletStreamsList">
          <li>From {{ inletStream.inletUnitOperation.name }}</li>
        </ul>

        <div *ngIf="!inletStreams.length" class="outletStreamName">No streams available</div>

        <h4 class="subtitle" style="margin-top: 20px">Outlet Stream</h4>
        <div *ngIf="outletStream" class="outletStreamName">To {{ outletStream.outletUnitOperation.name }}</div>
        <div *ngIf="!outletStream" class="outletStreamName">No stream available</div>
      </div>

      <ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
        <li role="presentation" class="active">
          <a href="#upgMixerComments" aria-controls="upgMixerComments" role="tab" data-toggle="tab">
            <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
            Comments
          </a>
        </li>
      </ul>
      <div class="tab-content pw-margin tab-content-padding">
        <div role="tabpanel" class="tab-pane fade in active" id="upgMixerComments">
          <div class="tab-content no-padding no-border">
            <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="mixerConstraints" [formGroup]="formGroup">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label for="mixerConstraintOption" class="pw-label control-label col-sm-4">Constraint Option</label>
          <div class="col-sm-6">
            <select class="form-control" id="mixerConstraintOption" formControlName="constraintOption">
              <option value="{{ MixerConstraintOption.NONE }}">None</option>
              <option value="{{ MixerConstraintOption.BLEND_RATIO }}">Blend Ratio</option>
            </select>
          </div>
        </div>

        <ng-container *ngIf="isBlendRatioConstraintSelected()">
          <div class="form-group">
            <label for="mixerBlendRecipeOption" class="pw-label control-label col-sm-4">Blend Recipe Option</label>
            <div class="col-sm-6">
              <select class="form-control" id="mixerBlendRecipeOption" formControlName="blendRecipeOption">
                <option value="{{ MixerBlendRecipeOption.EXACT }}">Exact</option>
                <option value="{{ MixerBlendRecipeOption.RANGE }}">Range</option>
              </select>
            </div>
          </div>

          <sob-reactive-range-distribution-ratio-variables
            [valueControlsReadonly]="mixingRatioValueControlsReadonly()"
            [ratioVariables]="unitOperation.mixingRatioVariables"
            [ratioVariablesFormGroup]="
              rangeDistributionVariablesFormGroup
            "></sob-reactive-range-distribution-ratio-variables>
        </ng-container>
      </div>
    </div>
  </div>
</div>
