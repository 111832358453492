<div
  class="modal fade"
  id="codeEditorModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="codeEditorModal"
  data-keyboard="false"
  data-backdrop="static">
  <input id="codeEditorInstanceId" type="hidden" value="" />
  >
  <div class="modal-lg modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Code Editor</h4>
      </div>
      <div class="modal-body">
        <div id="code-editor"></div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-primary" (click)="saveAndClose()">Save</button>
        <button class="btn btn-success" (click)="openRestoreDefaultCodeModal()" *ngIf="defaultCodeProvider">
          Restore default code
        </button>
        <button class="btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="restoreDefaultCodeModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="restoreDefaultCodeModal"
  data-keyboard="false"
  data-backdrop="static">
  <div class="modal-lg modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Warning</h4>
      </div>
      <div class="modal-body">Restore default model?</div>

      <div class="modal-footer">
        <button class="btn btn-primary" (click)="restoreDefaultCode()">Accept</button>
        <button class="btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
      </div>
    </div>
  </div>
</div>
