import { Injectable } from '@angular/core';
import { V102 } from '../_compatibility/v-1-0-2';
import { V201 } from '../_compatibility/v201';
import { V210 } from '../_compatibility/v210';
import { V2100 } from '../_compatibility/v2100';

@Injectable()
export class CompatibilityService {
  makeCompatible(serializedProject: any) {
    const compatibilityFunctions = this.getCompatibilityFunctions();
    const versionsList = Object.keys(compatibilityFunctions).sort();
    const firstFunctionToApply = versionsList.findIndex(key => key > serializedProject.version);

    if (firstFunctionToApply > -1) {
      for (let i = firstFunctionToApply; i < versionsList.length; i++) {
        for (const serializedCase of serializedProject.cases) {
          compatibilityFunctions[versionsList[i]](serializedCase);
        }
      }
    }
  }

  getCompatibilityFunctions(): { [v: string]: (serializedCase: any) => void } {
    return {
      '1.0.2': V102.moveFluidAnalysisVarsToPool,
      '2.0.1': V201.updateHydrotreatersRatioVariables,
      '2.1.0': V210.moveFluidAnalysisToAssayManager,
      '2.10.0': V2100.transformBitumenConversionBlocks,
    };
  }
}
