import * as wjcCore from '@grapecity/wijmo';

import { FlexSheetCellConfig } from './flex-sheet-cell-config';

export enum DiluentSourceSheetRows {
  NAME = 0,
  VOLUMETRIC_FLOW_RATE = 2,
  GHG_INTENSITY = 3,
  LIGHT_ENDS = 11,
  LIGHT_NAPHTHA = 12,
  HEAVY_NAPHTHA = 13,
  DISTILLATE = 14,
  LGO = 15,
  HGO = 16,
  VGO = 17,
  HVGO = 18,
  RESID = 19,
}

export const DiluentSourceCellConfig: FlexSheetCellConfig[] = [
  {
    row: DiluentSourceSheetRows.NAME,
    dataType: wjcCore.DataType.String,
    format: '',
  },
  {
    row: DiluentSourceSheetRows.VOLUMETRIC_FLOW_RATE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: DiluentSourceSheetRows.GHG_INTENSITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: DiluentSourceSheetRows.LIGHT_ENDS,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: DiluentSourceSheetRows.LIGHT_NAPHTHA,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: DiluentSourceSheetRows.HEAVY_NAPHTHA,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: DiluentSourceSheetRows.DISTILLATE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: DiluentSourceSheetRows.LGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: DiluentSourceSheetRows.HGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: DiluentSourceSheetRows.VGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: DiluentSourceSheetRows.HVGO,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: DiluentSourceSheetRows.RESID,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
];
