import { FlexSheetMapper } from './flex-sheet-mapper';
import { ThirdPartySource } from '../../../_models/_unit-operations/third-party-source';
import { ThirdPartySourceSheetRows } from '../cell-config/third-party-source-cell-config';
import { Mapper } from '../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../_models';
import { NumberToSimulationVariableMapper } from './number-to-simulation-variable-mapper';

export class ThirdPartySourceFlexSheetMapper implements FlexSheetMapper {
  simulationVariableMapper: Mapper<number, SimulationVariable>;

  constructor(simulationVariableMapper: NumberToSimulationVariableMapper) {
    this.simulationVariableMapper = simulationVariableMapper;
  }

  map(unitOperation: ThirdPartySource, row: number, value: string | number) {
    if (row === ThirdPartySourceSheetRows.NAME && typeof value === 'string') {
      unitOperation.name = value;
    }

    if (typeof value === 'number') {
      switch (row) {
        case ThirdPartySourceSheetRows.VOLUMETRIC_FLOW_RATE:
          this.simulationVariableMapper.map(value, unitOperation.volumetricFlowrate);
          break;

        case ThirdPartySourceSheetRows.GHG_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.ghgIntensity);
          break;

        case ThirdPartySourceSheetRows.PRICE:
          this.simulationVariableMapper.map(value, unitOperation.price);
          break;
      }
    }
  }
}
