import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Cut } from '../../../_models/_fluid/cut';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';

@Component({
  selector: 'sob-reactive-cut',
  templateUrl: './reactive-cut.component.html',
  styleUrls: ['./reactive-cut.component.css'],
})
export class ReactiveCutComponent implements OnInit {
  @Input()
  cut: Cut;

  @Input() idPrefix: string;
  @Input() isStream: boolean;
  @Input() readOnly: boolean;
  @Input() formGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private svfb: SimVarFormBuilder) {}

  ngOnInit(): void {
    this.readOnly = this.readOnly ? this.readOnly : false;
    this.addControls();
  }

  addControls() {
    this.cutFormGroup.addControl('sulfur', this.svfb.control(this.cut.sulfur, this.readOnly));
    this.cutFormGroup.addControl('anilinePoint', this.svfb.control(this.cut.anilinePoint, this.readOnly));
    this.cutFormGroup.addControl('aromaticityFactor', this.svfb.control(this.cut.aromaticityFactor, this.readOnly));
    this.cutFormGroup.addControl('massDensity', this.svfb.control(this.cut.massDensity, this.readOnly));
    this.cutFormGroup.addControl('nitrogen', this.svfb.control(this.cut.nitrogen, this.readOnly));
    this.cutFormGroup.addControl('ccr', this.svfb.control(this.cut.ccr, this.readOnly));
    this.cutFormGroup.addControl('afc', this.svfb.control(this.cut.afc, this.readOnly));
  }

  get cutFormGroup() {
    return this.formGroup.get(this.idPrefix) as UntypedFormGroup;
  }
}
