import { SimulationVariable } from '../simulation-variable';
import { Compound } from './compound';
import { Case } from '../case';

export class SuncorHypotheticalCompound extends Compound {
  category = 'hypothetical-compound';

  massDensity: SimulationVariable;
  olefinContent: SimulationVariable;
  aromaticityFactor: SimulationVariable;
  anilinePoint: SimulationVariable;
  nitrogenContent: SimulationVariable;
  sulfurContent: SimulationVariable;
  ccrContent: SimulationVariable;
  vabp: SimulationVariable;
  fbp: SimulationVariable;
  ibp: SimulationVariable;

  /**
   *
   */
  constructor(compound: any, ownerCase: Case) {
    super(compound.id, ownerCase);

    this.initValues(compound);
    this.setSimulationVariableNames();
  }

  initValues(compound: any) {
    this.massDensity = new SimulationVariable(compound.massDensity, this.ownerCase, this);
    this.olefinContent = new SimulationVariable(compound.olefinContent, this.ownerCase, this);
    this.aromaticityFactor = new SimulationVariable(compound.aromaticityFactor, this.ownerCase, this);
    this.anilinePoint = new SimulationVariable(compound.anilinePoint, this.ownerCase, this);
    this.nitrogenContent = new SimulationVariable(compound.nitrogenContent, this.ownerCase, this);
    this.sulfurContent = new SimulationVariable(compound.sulfurContent, this.ownerCase, this);
    this.ccrContent = new SimulationVariable(compound.ccrContent, this.ownerCase, this);
    this.vabp = new SimulationVariable(compound.vabp, this.ownerCase, this);
    this.fbp = new SimulationVariable(compound.fbp, this.ownerCase, this);
    this.ibp = new SimulationVariable(compound.ibp, this.ownerCase, this);
  }

  setSimulationVariableNames() {
    this.massDensity.setName('Mass Density');
    this.olefinContent.setName('Olefin content');
    this.aromaticityFactor.setName('Aromaticity factor');
    this.anilinePoint.setName('Aniline point');
    this.nitrogenContent.setName('Nitrogen content');
    this.sulfurContent.setName('Sulfur content');
    this.ccrContent.setName('CCR Content');
    this.vabp.setName('VABP');
    this.fbp.setName('FBP');
    this.ibp.setName('IBP');
  }

  // More methods are needed :(

  protected override isPropertyBlackListed(property: any): boolean {
    return property instanceof Case || typeof property === 'function';
  }

  // TODO: this should be at BaseObject class check if the isPropertyBlackListed affects.
  public toJSON(): any {
    const objectToSerialize: any = {};
    let val: any;
    for (val in this) {
      // only simulation variable id will be serialized
      // maybe detect all base objects?
      if (this[val] instanceof SimulationVariable) {
        objectToSerialize[val] = (<SimulationVariable>this[val]).id;
      } else if (this.isPropertyBlackListed(this[val])) {
        // welp, nothing
      } else {
        objectToSerialize[val] = this[val];
      }
    }

    return objectToSerialize;
  }
}
