<div class="form-group">
  <label [attr.for]="'capacity' + suncorRenewable.id" class="pw-label control-label col-sm-4"> Capacity </label>
  <sim-var-input [inputId]="'capacity' + suncorRenewable.id" [simVar]="suncorRenewable.capacity"> </sim-var-input>
</div>

<div class="form-group">
  <label [attr.for]="'capacityFactor' + suncorRenewable.id" class="pw-label control-label col-sm-4">
    Capacity Factor
  </label>
  <sim-var-input [inputId]="'capacityFactor' + suncorRenewable.id" [simVar]="suncorRenewable.capacityFactor">
  </sim-var-input>
</div>

<div class="form-group">
  <label [attr.for]="'ghgCredit' + suncorRenewable.id" class="pw-label control-label col-sm-4"> GHG Credit </label>
  <sim-var-input [inputId]="'ghgCredit' + suncorRenewable.id" [simVar]="suncorRenewable.ghgCredit"> </sim-var-input>
</div>

<div class="form-group">
  <label [attr.for]="'opex' + suncorRenewable.id" class="pw-label control-label col-sm-4"> OPEX </label>
  <sim-var-input [inputId]="'opex' + suncorRenewable.id" [simVar]="suncorRenewable.opex"> </sim-var-input>
</div>

<div class="form-group">
  <label [attr.for]="'amortizedCapex' + suncorRenewable.id" class="pw-label control-label col-sm-4">
    Amortized CAPEX
  </label>
  <sim-var-input [inputId]="'amortizedCapex' + suncorRenewable.id" [simVar]="suncorRenewable.amortizedCapex">
  </sim-var-input>
</div>

<div class="form-group">
  <label [attr.for]="'electricityPrice' + suncorRenewable.id" class="pw-label control-label col-sm-4">
    Electricity Price
  </label>
  <sim-var-input [inputId]="'electricityPrice' + suncorRenewable.id" [simVar]="suncorRenewable.electricityPrice">
  </sim-var-input>
</div>
