import { SimulationVariable } from '../simulation-variable';
import { IUnitOperation } from '../i-unit-operation';
import { Case } from '../case';
import { KpiProvider } from '../_case-study/kpi-provider';
import { defaultUpgraderModel } from '../../_config/unit-operations/default-models/default-upgrader-model';
import { ConstrainableObject } from '../_interfaces/constrainable-object';
import { UnitOperationConstraints } from './unit-operation-constraints';
import { SuncorUnitOpWithCogen } from './suncor-unit-op-with-cogen';
import { SimulationVariableName } from '../../_config/simulation-variable-name.enum';
import { ParameterProvider } from '../_case-study/parameter-provider';
import { MultiPeriodParameterProvider } from '../_multi-period/multi-period-parameter-provider';

declare let unitConverter: any;

export class Upgrader
  extends SuncorUnitOpWithCogen
  implements IUnitOperation, KpiProvider, ConstrainableObject, ParameterProvider, MultiPeriodParameterProvider
{
  category = 'upgrader';

  absoluteGhgEmissions: SimulationVariable;
  sweetProductRatio: SimulationVariable;
  sweetGhgIntensity: SimulationVariable;
  sourGhgIntensity: SimulationVariable;
  sulfurRemoval: SimulationVariable;
  nitrogenRemoval: SimulationVariable;
  ccrRemoval: SimulationVariable;
  sweetProductPrice: SimulationVariable;
  sourProductPrice: SimulationVariable;
  volumetricYield: SimulationVariable;
  maximumSulfurContent: SimulationVariable;
  maximumMassDensity: SimulationVariable;
  maximumHydrogenProduction: SimulationVariable;
  hydrogenUse: SimulationVariable;
  swellFactor: SimulationVariable;
  useSubFlowsheet: boolean;
  useCustomModel: boolean;
  upgraderModelScript: string;

  // TODO this seems to be obsolete
  hasTwoInlets: boolean;
  sulfurCapacity: SimulationVariable;
  constraints: UnitOperationConstraints;

  useCustomModelForHydrogenPlants: boolean;
  hydrogenPlantsCustomModel: string;

  // Amine tab fields
  totalH2SProduction: SimulationVariable;
  deaUseRate: SimulationVariable;
  amineCirculationRate: SimulationVariable;
  amineRateCapacity: SimulationVariable;

  // results for Utility Steam
  steamMake900: SimulationVariable;
  steamUse900: SimulationVariable;
  steamMake600: SimulationVariable;
  steamUse600: SimulationVariable;
  steamMake150: SimulationVariable;
  steamUse150: SimulationVariable;
  steamMake50: SimulationVariable;
  steamUse50: SimulationVariable;
  /**
   *
   */
  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  getAvailableKpis() {
    if (this.ownerCase.multiPeriodEnabled) {
      return [
        this.absoluteGhgEmissions,
        this.amineCirculationRate,
        this.cogenElectricityGeneration,
        this.ghgEmissions,
        this.hydrogenUse,
        this.sourGhgIntensity,
        this.sourProductPrice,
        this.sweetGhgIntensity,
        this.sweetProductPrice,
        this.totalH2SProduction,
      ];
    }
    return [
      this.absoluteGhgEmissions,
      this.amineCirculationRate,
      this.cogenElectricityGeneration,
      this.ghgEmissions,
      this.hydrogenUse,
      this.sourGhgIntensity,
      this.sourProductPrice,
      this.steamMake900,
      this.steamUse900,
      this.sweetGhgIntensity,
      this.sweetProductPrice,
      this.totalH2SProduction,
    ];
  }

  getAvailableMultiPeriodParameters(): SimulationVariable[] {
    return [this.capacity];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [
      this.ccrRemoval,
      this.nitrogenRemoval,
      this.sulfurRemoval,
      this.sweetProductRatio,
      this.sweetGhgIntensity,
      this.sourGhgIntensity,
      this.cogenElectricityGeneration,
      this.netElectricityExport,
      this.gridIntensity,
      this.cogenIntensity,
      this.deaUseRate,
      this.sweetProductPrice,
      this.sourProductPrice,
      this.electricityPrice,
      this.opexVar,
      this.opexGasDiesel,
      this.opexPower,
      this.opexFixed,
      this.capexAmortized,
    ];
  }

  override clearResults() {
    super.clearResults();
    this.absoluteGhgEmissions.value = null;
    this.ghgEmissions.value = null;
    this.revenue.value = null;
    this.unitValue.value = null;
    this.opexCarbonDioxide.value = null;
    this.opexTotal.value = null;
    this.capexTotal.value = null;
    this.volumetricYield.value = null;
  }

  override initValues(unitOperation: any): void {
    super.initValues(unitOperation);
    this.absoluteGhgEmissions = new SimulationVariable(unitOperation.absoluteGhgEmissions, this.ownerCase, this);
    this.sweetProductRatio = new SimulationVariable(unitOperation.sweetProductRatio, this.ownerCase, this);
    this.sweetGhgIntensity = new SimulationVariable(unitOperation.sweetGhgIntensity, this.ownerCase, this);
    this.sourGhgIntensity = new SimulationVariable(unitOperation.sourGhgIntensity, this.ownerCase, this);
    this.sulfurRemoval = new SimulationVariable(unitOperation.sulfurRemoval, this.ownerCase, this);
    this.nitrogenRemoval = new SimulationVariable(unitOperation.nitrogenRemoval, this.ownerCase, this);
    this.ccrRemoval = new SimulationVariable(unitOperation.ccrRemoval, this.ownerCase, this);
    this.sweetProductPrice = new SimulationVariable(unitOperation.sweetProductPrice, this.ownerCase, this);
    this.sourProductPrice = new SimulationVariable(unitOperation.sourProductPrice, this.ownerCase, this);
    this.volumetricYield = new SimulationVariable(unitOperation.volumetricYield, this.ownerCase, this);
    this.maximumSulfurContent = new SimulationVariable(unitOperation.maximumSulfurContent, this.ownerCase, this);
    this.maximumMassDensity = new SimulationVariable(unitOperation.maximumMassDensity, this.ownerCase, this);
    this.maximumHydrogenProduction = new SimulationVariable(
      unitOperation.maximumHydrogenProduction,
      this.ownerCase,
      this
    );
    this.hydrogenUse = new SimulationVariable(unitOperation.hydrogenUse, this.ownerCase, this);
    this.swellFactor = new SimulationVariable(unitOperation.swellFactor, this.ownerCase, this);

    // Amine tab
    this.totalH2SProduction = new SimulationVariable(unitOperation.totalH2SProduction || {}, this.ownerCase, this);
    this.deaUseRate = new SimulationVariable(unitOperation.deaUseRate || {}, this.ownerCase, this);
    this.amineCirculationRate = new SimulationVariable(unitOperation.amineCirculationRate || {}, this.ownerCase, this);
    this.amineRateCapacity = new SimulationVariable(unitOperation.amineRateCapacity || {}, this.ownerCase, this);
    this.sulfurCapacity = new SimulationVariable(unitOperation.sulfurCapacity || {}, this.ownerCase, this);

    // utility Steam
    this.steamMake900 = new SimulationVariable(unitOperation.steamMake900 || {}, this.ownerCase, this);
    this.steamUse900 = new SimulationVariable(unitOperation.steamUse900 || {}, this.ownerCase, this);
    this.steamMake600 = new SimulationVariable(unitOperation.steamMake600 || {}, this.ownerCase, this);
    this.steamUse600 = new SimulationVariable(unitOperation.steamUse600 || {}, this.ownerCase, this);
    this.steamMake150 = new SimulationVariable(unitOperation.steamMake150 || {}, this.ownerCase, this);
    this.steamUse150 = new SimulationVariable(unitOperation.steamUse150 || {}, this.ownerCase, this);
    this.steamMake50 = new SimulationVariable(unitOperation.steamMake50 || {}, this.ownerCase, this);
    this.steamUse50 = new SimulationVariable(unitOperation.steamUse50 || {}, this.ownerCase, this);

    if (typeof unitOperation.useCustomModel === 'undefined') {
      this.useCustomModel = false;
    } else {
      this.useCustomModel = unitOperation.useCustomModel;
    }
    if (typeof unitOperation.useSubFlowsheet === 'undefined') {
      this.useSubFlowsheet = false;
    } else {
      this.useSubFlowsheet = unitOperation.useSubFlowsheet;
    }

    if (typeof unitOperation.hasTwoInlets === 'undefined') {
      this.hasTwoInlets = false;
    } else {
      this.hasTwoInlets = unitOperation.hasTwoInlets;
    }
    this.hydrogenPlantsCustomModel = unitOperation.hydrogenPlantsCustomModel;
    this.useCustomModelForHydrogenPlants = unitOperation.useCustomModelForHydrogenPlants;

    this.upgraderModelScript =
      typeof unitOperation.upgraderModelScript === 'undefined'
        ? defaultUpgraderModel
        : unitOperation.upgraderModelScript;

    this.initConstraints();
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('capacity', this.capacity);
    this.constraints.addConstraint('maximumSulfurContent', this.maximumSulfurContent);
    this.constraints.addConstraint('maximumMassDensity', this.maximumMassDensity);
    this.constraints.addConstraint('maximumHydrogenProduction', this.maximumHydrogenProduction);
    this.constraints.addConstraint('amineRateCapacity', this.amineRateCapacity);
    this.constraints.addConstraint('sulfurCapacity', this.sulfurCapacity);
  }

  override setDefaultValues() {
    super.setDefaultValues();

    if (this.absoluteGhgEmissions.isUndefined()) {
      this.absoluteGhgEmissions.setDefaultValue(0.0, unitConverter.units.Massflowrate.MMTPA);
    }

    if (this.ccrRemoval.isUndefined()) {
      this.ccrRemoval.setDefaultValue(95);
    }

    if (this.sulfurRemoval.isUndefined()) {
      this.sulfurRemoval.setDefaultValue(99);
    }

    if (this.nitrogenRemoval.isUndefined()) {
      this.nitrogenRemoval.setDefaultValue(95);
    }

    if (this.sweetProductRatio.isUndefined()) {
      this.sweetProductRatio.setDefaultValue(55);
    }

    if (this.sweetProductPrice.isUndefined()) {
      this.sweetProductPrice.setDefaultValue(70);
    }

    if (this.sourProductPrice.isUndefined()) {
      this.sourProductPrice.setDefaultValue(65);
    }

    if (this.sourGhgIntensity.isUndefined()) {
      this.sourGhgIntensity.setDefaultValue(null, unitConverter.units.MassPerVolume.KG_BBL);
    }

    if (this.sweetGhgIntensity.isUndefined()) {
      this.sweetGhgIntensity.setDefaultValue(null, unitConverter.units.MassPerVolume.KG_BBL);
    }
    if (this.volumetricYield.isUndefined()) {
      this.volumetricYield.setDefaultValue(null);
    }
    if (this.swellFactor.isUndefined()) {
      this.swellFactor.setDefaultValue(1.03);
    }
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.absoluteGhgEmissions.setName('Absolute GHG Emissions');
    this.sweetProductRatio.setName('Sweet Product Ratio');
    this.sweetGhgIntensity.setName('Sweet Product GHG Intensity');
    this.sourGhgIntensity.setName('Sour Product GHG Intensity');
    this.sulfurRemoval.setName('Sulfur Removal');
    this.nitrogenRemoval.setName('Nitrogen Removal');
    this.ccrRemoval.setName('CCR Removal');
    this.sweetProductPrice.setName('Sweet Product Price');
    this.sourProductPrice.setName('Sour Product Price');
    this.deaUseRate.setName(SimulationVariableName.DEA_USE_RATE);
    // this.volumetricYield.setName('Volumetric Yield');
    this.volumetricYield.setName('Swell factor');
    this.maximumHydrogenProduction.setName(SimulationVariableName.MAXIMUM_HYDROGEN_PRODUCTION);
    this.maximumMassDensity.setName(SimulationVariableName.MAXIMUM_MASS_DENSITY);
    this.maximumSulfurContent.setName(SimulationVariableName.MAXIMUM_SULFUR_CONTENT);
    this.amineRateCapacity.setName(SimulationVariableName.AMINE_RATE_CAPACITY);
    this.sulfurCapacity.setName(SimulationVariableName.SULFUR_CAPACITY);

    this.hydrogenUse.setName('Hydrogen Use');
    this.totalH2SProduction.setName('Total H2S Production');
    this.amineCirculationRate.setName('Amine Circulation Rate');
    this.steamMake900.setName('#900 Steam Make');
    this.steamUse900.setName('#900 Steam Use');
    this.capacity.setName(SimulationVariableName.CAPACITY);
  }

  override setQuantities() {
    super.setQuantities();
    this.absoluteGhgEmissions.setQuantity('Massflowrate');
    this.sweetProductRatio.setQuantity('Percent');
    this.sweetGhgIntensity.setQuantity('MassPerVolume');
    this.sourGhgIntensity.setQuantity('MassPerVolume');
    this.sulfurRemoval.setQuantity('Percent');
    this.nitrogenRemoval.setQuantity('Percent');
    this.ccrRemoval.setQuantity('Percent');
    this.sweetProductPrice.setQuantity('CurrencyPerVolume');
    this.sourProductPrice.setQuantity('CurrencyPerVolume');
    this.volumetricYield.setQuantity('Percent');
    this.maximumSulfurContent.setQuantity('MassFraction');
    this.maximumMassDensity.setQuantity('LiquidDensity');
    this.maximumHydrogenProduction.setQuantity('Moleflowrate');
    this.swellFactor.setQuantity('Dimensionless');
    this.hydrogenUse.setQuantity('Moleflowrate');
    this.sulfurCapacity.setQuantity('Moleflowrate');

    // Amine tab
    this.totalH2SProduction.setQuantity('Moleflowrate');
    this.deaUseRate.setQuantity('AmineUseRate');
    this.amineCirculationRate.setQuantity('Volumetricflowrate');
    this.amineRateCapacity.setQuantity('Volumetricflowrate');

    // utility steam
    this.steamMake900.setQuantity('Massflowrate');
    this.steamUse900.setQuantity('Massflowrate');
    this.steamMake600.setQuantity('Massflowrate');
    this.steamUse600.setQuantity('Massflowrate');
    this.steamMake150.setQuantity('Massflowrate');
    this.steamUse150.setQuantity('Massflowrate');
    this.steamMake50.setQuantity('Massflowrate');
    this.steamUse50.setQuantity('Massflowrate');
  }

  getAlternativeGhgIntensity() {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    });

    if (inletStream.length) {
      const altGhgIntensity = new SimulationVariable(this.ghgEmissions, this.ownerCase, this);
      altGhgIntensity.value = this.ghgEmissions.value / inletStream[0].volumetricFlowrate.value;
      altGhgIntensity.setQuantity('MassPerVolume');
      return altGhgIntensity;
    }

    return undefined;
  }

  getAvailableParameters() {
    return [
      this.sourGhgIntensity,
      this.sweetGhgIntensity,
      this.sourProductPrice,
      this.sweetProductPrice,
      this.electricityPrice,
      this.opexVar,
      this.opexGasDiesel,
      this.opexPower,
      this.opexFixed,
    ];
  }

  override dePersist(sup: any) {
    super.dePersist(sup);

    this.absoluteGhgEmissions = this.getSimulationVariableSafe(sup.absoluteGhgEmissions);
    this.sweetProductRatio = this.getSimulationVariableSafe(sup.sweetProductRatio);
    this.sweetGhgIntensity = this.getSimulationVariableSafe(sup.sweetGhgIntensity);
    this.sourGhgIntensity = this.getSimulationVariableSafe(sup.sourGhgIntensity);
    this.sulfurRemoval = this.getSimulationVariableSafe(sup.sulfurRemoval);
    this.nitrogenRemoval = this.getSimulationVariableSafe(sup.nitrogenRemoval);
    this.ccrRemoval = this.getSimulationVariableSafe(sup.ccrRemoval);
    this.sweetProductPrice = this.getSimulationVariableSafe(sup.sweetProductPrice);
    this.sourProductPrice = this.getSimulationVariableSafe(sup.sourProductPrice);
    this.volumetricYield = this.getSimulationVariableSafe(sup.volumetricYield);
    this.maximumSulfurContent = this.getSimulationVariableSafe(sup.maximumSulfurContent);
    this.maximumMassDensity = this.getSimulationVariableSafe(sup.maximumMassDensity);
    this.maximumHydrogenProduction = this.getSimulationVariableSafe(sup.maximumHydrogenProduction);
    this.hydrogenUse = this.getSimulationVariableSafe(sup.hydrogenUse);
    this.swellFactor = this.getSimulationVariableSafe(sup.swellFactor);

    // Amine tab
    this.totalH2SProduction = this.getSimulationVariableSafe(sup.totalH2SProduction);
    this.deaUseRate = this.getSimulationVariableSafe(sup.deaUseRate);
    this.amineCirculationRate = this.getSimulationVariableSafe(sup.amineCirculationRate);
    this.amineRateCapacity = this.getSimulationVariableSafe(sup.amineRateCapacity);
    this.sulfurCapacity = this.getSimulationVariableSafe(sup.sulfurCapacity);

    // utility Steam
    this.steamMake900 = this.getSimulationVariableSafe(sup.steamMake900);
    this.steamUse900 = this.getSimulationVariableSafe(sup.steamUse900);
    this.steamMake600 = this.getSimulationVariableSafe(sup.steamMake600);
    this.steamUse600 = this.getSimulationVariableSafe(sup.steamUse600);
    this.steamMake150 = this.getSimulationVariableSafe(sup.steamMake150);
    this.steamUse150 = this.getSimulationVariableSafe(sup.steamUse150);
    this.steamMake50 = this.getSimulationVariableSafe(sup.steamMake50);
    this.steamUse50 = this.getSimulationVariableSafe(sup.steamUse50);

    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }

  override get isFlowsheetOwner() {
    return this.useSubFlowsheet;
  }

  // region constraints
  constraintViolated() {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    })[0];

    return (
      inletStream &&
      (this.constraints.constraintValueViolated('capacity', inletStream.volumetricFlowrate.value) ||
        this.constraints.constraintValueViolated('maximumSulfurContent', inletStream.sulfur.value) ||
        this.constraints.constraintValueViolated('maximumMassDensity', inletStream.massDensity.value))
    );
  }

  override ignoreForMakeReady(simvar: SimulationVariable): boolean {
    if (!this.useSubFlowsheet) {
      return (
        super.ignoreForMakeReady(simvar) ||
        simvar === this.volumetricYield ||
        simvar === this.minimumFlow ||
        simvar === this.hydrogenUse ||
        simvar === this.opexCarbonDioxide ||
        simvar === this.opexTotal
      );
    }
    return (
      super.ignoreForMakeReady(simvar) ||
      simvar === this.volumetricYield ||
      simvar === this.minimumFlow ||
      simvar === this.hydrogenUse ||
      simvar === this.opexCarbonDioxide ||
      simvar === this.opexTotal ||
      simvar === this.sweetGhgIntensity ||
      simvar === this.sourGhgIntensity ||
      simvar === this.sweetProductPrice ||
      simvar === this.sourProductPrice ||
      simvar === this.electricityPrice ||
      simvar === this.sweetProductRatio ||
      simvar === this.sulfurRemoval ||
      simvar === this.nitrogenRemoval ||
      simvar === this.ccrRemoval ||
      simvar === this.swellFactor
    );
  }

  constraintValueDefined() {
    return this.constraints.anyConstraintValueDefined();
  }
  // endregion

  override isPropertyBlackListed(property) {
    return super.isPropertyBlackListed(property) || property === this.constraints;
  }
}
