<div class="validation-error" *ngIf="control.errors?.['required']">
  <i class="fa fa-exclamation-triangle"></i>
  <span class="m-l-xs">This field is required</span>
</div>

<div class="validation-error" *ngIf="control.errors?.['positive']">
  <i class="fa fa-exclamation-triangle"></i>
  <span class="m-l-xs">Please enter a positive number</span>
</div>

<div class="validation-error" *ngIf="control.errors?.['validNumber']">
  <i class="fa fa-exclamation-triangle"></i>
  <span class="m-l-xs">Please enter a valid number</span>
</div>
