import { BaseObject } from '../../base-object';
import { SimulationVariable } from '../../simulation-variable';
import { Case } from '../../case';
import { Quantity } from '../../../_config/quantity.enum';

export class FuelGasSourceInformationStream extends BaseObject {
  category = 'fuelGasSourceInformationStream';
  flowrate: SimulationVariable;
  providerUnitOperationId: string;

  constructor(informationStream: FuelGasSourceInformationStream | any, ownerCase: Case) {
    super(informationStream.id, ownerCase);
    this.initValues(informationStream);
    this.setQuantities();
  }

  private initValues(informationStream: FuelGasSourceInformationStream) {
    this.providerUnitOperationId = informationStream.providerUnitOperationId;
    this.flowrate = new SimulationVariable(informationStream.flowrate, this.ownerCase, this);
  }

  setQuantities() {
    this.flowrate.setQuantity(Quantity.MOLARFLOWRATE);
  }

  getFullName() {
    return `${this.ownerCase.getUnitOperation(this.providerUnitOperationId).name}`;
  }

  override dePersist(persistedObj: any) {
    super.dePersist(persistedObj);
    this.flowrate = this.getSimulationVariableSafe(persistedObj.flowrate);
    this.setSimulationVariablesOwner();
    this.setQuantities();
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      category: this.category,
      providerUnitOperationId: this.providerUnitOperationId,
      flowrate: this.flowrate.id,
    };
  }
}
