import { Component, Input, OnInit } from '@angular/core';
import { WaterMixer } from '../../../_models/_unit-operations/utilties/water-mixer';
import { PressureCalculationMode } from '../../../_config/pressure-calculation-mode.enum';

@Component({
  selector: 'sob-water-mixer',
  templateUrl: './water-mixer.component.html',
  styleUrls: ['./water-mixer.component.css'],
})
export class WaterMixerComponent implements OnInit {
  @Input()
  unitOperation: WaterMixer;

  ngOnInit() {
    if ($('#selectMode').val() === PressureCalculationMode.LOWEST) {
      $('#outlet-pressure').hide();
    }
    if (this.unitOperation.pressureCalculationMode === PressureCalculationMode.OUTLET_PRESSURE) {
      $('#selectMode').val('Outlet Pressure');
      $('#outlet-pressure').show();
    } else if (this.unitOperation.pressureCalculationMode === PressureCalculationMode.LOWEST) {
      $('#selectMode').val('Lowest');
      $('#outlet-pressure').hide();
    }
  }

  pressureCalculationModeChanged(calculationValue) {
    if (calculationValue === PressureCalculationMode.OUTLET_PRESSURE) {
      this.unitOperation.pressureCalculationMode = PressureCalculationMode.OUTLET_PRESSURE;
      $('#outlet-pressure').show();
    } else if (calculationValue === PressureCalculationMode.LOWEST) {
      this.unitOperation.pressureCalculationMode = PressureCalculationMode.LOWEST;
      $('#outlet-pressure').hide();
    }
  }
}
