<div class="modal fade" id="caseCommentsModal" tabindex="-1" role="dialog" aria-labelledby="caseCommentsModalLabel">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Case Comments</h4>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <div class="row buttons">
            <a (click)="openFlowsheetComments()" class="btn btn-primary btn-md pdl">Case Comments</a>
          </div>
          <br />
          <div class="comments" id="mainReport">
            <table class="table" id="tableCaseComments" style="width: 100%">
              <thead>
                <tr>
                  <th style="padding-left: 10px">Operation</th>
                  <th style="padding-left: 10px">Flowsheet</th>
                  <th style="padding-left: 10px">Inspect</th>
                  <th style="padding-left: 10px">Created by</th>
                  <th style="padding-left: 10px">Created on</th>
                  <th style="padding-left: 10px">Text</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>

<div
  class="modal"
  *ngIf="selectedComment"
  id="editComment"
  tabindex="-1"
  role="dialog"
  aria-labelledby="flowsheetCommentsModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="cancel()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Edit Comment</h4>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <textarea rows="5" placeholder="Type here" cols="74" id="area">{{ selectedComment.text }}</textarea>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-default" (click)="cancel()" data-dismiss="modal">Cancel</button>
        <button class="btn btn-primary" (click)="saveComment(selectedComment)" data-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>
