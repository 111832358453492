import { SimulationVariable } from '../simulation-variable';
import { SuncorUnitOperation } from './suncor-unit-operation';
import { IUnitOperation } from '../i-unit-operation';
import { Case } from '../case';
import { ConstrainableObject } from '../_interfaces/constrainable-object';
import { UnitOperationConstraints } from './unit-operation-constraints';
import { KpiProvider } from '../_case-study/kpi-provider';
import { SimulationVariableName } from '../../_config/simulation-variable-name.enum';
import { ParameterProvider } from '../_case-study/parameter-provider';
import { MultiPeriodParameterProvider } from '../_multi-period/multi-period-parameter-provider';
import { unitOperationsConfig } from '../../_config/unit-operations.config';

declare let unitConverter: any;

export class Pipe
  extends SuncorUnitOperation
  implements IUnitOperation, ConstrainableObject, KpiProvider, ParameterProvider, MultiPeriodParameterProvider
{
  category = unitOperationsConfig.pipe.key;

  // can't use "length"
  pipeLength: SimulationVariable;
  pressureDrop: SimulationVariable;
  pipeDensitySpec: SimulationVariable;
  ghgIntensityPerMassDistance: SimulationVariable;

  constraints: UnitOperationConstraints;

  /**
   *
   */
  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [this.pressureDrop, this.ghgIntensityPerMassDistance, this.ghgEmissions];
    }
    return [this.pipeLength, this.pressureDrop, this.ghgIntensityPerMassDistance, this.ghgEmissions];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.pipeLength, this.pressureDrop, this.ghgIntensity, this.opexFixed, this.opexVar];
  }

  getAvailableParameters() {
    return undefined;
  }

  override clearResults() {
    super.clearResults();
    this.ghgEmissions.value = null;
    this.opexCarbonDioxide.value = null;
    this.opexTotal.value = null;
  }

  override initValues(unitOperation: any): void {
    super.initValues(unitOperation);
    this.pipeLength = new SimulationVariable(unitOperation.pipeLength, this.ownerCase, this);
    this.pressureDrop = new SimulationVariable(unitOperation.pressureDrop, this.ownerCase, this);
    this.pipeDensitySpec = new SimulationVariable(unitOperation.pipeDensitySpec, this.ownerCase, this);
    // redefine?
    this.ghgIntensityPerMassDistance = new SimulationVariable(
      unitOperation.ghgIntensityPerMassDistance,
      this.ownerCase,
      this
    );
    this.initConstraints();
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('capacity', this.capacity);
    this.constraints.addConstraint('pipeDensitySpec', this.pipeDensitySpec);
    this.constraints.addConstraint('minimumFlow', this.minimumFlow);
  }

  override setSimulationVariableNames(): void {
    super.setSimulationVariableNames();
    this.pipeLength.setName('Pipe Length');
    this.pressureDrop.setName('Pressure drop');
    this.pipeDensitySpec.setName('Density spec');
    this.capacity.setName(SimulationVariableName.CAPACITY);
    this.ghgIntensityPerMassDistance.setName(SimulationVariableName.GHG_INTENSITY);
  }

  override setDefaultValues(): void {
    if (this.pipeLength.isUndefined()) {
      this.pipeLength.setDefaultValue(0.0);
    }
    if (this.pressureDrop.isUndefined()) {
      this.pressureDrop.setDefaultValue(0.0);
    }
    if (this.pipeDensitySpec.isUndefined()) {
      this.pipeDensitySpec.setDefaultValue(920.98, unitConverter.units.LiquidDensity.KG_M3);
    }
    if (this.ghgIntensityPerMassDistance.isUndefined()) {
      this.ghgIntensityPerMassDistance.setDefaultValue(0.01);
    }

    super.setDefaultValues();
  }

  override setQuantities() {
    super.setQuantities();
    this.pipeLength.setQuantity('Length');
    // TODO: Fix this
    // Should be DeltaPressure
    this.pressureDrop.setQuantity('Pressure');
    this.pipeDensitySpec.setQuantity('LiquidDensity');

    this.ghgIntensityPerMassDistance.setQuantity('MassGHGPerMassDistance');
  }

  override updateStatus(another: Pipe) {
    this.updateSimulationVariablesStatus(another);
  }

  getAlternativeGhgIntensity() {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    });

    if (inletStream.length) {
      const altGhgIntensity = new SimulationVariable(this.ghgEmissions, this.ownerCase, this);
      altGhgIntensity.value = this.ghgEmissions.value / inletStream[0].volumetricFlowrate.value;
      altGhgIntensity.setQuantity('MassPerVolume');
      return altGhgIntensity;
    }

    return undefined;
  }

  override dePersist(sup: any) {
    super.dePersist(sup);

    this.pipeLength = this.getSimulationVariableSafe(sup.pipeLength);
    this.pressureDrop = this.getSimulationVariableSafe(sup.pressureDrop);
    this.pipeDensitySpec = this.getSimulationVariableSafe(sup.pipeDensitySpec);
    this.ghgIntensityPerMassDistance = this.getSimulationVariableSafe(sup.ghgIntensityPerMassDistance);

    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }

  override ignoreForMakeReady(simvar: SimulationVariable): boolean {
    return super.ignoreForMakeReady(simvar);
  }

  // region constraints
  constraintViolated() {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    })[0];

    return inletStream
      ? this.constraints.constraintValueViolated('capacity', inletStream.volumetricFlowrate.value) ||
          this.constraints.constraintValueViolated('pipeDensitySpec', inletStream.massDensity.value)
      : false;
  }

  constraintValueDefined() {
    return this.constraints.anyConstraintValueDefined();
  }
  // endregion

  getAvailableMultiPeriodParameters(): SimulationVariable[] {
    return [this.capacity];
  }
}
