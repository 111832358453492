import { Injectable } from '@angular/core';
import { CoreService } from '../core.service';
import { FlowsheetDiagramService } from '../flowsheet-diagram/flowsheet-diagram.service';
import { AbstractSubFlowsheetHandler } from './abstract-sub-flowsheet-handler';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { UpgraderSubFlowsheetHandler } from './upgrader-sub-flowsheet-handler.service';
import { ExtractionSubFlowsheetHandler } from './extraction-sub-flowsheet-handler.service';
import { SankeyDiagramService } from '../../flowsheet/sankey-diagram/sankey-diagram.service';
import { Flowsheet } from '../../_models/flowsheet-manager/flowsheet';
import { UtilitiesFlowsheetHandler } from './utilities-flowsheet-handler';
import { StreamCategory } from '../../_config/stream-category.enum';
import { FuelGasSubFlowsheetHandler } from './fuel-gas-sub-flowsheet-handler';

@Injectable({ providedIn: 'root' })
export class SubFlowsheetService {
  private subFlowsheetHandlers: { [key: string]: AbstractSubFlowsheetHandler } = {};
  private activeSubFlowsheetHandler: AbstractSubFlowsheetHandler;

  // this is a state variable... should not be used? this should be emptied when a new project file is loaded
  private allSubFlowsheetStacks: { [id: string]: Flowsheet[] } = {};

  constructor(
    private coreService: CoreService,
    private flowsheetDiagramService: FlowsheetDiagramService,
    private upgraderSubFlowsheetHandler: UpgraderSubFlowsheetHandler,
    private utilitiesFlowsheetHandler: UtilitiesFlowsheetHandler,
    private fuelGasFlowsheetHandler: FuelGasSubFlowsheetHandler,
    private extractionSubFlowsheetHandler: ExtractionSubFlowsheetHandler,
    private sankeyService: SankeyDiagramService
  ) {
    this.enableMaterialStreams();
    this.subFlowsheetHandlers[unitOperationsConfig.upgrader.key] = this.upgraderSubFlowsheetHandler;
    this.subFlowsheetHandlers[unitOperationsConfig.waterUtilityUnitOperation.key] = this.utilitiesFlowsheetHandler;
    this.subFlowsheetHandlers[unitOperationsConfig.fuelGasUtilityUnitOperation.key] = this.fuelGasFlowsheetHandler;
    this.subFlowsheetHandlers[unitOperationsConfig.extraction.key] = this.extractionSubFlowsheetHandler;

    this.coreService.currentCaseReplacedRequest.subscribe((options: { wholeProjectReplaced: boolean }) => {
      this.onCurrentCaseReplaced(options);
    });
  }

  open(uoId): void {
    if (!this.currentCaseSubFlowsheetStack.length) {
      this.setSubFlowsheetStyle();
    }

    // this has to be called before swapping flowsheets
    this.coreService.saveCurrentFlowsheet();

    // this also swaps the flowsheet...
    const flowsheet = this.changeFlowsheet(uoId);

    this.currentCaseSubFlowsheetStack.push(flowsheet);
    this.prepareSubFlowsheet(uoId);
  }

  private prepareSubFlowsheet(uoId: string) {
    this.setActiveSubFlowsheetHandler(uoId);
    this.activeSubFlowsheetHandler.syncWhileFlowsheetLoading(uoId);

    // TODO examine carefully the necessity of the following two lines - do they impact performance?
    this.flowsheetDiagramService.updateUnitOperationsInfoOnDiagram(this.coreService.currentCase);
    this.flowsheetDiagramService.updateStreamData(this.coreService.currentCase);

    this.sankeyService.caseToSankeyDiagram(this.coreService.currentCase, uoId, true);

    if (this.isUtilitiesFlowsheetHandlerActive) {
      // when the water-utility-unit-operation flowsheet is active, another type of stream will be created
      this.enableWaterStreams();
    } else if (this.isFuelGasFlowsheetHandlerActive) {
      this.enableFuelGasStreams();
    }
  }

  private changeFlowsheet(uoId: string): Flowsheet {
    let flowsheet = this.coreService.currentCase.findFlowsheetByUnitOperationId(uoId);

    const unitOperationCategory = this.coreService.currentCase.getUnitOperation(uoId).category;
    const newSubFlowsheetHandler = this.subFlowsheetHandlers[unitOperationCategory];

    if (flowsheet) {
      this.setCurrentFlowsheet(flowsheet);
    } else {
      flowsheet = newSubFlowsheetHandler.createFlowsheet(uoId);
    }

    return flowsheet;
  }

  close(): void {
    // remove the flowsheet being closed
    this.currentCaseSubFlowsheetStack.pop();

    // if the flowsheet being closed is...
    if (this.isUtilitiesFlowsheetHandlerActive || this.isFuelGasFlowsheetHandlerActive) {
      this.enableMaterialStreams();
    }

    // TODO this is yet to be tested...
    if (this.currentCaseSubFlowsheetStack.length) {
      const flowsheet = this.currentCaseSubFlowsheetStack[this.currentCaseSubFlowsheetStack.length - 1];
      // let's just swap the flowsheets
      this.setCurrentFlowsheet(flowsheet);
      this.activeSubFlowsheetHandler.syncAllBlocksInletOutletPorts();
      this.prepareSubFlowsheet(flowsheet.unitOperationId);
      return;
    }

    this.exitToMainFlowsheet();
    this.activeSubFlowsheetHandler = undefined;
  }

  exitToMainFlowsheet(): void {
    this.setNormalFlowsheetStyle();
    this.sankeyService.caseToSankeyDiagram(this.coreService.currentCase, null, false);

    this.setCurrentFlowsheetById(this.coreService.currentCase.getMainFlowsheetId());
    this.activeSubFlowsheetHandler.syncAllBlocksInletOutletPorts();

    // TODO this should be improved... in the main flowsheet, only material streams are allowed
    this.enableMaterialStreams();

    this.clearCurrentCaseSubFlowsheetStack();
  }

  private onCurrentCaseReplaced(options) {
    if (options?.wholeProjectReplaced) {
      this.clearAllSubFlowsheetStacks();
    }

    if (this.currentCaseSubFlowsheetStack.length) {
      const currentSubFlowsheet = this.currentCaseSubFlowsheetStack[this.currentCaseSubFlowsheetStack.length - 1];
      this.setActiveSubFlowsheetHandler(currentSubFlowsheet.unitOperationId);
      this.setSubFlowsheetStyle();
    } else {
      this.activeSubFlowsheetHandler = undefined;
      this.setNormalFlowsheetStyle();
    }

    if (this.isUtilitiesFlowsheetHandlerActive) {
      this.enableWaterStreams();
    } else if (this.isFuelGasFlowsheetHandlerActive) {
      this.enableFuelGasStreams();
    } else {
      this.enableMaterialStreams();
    }
  }

  private setSubFlowsheetStyle(): void {
    $('#flowsheet-diagram-wrapper').css('background', '#C6CECF');
  }

  private setNormalFlowsheetStyle(): void {
    $('#flowsheet-diagram-wrapper').css('background', '#CCC');
  }

  private setCurrentFlowsheetById(flowsheetId) {
    const flowsheet = this.coreService.currentCase.getFlowsheet(flowsheetId);
    if (flowsheet) {
      this.setCurrentFlowsheet(flowsheet);
    }
  }

  private setCurrentFlowsheet(flowsheet: Flowsheet) {
    this.coreService.saveCurrentFlowsheet();
    this.flowsheetDiagramService.setDiagramModel(flowsheet.getDiagramJson());
    this.coreService.setCurrentFlowsheet(flowsheet);
    this.flowsheetDiagramService.updateStreamData(this.coreService.currentCase);
    this.flowsheetDiagramService.makeAllUnitOperationsDeletable();
  }

  updateMaxLinksForAllSubFlowsheets(): void {
    // eslint-disable-next-line guard-for-in
    for (const key in this.subFlowsheetHandlers) {
      this.subFlowsheetHandlers[key].syncAllBlocksInletOutletPorts();
    }
  }

  setActiveSubFlowsheetHandler(subFlowsheetOwnerId: string) {
    const unitOperationCategory = this.coreService.currentCase.getUnitOperation(subFlowsheetOwnerId).category;
    this.activeSubFlowsheetHandler = this.subFlowsheetHandlers[unitOperationCategory];
  }

  private get gojsDiagram(): go.Diagram {
    return this.flowsheetDiagramService.gojsDiagram;
  }

  getSubFlowsheetOwnerNames(): string[] {
    return this.currentCaseSubFlowsheetStack.map(
      f => this.coreService.currentCase.unitOperationPool[f.unitOperationId].name
    );
  }

  private clearCurrentCaseSubFlowsheetStack() {
    // let's empty the stack, while preserving the same reference
    this.currentCaseSubFlowsheetStack.length = 0;
  }

  /**
   * Do not use this outside this service
   */
  private get currentCaseSubFlowsheetStack(): Flowsheet[] {
    const currentCaseId = this.coreService.currentCase.id;
    if (!this.allSubFlowsheetStacks[currentCaseId]) {
      this.allSubFlowsheetStacks[currentCaseId] = [];
    }

    return this.allSubFlowsheetStacks[currentCaseId];
  }

  get currentSubFlowsheetCategory() {
    if (!this.currentCaseSubFlowsheetStack?.length) {
      return undefined;
    }

    const currentSubFlowsheet = this.currentCaseSubFlowsheetStack[this.currentCaseSubFlowsheetStack.length - 1];
    return this.coreService.currentCase.getUnitOperation(currentSubFlowsheet.unitOperationId).category;
  }

  get currentSubFlowsheetId() {
    if (!this.currentCaseSubFlowsheetStack?.length) {
      return undefined;
    }

    return this.currentCaseSubFlowsheetStack[this.currentCaseSubFlowsheetStack.length - 1].unitOperationId;
  }

  enableWaterStreams() {
    this.flowsheetDiagramService.setStreamCategory(StreamCategory.WATER_MATERIAL_STREAM);
  }

  enableMaterialStreams() {
    this.flowsheetDiagramService.setStreamCategory(StreamCategory.MATERIAL_STREAM);
  }

  enableFuelGasStreams() {
    this.flowsheetDiagramService.setStreamCategory(StreamCategory.FUEL_GAS_MATERIAL_STREAM);
  }

  get isUtilitiesFlowsheetHandlerActive() {
    return this.activeSubFlowsheetHandler === this.utilitiesFlowsheetHandler;
  }

  get isFuelGasFlowsheetHandlerActive() {
    return this.activeSubFlowsheetHandler === this.fuelGasFlowsheetHandler;
  }

  clearAllSubFlowsheetStacks(): void {
    this.allSubFlowsheetStacks = {};
  }
}
