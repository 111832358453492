import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { SimulationVariableResourceData } from '../_models/_unit-operations/simulation-variable-resource-data';
import { AssayManagerResourceData } from '../_models/_fluid/assay-manager-resource-data';

interface ResourceDataFile {
  UnitOperations: any;
  AssayManager: any;
}

@Injectable({
  providedIn: 'root',
})
export class ResourceDataService {
  resourceData: { [category: string]: SimulationVariableResourceData };
  assayManagerResourceData: AssayManagerResourceData;

  constructor(private apiService: ApiService) {}

  loadResourceFile() {
    this.resourceData = {};
    this.readResourceFile().subscribe((data: ResourceDataFile) => {
      this.resourceData = data.UnitOperations;
      this.assayManagerResourceData = data.AssayManager;
    });
  }

  readResourceFile() {
    return this.apiService.readResourceFile();
  }

  getUOResourceData(category) {
    if (this.resourceData.hasOwnProperty(category)) {
      return this.resourceData[category];
    }
    return null;
  }

  getAssayManagerResourceData(): AssayManagerResourceData {
    return this.assayManagerResourceData;
  }
}
