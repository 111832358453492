import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EnergyStream } from '../../../_models/_unit-operations/fuel-gas/energy-stream';

@Component({
  selector: 'sob-energy-stream-form',
  templateUrl: './energy-stream-form.component.html',
  styleUrls: ['./energy-stream-form.component.css'],
})
export class EnergyStreamFormComponent {
  @Input() energyStream: EnergyStream;
  @Input() energyStreamFormGroup: UntypedFormGroup;
}
