import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver/FileSaver';
import { CoreService } from '../../_services/core.service';
import { FlowsheetService } from '../../_services/flowsheet.service';
import { ExcelReportService } from '../../_services/excel-report/excel-report.service';

@Component({
  selector: 'sob-multiperiod-excel-report',
  templateUrl: './multiperiod-excel-report.component.html',
  styleUrls: ['./multiperiod-excel-report.component.css'],
})
export class MultiperiodExcelReportComponent implements OnInit {
  filename: string;
  constructor(
    private xlsxSrv: ExcelReportService,
    private flowsheetService: FlowsheetService,
    private coreService: CoreService
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  generateExcel() {
    this.filename = this.filename.replace(/\s/g, '');

    if (this.filename.length > 0) {
      this.downloadExcelReport(this.filename);
      $('#multiperiodExcelReportModal').modal('hide');
      this.filename = '';
    }
  }

  private downloadExcelReport(filename): void {
    if (this.currentCase.getActiveFlowsheet().isSubflowsheet()) {
      this.flowsheetService.exitToMainFlowsheet();
    }

    const excelFile = this.xlsxSrv.generateMultiPeriodResultsExcelReport();
    saveAs(new Blob([excelFile], { type: 'application/octet-stream' }), `${filename}.xlsx`);
  }

  get currentCase() {
    return this.coreService.currentCase;
  }
}
