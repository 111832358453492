/* eslint-disable no-console */
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'sob-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent {
  error: string;
  success: string;
  loading = false;
  username: string;
  token: string;
  userId: string;
  password: string;
  confirmPassword: string;

  constructor(private authenticationService: AuthService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
      this.userId = params['u'];
    });
  }

  closeModal() {
    this.error = undefined; // <- WHY
    this.success = undefined;
  }

  sendPasswordRecovery() {
    if (!this.username) {
      return;
    }

    this.loading = true;
    this.authenticationService.sendPasswordRecovery(this.username).subscribe(
      () => {
        this.onSendPasswordRecoverySuccess();
      },
      error => {
        this.loading = false;
        if (error.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          this.error = `An error occurred: ${error.error.message}`;
          console.error('An error occurred:', error.error.message);
        } else if (error.status === 400) {
          console.error(error);
          this.success =
            "We've sent a verification email with the instructions to reset your password to your email address";
        } else {
          console.error('An error occurred:');
          console.error(error);
          console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
        }
      }
    );
  }

  onSendPasswordRecoverySuccess() {
    this.loading = false;
    this.success = "We've sent a verification email with the instructions to reset your password to your email address";
  }

  setNewPassword() {
    this.loading = true;
    if (this.password === this.confirmPassword) {
      this.error = '';
      this.authenticationService.setNewPassword(this.userId, this.token, this.password).subscribe(
        () => {
          this.loading = false;
          this.success = 'Your password has been changed successfully';
        },
        error => {
          this.loading = false;
          this.error =
            // eslint-disable-next-line max-len
            "There's been an error while trying to update your password, or the token is incorrect, please try again later";
          if (error.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            this.error = `An error occurred: ${error.error.message}`;
            console.error('An error occurred:', error.error.message);
          } else if (error.status === 400) {
            console.error(error);
          } else {
            console.error('An error occurred:');
            console.error(error);
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          }
        }
      );
    } else {
      this.error = 'Passwords do not match';
    }
  }
}
