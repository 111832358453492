import { StreamCategory } from '../../_config/stream-category.enum';
import { SimulationVariable } from '../simulation-variable';
import { Case } from '../case';
import { BaseStream } from './base-stream';
import { BaseObject } from '../base-object';
import { UnitOperation } from '../unit-operation';
import { Quantity } from '../../_config/quantity.enum';
import { KpiProvider } from '../_case-study/kpi-provider';

declare let unitConverter: any;

export class FuelGasMaterialStream extends BaseStream implements KpiProvider {
  category = StreamCategory.FUEL_GAS_MATERIAL_STREAM;
  flowrate: SimulationVariable;
  heatFlow: SimulationVariable;
  lhv: SimulationVariable;

  composition: SimulationVariable[] = [];

  constructor(stream: FuelGasMaterialStream | any, ownerCase: Case) {
    super(stream.id, ownerCase);

    this.initValues(stream);
    this.setSimulationVariableNames();
  }

  getAvailableKpis() {
    if (this.ownerCase.multiPeriodEnabled) {
      return [];
    }
    this.setSimulationVariableNames();
    return [this.flowrate, this.heatFlow, this.lhv];
  }

  override initValues(stream: any | FuelGasMaterialStream) {
    super.initValues(stream);

    this.flowrate = new SimulationVariable(stream.flowrate, this.ownerCase, this);
    this.heatFlow = new SimulationVariable(stream.heatFlow, this.ownerCase, this);
    this.lhv = new SimulationVariable(stream.lhv, this.ownerCase, this);

    this.initComposition(stream);
    this.setQuantities();
  }

  private initComposition(stream: any | FuelGasMaterialStream) {
    if (this.composition.length) {
      return;
    }

    const composition = stream.composition || [];

    let compound = new SimulationVariable(composition[0] || {}, this.ownerCase, this);
    this.composition.push(compound);

    compound = new SimulationVariable(composition[1] || {}, this.ownerCase, this);
    this.composition.push(compound);

    compound = new SimulationVariable(composition[2] || {}, this.ownerCase, this);
    this.composition.push(compound);

    compound = new SimulationVariable(composition[3] || {}, this.ownerCase, this);
    this.composition.push(compound);

    compound = new SimulationVariable(composition[4] || {}, this.ownerCase, this);
    this.composition.push(compound);

    compound = new SimulationVariable(composition[5] || {}, this.ownerCase, this);
    this.composition.push(compound);

    compound = new SimulationVariable(composition[6] || {}, this.ownerCase, this);
    this.composition.push(compound);

    compound = new SimulationVariable(composition[7] || {}, this.ownerCase, this);
    this.composition.push(compound);

    compound = new SimulationVariable(composition[8] || {}, this.ownerCase, this);
    this.composition.push(compound);

    compound = new SimulationVariable(composition[9] || {}, this.ownerCase, this);
    this.composition.push(compound);

    compound = new SimulationVariable(composition[10] || {}, this.ownerCase, this);
    this.composition.push(compound);

    compound = new SimulationVariable(composition[11] || {}, this.ownerCase, this);
    this.composition.push(compound);

    compound = new SimulationVariable(composition[12] || {}, this.ownerCase, this);
    this.composition.push(compound);

    compound = new SimulationVariable(composition[13] || {}, this.ownerCase, this);
    this.composition.push(compound);
  }

  override setDefaultValues() {
    if (this.heatFlow.isUndefined()) {
      this.heatFlow.setDefaultValue(0.0, unitConverter.units.Energyrate.GJ_H);
    }
  }

  override setQuantities() {
    this.flowrate.setQuantity(Quantity.MOLARFLOWRATE);
    this.heatFlow.setQuantity(Quantity.ENERGYRATE);
    this.lhv.setQuantity(Quantity.ENERGY_PER_VOLUME);

    for (const sv of this.composition) {
      sv.setQuantity(Quantity.MOLE_FRACTION);
    }
  }

  override setSimulationVariableNames() {
    this.flowrate.setName('Flowrate');
    this.heatFlow.setName('Heat Flow');
    this.lhv.setName('LHV');
    this.setCompositionNames();
  }

  setCompositionNames(): void {
    const compoundNames = [
      'CO',
      'CO2',
      'H2',
      'N2',
      'H2S',
      'SO2',
      'Methane',
      'Ethane',
      'Propane',
      'iC4',
      'nC4',
      'iC5',
      'nC5',
      'C6+',
    ];

    for (let i = 0; i < this.composition.length; i++) {
      this.composition[i].name = compoundNames[i];
    }
  }

  override clearResults() {
    this.flowrate.value = undefined;
    this.heatFlow.value = undefined;
    this.lhv.value = undefined;

    for (const sv of this.composition) {
      sv.value = undefined;
    }
  }

  override getChildrenObjectsForRemoval(): BaseObject[] {
    const objs = super.getChildrenObjectsForRemoval();
    objs.push(...this.composition);
    return objs;
  }

  override getSimVars() {
    const simVars = super.getSimVars();
    for (const sv of this.composition) {
      simVars.push(sv);
    }

    return simVars;
  }

  hasFlowrate(): boolean {
    return this.flowrate.value > 1e-3;
  }

  protected override isPropertyBlackListed(property: any) {
    return property instanceof Case || typeof property === 'function' || property instanceof UnitOperation;
  }

  override dePersist(fgms: any) {
    this.flowrate = this.getSimulationVariableSafe(fgms.flowrate);
    this.heatFlow = this.getSimulationVariableSafe(fgms.heatFlow);
    this.lhv = this.getSimulationVariableSafe(fgms.lhv);

    if (fgms.composition instanceof Array) {
      for (let i = 0; i < fgms.composition.length; i++) {
        this.composition[i] = this.getSimulationVariableSafe(fgms.composition[i]);
      }
    }

    this.setQuantities();
  }

  public toJSON() {
    return {
      key: this.key,
      streamColor: this.streamColor,
      id: this.id,
      category: this.category,
      name: this.name,
      inletUnitOperationId: this.inletUnitOperationId,
      outletUnitOperationId: this.outletUnitOperationId,
      fromPort: this.fromPort,
      toPort: this.toPort,
      flowrate: this.flowrate.id,
      heatFlow: this.heatFlow.id,
      lhv: this.lhv.id,
      composition: this.composition.map(sv => sv.id),
      isRecycleStream: this.isRecycleStream,
    };
  }
}
