import { WaterUnitOperation } from './water-unit-operation';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { PressureCalculationMode } from '../../../_config/pressure-calculation-mode.enum';
import { SimulationVariable } from '../../simulation-variable';
import { Case } from '../../case';
import { Quantity } from '../../../_config/quantity.enum';
import { UnitOperationConstraints } from '../unit-operation-constraints';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';

declare let unitConverter: any;

export class WaterValve extends WaterUnitOperation implements KpiProvider {
  category = unitOperationsConfig.waterValve.key;
  pressureCalculationMode: PressureCalculationMode | string;
  pressureDrop: SimulationVariable;
  outletPressure: SimulationVariable;

  massCapacity: SimulationVariable;
  constraints: UnitOperationConstraints;

  constructor(unitOperation: any | WaterValve, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.pressureCalculationMode = unitOperation.pressureCalculationMode || '';
    this.pressureDrop = new SimulationVariable(unitOperation.pressureDrop, this.ownerCase, this);
    this.outletPressure = new SimulationVariable(unitOperation.outletPressure, this.ownerCase, this);

    this.massCapacity = new SimulationVariable(unitOperation.massCapacity, this.ownerCase, this);
    this.initConstraints();
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('massCapacity', this.massCapacity);
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.pressureDrop.setName(SimulationVariableName.PRESSURE_DROP);
    this.outletPressure.setName(SimulationVariableName.OUTLET_PRESSURE);
    this.massCapacity.setName(SimulationVariableName.MAXIMUM_FEED_CAPACITY);
  }

  override setQuantities() {
    super.setQuantities();
    this.pressureDrop.setQuantity(Quantity.DELTA_PRESSURE);
    this.outletPressure.setQuantity(Quantity.PRESSURE);

    this.massCapacity.setQuantity(Quantity.MASSFLOWRATE);
  }

  override setDefaultValues() {
    super.setDefaultValues();
    if (this.pressureCalculationMode === '') {
      this.pressureCalculationMode = PressureCalculationMode.PRESSURE_DROP;
    }

    if (this.massCapacity.isUndefined()) {
      this.massCapacity.setDefaultValue(null, unitConverter.units.Massflowrate.KPPH);
    }
  }

  getAvailableKpis(): SimulationVariable[] {
    return [this.outletPressure, this.pressureDrop];
  }

  override dePersist(unitOperation: any) {
    super.dePersist(unitOperation);
    this.pressureDrop = this.getSimulationVariableSafe(unitOperation.pressureDrop);
    this.outletPressure = this.getSimulationVariableSafe(unitOperation.outletPressure);
    this.massCapacity = this.getSimulationVariableSafe(unitOperation.massCapacity);
    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }
}
