import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { GhgEmissionsReport } from '../_models/_reports/ghg-emissions-report';
import { RefineryFlowRatesReport } from '../_models/_reports/refinery-flow-rates-report';
import { RevenueReport } from '../_models/_reports/revenue-report';
import { DiluentFlowRatesReport } from '../_models/_reports/diluent-flow-rates-report';
import { OptimizationVariablesReport } from '../_models/_reports/_optimization-settings-table/optimization-variables-report';
import { ConstraintsReport } from '../_models/_reports/_optimization-settings-table/constraints-report';
import { CapexReport } from '../_models/_reports/capex-report';
import { OpexReport } from '../_models/_reports/opex-report';
import { SubFlowsheetService } from './sub-flowsheet/sub-flowsheet.service';
import { ReportDataSet } from '../_models/_reports/report-data-set';
import { FlowsheetTreeService } from './sub-flowsheet/flowsheet-tree/flowsheet-tree.service';

@Injectable()
export class ReportService {
  constructor(private coreService: CoreService, private flowsheetTree: FlowsheetTreeService) {}

  buildGhgEmissionsReport(): GhgEmissionsReport {
    return new GhgEmissionsReport(this.coreService.currentCase);
  }

  buildUpgraderDataSet(upgraderId: string): ReportDataSet {
    return new GhgEmissionsReport(this.coreService.currentCase).getUpgraderDataSet(
      '',
      upgraderId,
      this.coreService.currentCase
    );
  }

  buildRefineryFlowRatesReport() {
    return new RefineryFlowRatesReport(this.coreService.currentCase);
  }

  buildRevenueReport() {
    return new RevenueReport(this.coreService.currentCase);
  }

  buildDiluentFlowRatesReport() {
    return new DiluentFlowRatesReport(this.coreService.currentCase);
  }

  buildCapexReport() {
    return new CapexReport(this.coreService.currentCase);
  }

  buildOpexReport() {
    return new OpexReport(this.coreService.currentCase);
  }

  buildOptimizationVariablesReport(activeFlowsheetId: string) {
    return new OptimizationVariablesReport(this.coreService.currentCase, activeFlowsheetId, this.flowsheetTree);
  }

  buildConstraintsReport(activeFlowsheetId: string) {
    return new ConstraintsReport(this.coreService.currentCase, activeFlowsheetId);
  }
}
