import { Mapper } from '../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../_models';
import { StringToSimulationVariableMapper } from './string-to-simulation-variable-mapper';
import { ThirdPartySource } from '../../../_models/_unit-operations/third-party-source';

export enum ThirdPartySourceExcelIndexes {
  VOLUMETRIC_FLOW_RATE = 4,
  GHG_INTENSITY = 5,
  COGEN_ELECTRICITY_GENERATION = 7,
  NET_ELECTRICITY_EXPORT = 8,
  COGEN_INTENSITY = 9,
  GRID_INTENSITY = 10,
  MASS_DENSITY = 14,
  CCR = 15,
  SULFUR = 16,
  NITROGEN = 17,
  LIGHT_ENDS = 20,
  NAPHTA = 21,
  DISTILLATE = 22,
  GAS_OIL = 23,
  VACUUM_RESIDUE = 24,
  PRICE = 27,
}

export class ArrayToThirdPartySourceMapper implements Mapper<string[], ThirdPartySource> {
  private readonly simulationVariableMapper: Mapper<string, SimulationVariable>;

  constructor() {
    this.simulationVariableMapper = new StringToSimulationVariableMapper();
  }

  map(source: string[], destination: ThirdPartySource) {
    this.simulationVariableMapper.map(
      source[ThirdPartySourceExcelIndexes.VOLUMETRIC_FLOW_RATE],
      destination.volumetricFlowrate
    );
    this.simulationVariableMapper.map(source[ThirdPartySourceExcelIndexes.GHG_INTENSITY], destination.ghgIntensity);
    this.simulationVariableMapper.map(source[ThirdPartySourceExcelIndexes.PRICE], destination.price);
  }
}
