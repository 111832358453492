import { AfterViewInit, ContentChild, ContentChildren, Directive, Input, QueryList } from '@angular/core';
import { ReactiveUnitSelectComponent } from './reactive-unit-select/reactive-unit-select.component';
import { ReactiveVariableValueInputComponent } from './reactive-variable-value-input/reactive-variable-value-input.component';

declare let unitConverter: any;
@Directive({
  selector: '[sobReactiveMultipleVariableInput]',
})
export class ReactiveMultipleVariableInputDirective implements AfterViewInit {
  @Input() totalOutputId: string;
  @ContentChild(ReactiveUnitSelectComponent) unitSelect!: ReactiveUnitSelectComponent;
  @ContentChildren(ReactiveVariableValueInputComponent, { descendants: true })
  valueInputs!: QueryList<ReactiveVariableValueInputComponent>;

  ngAfterViewInit(): void {
    this.unitSelect.unitChange.subscribe(newUnit => {
      this.valueInputs.forEach(valueInput => {
        valueInput.updateUnit(newUnit);
      });
      this.calculateTotal();
    });
  }

  calculateTotal() {
    let total = 0;

    this.valueInputs.forEach(vi => {
      const val = unitConverter.parseFloatString(vi.displayValue);

      if (!isNaN(val)) {
        total += val;
      }
    });

    total = parseFloat(total.toFixed(5));

    const $totalOutput = $(`#${this.totalOutputId}`);
    $totalOutput.val(total);
  }
}
