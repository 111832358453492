import { Mapper } from '../../../_models/_interfaces/mapper';
import { Upgrader } from '../../../_models/_unit-operations/upgrader';
import { SimulationVariable } from '../../../_models';
import { StringToSimulationVariableMapper } from './string-to-simulation-variable-mapper';

export enum UpgraderExcelIndexes {
  CCR_REMOVAL = 5,
  NITROGEN_REMOVAL = 6,
  SULFUR_REMOVAL = 7,
  SWEET_PRODUCT_RATIO = 8,
  SWEET_GHG_INTENSITY = 9,
  SOUR_GHG_INTENSITY = 10,
  CAPACITY = 11,
  COGEN_ELECTRICITY_GENERATION = 13,
  NET_ELECTRICITY_EXPORT = 14,
  COGEN_INTENSITY = 15,
  GRID_INTENSITY = 16,
  SWEET_PRODUCT_PRICE = 19,
  SOUR_PRODUCT_PRICE = 20,
  CAPEX_AMORTIZED = 21,
  OPEX_FIXED = 22,
  OPEX_VAR = 23,
  OPEX_GAS_DIESEL = 24,
  OPEX_POWER = 25,
}

export class ArrayToUpgraderMapper implements Mapper<string[], Upgrader> {
  private readonly simulationVariableMapper: Mapper<string, SimulationVariable>;

  constructor() {
    this.simulationVariableMapper = new StringToSimulationVariableMapper();
  }

  map(source: string[], destination: Upgrader) {
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.CCR_REMOVAL], destination.ccrRemoval);
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.NITROGEN_REMOVAL], destination.nitrogenRemoval);
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.SULFUR_REMOVAL], destination.sulfurRemoval);
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.SWEET_PRODUCT_RATIO], destination.sweetProductRatio);
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.SWEET_GHG_INTENSITY], destination.sweetGhgIntensity);
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.SOUR_GHG_INTENSITY], destination.sourGhgIntensity);
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.CAPACITY], destination.capacity);

    this.simulationVariableMapper.map(
      source[UpgraderExcelIndexes.COGEN_ELECTRICITY_GENERATION],
      destination.cogenElectricityGeneration
    );
    this.simulationVariableMapper.map(
      source[UpgraderExcelIndexes.NET_ELECTRICITY_EXPORT],
      destination.netElectricityExport
    );
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.COGEN_INTENSITY], destination.cogenIntensity);
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.GRID_INTENSITY], destination.gridIntensity);

    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.SWEET_PRODUCT_PRICE], destination.sweetProductPrice);
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.SOUR_PRODUCT_PRICE], destination.sourProductPrice);
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.CAPEX_AMORTIZED], destination.capexAmortized);
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.OPEX_FIXED], destination.opexFixed);
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.OPEX_VAR], destination.opexVar);
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.OPEX_GAS_DIESEL], destination.opexGasDiesel);
    this.simulationVariableMapper.map(source[UpgraderExcelIndexes.OPEX_POWER], destination.opexPower);
  }
}
