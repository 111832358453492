import { ConstraintValueChecker } from '../_interfaces/constraint-value-checker';
import { isEmptyValue, isNullOrUndefined } from '../../_utils/utils';

export class RangeDistributionConstraint implements ConstraintValueChecker {
  id: string;
  sourceUnitOperationId: string;
  constraintName: string;
  constraintValue: number;
  isActive: boolean;

  constructor(
    id: string,
    sourceUnitOperationId: string,
    constraintName: string,
    constraintValue: number,
    isActive?: boolean
  ) {
    this.id = id;
    this.sourceUnitOperationId = sourceUnitOperationId;
    this.constraintName = constraintName;
    this.constraintValue = constraintValue;
    this.isActive = isActive || false;
  }

  constraintValueDefined(): boolean {
    return (
      !isNullOrUndefined(this.constraintValue) &&
      !isEmptyValue(this.constraintValue) &&
      !Number.isNaN(this.constraintValue)
    );
  }

  constraintValueViolated(referenceValue: number): boolean {
    return false;
  }
}
