import { Injectable } from '@angular/core';
import { AbstractSubFlowsheetHandler } from './abstract-sub-flowsheet-handler';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { UnitOperation } from '../../_models';
import { FlowsheetDiagramService } from '../flowsheet-diagram/flowsheet-diagram.service';
import { CoreService } from '../core.service';
import { Extraction } from '../../_models/_unit-operations/extraction';

@Injectable()
export class ExtractionSubFlowsheetHandler extends AbstractSubFlowsheetHandler {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(flowsheetDiagramService: FlowsheetDiagramService, coreService: CoreService) {
    super(flowsheetDiagramService, coreService);
  }

  getDefaultNodeData(): any {
    return this.addInletTransferUnit(-600, -150, unitOperationsConfig.inletTransferUnit.displayName);
  }

  private addInletTransferUnit(x: number, y: number, name: string): any {
    const data = {
      category: unitOperationsConfig.inletTransferUnit.key,
      loc: '',
      name,
    };

    return this.addNewNodeOnDiagram(data, x, y);
  }

  getSubFlowsheetInlets(uoId: string): UnitOperation[] {
    return this.coreService.currentCase.filterUnitOperations((u: UnitOperation) => {
      return u.category === unitOperationsConfig.inletTransferUnit.key && u.flowsheetId === uoId;
    });
  }

  getSubFlowsheetOutlets(uoId: string): UnitOperation[] {
    return this.coreService.currentCase.filterUnitOperations((u: UnitOperation) => {
      return u.category === unitOperationsConfig.outletTransferUnit.key && u.flowsheetId === uoId;
    });
  }

  syncWhileFlowsheetLoading(): void {
    // yeah, nothing
  }

  syncAllBlocksInletOutletPorts(): void {
    const extractionBlocks = this.coreService.currentCase.filterUnitOperations((uo: Extraction) => {
      return uo.category === unitOperationsConfig.extraction.key && uo.useSubFlowsheet;
    });

    extractionBlocks.forEach(uo => {
      this.syncBlockInletOutletPorts(uo);
    });
  }
}
