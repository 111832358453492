import { SimulationVariable } from '../simulation-variable';
import { BaseObject } from '../base-object';

export class BoilingPointCut extends BaseObject {
  category = 'boilingPointCut';
  unitOperationId: string;
  values: SimulationVariable[] = [];
  ownerBaseObject: BaseObject;

  constructor(bpc, pc, ownerCase, owner) {
    super(bpc, ownerCase);
    this.ownerBaseObject = owner;
    this.unitOperationId = bpc.unitOperationId;
    const values = bpc.values || [];
    for (let idx = 0; idx < pc; idx++) {
      if (values[idx] instanceof SimulationVariable || typeof values[idx] === 'undefined') {
        this.values.push(new SimulationVariable(values[idx], this.ownerCase, this));
      }
    }
    if (values.length === 0) {
      this.addSimVarsToPool();
    }
    this.setQuantities();
  }

  initEmpty(length: number) {
    for (let i = 0; i < length; i++) {
      this.values.push(new SimulationVariable('', this.ownerCase, this));
    }
    this.addSimVarsToPool();
    this.setQuantities();
  }

  override overwriteValues(another: any) {
    super.overwriteValues(another);
    this.unitOperationId = another.unitOperationId;
    for (let i = 0; i < this.values.length; i++) {
      this.values[i].overwriteValues(another.values[i]);
    }
  }

  protected override ignoreForOverWrite(propertyName: string): boolean {
    return super.ignoreForOverWrite(propertyName) || this[propertyName] instanceof Array;
  }

  setQuantities() {
    for (const sv of this.values) {
      sv.setQuantity('Temperature');
    }
  }

  override convertToInternalUnit(): void {
    super.convertToInternalUnit();
    for (const simVar of this.values) {
      simVar.value = simVar.convertToInternalUnit();
    }
  }

  override addSimVarsToPool(): void {
    super.addSimVarsToPool();
    for (const sv of this.values) {
      this.ownerCase.addToPools(sv);
    }
  }

  override detectChanges(another) {
    let changes = super.detectChanges(another);
    for (let idx = 0; idx < another.values.length; idx++) {
      changes = changes || this.values[idx].detectValueChange(another.values[idx]);
    }
    return changes;
  }

  override dePersist(persistedObj: any) {
    for (let idx = 0; idx < persistedObj.values.length; idx++) {
      this.values.push(this.ownerCase.getSimulationVariable(persistedObj.values[idx]));
    }
    this.setSimulationVariablesOwner();
  }

  override setSimulationVariablesOwner(): void {
    for (const sv of this.values) {
      sv.setOwnerBaseObject(this);
    }
  }

  toJSON() {
    return {
      category: this.category,
      unitOperationId: this.unitOperationId,
      values: this.values.map(sv => sv.id),
    };
  }
}
