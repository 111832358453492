<div class="modal fade" id="caseListModal" tabindex="-1" role="dialog" aria-labelledby="casesListLabel">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>

        <h4 class="modal-title" id="">Available Cases</h4>
      </div>

      <div class="modal-body">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active">
            <a href="#clAvailableCases" aria-controls="clAvailableCases" role="tab" data-toggle="tab">
              Available Cases
            </a>
          </li>
        </ul>

        <div class="tab-content">
          <div class="tab-pane fade in active" id="clAvailableCases">
            <div class="row">
              <div class="col-xs-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="current">Current Case</th>
                      <th>Name</th>
                      <th class="actions">Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor="let c of project.cases">
                      <td class="text-center current-marker">
                        <ng-container
                          *ngIf="currentCase.id === c.id; then markAsCurrent; else notCurrent"></ng-container>

                        <ng-template #markAsCurrent>
                          <i class="fa fa-check-circle"></i>
                        </ng-template>

                        <ng-template #notCurrent>
                          <i class="fa fa-times-circle"></i>
                        </ng-template>
                      </td>

                      <td>
                        <input type="text" class="form-control" [(ngModel)]="c.name" />
                      </td>

                      <td>
                        <button
                          class="btn btn-sm btn-success m-r-xs"
                          (click)="setCurrentCase(c.id)"
                          [disabled]="currentCase.id === c.id">
                          Select
                        </button>

                        <button class="btn btn-sm btn-primary m-r-xs" (click)="openCloneCaseModal(c.id)">Clone</button>

                        <button
                          class="btn btn-sm btn-danger"
                          (click)="openDeleteCaseModal(c.id, c.name)"
                          [disabled]="currentCase.id === c.id">
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="cloneCaseModal" tabindex="-1" role="dialog" aria-labelledby="cloneCaseLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Clone Case</h4>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="newCaseName" class="col-xs-4">New Case Name</label>
          <div class="col-xs-8">
            <input type="text" id="newCaseName" class="form-control" [(ngModel)]="newCaseName" />
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-primary" (click)="cloneCase()">Clone</button>
        <button class="btn btn-default" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteCaseModal" tabindex="-1" role="dialog" aria-labelledby="deleteCaseLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Delete Case</h4>
      </div>

      <div class="modal-body">Delete {{ selectedCaseName }}?</div>

      <div class="modal-footer">
        <button class="btn btn-danger" (click)="deleteCase()">Delete</button>
        <button class="btn btn-default" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
