<div class="form-group">
  <label for="objectiveFunctionType" class="pw-label control-label col-sm-4"> Objective Function </label>

  <div class="col-sm-6">
    <div class="dropdown-wrapper">
      <select
        id="objectiveFunctionType"
        [(ngModel)]="objectiveFunction.objectiveFunctionType"
        (change)="objectiveFunctionChanged($event.target.value)"
        class="form-control">
        <option value="Cost">Maximize Value</option>
        <option value="GHG">Minimize GHG</option>
      </select>
    </div>
  </div>
</div>
