import { Injectable } from '@angular/core';
import * as go from 'gojs';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { GoJsDiagramUtils } from './go-js-diagram-utils';

@Injectable()
export class UtilitiesFlowsheetTemplateProvider {
  $: any = go.GraphObject.make;

  constructor(private gojsDiagramUtils: GoJsDiagramUtils) {}

  getWaterSourceTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'left', marginTop: 6 }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.waterSource.diagramIcon,
            }),
            this.gojsDiagramUtils.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getWaterBoilerTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(50, 40),
              margin: 5,
              source: unitOperationsConfig.waterBoiler.diagramIcon,
            }),
            this.gojsDiagramUtils.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              portId: 'in',
              toMaxLinks: 1,
              name: 'IN',
              width: 6.2,
              height: 6.2,
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getWaterCogenerationTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(43, 43),
              margin: 5,
              source: unitOperationsConfig.waterCogeneration.diagramIcon,
            }),
            this.gojsDiagramUtils.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              portId: 'in',
              toMaxLinks: 1,
              name: 'IN',
              width: 6.2,
              height: 6.2,
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.3),
              fromMaxLinks: 1,
              portId: 'steam',
              toolTip: 'Steam',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.7),
              fromMaxLinks: 1,
              portId: 'water',
              toolTip: 'Water',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getWaterSourceImportTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'left', marginTop: 6 }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(50, 40),
              margin: 5,
              source: unitOperationsConfig.waterSourceImport.diagramIcon,
            }),
            this.gojsDiagramUtils.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getWaterSinkImportTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          { width: 100 },
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              width: 50,
              name: 'ICON',
              desiredSize: new go.Size(55, 48),
              margin: 5,
              source: unitOperationsConfig.waterSinkImport.diagramIcon,
            }),
            this.gojsDiagramUtils.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0.5, 1),
              toSpot: go.Spot.BottomSide,
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.getCenterRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getHeatExchangerTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.waterHeatExchanger.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'tubeIn',
              toolTip: 'Tube In',
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0.5, 0),
              toMaxLinks: 1,
              portId: 'shellIn',
              toolTip: 'Shell In',
              toSpot: go.Spot.TopSide,
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'tubeOut',
              toolTip: 'Tube Out',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(0.5, 1),
              fromMaxLinks: 1,
              portId: 'shellOut',
              toolTip: 'Shell Out',
              fromSpot: go.Spot.BottomSide,
            }),
            this.gojsDiagramUtils.getAllSidesBusyReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getTurbineTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(50, 40),
              margin: 5,
              source: unitOperationsConfig.waterTurbine.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out 1',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getHeaterTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.waterHeater.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getCoolerTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.waterCooler.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getTwoPhaseSeparatorTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.waterTwoPhaseSeparator.diagramIcon,
            }),
            this.$(go.Shape, 'Circle', { width: 40, height: 40, fill: 'Transparent', stroke: null }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0.25, 0.3),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(0.54, 0),
              fromMaxLinks: 1,
              portId: 'vapor',
              toolTip: 'Vapor',
              fromSpot: go.Spot.TopSide,
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(0.54, 1),
              fromMaxLinks: 1,
              portId: 'liquid',
              toolTip: 'Liquid',
              fromSpot: go.Spot.BottomSide,
            }),
            this.gojsDiagramUtils.getCenterRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getPumpTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.waterPump.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getValveTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.waterValve.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getSinkTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 5,
              source: unitOperationsConfig.waterSink.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.getTopLeftReadinessIndicator()
          ),
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center', marginTop: 6 })
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getWaterMixerTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        // dragComputation: AvoidOverlapHelper.avoidNodeOverlap,
        linkConnected: (node, link, port) => {
          if (port.portId === 'in' && node.findLinksInto().count > 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');
            const outlet = node.findObject('OUT');

            const proportion = (icon.height + 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;

            outlet.width *= proportion;
            outlet.height *= proportion;
          }
        },

        linkDisconnected: (node, link, port) => {
          if (port.portId === 'in' && node.findLinksInto().count >= 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');
            const outlet = node.findObject('OUT');

            const proportion = (icon.height - 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;

            outlet.width *= proportion;
            outlet.height *= proportion;
          }
        },
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(22, 28),
              source: unitOperationsConfig.waterMixer.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              portId: 'in',
              name: 'IN',
              width: 4,
              height: 39,
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(0.88, 0.5),
              portId: 'out',
              name: 'OUT',
              fromMaxLinks: 1,
              width: 6,
              height: 4,
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopRightReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getWaterSplitterTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        linkConnected: (node, link, port) => {
          if (port.portId === 'out' && node.findLinksOutOf().count > 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');
            const outlet = node.findObject('OUT');

            const proportion = (icon.height + 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;

            outlet.width *= proportion;
            outlet.height *= proportion;
          }
        },

        linkDisconnected: (node, link, port) => {
          if (port.portId === 'out' && node.findLinksOutOf().count >= 2) {
            const icon = node.findObject('ICON');
            const inlet = node.findObject('IN');
            const outlet = node.findObject('OUT');

            const proportion = (icon.height - 5) / icon.height;
            icon.width *= proportion;
            icon.height *= proportion;

            inlet.width *= proportion;
            inlet.height *= proportion;

            outlet.width *= proportion;
            outlet.height *= proportion;
          }
        },
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.$(go.Panel, 'Horizontal', this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' })),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(22, 28),
              source: unitOperationsConfig.waterSplitter.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0.1, 0.5),
              portId: 'in',
              name: 'IN',
              height: 4,
              width: 4,
              toolTip: 'In',
              toMaxLinks: 1,
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              portId: 'out',
              name: 'OUT',
              width: 4,
              height: 39,
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getTopLeftReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  getWaterHeaderTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
        linkConnected: (node, link, port) => {
          this.onWaterHeaderLinkConnected(node, port);
        },

        linkDisconnected: (node, link, port) => {
          this.onWaterHeaderLinkDisconnected(node, port);
        },
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Horizontal',
          {
            name: 'ICON',
          },
          this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' }),
          this.$(
            go.Panel,
            'Horizontal',
            {
              margin: 10,
            },
            this.$(go.Picture, {
              name: 'HEAD',
              desiredSize: new go.Size(6, 33),
              source: '/assets/img/blocks/icons/waterHeaderEnds.svg',
            }),
            this.getWaterHeaderBody(),
            this.$(go.Picture, {
              name: 'TAIL',
              desiredSize: new go.Size(6, 33),
              source: '/assets/img/blocks/icons/waterHeaderEnds.svg',
            }),
            this.$(
              go.Panel,
              'Spot',
              this.$(go.Shape, 'Rectangle', { height: 33, width: 2, fill: 'Transparent', stroke: null }),
              this.gojsDiagramUtils.getTopRightReadinessIndicator()
            )
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }

  private getWaterHeaderBody() {
    return this.$(
      go.Panel,
      'Spot',
      {
        margin: new go.Margin(-0.8, 0, 0, 0),
      },
      this.$(
        go.Panel,
        'Horizontal',
        {
          name: 'BODY',
        },
        this.getWaterHeaderBodySegmentPicture(),
        this.getWaterHeaderBodySegmentPicture(),
        this.getWaterHeaderBodySegmentPicture(),
        this.getWaterHeaderBodySegmentPicture()
      ),
      // the inlet port
      this.$(
        go.Panel,
        'Auto',
        { alignment: new go.Spot(0.5, -0.018) },
        this.$(
          go.Shape,
          {
            width: 40,
            height: 4,
            margin: 0,
            portId: 'in',
            name: 'IN',
            fill: '#4972b0',
            stroke: 'Transparent',
            toLinkable: true,
            toSpot: go.Spot.TopSide,
          },
          {
            toolTip: this.gojsDiagramUtils.createPortTooltip('In'),
          },
          new go.Binding('height', 'height')
        )
      ),

      // the outlet port
      this.$(
        go.Panel,
        'Auto',
        { alignment: new go.Spot(0.5, 0.95) },
        this.$(
          go.Shape,
          {
            width: 40,
            height: 4,
            margin: 0,
            portId: 'out',
            name: 'OUT',
            fill: '#4972b0',
            stroke: 'Transparent',
            fromLinkable: true,
            fromSpot: go.Spot.BottomSide,
          },
          {
            toolTip: this.gojsDiagramUtils.createPortTooltip('Out'),
          },
          new go.Binding('height', 'height')
        )
      )
    );
  }

  private getWaterHeaderBodySegmentPicture() {
    return this.$(go.Picture, {
      desiredSize: new go.Size(20, 22),
      margin: new go.Margin(0, -1, 0, 0),
      source: '/assets/img/blocks/icons/waterHeaderBodySegment.svg',
    });
  }

  private onWaterHeaderLinkConnected(node: go.Node, port: go.Part) {
    let increaseSize =
      port.portId === 'in'
        ? node.findLinksInto().count > node.findLinksOutOf().count
        : node.findLinksOutOf().count > node.findLinksInto().count;

    increaseSize = increaseSize && (node.findLinksInto().count > 1 || node.findLinksOutOf().count > 1);

    if (!increaseSize) {
      return;
    }

    const inlet = node.findObject('IN');
    const outlet = node.findObject('OUT');

    inlet.width += 19;
    outlet.width += 19;

    const body = node.findObject('BODY') as go.Part;
    body.add(this.getWaterHeaderBodySegmentPicture());
  }

  private onWaterHeaderLinkDisconnected(node, port) {
    let shouldDecreaseSize =
      port.portId === 'in'
        ? node.findLinksInto().count >= node.findLinksOutOf().count
        : node.findLinksOutOf().count >= node.findLinksInto().count;

    shouldDecreaseSize = shouldDecreaseSize && node.findLinksInto().count >= 1 && node.findLinksOutOf().count >= 1;

    if (!shouldDecreaseSize) {
      return;
    }

    const inlet = node.findObject('IN');
    const outlet = node.findObject('OUT');

    inlet.width -= 19;
    outlet.width -= 19;

    const body = node.findObject('BODY');
    body.removeAt(0);
  }

  getWaterPipeTemplate() {
    return this.$(
      go.Node,
      'Spot',
      {
        selectionAdornmentTemplate: this.gojsDiagramUtils.createNodeSelectionTemplate(),
        locationObjectName: 'ICON',
      },
      this.$(
        go.Panel,
        'Auto',
        this.gojsDiagramUtils.getNodeTransparentBackground(),
        this.$(
          go.Panel,
          'Vertical',
          this.$(go.Panel, 'Horizontal', this.gojsDiagramUtils.getNameTextBlockTemplate({ textAlign: 'center' })),
          this.$(
            go.Panel,
            'Spot',
            this.$(go.Picture, {
              name: 'ICON',
              desiredSize: new go.Size(40, 40),
              margin: 0,
              source: unitOperationsConfig.waterPipe.diagramIcon,
            }),
            this.gojsDiagramUtils.createInletPort({
              alignment: new go.Spot(0, 0.5),
              toMaxLinks: 1,
              portId: 'in',
              toolTip: 'In',
            }),
            this.gojsDiagramUtils.createOutletPort({
              alignment: new go.Spot(1, 0.5),
              fromMaxLinks: 1,
              portId: 'out',
              toolTip: 'Out',
            }),
            this.gojsDiagramUtils.getBottomCenterReadinessIndicator()
          )
        )
      ),
      new go.Binding('location', 'location', this.gojsDiagramUtils.toLocation).makeTwoWay(),
      this.gojsDiagramUtils.getDeletableBinding(),
      this.gojsDiagramUtils.getClickEventHandlers(),
      { toolTip: this.gojsDiagramUtils.createNodeTooltip() }
    );
  }
}
