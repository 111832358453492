import { Completion } from '@codemirror/autocomplete';
import { DefaultCodeProvider } from './default-code-provider';
import { DEFAULT_HYDROCRACKER_MODEL } from '../../_config/unit-operations/default-models/default-hydrocracker-model';
import { hydrocrackerCodeCompletions } from '../../_config/unit-operations/custom-code-completions/hydrocracker-code-completions';

export class HydrocrackerCodeProvider extends DefaultCodeProvider {
  constructor() {
    super(DEFAULT_HYDROCRACKER_MODEL);
  }

  override getCodeCompletions(): Completion[] {
    return super.getCodeCompletions().concat(hydrocrackerCodeCompletions);
  }
}
