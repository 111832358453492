import { WaterUnitOperation } from './water-unit-operation';
import { PressureCalculationMode } from '../../../_config/pressure-calculation-mode.enum';
import { SimulationVariable } from '../../simulation-variable';
import { SteamType } from '../../../_config/steam-type.enum';
import { WaterSourceInformationStream } from './water-source-information-stream';
import { Case } from '../../case';
import { isTypeUndefined } from '../../../_utils/utils';
import { Quantity } from '../../../_config/quantity.enum';
import { BaseObject } from '../../base-object';
import { WaterMaterialStream } from '../../_streams/water-material-stream';
import { KpiProvider } from '../../_case-study/kpi-provider';

export abstract class BaseWaterImportUnitOperation extends WaterUnitOperation implements KpiProvider {
  pressureCalculationMode: PressureCalculationMode;
  outletPressure: SimulationVariable;
  steamType: SteamType;
  inletSourceInformationStreams: WaterSourceInformationStream[];

  constructor(unitOperation: BaseWaterImportUnitOperation | any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: BaseWaterImportUnitOperation | any) {
    super.initValues(unitOperation);

    this.steamType = unitOperation.steamType || SteamType.Steam900;
    this.inletSourceInformationStreams = [];

    if (
      !isTypeUndefined(unitOperation.inletSourceInformationStreams) &&
      unitOperation.inletSourceInformationStreams instanceof Array
    ) {
      // could be an array of strings
      const informationStream = unitOperation.inletSourceInformationStreams.length
        ? unitOperation.inletSourceInformationStreams[0]
        : undefined;

      // the copies and the original will share the reference to WaterSourceInformationStreams array
      // as the copies only make sense for property windows that use template-driven forms
      if (informationStream instanceof WaterSourceInformationStream) {
        this.inletSourceInformationStreams = unitOperation.inletSourceInformationStreams;
      }
    }

    this.pressureCalculationMode = unitOperation.pressureCalculationMode || PressureCalculationMode.LOWEST;
    this.outletPressure = new SimulationVariable(unitOperation.outletPressure, this.ownerCase, this);
  }

  override setQuantities() {
    super.setQuantities();
    this.outletPressure.setQuantity(Quantity.PRESSURE);
  }

  addInformationStream(informationStream: WaterSourceInformationStream) {
    const index = this.inletSourceInformationStreams.findIndex(is => is.id === informationStream.id);
    if (index > -1) {
      return;
    }

    informationStream.providerUnitOperationId = informationStream.getSimulationVariable().ownerBaseObject.id;

    if (!informationStream.providerUnitOperationId) {
      throw new Error('Unable to set providerUnitOperationId for information Stream');
    }

    this.inletSourceInformationStreams.push(informationStream);
    this.ownerCase.addToPools(informationStream);
    informationStream.addSimVarsToPool();
  }

  removeInformationStream(simulationVariableId: string) {
    const index = this.inletSourceInformationStreams.findIndex(i => i.simulationVariableId === simulationVariableId);
    if (index === -1) {
      return;
    }

    const informationStream = this.inletSourceInformationStreams.splice(index, 1)[0];
    this.ownerCase.removeFromPools(informationStream);
  }

  removeInformationStreamsByProviderUnitOperationId(providerUnitOperationId: string) {
    const streamsToRemove = this.inletSourceInformationStreams.filter(
      s => s.providerUnitOperationId === providerUnitOperationId
    );

    for (const informationStream of streamsToRemove) {
      this.removeInformationStream(informationStream.simulationVariableId);
    }
  }

  override getChildrenObjectsForRemoval(): BaseObject[] {
    const objs = super.getChildrenObjectsForRemoval();
    objs.push(...this.inletSourceInformationStreams);

    return objs;
  }

  getStreamComingInToTheUnitOp() {
    return this.ownerCase.getStreamsByOutletUnitOperationId(this.id) as WaterMaterialStream[];
  }

  getStreamComingOutOfTheUnitOp() {
    return this.ownerCase.getStreamsByInletUnitOperationId(this.id) as WaterMaterialStream[];
  }

  getAvailableKpis() {
    if (!this.ownerCase.multiPeriodEnabled) {
      return [
        this.getStreamComingOutOfTheUnitOp()[0].massFlowrate,
        this.getStreamComingOutOfTheUnitOp()[0].temperature,
        this.getStreamComingOutOfTheUnitOp()[0].pressure,
      ];
    }
    return [];
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.outletPressure = this.getSimulationVariableSafe(sup.outletPressure);
  }

  dePersistInformationStreams(persistedObj: any) {
    // TODO this is temporary...
    if (!persistedObj.inletSourceInformationStreams) {
      return;
    }

    persistedObj.inletSourceInformationStreams.forEach(isId => {
      this.inletSourceInformationStreams.push(this.ownerCase.getOtherBaseObject(isId) as WaterSourceInformationStream);
    });
  }

  protected override isPropertyBlackListed(property: any): boolean {
    return super.isPropertyBlackListed(property) || property === this.inletSourceInformationStreams;
  }

  override toJSON(): any {
    const plainObject = super.toJSON();
    plainObject.inletSourceInformationStreams = this.inletSourceInformationStreams.map(i => i.id);

    return plainObject;
  }
}
