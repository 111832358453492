import { Completion } from '@codemirror/autocomplete';
import { DefaultCodeProvider } from './default-code-provider';
import { defaultDCUModel } from '../../_config/unit-operations/default-models/default-dcu-model';
import { dcuCodeCompletions } from '../../_config/unit-operations/custom-code-completions/dcu-code-completions';

export class DcuCodeProvider extends DefaultCodeProvider {
  constructor() {
    super(defaultDCUModel);
  }

  override getCodeCompletions(): Completion[] {
    return super.getCodeCompletions().concat(dcuCodeCompletions);
  }
}
