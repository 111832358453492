import { UnitOperation } from '../../unit-operation';
import { IUnitOperation } from '../../i-unit-operation';

export abstract class WaterUnitOperation extends UnitOperation implements IUnitOperation {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override initValues(unitOperation: any) {}

  override setSimulationVariableNames() {}

  override setDefaultValues(): void {}

  override setQuantities() {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override dePersist(unitOperation: any) {
    this.setQuantities();
  }
}
