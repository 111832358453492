import { WaterUnitOperation } from './water-unit-operation';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { SimulationVariable } from '../../simulation-variable';
import { Case } from '../../case';
import { Quantity } from '../../../_config/quantity.enum';
import { UnitOperationConstraints } from '../unit-operation-constraints';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { VariableStatus } from '../../../_config/variable-status.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';
import { ParameterProvider } from '../../_case-study/parameter-provider';

declare let unitConverter: any;

export class WaterBoiler extends WaterUnitOperation implements KpiProvider, ParameterProvider {
  category = unitOperationsConfig.waterBoiler.key;
  outletTemperature: SimulationVariable;
  outletPressure: SimulationVariable;
  outletVaporFraction: SimulationVariable;
  power: SimulationVariable;

  massCapacity: SimulationVariable;
  minimumMassFlowrate: SimulationVariable;
  constraints: UnitOperationConstraints;
  fuelGasEmissionFactor: SimulationVariable;
  fuelGasHeatingValue: SimulationVariable;
  ghgEmissions: SimulationVariable;

  constructor(unitOperation: any | WaterBoiler, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.outletTemperature = new SimulationVariable(unitOperation.outletTemperature, this.ownerCase, this);
    this.outletPressure = new SimulationVariable(unitOperation.outletPressure, this.ownerCase, this);
    this.outletVaporFraction = new SimulationVariable(unitOperation.outletVaporFraction, this.ownerCase, this);
    this.power = new SimulationVariable(unitOperation.power, this.ownerCase, this);
    this.fuelGasEmissionFactor = new SimulationVariable(
      unitOperation.fuelGasEmissionFactor || {},
      this.ownerCase,
      this
    );
    this.fuelGasHeatingValue = new SimulationVariable(unitOperation.fuelGasHeatingValue || {}, this.ownerCase, this);
    this.ghgEmissions = new SimulationVariable(unitOperation.ghgEmissions || {}, this.ownerCase, this);

    this.massCapacity = new SimulationVariable(unitOperation.massCapacity, this.ownerCase, this);
    this.minimumMassFlowrate = new SimulationVariable(unitOperation.minimumMassFlowrate, this.ownerCase, this);

    this.setQuantities(); // it is ok here?
    this.initConstraints();
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('massCapacity', this.massCapacity);
    this.constraints.addConstraint('minimumMassFlowrate', this.minimumMassFlowrate);
  }

  override clearResults() {
    super.clearResults();
    if (this.outletTemperature.isSolverCalculated()) {
      this.outletTemperature.value = undefined;
    }

    if (this.outletPressure.isSolverCalculated()) {
      this.outletPressure.value = undefined;
    }

    if (this.power.isSolverCalculated()) {
      this.power.value = undefined;
    }

    if (this.outletVaporFraction.isSolverCalculated()) {
      this.outletVaporFraction.value = undefined;
    }

    if (this.fuelGasHeatingValue.isSolverCalculated()) {
      this.fuelGasHeatingValue.variableStatus = VariableStatus.UNDEFINED;
      this.setDefaultValues();
    }

    if (this.fuelGasEmissionFactor.isSolverCalculated()) {
      this.fuelGasEmissionFactor.variableStatus = VariableStatus.UNDEFINED;
      this.setDefaultValues();
    }
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.outletPressure.setName(SimulationVariableName.OUTLET_PRESSURE);
    this.outletTemperature.setName(SimulationVariableName.OUTLET_TEMPERATURE);
    this.outletVaporFraction.setName(SimulationVariableName.OUTLET_VAPOR_FRACTION);
    this.power.setName(SimulationVariableName.POWER);
    this.fuelGasEmissionFactor.setName(SimulationVariableName.FUEL_GAS_EMISSION_FACTOR);
    this.fuelGasHeatingValue.setName(SimulationVariableName.FUEL_GAS_HEATING_VALUE);
    this.ghgEmissions.setName(SimulationVariableName.GHG_EMISSIONS);

    this.massCapacity.setName(SimulationVariableName.MAXIMUM_FEED_CAPACITY);
    this.minimumMassFlowrate.setName(SimulationVariableName.MINIMUM_MASS_FLOWRATE);
  }

  override setQuantities() {
    super.setQuantities();
    this.outletPressure.setQuantity(Quantity.PRESSURE);
    this.outletTemperature.setQuantity(Quantity.TEMPERATURE);
    this.outletVaporFraction.setQuantity(Quantity.FRACTION);
    this.power.setQuantity(Quantity.POWER);

    this.massCapacity.setQuantity(Quantity.MASSFLOWRATE);
    this.minimumMassFlowrate.setQuantity(Quantity.MASSFLOWRATE);

    this.ghgEmissions.setQuantity(Quantity.MASSFLOWRATE);
    this.fuelGasEmissionFactor.setQuantity(Quantity.MASS_EMISSIONS_PER_VOLUME);
    this.fuelGasHeatingValue.setQuantity(Quantity.ENERGY_PER_VOLUME);
  }

  override setDefaultValues() {
    if (this.massCapacity.isUndefined()) {
      this.massCapacity.setDefaultValue(null, unitConverter.units.Massflowrate.KPPH);
    }

    if (this.minimumMassFlowrate.isUndefined()) {
      this.minimumMassFlowrate.setDefaultValue(null, unitConverter.units.Massflowrate.KPPH);
    }

    if (this.fuelGasEmissionFactor.isUndefined()) {
      this.fuelGasEmissionFactor.setDefaultValue(0.0702, unitConverter.units.MassEmissionsPerVolume.KG_SCF);
    }

    if (this.fuelGasHeatingValue.isUndefined()) {
      this.fuelGasHeatingValue.setDefaultValue(1088.3, unitConverter.units.EnergyPerVolume.BTU_SCF);
    }

    if (this.ghgEmissions.isUndefined()) {
      this.ghgEmissions.setDefaultValue(0.0, unitConverter.units.Massflowrate.MMTPA);
    }
    super.setDefaultValues();
  }

  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [this.outletVaporFraction, this.outletTemperature, this.outletPressure, this.power, this.ghgEmissions];
    }
    return [
      this.outletVaporFraction,
      this.outletTemperature,
      this.outletPressure,
      this.power,
      this.fuelGasEmissionFactor,
      this.fuelGasHeatingValue,
      this.ghgEmissions,
    ];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.outletTemperature, this.outletPressure, this.outletVaporFraction];
  }

  getAvailableParameters() {
    return undefined;
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.outletTemperature = this.getSimulationVariableSafe(sup.outletTemperature);
    this.outletPressure = this.getSimulationVariableSafe(sup.outletPressure);
    this.outletVaporFraction = this.getSimulationVariableSafe(sup.outletVaporFraction);
    this.power = this.getSimulationVariableSafe(sup.power);
    this.massCapacity = this.getSimulationVariableSafe(sup.massCapacity);
    this.minimumMassFlowrate = this.getSimulationVariableSafe(sup.minimumMassFlowrate);
    this.ghgEmissions = this.getSimulationVariableSafe(sup.ghgEmissions);
    this.fuelGasEmissionFactor = this.getSimulationVariableSafe(sup.fuelGasEmissionFactor);
    this.fuelGasHeatingValue = this.getSimulationVariableSafe(sup.fuelGasHeatingValue);
    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }
}
