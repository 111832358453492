import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../shared/user';

@Injectable()
export class UserService {
  private currentUser: User;

  constructor(private http: HttpClient) {
    this.setCurrentUser();
  }

  signUp(user: any) {
    return this.http.post(`${environment.accountUrl}/signup`, user);
  }

  getUser(id?) {
    if (!id) {
      id = this.getCurrentUserId();
    }
    return this.http.get<User>(`${environment.usersUrl}/${id}`);
  }

  getCurrentUser() {
    const currentUser = localStorage.getItem('user');
    if (currentUser) {
      const u = JSON.parse(currentUser);
      if (!this.currentUser) {
        this.currentUser = u;
      }
      return u;
    }
    return undefined;
  }

  getCurrentUserId() {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      const u = JSON.parse(currentUser);
      return u.id;
    }
    return undefined;
  }

  getCurrentUserFullName() {
    if (this.getCurrentUser()) {
      return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
    }
    return '';
  }

  private setCurrentUser() {
    const id = this.getCurrentUserId();

    if (id) {
      this.getUser(id).subscribe(
        u => {
          this.currentUser = u;
        },
        () => {
          this.currentUser = undefined;
        }
      );
    }
  }

  get currentUsername(): string {
    if (this.getCurrentUser()) {
      return this.getCurrentUser().userName;
    }

    return this.getCurrentUserId();
  }
}
