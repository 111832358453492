import * as go from 'gojs';
import { Injectable } from '@angular/core';
import { unitOperationsConfig } from '../../_config/unit-operations.config';

@Injectable()
export class FirstLevelPaletteModelProvider {
  $ = go.GraphObject.make;
  iconHeight: 55;
  iconWidth: 92;

  getSourcesModel() {
    return [
      {
        name: unitOperationsConfig.sagd.displayName,
        icon: unitOperationsConfig.sagd.paletteIcon,
        diagramIcon: unitOperationsConfig.sagd.diagramIcon,
        category: unitOperationsConfig.sagd.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.mine.displayName,
        icon: unitOperationsConfig.mine.paletteIcon,
        diagramIcon: unitOperationsConfig.mine.diagramIcon,
        category: unitOperationsConfig.mine.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.diluentSource.displayName,
        icon: unitOperationsConfig.diluentSource.paletteIcon,
        diagramIcon: unitOperationsConfig.diluentSource.diagramIcon,
        category: unitOperationsConfig.diluentSource.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.thirdPartySource.displayName,
        icon: unitOperationsConfig.thirdPartySource.paletteIcon,
        diagramIcon: unitOperationsConfig.thirdPartySource.diagramIcon,
        category: unitOperationsConfig.thirdPartySource.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.offshore.displayName,
        icon: unitOperationsConfig.offshore.paletteIcon,
        diagramIcon: unitOperationsConfig.offshore.diagramIcon,
        category: unitOperationsConfig.offshore.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.suncorRenewableBlock.displayName,
        icon: unitOperationsConfig.suncorRenewableBlock.paletteIcon,
        diagramIcon: unitOperationsConfig.suncorRenewableBlock.diagramIcon,
        category: unitOperationsConfig.suncorRenewableBlock.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
    ];
  }

  getProcessingModel() {
    return [
      {
        name: unitOperationsConfig.upgrader.displayName,
        icon: unitOperationsConfig.upgrader.paletteIcon,
        diagramIcon: unitOperationsConfig.upgrader.diagramIcon,
        category: unitOperationsConfig.upgrader.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.bitumenConversion.displayName,
        icon: unitOperationsConfig.bitumenConversion.paletteIcon,
        diagramIcon: unitOperationsConfig.bitumenConversion.diagramIcon,
        category: unitOperationsConfig.bitumenConversion.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.refinery.displayName,
        icon: unitOperationsConfig.refinery.paletteIcon,
        diagramIcon: unitOperationsConfig.refinery.diagramIcon,
        category: unitOperationsConfig.refinery.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.thirdPartyRefinery.displayName,
        icon: unitOperationsConfig.thirdPartyRefinery.paletteIcon,
        diagramIcon: unitOperationsConfig.thirdPartyRefinery.diagramIcon,
        category: unitOperationsConfig.thirdPartyRefinery.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.extraction.displayName,
        icon: unitOperationsConfig.extraction.paletteIcon,
        diagramIcon: unitOperationsConfig.extraction.diagramIcon,
        category: unitOperationsConfig.extraction.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
    ];
  }

  getTransportModel() {
    return [
      {
        name: unitOperationsConfig.pipe.displayName,
        icon: unitOperationsConfig.pipe.paletteIcon,
        diagramIcon: unitOperationsConfig.pipe.diagramIcon,
        category: unitOperationsConfig.pipe.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
    ];
  }

  getConnectorsModel() {
    return [
      {
        name: unitOperationsConfig.mixer.displayName,
        icon: unitOperationsConfig.mixer.paletteIcon,
        diagramIcon: unitOperationsConfig.mixer.diagramIcon,
        category: unitOperationsConfig.mixer.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
      {
        name: unitOperationsConfig.splitter.displayName,
        icon: unitOperationsConfig.splitter.paletteIcon,
        diagramIcon: unitOperationsConfig.splitter.diagramIcon,
        category: unitOperationsConfig.splitter.key,
        height: this.iconHeight,
        width: this.iconWidth,
      },
    ];
  }
}
