import { WaterTurbine } from '../../../_unit-operations/utilties/water-turbine';
import { SimulationVariable } from '../../../simulation-variable';
import { SimulationVariableName } from '../../../../_config/simulation-variable-name.enum';
import { ConstraintReportVariable } from '../constraint-report-variable';

export class WaterTurbineConstraintReportVariableResolver {
  static create() {
    return new WaterTurbineConstraintReportVariableResolver();
  }

  addCurrentValueAndUnitToConstraintReportVariable(
    crv: ConstraintReportVariable,
    params: { uo: WaterTurbine; simVar: SimulationVariable }
  ): void {
    const { uo, simVar } = params;

    if (simVar.name === SimulationVariableName.MINIMUM_DUTY) {
      crv.currentValue = uo.dutyResult.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.dutyResult.getDefaultUnit();
      return;
    }

    if (simVar.name === SimulationVariableName.MAXIMUM_DUTY) {
      crv.currentValue = uo.dutyResult.convertToDefaultUnit(true, false);
      crv.currentUnit = uo.dutyResult.getDefaultUnit();
      return;
    }

    const inletStreamList = uo.ownerCase.filterWaterMaterialStreams(s => {
      return s.outletUnitOperationId === uo.id;
    });

    if (!inletStreamList.length) {
      return;
    }

    if (simVar.name === SimulationVariableName.MAXIMUM_FEED_CAPACITY) {
      crv.currentValue = inletStreamList[0].massFlowrate.convertToDefaultUnit(true, false);
      crv.currentUnit = inletStreamList[0].massFlowrate.getDefaultUnit();
      return;
    }
    if (simVar.name === SimulationVariableName.MINIMUM_MASS_FLOWRATE) {
      crv.currentValue = inletStreamList[0].massFlowrate.convertToDefaultUnit(true, false);
      crv.currentUnit = inletStreamList[0].massFlowrate.getDefaultUnit();
      return;
    }

    throw new Error(`Unknown constraint: ${simVar.name}`);
  }
}
