import { DataType } from '@grapecity/wijmo';
import { AbstractConstraintsHandler } from './abstract-constraints-handler';
import { BaseConstraintsSpreadsheetModel } from '../../_models/constraints-spreadsheet/base-constraints-spreadsheet-model';
import { FlexSheetColumn } from '../../_models/wijmo/flex-sheet-column';
import { UnitOperation } from '../../_models';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { CoreService } from '../core.service';
import { FlowsheetTreeService } from '../sub-flowsheet/flowsheet-tree/flowsheet-tree.service';
import { NumberToUnitConverter } from '../number-to-unit-converter.service';
import { BitumenConversion } from '../../_models/_unit-operations/bitumen-conversion';
import { Splitter } from '../../_models/_unit-operations/splitter';
import { Mixer } from '../../_models/_unit-operations/mixer';
import { Extraction } from '../../_models/_unit-operations/extraction';
import { isEmptyValue } from '../../_utils/utils';

export class ExtractionConstraintsSheetHandler extends AbstractConstraintsHandler {
  protected readonly SHEET_INDEX: number;

  constructor(
    coreService: CoreService,
    flowsheetTreeService: FlowsheetTreeService,
    private nuc: NumberToUnitConverter
  ) {
    super(coreService, flowsheetTreeService);
  }

  protected isUnitOperationAllowed(
    unitOperation: UnitOperation
  ): unitOperation is BitumenConversion | Splitter | Mixer {
    const flowsheet = this.coreService.currentCase.getFlowsheet(unitOperation.flowsheetId);
    if (isEmptyValue(flowsheet)) return false;

    const flowsheetOwner = this.coreService.currentCase.getUnitOperation(flowsheet.unitOperationId);
    if (flowsheetOwner instanceof Extraction) {
      return (
        unitOperation instanceof BitumenConversion ||
        unitOperation instanceof Splitter ||
        unitOperation instanceof Mixer
      );
    }

    return false;
  }

  override clearResults(): void {
    this.grid.collectionView.refresh();
  }

  private getUnitOperation(item: BaseConstraintsSpreadsheetModel): SuncorUnitOperation {
    const unitOperation = this.coreService.currentCase.getUnitOperation(item.unitOperationId) as SuncorUnitOperation;

    if (this.isUnitOperationAllowed(unitOperation)) {
      return unitOperation;
    }

    throw new Error(`A non-allowed unit operation was detected ${unitOperation.category} - ${unitOperation.name}`);
  }

  assignValueToUnitOperation(valueAsNumber: number, item: BaseConstraintsSpreadsheetModel): SuncorUnitOperation {
    const uo = this.getUnitOperation(item);
    if (this.isMixingConstraintsUser(uo)) {
      this.assignNewValuesForRangeConstraints(uo, item, valueAsNumber);
    } else {
      uo.getSimulationVariableSafe(item.id).value = this.nuc.convertDefaultToInternalUnit(
        valueAsNumber,
        uo.getSimulationVariableSafe(item.id).quantity
      );
    }

    return uo;
  }

  deleteUnitOperationValue(item: BaseConstraintsSpreadsheetModel): SuncorUnitOperation {
    const uo = this.getUnitOperation(item);

    if (this.isMixingConstraintsUser(uo)) {
      this.assignNewValuesForRangeConstraints(uo, item, null);
    } else {
      uo.getSimulationVariableSafe(item.id).value = null;
    }

    return uo;
  }

  protected getColumnsConfiguration(): FlexSheetColumn[] {
    return [
      {
        header: 'Active',
        binding: 'isActive',
        dataType: DataType.Boolean,
        width: 120,
        isReadOnly: false,
      },
      {
        header: 'Unit Name',
        binding: 'unitOperationName',
        dataType: DataType.String,
        width: 200,
        isReadOnly: true,
      },
      {
        header: 'Constraint',
        binding: 'constraint',
        dataType: DataType.String,
        width: 200,
        isReadOnly: true,
      },
      {
        header: 'Constraint Value',
        binding: 'constraintValue',
        dataType: DataType.Number,
        width: 120,
      },
      {
        header: 'Current Value',
        binding: 'currentValue',
        dataType: DataType.Number,
        width: 120,
        isReadOnly: true,
      },
      {
        header: 'Unit',
        binding: 'unit',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: 'Flowsheet Owner',
        binding: 'flowsheetOwner',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
      {
        header: '',
        binding: '',
        dataType: DataType.String,
        width: 120,
        isReadOnly: true,
      },
    ];
  }
}
