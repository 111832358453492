import { BaseStream } from '../_streams/base-stream';

export abstract class GojsBaseStream {
  category: string;
  id: string;
  linkKey: number;
  from: number;
  to: number;
  fromPort: string;
  toPort: string;
  name: string;
  connectionName: string;
  streamColor: string;
  dashedLine: boolean;

  protected constructor(s: BaseStream) {
    this.id = s.id;
    this.linkKey = s.key;
    this.from = s.inletUnitOperation.key;
    this.to = s.outletUnitOperation.key;
    this.fromPort = s.fromPort;
    this.toPort = s.toPort;
    this.name = s.name || s.connectionName;
    this.connectionName = s.connectionName;
    this.streamColor = s.streamColor;
  }
}
