import { Case } from '../../_models';
import { ServerUtcDateService } from '../helpers/server-utc-date.service';
import { MultiPeriodSettings } from '../../_models/_multi-period/multi-period-settings';

declare let unitConverter: any;

export class MultiperiodResultsExcelReport {
  static dataArray = [];

  static generateResultsArray(c: Case) {
    this.dataArray = [['Day']];
    const multiPerdiod = c.multiPeriodSettings;

    this.generateMultiperiodParametersDataArray(multiPerdiod);
    this.generateMultiperiodResultsDataArray(c, multiPerdiod);

    return this.dataArray;
  }

  static generateCaseDataArray(c: Case, utc: ServerUtcDateService) {
    const dataArray = [['User name', 'Creation Date', 'Name of the Case']];
    const currentDate = utc.getCurrentDate();
    dataArray.push([this.getUserName(), this.formatDate(currentDate), c.name]);
    return dataArray;
  }

  private static generateMultiperiodParametersDataArray(m: MultiPeriodSettings) {
    for (let i = 0; i < m.numberOfSteps; i++) {
      this.dataArray.push([(i + 1).toString()]);
    }

    for (let i = 0; i < m.multiPeriodParameters.length; i++) {
      const parameter = m.multiPeriodParameters[i];
      const simVar = parameter.simulationVariable;
      this.dataArray[0][i + 1] = `${parameter.getFullName()} (${parameter.getDefaultUnit()})`;

      for (let j = 0; j < parameter.multiPeriodValues.length; j++) {
        const parameterValue = parameter.multiPeriodValues[j];
        const conversion = unitConverter(simVar.quantity).convert(
          parameterValue,
          simVar.getInternalUnit(),
          simVar.getDefaultUnit()
        );
        const roundedValue = conversion.round();
        this.dataArray[j + 1][i + 1] = roundedValue.getValue().toLocaleString();
      }
    }
  }

  private static generateMultiperiodResultsDataArray(c: Case, m: MultiPeriodSettings) {
    const selectedKpis = this.noNullSelectedKpis(c);
    const index = this.dataArray[0].length;

    for (let i = 0; i < selectedKpis.length; i++) {
      const kpi = selectedKpis[i];
      this.dataArray[0][index + i] = `${kpi.getFullName()} (${kpi.getDefaultUnit()})`;

      const kpiResults = m.multiPeriodResults.map(arrayValues => {
        return arrayValues[kpi.id];
      });

      for (let j = 0; j < kpiResults.length; j++) {
        const kpiValue = kpiResults[j];
        const conversion = unitConverter(kpi.quantity).convert(kpiValue, kpi.getInternalUnit(), kpi.getDefaultUnit());
        const roundedValue = conversion.round();
        this.dataArray[j + 1][index + i] = roundedValue.getValue().toLocaleString();
      }
    }
  }

  private static formatDate(date) {
    const padTo2Digits = num => num.toString().padStart(2, '0');
    return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/');
  }

  private static getUserName() {
    const serializedUser = localStorage.getItem('user');
    return JSON.parse(serializedUser || '{}').userName;
  }

  private static noNullSelectedKpis(c: Case) {
    return c.caseStudyManager.kpiManager.selectedKpis.filter(kpi => {
      return kpi.value !== 0;
    });
  }
}
