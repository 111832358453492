import { Component, Input, OnInit } from '@angular/core';
import { PressureCalculationMode } from '../../../_config/pressure-calculation-mode.enum';
import { WaterPump } from '../../../_models/_unit-operations/utilties/water-pump';

@Component({
  selector: 'sob-water-pump',
  templateUrl: './water-pump.component.html',
  styleUrls: ['./water-pump.component.css'],
})
export class WaterPumpComponent implements OnInit {
  @Input() unitOperation: WaterPump;

  ngOnInit() {
    if (this.unitOperation.pressureCalculationMode === PressureCalculationMode.OUTLET_PRESSURE) {
      $('#selectMode').val(PressureCalculationMode.OUTLET_PRESSURE);
      $('#pressure-gain').hide();
      $('#outlet-pressure').show();
      $('#Duty').hide();
    } else if (this.unitOperation.pressureCalculationMode === PressureCalculationMode.DUTY) {
      $('#selectMode').val(PressureCalculationMode.DUTY);
      $('#Duty').show();
      $('#pressure-gain').hide();
      $('#outlet-pressure').hide();
    } else if (this.unitOperation.pressureCalculationMode === PressureCalculationMode.PRESSURE_GAIN) {
      $('#outlet-pressure').hide();
      $('#Duty').hide();
    }
  }

  pressureCalculationModeChanged(calculationValue) {
    if (calculationValue === PressureCalculationMode.OUTLET_PRESSURE) {
      this.unitOperation.pressureCalculationMode = PressureCalculationMode.OUTLET_PRESSURE;
      $('#pressure-gain').hide();
      $('#outlet-pressure').show();
      $('#Duty').hide();
    } else if (calculationValue === PressureCalculationMode.DUTY) {
      this.unitOperation.pressureCalculationMode = PressureCalculationMode.DUTY;
      $('#Duty').show();
      $('#pressure-gain').hide();
      $('#outlet-pressure').hide();
    } else if (calculationValue === PressureCalculationMode.PRESSURE_GAIN) {
      this.unitOperation.pressureCalculationMode = PressureCalculationMode.PRESSURE_GAIN;
      $('#pressure-gain').show();
      $('#outlet-pressure').hide();
      $('#Duty').hide();
    }
  }
}
