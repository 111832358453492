import { Injectable } from '@angular/core';
import { hasNumericValue, isNullOrUndefined } from '../_utils/utils';
import { SimulationVariable } from '../_models';

@Injectable({
  providedIn: 'root',
})
export class SimulationVariableMapper {
  map(source: SimulationVariable | any, destination: SimulationVariable) {
    if (source.id) {
      destination.id = source.id;
    }

    if (source.quantity) {
      destination.quantity = source.quantity;
    }

    if (hasNumericValue(source.value)) {
      destination.value = +source.value;
    } else {
      destination.value = undefined;
    }

    if (source.unit) {
      destination.unit = source.unit;
    }

    if (source.status) {
      destination.variableStatus = source.variableStatus;
    }

    if (!isNullOrUndefined(source.isActive)) {
      destination.isActive = !!source.isActive;
    }
  }
}
