<div class="form-horizontal">
  <div class="form-group">
    <label for="typeDD" class="pw-label control-label col-sm-4"> Assay Name </label>
    <div class="col-sm-6" id="typeDD">
      <sob-assay-selector
        [fluidAnalyses]="fluidAnalyses"
        [currentAnalysis]="currentAnalysis"
        (updateCurrentAssay)="updateCurrentAnalysis($event)"></sob-assay-selector>
    </div>
  </div>
</div>
<hr />
<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
  </li>

  <li *ngIf="currentAnalysis" role="presentation">
    <a href="#fluidCharacterization" aria-controls="fluidCharacterization" role="tab" data-toggle="tab">Fluid</a>
  </li>

  <li role="presentation">
    <a href="#economics" aria-controls="economics" role="tab" data-toggle="tab">Economics</a>
  </li>

  <li role="presentation">
    <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab">Constraints</a>
  </li>

  <li role="presentation">
    <a href="#sagdSourceComments" aria-controls="sagdSourceComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"></i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label [attr.for]="'volumetricFlowrate'" class="pw-label control-label col-sm-4"> Volumetric Flowrate </label>
          <sim-var-input [inputId]="'volumetricFlowrate'" [simVar]="unitOperation.volumetricFlowrate"></sim-var-input>
        </div>

        <hr />

        <h4 class="subtitle">GHG</h4>
        <div class="form-group">
          <label [attr.for]="'ghgIntensity'" class="pw-label control-label col-sm-4"> GHG Intensity </label>
          <sim-var-input [inputId]="'ghgIntensity'" [simVar]="unitOperation.ghgIntensity"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'absoluteGhgEmissions'" class="pw-label control-label col-sm-4">
            Absolute GHG Emissions
          </label>
          <sim-var-input
            [inputId]="'absoluteGhgEmissions'"
            [readOnly]="true"
            [simVar]="unitOperation.absoluteGhgEmissions"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'ghgEmissions'" class="pw-label control-label col-sm-4"> GHG Emissions </label>
          <sim-var-input
            [inputId]="'ghgEmissions'"
            [simVar]="unitOperation.ghgEmissions"
            [readOnly]="true"></sim-var-input>
        </div>

        <hr />

        <h4 class="subtitle">Cogen</h4>

        <div class="form-group">
          <label [attr.for]="'cogenElectricityGeneration'" class="pw-label control-label col-sm-4">
            Total Cogen Electricity
          </label>
          <sim-var-input
            [inputId]="'cogenElectricityGeneration'"
            [simVar]="unitOperation.cogenElectricityGeneration"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'netElectricityExport'" class="pw-label control-label col-sm-4">
            Net Electricity Export
          </label>
          <sim-var-input
            [inputId]="'netElectricityExport'"
            [simVar]="unitOperation.netElectricityExport"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'cogenIntensity'" class="pw-label control-label col-sm-4"> Cogen Intensity </label>
          <sim-var-input [inputId]="'cogenIntensity'" [simVar]="unitOperation.cogenIntensity"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'gridIntensity'" class="pw-label control-label col-sm-4"> Grid Intensity </label>
          <sim-var-input [inputId]="'gridIntensity'" [simVar]="unitOperation.gridIntensity"></sim-var-input>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentAnalysis" role="tabpanel" class="tab-pane fade in" id="fluidCharacterization">
    <ul class="nav nav-pills" role="tablist" style="margin-right: 15px">
      <li role="presentation" class="active">
        <a href="#bulkProperties" aria-controls="bulkProperties" role="tab" data-toggle="tab">Bulk Properties</a>
      </li>

      <li role="presentation">
        <a href="#cutProperties" aria-controls="cutProperties" role="tab" data-toggle="tab">Cut Properties</a>
      </li>
      <li role="presentation">
        <a href="#compositionUO" aria-controls="composition" role="tab" data-toggle="tab">Composition</a>
      </li>
    </ul>
    <div class="tab-content pw-margin tab-content-padding">
      <div role="tabpanel" class="tab-pane fade in active" id="bulkProperties">
        <div class="form-horizontal">
          <div class="form-group">
            <label [attr.for]="'fluidAnalysisMassDensity'" class="pw-label control-label col-sm-4">
              Mass Density
            </label>
            <sim-var-input
              [inputId]="'fluidAnalysisMassDensity'"
              [readOnly]="true"
              [simVar]="currentAnalysis.massDensity"></sim-var-input>
          </div>
          <div class="form-group">
            <label [attr.for]="'fluidAnalysisCcr'" class="pw-label control-label col-sm-4"> CCR </label>
            <sim-var-input
              [inputId]="'fluidAnalysisCcr'"
              [readOnly]="true"
              [simVar]="currentAnalysis.ccr"></sim-var-input>
          </div>
          <div class="form-group">
            <label [attr.for]="'fluidAnalysisSulfur'" class="pw-label control-label col-sm-4"> Sulfur </label>
            <sim-var-input
              [inputId]="'fluidAnalysisSulfur'"
              [readOnly]="true"
              [simVar]="currentAnalysis.sulfur"></sim-var-input>
          </div>
          <div class="form-group">
            <label [attr.for]="'fluidAnalysisNitrogen'" class="pw-label control-label col-sm-4"> Nitrogen </label>
            <sim-var-input
              [inputId]="'fluidAnalysisNitrogen'"
              [readOnly]="true"
              [simVar]="currentAnalysis.nitrogen"></sim-var-input>
          </div>
        </div>
      </div>
      <div role="tabpanel" class="tab-pane fade in" id="cutProperties">
        <sob-cut-properties [readonly]="true" [fluidAnalysis]="currentAnalysis"></sob-cut-properties>
      </div>
      <div role="tabpanel" class="tab-pane fade in" id="compositionUO">
        <div class="form-horizontal" sfeMultipleSimVarInput totalOutputId="compositionTotalUO">
          <div class="form-group">
            <label [attr.for]="'compositionUnit'" class="pw-label control-label col-sm-4"> Unit </label>

            <div class="col-sm-4">
              <sfe-unit-select
                [selectId]="'compositionUnit' + unitOperation.id"
                [simVar]="currentAnalysis.composition[0]"
                [disabled]="true"
                [elClass]="'form-control'"></sfe-unit-select>
            </div>
          </div>

          <ng-container *ngFor="let idx of currentAnalysis.composition | keys">
            <div class="form-group">
              <label [attr.for]="'composition' + idx" class="pw-label control-label col-sm-4">
                {{ currentAnalysis.composition[idx].name | titleCaseComposition }}
              </label>
              <div class="col-sm-4">
                <sfe-value-input
                  [simVar]="currentAnalysis.composition[idx]"
                  [elClass]="'form-control'"
                  [inputId]="'composition' + idx"
                  [multiple]="true"
                  [readOnlyValue]="true"
                  [disabled]="true"
                  [convertedBy]="'compositionUnit'">
                </sfe-value-input>
              </div>
            </div>
          </ng-container>

          <div class="form-group">
            <label class="pw-label control-label col-sm-4">Total</label>
            <div class="col-sm-4">
              <input type="text" class="form-control" id="compositionTotalUO" readonly />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="economics">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label [attr.for]="'price'" class="pw-label control-label col-sm-4"> Product Price </label>
          <sim-var-input [inputId]="'price'" [simVar]="unitOperation.price"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'electricityPrice'" class="pw-label control-label col-sm-4"> Electricity Price </label>
          <sim-var-input [inputId]="'electricityPrice'" [simVar]="unitOperation.electricityPrice"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'revenue'" class="pw-label control-label col-sm-4"> Revenue </label>
          <sim-var-input [inputId]="'revenue'" [simVar]="unitOperation.revenue" [readOnly]="true"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'unitValue'" class="pw-label control-label col-sm-4"> Before Tax Cash Flow </label>
          <sim-var-input [inputId]="'unitValue'" [simVar]="unitOperation.unitValue" [readOnly]="true"></sim-var-input>
        </div>
        <hr />
        <h4 class="subtitle">OPEX</h4>

        <div class="form-group">
          <label [attr.for]="'opexVar'" class="pw-label control-label col-sm-4"> Variable </label>
          <sim-var-input [inputId]="'opexVar'" [simVar]="unitOperation.opexVar"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexGasDiesel'" class="pw-label control-label col-sm-4"> Gas Diesel </label>
          <sim-var-input [inputId]="'opexGasDiesel'" [simVar]="unitOperation.opexGasDiesel"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexPower'" class="pw-label control-label col-sm-4"> Power </label>
          <sim-var-input [inputId]="'opexPower'" [simVar]="unitOperation.opexPower"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexCarbonDioxide'" class="pw-label control-label col-sm-4"> CO2 </label>
          <sim-var-input
            [inputId]="'opexCarbonDioxide'"
            [simVar]="unitOperation.opexCarbonDioxide"
            [readOnly]="true"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexFixed'" class="pw-label control-label col-sm-4"> Fixed </label>
          <sim-var-input [inputId]="'opexFixed'" [simVar]="unitOperation.opexFixed"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexTotal'" class="pw-label control-label col-sm-4"> Total </label>
          <sim-var-input [inputId]="'opexTotal'" [simVar]="unitOperation.opexTotal" [readOnly]="true"></sim-var-input>
        </div>

        <hr />
        <h4 class="subtitle">CAPEX</h4>

        <div class="form-group">
          <label [attr.for]="'capexAmortized'" class="pw-label control-label col-sm-4"> Amortized CAPEX </label>
          <sim-var-input [inputId]="'capexAmortized'" [simVar]="unitOperation.capexAmortized"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'capexTotal'" class="pw-label control-label col-sm-4"> Total </label>
          <sim-var-input [inputId]="'capexTotal'" [simVar]="unitOperation.capexTotal" [readOnly]="true"></sim-var-input>
        </div>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="constraints">
    <div class="tab-content no-padding no-border">
      <p style="text-align: right; position: relative">
        <span style="position: absolute; right: -25px; top: -20px; font-weight: bold">Active</span>
      </p>
      <div class="form-horizontal">
        <div class="form-group">
          <label [attr.for]="'capacity'" class="pw-label control-label col-sm-4"> Maximum Flow Rate </label>
          <sim-var-input [inputId]="'capacity'" [isConstraint]="true" [simVar]="unitOperation.capacity"></sim-var-input>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="sagdSourceComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
