import * as wjcSheet from '@grapecity/wijmo.grid.sheet';
import * as wjCore from '@grapecity/wijmo.grid';
import { isNumber } from '@grapecity/wijmo';
import { FlexSheetColumnTracker } from '../flex-sheet-column-tracker';
import { SuncorUnitOperation } from '../../../_models/_unit-operations/suncor-unit-operation';
import { Pipe } from '../../../_models/_unit-operations/pipe';
import { SimulationVariable, UnitOperation } from '../../../_models';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { GasFluidAnalysis } from '../../../_models/_fluid/gas-fluid-analysis';

export abstract class AbstractSheetHandler {
  abstract SHEET_INDEX;

  protected columnTracker: FlexSheetColumnTracker;
  protected spreadsheet: wjcSheet.FlexSheet;

  clear() {
    this.spreadsheet.sheets[this.SHEET_INDEX].grid.columns.splice(1, this.columnTracker.columnCount());

    this.columnTracker.clear();
  }

  init() {
    const labels = this.getLabels();
    this.arrayToSpreadsheetColumn(labels, 0);
    this.spreadsheet.sheets[this.SHEET_INDEX].grid.columns[0].width = 240;
    this.spreadsheet.frozenColumns = 1;
  }

  protected abstract getLabels(): string[];

  abstract isUnitOperationAllowed(
    unitOperation: SuncorUnitOperation | UnitOperation | SuncorFluidAnalysis | GasFluidAnalysis
  ): boolean;

  addUnitOperation(unitOperation: SuncorUnitOperation | UnitOperation) {
    this.columnTracker.addUnitOperation(unitOperation);

    const column = this.spreadsheet.sheets[this.SHEET_INDEX].grid.columns.length - 1;
    this.spreadsheet.sheets[this.SHEET_INDEX].grid.columns.insert(column, new wjCore.Column());

    this.setData(unitOperation);
  }

  addAssay(assay: SuncorFluidAnalysis | GasFluidAnalysis) {
    this.columnTracker.addAssay(assay);

    const column = this.spreadsheet.sheets[this.SHEET_INDEX].grid.columns.length - 1;
    this.spreadsheet.sheets[this.SHEET_INDEX].grid.columns.insert(column, new wjCore.Column());

    this.setData(assay);
  }

  updateUnitOperation(unitOperation: SuncorUnitOperation) {
    this.setData(unitOperation as Pipe);
  }

  updateAssay(assay: SuncorFluidAnalysis | GasFluidAnalysis) {
    this.setData(assay);
  }

  removeUnitOperation(unitOperation: SuncorUnitOperation) {
    const unitOperationColumn = this.columnTracker.findColumnIndex(unitOperation);

    if (unitOperationColumn > -1) {
      this.spreadsheet.sheets[this.SHEET_INDEX].grid.columns.removeAt(unitOperationColumn);
      this.columnTracker.removeUnitOperation(unitOperation);
    }
  }

  removeAssay(assay: SuncorFluidAnalysis | GasFluidAnalysis) {
    const assayColumn = this.columnTracker.findAssayColumnIndex(assay);

    if (assayColumn > -1) {
      this.spreadsheet.sheets[this.SHEET_INDEX].grid.columns.removeAt(assayColumn);
      this.columnTracker.removeAssay(assay);
    }
  }

  protected abstract setData(
    unitOperation: SuncorUnitOperation | UnitOperation | SuncorFluidAnalysis | GasFluidAnalysis
  );

  protected formatSimulationVariable(simulationVariable: SimulationVariable, unit?: string) {
    if (unit) {
      return this.formatValue(simulationVariable.convertToAnotherUnit(unit));
    }
    return this.formatValue(simulationVariable.convertToDefaultUnit());
  }

  private formatValue(value: number): number | undefined {
    if (isNumber(value)) {
      return parseFloat(value.toFixed(10));
    }

    return undefined;
  }

  protected arrayToSpreadsheetColumn(dataArray: any[], column: number) {
    for (let row = 0; row < dataArray.length; row++) {
      this.spreadsheet.sheets[this.SHEET_INDEX].grid.setCellData(row, column, dataArray[row]);
    }
  }

  clearColumn(columnIndex: number) {
    this.spreadsheet.columns.setAt(columnIndex, new wjCore.Column());
    // this.spreadsheet.columns.removeAt(columnIndex);
  }
}
