<div>
  <h3><b>Time-Dependent Setup</b></h3>
  <br />
</div>
<div class="row" id="parameterSelector">
  <div class="col-md-4">
    <div class="form-group">
      <span><strong>Multiperiod Category</strong></span>
      <select class="form-control" size="2" (change)="categoryChanged($event)">
        <option *ngFor="let category of multiPeriodSettings.categories" value="{{ category.categoryName }}">
          {{ category.categoryName }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <span><strong>Multiperiod Parameter Provider</strong></span>
      <select class="form-control" size="2" (change)="multiPeriodProviderChanged($event)">
        <option
          *ngFor="let multiPeriodProvider of multiPeriodProvidersForCategory | alphanumericSort"
          value="{{ multiPeriodProvider.id }}">
          {{ multiPeriodProvider.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group" style="margin-bottom: 2px">
      <span><strong>Available Multiperiod Parameters</strong></span>
      <select class="form-control" [(ngModel)]="availableMultiPeriodParameterId" size="2">
        <option
          *ngFor="let multiPeriodParameter of availableMultiPeriodParameters | alphanumericSort"
          [value]="multiPeriodParameter.id">
          {{ multiPeriodParameter.name }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <button
        class="btn btn-xs btn-success"
        (click)="addMultiPeriodParameter()"
        data-toggle="tooltip"
        title="Add Selected Parameter">
        <i class="fa fa-plus-circle"></i>
      </button>
    </div>
  </div>
</div>
<hr />
<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-xs-12">
        <strong>Selected Parameters</strong>
      </div>
    </div>

    <div id="selectedMultiPeriodParametersWrapper">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>Variable Name</th>
            <th>Unit</th>
            <th>Current Value</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let parameter of multiPeriodSettings.multiPeriodParameters">
            <td><input type="checkbox" [attr.data-parameter]="parameter.id" /></td>
            <td>{{ parameter.simulationVariable.getFullName() }}</td>
            <td>{{ parameter.simulationVariable.unit }}</td>
            <td>{{ parameter.simulationVariable.convertToCaptureUnit() }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <hr style="margin-top: 5px; margin-bottom: 5px" />

    <button
      class="btn btn-xs btn-danger"
      (click)="removeMultiPeriodParameter()"
      data-toggle="tooltip"
      title="Remove selected multi period parameters">
      <i class="fa fa-minus-circle"></i>
    </button>
  </div>
</div>
