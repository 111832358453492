import { FlexSheetMapper } from './flex-sheet-mapper';
import { MineSource } from '../../../_models/_unit-operations/mine-source';
import { Mapper } from '../../../_models/_interfaces/mapper';
import { SimulationVariable } from '../../../_models';
import { NumberToSimulationVariableMapper } from './number-to-simulation-variable-mapper';
import { MineSourceSheetRows } from '../cell-config/mine-source-cell-config';

export class MineSourceFlexSheetMapper implements FlexSheetMapper {
  private simulationVariableMapper: Mapper<number, SimulationVariable>;

  constructor(simulationVariableMapper: NumberToSimulationVariableMapper) {
    this.simulationVariableMapper = simulationVariableMapper;
  }

  map(unitOperation: MineSource, row: number, value: string | number) {
    if (typeof value === 'string' && row === MineSourceSheetRows.NAME) {
      unitOperation.name = value;
    }

    if (typeof value === 'number') {
      switch (row) {
        case MineSourceSheetRows.VOLUMETRIC_FLOW_RATE:
          this.simulationVariableMapper.map(value, unitOperation.volumetricFlowrate);
          break;

        case MineSourceSheetRows.GHG_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.ghgIntensity);
          break;

        case MineSourceSheetRows.COGEN_ELECTRICITY_GENERATION:
          this.simulationVariableMapper.map(value, unitOperation.cogenElectricityGeneration);
          break;

        case MineSourceSheetRows.NET_ELECTRICITY_EXPORT:
          this.simulationVariableMapper.map(value, unitOperation.netElectricityExport);
          break;

        case MineSourceSheetRows.COGEN_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.cogenIntensity);
          break;

        case MineSourceSheetRows.GRID_INTENSITY:
          this.simulationVariableMapper.map(value, unitOperation.gridIntensity);
          break;

        case MineSourceSheetRows.PRICE:
          this.simulationVariableMapper.map(value, unitOperation.price);
          break;

        case MineSourceSheetRows.CAPEX_AMORTIZED:
          this.simulationVariableMapper.map(value, unitOperation.capexAmortized);
          break;

        case MineSourceSheetRows.OPEX_FIXED:
          this.simulationVariableMapper.map(value, unitOperation.opexFixed);
          break;

        case MineSourceSheetRows.OPEX_VAR:
          this.simulationVariableMapper.map(value, unitOperation.opexVar);
          break;

        case MineSourceSheetRows.OPEX_GAS_DIESEL:
          this.simulationVariableMapper.map(value, unitOperation.opexGasDiesel);
          break;

        case MineSourceSheetRows.OPEX_POWER:
          this.simulationVariableMapper.map(value, unitOperation.opexPower);
          break;
      }
    }
  }
}
