import * as wjcCore from '@grapecity/wijmo';

import { FlexSheetCellConfig } from './flex-sheet-cell-config';

export enum PipeSheetRows {
  NAME = 0,
  PIPE_LENGTH = 3,
  PRESSURE_DROP = 4,
  GHG_INTENSITY_PER_MASS_DISTANCE = 5,
  PIPE_DENSITY_SPEC = 6,
  CAPACITY = 7,
  OPEX_FIXED = 10,
  OPEX_VAR = 11,
}

export const PipeCellConfig: FlexSheetCellConfig[] = [
  {
    row: PipeSheetRows.NAME,
    dataType: wjcCore.DataType.String,
    format: '',
  },
  {
    row: PipeSheetRows.PIPE_LENGTH,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: PipeSheetRows.PRESSURE_DROP,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: PipeSheetRows.GHG_INTENSITY_PER_MASS_DISTANCE,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: PipeSheetRows.PIPE_DENSITY_SPEC,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: PipeSheetRows.CAPACITY,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: PipeSheetRows.OPEX_FIXED,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
  {
    row: PipeSheetRows.OPEX_VAR,
    dataType: wjcCore.DataType.Number,
    format: '',
  },
];
