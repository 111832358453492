import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { WaterCogeneration } from '../../../_models/_unit-operations/utilties/water-cogeneration';
import { DefaultCodeProvider } from '../../../_services/custom-code-providers/default-code-provider';
import { DEFAULT_WATER_COGENERATION_MODEL } from '../../../_config/unit-operations/default-models/default-water-cogeneration-model';

@Component({
  selector: 'sob-water-cogeneration',
  templateUrl: './water-cogeneration.component.html',
  styleUrls: ['./water-cogeneration.component.css'],
})
export class WaterCogenerationComponent implements OnInit {
  @Input() unitOperation: WaterCogeneration;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;
  defaultCodeProvider: DefaultCodeProvider;

  constructor(private fb: UntypedFormBuilder, private svfb: SimVarFormBuilder) {
    this.defaultCodeProvider = new DefaultCodeProvider(DEFAULT_WATER_COGENERATION_MODEL);
  }

  ngOnInit(): void {
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl('heatRate', this.svfb.control(this.unitOperation.heatRate));
    this.propertyWindowFormGroup.addControl('power', this.svfb.control(this.unitOperation.power));
    this.propertyWindowFormGroup.addControl('quality', this.svfb.control(this.unitOperation.quality));

    this.propertyWindowFormGroup.addControl(
      'fuelGasEmissionFactor',
      this.svfb.control(this.unitOperation.fuelGasEmissionFactor)
    );
    this.propertyWindowFormGroup.addControl(
      'fuelGasHeatingValue',
      this.svfb.control(this.unitOperation.fuelGasHeatingValue)
    );
    this.propertyWindowFormGroup.addControl('ghgEmissions', this.svfb.control(this.unitOperation.ghgEmissions, true));

    this.propertyWindowFormGroup.addControl(
      'otsgEnergyDemand',
      this.svfb.control(this.unitOperation.otsgEnergyDemand, true)
    );
    this.propertyWindowFormGroup.addControl(
      'turbineEnergyDemand',
      this.svfb.control(this.unitOperation.turbineEnergyDemand, true)
    );
    this.propertyWindowFormGroup.addControl(
      'totalEnergyDemand',
      this.svfb.control(this.unitOperation.totalEnergyDemand, true)
    );

    this.propertyWindowFormGroup.addControl('useCustomModel', this.fb.control(this.unitOperation.useCustomModel));
    this.propertyWindowFormGroup.addControl('hrsgScript', this.fb.control(this.unitOperation.hrsgScript));

    this.propertyWindowFormGroup.addControl(
      'massCapacity',
      this.svfb.control(this.unitOperation.massCapacity, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'minimumMassFlowrate',
      this.svfb.control(this.unitOperation.minimumMassFlowrate, false, false, true)
    );
  }

  saveCustomModel(res: any) {
    if (res.instanceId === 'cogenerationCodeEditor') {
      this.propertyWindowFormGroup.get('hrsgScript').setValue(res.code);
      this.unitOperation.hrsgScript = res.code;
    }
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }

  get useCustomModel(): boolean {
    return !!this.propertyWindowFormGroup.get('useCustomModel').value;
  }
}
