import { Injectable } from '@angular/core';
import * as go from 'gojs';
import { LayeredDigraphLayout, Link, GraphObject, Shape, Binding } from 'gojs';

function getAutoHeightForNode(node) {
  let heightIn = 0;
  let it = node.findLinksInto();
  while (it.next()) {
    const link = it.value;
    heightIn += link.computeThickness();
  }
  let heightOut = 0;
  it = node.findLinksOutOf();
  while (it.next()) {
    const link = it.value;
    heightOut += link.computeThickness();
  }
  let h = Math.max(heightIn, heightOut);
  if (h < 10) {
    h = 10;
  }
  return h;
}

function getTextFontSize(node: any, height: number) {
  let fontSize;

  if (node.linksConnected.count >= 3) {
    fontSize = Math.max(60, Math.round(height / 10));
  } else {
    fontSize = Math.max(20, Math.round(height / 5));
    if (fontSize > height) {
      fontSize = height;
    }
  }

  return fontSize;
}

@Injectable({
  providedIn: 'root',
})
export class SankeyDiagramTemplateProviderService {
  $: any = go.GraphObject.make;

  getDiagramTemplate() {
    return this.$(
      go.Diagram,
      'sankey-diagram', // the ID of the DIV HTML element
      {
        initialAutoScale: go.Diagram.UniformToFill,
        'animationManager.isEnabled': false,
        'toolManager.hoverDelay': 60,
      }
    );
  }

  getNodeTemplate() {
    return this.$(
      go.Node,
      go.Panel.Horizontal,
      {
        locationObjectName: 'SHAPE',
        locationSpot: go.Spot.MiddleLeft,
        portSpreading: go.Node.SpreadingPacked, // rather than the default go.Node.SpreadingEvenly
      },
      this.$(go.TextBlock, this.textStyle(), { name: 'LTEXT' }, new go.Binding('text', 'name')),
      this.$(
        go.Shape,
        {
          name: 'SHAPE',
          figure: 'Rectangle',
          fill: '#2E8DEF', // default fill color
          stroke: null,
          strokeWidth: 0,
          portId: '',
          fromSpot: go.Spot.RightSide,
          toSpot: go.Spot.LeftSide,
          height: 50,
          width: 20,
        },
        new go.Binding('fill', 'color')
      ),
      this.$(go.TextBlock, this.textStyle(), { name: 'TEXT' }, new go.Binding('name'))
    );
  }

  getLinkTemplate() {
    return GraphObject.make(
      Link,
      Link.Bezier,
      {
        selectionAdornmentTemplate: this.getLinkSelectionAdornmentTemplate(),
        layerName: 'Background',
        fromEndSegmentLength: 150,
        toEndSegmentLength: 150,
        adjusting: Link.End,
      },
      GraphObject.make(
        Shape,
        { strokeWidth: 4, stroke: 'rgba(173, 173, 173, 0.25)' },
        new Binding('stroke', '', l => {
          return this.getAutoLinkColor(l.fromNode.data);
        }).ofObject(),
        new Binding('strokeWidth', 'width')
      ),
      {
        toolTip: this.getLinkToolTip(),
      }
    );
  }

  getWaterLinkTemplate() {
    return GraphObject.make(
      Link,
      Link.Bezier,
      {
        selectionAdornmentTemplate: this.getLinkSelectionAdornmentTemplate(),
        layerName: 'Background',
        fromEndSegmentLength: 150,
        toEndSegmentLength: 150,
        adjusting: Link.End,
      },
      GraphObject.make(
        Shape,
        { strokeWidth: 4, stroke: 'rgba(173, 173, 173, 0.25)' },
        new Binding('stroke', '', l => {
          return this.getAutoLinkColor(l.fromNode.data);
        }).ofObject(),
        new Binding('strokeWidth', 'width')
      ),
      {
        toolTip: this.getWaterLinkToolTip(),
      }
    );
  }

  getFuelGasLinkTemplate() {
    return GraphObject.make(
      Link,
      Link.Bezier,
      {
        selectionAdornmentTemplate: this.getLinkSelectionAdornmentTemplate(),
        layerName: 'Background',
        fromEndSegmentLength: 150,
        toEndSegmentLength: 150,
        adjusting: Link.End,
      },
      GraphObject.make(
        Shape,
        { strokeWidth: 4, stroke: 'rgba(173, 173, 173, 0.25)' },
        new Binding('stroke', '', l => {
          return this.getAutoLinkColor(l.fromNode.data);
        }).ofObject(),
        new Binding('strokeWidth', 'width')
      ),
      {
        toolTip: this.getFuelGasLinkToolTip(),
      }
    );
  }

  textStyle() {
    return { font: 'bold 12pt Segoe UI, sans-serif', stroke: 'black', margin: 5 };
  }

  getLinkSelectionAdornmentTemplate() {
    return this.$(
      go.Adornment,
      'Link',
      // use selection object's strokeWidth
      this.$(go.Shape, { isPanelMain: true, fill: null, stroke: 'rgba(0, 0, 255, 0.3)', strokeWidth: 0 })
    );
  }

  getLinkToolTip() {
    return GraphObject.make(
      go.Adornment,
      'Auto',
      GraphObject.make(go.Shape, {
        fill: '#CEF6F5',
        stroke: 'DarkGray',
      }),
      GraphObject.make(
        go.Panel,
        'Table',
        {
          padding: new go.Margin(3, 5, 1, 5),
        },

        GraphObject.make(go.TextBlock, {
          margin: new go.Margin(3, 7, 0, 0),
          text: 'Volumetric flowrate',
          row: 0,
          column: 0,
        }),

        GraphObject.make(
          go.TextBlock,
          {
            margin: new go.Margin(3, 3, 0, 0),
            row: 0,
            column: 1,
          },
          new go.Binding('text', 'volumetricFlowrate')
        ),

        GraphObject.make(
          go.TextBlock,
          {
            margin: new go.Margin(3, 7, 0, 0),
            row: 0,
            column: 2,
          },
          new go.Binding('text', 'volumetricFlowrateUnit')
        )
      )
    );
  }

  getWaterLinkToolTip() {
    return GraphObject.make(
      go.Adornment,
      'Auto',
      GraphObject.make(go.Shape, {
        fill: '#CEF6F5',
        stroke: 'DarkGray',
      }),
      GraphObject.make(
        go.Panel,
        'Table',
        {
          padding: new go.Margin(3, 5, 1, 5),
        },

        GraphObject.make(go.TextBlock, {
          margin: new go.Margin(3, 7, 0, 0),
          text: 'Mass flowrate',
          row: 0,
          column: 0,
        }),

        GraphObject.make(
          go.TextBlock,
          {
            margin: new go.Margin(3, 3, 0, 0),
            row: 0,
            column: 1,
          },
          new go.Binding('text', 'massFlowrate')
        ),

        GraphObject.make(
          go.TextBlock,
          {
            margin: new go.Margin(3, 7, 0, 0),
            row: 0,
            column: 2,
          },
          new go.Binding('text', 'massFlowrateUnit')
        )
      )
    );
  }

  getFuelGasLinkToolTip() {
    return GraphObject.make(
      go.Adornment,
      'Auto',
      GraphObject.make(go.Shape, {
        fill: '#CEF6F5',
        stroke: 'DarkGray',
      }),
      GraphObject.make(
        go.Panel,
        'Table',
        {
          padding: new go.Margin(3, 5, 1, 5),
        },

        GraphObject.make(go.TextBlock, {
          margin: new go.Margin(3, 7, 0, 0),
          text: 'Flowrate',
          row: 0,
          column: 0,
        }),

        GraphObject.make(
          go.TextBlock,
          {
            margin: new go.Margin(3, 3, 0, 0),
            row: 0,
            column: 1,
          },
          new go.Binding('text', 'flowrate')
        ),

        GraphObject.make(
          go.TextBlock,
          {
            margin: new go.Margin(3, 7, 0, 0),
            row: 0,
            column: 2,
          },
          new go.Binding('text', 'flowrateUnit')
        )
      )
    );
  }

  getSankeyLayout() {
    const sankeyLayout = new LayeredDigraphLayout();
    sankeyLayout.setsPortSpots = false;
    sankeyLayout.direction = 0;
    sankeyLayout.layeringOption = LayeredDigraphLayout.LayerOptimalLinkLength;
    sankeyLayout.packOption = LayeredDigraphLayout.PackStraighten || LayeredDigraphLayout.PackMedian;
    sankeyLayout.layerSpacing = 300;
    sankeyLayout.columnSpacing = 1;
    // eslint-disable-next-line func-names
    sankeyLayout.createNetwork = function () {
      // eslint-disable-next-line func-names
      this.diagram.nodes.each(function (node) {
        const height = getAutoHeightForNode(node);
        const font = `bold ${getTextFontSize(node, height)}pt Segoe UI, sans-serif`;
        const shape = node.findObject('SHAPE');
        const text = node.findObject('TEXT');
        const ltext = node.findObject('LTEXT');
        if (shape) {
          shape.height = height;
        }
        if (text) {
          text.font = font;
        }
        if (ltext) {
          ltext.font = font;
        }
      });
      return LayeredDigraphLayout.prototype.createNetwork.call(this);
    };

    // eslint-disable-next-line func-names
    (sankeyLayout as any).nodeMinColumnSpace = function (v, topLeft) {
      if (v.node === null) {
        if (v.edgesCount >= 1) {
          let max = 1;
          const it = v.edges;
          while (it.next()) {
            const edge = it.value;
            if (edge.link != null) {
              const t = edge.link.computeThickness();
              if (t > max) {
                max = t;
              }
              break;
            }
          }
          return Math.ceil(max / this.columnSpacing);
        }
        return 1;
      }
      return (LayeredDigraphLayout.prototype as any).nodeMinColumnSpace.call(this, v, topLeft);
    };

    // eslint-disable-next-line func-names
    (sankeyLayout as any).assignLayers = function () {
      (LayeredDigraphLayout.prototype as any).assignLayers.call(this);
      const maxlayer = this.maxLayer;
      // now make sure every vertex with no outputs is max layer
      for (const it = this.network.vertexes.iterator; it.next(); ) {
        const v = it.value;
        if (v.destinationVertexes.count === 0) {
          v.layer = 0;
        }
        if (v.sourceVertexes.count === 0) {
          v.layer = maxlayer;
        }
      }
    };

    // eslint-disable-next-line func-names
    (sankeyLayout as any).commitLayout = function () {
      (LayeredDigraphLayout.prototype as any).commitLayout.call(this);
      for (const it = this.network.edges.iterator; it.next(); ) {
        const { link } = it.value;
        if (link && link.curve === Link.Bezier) {
          // depend on Link.adjusting === go.Link.End to fix up the end points of the links
          // without losing the intermediate points of the route as determined by LayeredDigraphLayout
          link.invalidateRoute();
        }
      }
    };
    return sankeyLayout;
  }

  getAutoLinkColor(data) {
    let hex = data.color;
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r},${g},${b},${30 / 100})`;
  }
}
