<div class="dark-overlay" style="display: none">
  <div class="progress-wrapper">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="text-center progress-message">
      {{ message ? message : 'Solving...' }}
    </div>
    <button *ngIf="showStopButton" (click)="stop()" class="btn btn-sm btn-white btn-block" id="cancelSolve">
      Cancel
    </button>
  </div>
</div>
