import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { FuelGasSourceImport } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-source-import';
import { GasContributorUnitOperation } from '../../../_models/_unit-operations/gas-contributor-unit-operation';
import { Case } from '../../../_models';
import { CoreService } from '../../../_services/core.service';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { FuelGasSourceInformationStream } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-source-information-stream';

@Component({
  selector: 'sob-fuel-gas-source-import',
  templateUrl: './fuel-gas-source-import.component.html',
  styleUrls: ['./fuel-gas-source-import.component.css'],
})
export class FuelGasSourceImportComponent implements OnInit {
  @Input() unitOperation: FuelGasSourceImport;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;

  activeInformationStreams: FuelGasSourceInformationStream[] = [];
  availableProviderUnitOperations: GasContributorUnitOperation[];

  constructor(private coreService: CoreService, private fb: UntypedFormBuilder, private svfb: SimVarFormBuilder) {}

  ngOnInit(): void {
    this.activeInformationStreams.push(...this.unitOperation.inletSourceInformationStreams);
    this.addControls();
    this.formGroupWrapper.storeOriginalValue();

    // hmm
    this.filterStreams();
  }

  addControls(): void {
    // TODO nice hack to allow change detection in name... should not be used REMOVE when not needed
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl('selectedProviderUnitOperationId', this.fb.control(''));
    this.propertyWindowFormGroup.addControl('inletSourceInformationStreams', this.fb.array([]));

    for (const informationStream of this.activeInformationStreams) {
      this.addInformationStreamControls(informationStream);
    }
  }

  getAvailableInformationStreamProviders(): GasContributorUnitOperation[] {
    const gasContributorUnitOperations = this.getParentFlowsheetAllowedUnitOperations();
    const result = [];
    for (const gasContributor of gasContributorUnitOperations) {
      // don't even show it if it is already in use
      if (this.isInformationStreamInUseByOtherUnitOperation(gasContributor.id)) {
        continue;
      }

      result.push(gasContributor);
    }

    return result;
  }

  protected getParentFlowsheetAllowedUnitOperations(): GasContributorUnitOperation[] {
    const parentUnit = this.currentCase.getUnitOperation(this.unitOperation.flowsheetId);
    const parentFlowsheetId = parentUnit.flowsheetId;

    return this.coreService.currentCase.filterUnitOperations(uo => {
      return uo.flowsheetId === parentFlowsheetId && uo instanceof GasContributorUnitOperation;
    }) as GasContributorUnitOperation[];
  }

  addInformationStreamControls(informationStream: FuelGasSourceInformationStream) {
    const informationStreamStreamGroup = this.fb.group({
      id: this.fb.control(informationStream.id), // not visible at all
      providerUnitOperationId: this.fb.control(informationStream.providerUnitOperationId), // not visible
      flowrate: this.svfb.control(informationStream.flowrate, true),
      gasAnalysis: this.fb.control(''),
    });
    this.informationStreamsFormArray.push(informationStreamStreamGroup);
  }

  protected isInformationStreamInUseByOtherUnitOperation(providerUnitOperationId: string) {
    const otherInformationStreamUsers = this.getOtherInformationStreamUsers();

    for (const informationStreamUser of otherInformationStreamUsers) {
      if (this.usesInformationStream(informationStreamUser, providerUnitOperationId)) {
        return true;
      }
    }

    return false;
  }

  private getOtherInformationStreamUsers(): FuelGasSourceImport[] {
    return this.currentCase.filterUnitOperations(
      uo => uo instanceof FuelGasSourceImport && uo.id !== this.unitOperation.id
    ) as FuelGasSourceImport[];
  }

  protected usesInformationStream(informationStreamUser: FuelGasSourceImport, gasContributorId: string): boolean {
    return !!informationStreamUser.inletSourceInformationStreams.find(
      es => es.providerUnitOperationId === gasContributorId
    );
  }

  addInformationStream() {
    if (this.isSelectedInformationStreamActive()) {
      return;
    }

    const unitOp = this.availableProviderUnitOperations.find(uo => uo.id === this.selectedProviderUnitOperationId);
    const unitOpIndex = this.availableProviderUnitOperations.indexOf(unitOp);
    const informationStream = new FuelGasSourceInformationStream({}, this.unitOperation.ownerCase);
    informationStream.providerUnitOperationId = unitOp.id;
    this.addInformationStreamControls(informationStream);
    this.activeInformationStreams.push(informationStream);
    this.availableProviderUnitOperations.splice(unitOpIndex, 1);
  }

  protected isSelectedInformationStreamActive(): boolean {
    // instead of just a boolean this method could return a more elaborated object, maybe it could include the names
    // of the owner unit operation
    return !!this.activeInformationStreams.find(
      is => is.providerUnitOperationId === this.selectedProviderUnitOperationId
    );
  }

  // removes the information streams from the available list if they are already in use
  // TODO check if this works ... and if it can be improved
  filterStreams() {
    if (this.activeInformationStreams.length === 0) {
      this.availableProviderUnitOperations = this.getAvailableInformationStreamProviders();
    } else {
      this.availableProviderUnitOperations = this.getAvailableInformationStreamProviders();
      for (let i = 0; i < this.activeInformationStreams.length; i++) {
        for (let j = 0; j < this.availableProviderUnitOperations.length; j++) {
          if (this.availableProviderUnitOperations[j].id === this.activeInformationStreams[i].providerUnitOperationId) {
            this.availableProviderUnitOperations.splice(j, 1);
          }
        }
      }
    }
  }

  removeInformationStream(i: number) {
    this.activeInformationStreams.splice(i, 1);
    this.informationStreamsFormArray.removeAt(i);
    this.filterStreams();
  }

  // region form group getters
  getInformationStreamsFormGroupsArray(): UntypedFormGroup[] {
    return this.informationStreamsFormArray.controls as UntypedFormGroup[];
  }

  get selectedProviderUnitOperationId(): string {
    return this.propertyWindowFormGroup.get('selectedProviderUnitOperationId').value;
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }

  get informationStreamsFormArray(): UntypedFormArray {
    return this.propertyWindowFormGroup.get('inletSourceInformationStreams') as UntypedFormArray;
  }
  // endregion

  get currentCase(): Case {
    return this.coreService.currentCase;
  }
}
