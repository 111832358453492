import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import * as Highcharts from 'highcharts';

import { CoreService } from '../../../_services/core.service';
import { SuncorCalculator } from '../../../_models/suncor-calculator';
import { GhgEmissionsReport } from '../../../_models/_reports/ghg-emissions-report';
import { RevenueReport } from '../../../_models/_reports/revenue-report';
import { CapexReport } from '../../../_models/_reports/capex-report';
import { OpexReport } from '../../../_models/_reports/opex-report';
import { RefineryFlowRatesReport } from '../../../_models/_reports/refinery-flow-rates-report';
import { DiluentFlowRatesReport } from '../../../_models/_reports/diluent-flow-rates-report';

declare let unitConverter: any;

@Component({
  selector: 'sob-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class InsightsComponent {
  calculator: SuncorCalculator;

  constructor(private coreService: CoreService, private ref: ChangeDetectorRef) {
    // TODO it _seems_ this should be important only when the current case is replaced
    // TODO consider subscribing to currentCaseReplacedRequest
    this.coreService.caseChangedRequest.subscribe(() => {
      this.calculator = undefined;
      this.ref.detectChanges();
      this.calculator = this.coreService.currentCase.calculator;
      this.ref.detectChanges();
      this.setChartSubtitleValues();
    });
  }

  initGhgChart(ghgReport: GhgEmissionsReport) {
    const series = {
      name: 'GHG emissions',
      colorByPoint: true,
      data: ghgReport.contributionByCategory.map(cv => {
        return { y: cv.value, name: cv.name };
      }),
      innerSize: '60%',
    };

    const chartOptions = this.getCommonChartOptions(
      ghgReport.unit,
      series,
      unitConverter.formatNumber(ghgReport.totalGhg)
    );
    Highcharts.chart('insightsGhgChart', <any>chartOptions);
  }

  initRevenueChart(revenueReport: RevenueReport) {
    const series = {
      name: 'Revenue',
      colorByPoint: true,
      data: revenueReport.data.map(cv => {
        return { y: cv.value, name: cv.name };
      }),
      innerSize: '60%',
    };

    const chartOptions = this.getCommonChartOptions(
      revenueReport.unit,
      series,
      unitConverter.formatNumber(revenueReport.total)
    );

    Highcharts.chart('insightsRevenueChart', <any>chartOptions);
  }

  initCapexChart(capexReport: CapexReport) {
    const series = {
      name: 'CAPEX',
      colorByPoint: true,
      data: capexReport.contributionByCategory.map(cv => {
        return { y: cv.value, name: cv.name };
      }),
      innerSize: '60%',
    };

    const chartOptions = this.getCommonChartOptions(
      capexReport.unit,
      series,
      unitConverter.formatNumber(capexReport.total)
    );

    Highcharts.chart('insightsCapexChart', <any>chartOptions);
  }

  initOpexChart(opexReport: OpexReport) {
    const series = {
      name: 'OPEX',
      colorByPoint: true,
      data: opexReport.contributionByCategory.map(cv => {
        return { y: cv.value, name: cv.name };
      }),
      innerSize: '60%',
    };

    const chartOptions = this.getCommonChartOptions(
      opexReport.unit,
      series,
      unitConverter.formatNumber(opexReport.total)
    );

    Highcharts.chart('insightsOpexChart', <any>chartOptions);
  }

  initRefineryChart(refineryReport: RefineryFlowRatesReport) {
    const series = {
      name: 'Refinery flowrates',
      colorByPoint: true,
      data: refineryReport.data.map(cv => {
        return { y: cv.value, name: cv.name };
      }),
      innerSize: '60%',
    };

    const chartOptions = this.getCommonChartOptions(
      refineryReport.unit,
      series,
      unitConverter.formatNumber(refineryReport.total)
    );

    Highcharts.chart('insightsRefineryChart', <any>chartOptions);
  }

  initDiluentChart(diluentReport: DiluentFlowRatesReport) {
    const series = {
      name: 'Diluent flowrates',
      colorByPoint: true,
      data: diluentReport.data.map(cv => {
        return { y: cv.value, name: cv.name };
      }),
      innerSize: '60%',
    };

    const chartOptions = this.getCommonChartOptions(
      diluentReport.unit,
      series,
      unitConverter.formatNumber(diluentReport.total)
    );

    Highcharts.chart('insightsDiluentChart', <any>chartOptions);
  }

  getCommonChartOptions(unit, series, totalValue) {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        useHTML: true,
      },

      credits: {
        enabled: false,
      },

      title: {
        text:
          `<span style="font-size: 12px;">Total</span><br>` +
          `<strong style="font-size: 15px; font-weight: 700;">${totalValue}</strong><br>` +
          `<span style="font-size: 12px;">${unit}</span>`,
        verticalAlign: 'middle',
        y: -12,
      },

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 225,
            },
            chartOptions: {
              title: {
                text:
                  `<span style="font-size: 11px;">Total</span><br>` +
                  `<strong style="font-size: 13px; font-weight: 700">${totalValue}</strong><br>` +
                  `<span style="font-size: 11px;">${unit}</span>`,
                verticalAlign: 'middle',
                y: -15,
              },
            },
          },
          {
            condition: {
              maxWidth: 155,
            },
            chartOptions: {
              title: {
                text:
                  `<span style="font-size: 10px;">Total</span><br>` +
                  `<strong style="font-size: 13px; font-weight: 700">${totalValue}</strong><br>` +
                  `<span style="font-size: 10px;">${unit}</span>`,
                verticalAlign: 'middle',
                y: -15,
              },
            },
          },
        ],
      },

      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.2f} ${unit}</b><br/>`,
      },
      series: [series],
    };
  }

  setChartSubtitleValues() {
    $('#insightsTotalSuncorGhgValue').html(
      this.calculator.totalSuncorGhg.convertToAnotherUnit(this.calculator.totalSuncorGhg.unit, true)
    );

    $('#insightsTotalValueValue').html(
      this.calculator.totalValue.convertToAnotherUnit(this.calculator.totalValue.unit, true)
    );
  }

  switchTab(tabId: string) {
    $(`a[href="${tabId}"]`).tab('show');
  }
}
