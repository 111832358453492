import { ConstrainableObject } from '../_interfaces/constrainable-object';
import { SimulationVariable } from '../simulation-variable';
import { UnitOperationConstraints } from './unit-operation-constraints';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { Case } from '../case';

import { isTypeUndefined } from '../../_utils/utils';
import { Quantity } from '../../_config/quantity.enum';
import { SimulationVariableName } from '../../_config/simulation-variable-name.enum';
import { GasContributorUnitOperation } from './gas-contributor-unit-operation';
import { KpiProvider } from '../_case-study/kpi-provider';
import { ParameterProvider } from '../_case-study/parameter-provider';

declare let unitConverter: any;

export class FluidCoker
  extends GasContributorUnitOperation
  implements ConstrainableObject, KpiProvider, ParameterProvider
{
  category = unitOperationsConfig.fluidCoker.key;

  useDataDrivenYieldModel: boolean;
  naphthaYield: SimulationVariable;
  totalInletVolume: SimulationVariable;
  feedDensity: SimulationVariable;
  gasOilYield: SimulationVariable;
  producedFuelGas: SimulationVariable;
  producedFuelGasRate: SimulationVariable;
  producedCoke: SimulationVariable;
  useCustomModel: boolean;
  modelScript: string;
  h2sProductionRate: SimulationVariable;
  h2sProduction: SimulationVariable;
  constraints: UnitOperationConstraints;
  minimumApi: SimulationVariable;
  maximumCcr: SimulationVariable;
  cokeEmissionFactor: SimulationVariable;
  percentCokeToCombustion: SimulationVariable;

  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [
        this.totalInletVolume,
        this.feedDensity,
        this.naphthaYield,
        this.gasOilYield,
        this.producedCoke,
        this.producedFuelGasRate,
        this.ghgIntensity,
        this.percentCokeToCombustion,
        this.cokeEmissionFactor,
        this.ghgEmissions,
        this.h2sProductionRate,
        this.h2sProduction,
        this.opexTotal,
        this.capexTotal,
      ];
    }
    return [
      this.totalInletVolume,
      this.feedDensity,
      this.naphthaYield,
      this.gasOilYield,
      this.producedCoke,
      this.producedFuelGasRate,
      this.ghgIntensity,
      this.percentCokeToCombustion,
      this.cokeEmissionFactor,
      this.ghgEmissions,
      this.h2sProductionRate,
      this.h2sProduction,
      this.steamMake600,
      this.steamUse600,
      this.steamUse900,
      this.steamMake900,
      this.steamMake150,
      this.steamUse150,
      this.steamMake50,
      this.steamUse50,
      this.fuelGasHeatingValue,
      this.fuelGasEmissionFactor,
      this.energyFlow,
      this.flowrate,
      this.opexTotal,
      this.capexTotal,
    ];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [
      this.percentCokeToCombustion,
      this.cokeEmissionFactor,
      this.h2sProductionRate,
      this.opexVar,
      this.opexGasDiesel,
      this.opexPower,
      this.opexFixed,
      this.capexAmortized,
    ];
  }

  getAvailableParameters() {
    return undefined;
  }

  getAlternativeGhgIntensity(): SimulationVariable {
    return undefined;
  }

  override initValues(unitOperation: any | FluidCoker): void {
    super.initValues(unitOperation);

    if (isTypeUndefined(unitOperation.useDataDrivenYieldModel)) {
      this.useDataDrivenYieldModel = true;
    } else {
      this.useDataDrivenYieldModel = unitOperation.useDataDrivenYieldModel;
    }

    this.totalInletVolume = new SimulationVariable(unitOperation.totalInletVolume, this.ownerCase, this);
    this.feedDensity = new SimulationVariable(unitOperation.feedDensity, this.ownerCase, this);
    this.naphthaYield = new SimulationVariable(unitOperation.naphthaYield, this.ownerCase, this);
    this.gasOilYield = new SimulationVariable(unitOperation.gasOilYield, this.ownerCase, this);
    this.producedFuelGasRate = new SimulationVariable(unitOperation.producedFuelGasRate, this.ownerCase, this);
    this.producedFuelGas = new SimulationVariable(unitOperation.producedFuelGas, this.ownerCase, this);
    this.producedCoke = new SimulationVariable(unitOperation.producedCoke, this.ownerCase, this);
    this.h2sProductionRate = new SimulationVariable(unitOperation.h2sProductionRate || {}, this.ownerCase, this);
    this.h2sProduction = new SimulationVariable(unitOperation.h2sProduction || {}, this.ownerCase, this);
    this.minimumApi = new SimulationVariable(unitOperation.minimumApi || {}, this.ownerCase, this);
    this.maximumCcr = new SimulationVariable(unitOperation.maximumCcr || {}, this.ownerCase, this);
    this.cokeEmissionFactor = new SimulationVariable(unitOperation.cokeEmissionFactor || {}, this.ownerCase, this);
    this.percentCokeToCombustion = new SimulationVariable(
      unitOperation.percentCokeToCombustion || {},
      this.ownerCase,
      this
    );
    if (isTypeUndefined(unitOperation.useCustomModel)) {
      this.useCustomModel = false;
    } else {
      this.useCustomModel = unitOperation.useCustomModel;
    }

    this.modelScript = typeof isTypeUndefined(unitOperation.modelScript) ? '' : unitOperation.modelScript;

    this.initConstraints();
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('capacity', this.capacity);
    this.constraints.addConstraint('minimumFlow', this.minimumFlow);
    this.constraints.addConstraint('minimumApi', this.minimumApi);
    this.constraints.addConstraint('maximumCcr', this.maximumCcr);
  }

  override setQuantities() {
    super.setQuantities();
    this.feedDensity.setQuantity(Quantity.LIQUID_DENSITY);
    this.totalInletVolume.setQuantity(Quantity.VOLUMETRIC_FLOWRATE);
    this.naphthaYield.setQuantity(Quantity.PERCENT);
    this.gasOilYield.setQuantity(Quantity.PERCENT);
    this.producedFuelGasRate.setQuantity(Quantity.GAS_STD_VOLUME_PER_VOLUME);
    this.producedFuelGas.setQuantity(Quantity.MASSFLOWRATE);
    this.producedCoke.setQuantity(Quantity.MASSFLOWRATE);
    this.h2sProduction.setQuantity(Quantity.MOLEFLOWRATE);
    this.h2sProductionRate.setQuantity(Quantity.GAS_STD_VOLUME_PER_VOLUME);
    this.minimumApi.setQuantity(Quantity.LIQUID_DENSITY);
    this.maximumCcr.setQuantity(Quantity.MASS_FRACTION);
    this.percentCokeToCombustion.setQuantity(Quantity.PERCENT);
    this.cokeEmissionFactor.setQuantity(Quantity.MASS_EMISSIONS_PER_FUEL_MASS);
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.totalInletVolume = this.getSimulationVariableSafe(sup.totalInletVolume);
    this.feedDensity = this.getSimulationVariableSafe(sup.feedDensity);
    this.naphthaYield = this.getSimulationVariableSafe(sup.naphthaYield);
    this.gasOilYield = this.getSimulationVariableSafe(sup.gasOilYield);
    this.producedFuelGasRate = this.getSimulationVariableSafe(sup.producedFuelGasRate);
    this.producedFuelGas = this.getSimulationVariableSafe(sup.producedFuelGas);
    this.producedCoke = this.getSimulationVariableSafe(sup.producedCoke);
    this.h2sProduction = this.getSimulationVariableSafe(sup.h2sProduction);
    this.h2sProductionRate = this.getSimulationVariableSafe(sup.h2sProductionRate);
    this.minimumApi = this.getSimulationVariableSafe(sup.minimumApi);
    this.maximumCcr = this.getSimulationVariableSafe(sup.maximumCcr);
    this.cokeEmissionFactor = this.getSimulationVariableSafe(sup.cokeEmissionFactor);
    this.percentCokeToCombustion = this.getSimulationVariableSafe(sup.percentCokeToCombustion);
    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }

  constraintViolated() {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    })[0];

    return inletStream && this.constraints.constraintValueViolated('capacity', inletStream.volumetricFlowrate.value);
  }

  override setDefaultValues(): void {
    super.setDefaultValues();
    if (this.cokeEmissionFactor.isUndefined()) {
      this.cokeEmissionFactor.setDefaultValue(3017.4);
    }
    if (this.percentCokeToCombustion.isUndefined()) {
      this.percentCokeToCombustion.setDefaultValue(15.0);
    }
    if (this.producedCoke.isUndefined()) {
      this.producedCoke.setDefaultValue(0.0, unitConverter.units.Massflowrate.TONNES_D);
    }
    if (this.energyFlow.isUndefined()) {
      this.energyFlow.setDefaultValue(0.0, unitConverter.units.Energyrate.GJ_H);
    }
  }

  constraintValueDefined() {
    return this.constraints.anyConstraintValueDefined();
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.feedDensity.setName('Feed Density');
    this.totalInletVolume.setName('Total Inlet Volume');
    this.producedFuelGasRate.setName('Produced Fuel Gas Rate');
    this.producedFuelGas.setName('Produced Fuel Gas');
    this.producedCoke.setName('Produced Coke');
    this.cokeEmissionFactor.setName('Coke Emission Factor');
    this.percentCokeToCombustion.setName('Coke to Combustion ');
    this.maximumCcr.setName(SimulationVariableName.MAXIMUM_CCR);
    this.minimumApi.setName(SimulationVariableName.MINIMUM_API);
    this.h2sProduction.setName(SimulationVariableName.H2S_PRODUCTION);
    this.h2sProductionRate.setName(SimulationVariableName.H2S_PRODUCTION_RATE);
    this.gasOilYield.setName(SimulationVariableName.OIL_YIELD);
    this.naphthaYield.setName(SimulationVariableName.NAPHTHA_YIELD);
    this.steamMake900.setName('#900 Steam Make');
    this.steamUse900.setName('#900 Steam Use');
    this.steamMake600.setName('#600 Steam Make');
    this.steamUse600.setName('#600 Steam Use');
    this.steamMake150.setName('#150 Steam Make');
    this.steamUse150.setName('#150 Steam Use');
    this.steamMake50.setName('#50 Steam Make');
    this.steamUse50.setName('#50 Steam Use');
    this.opexTotal.setName('Total OPEX');
    this.capexTotal.setName('Total CAPEX');
    this.fuelGasHeatingValue.setName(SimulationVariableName.FUEL_GAS_HEATING_VALUE);
    this.fuelGasEmissionFactor.setName(SimulationVariableName.FUEL_GAS_EMISSION_FACTOR);
    this.energyFlow.setName(SimulationVariableName.ENERGY_FLOW);
    this.flowrate.setName(SimulationVariableName.FUEL_GAS_FLOWRATE);
  }

  override detectChanges(another: any): boolean {
    const changes = super.detectChanges(another);
    return changes || another.modelScript !== this.modelScript;
  }
}
