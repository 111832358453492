import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ParametricStudy } from '../../../_models/_case-study/parametric-study';

@Component({
  selector: 'sob-parameter-input',
  templateUrl: './parameter-input.component.html',
  styleUrls: ['./parameter-input.component.css'],
})
export class ParameterInputComponent implements OnInit {
  @Input()
  parametricStudy: ParametricStudy;

  parametersGroup: UntypedFormGroup;
  currentUnit: string;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.addControls();
  }

  updateUnit($event) {
    this.currentUnit = $event;
    this.parameter.variableValues.unit = this.currentUnit;
  }

  addControls() {
    const { variableValues } = this.parametricStudy.parameter;
    this.parametersGroup = this.fb.group({
      quantity: this.fb.control(variableValues.quantity),
      unit: this.fb.control(variableValues.unit),
      values: this.fb.array(this.initValues()),
    });
  }

  initValues() {
    return this.parametricStudy.parameter.variableValues.values.map(dv => {
      return this.addVariableValueControl(dv);
    });
  }

  updateVariableValueInput(index) {
    this.parameter.variableValues.values[index] = this.valuesGroupArray.controls[index].value.val;
    this.parametricStudy.clearResults();
  }

  addVariableValueControl(value: number) {
    // TODO check if this form group can be changed for a control
    return this.fb.group({ val: value });
  }

  addState() {
    if (this.parameter) {
      this.parametricStudy.clearResults();
      this.parameter.addState();
      const arrayValues = this.parameter.variableValues.values;
      const lastParameter = arrayValues[arrayValues.length - 1];
      this.addValueControl(lastParameter);
    }
  }

  addValueControl(value: number) {
    this.valuesGroupArray.push(this.addVariableValueControl(value));
  }

  removeState() {
    if (this.parameter && this.parameter.variableValues.values.length) {
      this.parametricStudy.clearResults();
      this.parameter.removeLastValue();
      this.valuesGroupArray.removeAt(this.valuesGroupArray.length - 1);
    }
  }

  get valuesGroupArray() {
    return this.parametersGroup.get('values') as UntypedFormArray;
  }

  get parameter() {
    return this.parametricStudy.parameter;
  }
}
