import { RangeDistributionConstraint } from './range-distribution-constraint';

export class MixingConstraints {
  constraints: { [id: string]: RangeDistributionConstraint } = {};

  addNewConstraint(id: string, name: string, value: number, unitOperationId: string, isActive?: boolean) {
    this.constraints[id] = new RangeDistributionConstraint(id, unitOperationId, name, value, isActive);
  }

  removeConstraint(unitOperationId: string) {
    Object.entries(this.constraints).forEach(cstr => {
      if (cstr[1].sourceUnitOperationId === unitOperationId) delete this.constraints[cstr[0]];
    });
  }

  removeMinMaxConIfNoOptimizable(unitOperationId: string) {
    if (this.minAndMaxConstraintsExists(unitOperationId)) {
      this.removeConstraint(unitOperationId);
    }
  }

  removeExactConstraintIfOptimizable(unitOperationId: string) {
    if (this.exactValueConstraintExists(unitOperationId)) {
      this.removeConstraint(unitOperationId);
    }
  }

  minAndMaxConstraintsExists(unitOperationId: string) {
    return !!(this.constraints[`minimumRange${unitOperationId}`] && this.constraints[`maximumRange${unitOperationId}`]);
  }

  exactValueConstraintExists(unitOperationId: string) {
    return !!this.constraints[`exactValue${unitOperationId}`];
  }
}
