import { WaterUnitOperation } from './water-unit-operation';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Case } from '../../case';
import { SimulationVariable } from '../../simulation-variable';
import { Quantity } from '../../../_config/quantity.enum';
import { UnitOperationConstraints } from '../unit-operation-constraints';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { VariableStatus } from '../../../_config/variable-status.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';
import { ParameterProvider } from '../../_case-study/parameter-provider';

declare let unitConverter: any;

export class WaterCogeneration extends WaterUnitOperation implements KpiProvider, ParameterProvider {
  category = unitOperationsConfig.waterCogeneration.key;
  heatRate: SimulationVariable;
  power: SimulationVariable;
  quality: SimulationVariable;

  fuelGasEmissionFactor: SimulationVariable;
  fuelGasHeatingValue: SimulationVariable;
  ghgEmissions: SimulationVariable;

  otsgEnergyDemand: SimulationVariable;
  turbineEnergyDemand: SimulationVariable;
  totalEnergyDemand: SimulationVariable;

  massCapacity: SimulationVariable;
  minimumMassFlowrate: SimulationVariable;
  constraints: UnitOperationConstraints;

  useCustomModel: boolean;
  hrsgScript: string;

  constructor(unitOperation: any | WaterCogeneration, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.heatRate = new SimulationVariable(unitOperation.heatRate, this.ownerCase, this);
    this.power = new SimulationVariable(unitOperation.power, this.ownerCase, this);
    this.quality = new SimulationVariable(unitOperation.quality, this.ownerCase, this);

    this.fuelGasEmissionFactor = new SimulationVariable(
      unitOperation.fuelGasEmissionFactor || {},
      this.ownerCase,
      this
    );
    this.fuelGasHeatingValue = new SimulationVariable(unitOperation.fuelGasHeatingValue || {}, this.ownerCase, this);
    this.ghgEmissions = new SimulationVariable(unitOperation.ghgEmissions || {}, this.ownerCase, this);

    this.otsgEnergyDemand = new SimulationVariable(unitOperation.otsgEnergyDemand || {}, this.ownerCase, this);
    this.turbineEnergyDemand = new SimulationVariable(unitOperation.turbineEnergyDemand || {}, this.ownerCase, this);
    this.totalEnergyDemand = new SimulationVariable(unitOperation.totalEnergyDemand || {}, this.ownerCase, this);

    this.useCustomModel = !!unitOperation.useCustomModel;
    this.hrsgScript = unitOperation.hrsgScript || '';

    this.massCapacity = new SimulationVariable(unitOperation.massCapacity, this.ownerCase, this);
    this.minimumMassFlowrate = new SimulationVariable(unitOperation.minimumMassFlowrate, this.ownerCase, this);

    this.initConstraints();
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.heatRate.setName(SimulationVariableName.HEAT_RATE);
    this.power.setName(SimulationVariableName.POWER);
    this.quality.setName(SimulationVariableName.QUALITY);
    this.otsgEnergyDemand.setName(SimulationVariableName.OTSG_ENERGY_DEMAND);
    this.turbineEnergyDemand.setName(SimulationVariableName.TURBINE_ENERGY_DEMAND);
    this.totalEnergyDemand.setName(SimulationVariableName.TOTAL_ENERGY_DEMAND);
    this.fuelGasEmissionFactor.setName(SimulationVariableName.FUEL_GAS_EMISSION_FACTOR);
    this.fuelGasHeatingValue.setName(SimulationVariableName.FUEL_GAS_HEATING_VALUE);
    this.ghgEmissions.setName(SimulationVariableName.GHG_EMISSIONS);
    this.massCapacity.setName(SimulationVariableName.MAXIMUM_FEED_CAPACITY);
    this.minimumMassFlowrate.setName(SimulationVariableName.MINIMUM_MASS_FLOWRATE);
  }

  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('massCapacity', this.massCapacity);
    this.constraints.addConstraint('minimumMassFlowrate', this.minimumMassFlowrate);
  }

  override setQuantities() {
    super.setQuantities();
    this.heatRate.setQuantity(Quantity.ENERGY_PER_POWER);
    this.power.setQuantity(Quantity.POWER);
    this.quality.setQuantity(Quantity.FRACTION);

    this.ghgEmissions.setQuantity(Quantity.MASSFLOWRATE);
    this.fuelGasEmissionFactor.setQuantity(Quantity.MASS_EMISSIONS_PER_VOLUME);
    this.fuelGasHeatingValue.setQuantity(Quantity.ENERGY_PER_VOLUME);

    this.otsgEnergyDemand.setQuantity(Quantity.ENERGYRATE);
    this.turbineEnergyDemand.setQuantity(Quantity.ENERGYRATE);
    this.totalEnergyDemand.setQuantity(Quantity.ENERGYRATE);

    this.massCapacity.setQuantity(Quantity.MASSFLOWRATE);
    this.minimumMassFlowrate.setQuantity(Quantity.MASSFLOWRATE);
  }

  override setDefaultValues() {
    if (this.fuelGasEmissionFactor.isUndefined()) {
      this.fuelGasEmissionFactor.setDefaultValue(0.0702, unitConverter.units.MassEmissionsPerVolume.KG_SCF);
    }
    if (this.fuelGasHeatingValue.isUndefined()) {
      this.fuelGasHeatingValue.setDefaultValue(1088.3, unitConverter.units.EnergyPerVolume.BTU_SCF);
    }
    if (this.heatRate.isUndefined()) {
      this.heatRate.setDefaultValue(12687, unitConverter.units.EnergyPerPower.KJ_KWH);
    }
    if (this.ghgEmissions.isUndefined()) {
      this.ghgEmissions.setDefaultValue(0.0, unitConverter.units.Massflowrate.MMTPA);
    }
    super.setDefaultValues();
  }

  override clearResults() {
    super.clearResults();

    if (this.fuelGasHeatingValue.isSolverCalculated()) {
      this.fuelGasHeatingValue.variableStatus = VariableStatus.UNDEFINED;
      this.setDefaultValues();
    }

    if (this.fuelGasEmissionFactor.isSolverCalculated()) {
      this.fuelGasEmissionFactor.variableStatus = VariableStatus.UNDEFINED;
      this.setDefaultValues();
    }
  }

  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [this.heatRate, this.power, this.quality, this.totalEnergyDemand, this.ghgEmissions];
    }
    return [
      this.heatRate,
      this.power,
      this.quality,
      this.otsgEnergyDemand,
      this.turbineEnergyDemand,
      this.totalEnergyDemand,
      this.fuelGasEmissionFactor,
      this.fuelGasHeatingValue,
      this.ghgEmissions,
    ];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [this.heatRate, this.power, this.quality];
  }

  getAvailableParameters() {
    return undefined;
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.heatRate = this.getSimulationVariableSafe(sup.heatRate);
    this.power = this.getSimulationVariableSafe(sup.power);
    this.quality = this.getSimulationVariableSafe(sup.quality);
    this.ghgEmissions = this.getSimulationVariableSafe(sup.ghgEmissions);
    this.fuelGasEmissionFactor = this.getSimulationVariableSafe(sup.fuelGasEmissionFactor);
    this.fuelGasHeatingValue = this.getSimulationVariableSafe(sup.fuelGasHeatingValue);
    this.otsgEnergyDemand = this.getSimulationVariableSafe(sup.otsgEnergyDemand);
    this.turbineEnergyDemand = this.getSimulationVariableSafe(sup.turbineEnergyDemand);
    this.totalEnergyDemand = this.getSimulationVariableSafe(sup.totalEnergyDemand);
    this.massCapacity = this.getSimulationVariableSafe(sup.massCapacity);
    this.minimumMassFlowrate = this.getSimulationVariableSafe(sup.minimumMassFlowrate);

    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }
}
