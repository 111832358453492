import { Injectable } from '@angular/core';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { FlowsheetDiagramService } from '../flowsheet-diagram/flowsheet-diagram.service';
import { CoreService } from '../core.service';
import { Upgrader } from '../../_models/_unit-operations/upgrader';
import { UnitOperation } from '../../_models';
import { AbstractSubFlowsheetHandler } from './abstract-sub-flowsheet-handler';

@Injectable()
export class UpgraderSubFlowsheetHandler extends AbstractSubFlowsheetHandler {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(flowsheetDiagramService: FlowsheetDiagramService, coreService: CoreService) {
    super(flowsheetDiagramService, coreService);
  }

  getDefaultNodeData(): any {
    return this.addUpgraderInlet(-600, -150, unitOperationsConfig.upgraderInlet.displayName);
  }

  private addUpgraderInlet(x: number, y: number, name: string): any {
    const data = {
      category: unitOperationsConfig.upgraderInlet.key,
      loc: '',
      name,
    };

    return this.addNewNodeOnDiagram(data, x, y);
  }

  /**
   * This method is run when the flowsheet is loading
   * @param uoId
   */
  syncWhileFlowsheetLoading(uoId: string) {
    this.syncL2WithUpgraderPorts(uoId);
  }

  private syncL2WithUpgraderPorts(upgraderId: string): void {
    const upgrader = this.coreService.currentCase.unitOperationPool[upgraderId] as Upgrader;
    const upgraderInlets = this.coreService.currentCase.filterUnitOperations((uo: UnitOperation) => {
      return uo.category === unitOperationsConfig.upgraderInlet.key && uo.flowsheetId === upgraderId;
    });

    // TODO remove this - hasTwoInlets is no longer used - and remove the called methods
    if (upgrader.hasTwoInlets && upgraderInlets.length < 2) {
      this.addExtraUpgraderInlet(upgraderId);
    } else if (!upgrader.hasTwoInlets && upgraderInlets.length === 2) {
      this.removeExtraUpgraderInlet();
    }
  }

  getSubFlowsheetInlets(uoId: string): UnitOperation[] {
    return this.coreService.currentCase.filterUnitOperations((u: UnitOperation) => {
      return u.category === unitOperationsConfig.upgraderInlet.key && u.flowsheetId === uoId;
    });
  }

  getSubFlowsheetOutlets(uoId: string): UnitOperation[] {
    return this.coreService.currentCase.filterUnitOperations((u: UnitOperation) => {
      return u.category === unitOperationsConfig.commodityTank.key && u.flowsheetId === uoId;
    });
  }

  private removeExtraUpgraderInlet() {
    const node = this.flowsheetDiagramService.gojsDiagram.findNodesByExample({ name: 'Upgrader inlet 2' }).first();
    if (node) {
      this.flowsheetDiagramService.gojsDiagram.remove(node);
      this.coreService.currentCase.deleteUnitOperationById(node.data.id);
    }
  }

  private addExtraUpgraderInlet(upgraderId) {
    const data = this.addUpgraderInlet(-600, 150, 'Upgrader inlet 2');
    data.flowsheetId = upgraderId;
    this.coreService.createUnitOpFromNodeData(data);

    // const unitOperationCategory = this.coreService.currentCase.getUnitOperation(upgraderId).category;
    // this.level2OpenedSubject.next(unitOperationCategory);
  }

  syncAllBlocksInletOutletPorts(): void {
    const upgraders = this.coreService.currentCase.filterUnitOperations((uo: Upgrader) => {
      return uo.category === unitOperationsConfig.upgrader.key && uo.useSubFlowsheet;
    });

    upgraders.forEach(uo => {
      this.syncBlockInletOutletPorts(uo);
    });
  }
}
