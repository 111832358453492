import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as go from 'gojs';
import { Diagram, DiagramEvent, GraphLinksModel, Model, Overview, Point } from 'gojs';

import { Case, UnitOperation } from '../../_models';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { GojsSuncorMaterialStream } from '../../_models/_gojs/gojs-suncor-material-stream';
import { GojsUnitOperation } from '../../_models/_gojs/gojs-unit-operation';
import { Upgrader } from '../../_models/_unit-operations/upgrader';
import { BaseStream } from '../../_models/_streams/base-stream';
import { GojsStreamFactory } from '../../_models/_gojs/gojs-stream-factory';
import { isNullOrUndefined } from '../../_utils/utils';
import { GojsWaterMaterialStream } from '../../_models/_gojs/gojs-water-material-stream';
import { WaterUtilityUnitOperation } from '../../_models/_unit-operations/utilties/water-utility-unit-operation';
import { GojsFuelGasMaterialStream } from '../../_models/_gojs/gojs-fuel-gas-material-stream';
import { FuelGasUtilityUnitOperation } from '../../_models/_unit-operations/fuel-gas/fuel-gas-utility-unit-operation';

@Injectable()
export class FlowsheetDiagramService {
  gojsDiagram: Diagram;
  overview: Overview;
  streamCategory: string; // defines the type of stream that will be created

  private clearCaseSubject = new Subject();
  clearCaseRequest = this.clearCaseSubject.asObservable();

  private contextClickOnNodeSubject = new Subject();
  contextClickOnNodeRequest = this.contextClickOnNodeSubject.asObservable();

  private doubleClickOnNodeSubject = new Subject();
  doubleClickOnNodeRequest = this.doubleClickOnNodeSubject.asObservable();

  private doubleClickOnLinkSubject = new Subject();
  doubleClickOnLinkRequest = this.doubleClickOnLinkSubject.asObservable();

  private doubleClickOnWaterLinkSubject = new Subject();
  doubleClickOnWaterLinkRequest = this.doubleClickOnWaterLinkSubject.asObservable();

  private doubleClickOnFuelGasLinkSubject = new Subject();
  doubleClickOnFuelGasLinkRequest = this.doubleClickOnFuelGasLinkSubject.asObservable();

  private connectionFromSplitterSubject = new Subject();
  connectionFromSplitterRequest = this.connectionFromSplitterSubject.asObservable();

  private connectionFromBitumenConversionSubject = new Subject();
  connectionFromBitumenConversionRequest = this.connectionFromBitumenConversionSubject.asObservable();

  private cutRatioVariableConnectionSubject = new Subject();
  cutRatioVariableConnectionRequest = this.cutRatioVariableConnectionSubject.asObservable();

  private connectionToMixerSubject = new Subject();
  connectionToMixerRequest = this.connectionToMixerSubject.asObservable();

  private linkCreatedSubject = new Subject();
  linkCreatedRequest = this.linkCreatedSubject.asObservable();

  private linkDeletedSubject = new Subject();
  linkDeletedRequest = this.linkDeletedSubject.asObservable();

  private mainMapCenterSubject = new Subject();
  mainMapCenterRequest = this.mainMapCenterSubject.asObservable();

  private nodeCreatedSubject = new Subject();
  nodeCreatedRequest = this.nodeCreatedSubject.asObservable();

  private nodeDeletedSubject = new Subject();
  nodeDeletedRequest = this.nodeDeletedSubject.asObservable();

  private nodeDraggedSubject = new Subject();
  nodeDraggedRequest = this.nodeDraggedSubject.asObservable();

  private updateModelSubject = new Subject();
  updateModelRequest = this.updateModelSubject.asObservable();

  setStreamCategory(category: string) {
    this.streamCategory = category;
  }

  // region Subject handling
  clearCaseResults() {
    this.clearCaseSubject.next();
  }

  contextClickOnNode(nodeId: string) {
    this.contextClickOnNodeSubject.next(nodeId);
  }

  doubleClickOnNode(nodeId: string) {
    this.doubleClickOnNodeSubject.next(nodeId);
  }

  doubleClickOnLink(linkId: string) {
    this.doubleClickOnLinkSubject.next(linkId);
  }

  doubleClickOnWaterLink(linkId: string) {
    this.doubleClickOnWaterLinkSubject.next(linkId);
  }

  doubleClickOnFuelGasLink(linkId: string) {
    this.doubleClickOnFuelGasLinkSubject.next(linkId);
  }

  linkDeleted(linkData: any) {
    const inletId = this.gojsDiagram.model.findNodeDataForKey(linkData.from)['id'];
    const outletId = this.gojsDiagram.model.findNodeDataForKey(linkData.to)['id'];

    this.linkDeletedSubject.next({ id: linkData.id, inletUnitOpId: inletId, outletUnitOpId: outletId });
  }

  nodeCreated(nodeData: any) {
    this.nodeCreatedSubject.next(nodeData);
  }

  nodeDragged(nodeData: any) {
    this.nodeDraggedSubject.next(nodeData);
  }

  addNodeData(nodeData: any) {
    this.gojsDiagram.model.startTransaction('addNewNode');
    this.gojsDiagram.model.addNodeData(nodeData);
    this.gojsDiagram.model.commitTransaction('addNewNode');
    this.gojsDiagram.requestUpdate();
  }

  nodeDeleted(nodeId: string) {
    this.nodeDeletedSubject.next(nodeId);
  }

  /**
   * Clears the current diagram and creates a new gojs model
   * @param {Case} c
   */
  drawDiagram(c: Case) {
    this.updateModelSubject.next(c);
    this.caseToGoJSModel(c);
  }

  /**
   * Used after clearing results, as the name says, updates all the unit operations info and thus, updating
   * all possible data bindings in the diagram
   * @param {Case} c
   */
  updateUnitOperationsInfoOnDiagram(c: Case) {
    // eslint-disable-next-line guard-for-in
    for (const uoId in c.unitOperationPool) {
      this.saveUnitOperationOnModel(c.getUnitOperation(uoId));
    }
  }

  // endregion

  // region diagram logic
  saveUnitOperationOnModel(uo: UnitOperation, data?: any) {
    const gojsUo = new GojsUnitOperation(uo as SuncorUnitOperation);

    let node;
    if (data) {
      node = this.gojsDiagram.findNodeForData(data);
    } else {
      node = this.gojsDiagram.findNodesByExample({ id: uo.id }).first();
    }

    if (node) {
      const nodeData = node.data;
      this.gojsDiagram.model.startTransaction('save');
      this.gojsDiagram.model.setDataProperty(nodeData, 'id', gojsUo.id);
      this.gojsDiagram.model.setDataProperty(nodeData, 'name', gojsUo.name);
      this.gojsDiagram.model.setDataProperty(nodeData, 'readiness', gojsUo.readiness);
      this.gojsDiagram.model.setDataProperty(nodeData, 'constraintInfo', gojsUo.constraintInfo);
      if (uo instanceof WaterUtilityUnitOperation) {
        this.gojsDiagram.model.setDataProperty(nodeData, 'isSolved', gojsUo.isSolved);
      }
      if (uo instanceof FuelGasUtilityUnitOperation) {
        this.gojsDiagram.model.setDataProperty(nodeData, 'isSolved', gojsUo.isSolved);
      }

      this.gojsDiagram.model.commitTransaction('save');
    }
  }

  saveStreamOnModel(s: BaseStream) {
    const linkData = (this.gojsDiagram.model as GraphLinksModel).findLinkDataForKey(s.key);
    const gs = GojsStreamFactory.create(s);

    this.gojsDiagram.model.startTransaction('saveStream');
    this.gojsDiagram.model.setDataProperty(linkData, 'category', gs.category);
    this.gojsDiagram.model.setDataProperty(linkData, 'id', gs.id);
    this.gojsDiagram.model.setDataProperty(linkData, 'streamColor', gs.streamColor);
    this.gojsDiagram.model.setDataProperty(linkData, 'name', gs.name);
    this.gojsDiagram.model.setDataProperty(linkData, 'inletUnitOperationId', s.inletUnitOperationId);
    this.gojsDiagram.model.setDataProperty(linkData, 'outletUnitOperationId', s.outletUnitOperationId);
    this.gojsDiagram.model.setDataProperty(linkData, 'connectionName', gs.connectionName);
    this.gojsDiagram.model.commitTransaction('saveStream');
  }

  /**
   * Finds all nodes marked as non deletable and makes them deletable
   */
  makeAllUnitOperationsDeletable() {
    const nonDeletableNodes = this.gojsDiagram.findNodesByExample({ deletable: false });

    nonDeletableNodes.each(node => {
      this.gojsDiagram.model.startTransaction('makeDeletable');
      this.gojsDiagram.model.setDataProperty(node.data, 'deletable', true);
      this.gojsDiagram.model.commitTransaction('makeDeletable');
    });
  }

  /**
   * Sets a node's deletable attribute as false, which will prevent deletion in the diagram
   * @param {UnitOperation} uo
   */
  preventUnitOperationDeletion(uo: UnitOperation) {
    const nodeData = this.gojsDiagram.model.findNodeDataForKey(uo.key);
    this.gojsDiagram.model.startTransaction('save');
    this.gojsDiagram.model.setDataProperty(nodeData, 'deletable', false);
    this.gojsDiagram.model.commitTransaction('save');
  }

  linkDrawn(e: DiagramEvent) {
    if (e.subject.fromNode.data.category === unitOperationsConfig.splitter.key) {
      this.connectionFromSplitterSubject.next({
        splitterId: e.subject.fromNode.data.id,
        unitOperationId: e.subject.toNode.data.id,
      });
    } else if (e.subject.toNode.data.category === unitOperationsConfig.mixer.key) {
      this.connectionToMixerSubject.next(e.subject.toNode.data);
    }

    if (e.subject.fromNode.data.category === unitOperationsConfig.waterSplitter.key) {
      this.connectionFromSplitterSubject.next({
        splitterId: e.subject.fromNode.data.id,
        unitOperationId: e.subject.toNode.data.id,
      });
    }

    if (e.subject.fromNode.data.category === unitOperationsConfig.fuelGasSplitter.key) {
      this.connectionFromSplitterSubject.next({
        splitterId: e.subject.fromNode.data.id,
        unitOperationId: e.subject.toNode.data.id,
      });
    }

    if (e.subject.fromNode.data.category === unitOperationsConfig.productTank.key) {
      this.connectionFromSplitterSubject.next({
        splitterId: e.subject.fromNode.data.id,
        unitOperationId: e.subject.toNode.data.id,
      });
    }
    if (
      e.subject.fromNode.data.category === unitOperationsConfig.dru.key ||
      e.subject.fromNode.data.category === unitOperationsConfig.vac.key
    ) {
      this.cutRatioVariableConnectionSubject.next({
        id: e.subject.fromNode.data.id,
        unitOperationId: e.subject.toNode.data.id,
      });
    }

    if (e.subject.toNode.data.category === unitOperationsConfig.commodityTank.key) {
      this.connectionFromSplitterSubject.next({
        splitterId: e.subject.toNode.data.id,
        unitOperationId: e.subject.fromNode.data.id,
      });
    }

    if (e.subject.fromNode.data.category === unitOperationsConfig.bitumenConversion.key) {
      this.connectionFromBitumenConversionSubject.next({
        id: e.subject.fromNode.data.id,
        unitOperationId: e.subject.toNode.data.id,
      });
    }

    if (
      e.subject.toNode.data.category === unitOperationsConfig.hydrotreater.key ||
      e.subject.toNode.data.category === unitOperationsConfig.separatedHydrotreater.key
    ) {
      this.connectionFromSplitterSubject.next({
        splitterId: e.subject.toNode.data.id,
        unitOperationId: e.subject.fromNode.data.id,
      });
    }

    this.linkCreatedSubject.next({
      inletUnitOperationId: e.subject.fromNode.data.id,
      outletUnitOperationId: e.subject.toNode.data.id,
      id: e.subject.data.id,
      fromPort: e.subject.data.fromPort,
      toPort: e.subject.data.toPort,
      key: e.subject.data.linkKey,
      category: this.streamCategory,
      isRecycleStream: false,
    });
  }

  // endregion

  private caseToGoJSModel(c: Case) {
    const nodeDataArray = [];
    const linkDataArray = [];
    const keyLessNodes = [];

    // eslint-disable-next-line guard-for-in
    for (const uoId in c.unitOperationPool) {
      const o = new GojsUnitOperation(c.unitOperationPool[uoId] as SuncorUnitOperation);

      // the tanks created in the API don't have a key value.
      if (isNullOrUndefined(o.key) || <any>o.key === '') {
        delete o.key;
        keyLessNodes.push(o);
      } else {
        nodeDataArray.push(o);
      }
    }

    // first add the nodes that have a key defined
    this.gojsDiagram.clear(); // this will destroy all the undo actions so far
    this.gojsDiagram.model.startTransaction('updateModel');
    this.gojsDiagram.model.addNodeDataCollection(nodeDataArray);
    this.gojsDiagram.model.commitTransaction('updateModel');

    // then, add the nodes that don't have a key at all
    for (const node of keyLessNodes) {
      this.addNodeData(node);
      c.getUnitOperation(node.id).key = node.key;
    }

    this.gojsDiagram.model.startTransaction('updateModel');
    // then, add the streams considering the nodes that hadn't a key

    const streamIds = c.getAllSuncorMaterialStreamIds();

    for (const id of streamIds) {
      const stream = c.getSuncorMaterialStream(id);

      if (!stream) {
        return;
      }

      linkDataArray.push(GojsStreamFactory.create(stream));
    }

    (this.gojsDiagram.model as GraphLinksModel).addLinkDataCollection(linkDataArray);
    this.gojsDiagram.model.commitTransaction('updateModel');

    this.gojsDiagram.moveParts(this.gojsDiagram.nodes, new Point(0, 0), false);
  }

  updateRecycleStreamData(ms: Case) {
    // eslint-disable-next-line guard-for-in
    for (const sId in ms.materialStreamPool) {
      const stream = ms.materialStreamPool[sId];
      const link = this.gojsDiagram.findNodesByExample({ id: stream.id }).first();
      if (link) {
        this.gojsDiagram.model.startTransaction('updateStreamData');
        this.gojsDiagram.model.setDataProperty(link.data, 'isRecycleStream', stream.isRecycleStream);
        this.gojsDiagram.model.commitTransaction('updateStreamData');
      }
    }
  }

  updateStreamData(c: Case, skipSimVars?: boolean) {
    // I could not find another way to update the link's data, setting the data to a GojsStream instance
    // would not work properly.
    // I know it's ugly

    const streamIds = c.getAllMaterialStreamIds();
    for (const sId of streamIds) {
      const stream = c.getMaterialStream(sId);

      if (!stream) {
        return;
      }

      const gojsLink = this.gojsDiagram.findLinksByExample({ id: stream.id }).first();
      const gojsStream = GojsStreamFactory.create(stream);
      if (gojsLink) {
        this.gojsDiagram.model.startTransaction('updateStream');

        if (!skipSimVars) {
          this.gojsDiagram.model.setDataProperty(gojsLink.data, 'dashedLine', gojsStream.dashedLine);

          if (gojsStream instanceof GojsSuncorMaterialStream) {
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'volumetricFlowrate', gojsStream.volumetricFlowrate);
            this.gojsDiagram.model.setDataProperty(
              gojsLink.data,
              'volumetricFlowrateUnit',
              gojsStream.volumetricFlowrateUnit
            );
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'ghgIntensity', gojsStream.ghgIntensity);
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'ghgIntensityUnit', gojsStream.ghgIntensityUnit);
          } else if (gojsStream instanceof GojsWaterMaterialStream) {
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'massFlowrate', gojsStream.massFlowrate);
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'massFlowrateUnit', gojsStream.massFlowrateUnit);
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'temperature', gojsStream.temperature);
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'temperatureUnit', gojsStream.temperatureUnit);
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'pressure', gojsStream.pressure);
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'pressureUnit', gojsStream.pressureUnit);
          } else if (gojsStream instanceof GojsFuelGasMaterialStream) {
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'flowrate', gojsStream.flowrate);
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'flowrateUnit', gojsStream.flowrateUnit);
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'heatFlow', gojsStream.heatFlow);
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'heatFlowUnit', gojsStream.heatFlowUnit);
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'lhv', gojsStream.lhv);
            this.gojsDiagram.model.setDataProperty(gojsLink.data, 'lhvUnit', gojsStream.lhvUnit);
          }
        }

        this.gojsDiagram.model.setDataProperty(gojsLink.data, 'category', stream.category);
        this.gojsDiagram.model.setDataProperty(gojsLink.data, 'name', stream.name);
        this.gojsDiagram.model.setDataProperty(gojsLink.data, 'connectionName', gojsStream.connectionName);
        this.gojsDiagram.model.setDataProperty(gojsLink.data, 'inletUnitOperationId', stream.inletUnitOperationId);
        this.gojsDiagram.model.setDataProperty(gojsLink.data, 'outletUnitOperationId', stream.outletUnitOperationId);
        this.gojsDiagram.model.setDataProperty(gojsLink.data, 'isRecycleStream', stream.isRecycleStream);

        // this will actually synchronize the color from the stream object with the diagram model
        this.gojsDiagram.model.setDataProperty(gojsLink.data, 'streamColor', stream.streamColor);
        this.gojsDiagram.model.commitTransaction('updateStream');
      }
    }
  }

  zoomToFit() {
    this.gojsDiagram.zoomToFit();
  }

  hideDashedLineLinks() {
    this.gojsDiagram.links.each(link => {
      if (link.data.dashedLine) {
        link.opacity = 0;
      }
    });
  }

  showDashedLineLinks() {
    this.gojsDiagram.links.each(link => {
      if (link.data.dashedLine) {
        link.opacity = 1;
      }
    });
  }

  hideInactiveItems() {
    this.hideDashedLineLinks();
    this.gojsDiagram.nodes.each(node => {
      let hasActiveLinks = false;
      node.findLinksConnected().each(link => {
        if (!link.data.dashedLine) {
          hasActiveLinks = true;
        }
      });
      node.opacity = hasActiveLinks ? 1 : 0;
    });
  }

  showInactiveItems() {
    this.showDashedLineLinks();
    this.gojsDiagram.nodes.each(node => {
      node.opacity = 1;
    });
  }

  hideNodeLabels() {
    this.gojsDiagram.nodes.each(node => {
      node.data.hiddenLabel = true;
      node.updateTargetBindings();
    });
  }

  showNodeLabels() {
    this.gojsDiagram.nodes.each(node => {
      node.data.hiddenLabel = false;
      node.updateTargetBindings();
    });
  }

  getDiagramJson() {
    return this.gojsDiagram.model.toJson();
  }

  setDiagramModel(diagram: string) {
    this.gojsDiagram.model = Model.fromJson(diagram);
  }

  clearModel() {
    const nodeDataArray = [];
    // first add the nodes that have a key defined
    this.gojsDiagram.clear(); // this will destroy all the undo actions so far
    this.gojsDiagram.model.startTransaction('updateModel');
    this.gojsDiagram.model.addNodeDataCollection(nodeDataArray);
    this.gojsDiagram.model.commitTransaction('updateModel');
  }

  changeTemplate(unitOperationId: string, templateName: string) {
    const node = this.gojsDiagram.findNodesByExample({ id: unitOperationId }).first();
    this.gojsDiagram.model.setCategoryForNodeData(node.data, templateName);
  }

  deleteOutletStreams(unitOperationId: string) {
    const linksToDelete = [];
    const node = this.gojsDiagram.findNodesByExample({ id: unitOperationId }).first();
    const linksIterator = node.findLinksOutOf();
    linksIterator.each(link => {
      linksToDelete.push(link);
    });
    this.gojsDiagram.selectCollection(linksToDelete);
    this.gojsDiagram.commandHandler.deleteSelection();
  }

  updateUpgraders(upgraders: Upgrader[]) {
    upgraders.forEach(upgrader => {
      const node = this.gojsDiagram.findNodesByExample({ id: upgrader.id }).first();
      if (node.findPort('coker') !== node) {
        if (upgrader.hasTwoInlets) {
          this.changeTemplate(upgrader.id, unitOperationsConfig.multipleInletUpgrader.key);
        } else {
          this.changeTemplate(upgrader.id, unitOperationsConfig.level2Upgrader.key);
        }
        this.deleteInletStreams(upgrader.id);
        this.deleteOutletStreams(upgrader.id);
      }
      if (node.findPort('inlet1') !== node && upgrader.useSubFlowsheet) {
        this.changeTemplate(upgrader.id, unitOperationsConfig.flexibleUpgrader.key);
        this.deleteInletStreams(upgrader.id);
      }
    });
  }

  deleteInletStreams(unitOperationId: string) {
    const linksToDelete = [];
    const node = this.gojsDiagram.findNodesByExample({ id: unitOperationId }).first();
    const linksIterator = node.findLinksInto();
    linksIterator.each(link => {
      linksToDelete.push(link);
    });
    this.gojsDiagram.selectCollection(linksToDelete);
    this.gojsDiagram.commandHandler.deleteSelection();
  }

  zoomToUnitOperation(id: string) {
    const node = this.gojsDiagram.findNodesByExample({ id }).first();
    if (!node) {
      return;
    }
    this.gojsDiagram.select(node);
    const anim = new go.Animation();
    anim.add(node, 'scale', 1, 3);
    anim.reversible = true;
    anim.start();
    this.gojsDiagram.commandHandler.scrollToPart(node);
  }

  setMultiPeriodStyle(): void {
    $('#flowsheet-diagram-wrapper').css('background', '#D9D9D9');
  }

  setNormalStyle(): void {
    $('#flowsheet-diagram-wrapper').css('background', '#CCC');
  }

  setDiagramAsReadOnly(): void {
    this.gojsDiagram.isReadOnly = true;
  }

  setDiagramAsEditable(): void {
    this.gojsDiagram.isReadOnly = false;
  }
}
