import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { Case, CaseSettings, Project, UnitOperation } from '../_models';
import { ApiService } from './api.service';
import { FlowsheetDiagramService } from './flowsheet-diagram/flowsheet-diagram.service';
import { SuncorUnitOperation } from '../_models/_unit-operations/suncor-unit-operation';
import { LogManagerService } from '../flowsheet/log-manager/log-manager.service';
import { OldCaseValidator } from '../_models/_validation/old-case-validator';
import { ProgressIndicatorService } from '../flowsheet/progress-indicator/progress-indicator.service';
import { SendCaseModalService } from '../flowsheet/send-case-modal/send-case-modal.service';
import { SolveCaseRequest } from '../_models/solve-case-request';
import { AppInfo } from '../_config/app-info';
import { environment } from '../../environments/environment';
import { CaseDeserializerService } from './case-deserializer/case-deserializer.service';
import { ProjectDeserializerService } from './project-deserializer/project-deserializer.service';
import { SankeyDiagramService } from '../flowsheet/sankey-diagram/sankey-diagram.service';
import { Flowsheet } from '../_models/flowsheet-manager/flowsheet';
import { DRU } from '../_models/_unit-operations/dru';
import { VAC } from '../_models/_unit-operations/vac';
import { CommodityTank } from '../_models/_unit-operations/commodity-tank';
import { ProductTank } from '../_models/product-tank';
import { FileService } from './file.service';
import { Hydrotreater } from '../_models/_unit-operations/hydrotreater';
import { SuncorFluidAnalysis } from '../_models/_fluid/suncor-fluid-analysis';
import { ResourceDataService } from './resource-data.service';
import { BitumenConversion } from '../_models/_unit-operations/bitumen-conversion';
import { StreamFactory } from '../_models/_streams/stream-factory';
import { BaseStream } from '../_models/_streams/base-stream';
import { WaterSplitter } from '../_models/_unit-operations/utilties/water-splitter';
import { BaseObjectFormGroupMapper } from '../_mappers/base-object-form-group.mapper';
import { SimulationVariableMapper } from '../_mappers/simulation-variable-mapper';
import { BaseObjectFormGroupWrapper } from '../_form-utils/base-object-form-group-wrapper';
import { WaterHeader } from '../_models/_unit-operations/utilties/water-header';
import { GasFluidAnalysis } from '../_models/_fluid/gas-fluid-analysis';
import { FuelGasSplitter } from '../_models/_unit-operations/fuel-gas/fuel-gas-splitter';
import { isInletDistributionRatioVariablesOwner } from '../_models/_unit-operations/inlet-distribution-ratio-variables-owner';
import { isOutletDistributionRatioVariablesOwner } from '../_models/_unit-operations/outlet-distribution-ratio-variables-owner';

@Injectable()
export class CoreService {
  project: Project;
  currentCase: Case;

  // when base objects of a case change, this could be fired
  // TODO evaluate if this is still needed or if it is good enough to use
  // TODO the unitOperationAdded/Updated/Removed observables
  private caseChangedSubject = new Subject();
  caseChangedRequest = this.caseChangedSubject.asObservable();

  // when the current case is replaced (or big amount of data is updated)
  // fire this observable
  private currentCaseReplacedSubject = new Subject<{ wholeProjectReplaced: boolean }>();
  currentCaseReplacedRequest = this.currentCaseReplacedSubject.asObservable();

  private unitOperationAddedSubject = new Subject<SuncorUnitOperation | UnitOperation>();
  unitOperationAddedRequest = this.unitOperationAddedSubject.asObservable();

  private assayAddedSubject = new Subject<SuncorFluidAnalysis | GasFluidAnalysis>();
  assayAddedRequest = this.assayAddedSubject.asObservable();

  private unitOperationUpdatedSubject = new Subject<{ unitOperation: UnitOperation; changesInValues: boolean }>();
  unitOperationUpdatedRequest = this.unitOperationUpdatedSubject.asObservable();

  private assayUpdatedSubject = new Subject<{
    assay: SuncorFluidAnalysis | GasFluidAnalysis;
    changesInValues: boolean;
  }>();

  assayUpdatedRequest = this.assayUpdatedSubject.asObservable();

  private unitOperationRemovedSubject = new Subject<UnitOperation>();
  unitOperationRemovedRequest = this.unitOperationRemovedSubject.asObservable();

  private assayRemovedSubject = new Subject<SuncorFluidAnalysis | GasFluidAnalysis>();
  assayRemovedRequest = this.assayRemovedSubject.asObservable();

  private streamRemovedSubject = new Subject<BaseStream>();
  streamRemovedRequest = this.streamRemovedSubject.asObservable();

  private streamAddedSubject = new Subject<BaseStream>();
  streamAddedRequest = this.streamAddedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private flowsheetDiagramService: FlowsheetDiagramService,
    private logManager: LogManagerService,
    private progressIndicator: ProgressIndicatorService,
    private sendCaseModal: SendCaseModalService,
    private projectDeserializer: ProjectDeserializerService,
    private caseDeserializer: CaseDeserializerService,
    private fileService: FileService,
    private toastr: ToastrService,
    private resourceDataService: ResourceDataService,
    private sankeyDiagramService: SankeyDiagramService
  ) {
    this.resourceDataService.loadResourceFile();
    this.createEmptyProject(false);
    // TODO: Remove this
    (<any>window).service = this;

    this.flowsheetDiagramService.nodeCreatedRequest.subscribe(nodeData => {
      this.createUnitOpFromNodeData(nodeData);
    });

    // TODO remove this, subscribe to the streamAddedRequest instead
    this.flowsheetDiagramService.connectionFromSplitterRequest.subscribe((data: any) => {
      this.createSplitDistributionVariable(data.splitterId, data.unitOperationId);
    });

    // TODO remove this, subscribe to the streamAddedRequest instead
    this.flowsheetDiagramService.cutRatioVariableConnectionRequest.subscribe((data: any) => {
      this.createCutDistributionVariable(data.id, data.unitOperationId);
    });

    // TODO remove this, subscribe to the streamAddedRequest instead
    this.flowsheetDiagramService.connectionFromBitumenConversionRequest.subscribe((data: any) => {
      this.createBitumenConversionOutlet(data.id, data.unitOperationId);
    });

    this.flowsheetDiagramService.linkCreatedRequest.subscribe((data: any) => {
      this.addStream(data);
    });

    this.flowsheetDiagramService.linkDeletedRequest.subscribe((data: any) => {
      this.removeStream(data);
    });

    this.flowsheetDiagramService.clearCaseRequest.subscribe(() => {
      this.clearCurrentCaseResults();
    });

    this.flowsheetDiagramService.nodeDeletedRequest.subscribe((unitOperationId: string) => {
      this.removeUnitOperation(unitOperationId);
    });

    this.flowsheetDiagramService.nodeDraggedRequest.subscribe((unitOperationData: any) => {
      this.updateUnitOperationLocation(unitOperationData);
    });

    this.unitOperationUpdatedRequest.subscribe(data => {
      this.unitOperationUpdated(data.unitOperation, data.changesInValues);
    });
  }

  // TODO: Should this only create a new case?
  createEmptyProject(redrawDiagram: boolean): void {
    this.project = new Project();
    this.project.version = AppInfo.FILE_VERSION.toString();
    this.project.name = 'Project';

    this.currentCase = this.project.createEmptyCase();

    // TODO do not fire this observable here
    this.notifyCaseChanged();

    // TODO only fire this observable
    this.notifyCurrentCaseReplaced({ wholeProjectReplaced: true });

    if (redrawDiagram) {
      this.caseToGoJSModel(this.currentCase);
    }
  }

  // why this method includes so many units besides splitters.... man...
  createSplitDistributionVariable(splitterId: string, unitOperationId: string) {
    const splitter = this.currentCase.getUnitOperation(splitterId);

    if (splitter instanceof WaterSplitter) {
      splitter.addDistributionVariable(unitOperationId);
    } else if (splitter instanceof FuelGasSplitter) {
      splitter.addDistributionVariable(unitOperationId);
    } else if (splitter instanceof Hydrotreater) {
      // TODO why is the hydrotreater with the splitters?... restored as it was before
      // wat... Dewey get out of there, that's not your family
      splitter.addDistributionVariable(unitOperationId);
    } else if (splitter instanceof CommodityTank) {
      splitter.addDistributionVariable(unitOperationId);
    } else if (splitter instanceof ProductTank) {
      splitter.addDistributionVariable(unitOperationId);
    }
  }

  createCutDistributionVariable(nId: string, unitOperationId: string) {
    const uo = <DRU | VAC>this.currentCase.getUnitOperation(nId);
    uo.addDistributionVariable(unitOperationId);
  }

  createBitumenConversionOutlet(id: string, unitOperationId: string) {
    const uo = <BitumenConversion>this.currentCase.getUnitOperation(id);
    if (uo) {
      uo.addOutlet(unitOperationId);
    }
  }

  /**
   * Creates an unit operation using node data provided by gojs
   * @param nodeData An object containing the source data for the unit operation
   */
  createUnitOpFromNodeData(nodeData: any): void {
    // at this point nodeData has been modified by GoJS
    const uo = this.currentCase.createAndAddToUnitOperations(
      {
        category: nodeData.category,
        key: nodeData.key,
        name: nodeData.name,
        flowsheetId: nodeData.flowsheetId || this.currentCase.getActiveFlowsheet().unitOperationId,
        location: { x: nodeData.location.x, y: nodeData.location.y },
      },
      this.resourceDataService.getUOResourceData(nodeData.category)
    );

    uo.name = uo.ownerCase.calculateUnitOperationName(uo.name);
    this.flowsheetDiagramService.saveUnitOperationOnModel(uo, nodeData);

    this.unitOperationAddedSubject.next(uo);
    this.clearCurrentCaseResults();
  }

  /**
   * Copies an unit operation that exists on current case's current flowsheet and
   * returns it
   * @param id the id of the unit operation to copy
   * @returns {UnitOperation} A copy of an unit operation given its id
   */
  copyUnitOperationById(id: string): UnitOperation {
    return this.currentCase.cloneUnitOperation(this.currentCase.getUnitOperation(id));
  }

  /**
   * Saves an unit operation, writes the values from unitOperationCopy to a matching (by id) unit operation
   * stored on current case's current flowsheet
   * @param unitOperationCopy
   */
  saveUnitOperationFromTemplateDrivenForm(unitOperationCopy: UnitOperation): void {
    const originalUnitOperation = this.currentCase.getUnitOperation(unitOperationCopy.id);
    const originalName = originalUnitOperation.name;

    let changesInValues = false;
    if (unitOperationCopy.detectChanges(originalUnitOperation)) {
      changesInValues = true;
    }

    unitOperationCopy.updateStatus(originalUnitOperation);
    unitOperationCopy.convertToInternalUnit();

    this.currentCase.saveUnitOperation(<UnitOperation>unitOperationCopy);
    const changesInName = originalName !== unitOperationCopy.name;

    // results cleared in the end of the process
    if (changesInValues || changesInName) {
      this.notifyUnitOperationUpdated(originalUnitOperation, changesInValues);
    }
  }

  // TODO it looks like this should not exist
  saveUnitOperationWithReactiveFormWhenCustomCodeChange(
    unitOperationCopy: any,
    formGroupWrapper: BaseObjectFormGroupWrapper
  ): void {
    const originalUnitOperation = this.currentCase.getUnitOperation(unitOperationCopy.id);
    const changesInValues = formGroupWrapper.detectChangesInValues(originalUnitOperation.category);

    if (changesInValues) {
      this.notifyUnitOperationUpdated(unitOperationCopy, changesInValues);
    }
  }

  // TODO it looks like this should not exist
  saveUnitOperationWhenCustomCodeChange(unitOperationCopy: any): void {
    const originalUnitOperation = this.currentCase.getUnitOperation(unitOperationCopy.id);
    const changesInValues = unitOperationCopy.detectChanges(originalUnitOperation);

    if (changesInValues) {
      this.notifyUnitOperationUpdated(unitOperationCopy, changesInValues);
    }
  }

  saveUnitOperationFromReactiveForm(formGroupWrapper: BaseObjectFormGroupWrapper, unitOperationCopy: UnitOperation) {
    const svMapper = new SimulationVariableMapper();
    const mapper = new BaseObjectFormGroupMapper(svMapper);

    // TODO nice hack to allow change detection in name... should not be used REMOVE when not needed
    formGroupWrapper.updateBaseObjectNameOnFormGroup(unitOperationCopy.name);
    // at this point, the unit operation is a "copy" of the actual unit operation...
    const actualUnitOperation = this.currentCase.getUnitOperation(unitOperationCopy.id);

    const source = formGroupWrapper.getRawValue();
    mapper.updateVariableStatus(source, actualUnitOperation);
    mapper.map(source, actualUnitOperation);

    // changes can be detected earlier
    const changesInValues = formGroupWrapper.detectChangesInValues(unitOperationCopy.category);
    const changesInName = formGroupWrapper.detectChangesInName();
    if (changesInValues || changesInName) {
      this.notifyUnitOperationUpdated(actualUnitOperation as SuncorUnitOperation, changesInValues);
    }
  }

  /**
   *
   * @param unitOperation The updated unit operation
   * @param changesInValues False if the unit operation had changes only in its name property
   */
  unitOperationUpdated(unitOperation: UnitOperation, changesInValues: boolean) {
    if (changesInValues) {
      unitOperation.resetReadiness();
    }

    this.flowsheetDiagramService.saveUnitOperationOnModel(unitOperation);

    if (changesInValues) {
      this.clearCurrentCaseResults('Block specifications changed, your case is not updated, please re-run');
    }
  }

  notifyUnitOperationUpdated(unitOperation: UnitOperation, changesInValues: boolean) {
    this.unitOperationUpdatedSubject.next({ unitOperation, changesInValues });
  }

  notifyAssayUpdated(assay: SuncorFluidAnalysis | GasFluidAnalysis, changesInValues: boolean) {
    this.assayUpdatedSubject.next({ assay, changesInValues });
  }

  notifyAssayAdded(assay: SuncorFluidAnalysis | GasFluidAnalysis) {
    this.assayAddedSubject.next(assay);
  }

  notifyAssayDeleted(assay: SuncorFluidAnalysis | GasFluidAnalysis) {
    this.assayRemovedSubject.next(assay);
  }

  saveCaseSettings(cs: CaseSettings) {
    if (cs.detectChanges(this.currentCase.caseSettings)) {
      this.clearCurrentCaseResults();
    }

    this.currentCase.saveCaseSettings(cs);
  }

  saveStream(s: BaseStream) {
    // for now, this is only for updating the color on the gojs model
    this.flowsheetDiagramService.saveStreamOnModel(s);
  }

  saveCurrentFlowsheet() {
    if (!this.currentCase.getActiveFlowsheet()) {
      // eslint-disable-next-line no-console
      console.warn('The current case didnt have an active flowsheet... setting one');
      this.currentCase.setActiveFlowsheet(new Flowsheet({}, this.currentCase));
      this.currentCase.setMainFlowsheetId(this.currentCase.getActiveFlowsheet().id);
      this.currentCase.addToPools(this.currentCase.getActiveFlowsheet());
      this.flowsheetDiagramService.updateUnitOperationsInfoOnDiagram(this.currentCase);
    }

    // this seems kind of redundant
    this.currentCase.flowsheetPool[this.currentCase.getActiveFlowsheet().id].setDiagramJson(
      this.flowsheetDiagramService.getDiagramJson()
    );
  }

  /**
   *
   * @param streamData data coming from diagram service
   */
  private addStream(streamData: any) {
    const s = StreamFactory.create(streamData, this.currentCase);
    s.inletUnitOperationId = streamData.inletUnitOperationId;
    s.outletUnitOperationId = streamData.outletUnitOperationId;
    s.setName(this.currentCase.calculateMaterialStreamName('Stream'));
    s.setQuantities();
    s.setDefaultValues();
    s.isRecycleStream = streamData.isRecycleStream;
    this.currentCase.addToPools(s);
    s.addSimVarsToPool();
    this.flowsheetDiagramService.saveStreamOnModel(s);

    const { inletUnitOperation, outletUnitOperation } = s;
    if (inletUnitOperation instanceof WaterHeader) {
      inletUnitOperation.addDistributionVariable(s.outletUnitOperationId);
    }

    // TODO all unit operations that have distribution ratio variables should
    // implement this interface. Period.
    if (isInletDistributionRatioVariablesOwner(outletUnitOperation)) {
      outletUnitOperation.addInletDistributionRatioVariables(s.inletUnitOperationId);
    }

    if (isOutletDistributionRatioVariablesOwner(inletUnitOperation)) {
      inletUnitOperation.addOutletDistributionRatioVariables(s.outletUnitOperation.id);
    }

    this.streamAddedSubject.next(s);
  }

  private removeStream(streamData: { id: string }, unitOperationBeingDeleted?: UnitOperation) {
    const stream = this.currentCase.getMaterialStream(streamData.id);

    if (!stream) {
      return;
    }

    this.detachStream(stream, unitOperationBeingDeleted);

    this.streamRemovedSubject.next(stream);
    this.currentCase.deleteMaterialStreamById(stream.id);
  }

  /**
   * Remove all the variables that are created when a stream is connected
   * @param stream the stream to detach
   * @param unitOperationBeingDeleted the deleted unit operation causing the stream deletion
   */
  detachStream(stream: BaseStream, unitOperationBeingDeleted?: UnitOperation) {
    const inletUnitOp = stream.inletUnitOperation;
    const outletUnitOp = stream.outletUnitOperation;

    // TODO we can assume the detached unit operations have been modified
    // TODO if needed, this could be actually part of the case itself!
    if (unitOperationBeingDeleted !== inletUnitOp) {
      if (isOutletDistributionRatioVariablesOwner(inletUnitOp)) {
        inletUnitOp.removeOutletDistributionRatioVariables(outletUnitOp.id);
        this.unitOperationUpdatedSubject.next({ unitOperation: inletUnitOp, changesInValues: true });
      }

      if (inletUnitOp instanceof DRU || inletUnitOp instanceof VAC) {
        inletUnitOp.removeDistributionRatioVariable(stream.outletUnitOperationId);
        inletUnitOp.calculateDistributionVariables();
      } else if (inletUnitOp instanceof ProductTank) {
        inletUnitOp.removeDistributionRatioVariable(stream.outletUnitOperationId);
      } else if (inletUnitOp instanceof BitumenConversion) {
        inletUnitOp.removeOutlet(outletUnitOp.id);
      } else if (inletUnitOp instanceof WaterSplitter) {
        inletUnitOp.removeDistributionRatioVariable(stream.outletUnitOperationId);
      } else if (inletUnitOp instanceof FuelGasSplitter) {
        inletUnitOp.removeDistributionRatioVariable(stream.outletUnitOperationId);
      } else if (inletUnitOp instanceof WaterHeader) {
        inletUnitOp.removeDistributionRatioVariable(stream.outletUnitOperationId);
      }
    }

    // TODO we can assume the detached unit operations have been modified
    // TODO if needed, this could be actually part of the case itself!
    if (unitOperationBeingDeleted !== outletUnitOp) {
      if (outletUnitOp instanceof CommodityTank) {
        outletUnitOp.removeDistributionRatioVariable(stream.inletUnitOperationId);
      }
      if (outletUnitOp instanceof Hydrotreater) {
        outletUnitOp.removeDistributionRatioVariable(stream.inletUnitOperationId);
      }

      if (isInletDistributionRatioVariablesOwner(outletUnitOp)) {
        outletUnitOp.removeInletDistributionRatioVariables(inletUnitOp.id);
        this.unitOperationUpdatedSubject.next({ unitOperation: outletUnitOp, changesInValues: true });
      }
    }
  }

  /**
   * Calls to Case's deleteUnitOperation method, removes an unit
   * operation and all its references to case's pools
   *
   * @param id The id of the unit operation to delete
   */
  removeUnitOperation(id: string) {
    const uo = this.currentCase.getUnitOperation(id);

    // TODO delete attached streams here...
    const attachedStreams = this.currentCase.getAttachedStreams(id);

    for (const attachedStream of attachedStreams) {
      this.removeStream(attachedStream, uo);
    }

    this.unitOperationRemovedSubject.next(uo);

    if (uo.isFlowsheetOwner) {
      this.notifyAllChildUnitOperationsRemoved(uo);
    }

    // this is called from gojs in a diagram event.
    // the results are cleared in a GoJS callback
    this.currentCase.deleteUnitOperationById(id);

    // TODO streams should be deleted and proper events fired...
  }

  private notifyAllChildUnitOperationsRemoved(owner: UnitOperation) {
    const allChildUnitOperations = this.currentCase.findUnitOperationsByFlowsheetId(owner.id);
    for (const childUnitOperation of allChildUnitOperations) {
      if (childUnitOperation.isFlowsheetOwner) {
        console.log(
          childUnitOperation.name,
          'is a flowsheet owner',
          'lets notify about all the children being deleted'
        );
        this.notifyAllChildUnitOperationsRemoved(childUnitOperation);
      }

      this.unitOperationRemovedSubject.next(childUnitOperation);
    }
  }

  updateUnitOperationLocation(unitOperationData: any) {
    let uo: any = this.currentCase.getUnitOperation(unitOperationData.id);

    if (uo) {
      uo = uo as SuncorUnitOperation;
      uo.location.x = unitOperationData.location.x;
      uo.location.y = unitOperationData.location.y;
    }
  }

  /**
   * Deletes the results for the current case and notifies subscribers
   * @param message
   */
  clearCurrentCaseResults(message?: string) {
    if (this.currentCase.isSolved) {
      this.currentCase.clearResults();

      this.logManager.warning(message || 'Your case is not updated, please re-run');

      // TODO evaluate if this call should be kept here.
      this.notifyCaseChanged();

      // this.caseToGoJSModel(this.currentCase);
      this.flowsheetDiagramService.updateUnitOperationsInfoOnDiagram(this.currentCase);
      this.flowsheetDiagramService.updateStreamData(this.currentCase);
    }
  }

  /**
   * Serialize current project
   * For use before downloading a project file
   */
  projectToJson(): string {
    // update file version before downloading
    this.project.version = AppInfo.FILE_VERSION.toString();
    return JSON.stringify(this.project);
  }

  /**
   * Takes a case object that will be sent to GoJS component and there, will be translated to a model
   * @param c The case whose data will be used to create a new GoJS model
   */
  caseToGoJSModel(c: Case) {
    this.flowsheetDiagramService.drawDiagram(c);
  }

  parseSolvedResponse(solvedResponse: any): SolveCaseRequest {
    return {
      email: '',
      userId: '',
      solveOption: solvedResponse.solveOption,
      version: solvedResponse.version,
      username: solvedResponse.username,
      caseModel: this.caseDeserializer.plainObjectToCase(solvedResponse.caseModel),
    };
  }

  /**
   * Sends a pWater project to Web API
   */
  solveCase(): void {
    const validation = OldCaseValidator.validate(this.currentCase);
    // disabling makeReady code
    // const fullyConnected = this.flowsheetDiagramService.validateConnections(this.currentCase);
    // validation.valid = validation.valid &&  fullyConnected;
    if (!validation.valid) {
      for (const message of validation.messages) {
        this.logManager.error(message);
      }
      return;
    }

    this.logManager.info('Solving case.');
    this.logManager.info(`Objective function: ${this.currentCase.objectiveFunction.objectiveFunctionName}`);
    this.progressIndicator.show({ showStopButton: true, solveCanceller: this.apiService.solveCanceller }); // wot
    this.saveCurrentFlowsheet();

    if (this.currentCase.solveOption === 'sendResultsViaEmail') {
      this.sendSolveCaseAsyncRequest();
    } else {
      this.sendSolveCaseRequest();
    }
  }

  private sendSolveCaseAsyncRequest() {
    // this block to send whole file with diagrams
    const formData = this.getProjectFormData();
    this.apiService.solveCaseAsync(formData, this.currentCase.id).subscribe(
      response => {
        const result = response.solvedResponse;
        if (response.success) {
          this.toastr.success(result.message, '', { positionClass: 'toast-top-right' });
        } else {
          this.toastr.warning(result.message, '', { positionClass: 'toast-top-right' });
        }
        this.progressIndicator.hide();
      },
      () => {
        this.toastr.error('An internal error happened in the calculation engine', '', {
          positionClass: 'toast-top-right',
        });
        this.sendCaseModal.show();
        this.logManager.error('An internal error happened in the calculation engine.');
        this.progressIndicator.hide();
      }
    );
  }

  private sendSolveCaseRequest() {
    this.apiService.solveCase(this.project, this.currentCase.id).subscribe(response => {
      if (response.success) {
        this.caseSolveSuccess(response, response.backupFlowsheets, response.activeFlowsheet);
      } else {
        // this.sendCaseModal.setError(response.error);
        this.sendCaseModal.show();
        this.logManager.error('An internal error happened in the calculation engine.');
      }

      // TODO should this be fired here, only notifyCurrentCaseReplaced;
      this.notifyCaseChanged({ solveSuccess: this.currentCase.isSolved, isCaseReady: this.currentCase.isReady });
      this.notifyCurrentCaseReplaced();
    });
  }

  private caseSolveSuccess(response, backupFlowsheets?, activeFlowsheet?) {
    const { calculator } = this.currentCase;
    this.currentCase.removeFromPools(calculator);

    const solvedResponse = this.parseSolvedResponse(response.solvedResponse);

    const solvedCaseIndex = this.project.findCaseIndexById(solvedResponse.caseModel.id);
    this.project.cases[solvedCaseIndex] = solvedResponse.caseModel;
    this.currentCase = solvedResponse.caseModel;
    if (backupFlowsheets) {
      this.currentCase.flowsheetPool = backupFlowsheets;
    }
    if (activeFlowsheet) {
      this.currentCase.setActiveFlowsheet(this.currentCase.flowsheetPool[activeFlowsheet]);
    }

    if (this.currentCase.getActiveFlowsheet().isSubflowsheet()) {
      this.sankeyDiagramService.caseToSankeyDiagram(
        this.currentCase,
        this.currentCase.getActiveFlowsheet().unitOperationId,
        true
      );
    } else {
      this.sankeyDiagramService.caseToSankeyDiagram(this.currentCase);
    }
    this.flowsheetDiagramService.updateStreamData(this.currentCase);
    this.flowsheetDiagramService.updateRecycleStreamData(this.currentCase);
    this.flowsheetDiagramService.zoomToFit();
    this.flowsheetDiagramService.updateUnitOperationsInfoOnDiagram(this.currentCase);

    if (response.solvedResponse.caseModel.loggedMessages) {
      this.logManager.logSerializedMessages(response.solvedResponse.caseModel.loggedMessages);
    }

    if (!environment.production) {
      this.logManager.info(`Time to map the case ${response.solvedResponse.caseModel.timeProfile.timeToMap} ms`);
      this.logManager.info(`Time to solve the case ${response.solvedResponse.caseModel.timeProfile.timeToSolve} ms`);
      this.logManager.info(`Time to get a response from the API: ${Math.round(response.timeToGetResponse)} ms`);
    }

    // TODO dangerous assignment to the case's diagram
    //  when a subflowsheet is active that diagram is assigned to the case..
    this.updateCurrentCaseDiagramString();
  }

  sendCaseToProcessEcology(): Observable<any> {
    const formData = this.getProjectFormData();
    return this.apiService.sendCaseToProcessEcology(formData);
  }

  getProjectFormData(): Promise<FormData> {
    const formData = new FormData();

    // TODO dangerous assignment
    this.updateCurrentCaseDiagramString();
    return this.fileService.generateFile(this.project.cases, this.projectToJson()).then(content => {
      formData.append('project', content);
      return formData;
    });
  }

  setCurrentCaseById(id: string) {
    const newCurrentCase = this.project.findCaseById(id);
    // this is horribly wrong and dangerous - assumes that the case being changed has its main flowsheet open
    // why was this needed?
    // TODO dangerous assignment - probably the most dangerous
    this.updateCurrentCaseDiagramString();
    if (newCurrentCase) {
      this.setCurrentCase(newCurrentCase);
    }
  }

  setCurrentFlowsheet(flowsheet: Flowsheet) {
    this.currentCase.setActiveFlowsheet(flowsheet);
  }

  createFlowsheetAndSave(unitOperationId?) {
    return this.currentCase.createFlowsheet(unitOperationId);
  }

  setCurrentCase(c: Case) {
    this.currentCase = c;

    if (c.getDiagramString() !== 'undefined' && !!c.getDiagramString()) {
      this.flowsheetDiagramService.setDiagramModel(c.getDiagramString());
    } else {
      this.caseToGoJSModel(this.currentCase);
    }

    // TODO do not fire this here, only fire currentCaseReplacedSubject
    this.notifyCaseChanged();
    this.notifyCurrentCaseReplaced();
    this.logManager.info(`Case ${c.name} selected`);
  }

  // this is kind of dangerous
  updateCurrentCaseDiagramString() {
    this.currentCase.setDiagramString(this.flowsheetDiagramService.getDiagramJson());
  }

  notifyCurrentCaseReplaced(options?: { wholeProjectReplaced: boolean }) {
    this.currentCaseReplacedSubject.next(options);
  }

  notifyCaseChanged(p: { solveSuccess: boolean; isCaseReady: boolean } = null): void {
    if (p) {
      this.caseChangedSubject.next(p);
      return;
    }

    this.caseChangedSubject.next();
  }
}
