import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[sfeCollapsePanelIcon]',
})
export class CollapsePanelIconDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const $panel = jQuery(this.el.nativeElement.getAttribute('href'));
    const $link = jQuery(this.el.nativeElement);

    // eslint-disable-next-line func-names
    $panel.on('hidden.bs.collapse', function () {
      $link.find('i').removeClass('fa-minus').addClass('fa-plus');
    });

    // eslint-disable-next-line func-names
    $panel.on('shown.bs.collapse', function () {
      $link.find('i').removeClass('fa-plus').addClass('fa-minus');
    });
  }
}
