<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
  </li>

  <li role="presentation">
    <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
  </li>

  <li role="presentation">
    <a href="#thirdPartyRefineryComments" aria-controls="thirdPartyRefineryComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Cut Margin</h4>
        <div class="form-group">
          <label [attr.for]="'lightEndsMargin'" class="pw-label control-label col-sm-4"> Light Ends Margin </label>
          <sim-var-input [inputId]="'lightEndsMargin'" [simVar]="unitOperation.lightEndsMargin"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'naphthaMargin'" class="pw-label control-label col-sm-4"> Naphtha Margin </label>
          <sim-var-input [inputId]="'naphthaMargin'" [simVar]="unitOperation.naphthaMargin"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'distillateMargin'" class="pw-label control-label col-sm-4"> Distillate Margin </label>
          <sim-var-input [inputId]="'distillateMargin'" [simVar]="unitOperation.distillateMargin"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'gasOilMargin'" class="pw-label control-label col-sm-4"> Gas Oil Margin </label>
          <sim-var-input [inputId]="'gasOilMargin'" [simVar]="unitOperation.gasOilMargin"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'vacuumResidueMargin'" class="pw-label control-label col-sm-4">
            Vacuum Residue Margin
          </label>
          <sim-var-input [inputId]="'vacuumResidueMargin'" [simVar]="unitOperation.vacuumResidueMargin"></sim-var-input>
        </div>

        <hr />

        <div class="form-group">
          <label [attr.for]="'suncorProfitPercentage'" class="pw-label control-label col-sm-4">
            Suncor Profit Percentage
          </label>
          <sim-var-input
            [inputId]="'suncorProfitPercentage'"
            [simVar]="unitOperation.suncorProfitPercentage"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'volumetricFlowrate'" class="pw-label control-label col-sm-4"> Volumetric Flowrate </label>
          <sim-var-input
            [inputId]="'volumetricFlowrate'"
            [simVar]="unitOperation.volumetricFlowrate"
            [readOnly]="true"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'feedstockValue'" class="pw-label control-label col-sm-4"> Feedstock Value </label>
          <sim-var-input
            [inputId]="'feedstockValue'"
            [readOnly]="true"
            [simVar]="unitOperation.feedstockValue"></sim-var-input>
        </div>

        <hr />

        <h4 class="subtitle">GHG</h4>
        <div class="form-group">
          <label [attr.for]="'ghgIntensity'" class="pw-label control-label col-sm-4"> GHG Intensity </label>
          <sim-var-input
            [inputId]="'ghgIntensity'"
            [simVar]="unitOperation.ghgIntensity"
            [readOnly]="true"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'ghgEmissions'" class="pw-label control-label col-sm-4"> GHG Emissions </label>
          <sim-var-input
            [inputId]="'ghgEmissions'"
            [simVar]="unitOperation.ghgEmissions"
            [readOnly]="true"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'cumulativeGhgEmissions'" class="pw-label control-label col-sm-4">
            Cumulative GHG Emissions
          </label>
          <sim-var-input
            [inputId]="'cumulativeGhgEmissions'"
            [simVar]="unitOperation.cumulativeGhgEmissions"
            [readOnly]="true"></sim-var-input>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="constraints">
    <p style="text-align: right; position: relative">
      <span style="position: absolute; right: -25px; top: -20px; font-weight: bold">Active</span>
    </p>
    <div class="form-horizontal">
      <div class="form-group">
        <label [attr.for]="'capacity'" class="pw-label control-label col-sm-4"> Maximum Feed Capacity </label>
        <sim-var-input [inputId]="'capacity'" [isConstraint]="true" [simVar]="unitOperation.capacity"> </sim-var-input>
      </div>

      <div class="form-group">
        <label [attr.for]="'maximumSulfurContent'" class="pw-label control-label col-sm-4">
          Maximum Sulfur Content
        </label>
        <sim-var-input
          [inputId]="'maximumSulfurContent'"
          [isConstraint]="true"
          [simVar]="unitOperation.maximumSulfurContent">
        </sim-var-input>
      </div>

      <div class="form-group">
        <label [attr.for]="'maximumMassDensity'" class="pw-label control-label col-sm-4"> Maximum Mass Density </label>
        <sim-var-input
          [inputId]="'maximumMassDensity'"
          [isConstraint]="true"
          [simVar]="unitOperation.maximumMassDensity">
        </sim-var-input>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="thirdPartyRefineryComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
