export class DistributionRatioVariable {
  // should it be stream id?
  // could the same unit op be connected twice to a single splitter?
  unitOperationId: string;
  value?: number;

  constructor(unitOperationId: string, value?: number) {
    this.unitOperationId = unitOperationId;
    this.value = value;
  }
}
