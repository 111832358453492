import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { WaterSourceInformationStream } from '../../../_models/_unit-operations/utilties/water-source-information-stream';

@Component({
  selector: 'sob-water-source-information-stream-form',
  templateUrl: './water-source-information-stream-form.component.html',
  styleUrls: ['./water-source-information-stream-form.component.css'],
})
export class WaterSourceInformationStreamFormComponent {
  @Input() informationStream: WaterSourceInformationStream;
  @Input() informationStreamFormGroup: UntypedFormGroup;
  @Output() removeClicked = new EventEmitter<any>();
}
