import { Component, Input, OnInit } from '@angular/core';
import { WaterHeater } from '../../../_models/_unit-operations/utilties/water-heater';
import { TemperatureCalculationMode } from '../../../_config/temperature-calculation-mode.enum';

@Component({
  selector: 'sob-water-heater',
  templateUrl: './water-heater.component.html',
  styleUrls: ['./water-heater.component.css'],
})
export class WaterHeaterComponent implements OnInit {
  @Input() unitOperation: WaterHeater;

  ngOnInit() {
    if (this.unitOperation.temperatureCalculationMode === TemperatureCalculationMode.OUTLET_TEMPERATURE) {
      $('#selectMode').val(TemperatureCalculationMode.OUTLET_TEMPERATURE);
      $('#temperature-gain').hide();
      $('#outlet-temperature').show();
    } else if (this.unitOperation.temperatureCalculationMode === TemperatureCalculationMode.TEMPERATURE_GAIN) {
      $('#temperature-gain').show();
      $('#outlet-temperature').hide();
    }
  }

  temperatureCalculationModeChanged(calculationValue) {
    if (calculationValue === TemperatureCalculationMode.OUTLET_TEMPERATURE) {
      this.unitOperation.temperatureCalculationMode = TemperatureCalculationMode.OUTLET_TEMPERATURE;
      $('#temperature-gain').hide();
      $('#outlet-temperature').show();
    } else if (calculationValue === TemperatureCalculationMode.TEMPERATURE_GAIN) {
      this.unitOperation.temperatureCalculationMode = TemperatureCalculationMode.TEMPERATURE_GAIN;
      $('#temperature-gain').show();
      $('#outlet-temperature').hide();
    }
  }
}
