import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CoreService } from '../../../_services/core.service';
import { GasFluidAnalysis } from '../../../_models/_fluid/gas-fluid-analysis';
import { FuelGasSourceInformationStream } from '../../../_models/_unit-operations/fuel-gas/fuel-gas-source-information-stream';

@Component({
  selector: 'sob-fuel-gas-source-information-stream-form',
  templateUrl: './fuel-gas-source-information-stream-form.component.html',
  styleUrls: ['./fuel-gas-source-information-stream-form.component.css'],
})
export class FuelGasSourceInformationStreamFormComponent {
  @Input() informationStream: FuelGasSourceInformationStream;
  @Input() informationStreamFormGroup: UntypedFormGroup;
  currentAnalysis: GasFluidAnalysis;
  currentUnitOp: any;

  constructor(private coreService: CoreService) {}

  get fluidAnalyses() {
    return this.coreService.currentCase.assayManager.getAssaysByCategory('Gas');
  }

  getGasAnalysis() {
    this.informationStreamFormGroup.get('gasAnalysis').disable();
    this.currentUnitOp = this.coreService.currentCase.getUnitOperation(this.informationStream.providerUnitOperationId);
    const gasAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
      this.currentUnitOp.gasAnalysisId
    );
    return gasAnalysis ? gasAnalysis.assayName : '';
  }
}
