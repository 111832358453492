import { BaseObject } from '../base-object';
import { UncertainParameter } from './uncertain-parameter';
import { KpiResult } from './kpi-result';
import { ParameterSensitivity } from './parameter-sensitivity';
import { Case } from '../case';
import { SimulationVariable } from '../simulation-variable';

declare let unitConverter: any;

export class UncertaintyAnalysis extends BaseObject {
  category = 'uncertaintyAnalysis';
  uncertainParameters: Array<UncertainParameter> = [];
  sampleSize: number;
  parameterSensitivities: Array<ParameterSensitivity> = [];
  kpiResults: Array<KpiResult> = [];
  parametersToPool = true;

  constructor(uncertaintyAnalysis: any, ownerCase: Case) {
    super(uncertaintyAnalysis.id, ownerCase);
    this.name = uncertaintyAnalysis.name || '';

    if (uncertaintyAnalysis.uncertainParameters instanceof Array) {
      for (const up of uncertaintyAnalysis.uncertainParameters) {
        this.uncertainParameters.push(new UncertainParameter(up, this.ownerCase));
      }
    }
    if (uncertaintyAnalysis.parameterSensitivities instanceof Array) {
      for (const ps of uncertaintyAnalysis.parameterSensitivities) {
        if (ps instanceof ParameterSensitivity) {
          this.parameterSensitivities.push(ps);
        }
      }
    }
    if (uncertaintyAnalysis.kpiResults instanceof Array) {
      for (const kpiResult of uncertaintyAnalysis.kpiResults) {
        if (kpiResult instanceof KpiResult) {
          this.kpiResults.push(kpiResult);
        }
      }
    }
    this.sampleSize = uncertaintyAnalysis.sampleSize == null ? 100 : uncertaintyAnalysis.sampleSize;

    this.ownerCase.simulationVariableDeletedRequest.subscribe((sv: SimulationVariable) => {
      // first remove the results, then other stuff
      this.removeParameterSensitivityBySimulationVariable(sv);
      this.removeParameterBySimulationVariable(sv);
      this.removeKpiResultsByKpi(sv);
    });
    this.ownerCase.kpiDeletedRequest.subscribe((kpi: SimulationVariable) => {
      this.removeParameterSensitivityByKpi(kpi);
      this.removeKpiResultsByKpi(kpi);
    });
  }

  addParameterBySimulationVariableId(id: string) {
    if (this.ownerCase.getSimulationVariable(id)) {
      this.addParameterBySimulationVariable(this.ownerCase.getSimulationVariable(id));
    }
  }

  addParameterBySimulationVariable(sv: SimulationVariable): UncertainParameter {
    if (!this.findParameterBySimulationVariableId(sv.id)) {
      const up = new UncertainParameter({ simulationVariable: sv }, this.ownerCase);
      this.addParameter(up);
      return up;
    }

    return null;
  }

  addParameter(up: UncertainParameter) {
    this.uncertainParameters.push(up);
    if (this.parametersToPool) {
      this.ownerCase.addToPools(up);
      this.ownerCase.addToPools(up.mode);
      this.ownerCase.addToPools(up.minimumValue);
      this.ownerCase.addToPools(up.maximumValue);
    }
  }

  removeParameterById(id: string) {
    this.removeParameter(this.uncertainParameters.find(up => up.id === id));
  }

  removeParameterBySimulationVariable(sv: SimulationVariable) {
    const parameter = this.findParameterBySimulationVariableId(sv.id);
    if (parameter) {
      this.removeParameter(parameter);
    }
  }

  removeParameter(parameter: UncertainParameter) {
    const index = this.uncertainParameters.indexOf(parameter);
    if (index > -1) {
      const removedParameter = this.uncertainParameters.splice(index, 1)[0];
      this.removeParameterSensitivityByParameter(removedParameter);
      if (this.parametersToPool) {
        this.ownerCase.removeFromPools(removedParameter);
        this.ownerCase.removeFromPools(removedParameter.maximumValue);
        this.ownerCase.removeFromPools(removedParameter.minimumValue);
        this.ownerCase.removeFromPools(removedParameter.mode);
      }
    }
  }

  findParameterBySimulationVariableId(id: string) {
    return this.uncertainParameters.find(up => up.simulationVariable.id === id);
  }

  findParameterById(id: string): UncertainParameter {
    return this.uncertainParameters.find(up => up.id === id);
  }

  override overwriteValues(ua: UncertaintyAnalysis) {
    const parametersToRemove = this.uncertainParameters.filter((up: UncertainParameter) => {
      return ua.uncertainParameters.indexOf(ua.findParameterById(up.id)) < 0;
    });
    for (const p of parametersToRemove) {
      this.removeParameter(p);
    }
    for (const up of ua.uncertainParameters) {
      const existingParameter = this.findParameterBySimulationVariableId(up.simulationVariable.id);
      if (existingParameter) {
        existingParameter.overwriteValues(up);
      } else {
        this.addParameter(up);
      }
    }
    this.parameterSensitivities = [];
    for (const ps of ua.parameterSensitivities) {
      this.parameterSensitivities.push(ps);
    }
    this.kpiResults = [];
    for (const kpiResult of ua.kpiResults) {
      this.kpiResults.push(kpiResult);
    }
    this.sampleSize = ua.sampleSize;
  }

  override convertToInternalUnit(): void {
    for (const up of this.uncertainParameters) {
      // up.confidenceInterval = unitConverter.parseFloatString(up.confidenceInterval);
      up.convertToInternalUnit();
    }
    this.sampleSize = unitConverter.parseFloatString(this.sampleSize);
  }

  removeParameterSensitivityByKpi(kpi: SimulationVariable) {
    const resultsToRemove = this.parameterSensitivities.filter((ps: ParameterSensitivity) => ps.kpi.id === kpi.id);
    for (const ps of resultsToRemove) {
      this.parameterSensitivities.splice(this.parameterSensitivities.indexOf(ps), 1);
    }
  }

  removeParameterSensitivityByParameter(parameter: UncertainParameter) {
    const resultsToRemove = this.parameterSensitivities.filter((ps: ParameterSensitivity) => {
      return ps.parameter.id === parameter.id;
    });
    for (const ps of resultsToRemove) {
      this.parameterSensitivities.splice(this.parameterSensitivities.indexOf(ps), 1);
    }
  }

  removeParameterSensitivityBySimulationVariable(sv: SimulationVariable) {
    this.removeParameterSensitivityByKpi(sv);
    const p = this.findParameterBySimulationVariableId(sv.id);
    if (p) {
      this.removeParameterSensitivityByParameter(p);
    }
  }

  removeKpiResultsByKpi(kpi: SimulationVariable) {
    const resultsToRemove = this.kpiResults.filter(result => {
      return result.kpi.id === kpi.id;
    });
    for (const resultToRemove of resultsToRemove) {
      this.kpiResults.splice(this.kpiResults.indexOf(resultToRemove), 1);
    }
  }

  clearResults(): void {
    this.parameterSensitivities = [];
    this.kpiResults = [];
  }

  public addParametersToPool(): void {
    if (this.parametersToPool) {
      for (const p of this.uncertainParameters) {
        this.ownerCase.addToPools(p);
        this.ownerCase.addToPools(p.mode);
        this.ownerCase.addToPools(p.maximumValue);
        this.ownerCase.addToPools(p.minimumValue);
      }
    }
  }

  override dePersist(persistedObj: any): void {
    this.uncertainParameters = [];
    for (const pId of persistedObj.uncertainParameters) {
      const up = <UncertainParameter>this.ownerCase.getOtherBaseObject(pId);
      if (up) {
        this.uncertainParameters.push(up);
      }
    }
    this.dePersistResults(persistedObj);
  }

  public dePersistResults(persistedAnalysis: any) {
    for (const ps of persistedAnalysis.parameterSensitivities) {
      const kpi = this.ownerCase.getSimulationVariable(ps.kpi);
      const parameter = <UncertainParameter>this.ownerCase.getOtherBaseObject(ps.parameter);
      if (kpi && parameter) {
        ps.kpi = kpi;
        ps.parameter = parameter;
        this.parameterSensitivities.push(new ParameterSensitivity(ps));
      }
    }
    if (persistedAnalysis.kpiResults) {
      for (const kpiResult of persistedAnalysis.kpiResults) {
        const kpi = this.ownerCase.getSimulationVariable(kpiResult.kpi);
        if (kpi) {
          kpiResult.kpi = kpi;
          this.kpiResults.push(new KpiResult(kpiResult));
        }
      }
    }
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      category: this.category,
      uncertainParameters: this.uncertainParameters.map((up: UncertainParameter) => up.id),
      sampleSize: this.sampleSize,
      parameterSensitivities: this.parameterSensitivities,
      kpiResults: this.kpiResults,
    };
  }
}
