import { Quantity } from '../../../_config/quantity.enum';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Case } from '../../case';
import { SimulationVariable } from '../../simulation-variable';
import { FuelGasUnitOperation } from './fuel-gas-unit-operation';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';
import { KpiProvider } from '../../_case-study/kpi-provider';

declare let unitConverter: any;

export class FuelGasFlare extends FuelGasUnitOperation implements KpiProvider {
  category = unitOperationsConfig.fuelGasFlare.key;
  flowrate: SimulationVariable;
  fuelGasEmissionFactor: SimulationVariable;
  ghgEmissions: SimulationVariable;

  constructor(unitOperation: any | FuelGasFlare, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);
    this.flowrate = new SimulationVariable(unitOperation.flowrate || {}, this.ownerCase, this);
    this.fuelGasEmissionFactor = new SimulationVariable(
      unitOperation.fuelGasEmissionFactor || {},
      this.ownerCase,
      this
    );
    this.ghgEmissions = new SimulationVariable(unitOperation.ghgEmissions || {}, this.ownerCase, this);
    this.setQuantities();
  }

  override setSimulationVariableNames() {
    this.flowrate.setName(SimulationVariableName.FLOWRATE);
    this.fuelGasEmissionFactor.setName(SimulationVariableName.FUEL_GAS_EMISSION_FACTOR);
    this.ghgEmissions.setName(SimulationVariableName.GHG_EMISSIONS);
  }

  override setDefaultValues() {
    if (this.fuelGasEmissionFactor.isUndefined()) {
      this.fuelGasEmissionFactor.setDefaultValue(0.0702, unitConverter.units.MassEmissionsPerVolume.KG_M3);
    }
    if (this.ghgEmissions.isUndefined()) {
      this.ghgEmissions.setDefaultValue(null, unitConverter.units.Massflowrate.MMTPA);
    }
  }

  override setQuantities() {
    super.setQuantities();
    this.flowrate.setQuantity(Quantity.MOLARFLOWRATE);
    this.ghgEmissions.setQuantity(Quantity.MASSFLOWRATE);
    this.fuelGasEmissionFactor.setQuantity(Quantity.MASS_EMISSIONS_PER_VOLUME);
  }

  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [this.ghgEmissions];
    }
    return [this.flowrate, this.fuelGasEmissionFactor, this.ghgEmissions];
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.flowrate = this.getSimulationVariableSafe(sup.flowrate);
    this.fuelGasEmissionFactor = this.getSimulationVariableSafe(sup.fuelGasEmissionFactor);
    this.ghgEmissions = this.getSimulationVariableSafe(sup.ghgEmissions);
    this.setQuantities();
  }
}
