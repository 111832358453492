import * as go from 'gojs';
import { GoJsPaletteUtils } from './gojs-palette-utils';

export abstract class AbstractSubFlowsheetPaletteHandler {
  $ = go.GraphObject.make;

  constructor(protected paletteUtils: GoJsPaletteUtils) {}

  protected iconHeight: 55;
  protected iconWidth: 92;
  paletteContainerDivId: string;
  paletteDivId: string;

  subFlowsheetPalette: go.Palette;

  abstract getPaletteModel(): any[];

  init(paletteContainerDivId: string, paletteDivId: string): void {
    this.paletteContainerDivId = paletteContainerDivId;
    this.paletteDivId = paletteDivId;

    this.subFlowsheetPalette = this.paletteUtils.initPaletteElement(this.paletteDivId);
    this.subFlowsheetPalette.nodeTemplate = this.paletteUtils.getPaletteNodeTemplate();
    this.subFlowsheetPalette.model.nodeDataArray = this.getPaletteModel();
  }

  showPalette(): void {
    $(`#${this.paletteContainerDivId}`).show();
    $('#normalPalettes').hide();
    this.updatePalette();
  }

  updatePalette(): void {
    this.subFlowsheetPalette.model.nodeDataArray = this.getPaletteModel();
    this.subFlowsheetPalette.requestUpdate();
  }

  hidePalette(): void {
    $(`#${this.paletteContainerDivId}`).hide();
    $('#normalPalettes').show();
  }
}
