import { Injectable } from '@angular/core';
import { AbstractSubFlowsheetHandler } from './abstract-sub-flowsheet-handler';
import { UnitOperation } from '../../_models';
import { CoreService } from '../core.service';
import { FlowsheetDiagramService } from '../flowsheet-diagram/flowsheet-diagram.service';

@Injectable()
export class UtilitiesFlowsheetHandler extends AbstractSubFlowsheetHandler {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(flowsheetDiagramService: FlowsheetDiagramService, coreService: CoreService) {
    super(flowsheetDiagramService, coreService);
  }

  getDefaultNodeData(): any {
    return undefined;
  }

  getSubFlowsheetInlets(): UnitOperation[] {
    return [];
  }

  getSubFlowsheetOutlets(): UnitOperation[] {
    return [];
  }

  syncWhileFlowsheetLoading(): void {}

  syncAllBlocksInletOutletPorts(): void {}
}
