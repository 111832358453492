<div class="container-fluid">
  <div class="row" style="background-color: #ccc">
    <div class="col-md-3" style="padding-left: 0">
      <div class="bottom-tabpanel" style="display: none" id="overviewPanel">
        <div id="overview-container" style="padding-left: 15px">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <div class="ibox-tools">
                <a class="close-link close-bottom-tabpanel">
                  <i class="fa fa-chevron-down"></i>
                </a>
              </div>
              <h5>Overview</h5>
            </div>
            <div class="ibox-content">
              <input type="text" id="zoomSlider" name="zoomSlider" value="" />
              <div id="overview"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9" style="padding-left: 0">
      <div class="bottom-tabpanel" style="display: none" id="logManagerPanel">
        <div class="log-container">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <div class="ibox-tools">
                <a class="close-link close-bottom-tabpanel">
                  <i class="fa fa-chevron-down"></i>
                </a>
                <a title="Clear" (click)="clearLogManager()">
                  <i class="fa fa-trash-o"></i>
                </a>
              </div>
              <h5>Output</h5>
            </div>
            <div class="ibox-content">
              <sob-log-manager></sob-log-manager>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="lower-toolbar">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3" style="padding-left: 15px; padding-right: 29px">
        <div class="overview-tc" style="background-color: #fff; padding: 5px; box-shadow: #959595 4px 0px 5px 0px">
          <span
            class="inverted-tab"
            style="font-weight: 600; color: #676a6c; font-size: 13px"
            data-controls="#overviewPanel"
            (click)="clearLogCounter()">
            Overview <span class="label label-primary"><i class="fa fa-binoculars"></i></span>
            <div class="inline" style="display: inline-block"></div>
          </span>
        </div>
      </div>
      <div class="col-md-9" style="padding: 5px; background-color: #fff">
        <span class="inverted-tab" data-controls="#logManagerPanel" (click)="clearLogCounter()">
          Output
          <div class="inline" style="display: inline-block">
            <span id="logCounter" class="badge badge-warning"></span>
          </div>
        </span>
        <span class="separator"></span>

        <sob-case-status-indicator></sob-case-status-indicator>

        <div class="inline" style="display: inline-block; margin-left: 5px">
          <span class="label label-info" style="font-size: 11px">{{ currentProject.fileName }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
