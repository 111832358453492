<div [formGroup]="cutFormGroup">
  <div class="form-group" formGroupName="sulfur">
    <label class="control-label col-sm-4"> Sulfur </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="cut.sulfur.quantity"></sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="cut.sulfur">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="anilinePoint">
    <label class="control-label col-sm-4"> Aniline Point </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="cut.anilinePoint.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="cut.anilinePoint">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="aromaticityFactor" *ngIf="isStream">
    <label class="control-label col-sm-4"> Aromaticity Factor </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="cut.aromaticityFactor.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="cut.aromaticityFactor">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="massDensity">
    <label class="control-label col-sm-4"> Mass Density </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="cut.massDensity.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="cut.massDensity">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="nitrogen">
    <label class="control-label col-sm-4"> Nitrogen </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="cut.nitrogen.quantity">
          </sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="cut.nitrogen">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="ccr">
    <label class="control-label col-sm-4"> CCR </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="cut.ccr.quantity"></sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="cut.ccr">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" formGroupName="afc" *ngIf="isStream">
    <label class="control-label col-sm-4"> AFC </label>

    <div class="col-sm-8" sobReactiveSimVarInput>
      <div class="row">
        <div class="col-xs-6">
          <sob-reactive-unit-select formControlName="unit" [quantity]="cut.afc.quantity"></sob-reactive-unit-select>
        </div>
        <div class="col-xs-6">
          <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="cut.afc">
          </sob-reactive-variable-value-input>
        </div>
      </div>
    </div>
  </div>
</div>
