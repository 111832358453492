import { SimulationVariable } from '../simulation-variable';
import { Case } from '../case';
import { KpiProvider } from '../_case-study/kpi-provider';
import { BaseObject } from '../base-object';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { SuncorUnitOpWithCogen } from './suncor-unit-op-with-cogen';
import { MultiPeriodParameterProvider } from '../_multi-period/multi-period-parameter-provider';
import { ParameterProvider } from '../_case-study/parameter-provider';

declare let unitConverter: any;

export class OffshoreSource
  extends SuncorUnitOpWithCogen
  implements KpiProvider, ParameterProvider, MultiPeriodParameterProvider
{
  category = unitOperationsConfig.offshore.key;

  volumetricFlowrate: SimulationVariable;
  absoluteGhgEmissions: SimulationVariable;
  price: SimulationVariable;
  fluidAnalysisId: string;

  /**
   *
   */
  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override addSimVarsToPool() {
    super.addSimVarsToPool();
  }

  override overwriteValues(another: BaseObject) {
    super.overwriteValues(another);
  }

  getAvailableKpis() {
    return [this.cogenElectricityGeneration, this.absoluteGhgEmissions, this.volumetricFlowrate, this.price];
  }

  getAvailableMultiPeriodParameters(): SimulationVariable[] {
    return [this.volumetricFlowrate];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [
      this.volumetricFlowrate,
      this.ghgIntensity,
      this.cogenElectricityGeneration,
      this.netElectricityExport,
      this.cogenIntensity,
      this.gridIntensity,
      this.price,
      this.electricityPrice,
      this.opexVar,
      this.opexGasDiesel,
      this.opexPower,
      this.opexFixed,
      this.capexAmortized,
    ];
  }

  override clearResults() {
    super.clearResults();
    this.absoluteGhgEmissions.value = null;
    this.ghgEmissions.value = null;
    this.revenue.value = null;
    this.unitValue.value = null;
    this.opexCarbonDioxide.value = null;
    this.opexTotal.value = null;
    this.capexTotal.value = null;
  }

  override initValues(unitOperation: any): void {
    super.initValues(unitOperation);
    this.volumetricFlowrate = new SimulationVariable(unitOperation.volumetricFlowrate, this.ownerCase, this);
    this.absoluteGhgEmissions = new SimulationVariable(unitOperation.absoluteGhgEmissions, this.ownerCase, this);
    this.price = new SimulationVariable(unitOperation.price, this.ownerCase, this);

    this.fluidAnalysisId = unitOperation.fluidAnalysisId || '';
  }

  override setSimulationVariableNames(): void {
    super.setSimulationVariableNames();
    this.volumetricFlowrate.setName('Volumetric Flowrate');
    this.absoluteGhgEmissions.setName('Absolute GHG Emissions');
    this.cogenElectricityGeneration.setName('Cogen Electricity Generation');
    this.price.setName('Product Price');
  }

  override setDefaultValues(): void {
    super.setDefaultValues();
    if (this.volumetricFlowrate.isUndefined()) {
      this.volumetricFlowrate.setDefaultValue(0.0);
    }
    if (this.absoluteGhgEmissions.isUndefined()) {
      this.absoluteGhgEmissions.setDefaultValue(0.0, unitConverter.units.Massflowrate.MMTPA);
    }
    if (this.price.isUndefined()) {
      this.price.setDefaultValue(50.0);
    }
  }

  override setQuantities() {
    super.setQuantities();
    this.volumetricFlowrate.setQuantity('Volumetricflowrate');
    this.absoluteGhgEmissions.setQuantity('Massflowrate');
    this.price.setQuantity('CurrencyPerVolume');
  }

  override convertToInternalUnit() {
    super.convertToInternalUnit();
  }

  getAlternativeGhgIntensity() {
    return new SimulationVariable(this.ghgIntensity, this.ownerCase, this);
  }

  getAvailableParameters() {
    return [
      this.volumetricFlowrate,
      this.ghgIntensity,
      this.price,
      this.opexVar,
      this.opexGasDiesel,
      this.opexPower,
      this.opexFixed,
    ];
  }

  override ignoreForMakeReady(simvar: SimulationVariable): boolean {
    return super.ignoreForMakeReady(simvar) || simvar === this.capacity || simvar === this.minimumFlow;
  }

  override dePersist(sup: any) {
    super.dePersist(sup);
    this.volumetricFlowrate = this.getSimulationVariableSafe(sup.volumetricFlowrate);
    this.absoluteGhgEmissions = this.getSimulationVariableSafe(sup.absoluteGhgEmissions);
    this.price = this.getSimulationVariableSafe(sup.price);

    this.setQuantities();
    this.setDefaultValues();
  }
}
