import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommentsService } from '../../_services/helpers/comments.service';
import { BaseObjectComment } from '../../_models/base-object-comment';

@Component({
  selector: 'sob-unit-operation-comments',
  templateUrl: './unit-operation-comments.component.html',
  styleUrls: ['./unit-operation-comments.component.css'],
})
export class UnitOperationCommentsComponent implements OnInit {
  @Input() unitOpId: string;
  commentsGroup: UntypedFormGroup;
  unitOpComments: BaseObjectComment[];

  constructor(private fb: UntypedFormBuilder, private commentsService: CommentsService) {}

  ngOnInit(): void {
    this.unitOpComments = this.commentsService.getCommentsByOwnerId(this.unitOpId);
    this.addControl();
  }

  addControl() {
    this.commentsGroup = this.fb.group({
      commentArea: this.fb.control('' || '', [Validators.required]),
      commentsArray: this.fb.array(this.fillArrayComments()),
    });
  }

  fillArrayComments() {
    return this.unitOpComments.map(dv => {
      return this.addCommentControl(dv);
    });
  }

  addCommentControl(comment: BaseObjectComment) {
    const control = this.fb.group({
      text: this.fb.control(comment.text),
      confirm: this.fb.control(''),
    });
    control.get('text').disable();
    control.get('confirm').disable();
    return control;
  }

  addComment() {
    const text = this.commentsGroup.get('commentArea').value;
    if (text) {
      const comment = this.commentsService.addComment(this.unitOpId, text); // inserting in pool
      const control = this.addCommentControl(comment);
      this.unitOpComments.unshift(comment); // inserting in array coments
      (this.commentsGroup.get('commentsArray') as UntypedFormArray).insert(0, control); // inserting in arrayControls
      this.commentsGroup.get('commentArea').reset();
    }
  }

  get commentsFormGroupArray() {
    const array = this.commentsGroup.get('commentsArray') as UntypedFormArray;
    return array.controls;
  }

  isControlDisabled(i: number, control: string) {
    return (this.commentsGroup.get('commentsArray') as UntypedFormArray).at(i).get(control).disabled;
  }

  isSameUser(userControl: string) {
    const serializedUser = localStorage.getItem('user');
    const currentUser = JSON.parse(serializedUser || '{}').userName;
    return currentUser === userControl;
  }

  enableTextArea(i: number) {
    const area = (this.commentsGroup.get('commentsArray') as UntypedFormArray).at(i).get('text');
    area.enable();
  }

  updateComment(i: number) {
    const newText = (this.commentsGroup.get('commentsArray') as UntypedFormArray).at(i).get('text').value;
    const comment = this.unitOpComments[i];
    this.commentsService.updateComment(comment, newText);
    (this.commentsGroup.get('commentsArray') as UntypedFormArray).at(i).get('text').disable();
  }

  confirmDelete(i: number) {
    this.updateComment(i);
    (this.commentsGroup.get('commentsArray') as UntypedFormArray).at(i).get('confirm').enable();
  }

  cancelDelete(i) {
    (this.commentsGroup.get('commentsArray') as UntypedFormArray).at(i).get('confirm').disable();
  }

  deleteComment(i: number) {
    const { id } = this.unitOpComments[i];
    this.commentsService.removeComment(id); // removing from pool
    this.unitOpComments.splice(i, 1); // removing from arraycoments
    (this.commentsGroup.get('commentsArray') as UntypedFormArray).removeAt(i); // removing from arrayControls
  }

  formatDate(date) {
    const padTo2Digits = num => num.toString().padStart(2, '0');
    return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/');
  }
}
