import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import * as go from 'gojs';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { FlowsheetService } from '../../_services/flowsheet.service';
import { MultiPeriodService } from '../../_services/multi-period/multi-period.service';
import { GoJsPaletteUtils } from '../../_services/flowsheet-palette/gojs-palette-utils';
import { UpgraderPaletteHandler } from '../../_services/flowsheet-palette/upgrader-palette-handler';
import { AbstractSubFlowsheetPaletteHandler } from '../../_services/flowsheet-palette/abstract-sub-flowsheet-palette-handler';
import { FirstLevelPaletteModelProvider } from '../../_services/flowsheet-palette/first-level-palette-model-provider';
import { ExtractionPaletteHandler } from '../../_services/flowsheet-palette/extraction-palette-handler.service';
import { UtilitiesPaletteHandler } from '../../_services/flowsheet-palette/utilities-palette-handler';
import { CoreService } from '../../_services/core.service';
import { SubFlowsheetService } from '../../_services/sub-flowsheet/sub-flowsheet.service';
import { FuelGasPaletteHandler } from '../../_services/flowsheet-palette/fuel-gas-palette-handler';
import { FlowsheetTreeService } from '../../_services/sub-flowsheet/flowsheet-tree/flowsheet-tree.service';

@Component({
  selector: 'sob-flowsheet-diagram-palette',
  templateUrl: './flowsheet-diagram-palette.component.html',
  styleUrls: ['./flowsheet-diagram-palette.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FlowsheetDiagramPaletteComponent implements AfterViewInit {
  sources: go.Palette;
  processing: go.Palette;
  transport: go.Palette;
  connectors: go.Palette;

  subFlowsheetPaletteHandlers: { [key: string]: AbstractSubFlowsheetPaletteHandler } = {};
  activeSubFlowsheetPaletteHandler: AbstractSubFlowsheetPaletteHandler;

  constructor(
    private multiPeriodService: MultiPeriodService,
    private coreService: CoreService,
    private flowsheetService: FlowsheetService,
    private subFlowsheetService: SubFlowsheetService,
    private flowsheetTree: FlowsheetTreeService,
    private modelProvider: FirstLevelPaletteModelProvider,
    private paletteUtils: GoJsPaletteUtils,
    private upgraderPaletteHandler: UpgraderPaletteHandler,
    private utilitiesPaletteHandler: UtilitiesPaletteHandler,
    private fuelGasPaletteHandler: FuelGasPaletteHandler,
    private extractionPaletteHandler: ExtractionPaletteHandler
  ) {
    this.subFlowsheetPaletteHandlers[unitOperationsConfig.upgrader.key] = upgraderPaletteHandler;
    this.subFlowsheetPaletteHandlers[unitOperationsConfig.waterUtilityUnitOperation.key] = utilitiesPaletteHandler;
    this.subFlowsheetPaletteHandlers[unitOperationsConfig.fuelGasUtilityUnitOperation.key] = fuelGasPaletteHandler;
    this.subFlowsheetPaletteHandlers[unitOperationsConfig.extraction.key] = extractionPaletteHandler;

    this.coreService.currentCaseReplacedRequest.subscribe(() => {
      this.onCurrentCaseReplaced();
    });

    this.flowsheetService.subFlowsheetOpenedRequest.subscribe((unitOperationCategory: string) => {
      this.changePaletteHandler(unitOperationCategory);
    });

    this.flowsheetService.subFlowsheetClosedRequest.subscribe(() => {
      this.changePaletteHandler(this.subFlowsheetService.currentSubFlowsheetCategory);
    });

    this.multiPeriodService.multiPeriodModeEnabledRequest.subscribe(options => {
      if (options.multiPeriodEnabled) {
        this.hidePalette();
        this.hidePaletteButton();
      } else {
        this.showPalette();
        this.showPaletteButton();
      }
    });

    this.coreService.unitOperationAddedRequest.subscribe(uo => {
      if (
        uo.category === unitOperationsConfig.waterUtilityUnitOperation.key ||
        uo.category === unitOperationsConfig.fuelGasUtilityUnitOperation.key
      ) {
        this.upgraderPaletteHandler.updatePaletteNodeByAction(uo.category, 'disable');
      }
    });

    this.coreService.unitOperationRemovedRequest.subscribe(uo => {
      if (
        uo.category === unitOperationsConfig.waterUtilityUnitOperation.key ||
        uo.category === unitOperationsConfig.fuelGasUtilityUnitOperation.key
      ) {
        this.upgraderPaletteHandler.updatePaletteNodeByAction(uo.category, 'enable');
      }
    });
  }

  ngAfterViewInit() {
    this.initPalettes();
  }

  private initPalettes() {
    this.sources = this.paletteUtils.initPaletteElement('sources');
    this.sources.nodeTemplate = this.paletteUtils.getPaletteNodeTemplate();
    this.sources.model.nodeDataArray = this.modelProvider.getSourcesModel();

    this.processing = this.paletteUtils.initPaletteElement('processing');
    this.processing.nodeTemplate = this.paletteUtils.getPaletteNodeTemplate();
    this.processing.model.nodeDataArray = this.modelProvider.getProcessingModel();

    this.transport = this.paletteUtils.initPaletteElement('transport');
    this.transport.nodeTemplate = this.paletteUtils.getPaletteNodeTemplate();
    this.transport.model.nodeDataArray = this.modelProvider.getTransportModel();

    this.connectors = this.paletteUtils.initPaletteElement('connectors');
    this.connectors.nodeTemplate = this.paletteUtils.getPaletteNodeTemplate();
    this.connectors.model.nodeDataArray = this.modelProvider.getConnectorsModel();

    this.upgraderPaletteHandler.init('upgraderPaletteContainer', 'generalUpgraderPalette');
    this.upgraderPaletteHandler.init('upgraderPaletteContainer', 'processingUpgraderPalette');
    this.upgraderPaletteHandler.init('upgraderPaletteContainer', 'reactorsUpgraderPalette');
    this.upgraderPaletteHandler.init('upgraderPaletteContainer', 'utilitiesAndEnvironmentalUpgraderPalette');

    this.utilitiesPaletteHandler.init('utilitiesPaletteContainer', 'utilitiesPalette');
    this.fuelGasPaletteHandler.init('fuelGasPaletteContainer', 'fuelGasPalette');
    this.extractionPaletteHandler.init('extractionPaletteContainer', 'extractionPalette');
  }

  private changePaletteHandler(unitOperationCategory: string) {
    if (this.activeSubFlowsheetPaletteHandler) {
      this.activeSubFlowsheetPaletteHandler.hidePalette();
    }

    if (unitOperationCategory) {
      this.activeSubFlowsheetPaletteHandler = this.subFlowsheetPaletteHandlers[unitOperationCategory];
      this.activeSubFlowsheetPaletteHandler.showPalette();
      if (unitOperationCategory === unitOperationsConfig.upgrader.key) {
        this.disableWaterOrGasPaletteNodesInExistingCase();
      }
    } else {
      this.activeSubFlowsheetPaletteHandler = undefined;
    }
  }

  private onCurrentCaseReplaced(): void {
    const subFlowsheetCategory = this.subFlowsheetService.currentSubFlowsheetCategory;
    this.changePaletteHandler(subFlowsheetCategory);
    /* if (subFlowsheetCategory) {
      if (this.activeSubFlowsheetPaletteHandler) {
        this.activeSubFlowsheetPaletteHandler.hidePalette();
      }

      this.enablePaletteHandler(subFlowsheetCategory);
    } else {
      if (!this.activeSubFlowsheetPaletteHandler) {
        return;
      }

      this.disablePaletteHandler();
    } */
  }

  private enablePaletteHandler(unitOperationCategory) {
    this.activeSubFlowsheetPaletteHandler = this.subFlowsheetPaletteHandlers[unitOperationCategory];
    this.activeSubFlowsheetPaletteHandler.showPalette();
  }

  private disablePaletteHandler(): void {
    this.activeSubFlowsheetPaletteHandler.hidePalette();
    this.activeSubFlowsheetPaletteHandler = undefined;
  }

  togglePalette() {
    $('#paletteWrapper').toggle('slide', { direction: 'right' }, 500, () => {});
  }

  hidePalette(): void {
    if ($('#paletteWrapper').is(':visible')) {
      this.togglePalette();
    }
  }

  hidePaletteButton(): void {
    $('#paletteButton').hide();
  }

  showPalette(): void {
    if ($('#paletteWrapper').is(':hidden')) {
      this.togglePalette();
    }
  }

  showPaletteButton(): void {
    $('#paletteButton').show();
  }

  disableWaterOrGasPaletteNodesInExistingCase() {
    const flowsheetTree = this.flowsheetTree.getFlowsheetTree(this.coreService.currentCase).root.children;
    flowsheetTree.forEach(parentNode => {
      if (parentNode.id === this.subFlowsheetService.currentSubFlowsheetId) {
        let waterUtilityCount = 0;
        let fuelGasUtilityCount = 0;
        parentNode.children.forEach(childNode => {
          if (childNode.unitOperation.category === unitOperationsConfig.waterUtilityUnitOperation.key) {
            waterUtilityCount++;
          } else if (childNode.unitOperation.category === unitOperationsConfig.fuelGasUtilityUnitOperation.key) {
            fuelGasUtilityCount++;
          }
        });
        if (waterUtilityCount > 0) {
          this.upgraderPaletteHandler.updatePaletteNodeByAction(
            unitOperationsConfig.waterUtilityUnitOperation.key,
            'disable'
          );
        }
        if (fuelGasUtilityCount > 0) {
          this.upgraderPaletteHandler.updatePaletteNodeByAction(
            unitOperationsConfig.fuelGasUtilityUnitOperation.key,
            'disable'
          );
        }
      }
    });
  }
}
