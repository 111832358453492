import { SimulationVariableMapper } from './simulation-variable-mapper';
import { BaseObject } from '../_models/base-object';
import { SimulationVariable } from '../_models';

export class DefaultBaseObjectFormGroupMapper {
  constructor(protected simVarMapper: SimulationVariableMapper) {}

  map(source: BaseObject, destination: BaseObject) {
    const keys = Object.keys(source);

    for (const key of keys) {
      const currentValue = destination[key];

      if (this.ignoreForAutoMapping(key, source, destination)) {
        continue;
      }

      if (currentValue instanceof SimulationVariable) {
        this.simVarMapper.map(source[key], currentValue);
      } else {
        destination[key] = source[key];
      }
    }
  }

  ignoreForAutoMapping(key: string, source: BaseObject, destination: BaseObject): boolean {
    return typeof destination[key] === 'function';
  }

  updateVariableStatus(source: BaseObject, destination: BaseObject) {
    // eslint-disable-next-line guard-for-in
    for (const key in destination) {
      const property = destination[key];

      if (property instanceof SimulationVariable && source.hasOwnProperty(key)) {
        const simVarData = source[key];
        const otherSimVar = new SimulationVariable(simVarData, null, null);
        otherSimVar.setQuantity(property.quantity);
        property.updateVariableStatus(otherSimVar);
      }
    }
  }
}
