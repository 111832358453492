import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { RangeDistributionRatioVariable } from '../../../_models/_unit-operations/range-distribution-ratio-variable';
import { RangeDistributionRatioVariableForm } from '../../../_form-utils/range-distribution-ratio-variable-form-builder';
import { CoreService } from '../../../_services/core.service';
import { roundNumber } from '../../../_utils/utils';

declare let unitConverter: any;

@Component({
  selector: 'sob-reactive-range-distribution-ratio-variables',
  templateUrl: './reactive-range-distribution-ratio-variables.component.html',
  styleUrls: ['./reactive-range-distribution-ratio-variables.component.css'],
})
export class ReactiveRangeDistributionRatioVariablesComponent implements OnInit {
  @Input() ratioVariables!: RangeDistributionRatioVariable[];
  @Input() valueControlsReadonly!: boolean;
  @Input() readonly = false;
  @Input() ratioVariablesFormGroup!: FormGroup<{ ratioVariables: FormArray<RangeDistributionRatioVariableForm> }>;

  constructor(private core: CoreService) {}

  ngOnInit() {
    this.ratioVariablesFormArray.valueChanges.forEach(() => {
      this.calculateLastDistributionVariable();
    });
  }

  findDistributionRatioName(i: number) {
    const id = this.ratioVariables[i].unitOperationId;
    return this.core.currentCase.getUnitOperationName(id);
  }

  sanitizeRangeValues(i: number, target: 'minimumValue' | 'maximumValue') {
    const ratioVariableFormGroup = this.ratioVariablesFormArray.controls[i];
    const parsedMin = unitConverter.parseFloatString(ratioVariableFormGroup.controls.minimumValue.value);
    const parsedMax = unitConverter.parseFloatString(ratioVariableFormGroup.controls.maximumValue.value);

    if (parsedMin > parsedMax) {
      ratioVariableFormGroup.get(target).setValue(undefined);
    }

    this.roundValues(i);
  }

  roundValues(i: number) {
    const ratioVariableFormGroup = this.ratioVariablesFormArray.controls[i];

    const parsedValue = unitConverter.parseFloatString(ratioVariableFormGroup.controls.value.value);
    const parsedMin = unitConverter.parseFloatString(ratioVariableFormGroup.controls.minimumValue.value);
    const parsedMax = unitConverter.parseFloatString(ratioVariableFormGroup.controls.maximumValue.value);

    if (!Number.isNaN(parsedValue)) {
      ratioVariableFormGroup.controls.value.setValue(roundNumber(parsedValue, 3));
    }

    if (!Number.isNaN(parsedMin)) {
      ratioVariableFormGroup.controls.minimumValue.setValue(roundNumber(parsedMin, 3));
    }

    if (!Number.isNaN(parsedMax)) {
      ratioVariableFormGroup.controls.maximumValue.setValue(roundNumber(parsedMax, 3));
    }
  }

  calculateLastDistributionVariable() {
    const { controls } = this.ratioVariablesFormArray;

    if (controls.length <= 1) {
      return;
    }

    let total = 0;

    for (let i = 0; i < controls.length - 1; i++) {
      const ratioVariableFormGroup = controls[i];
      const valueParsed = unitConverter.parseFloatString(ratioVariableFormGroup.controls.value.value);

      if (!Number.isNaN(valueParsed)) {
        total += valueParsed;
      } else {
        return;
      }
    }

    const lastDistributionRatio = parseFloat((1 - total).toFixed(5));

    controls[controls.length - 1].controls.value.setValue(lastDistributionRatio, {
      emitEvent: false,
      emitModelToViewChange: true,
      emitViewToModelChange: true,
    });
    controls[controls.length - 1].controls.value.markAsDirty();
  }

  isRangeControlReadonly() {
    return !this.valueControlsReadonly || this.readonly;
  }

  isValueControlReadonly(i: number) {
    return i === this.ratioVariablesFormArray.controls.length - 1 || this.valueControlsReadonly || this.readonly;
  }

  get ratioVariablesFormArray() {
    return this.ratioVariablesFormGroup.controls.ratioVariables;
  }

  getValueControlAt(i: number) {
    return this.ratioVariablesFormArray.controls[i].controls.value;
  }

  getMinimumValueControlAt(i: number) {
    return this.ratioVariablesFormArray.controls[i].controls.minimumValue;
  }

  getMaximumValueControlAt(i: number) {
    return this.ratioVariablesFormArray.controls[i].controls.maximumValue;
  }

  areFormControlsValid(i: number) {
    return (
      this.isSingleFormControlValid(this.getValueControlAt(i)) ||
      this.isSingleFormControlValid(this.getMinimumValueControlAt(i)) ||
      this.isSingleFormControlValid(this.getMaximumValueControlAt(i))
    );
  }

  isSingleFormControlValid(control: FormControl) {
    return control.invalid && (control.dirty || control.touched);
  }

  formControlsHasErrors(i: number, type: string) {
    return !!(
      this.getValueControlAt(i).errors?.[type] ||
      this.getMinimumValueControlAt(i).errors?.[type] ||
      this.getMaximumValueControlAt(i).errors?.[type]
    );
  }
}
